import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Fase1, Fase1Response, Fase1Submit, Fase2, Fase2Base, Fase2BaseResponse, Fase2Response, Fase2Submit, Fase2SubmitResponse, Fase3, Fase3Base, Fase3BaseResponse, Fase3Res, Fase3Response, Fase3Submit, Fase4, Fase4Base, Fase4BaseResponse, Fase4Res, Fase4Response, Fase4Submit, Fase5, Fase5Res, Fase5Response, Fase5Submit, Fasi1Response } from '../model/fase1';

@Injectable({
  providedIn: 'root'
})

export class SpeseService {

  BASE_URL = '';
  ESECUZIONE_URL = 'api/Esecuzione';

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient) {
    this.BASE_URL = baseUrl;
    this.ESECUZIONE_URL = baseUrl + this.ESECUZIONE_URL;
  }


  getAllFasiByCupId(cupId: number) : Observable<Fasi1Response> {
    return this.http.get<Fasi1Response>(`${this.ESECUZIONE_URL}/cup/${cupId}`)
  }


  submitFase1(fase1: Fase1Submit): Observable<Fase1Response> {
    return this.http.post<Fase1Response>(`${this.ESECUZIONE_URL}/fase1`, fase1);
  }
  updateFase1(fase1: Fase1Submit, id: number) : Observable<Fasi1Response> {
    return this.http.put<Fasi1Response>(`${this.ESECUZIONE_URL}/fase1/${id}`, fase1)
  }
  deleteFase1(id: number){
    return this.http.delete(`${this.ESECUZIONE_URL}/fase1Delete/${id}`);
  }


  submitFase2(fase2: Fase2Submit): Observable<Fase2Response> {   
    return this.http.post<Fase2Response>(`${this.ESECUZIONE_URL}/fase2`, fase2);
  }
  updateFase2(fase2: Fase2Submit, id: number) : Observable<Fase2Response> {
    return this.http.put<Fase2Response>(`${this.ESECUZIONE_URL}/fase2/${id}`, fase2)
  }
  deleteFase2(id: number){
    return this.http.delete(`${this.ESECUZIONE_URL}/fase2Delete/${id}`);
  }
  getFase2(id: number): Observable<Fase2SubmitResponse>{
    return this.http.get<Fase2SubmitResponse>(`${this.ESECUZIONE_URL}/fase2/${id}`)
  }
  submitFase3(fase3: Fase3Submit): Observable<Fase3Response> {
    return this.http.post<Fase3Response>(`${this.ESECUZIONE_URL}/fase3`, fase3);
  }
  updateFase3(fase3: Fase3Submit, id: number) : Observable<Fase3Response> {
    return this.http.put<Fase3Response>(`${this.ESECUZIONE_URL}/fase3/${id}`, fase3)
  }
  deleteFase3(id: number){
    return this.http.delete(`${this.ESECUZIONE_URL}/fase3Delete/${id}`);
  }
  getFase3(id: number): Observable<Fase3Res> {
    return this.http.get<Fase3Res>(`${this.ESECUZIONE_URL}/fase3/${id}`)
  }
  submitFase4(fase4: Fase4Submit): Observable<Fase4Response> {
    return this.http.post<Fase4Response>(`${this.ESECUZIONE_URL}/fase4`, fase4);
  }
  updateFase4(fase4: Fase4Submit, id: number) : Observable<Fase4Response> {
    return this.http.put<Fase4Response>(`${this.ESECUZIONE_URL}/fase4/${id}`, fase4)
  }
  deleteFase4(id: number){
    return this.http.delete(`${this.ESECUZIONE_URL}/fase4Delete/${id}`);
  }
  getFase4(id: number): Observable<Fase4Res>{
    return this.http.get<Fase4Res>(`${this.ESECUZIONE_URL}/fase4/${id}`)
  }
  
  
  submitFase5(fase5: Fase5Submit): Observable<Fase5Response> {
    return this.http.post<Fase5Response>(`${this.ESECUZIONE_URL}/fase5`, fase5);
  }
  updateFase5(fase5: Fase5Submit, id: number) : Observable<Fase5Response> {
    return this.http.put<Fase5Response>(`${this.ESECUZIONE_URL}/fase5/${id}`, fase5)
  }
  deleteFase5(id: number){
    return this.http.delete(`${this.ESECUZIONE_URL}/fase5Delete/${id}`);
  }
  getFase5(id: number): Observable<Fase5Res>{
    return this.http.get<Fase5Res>(`${this.ESECUZIONE_URL}/fase5/${id}`)
  }
  

  rifFase2(fase1Id: number): Observable<Fase2BaseResponse> {
    return this.http.get<Fase2BaseResponse>(`${this.ESECUZIONE_URL}/rif/fase2/${fase1Id}`)
  }
  rifFase3(fase1Id: number): Observable<Fase3BaseResponse> {
    return this.http.get<Fase3BaseResponse>(`${this.ESECUZIONE_URL}/rif/fase3/${fase1Id}`)
  }
  rifFase4(fase1Id: number): Observable<Fase4BaseResponse> {
    return this.http.get<Fase4BaseResponse>(`${this.ESECUZIONE_URL}/rif/fase4/${fase1Id}`)
  }



}
