import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { UserClientService } from 'src/app/service/user-client.service';

export function passWord(p: FormControl): { [s: string]: boolean } {
  if (!p.value) {
    return { invalidPass: true };
  }
  return null
}
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePswForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private userClientService: UserClientService,
    private router: Router
  ) {

    this.changePswForm = this.formBuilder.group({
      password: ['', Validators.required],
      newpassword: ['', [passWord, Validators.required]],
      confirmnewpassword: ['', [passWord, Validators.required]]
    });

  }

  ngOnInit() {
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.changePswForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePswForm.invalid) {
      return;
    }

    if (this.f.newpassword.value != this.f.confirmnewpassword.value) {
      this.messageService.add({
        severity: "warning",
        summary: "Attenzione",
        detail: "Le password devono coincidere"
      });
        return;
    }

    this.loading = true;
    this.spinner.show()
    this.userClientService.changePass(this.userClientService.getUser().userName, this.f.password.value, this.f.newpassword.value).subscribe(
      res => {
            setTimeout(() => {

        this.spinner.hide();
    }, 500);
        this.messageService.add({
          severity: "info",
          summary: "Modifica Password",
          detail: "Password correttamente modificata",
          sticky: true
        });
        console.log(res);
        this.router.navigate(['/login']);
      },
      error => {
            setTimeout(() => {

        this.spinner.hide();
    }, 500);
      });
  }


}
