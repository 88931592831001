import { Component, Input, OnInit } from '@angular/core';
import { CUP } from '../../../model/cup';
import { QE, QECompare, QEItemCompare } from 'src/app/model/qe';
import { QEService } from 'src/app/service/qe.service';
import { EsportaExcelTipo } from 'src/app/service/export-excel.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-raffronto',
  templateUrl: './raffronto.component.html',
  styleUrls: ['./raffronto.component.scss'],
})

export class RaffrontoComponent implements OnInit {

  public esportaExcelTipo = EsportaExcelTipo;

  @Input() qe: QE;
  @Input() cup: CUP;

  qeCompare: QECompare;
  oldQE: QE;
  items: QEItemCompare[];

  cupId: number;
  totaleFirst: number = 0;
  totaleSecond: number = 0;
  isInEdit: boolean = false;
  datigen: boolean = false;
  draftOrCurrent: any;

  constructor(private qeServices: QEService) { }

  /**
   * Inizializza il componente
   */
 public async ngOnInit (): Promise<void>
  {
    if (this.qe) {
      const response = await this.qeServices.getQECompare(this.qe.id, this.qe.qeMainId).toPromise();
      if(response && response.success){
        this.qeCompare = response.dto;
        this.items = this.qeCompare.items;
        let i: number = 0;
        this.qeCompare.items.forEach(element => {

          element.firstTotal = this.qeCompare.firstQE.sections[i]?.total ?? 0;
          element.secondTotal = this.qeCompare.secondQE.sections[i]?.total ?? 0;

          this.totaleFirst = this.totaleFirst + element.firstTotal;
          this.totaleSecond = this.totaleSecond + element.secondTotal;
          i++;
        });
      } else {
        console.log('500');
      }
    }
  }


  indexToTitle(n: number): string {
    let title = '';
    switch (n){
      case 1: 
        title = "A";
      break;
      case 2: 
        title = "B";
      break;
      case 3: 
        title = "C";
      break;
    }
    return title;
  }


}
