import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Annotazione } from '../../../model/annotazione';
import { ActivatedRoute } from '@angular/router';
import { AnnotazioniService } from 'src/app/service/annotazioni.service';
import { Professionista } from 'src/app/model/professionista';
import { tipiAtti } from 'src/app/model/spesa';


@Component({
	selector: 'app-professionista-form',
  templateUrl: './professionista-form.component.html',
  styleUrls: ['./professionista-form.component.scss']
})


export class ProfessionistaFormComponent {

  @Input() isInEdit: boolean;
  @Input() visible: boolean;
  @Input() cupId: number;
  @Input() id: number;

  @Output() closeDialog = new EventEmitter<boolean>()

  professionistaForm: FormGroup = new FormGroup({
    id: new FormControl(),
    cupId: new FormControl(),
    ruolo: new FormControl(),
    nome: new FormControl(),
    titolo: new FormControl(),
    pi_cf: new FormControl(),
    indirizzo: new FormControl(),
    città: new FormControl(),
    telefono: new FormControl(),
    email: new FormControl(),
    atto: new FormControl(),
    numero: new FormControl(),
    data: new FormControl(),
    importoNetto: new FormControl(),
    iva: new FormControl(),
    cassa: new FormControl(),
    importoTotale: new FormControl(),
  })


  professionista: Professionista;

  tipiAtti: tipiAtti[] = [
    { atto: "Determina" },
    { atto: "Delibera di G.C." },
  ];

  file: File;
  visualizza: boolean;


  constructor(){

  }

  ngOnChange(changes: SimpleChanges){
    this.initForm()
  }

  ngOnInit(){
    this.initForm()
  }

  initForm(){
    if(this.id){
      this.professionistaForm.setValue({
        id: this.professionista.id,
        cupId: this.professionista.cupId,
        ruolo: this.professionista.ruolo,
        nome: this.professionista.ruolo,
        titolo: this.professionista.ruolo,
        pi_cf: this.professionista.ruolo,
        indirizzo: this.professionista.ruolo,
        città: this.professionista.ruolo,
        telefono: this.professionista.ruolo,
        email: this.professionista.ruolo,
        atto: this.professionista.ruolo,
        numero: this.professionista.ruolo,
        data: new Date(this.professionista.data),
        importoNetto: this.professionista.ruolo,
        iva: this.professionista.ruolo,
        cassa: this.professionista.ruolo,
        importoTotale: this.professionista.importoNetto + this.professionista.iva +this.professionista.cassa,
      })

      this.professionistaForm.get('importoNetto').valueChanges.subscribe(() => this.updateSum());
      this.professionistaForm.get('iva').valueChanges.subscribe(() => this.updateSum());
      this.professionistaForm.get('cassa').valueChanges.subscribe(() => this.updateSum());

      this.professionistaForm.disable();

      /* this.annotazioniServices.getAnnotazione(this.id).subscribe({
        next: (resp: Annotazione) => {
          this.annotazione = resp;
          this.cupId = this.annotazione.cupId;
          this.isInEdit = true;

          
        }
      }) */

    }else{
      this.professionistaForm = new FormGroup({
        id: new FormControl(),
        cupId: new FormControl(),
        ruolo: new FormControl(),
        nome: new FormControl(),
        titolo: new FormControl(),
        pi_cf: new FormControl(),
        indirizzo: new FormControl(),
        città: new FormControl(),
        telefono: new FormControl(),
        email: new FormControl(),
        atto: new FormControl(),
        numero: new FormControl(),
        data: new FormControl(),
        importoNetto: new FormControl(),
        iva: new FormControl(),
        cassa: new FormControl(),
        importoTotale: new FormControl(),
      })

      this.professionistaForm.get('importoNetto').valueChanges.subscribe(() => this.updateSum());
      this.professionistaForm.get('iva').valueChanges.subscribe(() => this.updateSum());
      this.professionistaForm.get('cassa').valueChanges.subscribe(() => this.updateSum());

    }


  }

  updateSum(){
    const importoNetto = this.professionistaForm.get('importoNetto').value;
    const importoIVA = this.professionistaForm.get('iva').value;
    const importoCassa = this.professionistaForm.get('cassa').value;

    const sum = importoNetto + importoIVA + importoCassa;
    this.professionistaForm.get('importoTotale').setValue(sum);
  }

  add(){}

  save(){}

  close(bool?){

  }

  onUpload(event){
    this.file = event.files[0];
    let customFile = new File([this.file], 'NEW_GUID_GOES_HERE.txt', {
      type: this.file.type
    });

    console.log(customFile.name, customFile.size, customFile.type);
  }
}