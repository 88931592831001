import { Font } from "jspdf";

export class FonteSubmit{
  ente: string;
  atto: string;
  numero: string;
  del: Date;
  descrizione: string;
  importoFinanziamento: number;
  cupId: number;

  constructor(cupId: number, ente: string, atto: string, numero: string, del: Date, descrizione: string, importoFinanziamento: number){

    this.cupId = cupId || 0;
    this.ente = ente || '';
    this.atto = atto || '';
    this.numero = numero || '';
    this.del = del || new Date;
    this.descrizione = descrizione || '';
    this.importoFinanziamento = importoFinanziamento || 0;
  }
}
export class FonteResponse{
  success: boolean;
  dto: Fonte;
}

export class FontiResponse{
  success: boolean;
  dtos: Fonte[];
}

export class Fonte{
  ente: string;
  atto: string;
  numero: string;
  del: Date;
  descrizione: string;
  importoFinanziamento: number;

  id: number;
  cupId: number;
  sommaIncassato: number;

  allegato?: boolean;

  constructor(cupId: number, id: number, ente: string, atto: string, numero: string, del: Date, descrizione: string, importoFinanziamento: number, sommaIncassato: number){

    this.cupId = cupId || 0;
    this.id = id || 0;
    this.ente = ente || '';
    this.atto = atto || '';
    this.numero = numero || '';
    this.del = del || new Date;
    this.descrizione = descrizione || '';
    this.importoFinanziamento = importoFinanziamento || 0;
    this.sommaIncassato = sommaIncassato || 0;
  }
}

export class IncassoSubmit{
  atto: string;
  numero: string;
  del: Date;
  descrizione: string;
  importoIncassato: number;
  fonteFinanziamentoID: number;

  constructor(ente: string, fonteFinanziamentoID: number, atto: string, numero: string, del: Date, descrizione: string, importoIncassato: number){

    //this.ente = ente || ''; 
    this.atto = atto || '';
    this.numero = numero || '';
    this.del = del || new Date;
    this.descrizione = descrizione || '';
    this.importoIncassato = importoIncassato || 0;
    this.fonteFinanziamentoID = fonteFinanziamentoID || 0;
  }
}

export class IncassoResponse{
  success: boolean;
  dto: Incasso;
}
export class IncassiResponse{
  success: boolean;
  dtos: Incasso[];
}

export class Incasso{
  id: number;
  ente: string;
  fonteFinanziamentoID: number;
  atto: string;
  numero: string;
  del: Date;
  descrizione: string;
  importoIncassato: number;

  constructor(id: number, ente: string, fonteFinanziamentoID: number, atto: string, numero: string, del: Date, descrizione: string, importoIncassato: number){

    this.id = id || 0;
    this.fonteFinanziamentoID = fonteFinanziamentoID || 0;
    this.ente = ente || '';
    this.atto = atto || '';
    this.numero = numero || '';
    this.del = del || new Date;
    this.descrizione = descrizione || '';
    this.importoIncassato = importoIncassato || 0;
  }
}

export class FontiFinanziamentoSearch{
  storiche: boolean
}
