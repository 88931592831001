import { Component } from '@angular/core';
import { Professionista } from 'src/app/model/professionista';
import { ConfirmationService, MessageService } from "primeng/api";
import { ActivatedRoute } from '@angular/router';
import { SpeseService } from 'src/app/service/spese.service';
import { Fase1, Fasi1Response } from 'src/app/model/fase1';

@Component({
  selector: 'app-professionisti',
  templateUrl: './professionisti.component.html',
  styleUrls: ['./professionisti.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class ProfessionistiComponent {

  filteredTab: Professionista[];
  selectedProfessionista: Professionista;
  isId: boolean;
  visible: boolean;
  isInEdit: boolean;
  id: number;
  cupId: number;
  spese: Fase1[];
  beneficiari = []

  constructor(
    private route: ActivatedRoute,
    private spesaServices: SpeseService,
  ){}

  ngOnInit(){
    this.isId = this.route.snapshot.paramMap.get('id') ? true : false;
    if (this.isId) {
      this.cupId = Number(this.route.snapshot.paramMap.get('id'));
      this.spesaServices.getAllFasiByCupId(this.cupId).subscribe({
        next: (resp: Fasi1Response) => {
          if (resp && resp.success) {
            this.spese = resp.dtos;
            for(let spesa of this.spese){
              let prof:Professionista;
             this.beneficiari.push(prof) 
            }
            this.filteredTab = this.beneficiari;
          }
        }
      })
    }
  }

  showDialogForm(id?){
    this.visible = true;
    this.id = id;
    this.isInEdit = !!id;
  }

  selectedRow(id?){

  }
  confirm(id?){}

  onClose(event){}
}
