
<form [formGroup]="scadenzaForm">

	<div class="row">
		<div class="form-group col-md-3">
			<label for="data" class="col-form-label">Data</label>
			<p-calendar formControlName="data" class="inputScadenzeForm" dateFormat="dd/mm/yy"></p-calendar>
			<small style="color:  #dc3545;" *ngIf="!scadenzaForm.get('data').valid && scadenzaForm.get('data').touched">
				Inserisci una data</small>
		</div>

		<div class="form-group col-md-9">
			<label for="descrizione" class="col-form-label">Descrizione</label>
			<textarea formControlName="descrizione" type="text" class="form-control inputScadenzeForm" rows="1" cols="30" pInputTextarea [autoResize]="true"></textarea>
			<small style="color:  #dc3545;" *ngIf="!scadenzaForm.get('descrizione').valid && scadenzaForm.get('descrizione').touched">Inserisci una descrizione</small>
		</div>



		<div class="form-group col-md-12">
		  <label for="alertAt" class="col-form-label">Alert</label>
			<div style="display: flex; justify-content: space-between; gap: 10px; padding-bottom: 5px;">
				<p-checkbox *ngFor="let option of options" formControlName="alertAt"
											[value]="option.days"
											[label]="option.label"
											(onChange)="change($event)"
											>
				</p-checkbox>
			</div>
			<small style="color:  #dc3545;" *ngIf="!scadenzaForm.get('alertAt').valid && scadenzaForm.get('alertAt').touched">Seleziona una scadenza</small>
		</div>

		<div class="form-group col-md-12">
			<label for="emails" class="col-form-label">Email</label>
			<div class="col-12" style="display: flex; justify-content: space-between; gap: 10px">
				<p-chips formControlName="emails" [separator]="separatorExp"></p-chips>
			</div>
			<small style="color:  #dc3545;" *ngIf="!scadenzaForm.get('emails').valid && scadenzaForm.get('emails').touched">Inserisci almeno una mail</small>
		</div>
	</div>

	<div class="footer mt-4" >
		<div *ngIf="isInEdit && (!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" style="display: flex; gap: 10px;">

			<button *ngIf="!scadenzaForm.enabled" pButton label="MODIFICA" [disabled]="scadenzaForm.invalid" icon="pi pi-pencil"
				(click)="scadenzaForm.enable()"></button>
			<p-toast></p-toast>
			<button *ngIf="scadenzaForm.enabled" pButton pRipple label="SALVA" [disabled]="scadenzaForm.invalid" icon="pi pi-check"
				(click)="save()"></button>
			<button pButton label="ANNULLA" (click)="close(false)"></button>
		</div>

		<div *ngIf="!isInEdit" style="display: flex; gap: 10px;">
			<p-toast></p-toast>
      		<button pButton label="AGGIUNGI" icon="pi pi-plus" [disabled]="scadenzaForm.invalid" (click)="add()"></button>
      		<button pButton label="ANNULLA" (click)="close(false)"></button>
		</div>
	</div>

</form>





