  <form *ngIf="formGestioneUtente" [formGroup]="formGestioneUtente">
    
    <div class="row">

      <div class="form-group col-md-8">
        <label for="utente" class="col-form-label">Utente</label>
        <p-dropdown [options]="users" formControlName="utente" optionValue="userName" optionLabel="userName" [showClear]="true"
          placeholder="Seleziona un utente" (onChange)="onChangeUtente($event.value)" appendTo="body">
        </p-dropdown>
      </div>
      <div class="col-md-2 icon-info-utente">
        <button pButton icon="pi pi-info-circle" [pTooltip]="infoTooltip" tooltipPosition="top" class="custom-info-utente"></button>
      </div>

      <div *ngIf="isLoadingPermessi" class="text-center">
        <p-progressSpinner></p-progressSpinner>
        <div> Caricamento Permessi in Corso </div>
      </div>

      <div *ngIf="formGestioneUtenteArray && formGestioneUtenteArray.controls && formGestioneUtenteArray.controls.length > 0" formArrayName="percorsi">
        <div class="form-group col-md-12">
          <div style="width: 100%; display: flex;">
            <div style="width: 20%;">
              <label for="tabConcesse" class="col-form-label">Tab</label>
            </div>
            <div style="width: 80%;">
              <label for="permessi" class="col-form-label">Permessi</label>
            </div>
          </div>
          
          <div  *ngFor="let percorsoGroup of formGestioneUtenteArray.controls; let i = index" 
            [formGroupName]="i" style="display: flex; flex-direction: column;  justify-content: space-between;" class="my-2">
            <div class="row">

                <div style="display: flex; align-items: center;">
                  <div style="width: 20%;">
                    <p-checkbox formControlName="tab" [value]="tabs[i].id" (onChange)="change($event, percorsoGroup, i)">
                    </p-checkbox>
                    <label [for]="tabs[i]" style="padding-left:1em">{{tabs[i].descrizione}}</label>
                  </div>
                  
                  <div style="width: 80%;">
                    <p-dropdown [options]="permessi" formControlName="permesso" optionValue="permesso" optionLabel="permesso" optionDisabled="Lettura"
                      placeholder="Nessun permesso selezionato" (onChange)="onChangePermesso(percorsoGroup)" appendTo="body" >
                    </p-dropdown>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="formGestioneUtente.get('utente').value" class="d-flex justify-content-end mt-3">
      <div>
        <button pButton [label]="labelSavePermessi" class="p-button" [disabled]="formGestioneUtente.invalid" (click)="savePermessi()"></button>
      </div>
    </div>
    <p-toast></p-toast>

    <rbk-modal *ngIf="visibleDialog" [dialogModel]="dialogModel" (onConfirm)="onConfirmPopup()"></rbk-modal>
  </form>
