import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import { MessageService } from 'primeng/api';


@Injectable({
  providedIn: 'root'
})
export class ErrorCatchingInterceptor implements HttpInterceptor {

  constructor(
    private messageService: MessageService,
    ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
     return next.handle(request)
           .pipe(
                 catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    console.log(error);
                    if (error.error instanceof ErrorEvent) {
                       console.log('This is client side error');
                       errorMsg = `Error: ${error.error.message}`;
                       this.messageService.add({
                        severity: "error",
                        summary: "Client Error",
                        detail:  errorMsg
                       })
                    } else {
                      if (error.status != 409)
                      {
                       console.log('This is server side error');
                       errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
                       this.messageService.add({
                        severity: "error",
                        summary: "Server Error",
                        detail:  errorMsg + " " + error.error.message,
                       })
                      }
                    }
                    console.log(errorMsg);
                    return throwError(error);
                 })
           )
  }
}
