import { QEDetail } from './../../../model/qe';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ConfirmationService, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { CUP } from "src/app/model/cup";
import { TipoEntita } from "src/app/model/documento";
import { Model, ModelResponse, QE, QEItem, QEItemBase, QEModel, QEModelResponse, QEResponse, QESection } from "src/app/model/qe";
import { Dizionario, ModelloDocumentaleCustom, Scenario, TipologiaDizionario } from "src/app/model/scenari";
import { EsportaExcelTipo } from "src/app/service/export-excel.service";
import { QEService } from "src/app/service/qe.service";
import { ScenarioService } from "src/app/service/scenario.service";
import { SharedService } from "src/app/service/shared.service";
import * as _ from 'lodash';
import { Ruolo, TipoPermesso, Utente } from "src/app/model/utente";
import { RuoloPipe } from "src/app/pipe/ruolo-pipe";
import { UserClientService } from "src/app/service/user-client.service";

@Component({
    selector: 'rbk-qe',
    templateUrl: './qe-item.component.html',
    providers: [DialogService, ConfirmationService, MessageService],
  })
  export class QEItemComponent implements OnInit {
    
    @Input() public qe: QE;
    @Input() public cup: CUP;
    @Input() public isDraft: boolean = false;
    @Input() public isAttuale: boolean = false;
    @Input() public isStorico: boolean = false;
    @Input() public idLastStorico: number;
    @Input() public isAnteprima: boolean = false;
    /**
     * Permesso in base all'utente loggato
     */
    @Input() public permesso: TipoPermesso;
    /**
     * Utente loggato
     */
    @Input() public user: Utente;

    @Output() publishBozza = new EventEmitter<QE>();
    @Output() publishQE = new EventEmitter<QE>();
    @Output() updateQE = new EventEmitter<QE>();
    @Output() newQE = new EventEmitter<QEModel>();
    /**
     * Evento che si scatena alla chiusura della modale
     */
    @Output() onCloseDialog = new EventEmitter<void>();
    
    /**
     * Datimodello per qe attuale
     */
    public datiModelloDocumentale: ModelloDocumentaleCustom;

    qeSectionsCalcolate: QESection[] = [];
    bloccaSaveBozza: boolean = true;
    isInEdit: boolean = false;
    isInEditSections: boolean = true;
    saveModel: boolean = false;
    modelloQE: QEModel;  
    modelQE: FormGroup = new FormGroup({
      nome: new FormControl('', Validators.required),
    });
    rowsImpegnate: QEItemBase[];
    qeTotal: number = 0;
    total: number = 0;
    sezioneC: boolean = false;
    visualizza: boolean = true;
    public editCurrent: boolean;
    firstQE: boolean;
    listaModelli: Model[] = [];
    showListaModelli: boolean = false;
    entityIdAdd: number;
    tipiQE: Dizionario[] = [];
    tipiAtti: Dizionario[] = [];

    /**
     * Disabilita il pulsante Salva Bozza se true
     */
    public disableSalvaBozza: boolean = false;

    public esportaExcelTipo = EsportaExcelTipo;
    sectionsAreReady: boolean = false;
    public RuoloPipe = new RuoloPipe();
    public Ruolo = Ruolo;
    public TipoPermesso = TipoPermesso;

    constructor(
          private ref: ChangeDetectorRef,
          private qeServices: QEService,
          private confirmationService: ConfirmationService,
          private spinner: NgxSpinnerService,
          private messageService: MessageService,
          private scenarioServices: ScenarioService,
          private route: ActivatedRoute,
          private sharedServices: SharedService,
          private userClientService: UserClientService){ }


    async ngOnInit ()
    {
      this.inzializzaQE(); 
      this.checkDeletedItem();
    }
    
    async inzializzaQE(){
      this.firstQE = false;
      const formGroup = this.scenarioServices.getFormGroup(Scenario.QuadroEconomico);
      this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.QuadroEconomico);
      this.datiModelloDocumentale.entityID = this.isStorico ? this.qe?.id : this.idLastStorico;
      this.datiModelloDocumentale.entityTipo = TipoEntita.QuadroEconomicoStorico; // Da sistemare
      this.datiModelloDocumentale.cup = this.cup;
      this.datiModelloDocumentale.isEdit = false;
      this.datiModelloDocumentale.idCup = this.cup?.id;
      this.datiModelloDocumentale.isFrom = Scenario.QuadroEconomico;

      let tipoQE = await this.sharedServices.getDizionarioByCategoria(TipologiaDizionario.QEBozza_Tipo) || [];
      let tipoAtto = await this.sharedServices.getDizionarioByCategoria(TipologiaDizionario.Atto) || [];
      this.datiModelloDocumentale.DatiForm[0].lista = tipoQE.filter(x => x.attivo);
      this.datiModelloDocumentale.DatiForm[1].lista = tipoAtto.filter(x => x.attivo);


      if (this.qe) {
          if (!this.isDraft) {
            formGroup.setValue({
              id: this.qe.detail.id || 0,
              cupId: this.qe.cupId || this.cup?.id,
              qeMainId: this.qe.detail.qeMainId,
              tipo: this.qe.detail.tipo,
              atto: this.qe.detail.atto,
              numero: this.qe.detail.numero,
              data: new Date(this.qe.detail.data),
              descrizione: this.qe.detail.descrizione,
              allegato: this.qe.detail.allegato || false
            });
          } else {
            formGroup.setValue({
              id: 0,
              cupId: this.qe.cupId || this.cup?.id,
              qeMainId: this.qe.detail.qeMainId,
              tipo: '',
              atto: '',
              numero: 0,
              data: null,
              descrizione: '',
              allegato: false
            });
          }
    
          if (!this.qe.sections) {
              this.qe.sections = [];
              this.addSezione('A');
              this.addSezione('B');
          } else {
              this.sezioneC = this.qe?.sections.some((x) => x.title == 'C');
          }
    
          this.checkTotal();
          this.ref.markForCheck();
          this.order();
    
          if (this.qe.detail.data && !this.isDraft) {
              const date = new Date(`${this.qe.detail.data.toString()}`);
              formGroup.controls['data'].setValue(date);
          }
    
          if(!this.isInEdit && this.qe.cupId === 0) {
              formGroup.disable();
          }
      } else {
        formGroup.reset();
        if (this.cup) {
            this.firstQE = (this.cup.qEs.length === 0);
        }
      }
      this.checkDeletedItem();
    

      if(this.qe){
        this.qeSectionsCalcolate = _.cloneDeep(this.qe?.sections);
      }
      
    
      formGroup.disable();
      this.datiModelloDocumentale.formGroup = formGroup;
    }
    


    async newBozza(model?: QEModel) {
      if (this.isDraft && !this.qe) {
        await this.addOrEditQEBozza(model);
      }
      this.checkTotal();
      this.checkDeletedItem();
    }

    modificaBozza() {
      this.isInEdit = true;
      this.checkDeletedItem();
    }

  async addOrEditQEBozza(model?: QEModel) {
    this.spinner.show();
    const response = await this.qeServices.getBozzeQE(this.cup?.id).toPromise();
    if(response && response.success){
      if (model) {
        this.createModel(model, response.dto);
      } else {
        this.qe = response.dto;
      }
    } else {
      console.log('500');
    }
    this.spinner.hide();
  }

    /**
   * Calcola gli item che non si possono cancellare quando modifico il QE dallo storico
   */
  checkDeletedItem(){
    this.rowsImpegnate = this.sharedServices.onReceiveRowsImpegnate();
    if (this.qe && this.qe.sections) {
      for (let el of this.qe.sections) {
        for (let item of el.items) {
            item.deletable = true;
        }
      }
      if (this.rowsImpegnate) {
        for (let elem of this.rowsImpegnate) {
            if (this.qe.sections && this.qe.sections.length > 0) {
            if (this.qe.sections[elem.qeSectionIndex]) {
                if (
                this.qe.sections[elem.qeSectionIndex].items.find(
                    (x) => x.index === elem.index
                )
                ) {
                this.qe.sections[elem.qeSectionIndex].items.find(
                    (x) => x.index === elem.index
                ).deletable = false;
                }
            }
          }
        }
      }
    }
  this.sectionsAreReady = true;
  }

/**
 * Create QE from Modello
 */
  async createModel(model, qeFromModel) {
    this.spinner.show();
    const response = await this.qeServices.getTemplateById(model?.id).toPromise();
    if(response && response.success){
      this.qe = response.dto;
      this.qe.cupId = qeFromModel.cupId;
      this.qe.detail.qeMainId = qeFromModel.detail.qeMainId;
      this.qe.qeMainId = qeFromModel.qeMainId;
      this.qe.publishedAt = null;
      this.qe.version = qeFromModel.version;
      this.inzializzaQE();
    } else {
      console.log('500');
    }
    this.spinner.hide();
  }

  async newBozzaDaModello() {
    if (this.isDraft && !this.qe) {
      const response: ModelResponse = await this.qeServices.getTemplates().toPromise();
      if (response && response.success){
        this.listaModelli = response.dtos;
      } else {
        console.log('500');
      }
      this.showListaModelli = true;
    }
  }

    deleteItem(section, item) {
    if (item?.deletable) {
      this.confirmationService.confirm({
        message: 'Sei sicuro di voler eliminare questa voce di spesa?',
        header: 'Eliminazione',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Si, elimina',
        rejectLabel: 'No, torna indietro',
        acceptButtonStyleClass: 'p-button-danger p-button-text',
        rejectButtonStyleClass: 'p-button-text',
        accept: () => {
          this.scenarioServices.showSuccessMessage('Voce di spesa eliminata');
          this.removeItem(section, item);
        }
      });
    } else {
      this.scenarioServices.showErrorMessage('Voce di spesa non eliminabile');
    }
  }

  addHeader(section: QESection): void {
    this.qe.sections
      .filter((x) => x.index == section.index)[0]
      .items.push({
        qeSectionQEMainId: section.qeMainId,
        qeSectionIndex: section.index,
        index: this.getNextIndex(section, 0, true),
        total: null,
        subIndex: 0,
        title: '',
        deletable: true,
      });
    this.order();
  }

  getNextIndex(section: QESection, index: number, findForHeader: boolean): number {
    if (section == null) return 0;
    if (findForHeader) {
      var items = section.items
        .filter((x) => x.subIndex == 0)
        .map((x) => x.index);
      let i = 1;
      while (i < 1000) {
        if (items.indexOf(i) == -1) return i;
        i++;
      }
    } else {
      var items = section.items
        .filter((x) => x.index == index)
        .map((x) => x.subIndex);
      return items.length;
    }
    return 0;
  }

    addSezione(title: string) {
        this.sezioneC = title === 'C';
        const index = title === 'B' ? 1 : title === 'C' ? 2 : 0;
        const qeSection: QESection = {
          qeMainId: 0,
          title: title,
          description: '',
          index: index,
          total: 0,
          items: [],
        };
        this.qe.sections.push(qeSection);
      }
    
      delSezioneC() {
        /// Verifica se è possibile elimiare la sezione C se collegata a Esecuzione !!! Alessia
        this.sezioneC = false;
        this.qe.sections.pop();
        this.changeImport(null);
      }

      changeImport(
        event?: any,
        fromHTML = false,
        section?: QESection,
        item?: QEItem
      ) {
        if ((event && +event) || fromHTML) {
          this.qe.sections
            .filter((x) => x.index == section.index)[0] 
            .items.filter(
              (x) => x.index == item.index && x.subIndex == item.subIndex
            )[0].total = event ?? 0;
        }
        this.checkTotal();
      }
    
      checkTotal() {
        this.qeTotal = 0;
        this.qe.sections.forEach((x) => {
          let headers = x.items?.filter((i) => i.subIndex == 0);
    
          headers?.forEach((h) => {
            let items = x.items.filter(
              (f) => f.index == h.index && f.subIndex != 0
            );
            if (items.length != 0) {
              h.total = items.reduce((a, b) => a + b.total, 0);
            }
          });
    
          x.total =
            x.items
              ?.filter((x) => x.subIndex == 0)
              ?.reduce((a, b) => a + b.total, 0) ?? 0;
        });
        this.qeTotal = this.qe.sections?.reduce((a, b) => a + b.total, 0) ?? 0;
      }
    
      changeImportHeader(
        event?: any,
        fromHTML = false,
        section?: QESection,
        item?: QEItem
      ) {
        if ((event && +event) || fromHTML) {
          this.qe.sections
            .filter((x) => x.index == section.index)[0]
            .items.filter(
              (x) => x.index == item.index && item.subIndex === 0
            )[0].total = event ?? 0;
        }
        this.checkTotal();
      }

      /**
       * Abilita il pulsante Salva Bozza
       */
      public onIsSalvaBozza() {
        this.isInEdit = true;
        this.bloccaSaveBozza = true;
      }

      bloccaBtnSalvaBozza(event: boolean){
        this.bloccaSaveBozza = event;
      }

      order(): void {
        this.qe.sections = this.qe.sections.sort((a, b) =>
          a.index > b.index ? 1 : -1
        );
        this.qe.sections.forEach((section) => {
          section.items = section.items.sort((a, b) =>
            a.index * 1000 + a.subIndex > b.index * 1000 + b.subIndex ? 1 : -1
          );
        });
      }
    
      removeItem(section: QESection, item: QEItem): void {
        
      this.sectionsAreReady = false;
        let qe: QE = Object.assign({}, this.qe);
        qe.sections = [];
        this.qe.sections.forEach((s) => {
          if (section.index != s.index) {
            qe.sections.push(s);
          } else {
            let sn: QESection = Object.assign({}, s);
            sn.items = [];
            s.items.forEach((i) => {
              if (
                (item.index == i.index &&
                  (i.subIndex == item.subIndex || item.subIndex == 0)) == false
              )
                sn.items.push(i);
            });
            qe.sections.push(sn);
          }
        });
        this.qe = qe;
        this.order();
    
        let b = this.qe.sections.find((sec) => sec.index === section.index);
        let bGroupIndex = b.items.filter((x) => x.index === item.index);
        if (bGroupIndex.length === 1) bGroupIndex[0].importDisabled = false;
    
        this.sectionsAreReady = true;
        this.changeImport(null);
      }

    addItem(section: QESection, item: QEItem): void {
      this.qe.sections
      .filter((x) => x.index == section.index)[0]
      .items.push({
        qeSectionQEMainId: section.qeMainId,
        subIndex: this.getNextIndex(section, item.index, false),
        qeSectionIndex: section.index,
        index: item.index,
        title: '',
        total: null,
        deletable: true,
      });
      this.order();
  }

    changeTitle(
    event?: any,
    fromHTML = false,
    section?: QESection,
    item?: QEItem
  ) {
    this.qe.sections
      .filter((x) => x.index == section.index)[0]
      .items.filter(
        (x) => x.index == item.index && x.subIndex == item.subIndex
      )[0].title = event.target.value;

    this.qe.sections
      .filter((x) => x.index == section.index)[0]
      .items.filter(
        (x) => x.index == item.index && x.subIndex == item.subIndex
      )[0].title = event.target.value;
  }


  confirm3(qe: QE) {
    this.confirmationService.confirm({
      message:
        'Sei sicuro di voler modificare questo quadro economico già approvato?',
      header: 'Modifiche',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, modifica',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Confirmed',
          detail: 'QE modificato',
          sticky: true,
        });
        this.editVariante(qe);
        this.entityIdAdd = qe.id;
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have rejected',
        });
      },
    });
  }



  async editVariante(qe: QE) {
    this.qe.detail.tipo = this.datiModelloDocumentale?.formGroup.controls['tipo'].value;
    this.qe.detail.atto = this.datiModelloDocumentale?.formGroup.controls['atto'].value;
    this.qe.detail.numero = this.datiModelloDocumentale?.formGroup.controls['numero'].value;
    this.qe.detail.data = this.datiModelloDocumentale?.formGroup.controls['data'].value;
    this.qe.detail.descrizione = this.datiModelloDocumentale?.formGroup.controls['descrizione'].value;
    this.qe.detail.allegato = this.datiModelloDocumentale?.formGroup.controls['allegato'].value;

    this.qe.detail.idCup = parseInt(this.route.snapshot.paramMap.get('id'));
    this.qe.cupId = parseInt(this.route.snapshot.paramMap.get('id'));
    console.log(this.qe);
    this.spinner.show();
    const resp = await this.qeServices.updateQe(this.qe).toPromise();
    if (resp && resp.success) {
      this.qe = resp.dto;
      console.log('resp edit', resp);
      this.order();
      this.spinner.hide();
      this.sharedServices.sendQE(this.qe);

      //this.dialog.nativeElement.style.display = 'none';
      if (this.qe && this.qe.sections) {
        for (let el of this.qe.sections) {
          for (let item of el.items) {
            item.deletable = true;
          }
        }
        
      this.rowsImpegnate = this.sharedServices.onReceiveRowsImpegnate();
        if ( this.rowsImpegnate?.length > 0) {
          for (let elem of this.rowsImpegnate) {
            if (this.qe.sections && this.qe.sections.length > 0) {
              if (this.qe.sections[elem.qeSectionIndex]) {
                if (
                  this.qe.sections[elem.qeSectionIndex].items[elem.index]
                ) {
                  this.qe.sections[elem.qeSectionIndex].items.find(
                    (x) => x.index === elem.index
                  ).deletable = false;
                }
              }
            }
          }
        }
      }
      this.updateQE.emit(resp.dto);
      this.onCloseDialog.emit();
    } else {
      console.log("Error");
    }
  }

  
  async saveBozza() {
    this.isInEdit = false;
    this.qe.sections?.forEach((x) => (x.total = x.total ?? 0));
    this.qe.sections?.forEach((x) => x.items?.forEach((x) => (x.total = x.total ?? 0)));
    this.qe.cupId = this.cup?.id;
    this.spinner.show();
    const response = await this.qeServices.updateQeDraft(this.qe).toPromise();
    if(response && response.success){
      this.qe = response.dto;
      this.entityIdAdd = this.idLastStorico;
      this.order();
      setTimeout(() => {
        // serve per far caricare il documento alla pubblicazione
        this.publishBozza.emit(this.qe);
        // this.bloccaSaveBozza = true;
        this.checkTotal();
      }, 1000);
    } else {
      console.log('500');
    }
    this.spinner.hide();
  }

  async pubblicaVariante() {
    this.spinner.show();
    this.qe.detail.tipo = this.datiModelloDocumentale?.formGroup.controls['tipo'].value;
    this.qe.detail.atto = this.datiModelloDocumentale?.formGroup.controls['atto'].value;
    this.qe.detail.numero = this.datiModelloDocumentale?.formGroup.controls['numero'].value;
    this.qe.detail.data = this.datiModelloDocumentale?.formGroup.controls['data'].value;
    this.qe.detail.descrizione = this.datiModelloDocumentale?.formGroup.controls['descrizione'].value;
    this.qe.detail.allegato = this.datiModelloDocumentale?.formGroup.controls['allegato'].value;
    const response = await this.qeServices.createAttualeQe(this.qe).toPromise();
    if(response && response.success){       
        this.firstQE = false;
        this.qe = response.dto;
        this.order();
        setTimeout(() => {
        this.publishQE.emit(response.dto);
        }, 1200);
        this.scenarioServices.showSuccessMessage('Quadro Economico salvato con successo');
    } else {
      console.log('500');
      this.scenarioServices.showErrorMessage('Errore nel salvataggio del Quadro Economico');
    }
    this.spinner.hide();
  }


  modificaQEStorico() {
    this.isInEdit = true;
    this.datiModelloDocumentale?.formGroup.enable();
  }

  saveModelloDialog() {
    this.saveModel = true;
  }
  
  confirmModel(model) {
    console.log(model);
    this.confirmationService.confirm({
      message: `Hai selezionato il modello ${model.nome}. Proseguire?`,
      header: 'Scelta modello',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.newBozza(model);
        this.showListaModelli = false;
      },
      reject: () => {},
    });
  }

    confirmDeleteModel(model) {
    console.log(model);
    this.confirmationService.confirm({
      message: `Sei sicuro di voler eliminare il modello ${model.nome}?`,
      header: 'Elimina modello',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.qeServices.deleteTemplate(model.id).subscribe({
          next: (resp) => {
            if (resp) {
              console.log(resp);
            }
          }
        })
        this.showListaModelli = false;
      }
    });
  }

  
  saveModello() {
    let string = JSON.stringify(this.qe);
    let newQue = JSON.parse(string);

    for (let s of newQue.sections) {
      s.total = 0;
      for (let it of s.items) {
        it.total = 0;
      }
    }

    this.modelloQE = {
      nome: this.modelQE.value.nome,
      draft: newQue,
    };

    this.qeServices.createTemplate(this.modelloQE).subscribe({
      next: (resp: QEModelResponse) => {
        if (resp && resp.success) {
          console.log(resp);
          this.modelloQE = resp.dto;
        }
      },
    });
    this.saveModel = false;
  }


      /**
   * Controlla se ci sono delle righe utilizzate che non si possono cancellare
   */
      private getRowsImpegnate() {
        if (this.qe && this.qe.sections) {
          for (let el of this.qe.sections) {
            for (let item of el.items) {
              item.deletable = true;
            }
          }
          if (this.rowsImpegnate) {
            for (let elem of this.rowsImpegnate) {
              if (this.qe.sections && this.qe.sections.length > 0) {
                if (this.qe.sections[elem.qeSectionIndex]) {
                  if (this.qe.sections[elem.qeSectionIndex].items.find(x => x.index === elem.index)) {
                    this.qe.sections[elem.qeSectionIndex].items.find(x => x.index === elem.index).deletable = false;
                  }
                }
              }
            }
          }
        }
      }


}