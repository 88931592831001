<p-tabView>
    <p-tabPanel header="Da lavorare">
        <p-card>

            <div class="row">
                <div class="col-4">

                    <p-autoComplete [(ngModel)]="selectedCup" [showEmptyMessage]="true" [suggestions]="filteredCups"
                        [showClear]="true" [dropdown]="true" (completeMethod)="filterCup($event)" field="codice"
                        [minLength]="1" (onSelect)="filterBrowse()" (onClear)="clearCupFilter()"></p-autoComplete>

                </div>
                <div class="col-4">
                    <h5>Includi Senza Cup</h5>
                    <p-inputSwitch [(ngModel)]="includiSenzaCup" (onChange)="filterBrowse()"></p-inputSwitch>
                </div>
            </div>

            <!-- Spiner caricamento Lista documenti/preview documenti-->
            <div *ngIf="caricamentoGrid" class="text-center">
                <p-progressSpinner></p-progressSpinner>
                <div> Caricamento in corso </div>
            </div>

            <p-table *ngIf="!caricamentoGrid" [value]="documentBrowse" [paginator]="true" [rows]="rows"
                [(first)]="first" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]"
                currentPageReportTemplate="Da {first} a {last} di {totalRecords} Documenti"
                [(selection)]="selectedDocumento" dataKey="codice">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Data</th>
                        <th>Ora</th>
                        <th>Utente</th>
                        <th>Nome file</th>
                        <th>Cartella</th>
                        <th>CUP</th>
                        <th>Descrizione</th>
                        <th>Apri</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-documento>
                    <tr class="riga-documenti" [pSelectableRow]="documento"
                        (dblclick)="prendiInCaricoDocumento(documento.id)">
                        <td>{{ documento?.uploadAt | date }}</td>
                        <td>{{ documento?.uploadAt | date:'HH:mm' }}</td>
                        <td>{{ documento?.uploadBy }}</td>
                        <td>{{ documento?.nomeFile }}</td>
                        <td>{{ documento?.folder }}</td>
                        <td>{{ documento?.cup }}</td>
                        <td>{{ documento?.descrizione }}</td>
                        <td>
                            <p-button type="button" icon="pi pi-download" styleClass="p-button-text"
                                (click)="prendiInCaricoDocumento(documento)"></p-button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-card>
    </p-tabPanel>
    <p-tabPanel header="Lavorati">
        <p-card>
            <p-table [value]="documentBrowseArchiviati" [paginator]="true" [rows]="rows" [(first)]="first"
                [showCurrentPageReport]="true" [(selection)]="selectedDocumento"
                currentPageReportTemplate="Da {first} a {last} di {totalRecords} Documenti"
                [rowsPerPageOptions]="[10, 25, 50]" dataKey="codice">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Data</th>
                        <th>Ora</th>
                        <th>Utente</th>
                        <th>CUP</th>
                        <th>Data</th>
                        <th>Ora</th>
                        <th>Utente</th>
                        <th>Descrizione</th>
                        <th>Sezione</th>
                        <th>Tipologia</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-documento>
                    <tr class="riga-documenti" [pSelectableRow]="documento">
                        <td>{{ documento.archiviatoil | date }}</td>
                        <td>{{ documento.archiviatoil | date:'HH:mm' }}</td>
                        <td>{{ documento.lavoratoda }}</td>
                        <td>{{ documento.codicecup }}</td>
                        <td>{{ documento.lavoratoil | date }}</td>
                        <td>{{ documento.lavoratoil | date:'HH:mm' }}</td>
                        <td>{{ documento.archiviatoda }}</td>
                        <td>{{ documento.descrizionebozza }}</td>
                        <td>{{ documento.entity_tipo | TipoEntitaPipe }}</td>
                        <td>{{ documento.nomemodello }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-card>
    </p-tabPanel>
</p-tabView>

<!-- Questa sarà la form per la lavorazione dei documenti-->
<p-dialog [(visible)]="showLavoraDocumento" [resizable]="true" header="Documento preso in carico" class="p-dialog-full"
    [modal]="true" [draggable]="true" [maximizable]="true" (onHide)="cancelDocumentoCollegato()">

    <upload-file *ngIf="datiModelloDocumentale?.formGroup && showLavoraDocumento"
        nomeModello="Documento preso in carico" [datiModelloDocumentale]="datiModelloDocumentale" [header]="header"
        (eventDropdown)="onChangeItemDropdown($event)" (close)="showLavoraDocumento = false"
        (onClickBtn)="onClickBtn($event)" [isScenarioCartellaMonitorata]="true"></upload-file>

</p-dialog>
<p-toast></p-toast>