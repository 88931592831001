  <p-card>
    <form [formGroup]="formUtente">
      <div class="containerLoginForm">
        <div class="row">
          <div class="form-group col-md-10">
            <label for="userName" class="col-form-label">Username</label>
            <input formControlName="userName" type="text" class="form-control" placeholder="Email di registrazione">
            <!-- <small style="color: #dc3545;" *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">
              Inserisci il numero</small> -->
          </div>
          <div class="form-group col-md-1">
            <label for="isLocked" class="col-form-label">Bloccato</label>
            <p-checkbox formControlName="isLocked" [binary]="true" inputId="binary"></p-checkbox>
            <!-- <small style="color: #dc3545;" *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">
              Inserisci il numero</small> -->
            </div>

          <div class="form-group col-md-4">
            <label for="qualification" class="col-form-label">Titolo</label>
            <input formControlName="qualification" type="text" class="form-control" placeholder="Titolo">
            <!-- <small style="color: #dc3545;" *ngIf="!loginForm.get('data').valid && loginForm.get('data').touched">
              Inserisci una data</small> -->
            </div>
            <div class="form-group col-md-4">
              <label for="firstName" class="col-form-label">Nome</label>
              <input formControlName="firstName" type="text" class="form-control" placeholder="Nome">
              <!-- <small style="color: #dc3545;" *ngIf="!loginForm.get('data').valid && loginForm.get('data').touched">
                Inserisci una data</small> -->
              </div>
              
          <div class="form-group col-md-4">
            <label for="lastName" class="col-form-label">Cognome</label>
            <input formControlName="lastName" type="text" class="form-control" placeholder="Cognome">
            <!-- <small style="color: #dc3545;" *ngIf="!loginForm.get('data').valid && loginForm.get('data').touched">
              Inserisci una data</small> -->
          </div>
          
          <div class="form-group col-md-6">
            <label for="fiscalCode" class="col-form-label">Codice Fiscale/Partita Iva</label>
            <input formControlName="fiscalCode" type="text" class="form-control" placeholder="Codice Fiscale/Partita Iva"
            oninput="this.value = this.value.toUpperCase()">
            <small style="color: #dc3545;" *ngIf="!formUtente.get('fiscalCode').valid && formUtente.get('fiscalCode').touched">
              Inserisci un codice fiscale valido</small>
            </div>

            <div class="form-group col-md-6">
              <label for="address" class="col-form-label">Indirizzo</label>
              <input formControlName="address" type="text" class="form-control" placeholder="Indirizzo">
            </div>

          <div class="form-group col-md-8">
            <label for="city" class="col-form-label">Città</label>
            <input formControlName="city" type="text" class="form-control" placeholder="Città">
          </div>
          
          <div class="form-group col-md-4">
            <label for="province" class="col-form-label">Provincia</label>
            <input formControlName="province" type="text" class="form-control" placeholder="Provincia">
          </div>
          

          <div class="form-group col-md-6">
            <label for="phoneNumber" class="col-form-label">Telefono fisso</label>
            <input formControlName="phoneNumber" type="tel" class="form-control" placeholder="Telefono Fisso">
            <small style="color: #dc3545;" *ngIf="!formUtente.get('phoneNumber').valid && formUtente.get('phoneNumber').touched">
              Inserisci un numero di telefono valido</small>
          </div>
          
          <div class="form-group col-md-6">
            <label for="mobilePhoneNumber" class="col-form-label">Cellulare</label>
            <input formControlName="mobilePhoneNumber" type="tel" class="form-control" placeholder="Cellulare">
            <small style="color: #dc3545;" *ngIf="!formUtente.get('mobilePhoneNumber').valid && formUtente.get('mobilePhoneNumber').touched">
              Inserisci un numero di cellulare valido</small>
            </div>
          </div>

        <div class="footer_button">
          <p-toast></p-toast>
          <button pButton label="REGISTRATI" class="p-button-success" [disabled]="formUtente.invalid" (click)="createUser()"></button>
        </div>
      </div>

    </form>
  </p-card>  