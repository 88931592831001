<div class="d-flex flex-row">
  <div class="flex-1 m-2">
    <div class="header row titolone">
      <div class="col-md-9">
      </div>
      <div class="col-md-3" style="float:right">
        <button style="float:right" pButton label="PROFESSIONISTA" icon="pi pi-plus"
          (click)="showDialogForm()"></button>
      </div>
    </div>

    <p-dialog *ngIf="visible" header="Professionista" [(visible)]="visible" [style]="{width: '85vw'}" [modal]="true"
      [draggable]="false">
      <app-professionista-form [cupId]="cupId" [isInEdit]="isInEdit" [id]="id" [visible]="visible"
        (closeDialog)="onClose($event)"></app-professionista-form>
    </p-dialog>

    <p-card>
      <p-table [value]="filteredTab" selectionMode="single" [(selection)]="selectedProfessionista" dataKey="codice"
        [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
        currentPageReportTemplate="Da {first} a {last} di {totalRecords} professionisti"
        [rowsPerPageOptions]="[5, 10, 25, 50]">

        <ng-template pTemplate="header">
          <tr>
            <th>Ruolo</th>
            <th>Nome</th>
            <th>CF/PI</th>
            <th>Email</th>
            <th>Telefono</th>
            <th>Importo</th>
            <th>Atto</th>
            <th style="width: 5%;"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-professionista>
          <tr [pSelectableRow]="professionista" (dblclick)="selectedRow(professionista.id)">
            <td>{{ professionista?.ruolo }}</td>
            <td>{{ professionista?.nome }}</td>
            <td>{{ professionista?.pi_cf }}</td>
            <td>{{ professionista?.email }}</td>
            <td>{{ professionista?.telefono }}</td>
            <td>{{ professionista?.importoNetto + professionista?.iva + professionista?.cassa }}</td>
            <td>{{ professionista?.atto }}</td>
            <td>
              <div style="display: flex;">
                <p-button type="button" icon="pi pi-pencil" styleClass="p-button-text"
                  (click)="showDialogForm(professionista.id)"></p-button>
                <p-button type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger"
                  (click)="confirm(professionista.id)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>

  </div>

</div>