import { Inject, Injectable } from '@angular/core';
import { Scadenza } from '../model/scadenza';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../basehttp.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ScadenzeService {
  BASE_URL = ''
  ALARMS_URL = 'api/Alarms'

  constructor(
    @Inject('BASE_URL')
    baseUrl: string,
    private http: HttpClient,
    private apiClient: BaseHttpService
  ) {
    this.BASE_URL = baseUrl
    this.ALARMS_URL = baseUrl + this.ALARMS_URL
  }

  deleteScadenza(id: number): Observable<any> {
    return this.http.delete(`${this.ALARMS_URL}/${id}`)
  }

  getScadenzeByCup(cupId: number): Observable<Scadenza[]> {
    return this.http.get<Scadenza[]>(`${this.ALARMS_URL}/cup/${cupId}`);
  }


    getAllScadenze(page?: number, size?: number): Observable<Scadenza[]> {
    page = page ?? 1;
    size = size ?? 50;
    return this.http.get<Scadenza[]>(`${this.ALARMS_URL}?page=${page}&size=${size}`);
  }

  getScadenza(id: number): Observable<Scadenza> {
    return this.http.get<Scadenza>(`${this.ALARMS_URL}/${id}`);
  }
  updateScadenza(scadenza: Scadenza): Observable<Scadenza> {
    return this.http.put<Scadenza>(`${this.ALARMS_URL}/${scadenza.id}`, scadenza);
  }
  createScadenza(scadenza: Scadenza): Observable<Scadenza> {
    return this.http.post<Scadenza>(`${this.ALARMS_URL}`, scadenza)
  }
};
