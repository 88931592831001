import { Pipe, PipeTransform } from '@angular/core';
import { ModelloDocumentaleKeys } from '../model/documento';

@Pipe({ name: 'keyTypePipe' })
export class KeyTypePipe implements PipeTransform {
  transform(tipo: string, keyValue: string): string {
    let value: any;
    switch (tipo) {
      case 'date':
        value = keyValue;
        break;
      default:
        value = keyValue.toString();
        break;
    }
    return value;
  }
}
