import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class UserLogin {
  userId: string;
  username: string;
  firstname: string;
  cognome: string;
  roles: string;
  expires: string;
  token: string;
  presidi: [];
}

export class Login {
  userName: string;
  password: string;

  constructor() {
    this.userName = '';
    this.password = '';
  }
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  BASE_URL = '';
  AUTH_URL = '';
  USERAUTH_URL = '';

  private currentUserSubject: BehaviorSubject<UserLogin>;
  public currentUser: Observable<UserLogin>;

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient
  ) {

    let loggato = localStorage.getItem('UserAuthenticate') !== undefined ? JSON.parse(localStorage.getItem('UserAuthenticate')) : undefined;
    if (loggato) {
      this.currentUserSubject = new BehaviorSubject<UserLogin>(loggato);
    } else {
      this.currentUserSubject = new BehaviorSubject<UserLogin>(undefined);
    }

    this.currentUser = this.currentUserSubject.asObservable();
    this.BASE_URL = baseUrl;
    this.AUTH_URL = `${this.BASE_URL}api/Auth`;
    this.USERAUTH_URL = `${this.BASE_URL}api/UserAuth`
  }

  getCurrentUser(): UserLogin | null{
    let loggato = localStorage.getItem('UserAuthenticate') != null ? JSON.parse(localStorage.getItem('UserAuthenticate')) : null;
    return loggato
  }
  isLocalAuthenticated(): boolean {
    return this.getCurrentUser() != null
  }

  public get currentUserValue(): UserLogin {
    return this.currentUserSubject.value;
  }

  login(userLogin: Login): Observable<UserLogin> {
    return this.http.post<any>(`${this.AUTH_URL}/Login`, userLogin)
      .pipe(map(
        user => {
          localStorage.setItem('UserAuthenticate', JSON.stringify(user));
          this.currentUserSubject.next(user);
          return user;
        }
      ));
  }

  logout() {
    localStorage.removeItem('UserAuthenticate');
    this.currentUserSubject.next(null);
  }

  getRoles(): Observable<any> {
    return this.http.get<any>(`${this.AUTH_URL}/GetRoles`);
  }

  isAuthenticated(): Observable<any> {
    return this.http.post<any>(`${this.AUTH_URL}/IsAuthenticated`, {});
  }

  lockUser(idUser: string): Observable<boolean> {
    return this.http.post<any>(`${this.AUTH_URL}/LockUser?ID=${idUser}`, idUser);
  }

  unlockUser(idUser: string): Observable<any> {
    return this.http.post<any>(`${this.AUTH_URL}/UnLockUser?ID=${idUser}`, idUser);
  }

  /* updateUser(aggiungiUser: AddUser, id?: string): Observable<any> {
    if (id) {
      return this.http.put(`${this.AUTH_URL}/Update?Id=${id}`, aggiungiUser);
    } else {
      return this.http.post(`${this.AUTH_URL}/AddUser`, aggiungiUser);
    }
  } */

  changePass(username: string, password: string, newpassword: string): Observable<UserLogin> {
    return this.http.post<any>(`${this.AUTH_URL}/ChangePassword`, { userName: username, password: password, newPassword: newpassword });
  }

  resetPass(username: string): Observable<any> {
    return this.http.post<any>(`${this.AUTH_URL}/ResetPassword`, { userName : username  });
  }

  confirmResetPass(username: string, password: string, code: string): Observable<any> {
    return this.http.post<any>(`${this.AUTH_URL}/ConfirmResetPassword`, { userName : username, password: password, code: code  });
  }

}
