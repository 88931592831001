<div class="row">
  <h1>
    <div class="title-card pull-left">DOCUMENTI</div>

  </h1>

  <div class="d-flex flex-row justify-content-between">
    <p-multiSelect class="multiselect-modelli" [options]="modelli" [(ngModel)]="selectedModelli" p-dropdown-clearable
      optionLabel="nomeModello" (onChange)="filterBrowse(selectedModelli)" (onClear)="clearFilter()" 
      [disabled]="caricamentoGrid" [showClear]="true"></p-multiSelect>
      <!-- [overlayVisible]="caricamentoGrid" -->

    <div class="d-flex justify-content-end mb-2">
      <div>
        <button type="button" pButton pRipple icon="pi pi-file-pdf" style="height: 2.9rem;"
          (click)="createPdfDocumenti()" class="p-button-warning mr-2" tooltipPosition="bottom"
          [disabled]="caricamentoGrid"></button>
      </div>
      <div class="mr-1">
        <p-button label="Scarica Zip" icon="pi pi-download" (click)="scaricaFileZip()"
          [disabled]="caricamentoGrid"></p-button>
      </div>
      <div *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)">
        <p-button label="Carica File" icon="pi pi-upload" (click)="caricaDocumeti()" [disabled]="caricamentoGrid"></p-button>
      </div>
    </div>
  </div>

  <!-- Spiner caricamento Lista documenti/preview documenti-->
  
  <!-- <div *ngIf="caricamentoGrid" class="text-center">
    <p-progressSpinner></p-progressSpinner>
    <div> Caricamento in corso </div>
  </div> -->
  

  <p-card class="grid-documenti">
    <p-table #tableDocumenti1 *ngIf="!selectedModelli || selectedModelli && (selectedModelli.length === 0 || selectedModelli.length > 1)"  [value]="keysFiltered" 
      [paginator]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[5, 10, 25, 50]"
      currentPageReportTemplate="Da {first} a {last} di {totalRecords} documenti" [(selection)]="selectedKey" dataKey="id" 
      [resizableColumns]="true" (onSort)="customSort($event)">
     <ng-template pTemplate="header" let-columns>
       <tr class="ellipsis">
         <th pResizableColumn style="width: 2rem;">
           <p-tableHeaderCheckbox (click)="selectedFromZip(keys)"></p-tableHeaderCheckbox>
         </th>
         <th pResizableColumn pSortableColumn="nomeModello"> Modello <p-sortIcon field="nomeModello"></p-sortIcon></th>
         <th pResizableColumn pSortableColumn="protocollo"> Progressivo <p-sortIcon field="protocollo"></p-sortIcon></th>
         <th pResizableColumn pSortableColumn="key2_Value"> Data <p-sortIcon field="key2_Value"></p-sortIcon></th>
         <th pResizableColumn>Chiave/Valore</th>
         <th pResizableColumn pSortableColumn="nomeFile"> Nome File <p-sortIcon field="nomeFile"></p-sortIcon></th>
         <th pResizableColumn></th>
       </tr>
       <tr *ngIf="caricamentoGrid">
        <div class="spinner-center text-center">
          <p-progressSpinner></p-progressSpinner>
          <div> Caricamento in corso </div>
        </div>
       </tr>
     </ng-template>
     <ng-template pTemplate="body" let-key let-columns="columns">
       <tr *ngIf="!caricamentoGrid" class="ellipsis">
         <td>
           <p-tableCheckbox [value]="key" (click)="selectedFromZip(key)"></p-tableCheckbox>
         </td>
         <td [title]="key.nomeModello">{{ key.nomeModello }}</td>
         <td [title]="key.protocollo">{{ key.protocollo }}</td>
         <td [title]="key.key2_Value">{{ key.key2_Value | date:"dd/MM/yy" }}</td>
         <td class="not-ellipsis" [innerHTML]="key.allKeyValueActive"></td>
         <td [title]="key.nomeFile">{{ key.nomeFile }}</td>
         <td>
           <p-button type="button" icon="pi pi-download" styleClass="p-button-text" title="Download il documento"
             (click)="downloadFile(key.id, key.nomeFile)"></p-button>
           <p-button type="button" icon="pi pi-eye" styleClass="p-button-text" title="Visualizza il documento"
             (click)="showDialogForm(key)"></p-button>
           <p-button *ngIf="key.entityId === 0 && (!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))"
              icon="pi pi-trash" type="button" styleClass="p-button-text p-button-danger" title="Cancella File" (click)="showDialogDeleteFile(key.idDocumento)"></p-button>
         </td>
       </tr>
     </ng-template>
   </p-table>
   <p-table #tableDocumenti2 *ngIf="selectedModelli && selectedModelli.length === 1" [value]="keysFiltered" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" 
     currentPageReportTemplate="Da {first} a {last} di {totalRecords} documenti" [rowsPerPageOptions]="[5, 10, 25, 50]"
     [(selection)]="selectedKey" dataKey="id" [resizableColumns]="true" (onSort)="customSort($event)" >
     <ng-template pTemplate="header" let-key let-columns>
       <tr class="ellipsis">
         <th pResizableColumn style="width: 2rem;">
           <p-tableHeaderCheckbox (click)="selectedFromZip(keys)"></p-tableHeaderCheckbox>
         </th>
         <th pResizableColumn pSortableColumn="nomeModello"> Modello <p-sortIcon field="nomeModello"></p-sortIcon></th>
         <th pResizableColumn pSortableColumn="protocollo"> Progressivo <p-sortIcon field="protocollo"></p-sortIcon></th>
         <th pResizableColumn pSortableColumn="key2_Value"> {{dataEtichetta}} <p-sortIcon field="key2_Value"></p-sortIcon></th>
         <th pResizableColumn *ngFor="let col of columnsActive" [style.width]="getWidth(col)"
           [style.white-space]="getWhiteSpace(col)" pSortableColumn="{{col.field}}">
           {{col.header}} <p-sortIcon field="{{col.field}}"></p-sortIcon>
         </th>
         <th pResizableColumn pSortableColumn="nomeFile"> Nome File <p-sortIcon field="nomeFile"></p-sortIcon></th>
         <th pResizableColumn></th>
       </tr>
       <tr *ngIf="caricamentoGrid">
        <div class="spinner-center text-center">
          <p-progressSpinner></p-progressSpinner>
          <div> Caricamento in corso </div>
        </div>
       </tr>
     </ng-template>
     <ng-template pTemplate="body" let-key let-columns="columns">
       <tr class="ellipsis">
         <td>
           <p-tableCheckbox [value]="key" (click)="selectedFromZip(key)"></p-tableCheckbox>
         </td>
         <td [title]="key.nomeModello">{{ key.nomeModello }}</td>
         <td [title]="key.protocollo">{{ key.protocollo }}</td>
         <td [title]="key.key2_Value">{{ key.key2_Value | date:"dd/MM/yy" }}</td>
         <td *ngFor="let col of columnsActive" [title]="key[col.field]">
           {{(key.key1_Value === "Quietanza" && key[col.field] === "null" ) ? '' :
           (key[col.header] === "Importo" ) ?
           (key[col.field] | currency: 'EUR':'symbol':undefined:'it-IT') : key[col.field]}}
         </td>
         <td [title]="key.nomeFile">{{ key.nomeFile }}</td>
         <td>
           <p-button type="button" icon="pi pi-download" styleClass="p-button-text" title="Download il documento"
             (click)="downloadFile(key.id, key.nomeFile)"></p-button>
           <p-button type="button" icon="pi pi-eye" styleClass="p-button-text" title="Visualizza il documento"
             (click)="showDialogForm(key)"></p-button>
           <p-button *ngIf="key.entityId === 0 && (!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator))" 
            icon="pi pi-trash" type="button" styleClass="p-button-text p-button-danger" title="Cancella File"
             (click)="showDialogDeleteFile(key.idDocumento)"></p-button>
         </td>
       </tr>
     </ng-template>
   </p-table>
  </p-card>
</div>

<p-dialog *ngIf="visibleForm" [header]="header" [(visible)]="visibleForm" class="p-dialog-full" [modal]="true"
  [draggable]="false" [resizable]="true" (onHide)="onCloseFormCaricaFile()" [maximizable]="true">

  <upload-file *ngIf="visibleForm && datiModelloDocumentale?.formGroup" [nomeModello]="nomeModello"
    [datiModelloDocumentale]="datiModelloDocumentale" [buttonDocumenti]="buttonDocumenti" [hasSystemModello]="hasSystemModello"
    [isEnabledLoadFile]="isEnabledLoadFile" [nomeModelliDocumenti]="nomeModelliDocumenti" [cup]="cup" [user]="user" [permesso]="permesso"
    [idSezione]="sezione.id" (eventDropdown)="onChangeItemDropdown($event)" (close)="closeDialog($event)">
  </upload-file>
</p-dialog>

<p-toast></p-toast>
<p-confirmDialog [visible]="isShowDialogDeleteDoc" [style]="{width: '25vw'}" class="p-dialog-style"></p-confirmDialog>