import { Pipe, PipeTransform } from '@angular/core';
import { TipoPermesso } from '../model/utente';

@Pipe({ name: 'TipoPermessoPipe' })
export class TipoPermessoPipe implements PipeTransform {
  transform(value: number): string {
    let permesso: string;
    switch (TipoPermesso[value]) {
      case 'None':
        permesso = 'Nessun Permesso';
        break;
      case 'Read':
        permesso = 'Lettura';
        break;
      case 'ReadWrite':
        permesso = 'Lettura/Scrittura/Modifica';
        break;
      case 'ReadWriteDelete':
        permesso = 'Lettura/Scrittura/Modifica/Cancellazione';
        break;
      default:
        permesso = '';
        break;
    }
    return permesso;
  }
}

@Pipe({ name: 'PermessoPipe' })
export class PermessoPipe implements PipeTransform {
  transform(value: string): number {
    let tipoPermesso: number;
    switch (value) {
      case 'Nessun Permesso':
        tipoPermesso = TipoPermesso.None;
        break;
      case 'Lettura':
        tipoPermesso = TipoPermesso.Read;
        break;
      case 'Lettura/Scrittura/Modifica':
        tipoPermesso = TipoPermesso.ReadWrite;
        break;
      case 'Lettura/Scrittura/Modifica/Cancellazione':
        tipoPermesso = TipoPermesso.ReadWriteDelete;
        break;
      default:
        tipoPermesso = null;
        break;
    }
    return tipoPermesso;
  }
}
@Pipe({ name: 'AreaPipe' })
export class AreaPipe implements PipeTransform {
  transform(value: number): string {
    let area: string;
    switch (value) {
      case 1:
        area = 'CUP';
        break;
      case 2:
        area = 'QUADRO ECONOMICO';
        break;
      case 3:
        area = 'ESECUZIONE';
        break;
      case 4:
        area = 'PROFESSIONISTI INCARICATI';
        break;
      case 5:
        area = 'DOCUMENTI';
        break;
      case 6:
        area = 'FONTI FINANZIAMENTO';
        break;
      case 7:
        area = 'PROMEMORIA';
        break;
      case 8:
        area = 'ANNOTAZIONI';
        break;
      default:
        break;
    }
    return area;
  }
}