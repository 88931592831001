
<div class="row contenitore">
  <div class="header">

    <h1 class="title-card">LISTA UTENTI</h1>
    <button pButton (click)="showDialog()" label="INVITA UTENTE" icon="pi pi-plus" style="height: 2.25em;"></button>

  </div>

  <!-- <p-dialog *ngIf="visible" [header]="selectedUtente ? 'Modifica Utente' : 'Nuovo Utente'"  [(visible)]="visible" [style]="{width: '85vw'}" [modal]="true"
    [draggable]="false">
    <app-utente [clientId]="user.clientId" [isInEdit]="isInEdit" [visible]="visible" [utente]="selectedUtente" (closeDialog)="onClose($event)"></app-utente>
  </p-dialog> -->
  
  <p-dialog *ngIf="visible" header="Invita utente"  [(visible)]="visible" [style]="{width: '50vw'}" [modal]="true"
    [draggable]="false">
    <app-invita-form [visible]="visible" (closeDialog)="onClose($event)"></app-invita-form>
  </p-dialog>

  <p-card>
    <p-table
      [value]="filteredTab"
      [paginator]="true"
      [rows]="15"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="Da {first} a {last} di {totalRecords} utenti"
      [rowsPerPageOptions]="[15, 25, 50]" dataKey="idUtente"
      >

      <ng-template pTemplate="header">
        <tr>
          <th style="width: 4%;">Utente Bloccato</th>
          <th style="width: 4%;">Utente Attivo</th>
          <th>Creato il</th>
          <th>Titolo</th>
          <th>Cognome</th>
          <th>Nome</th>
          <th>C.F./P.Iva</th>
          <th>Indirizzo</th>
          <th>Città</th>
          <th>Provincia</th>
          <th>Email</th>
          <th>Telefono</th>
          <th>Cellulare</th>
          <th>Reset</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-utente>
        <tr>
          <td><p-checkbox [(ngModel)]="utente.isLocked" [binary]="true" (ngModelChange)="confirmLocked(utente)" inputId="binary" ></p-checkbox></td>
          <td><p-checkbox [(ngModel)]="utente.flagAttivo" [binary]="true" (ngModelChange)="confirmAttivo(utente)" inputId="binary"></p-checkbox></td>
          <td>{{ utente.createdAt }}</td>
          <td>{{ utente.qualification }}</td>
          <td>{{ utente.lastName }}</td>
          <td>{{ utente.firstName }}</td>
          <td>{{ utente.fiscalCode }}</td>
          <td>{{ utente.address }}</td>
          <td>{{ utente.city }}</td>
          <td>{{ utente.province }}</td>
          <td>{{ utente.userName }}</td>
          <td>{{ utente.phoneNumber }}</td>
          <td>{{ utente.mobilePhoneNumber }}</td>
          <td></td>


          <!-- <td [pTooltip]="utente.titolo">{{ (utente.titolo.length>6)? (utente.titolo | slice:0:20)+'...':(utente.titolo) }}</td>
          <td [pTooltip]="utente.descrizione">{{ (utente.descrizione.length>6)? (utente.descrizione | slice:0:40)+'...':(utente.descrizione) }}</td>
          <td>{{ utente.importo | currency: 'EUR':'symbol':undefined:'it-IT'}}</td> -->
        </tr>
      </ng-template>
    </p-table>
  </p-card>


</div>

  <!-- [routerLink]="['cup/', cup.id]" -->

  <p-toast></p-toast>
  <p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
  