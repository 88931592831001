import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { UserClientService } from 'src/app/service/user-client.service';

export function passwordValidator(control: AbstractControl): ValidationErrors | null {
  const password = control.value;

  // Verifica se la password contiene almeno 8 caratteri
  if (!password || password.length < 8) {
    return { tooShort: true };
  }

  // Verifica se contiene almeno una lettera maiuscola
  const hasUpperCase = /[A-Z]/.test(password);
  if (!hasUpperCase) {
    return { missingUpperCase: true };
  }

  // Verifica se contiene almeno un numero
  const hasNumber = /\d/.test(password);
  if (!hasNumber) {
    return { missingNumber: true };
  }

  // Verifica se contiene almeno un carattere speciale
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  if (!hasSpecialChar) {
    return { missingSpecialChar: true };
  }

  // Se tutte le condizioni sono soddisfatte, il Validator restituisce null (nessun errore)
  return null;
}

export function matchPasswordValidator(newpassword: string, confirmnewpassword: string): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const passwordControl = formGroup.get(newpassword);
    const confirmPasswordControl = formGroup.get(confirmnewpassword);

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    // Verifica se le due password coincidono
    if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordMismatch']) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ passwordMismatch: true });
    } else {
      confirmPasswordControl.setErrors(null);
    }

    return null;
  };
}

@Component({
  selector: 'app-confirm-reset-password',
  templateUrl: './confirm-reset-password.component.html',
  styleUrls: ['./confirm-reset-password.component.scss']
})
export class ConfirmResetPasswordComponent implements OnInit {

  conifrmResetPwdForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  code: string = '';
  email: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private userClientService: UserClientService,
    private router: Router,
  ) {
    this.conifrmResetPwdForm = this.formBuilder.group({
      newpassword: ['', [passwordValidator, Validators.required]],
      confirmnewpassword: ['', [Validators.required]]
    },
    { validator: matchPasswordValidator('newpassword', 'confirmnewpassword') }
    );

    route.queryParams.subscribe(
      params => {
        this.code = params['code'];
        this.email = params['email'];
      });

  }

  ngOnInit() {

  }

  get f() { return this.conifrmResetPwdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.conifrmResetPwdForm.invalid) {
      return;
    }
    this.loading = true;
    this.spinner.show()
    this.userClientService.confirmResetPass(this.email,this.f.newpassword.value, this.code).subscribe(
      res => {
        setTimeout(() => {this.spinner.hide();}, 500);
        this.loading = false;
        this.messageService.add({
          severity: "info",
          summary: "Reset Password",
          detail: "Password correttamente modificata",
          sticky: true
        });
        this.router.navigate(['/login']);
      },
      err => {
        this.messageService.add({
          severity: "error",
          summary: "Reset Password",
          detail: "Errore: " + err
        });
        setTimeout(() => {this.spinner.hide();}, 500);
        this.loading = false;
      });
  }

}
