import { Inject, Injectable } from '@angular/core';
import { Annotazione, AnnotazioneResponse, AnnotazioniResponse } from '../model/annotazione';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../basehttp.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AnnotazioniService {

  BASE_URL = ''
  ANNOTATIONS_URL = 'api/Annotations'

 constructor(
   @Inject('BASE_URL') baseUrl: string,
   private http: HttpClient,
   private apiClient: BaseHttpService
 ) {
   this.BASE_URL = baseUrl
   this.ANNOTATIONS_URL = baseUrl + this.ANNOTATIONS_URL
 }

  deleteAnnotazione(id:number) : Observable<AnnotazioneResponse>{
    return this.http.delete<AnnotazioneResponse>(`${this.ANNOTATIONS_URL}/${id}`)
  }
  getAnnotazioniByCup(cupId: number) : Observable<AnnotazioniResponse> {
    return this.http.get<AnnotazioniResponse>(`${this.ANNOTATIONS_URL}/cup/${cupId}`);
  }
  getAnnotazione(id: number) : Observable<AnnotazioneResponse> {
    return this.http.get<AnnotazioneResponse>(`${this.ANNOTATIONS_URL}/${id}`);
  }
  updateAnnotazione(annotazione: Annotazione) : Observable<AnnotazioneResponse> {
    return this.http.put<AnnotazioneResponse>(`${this.ANNOTATIONS_URL}/${annotazione.id}`, annotazione);
  }
  createAnnotazione(annotazione: Annotazione) : Observable<AnnotazioneResponse>{
    return this.http.post<AnnotazioneResponse>(`${this.ANNOTATIONS_URL}`, annotazione)
  }
}
