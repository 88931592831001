import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Scadenza } from 'src/app/model/scadenza';
import { ScadenzeService } from 'src/app/service/scadenze.service';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { Ruolo, TipoPermesso, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';



interface Options {
  days: number;
  label: string;
}

@Component({
  selector: 'app-cup-promemoria-form',
  templateUrl: './cup-promemoria-form.component.html',
  styleUrls: ['./cup-promemoria-form.component.scss']
})

export class CupPromemoriaFormComponent implements OnChanges {

  @Input() scadenza: Scadenza;
  @Input() isInEdit: boolean;
  @Input() visible: boolean;
  /**
     * Permesso in base all'utente loggato
     */
  @Input() public permesso: TipoPermesso;
  /**
   * Utente loggato
   */
	@Input() public user: Utente;

  @Output() closeDialogPromemoria = new EventEmitter<boolean>();

  scadenzaForm: FormGroup = new FormGroup({
    cupId: new FormControl(),
    id: new FormControl(0),
    data: new FormControl<Date | null>(null, Validators.required),
    descrizione: new FormControl('', Validators.required),
    alertAt: new FormControl<Options | null>(null, Validators.required),
    emails: new FormControl<string[] | null>(null, Validators.required)
  });

  options: Options[] = [
    { days: 1, label: "1 giorno prima" },
    { days: 2, label: "2 giorni prima" },
    { days: 7, label: "1 settimana prima" },
    { days: 30, label: "1 mese prima" },
  ]

  scadenzeTab: Scadenza[];
  separatorExp = ",";

  isId: boolean;
  cupId: number;
  selectedDate: number[];
  ruolo: string;
  public TipoPermesso = TipoPermesso;
	public Ruolo = Ruolo;
	public RuoloPipe = new RuoloPipe();

  constructor(
    private route: ActivatedRoute,
    private scadenzeServices: ScadenzeService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.initForm();
  }

  selectedDateFormat() {
    return "ciao"
  }

  initForm() {
    this.isId = this.route.snapshot.paramMap.get('id') ? true : false;
    this.cupId = Number(this.route.snapshot.paramMap.get('id'));
    if (this.scadenza) {
      this.spinner.show()
      this.isInEdit = true;
      this.scadenzeServices.getScadenza(this.scadenza.id).subscribe({
        next: (resp: Scadenza) => {
          this.scadenza = resp
          this.scadenzaForm.setValue({
            cupId: this.scadenza.cupId,
            id: this.scadenza.id,
            data: new Date(this.scadenza.data),
            descrizione: this.scadenza.descrizione || "",
            alertAt: this.scadenza.alertAt,
            emails: this.scadenza.emails
          });
           this.spinner.hide()
          this.scadenzaForm.disable();
        },
        error(err) {
            console.log(err)
            this.spinner.hide()
            this.scadenzaForm.disable()
        },
      })

    } else {
      this.isInEdit = false;

      this.scadenzaForm = new FormGroup({
        cupId: new FormControl(this.cupId),
        id: new FormControl<number | null>(0),
        data: new FormControl<Date | null>(null, Validators.required),
        descrizione: new FormControl('', Validators.required),
        alertAt: new FormControl<Options | null>(null, Validators.required),
        emails: new FormControl<string[] | null>(null, Validators.required)
      });
    }
  }


  add() {
    this.scadenza = this.scadenzaForm.value;
    console.log(this.scadenzaForm.value)
    console.log(this.scadenza)
    this.spinner.show();
    this.scadenzeServices.createScadenza(this.scadenza).subscribe({
      next: (resp: Scadenza) => {
        if (resp) {
          console.log(resp);
          this.scadenza = resp;
          this.spinner.hide();
        }
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Scadenza aggiunta con successo' });
        this.close(true);
      },
      error: (err) => {
        console.log(err);


        this.spinner.hide();

        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore nel salvataggio del promemoria' });
      }
    })
    //this.scadenzaForm.reset();
  }


  save() {
    let createdAt: Date = this.scadenza.createdAt;
    this.scadenza = this.scadenzaForm.value;
    this.scadenza.createdAt = createdAt;
    console.log(this.scadenza);

    this.scadenzeServices.updateScadenza(this.scadenza).subscribe({
      next: (resp: Scadenza) => {
        if (resp) {
          console.log(resp);
          this.scadenza = resp;
          this.spinner.hide();
        }
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Scadenza modificata con successo' });
        this.close(true);
      },
      error: (err) => {
        console.log(err);


        this.spinner.hide();

        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore nel salvataggio del promemoria' });

      }
    })
    this.scadenzaForm.reset();
  }

  close(update: boolean) {
    this.visible = false;
    this.closeDialogPromemoria.emit(update);
  }

  change($event) { }

}
