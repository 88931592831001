import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { CUP } from 'src/app/model/cup';
import { Fase1 } from 'src/app/model/fase1';
import { ModelloDocumentaleCustom, Scenario } from 'src/app/model/scenari';
import { CupService } from 'src/app/service/cup.service';
import { DocumentiService } from 'src/app/service/documenti.service';
import { IndiceTab, ScenarioService } from 'src/app/service/scenario.service';
import { SpeseService } from 'src/app/service/spese.service';
import * as _ from 'lodash';
import { FontiService } from 'src/app/service/fonti.service';
import { Router } from '@angular/router';
import {
  NavigazioneDaDocumenti,
  SharedService,
} from 'src/app/service/shared.service';
import { QEService } from 'src/app/service/qe.service';

export enum DraftDocumentDestination {
  NotSaved,
  CUP,
  QE,
  Documents,
  FonteFinanziamento,
  FonteFinanziamentoIncassi,
  FonteFInanziamentoDocumenti,
  Fase1,
  Fase2,
  Fase3,
  Fase4,
  Fase5,
}

export class DocumentiDaArchiviareBrowseResponse {
  success: boolean;
  dtos: DocumentiDaArchiviareBrowse[];
}

export class DocumentiArchiviatiBrowseResponse {
  success: boolean;
  dtos: DocumentiArchiviatiBrowse[];
}

export class DocumentiDaArchiviareBrowse {
  id: number;
  nomeFile: string;
  path: string;
  descrizione: string;
  draftDocumentDestination: DraftDocumentDestination;
  worked: boolean;
  uploadBy: string;
  uploadAt: Date;
  workedBy: string;
  workedAt: Date;
  cup: string; // Codice Cup
  folder: string;
  idDocumentoMaster: string;
}

export class DocumentiArchiviatiBrowse {
  documentobozzaid: number;
  documentomasterid: number;
  cupid: number;
  codicecup: string;
  lavoratoil: Date;
  lavoratoda: string;
  entity_id: number;
  entity_tipo: number;
  descrizionebozza: string;
  archiviatoil: Date;
  archiviatoda: string;
  nomemodello: string;
}

export class DraftSearch {
  codiceCup: string;
  includiSenzaCup: boolean;
  pageIndex: number;
  pageSize: number;
}

@Component({
  selector: 'app-documenti-sospesi',
  templateUrl: './documenti-sospesi.html',
  styleUrls: ['./documenti-sospesi.scss'],
})
export class DocumentiSospesiComponent implements OnInit {
  /**
   * Dati modello documentale
   */
  datiModelloDocumentale: ModelloDocumentaleCustom;
  /**
   * Mostra la modale per poter lavorare il documento
   */
  showLavoraDocumento: boolean = false;

  selectedDocumento: DocumentiDaArchiviareBrowse;
  /**
   * Lista dei cup da nostrare in tendina e da poter selezionare
   */
  public cups: CUP[] = [];
  /**
   * id Del cup selezionato, non appena pronto da agganciare al selected documento !!! ALessia
   */
  public idCup: number;
  /**
   * Cup seleionato
   */
  public cup: CUP;
  /**
   * Spesa selezionata
   */
  public spesa: Fase1;
  /**
   * Lista dei documenti recuperati da lavorare
   */
  public documentBrowse: DocumentiDaArchiviareBrowse[] = [];

  public allListaDocumenti: DocumentiDaArchiviareBrowse[] = [];
  /**
   * Lista dei documenti recuperati Archiviati
   */
  public documentBrowseArchiviati: DocumentiArchiviatiBrowse[] = [];
  /**
   * Cup già selezionato
   */
  public cupDaCartellaMonitorata: CUP;
  /**
   * Intestazione se il cup è già selezionato
   */
  public header: string = '';
  /**
   * Filtro per la griglia
   */
  public selectedCup: CUP;
  /**
   * Flag, includi senza Cup
   */
  public includiSenzaCup: boolean = false;
  /**
   * CUp filtrati nellì'autoComplite
   */
  public filteredCups: CUP[] = [];

  first = 0;
  rows = 10;

  caricamentoGrid = false;

  constructor(
    private _documentiService: DocumentiService,
    private _scenarioServices: ScenarioService,
    private _cupServices: CupService,
    private _qeService: QEService,
    private _speseService: SpeseService,
    private _fontiServices: FontiService,
    private _route: Router,
    private _sharedServices: SharedService,
    private _spinner: NgxSpinnerService
  ) {}

  async ngOnInit(): Promise<void> {
    this._sharedServices.setAnteprimaDocumentoCartellaMonitorata(
      false,
      undefined
    );
    this.caricamentoGrid = true;
    const idCupLocalStorage = localStorage.getItem('idCup');
    if (idCupLocalStorage) {
      this.idCup = Number(idCupLocalStorage);
    }
    // Se provengo dallo scenario in cui già mi rovo dentro un CUP e voglio filtrarlo, lo recuper dalla URL
    // Per poter filtrare anche i documenti da lavorare del solo cuo selezionato !!! Alessia ricorda do foltrare per CUp
    // o senza cup
    const listaCup = await this._cupServices.getAllCups().toPromise();
    if (listaCup && listaCup.length > 0) {
      this.cups = listaCup;
      if (this.idCup) {
        this.cupDaCartellaMonitorata = this.cups.find(
          (x) => x.id === this.idCup
        );
        this.selectedCup = this.cupDaCartellaMonitorata;
        this.filterBrowse();
      } else {
        const response = await this._documentiService.getDraftDocumentAsync().toPromise();
        if(response && response.success){
          this.allListaDocumenti = response.dtos;
          this.documentBrowse = this.allListaDocumenti || [];
        }
      }
    }

    const response = await this._documentiService.getDraftWorkedDocument().toPromise();
    if(response && response.success){
      this.documentBrowseArchiviati = response.dtos || [];
    }
    this.caricamentoGrid = false;
  }

  /**
   * Filtra la griglia in base ai modelli selezionati
   * @param selectedModelli  modelli selezionati nel filtro
   */
  public async filterBrowse() {
    this.caricamentoGrid = true;
    delete this.documentBrowse;
    const filtri: DraftSearch = new DraftSearch();
    filtri.codiceCup = (this.selectedCup && this.selectedCup?.codice) || '';
    filtri.includiSenzaCup = !!this.includiSenzaCup;
    filtri.pageIndex = this.first;
    filtri.pageSize = this.rows;
    const response = await this._documentiService.getSearchDraftDocument(filtri).toPromise();
    if (response && response.success) {
      this.documentBrowse = response.dtos;
    }
    this.caricamentoGrid = false;
  }

  public clearCupFilter() {
    delete this.selectedCup;
    this.filterBrowse();
  }

  public filterCup(event) {
    let cup: CUP[] = [];
    let query = event.query;

    for (let i = 0; i < this.cups.length; i++) {
      let country = this.cups[i];
      if (country.codice.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        cup.push(country);
      }
    }

    this.filteredCups = cup;
  }


    /**
     * Selezione della riga della tabella per prendere il carico il documento da lavorare
     * @param documento 
     */
    async prendiInCaricoDocumento(documento: DocumentiDaArchiviareBrowse) {
      this.datiModelloDocumentale = this._scenarioServices.getModelloDocumentaleCustom(Scenario.DocumentiDaArchiviareBrowse);
      const formGroup = this._scenarioServices.getFormGroup(Scenario.DocumentiDaArchiviareBrowse);
      this.datiModelloDocumentale.isFrom = Scenario.DocumentiDaArchiviareBrowse;
      this.datiModelloDocumentale.isEdit = true;
      this.datiModelloDocumentale.entityID = documento.id;
      this.datiModelloDocumentale.documentoID = documento.id;
        
      this._sharedServices.setAnteprimaDocumentoCartellaMonitorata(true, documento.id);
         
      const cup = this.getDatiFatturaByName('cup');
      if(!this.cupDaCartellaMonitorata){
        const cupDaCercare = this.cups.find(c => c.codice === documento.cup);
          if(cupDaCercare){
            this.cupDaCartellaMonitorata = cupDaCercare;
            this.idCup = this.cupDaCartellaMonitorata?.id;
            this.header = `CUP ${documento.cup}`;
          }
      }

    if (!this.idCup) {
      cup.visible = true;
    }

    cup.lista = this.cups.map((x) => {
      return { idCup: x.id, descrizione: `${x.codice} - ${x.descrizione}` };
    });

    const sezioni = this.getDatiFatturaByName('sezione');
    if (!this.cupDaCartellaMonitorata) {
      const cupDaCercare = this.cups.find((c) => c.codice === documento.cup);
      if (cupDaCercare) {
        this.cupDaCartellaMonitorata = cupDaCercare;
        this.idCup = this.cupDaCartellaMonitorata?.id;
        this.header = `CUP ${documento.cup}`;
        sezioni.lista = [
          { descrizione: 'Spesa' },
          { descrizione: 'Documento' },
          { descrizione: 'Fonte di Finanziamento' },
        ];
      } else {
        sezioni.lista = [
          { descrizione: 'Cup' },
          { descrizione: 'Spesa' },
          { descrizione: 'Documento' },
          { descrizione: 'Fonte di Finanziamento' },
        ];
      }
    } else {
      this.header = this.cupDaCartellaMonitorata
        ? `CUP ${this.cupDaCartellaMonitorata.codice}`
        : '';
      sezioni.lista = [
        { descrizione: 'Spesa' },
        { descrizione: 'Documento' },
        { descrizione: 'Fonte di Finanziamento' },
      ];
    }

    formGroup.get('cup').setValue(this.cupDaCartellaMonitorata);
    this.datiModelloDocumentale.formGroup = formGroup;
    this.showLavoraDocumento = true;
  }

public cancelDocumentoCollegato(){
  this._sharedServices.deleteParametriNavigaDaDocumentiArchviazione();
  this._sharedServices.setAnteprimaDocumentoCartellaMonitorata(false, undefined);
}

  /**
   * Click del bottone per poter agganciare il documento allo scenario e cu scelto
   * @param event
   */
  public onClickBtn(event: { formGroup: FormGroup<any>; item: string }) {
    const sezione = event.formGroup.controls['sezione'].value;
    const sezioneSpesa = event.formGroup.controls['sottoSezioneSpesa'].value;
    const sezioneFonti = event.formGroup.controls['sottoSezioneFonte'].value;
    const idSpesa = event.formGroup.controls['spese'].value;
    const idFonte = event.formGroup.controls['fonti'].value;

    const dati = new NavigazioneDaDocumenti();
    dati.documentoID = this.datiModelloDocumentale?.entityID;
    dati.sezione = sezione;
    dati.sottoSezione =
      sezione === 'Spesa'
        ? sezioneSpesa
        : sezione === 'Fonte di Finanziamento'
        ? sezioneFonti
        : undefined;
    dati.idSottoSezione =
      sezione === 'Spesa'
        ? idSpesa
        : sezione === 'Fonte di Finanziamento'
        ? idFonte
        : undefined;
    switch (sezione) {
      case 'Cup':
        this.datiModelloDocumentale.isFrom = Scenario.Cup;
      break;
      case 'Fonte di Finanziamento': 
        if (sezioneFonti === 'Fonte di finaziamento') {
          this.datiModelloDocumentale.isFrom = Scenario.Fonte;
        }
        break;
      case 'Spesa':
        if (sezioneSpesa === 'Spesa') {
          this.datiModelloDocumentale.isFrom = Scenario.Spesa;
        }
        break;
      case 'Documento':
        this.datiModelloDocumentale.isFrom = Scenario.Documento;
      break;
    }
    this.datiModelloDocumentale.cup = this.cupDaCartellaMonitorata;
    this.datiModelloDocumentale.idCup = this.idCup;
    this._sharedServices.setParametriNavigaDaDocumentiArchviazione(dati);
    this.navigaVersoScenario(event);
  }

  /**
   * OnChange delle select
   */
  async onChangeItemDropdown(event: {
    formGroup: FormGroup<any>;
    item: string;
  }) {
    const sezioneFonti = event.formGroup.controls['sottoSezioneFonte'].value;
    const sezioneSpesa = event.formGroup.controls['sottoSezioneSpesa'].value;
    const btnAggiungi = this.getDatiFatturaByName('btnAggiungi');

    switch (event.item) {
      case 'cup':
        this.onChangeCup(event);
        break;
      case 'sezione':
        this.onChangeSezione(event);
        break;
      case 'sottoSezioneFonte':
      case 'sottoSezioneSpesa':
        event.formGroup.controls['spese'].setValue('');
        event.formGroup.controls['fonti'].setValue('');
        this.createBtn(event);
        break;
      case 'spese':
        if (event.formGroup.controls['spese'].value) {
          btnAggiungi.icon = 'plus';
          switch (sezioneSpesa) {
            case 'Spesa':
              btnAggiungi.btnLabel = 'SPESA';
              break;
            case 'Giuistificativo':
              btnAggiungi.btnLabel = 'Giuistificativo';
              break;
            case 'Liquidazione':
              btnAggiungi.btnLabel = 'Liquidazione';
              break;
            case 'Mandato':
              btnAggiungi.btnLabel = 'Mandato';
              break;
            case 'Quitanza':
              btnAggiungi.btnLabel = 'Quitanza';
              break;
          }
          btnAggiungi.visible = true;
        }
        break;
      case 'fonti':
        if (event.formGroup.controls['fonti'].value) {
          btnAggiungi.icon = sezioneFonti == 'Sostituisci fonte' ? '' : 'plus';
          btnAggiungi.btnLabel =
            sezioneFonti == 'Sostituisci fonte'
              ? 'Sostiuisci fonte'
              : 'Incasso';
          btnAggiungi.visible = true;
        }
        break;
    }
  }

  /**
   * OnChangeSezione
   * @param event
   */
  private onChangeSezione(event: { formGroup: FormGroup<any>; item: string }) {
    const sezione = event?.formGroup?.controls['sezione']?.value;
    this.cleanData(event);
    switch (sezione) {
      case 'Cup':
        this.createBtn(event);
        break;
      case 'Spesa':
        event.formGroup.controls['spese'].setValue('');
        this.getSpesaByIdCup();
        break;
      case 'Documento':
        this.createBtn(event);
        break;
      case 'Fonte di Finanziamento':
        event.formGroup.controls['fonti'].setValue('');
        this.getFontiByCupId();
        break;
      case 'Annotazione':
        this.createBtn(event);
        break;
    }
  }

  valorizzaCup(event: { formGroup: FormGroup<any>; item: string }) {
    const cup = this.getDatiFatturaByName('cup');
    cup.readonly = true;
    cup.visible = !this.cupDaCartellaMonitorata;
    event.formGroup.controls['cup'].setValue(this.cupDaCartellaMonitorata.id);
  }

  /**
   * Al cambio della Sottosezione Spesa o Fonte,
   * diamo la possibilità di selezionare il cup in cui inserirlo
   * se il cup è già salvato nel documento, questo passaggio non lo deve fare, ma deve mostrare il cup
   * senza farlo selezionare e passare al passaggio successivo
   */
  // private onChangeSezioneSpesaFonte(event: { formGroup: FormGroup<any>, item: string }){
  //     this.valorizzaCup(event);

  //     const cup = this.getDatiFatturaByName('cup');
  //     cup.visible = true;
  // }

  /**
   * Evento scatenato alla selezione del CUP
   */
  private async onChangeCup(event: {
    formGroup: FormGroup<any>;
    item: string;
  }) {
    // delete this.idCup;
    // delete this.cup;

    // const errore = this.getDatiFatturaByName('errore');
    //         if(errore){
    //             errore.visible = false;
    //         }

    this.idCup = event.formGroup.controls['cup'].value;

    // questo va messo in || con quello di sopra così quando attivo l'autocomplete non devo fre altri giri
    /// event.formGroup.controls['cup'].value?.idCup ||
    this.createBtn(event);
  }

  /**
   * Consente la navigaziove verso lo scenario scelto e apre la form
   */
  private navigaVersoScenario(event) {
    const sezione = event.formGroup.controls['sezione'].value;
    this._sharedServices.setIndex(this.getIndexSezione(sezione));
    if (sezione === 'Cup') {
      this._route.navigate(['/']);
    } else {
      this.idCup = this.idCup
        ? this.idCup
        : event.formGroup.controls['cup'].value;
      if (this.idCup) {
        this._route.navigateByUrl('cup/' + this.idCup);
      }
    }
  }

  /**
   * Get delle fonti di finanziamento by id Cup per poter aggiungere nuovo incasso o sostituire la fonte
   */
  private async getFontiByCupId() {
    this._spinner.show();
    if (this.idCup) {
      const sottoSezioneFonte = this.getDatiFatturaByName('sottoSezioneFonte');
      const fontiDatiForm = this.getDatiFatturaByName('fonti');
      fontiDatiForm.visible = false;
      const fonti = await this._fontiServices
        .getFontiFinanziamentoAttuali(this.idCup)
        .toPromise();
      if (fonti && fonti.success) {
        sottoSezioneFonte.visible = true;
        fontiDatiForm.lista = fonti.dtos.map((x) => {
          return {
            idFonte: x.id,
            descrizione: `${x.descrizione} ${x.atto} € ${x.importoFinanziamento}`,
          };
        });
      } else {
        console.log('500');
      }
    }
    this._spinner.hide();
  }

  /**
   * Se viene selezionato un impegno bisogna creare la tendina con la descrizione degli impegni
   * @param idCup
   */
  private async getSpesaByIdCup(campoErrore?: string) {
    this._spinner.show();
    if (this.idCup) {
      const sottoSezioneSpesa = this.getDatiFatturaByName('sottoSezioneSpesa');
      const cup = await this._cupServices.getCupById(this.idCup).toPromise();
      const errore = this.getDatiFatturaByName('errore');
      errore.visible = false;
      const speseDatiForm = this.getDatiFatturaByName('spese');
      if (cup?.qEs?.length > 0) {
        sottoSezioneSpesa.visible = true;

        speseDatiForm.visible = false;
        let spese: Fase1[] = [];
        const response = await this._speseService
          .getAllFasiByCupId(this.idCup)
          .toPromise();
        if (response && response.success) {
          spese = response.dtos;
        } else {
          console.log('500');
        }
        if (spese) {
          speseDatiForm.lista = spese.map((fase1) => {
            return {
              idFase1: fase1.id,
              descrizione: `${fase1.tipo} ${fase1.descrizione} ${fase1.cig} € ${fase1.importoNetto}`,
            };
          });
        }
      } else {
        sottoSezioneSpesa.visible = false;
        if (errore) {
          errore.label =
            'Non è possibile inserire la spesa, non sono presenti QE';
          errore.visible = true;
        }
      }
    }
    this._spinner.hide();
  }

  /**
   * Restituisce i dati fattura a partire dal nome
   */
  private getDatiFatturaByName(name: string) {
    return this.datiModelloDocumentale?.DatiForm?.find((x) => x.key === name);
  }

  /**
   * Setta il valore dell'index per poter gestire l'apertura della foorm scelta dall'utente
   */
  private getIndexSezione(sezione: string) {
    let index: IndiceTab;
    switch (sezione) {
      case 'Cup':
        index = IndiceTab.CUP;
        break;
      case 'Quadro Economico':
        index = IndiceTab.QE;
        break;
      case 'Spesa':
        index = IndiceTab.Esecuzione;
        break;
      case 'Documento':
        index = IndiceTab.Documenti;
        break;
      case 'Fonte di Finanziamento':
        index = IndiceTab.Fonte;
        break;
    }
    return index;
  }

  /**
   * Creazione del bottone per la navigazione verso la sezione e sotto sezione selezionata
   * @param event
   */
  private async createBtn(event) {
    const sezione = event.formGroup.controls['sezione'].value;
    const sezioneSpesa = event.formGroup.controls['sottoSezioneSpesa'].value;
    const sezioneFonti = event.formGroup.controls['sottoSezioneFonte'].value;
    const btnAggiungi = this.getDatiFatturaByName('btnAggiungi');
    btnAggiungi.visible = false;

    const fontiDatiForm = this.getDatiFatturaByName('fonti');
    fontiDatiForm.visible = false;
    const speseDatiForm = this.getDatiFatturaByName('spese');
    speseDatiForm.visible = false;

    switch (sezione) {
      case 'Cup':
        btnAggiungi.icon = 'plus';
        btnAggiungi.btnLabel = 'CUP';
        btnAggiungi.visible = true;
        break;
      case 'Fonte di Finanziamento':
        switch (sezioneFonti) {
          case 'Fonte di finaziamento':
            fontiDatiForm.visible = false;
            btnAggiungi.icon = 'plus';
            btnAggiungi.btnLabel = 'FONTI';
            btnAggiungi.visible = true;
            break;
          case 'Sostituisci fonte':
            if (
              fontiDatiForm &&
              fontiDatiForm.lista &&
              fontiDatiForm.lista.length === 0
            ) {
              const errore = this.getDatiFatturaByName('errore');
              errore.label = `Non sono presenti Fonti di finanziamento per questo Cup, non è possibile Sostituire la Fonte`;
            } else {
              fontiDatiForm.visible = true;
              // btnAggiungi.btnLabel = 'Sostituisci fonte'; //!!! Alessia
            }
            break;
          case 'Registra incasso':
            if (
              fontiDatiForm &&
              fontiDatiForm.lista &&
              fontiDatiForm.lista.length === 0
            ) {
              const errore = this.getDatiFatturaByName('errore');
              errore.label = `Non sono presenti Fonti di finanziamento per questo Cup, non è possibile Registrare un incasso`;
            } else {
              fontiDatiForm.visible = true;
              // btnAggiungi.btnLabel = 'Registra incasso'; //!!! Alessia
            }
            break;
          case 'Documento Fonti':
            btnAggiungi.icon = '';
            btnAggiungi.btnLabel = 'Carica Documento';
            btnAggiungi.visible = true;
            break;
        }
        break;
      case 'Spesa':
        switch (sezioneSpesa) {
          case 'Spesa':
            speseDatiForm.visible = false;
            btnAggiungi.icon = 'plus';
            btnAggiungi.btnLabel = 'SPESA';
            btnAggiungi.visible = true;
            break;
          case 'Giuistificativo':
            if (
              speseDatiForm &&
              speseDatiForm.lista &&
              speseDatiForm.lista.length === 0
            ) {
              const errore = this.getDatiFatturaByName('errore');
              errore.label = `Non sono presenti spese per questo Cup, non è possibile aggiungere Giuistificativo`;
            } else {
              speseDatiForm.visible = true;
              // btnAggiungi.btnLabel = 'Giuistificativo';
            }
            break;
          case 'Liquidazione':
            if (
              speseDatiForm &&
              speseDatiForm.lista &&
              speseDatiForm.lista.length === 0
            ) {
              const errore = this.getDatiFatturaByName('errore');
              errore.label = `Non sono presenti spese per questo Cup, non è possibile aggiungere Liquidazione`;
            } else {
              speseDatiForm.visible = true;
              // btnAggiungi.btnLabel = 'Liquidazione';
            }
            break;
          case 'Mandato':
            if (
              speseDatiForm &&
              speseDatiForm.lista &&
              speseDatiForm.lista.length === 0
            ) {
              const errore = this.getDatiFatturaByName('errore');
              errore.label = `Non sono presenti spese per questo Cup, non è possibile aggiungere Mandato`;
            } else {
              speseDatiForm.visible = true;
              // btnAggiungi.btnLabel = 'Mandato';
            }
            break;
          case 'Quitanza':
            if (
              speseDatiForm &&
              speseDatiForm.lista &&
              speseDatiForm.lista.length === 0
            ) {
              const errore = this.getDatiFatturaByName('errore');
              errore.label = `Non sono presenti spese per questo Cup, non è possibile aggiungere Quitanza`;
            } else {
              speseDatiForm.visible = true;
              // btnAggiungi.btnLabel = 'Quitanza';
            }
            break;
        }
        break;
      case 'Documento':
        btnAggiungi.icon = 'plus';
        btnAggiungi.btnLabel = 'DOCUMENTO';
        btnAggiungi.visible = true;
        break;
      case 'Annotazione':
        break;
      case 'Quadro Economico':
        break;
    }
  }

  /**
   * Pulizia dei campi alla selezione della Sezione
   */
  cleanData(event: { formGroup: FormGroup<any>; item: string }) {
    event.formGroup.controls['spese'].setValue('');
    event.formGroup.controls['fonti'].setValue('');
    event.formGroup.controls['sottoSezioneFonte'].setValue('');
    event.formGroup.controls['sottoSezioneSpesa'].setValue('');

    const sottoSezioneSpesa = this.getDatiFatturaByName('sottoSezioneSpesa');
    sottoSezioneSpesa.visible = false;
    const sottoSezioneFonte = this.getDatiFatturaByName('sottoSezioneFonte');
    sottoSezioneFonte.visible = false;
    const errore = this.getDatiFatturaByName('errore');
    errore.visible = false;
    const btn = this.getDatiFatturaByName('btnAggiungi');
    btn.visible = false;
  }
}
