<div class="col-md-6 offset-md-3 mt-5">
  <div class="card">
    <h4 class="card-header" style="background-color: transparent; border: none; font-size: large;">Cambio Password</h4>
    <div class="card-body">
      <form [formGroup]="changePswForm" (ngSubmit)="onSubmit()">
        <div class="form-group mt-2">
          <label for="password">Password</label>
          <input type="password" formControlName="password" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Password Obbligatoria</div>
          </div>
        </div>
        <div class="form-group mt-2">
          <label for="password">Nuova Password</label>
          <input type="password" formControlName="newpassword" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }" />
          <div *ngIf="f.newpassword.errors?.invalidPass" class="invalid-feedback">
            <div *ngIf="f.newpassword.errors?.invalidPass">La password deve contenere almeno 8 caratteri e almeno 1 lettera maiuscola, 1 numero e 1 carattere speciale. </div>
          </div>
          <div *ngIf="submitted && f.newpassword.errors" class="invalid-feedback">
            <div *ngIf="f.newpassword.errors.required">Nuova Password Obbligatoria</div>
          </div>
        </div>
        <div class="form-group mt-2">
          <label for="password">Conferma Nuova Password</label>
          <input type="password" formControlName="confirmnewpassword" class="form-control"
                 [ngClass]="{ 'is-invalid': submitted && f.newpassword.errors }" />
           <div *ngIf="f.confirmnewpassword.errors?.invalidPass" class="invalid-feedback">
            <div *ngIf="f.confirmnewpassword.errors?.invalidPass">La password deve contenere almeno 8 caratteri e almeno 1 lettera maiuscola, 1 numero e 1 carattere speciale. </div>
          </div>
          <div *ngIf="submitted && f.newpassword.errors" class="invalid-feedback">
            <div *ngIf="f.confirmnewpassword.errors.required">Nuova Password Obbligatoria</div>
          </div>
        </div>
        <div class="w-100 text-center">
          <button [disabled]="loading" class="btn btn-primary mt-3 w-50">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Cambia Password
          </button>
        </div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">Errore durante il cambio password, contattare l'amministratore</div>
      </form>
    </div>
  </div>
</div>
  