import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserClientService } from './user-client.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
    constructor(private jwtHelper: JwtHelperService,
        private router: Router,
        private http: HttpClient,
        private authService: UserClientService) {
    }
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const token = localStorage.getItem("jwt");

        let user = this.authService.getUser();
        let roles = route.data.roles as string[] | null;
        let isTokenExpired = token && this.jwtHelper.isTokenExpired(token);
        let found = user && (roles == null
                  || user?.roles !== '');
      if (token
        && !isTokenExpired
        && found) {
        return true;
        }

      if (isTokenExpired){
        localStorage.removeItem('infoUtente');
        localStorage.removeItem('infoRuolo');
        this.router.navigate(["login"]);
        return false;
      }

      const isRefreshSuccess = await this.tryRefreshingTokens(token);
      if (!isRefreshSuccess) {
        this.router.navigate(["login"]);
        return false;
      }

      if (!found)
        return false;

      return isRefreshSuccess;
    }

    private async tryRefreshingTokens(token: string | null): Promise<boolean> {
      // Try refreshing tokens using refresh token
      const refreshToken: string = localStorage.getItem("refreshToken") ?? "";

      if (!token || !refreshToken) {
        return false;
      }

      const credentials = JSON.stringify({ accessToken: token, refreshToken: refreshToken });

      let isRefreshSuccess: boolean;
      try {
        const response = await this.http.post("http://localhost:5000/api/token/refresh", credentials, {
          headers: new HttpHeaders({
            "Content-Type": "application/json"
          }),
          observe: 'response'
        }).toPromise();
        // If token refresh is successful, set new tokens in local storage.
        const newToken = (<any>response).body.accessToken;
        const newRefreshToken = (<any>response).body.refreshToken;
        localStorage.setItem("jwt", newToken);
        localStorage.setItem("refreshToken", newRefreshToken);
        isRefreshSuccess = true;
      }
      catch (ex) {
        isRefreshSuccess = false;
      }
      return isRefreshSuccess;
    }

}
