import { Inject, Injectable } from '@angular/core';
import { CUP } from '../model/cup';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CupService {
  
  getCupById(id: number): Observable<CUP> {
    return this.http.get<CUP>(`${this.CUP_URL}/${id}`)
  }

  BASE_URL = ''
  CUP_URL = 'api/Cup'

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient
  ) {
    this.BASE_URL = baseUrl
    this.CUP_URL = baseUrl + this.CUP_URL
  }

  getAllCups(): Observable<CUP[]> {
    return this.http.get<CUP[]>(`${this.CUP_URL}`);
  }

  updateCup(cup: CUP): Observable<number> {
    return this.http.put<number>(this.CUP_URL, cup);
  }

  submitCup(cup: CUP): Observable<number> {
    return this.http.post<number>(this.CUP_URL, cup);
  }
  
  deleteCup(cupid: number){
    return this.http.delete(`${this.CUP_URL}/${cupid}`);
  }
}
