<div class="row contenitore">
  <div class="col-md-9">
    <div class="header">

      <h1 class="title-card">CUP</h1>

      <!-- <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel()" class="p-button-success mr-2" pTooltip="XLS" tooltipPosition="bottom"></button> -->

      <div class="button-container">
        <form [formGroup]="theForm">
          <p-dropdown optionLabel="descrizione" [options]="statiDisponibili" optionValue="descrizione" formControlName="stato"
            (onChange)="filtraCupStato($event.value)"></p-dropdown>
        </form>

        <button *ngIf="isUtenteAbilitato" pButton (click)="showDialog()" label="NUOVO CUP" icon="pi pi-plus" style="height: 2.25em;"></button>
        <button type="button" pButton pRipple icon="pi pi-file-pdf" style="height: 2.25em;" (click)="createPdf()"
          class="p-button-warning mr-2" pTooltip="PDF" tooltipPosition="bottom"></button>
      </div>
    </div>

    <p-dialog [(visible)]="showAddCup" [resizable]="true" header="NUOVO CUP" class="p-dialog-full" [modal]="true"
      [draggable]="true" [maximizable]="true">

      <upload-file *ngIf="datiModelloDocumentale?.formGroup" nomeModello="CUP"
        [datiModelloDocumentale]="datiModelloDocumentale" [isInEdit]="false" [entityIdAdd]="entityIdAdd"
        (add)="addCup($event)" (close)="onClose($event)"></upload-file>

    </p-dialog>

    <p-card *ngIf="filteredCup">
      <p-table [value]="filteredCup" [paginator]="true" [rows]="10" [showCurrentPageReport]="true"
        [resizableColumns]="true" currentPageReportTemplate="Da {first} a {last} di {totalRecords} Cup"
        [rowsPerPageOptions]="[10, 20, 50]" [(selection)]="selectedCup" dataKey="codice"
        styleClass="p-datatable-striped">

        <ng-template pTemplate="header">
          <tr class="ellipsis">
            <th pResizableColumn>Codice</th>
            <th pResizableColumn>Titolo</th>
            <th pResizableColumn>Descrizione</th>
            <th pResizableColumn>Importo</th>
            <th pResizableColumn>Stato</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-cup>
          <tr class="ellipsis" [pSelectableRow]="cup" (dblclick)="dblclickCUP(cup.id)">
            <td>{{ cup.codice }}</td>
            <td [pTooltip]="cup.titolo"> {{ cup.titolo }} </td>
            <td [pTooltip]="cup.descrizione"> {{ cup.descrizione }} </td>
            <td>{{ cup.importo | currency: 'EUR':'symbol':undefined:'it-IT'}}</td>
            <td>{{ cup.stato }}</td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>

  </div>

  <div class="col-md-3">
    <div class="header">
      <h1 class="title-card">PROMEMORIA</h1>
    </div>
    <p-card>
      <app-scadenzario [user]="user"></app-scadenzario>
    </p-card>
  </div>

  <rbk-modal *ngIf="visibleDialog" [dialogModel]="dialogModel" (onConfirm)="visibleDialog = false"></rbk-modal>
</div>

<!-- [routerLink]="['cup/', cup.id]" -->