import { Component, Input } from '@angular/core';
import { ConfirmationService, MessageService } from "primeng/api";

@Component({
	selector: 'app-gestione-utenti',
	templateUrl: './gestione-utenti.component.html',
	styleUrls: ['./gestione-utenti.component.scss'],
	providers: [ConfirmationService, MessageService]
})


export class GestioneUtentiComponent {
  @Input() public idCup: number;
  visible: boolean = false;
  
  constructor(){
  }

  showDialogForm(bool){
    this.visible = true;
    //this.visualizza = visualizza;
    /* if (spesa) {
      this.spesaFase1 = spesa;
      this.isInEdit = true;
    } else {
      this.spesaFase1 = null
      this.isInEdit = false
    } */
  }
}