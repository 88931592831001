import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from "primeng/api";
import { NgxSpinnerService } from 'ngx-spinner';
import { Utente } from 'src/app/model/utente';
import { UserClientService } from 'src/app/service/user-client.service';


@Component({
  selector: 'app-invita-form',
  templateUrl: './invita-form.component.html',
  styleUrls: ['./invita-form.component.scss'],
  providers: [ConfirmationService, MessageService]
})


export class InvitaFormComponent {

  @Input() visible: boolean;

  @Output() closeDialog = new EventEmitter<boolean>()

  utente: Utente

  formInvito: FormGroup = new FormGroup({
    email: new FormControl()
  });

  constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService,
    private userClientService: UserClientService,
  ) { }

  ngOnInit() {
    this.formInvito = new FormGroup({
      email: new FormControl('', Validators.required)
    })
    this.utente = this.userClientService.getUser()
  }

  confirm() {
    console.log(this.formInvito.value.email)
    this.confirmationService.confirm({
      message: "L'utente riceverà una mail con il link per registrarsi. Una volta completata la registrazione sarà visibile nell'elenco utenti.",
      header: "Invito",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Ok, invita",
      rejectLabel: "Torna indietro",
      acceptButtonStyleClass: "p-button-success p-button-text",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.messageService.add({
          severity: "success",
          summary: "Confirmed",
          detail: "Invito inviato",
        });
        this.associate(this.formInvito.value.email)
        this.close(true)
      },
      reject: () => {
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Invito non inviato"
        });
      }
    });
  }

  associate(username: string) {
    this.spinner.show();
    this.userClientService.associateUser(username, this.utente.clientId, true).subscribe({
      next: (resp) => {
        this.messageService.add({
          severity: "info",
          summary: "Utente associato",
          detail: "Utente associato",
        });
        this.spinner.hide();
      },
      error: (err) => {
        console.log(err)
        this.messageService.add({
          severity: "error",
          summary: "Utente non associato",
          detail: "Errore: " + err,
        });

        this.spinner.hide();
      }
    })
  }

  invita() { }

  close(update) {
    this.visible = false;
    this.closeDialog.emit(update);
  }


}
