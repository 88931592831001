<div>
  <p-accordion> <!-- [activeIndex]="0" -->
    <p-accordionTab header="CUP">
      <div *ngIf="this.formGroupCup" class="d-flex flex-column">
        <div class="d-flex align-items-center mb-2 addDizionario">
          <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" (onClick)="add(0)"></p-button>
          <span style="font-weight: 600; font-size: 16px;">Stato</span>
        </div>
        <form [formGroup]="formGroupCup">
          <div formArrayName="stati">
            <!-- HEADER -->
            <div class="d-flex align-items-center form-group">
              <div class="form-group col-md-1">
                <label for="attivo" class="col-form-label">Attivo</label>
              </div>
              <div class="form-group col-md-10">
                <label for="descriione" class="col-form-label">Descrizione</label>
              </div>
              <div class="form-group col-md-1 d-flex justify-content-center">
                <label class="col-form-label">Elimina</label>
              </div>
            </div>
            <!-- ISTANZE -->
            <div *ngFor="let dizionarioGroup of cupFormArray.controls; let i = index" [formGroupName]="i"
              class="d-flex align-items-center form-group mb-1">
              <div class="form-group col-md-1">
                <p-toggleButton formControlName="attivo" onLabel="Yes" offLabel="No"
                  styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle(0)"></p-toggleButton>
              </div>
              <div class="form-group col-md-10">
                <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                  [readonly]="dizionarioGroup.value.readonly" [ngClass]="dizionarioGroup.value.readonly ? 'noFocus': '' ">
                <small style="color: #dc3545"
                  *ngIf="!dizionarioGroup.get('descrizione').valid && dizionarioGroup.get('descrizione').touched">
                  Inserisci una descrizione</small>
              </div>
              <div class="form-group col-md-1 d-flex justify-content-center">
                <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
                  (click)="removeMessage(dizionarioGroup, i, 0)"></p-button>
              </div>
            </div>
          </div>
          <!-- FOOTER -->
          <div class="d-flex justify-content-end footer mt-3">
            <button pButton label="ANNULLA" class="p-button-secondary" [disabled]="!somethingHasChangedInCup"
              (click)="restore(0)"></button>
            <button style="margin-right: 1em;" pButton label="SALVA" class="p-button-success"
              [disabled]="!somethingHasChangedInCup || formGroupCup.invalid" (click)="save(0)"></button>
          </div>
        </form>
      </div>
    </p-accordionTab>

    <p-accordionTab header="Quadro Economico">
      <div class="d-flex halfContainer">
        <div *ngIf="this.formGroupTipiQE" class="d-flex flex-column flex-grow-1">
          <div class="d-flex align-items-center mb-2 addDizionario">
            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" (onClick)="add(2)"></p-button>
            <span style="font-weight: 600; font-size: 16px;">Tipo progetto</span>
          </div>
          <form [formGroup]="formGroupTipiQE">
            <div formArrayName="tipi">
              <!-- HEADER -->
              <div class="d-flex align-items-center form-group">
                <div class="form-group col-md-2">
                  <label for="attivo" class="col-form-label">Attivo</label>
                </div>
                <div class="form-group col-md-9">
                  <label for="descriione" class="col-form-label">Descrizione</label>
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <label class="col-form-label">Elimina</label>
                </div>
              </div>
              <!-- ISTANZE -->
              <div *ngFor="let dizionarioGroup of tipiQEFormArray.controls; let i = index" [formGroupName]="i"
                class="d-flex align-items-center form-group mb-1">
                <div class="form-group col-md-2">
                  <p-toggleButton formControlName="attivo" onLabel="Yes" offLabel="No"
                    styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle(2)"></p-toggleButton>
                </div>
                <div class="form-group col-md-9">
                  <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                    [readonly]="dizionarioGroup.value.readonly">
                  <small style="color: #dc3545"
                    *ngIf="!dizionarioGroup.get('descrizione').valid && dizionarioGroup.get('descrizione').touched">
                    Inserisci una descrizione</small>
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
                    (click)="removeMessage(dizionarioGroup, i, 2)"></p-button>
                </div>
              </div>
            </div>
            <!-- FOOTER -->
            <div class="d-flex justify-content-end footer mt-2">
              <button pButton label="ANNULLA" class="p-button-secondary" [disabled]="!somethingHasChangedInTipiQE"
                (click)="restore(2)"></button>
              <button style="margin-right: 1em;" pButton label="SALVA" class="p-button-success"
                [disabled]="!somethingHasChangedInTipiQE || formGroupTipiQE.invalid" (click)="save(2)"></button>
            </div>
          </form>
        </div>

        <div *ngIf="this.formGroupAttiQE" class="d-flex flex-column flex-grow-1">
          <div class="d-flex align-items-center mb-2 addDizionario">
            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" (onClick)="add(1)"></p-button>
            <span style="font-weight: 600; font-size: 16px;">Tipo atto</span>
          </div>
          <form [formGroup]="formGroupAttiQE">
            <div formArrayName="atti">
              <!-- HEADER -->
              <div class="d-flex align-items-center form-group">
                <div class="form-group col-md-2">
                  <label for="attivo" class="col-form-label">Attivo</label>
                </div>
                <div class="form-group col-md-9">
                  <label for="descriione" class="col-form-label">Descrizione</label>
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <label class="col-form-label">Elimina</label>
                </div>
              </div>
              <!-- ISTANZE -->
              <div *ngFor="let dizionarioGroup of attiQEFormArray.controls; let i = index" [formGroupName]="i"
                class="d-flex align-items-center form-group mb-1">
                <div class="form-group col-md-2">
                  <p-toggleButton formControlName="attivo" onLabel="Yes" offLabel="No"
                    styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle(1)"></p-toggleButton>
                </div>
                <div class="form-group col-md-9">
                  <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                    [readonly]="dizionarioGroup.value.readonly">
                  <small style="color: #dc3545"
                    *ngIf="!dizionarioGroup.get('descrizione').valid && dizionarioGroup.get('descrizione').touched">
                    Inserisci una descrizione</small>
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
                    (click)="removeMessage(dizionarioGroup, i, 1)"></p-button>
                </div>
              </div>
            </div>
            <!-- FOOTER -->
            <div class="d-flex justify-content-end footer mt-2">
              <button pButton label="ANNULLA" class="p-button-secondary" [disabled]="!somethingHasChangedInAtti"
                (click)="restore(1)"></button>
              <button style="margin-right: 1em;" pButton label="SALVA" class="p-button-success"
                [disabled]="!somethingHasChangedInAtti || formGroupAttiQE.invalid" (click)="save(1)"></button>
            </div>
          </form>
        </div>
      </div>
    </p-accordionTab>

    <p-accordionTab header="Ordinativo di spesa">
      <div class="d-flex halfContainer">
        <div *ngIf="this.formGroupOrdinativo" class="d-flex flex-column flex-grow-1">
          <div class="d-flex align-items-center mb-2 addDizionario">
            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" (onClick)="add(3)"></p-button>
            <span style="font-weight: 600; font-size: 16px;">Tipo spesa</span>
          </div>
          <form [formGroup]="formGroupOrdinativo">
            <div formArrayName="tipi">
              <!-- HEADER -->
              <div class="d-flex align-items-center form-group">
                <div class="form-group col-md-2">
                  <label for="attivo" class="col-form-label">Attivo</label>
                </div>
                <div class="form-group col-md-9">
                  <label for="descriione" class="col-form-label">Descrizione</label>
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <label class="col-form-label">Elimina</label>
                </div>
              </div>
              <!-- ISTANZE -->
              <div *ngFor="let dizionarioGroup of tipiOrdinativoFormArray.controls; let i = index" [formGroupName]="i"
                class="d-flex align-items-center form-group mb-1">
                <div class="form-group col-md-2">
                  <p-toggleButton formControlName="attivo" onLabel="Yes" offLabel="No"
                    styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle(3)"></p-toggleButton>
                </div>
                <div class="form-group col-md-9">
                  <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                    [readonly]="dizionarioGroup.value.readonly">
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
                    (click)="removeMessage(dizionarioGroup, i, 3)"></p-button>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end footer mt-2">
              <button pButton label="ANNULLA" class="p-button-secondary" [disabled]="!somethingHasChangedInOrdinativi"
                (click)="restore(3)"></button>
              <button style="margin-right: 1em;" pButton label="SALVA" class="p-button-success"
                [disabled]="!somethingHasChangedInOrdinativi || formGroupOrdinativo.invalid" (click)="save(3)"></button>
            </div>
          </form>
        </div>

        <div *ngIf="this.formGroupAttiQE" class="d-flex flex-column flex-grow-1">
          <div class="d-flex align-items-center mb-2 noButton">
            <span style="font-weight: 600; font-size: 16px;">Tipo atto <span style="font-weight: 400;"><i>(ereditato dal Quadro economico)</i></span></span>
          </div>
          <form [formGroup]="formGroupAttiQE">
            <div formArrayName="atti">
              <!-- HEADER -->
              <div class="d-flex align-items-center form-group">
                <div class="form-group col-md-2">
                  <label for="attivo" class="col-form-label">Attivo</label>
                </div>
                <div class="form-group col-md-9">
                  <label for="descriione" class="col-form-label">Descrizione</label>
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <label class="col-form-label">Elimina</label>
                </div>
              </div>
              <!-- ISTANZE -->
              <div *ngFor="let dizionarioGroup of attiQEFormArray.controls; let i = index" [formGroupName]="i"
                class="d-flex align-items-center form-group mb-1">
                <div class="form-group col-md-2">
                  <p-toggleButton formControlName="attivo" [disabled]="true" onLabel="Yes" offLabel="No"
                    styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle()"></p-toggleButton>
                </div>
                <div class="form-group col-md-9">
                  <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                    [readOnly]="true">
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text" [disabled]="true"
                    (click)="removeMessage(dizionarioGroup, i, 1)"></p-button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </p-accordionTab>

    <p-accordionTab header="Giustificativo di spesa">
      <div *ngIf="this.formGroupGiustificativo" class="d-flex flex-column">
        <div class="d-flex align-items-center mb-2 addDizionario">
          <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" (onClick)="add(4)"></p-button>
          <span style="font-weight: 600; font-size: 16px;">Tipo giustificativo</span>
        </div>
        <form [formGroup]="formGroupGiustificativo">
          <div formArrayName="tipi">
            <!-- HEADER -->
            <div class="d-flex align-items-center form-group">
              <div class="form-group col-md-1">
                <label for="attivo" class="col-form-label">Attivo</label>
              </div>
              <div class="form-group col-md-10">
                <label for="descriione" class="col-form-label">Descrizione</label>
              </div>
              <div class="form-group col-md-1 d-flex justify-content-center">
                <label class="col-form-label">Elimina</label>
              </div>
            </div>
            <!-- ISTANZE -->
            <div *ngFor="let dizionarioGroup of tipiGiustificativoFormArray.controls; let i = index" [formGroupName]="i"
              class="d-flex align-items-center form-group mb-1">
              <div class="form-group col-md-1">
                <p-toggleButton formControlName="attivo" onLabel="Yes" offLabel="No"
                  styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle(4)"></p-toggleButton>
              </div>
              <div class="form-group col-md-10">
                <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                  [readonly]="dizionarioGroup.value.readonly">
              </div>
              <div class="form-group col-md-1 d-flex justify-content-center">
                <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
                  (click)="removeMessage(dizionarioGroup, i, 4)"></p-button>
              </div>
            </div>
          </div>
          <!-- FOOTER -->
          <div class="d-flex justify-content-end footer mt-3">
            <button pButton label="ANNULLA" class="p-button-secondary" [disabled]="!somethingHasChangedInGiustificativi"
              (click)="restore(4)"></button>
            <button style="margin-right: 1em;" pButton label="SALVA" class="p-button-success"
              [disabled]="!somethingHasChangedInGiustificativi || formGroupGiustificativo.invalid"
              (click)="save(4)"></button>
          </div>
        </form>
      </div>
    </p-accordionTab>

    <p-accordionTab header="Fonte di finanziamento">
      <div class="d-flex halfContainer">
        <div *ngIf="this.formGroupEntiFonte" class="d-flex flex-column flex-grow-1">
          <div class="d-flex align-items-center mb-2 addDizionario">
            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" (onClick)="add(5)"></p-button>
            <span style="font-weight: 600; font-size: 16px;">Tipo ente</span>
          </div>
          <form [formGroup]="formGroupEntiFonte">
            <div formArrayName="enti">
              <!-- HEADER -->
              <div class="d-flex align-items-center form-group">
                <div class="form-group col-md-2">
                  <label for="attivo" class="col-form-label">Attivo</label>
                </div>
                <div class="form-group col-md-9">
                  <label for="descriione" class="col-form-label">Descrizione</label>
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <label class="col-form-label">Elimina</label>
                </div>
              </div>
              <!-- ISTANZE -->
              <div *ngFor="let dizionarioGroup of entiFontiFormArray.controls; let i = index" [formGroupName]="i"
                class="d-flex align-items-center form-group mb-1">
                <div class="form-group col-md-2">
                  <p-toggleButton formControlName="attivo" onLabel="Yes" offLabel="No"
                    styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle()"></p-toggleButton>
                </div>
                <div class="form-group col-md-9">
                  <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                    [readonly]="dizionarioGroup.value.readonly">
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
                    (click)="removeMessage(dizionarioGroup, i, 5)"></p-button>
                </div>
              </div>
            </div>
            <!-- FOOTER -->
            <div class="d-flex justify-content-end footer mt-2">
              <button pButton label="ANNULLA" class="p-button-secondary" [disabled]="!somethingHasChangedInEntiFonte"
                (click)="restore()"></button>
              <button style="margin-right: 1em;" pButton label="SALVA" class="p-button-success"
                [disabled]="!somethingHasChangedInEntiFonte || formGroupEntiFonte.invalid"></button>
            </div>
          </form>
        </div>

        <div *ngIf="this.formGroupAttiFonte" class="d-flex flex-column flex-grow-1">
          <div class="d-flex align-items-center mb-2 addDizionario">
            <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" (onClick)="add(6)"></p-button>
            <span style="font-weight: 600; font-size: 16px;">Tipo atto fonte</span>
          </div>
          <form [formGroup]="formGroupAttiFonte">
            <div formArrayName="atti">
              <!-- HEADER -->
              <div class="d-flex align-items-center form-group">
                <div class="form-group col-md-2">
                  <label for="attivo" class="col-form-label">Attivo</label>
                </div>
                <div class="form-group col-md-9">
                  <label for="descriione" class="col-form-label">Descrizione</label>
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <label class="col-form-label">Elimina</label>
                </div>
              </div>
              <!-- ISTANZE -->
              <div *ngFor="let dizionarioGroup of attiFontiFormArray.controls; let i = index" [formGroupName]="i"
                class="d-flex align-items-center form-group mb-1">
                <div class="form-group col-md-2">
                  <p-toggleButton formControlName="attivo" onLabel="Yes" offLabel="No"
                    styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle()"></p-toggleButton>
                </div>
                <div class="form-group col-md-9">
                  <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                    [readonly]="dizionarioGroup.value.readonly">
                </div>
                <div class="form-group col-md-1 d-flex justify-content-center">
                  <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
                    (click)="removeMessage(dizionarioGroup, i, 6)"></p-button>
                </div>
              </div>
            </div>
            <!-- FOOTER -->
            <div class="d-flex justify-content-end footer mt-2">
              <button pButton label="ANNULLA" class="p-button-secondary" [disabled]="!somethingHasChangedInAttiFonte"
                (click)="restore()"></button>
              <button style="margin-right: 1em;" pButton label="SALVA" class="p-button-success"
                [disabled]="!somethingHasChangedInAttiFonte || formGroupAttiFonte.invalid" (click)="save(6)"></button>
            </div>
          </form>
        </div>
      </div>
    </p-accordionTab>

    <p-accordionTab header="Incasso">
      <div *ngIf="this.formGroupIncasso" class="d-flex flex-column">
        <div class="d-flex align-items-center mb-2 addDizionario">
          <p-button icon="pi pi-plus" styleClass="p-button-rounded p-button-outlined" (onClick)="add(7)"></p-button>
          <span style="font-weight: 600; font-size: 16px;">Tipo incasso</span>
        </div>
        <form [formGroup]="formGroupIncasso">
          <div formArrayName="tipi">
            <!-- HEADER -->
            <div class="d-flex align-items-center form-group">
              <div class="form-group col-md-1">
                <label for="attivo" class="col-form-label">Attivo</label>
              </div>
              <div class="form-group col-md-10">
                <label for="descriione" class="col-form-label">Descrizione</label>
              </div>
              <div class="form-group col-md-1 d-flex justify-content-center">
                <label class="col-form-label">Elimina</label>
              </div>
            </div>
            <!-- ISTANZE -->
            <div *ngFor="let dizionarioGroup of tipiIncassoFormArray.controls; let i = index" [formGroupName]="i"
              class="d-flex align-items-center form-group mb-1">
              <div class="form-group col-md-1">
                <p-toggleButton formControlName="attivo" onLabel="Yes" offLabel="No"
                  styleClass="p-button p-button-text p-button-primary" (onChange)="changeToggle(7)"></p-toggleButton>
              </div>
              <div class="form-group col-md-10">
                <input formControlName="descrizione" type="text" class="form-control" placeholder="Descrizione"
                  [readonly]="dizionarioGroup.value.readonly">
              </div>
              <div class="form-group col-md-1 d-flex justify-content-center">
                <p-button icon="pi pi-trash" styleClass="p-button p-button-danger p-button-text"
                  (click)="removeMessage(dizionarioGroup, i, 7)"></p-button>
              </div>
            </div>
          </div>
          <!-- FOOTER -->
          <div class="d-flex justify-content-end footer mt-3">
            <button pButton label="ANNULLA" class="p-button-secondary" [disabled]="!somethingHasChangedInIncassi"
              (click)="restore(7)"></button>
            <button style="margin-right: 1em;" pButton label="SALVA" class="p-button-success"
              [disabled]="!somethingHasChangedInIncassi || formGroupIncasso.invalid" (click)="save(7)"></button>
          </div>
        </form>
      </div>
    </p-accordionTab>
  </p-accordion>

  <!-- DIALOG ELIMINA VOCE -->
  <p-dialog *ngIf="openDeleteDialog" header="Eliminazione voce dizionario" [(visible)]="openDeleteDialog"
    [style]="{width: '50vw'}" [contentStyle]="{'overflow':'visible'}" [modal]="true" [draggable]="false">
    <div>Sei sicuro di voler eliminare questa voce in dizionario?
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple icon="pi pi-check" (click)="openDeleteDialog = false" label="No, torna indietro"
        class="p-button-text"></button>
      <button pButton pRipple icon="pi pi-check" label="Si, elimina"
        (click)="remove(dizionarioToDelete , indexToDelete , tipologiaDizionarioToDelete)"
        class="p-button-danger p-button-text"></button>
    </ng-template> <!--   -->
  </p-dialog>
</div>