import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from "primeng/api";
import { CupService } from 'src/app/service/cup.service';
import { SharedService } from 'src/app/service/shared.service';
import { EnteDto } from 'src/app/model/ente';
import { UserClientService } from 'src/app/service/user-client.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-ente-list',
  templateUrl: './ente-list.component.html',
  styleUrls: ['./ente-list.component.scss']
})
export class EnteListComponent {

  filteredTab: EnteDto[];
  selectedEnte: EnteDto;
  visible: boolean = false;
  isInEdit: boolean;


  constructor(
    private entiServices: UserClientService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.init()
  }

  init() {
    localStorage.removeItem('titoloCup');
    this.spinner.show()
    this.entiServices.getClient().subscribe({
      next: (resp) => {
        this.spinner.hide();
        this.filteredTab = resp
      },
      error: (err) => {
        this.spinner.hide();
        console.log(err)
      }
    })

  }

  showDialog(ente?: EnteDto) {
    if (ente) {
      this.spinner.show()
      this.entiServices.getClientById(ente.id).subscribe({
        next: resp => {
         this.spinner.hide();
          this.selectedEnte = resp
          this.visible = true
          this.isInEdit = true
        },
        error: (err) => {
          this.spinner.hide();
        }
      })
    } else {
      this.visible = true;
      this.isInEdit = false;
      this.selectedEnte = undefined;
    }
  }

  confirm(id) {
    this.confirmationService.confirm({
      message: "Sei sicuro di vole eliminare questo ente?",
      header: "Eliminazione",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Si, elimina",
      rejectLabel: "No, torna indietro",
      acceptButtonStyleClass: "p-button-danger p-button-text",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.entiServices.deleteClientById(id).subscribe({
          next: (resp: EnteDto) => {
            console.log(resp)
          }
        })
        this.messageService.add({
          severity: "info",
          summary: "Confirmed",
          detail: "Ente eliminato con successo",
        });
        this.filteredTab = this.filteredTab.filter(x => x.id !== id)
      },
      reject: () => {
        /* this.init();
        this.messageService.add({
          severity: "error",
          summary: "Rejected",
          detail: "Errore nell'eliminazione dell'ente"
        }); */
      }
    });
  }

  onClose(bool) {
    this.visible = false;
    if (bool) {
      this.init();
    }
  }

}
