import { Inject, Injectable } from '@angular/core';
import { Fonte, FonteResponse, FonteSubmit, FontiResponse, IncassiResponse, Incasso, IncassoResponse, IncassoSubmit } from '../model/fonte';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

    
@Injectable({
  providedIn: 'root'
})

export class FontiService {

  BASE_URL = ''
  FONTE_URL = 'api/FonteFinanziamento'

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient
  ) {
    this.BASE_URL = baseUrl
    this.FONTE_URL = baseUrl + this.FONTE_URL
  }


  createFonteFinanziamento(fonte: FonteSubmit): Observable<FonteResponse>{
    return this.http.post<FonteResponse>(`${this.FONTE_URL}/CreateFonteFinaziamento`, fonte);
  }
  updateFonteFinanziamento(fonte: FonteSubmit, id: number): Observable<FonteResponse>{
    return this.http.put<FonteResponse>(`${this.FONTE_URL}/AggiornaFonteFinanziamento/${id}`, fonte);
  }
  replaceFonteFinanziamento(fonte: FonteSubmit, id: number): Observable<FonteResponse>{
    return this.http.put<FonteResponse>(`${this.FONTE_URL}/StoricizzaFonteFinanziamento/${id}`, fonte);
  }
  
  createIncasso(incasso: IncassoSubmit): Observable<IncassoResponse>{
    return this.http.post<IncassoResponse>(`${this.FONTE_URL}/CreateIncasso`, incasso);
  }
  updateIncasso(incasso: IncassoSubmit, id: number): Observable<IncassoResponse>{
    return this.http.put<IncassoResponse>(`${this.FONTE_URL}/AggiornaIncasso/${id}`, incasso);
  }

  getFontiFinanziamentoStoriche(cupId:number): Observable<FontiResponse>{
    return this.http.get<FontiResponse>(`${this.FONTE_URL}/BrowseFontiFinanziamentoStoriche/${cupId}`)
  }
  getFontiFinanziamentoAttuali(cupId:number): Observable<FontiResponse>{
    return this.http.get<FontiResponse>(`${this.FONTE_URL}/BrowseFontiFinanziamentoAttuali/${cupId}`)
  }
  getIncassi(cupId:number): Observable<IncassiResponse>{
    return this.http.get<IncassiResponse>(`${this.FONTE_URL}/BrowseIncassi?CupID=${cupId}`)
  }

  deleteFonteAttuale(id:number){
    return this.http.delete(`${this.FONTE_URL}/DeleteFonteFinanaziamentoAttuali/${id}`)
  }
  deleteFonteStorica(id:number){
    return this.http.delete(`${this.FONTE_URL}/DeleteFonteFinanziamentoStorico/${id}`)
  }
  deleteIncasso(id:number){
    return this.http.delete(`${this.FONTE_URL}/Incasso/${id}`)
  }
  
}