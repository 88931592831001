import { QEItemBase } from './qe';

export interface Fasi1Response {
  success: boolean;
  dtos: Fase1[];
}

export interface Fase1Response {
  success: boolean;
  dto: Fase1;
}

export interface Fase1 {
  id: number;
  cig: string;
  tipo: string;
  atto: string;
  numero: number;
  beneficiario: string;
  data: Date;
  descrizione: string;
  importoNetto: number;
  importoIVA: number;
  importoCassa?: number | null;
  pIvaCodiceFiscale: string | null;
  qeItemImportoNetto: QEItemBase | null;
  qeItemIVA: QEItemBase | null;
  qeItemCassa?: QEItemBase | null;
  fasi2: Fase2[];
  fasi3: Fase3[] | null;
  fasi4: Fase4[] | null;
  fasi5: Fase5[] | null;
}

//cambiato i null con any
export interface Fase1Submit {
  id: number | null;
  cig: string | null;
  tipo: string | null;
  atto: string | null;
  numero: number | null;
  beneficiario: string | null;
  data: string | null;
  descrizione: string | null;
  importoNetto: number | null;
  importoIVA: number | null;
  importoCassa?: number | null;
  pIvaCodiceFiscale: string | null;
  qeItemImportoNettoIndex: number | null;
  qeItemImportoNettoSubIndex: number | null;
  qeItemImportoNettoSectionIndex: number | null;
  qeItemImportoNettoSectionQEMainId: number | null;
  qeItemIVAIndex: number | null;
  qeItemIVASubIndex: number | null;
  qeItemIVASectionIndex: number | null;
  qeItemIVASectionQEMainId: number | null;
  qeItemCassaIndex?: number | null;
  qeItemCassaSubIndex?: number | null;
  qeItemCassaSectionIndex?: number | null;
  qeItemCassaSectionQEMainId?: number | null;
  cupId: number | null;
}

export interface Fasi2Response {
  success: boolean;
  dtos: Fase2[];
}

export interface Fase2Response {
  success: boolean;
  dto: Fase2;
}

export interface Fase2SubmitResponse {
  success: boolean;
  dto: Fase2Submit;
}

export interface Fase2 {
  id: number;
  fase1Id: number;
  tipo: string;
  numero: number;
  data: string;
  beneficiario: string;
  pIvaCodiceFiscale: string | null;
  importoNetto: number;
  iva: number;
  cassa?: number;
  descrizione?: string;
  fasi3: Fase3[];
  flag?: boolean | false;
}

export interface Fase2Submit {
  id: number | null;
  fase1Id: number;
  tipo: string | null;
  numero: number | null;
  data: string | null;
  beneficiario: string | null;
  pIvaCodiceFiscale: string | null;
  importoNetto: number | null;
  iva: number | null;
  cassa?: number | null;
  descrizione?: string | null;
}

export interface Fasi3Response {
  success: boolean;
  dtos: Fase3[];
}

export interface Fase3Response {
  success: boolean;
  dto: Fase3;
}

export interface Fase3Res {
  success: boolean;
  dto: Fase3Edit;
}

export interface Fase3Edit extends Fase3 {
  idFase1: number;
}

export interface Fase3 {
  id: number;
  fase2Id: number;
  numero: number;
  data: string;
  importo?: number;
  importoNetto: number;
  iva: number;
  cassa?: number;
  fasi4: Fase4[];
}

export interface Fase3Submit {
  id: number | null;
  fase2Id: number;
  numero: number | null;
  data: string | null;
  importo?: number | null;
  importoNetto?: number | null;
  iva?: number | null;
  cassa?: number | null;
}

export interface Fasi4Response {
  success: boolean;
  dtos: Fase4[];
}

export interface Fase4Response {
  success: boolean;
  dto: Fase4;
}

export interface Fase4Res {
    success: boolean;
    dto: Fase4Edit;
  }

export interface Fase4Edit extends Fase4 {
    idFase1: number;
  }

export interface Fase4 {
  id: number;
  fase3Id: number;
  numero: number;
  data: string;
  descrizione: string;
  importo: number;
  fase5: Fase5;
}

export interface Fase4Submit {
  id: number | null;
  fase3Id: number;
  numero: number | null;
  data: string | null;
  descrizione: string | null;
  importo: number | null;
}

export interface Fase5Res {
  success: boolean;
  dto: Fase5Edit;
}

export interface Fase5Response {
  success: boolean;
  dtos: Fase5;
}

export interface Fase5Edit extends Fase5 {
  idFase1: number;
}

export interface Fase5 {
  id: number;
  fase4Id: number;
  attachmentUrl: string;
}

export interface Fase5Submit {
  id?: number | null;
  fase4Id?: number;
  attachment: string | null;
}

export interface Fase2BaseResponse {
  success: boolean;
  dtos: Fase2Base[];
}

export interface Fase2Base {
  id: number | null;
  tipo: string | null;
  number: number | null;
  data: Date | null;
  text: string | null;
  importoNetto?: number | null;
  iva?: number | null;
  cassa?: number | null;
}

export class Fase2Riferimenti {
  beneficiario: string;
  pIvaCodiceFiscale: string;
  riferimentoLiquidazione: string;
}

export interface Fase3BaseResponse {
  success: boolean;
  dtos: Fase3Base[];
}

export interface Fase3Base {
  id: number | null;
  number: number | null;
  data: Date | null;
  text: string | null;
}

export interface Fase4BaseResponse {
  success: boolean;
  dtos: Fase4Base[];
}

export interface Fase4Base {
  id: number | null;
  number: number | null;
  data: Date | null;
  text: string | null;
}
