import { Pipe, PipeTransform } from '@angular/core';

const FILE_SIZE_UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const FILE_SIZE_UNITS_LONG = ['Bytes', 'Kilobytes', 'Megabytes', 'Gigabytes', 'Pettabytes', 'Exabytes', 'Zettabytes', 'Yottabytes'];

@Pipe({
  name: 'formatFileSize'
})
export class FormatFileSizePipe implements PipeTransform {
  transform(sizeInBytes: number, longForm: boolean): string {
    const units = longForm
      ? FILE_SIZE_UNITS_LONG
      : FILE_SIZE_UNITS;

    // let power = Math.round(Math.log(sizeInBytes) / Math.log(1024 * 1024));
    // power = Math.min(power, units.length - 1);

    const size = sizeInBytes / (1024 * 1024); // size in new units
    const formattedSize = Math.round(size * 100) / 100; // keep up to 2 decimals
    const unit = units[2];

    return `${formattedSize} ${unit}`;
  }
}

@Pipe({
  name: 'mimeType'
})
export class MimeTypePipe implements PipeTransform {
  transform(extension: string) {
    let mimeType = 'application/octet-stream';
    extension = extension?.toLowerCase();
    if (extension?.match('(doc|dot)')) {
      mimeType = 'application/msword';
    } else if (extension?.match('(docx)')) {
      mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (extension?.match('(dotx)')) {
      mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.template';
    } else if (extension?.match('(docm)')) {
      mimeType = 'application/vnd.ms-word.document.macroEnabled.12';
    } else if (extension?.match('(dotm)')) {
      mimeType = 'application/vnd.ms-word.template.macroEnabled.12';
    } else if (extension?.match('(xls|xlt|xla|xlw)')) {
      mimeType = 'application/vnd.ms-excel';
    } else if (extension?.match('(xlsx)')) {
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (extension?.match('(xltx)')) {
      mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.template';
    } else if (extension?.match('(xlsm)')) {
      mimeType = 'application/vnd.ms-excel.sheet.macroEnabled.12';
    } else if (extension?.match('(xltm)')) {
      mimeType = 'application/vnd.ms-excel.template.macroEnabled.12';
    } else if (extension?.match('(xlam)')) {
      mimeType = 'application/vnd.ms-excel.addin.macroEnabled.12';
    } else if (extension?.match('(xlsb)')) {
      mimeType = 'application/vnd.ms-excel.sheet.binary.macroEnabled.12';
    } else if (extension?.match('(txt)')) {
      mimeType = 'text/plain';
    } else if (extension?.match('(odt|oad|oadx)')) {
      mimeType = 'application/vnd.oasis.opendocument.text';
    } else if (extension?.match('(rtf|pdf|oadx|xml)')) {
      mimeType = `application/${extension}`;
    } else if (extension?.match('(bmp|cdp|djvu|gif|gdp|gpd|jpd|jpg|jpeg|pict|png|tga|tiff|tif|pcx|psd|webp)')) {
      mimeType = `image/${extension}`;
    } else if (extension?.match('(oft)')) {
      mimeType = 'application/vnd.ms-outlook';
    } else if (extension?.match('(eml|msg)')) {
      mimeType = 'message/rfc822';
    } else if (extension?.match('(zip)')) {
      mimeType = 'application/zip';
    } else if (extension?.match('(ico)')) {
      mimeType = 'image/x-icon';
    } else if (extension?.match('p7m')) {
      mimeType = 'application/pdf';
    }
    return mimeType;
  }
}

@Pipe({
  name: 'extensionTypePipe'
})
export class ExtensionTypePipe implements PipeTransform {
  transform(nomeFile: string) {
    let nomeFileWithoutExt = '';
    const extensionRegex = /(?:\.([^.]+))?$/;
    const cancelExtension = extensionRegex.exec(nomeFile)[1];
    nomeFileWithoutExt = nomeFile.replace("." + cancelExtension, "");
    return nomeFileWithoutExt;
  }
}


@Pipe({
  name: 'fileNamePipe'
})
export class FileNamePipe implements PipeTransform {
  transform(nomeFile: string) {
    return nomeFile.split('.')[0];
  }
}


