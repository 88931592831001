import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CUP } from '../../model/cup';
import { Stato } from '../../model/stato';
import { CupService } from 'src/app/service/cup.service';
import { SharedService } from 'src/app/service/shared.service';
import "jspdf-autotable";
import { CurrencyPipe } from '@angular/common';
import { QEService } from 'src/app/service/qe.service';
import { Ruolo, SearcherUserDto, TipoPermesso, Utente } from 'src/app/model/utente';
import { ScenarioService } from 'src/app/service/scenario.service';
import { Dizionario, ModelloDocumentaleCustom, Scenario, TipologiaDizionario } from 'src/app/model/scenari';
import { NgxSpinnerService } from 'ngx-spinner';
import { TipoEntita } from 'src/app/model/documento';
import { UserClientService } from 'src/app/service/user-client.service';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';
import { DialogModel } from '../shared/rbk-modal/rbk-modal';

interface Column {
	field: string;
	header: string;
	customExportHeader?: string;
}

interface ExportColumn {
	title: string;
	dataKey: string;
}

@Component({
	selector: 'app-cup-list',
	templateUrl: './cup-list.component.html',
	styleUrls: ['./cup-list.component.scss']
})
export class CupListComponent {
	selectedStato: Stato = new Stato("attivo");
	theForm: FormGroup;
	cups: CUP[] = []
	filteredCup: CUP[]
	selectedCup: CUP;
	newCup: boolean = false;
	visible: boolean = false;
	idCup: number = 0;
	exportColumns: ExportColumn[];
	user: Utente;
	id: number;
	stepQE: boolean = false;
	// Id dell'elemento appena creato da inviare all'onchange per procedere con il salvataggio del documento se collegato 
	entityIdAdd: number;

	showAddCup: boolean = false;

	stati: Stato[] = [
		{ stato: 'attivo' },
		{ stato: 'in programmazione' },
		{ stato: 'chiuso' },
		{ stato: 'dismesso' },
		{ stato: 'tutti' },
	];
	
	/**
	 * Nome del ,mpdello da vedere nella header e da passare al documento
	 */
	public nomeModello = 'CUP';
	/**
	 * Settaggi per i dati della Form
	 */
	public datiModelloDocumentale: ModelloDocumentaleCustom;
	/**
	 * FormGroup
	 */
	public formGroup: FormGroup;
	/**
	 * gli stati dei CUP derivano dal dizionario
	 */
	public statiDisponibili: Dizionario[];
	public RuoloPipe = new RuoloPipe();
	public isUtenteAbilitato: boolean = false;
	public dialogModel: DialogModel;
	public visibleDialog: boolean = false;

	constructor(
		private fb: FormBuilder,
		private route: Router,
		private cupServices: CupService,
		private sharedServices: SharedService,
		private qeService: QEService,
		private userClientService: UserClientService,
		private scenarioServices: ScenarioService,
		private spinner: NgxSpinnerService,
		private _sharedServices: SharedService
	) { }	

	async ngOnInit() {
		this.spinner.show();
		this.user = this.userClientService.getUser();
		if(this.user && this.user.roles && 
			(this.user?.roles === this.RuoloPipe.transform(Ruolo.SuperAdministrator) 
			|| this.user?.roles === this.RuoloPipe.transform(Ruolo.administrator))) {
				this.isUtenteAbilitato = true;
		}
		localStorage.removeItem('idCup');
		localStorage.removeItem('titoloCup');

		// Pulizia del valore del CUPId entrando nella lista dei cup
		this.sharedServices.setCupId(undefined);

		this.theForm = this.fb.group({
			stato: new FormControl(null),
		});

		this.statiDisponibili = [
			{
				"descrizione": "Tutti",
				"attivo": true,
				"categoria": 1,
				"readonly": false,
				"id": 0
			}
		]

    // ottengo tutti gli stati possibili dei CUP
		const dizionarioStati = await this.scenarioServices.getAllDescrizioniDizionario(TipologiaDizionario.CUP_Stato).toPromise();
		if(dizionarioStati && dizionarioStati.success){
			for(let diz of dizionarioStati.dtos){
				this.statiDisponibili.push(diz);
			}
		}

		// Verfico se atterro su questa pagina dopo la navigazione da Cartella Monitorata
		const dati = this._sharedServices.getParametriNavigaDaDocumentiArchviazione();
		if (dati && dati?.sezione === 'Cup') {
			this.showDialog();
			this.spinner.hide();
		}
		await this.loadAllCups();
	}

	public async loadAllCups() {
		const response = await this.cupServices.getAllCups().toPromise();
		if (response) {
			this.cups = response;
			this.filtraCupStato("attivo");
			const statoFind = this.statiDisponibili.find(s => s.descrizione.toLocaleLowerCase() === "attivo");
			this.theForm.controls['stato'].setValue(statoFind.descrizione);
			this.spinner.hide();
		}
	}

	async showDialog() {
		this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Cup);
	  let resp = await this.scenarioServices.getAllDescrizioniDizionario(TipologiaDizionario.CUP_Stato).toPromise();
		let dizionarioCup: Dizionario[];
		if(resp && resp.success){
			dizionarioCup = resp.dtos;
		}
		if (this.datiModelloDocumentale) {
			this.datiModelloDocumentale.formGroup = this.scenarioServices.getFormGroup(Scenario.Cup);
			this.datiModelloDocumentale.idCup = 0;
			this.datiModelloDocumentale.isEdit = false;
			this.datiModelloDocumentale.entityID = 0;
			this.datiModelloDocumentale.entityTipo = TipoEntita.SchedaCup;
			this.datiModelloDocumentale.DatiForm[6].lista = dizionarioCup.filter(x=> x.attivo).map(d => d.descrizione);
		}
		this.showAddCup = true;
	}

	/**
	 * Aggiungo un nuovo Cup
	 * @param cupForm la form con i campi del cup da salvare
	 */
	public async addCup(cupForm: FormGroup<any>) {
		this.spinner.show();
		if (cupForm && cupForm.value) {
			const resultAddCup = await this.cupServices.submitCup(cupForm.value).toPromise();
			if (resultAddCup) {
				this.entityIdAdd = resultAddCup;
				this.datiModelloDocumentale.entityID = resultAddCup;
				this.datiModelloDocumentale.idCup = resultAddCup;
				this.idCup = resultAddCup;
				this.stepQE = true;
				this.scenarioServices.showSuccessMessage('CUP salvato con successo');
				this.sharedServices.changeActiveIndex(0);
				 setTimeout(() => {
					this.spinner.hide();
					this.route.navigateByUrl('cup/' + this.idCup);
				}, 5000);
				// await this.loadAllCups();
			} else {
				this.scenarioServices.showErrorMessage('Errore nel salvataggio del CUP');
				this.spinner.hide();
			}
		}
		this.sharedServices.setCupId(this.idCup);
	}

	/**
	 * Chiusura della modale per aggiungere il Cup alla Form
	 */
	onClose(event) {
		this.showAddCup = false;
	}

	/**
	 * Doppio Click sulla row del Cup, navigazione verso lo scenario
	 * @param idCup 
	 */
	public async dblclickCUP(idCup: number) {
		delete this.idCup;
		if (this.cups && this.cups.length > 0) {
			const notPermesso = await this.getAllNotPermessi(idCup);
			if(notPermesso) {
				this.dialogModel = {
					title: "Attenzione",
					message: "Il Cup selezionato non può essere aperto perchè non ha nessun permesso associato",
					btnConferma: "Ok",
					hasBtnAnnulla: false
				};
				this.visibleDialog = true;
			} else {
				this.sharedServices.changeActiveIndex(1);
				this.sharedServices.setCupId(idCup);
				this.route.navigateByUrl('cup/' + idCup);
			}
		}
	}

	/**
	 * Recupero i permessi associati all'utente per questo cup
	 * @returns ritorna true se tutte le aree non hanno permessi
	 */
	public async getAllNotPermessi(idCup: number) {
		let notAllPermesso: boolean = false;
		const searcherAuth = new SearcherUserDto();
		searcherAuth.clientId = this.user.clientId;
		searcherAuth.idCup = idCup;
		searcherAuth.username = this.user.userName;
		const area = await this.userClientService.getArea().toPromise();
		let aree = [];
		if(area && area.success) {
			aree = area.dtos;
		}
		const response = await this.userClientService.getUserAuth(searcherAuth).toPromise();
		if(response && response.success && response.dto && response.dto.auths) {
			if(aree.length === response.dto.auths.length) {
				notAllPermesso = response.dto.auths.every(permesso => permesso.tipoPermesso === TipoPermesso.None);
			}
		}
		return notAllPermesso;
	}

	cutText(string: string) {
		return string.slice(0, 1)
	}

	/**
	 * Filtra la lista dei cup in base allo stato passato 
	 */
	public filtraCupStato(stato: string) {
		this.filteredCup = [];
		if (this.cups && this.cups.length > 0) {
			this.filteredCup = (stato === "Tutti") ? this.cups : this.cups.filter(item => item?.stato.toLocaleLowerCase() === stato.toLocaleLowerCase());
			this.filteredCup = this.filteredCup.sort((a, b) => b.id - a.id);
		}
	}

	async createPdf() {
		const cupIds = this.filteredCup && this.filteredCup.map(cup => cup.id);
		const cupTotali = await this.qeService.getTotaliCupAsync(cupIds).toPromise();
		if (cupTotali && cupTotali.success) {
			import('jspdf').then((jsPDF) => {
				const doc = new jsPDF.default('l', 'px', 'a4');
				const rows = [];
				for (const x of cupTotali.dtos) {
					rows.push({
						codice: x.codice,
						titolo: x.titolo,
						descrizione: x.descrizione,
						importo: new CurrencyPipe('it-IT').transform(x.importo, 'EUR'),
						totaleQE: new CurrencyPipe('it-IT').transform(x.totaleQE, 'EUR'),
						impegni: new CurrencyPipe('it-IT').transform(x.impegni, 'EUR'),
						giustificativi: new CurrencyPipe('it-IT').transform(x.giustificativi, 'EUR'),
						liquidazioni: new CurrencyPipe('it-IT').transform(x.liquidazioni, 'EUR'),
						mandati: new CurrencyPipe('it-IT').transform(x.mandati, 'EUR'),
						stato: x.stato
					});
				}
				(doc as any).autoTable({
					columns: [
						{ header: 'Codice Cup', dataKey: 'codice' },
						{ header: 'Titolo', dataKey: 'titolo' },
						{ header: 'Descrizione', dataKey: 'descrizione' },
						{ header: 'Importo Cup', dataKey: 'importo' },
						{ header: 'Quadro Economico', dataKey: 'totaleQE' },
						{ header: 'Impegni', dataKey: 'impegni' },
						{ header: 'Giustificatvi', dataKey: 'giustificativi' },
						{ header: 'Liquidazioni', dataKey: 'liquidazioni' },
						{ header: 'Mandati', dataKey: 'mandati' },
						{ header: 'Stato', dataKey: 'stato' },
					],
					body: rows,
					margin: { top: 35 },
					didDrawPage: function (data) {
						doc.text("ELENCO CUP", 270, 20);
					},
					columnStyles: {
						codice: { cellWidth: 72 },
						importo: { cellWidth: 60 },
						totaleQE: { cellWidth: 55 },
						impegni: { cellWidth: 55 },
						giustificativi: { cellWidth: 55 },
						liquidazioni: { cellWidth: 55 },
						mandati: { cellWidth: 55 },
						stato: { cellWidth: 30 },
					},
					didParseCell: data => {
						if (data.cell) {
							data.cell.styles.valign = 'middle';
						}
					}
				})
				doc.save("ELENCO CUP.pdf");
			});
		}
	}

}
