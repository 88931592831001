import { FormGroup } from "@angular/forms";
import { CUP } from "./cup";
import { TipoEntita } from "./documento";

export enum Scenario {
  Nessuno,
  Cup,
  Spesa,
  Giustificativo,
  Liquidazione,
  Mandato,
  Quietanza,
  Annotazione,
  Documento,
  Fonte,
  Incasso,
  QuadroEconomico,
  DocumentiDaArchiviareBrowse,
  ModelloDocumentaleStart,
  ModelloDocumentale
}

export enum TipologiaDizionario {
  // 
  // Indica lo stato del CUP
  // 
  CUP_Stato = 0,
  // 
  // Indica la tipologia dell'atto spesa e del QEBozza
  //
  Atto = 1,
  //
  // Indica la tipologia del tipo bozza
  //
  QEBozza_Tipo = 2,
  //
  // Indica la tipologia del tipo spesa
  //
  Spesa_Tipo = 3,
  //
  // Indica la tipologia del tipo di giustoificativo
  //
  Giustificativo_Tipo = 4,
  //
  // Indica la tipologia di ente in fonte
  //
  Fonte_Ente = 5,
  //
  // Indica la tipologia di atto in fonte
  //
  Fonte_Atto = 6,
  //
  // Indica la tipologia di atto in incassato
  // 
  Incasso_Atto = 7
}

export class DizionarioResponse {
  success: boolean;
  dtos: Dizionario[];
}

export class Dizionario {
  id: number;
  descrizione: string;
  attivo: boolean;
  categoria: TipologiaDizionario;
  readonly?: boolean;
  hasFase?: boolean;

  constructor(categoria: TipologiaDizionario){
    this.id = 0;
    this.descrizione = "Tutti";
    this.attivo = true;
    this.categoria = categoria;
    this.readonly = false;
    this.hasFase = false;
  }
}

export class Campo {
  key?: string;
  label?: string;
  tipo?: 'text' | 'lista' | 'autocomplete' | 'radio' | 'checkbox' | 'textarea' | 'number' | 'date' | 'currency' | 'allegato' | 'hidden' | 'button';
  dimensioneBasic?: number;
  lista?: any;
  listaAutoComplete?: any;
  optionLabel?: string;
  optionValue?: string;
  classeCss?: string;
  placeholder?: string;
  hasAction?: boolean;
  onChangeNameFunction?: string;
  functionName?: string;
  readonly?: boolean;
  errorMessage?: string;
  visible?: boolean;

  // Btn 
  icon?: string;
  classColor?: string;
  pTooltip?: boolean;
  tooltipPosition?: 'right' | 'left' | 'top' | 'bottom';
  btnLabel?: string;

  constructor() {
    this.visible = true;
    this.dimensioneBasic = 12;
    this.lista = [];
    this.label = '';
  }

}

export class Azioni {
  nameButton: string;
  typeButton: 'primary' | 'secondary';
}

export class ModelloDocumentaleCustom {
  isFrom: Scenario;
  DatiForm: Campo[];
  DatiFormDocumento?: Campo[];
  idCup: number;
  cup: CUP
  entityID?: number;
  entityTipo?: TipoEntita;
  documentoID?: number;
  isEdit: boolean;
  formGroup: FormGroup;
}

export class Attributo {
  id?: string;
  inputId?: string;
  mode?: string;
  currency?: string;
  locale?: string;
  rows?: number;
  cols?: number;
}

