<div class="d-flex flex-row">
  <div class="flex-1 m-2">
    <div class="header row titolone">
      <div class="col-md-9">
        <!-- <h1 style="font-size: 1.6em; font-weight: 700">ANNOTAZIONI</h1> -->
      </div>
      <div class="col-md-3" style="float:right">
        <button *ngIf="!permesso || permesso === TipoPermesso.ReadWrite || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
          style="float:right" pButton label="ANNOTAZIONE" icon="pi pi-plus" (click)="showDialogForm(false)"></button>
      </div>
    </div>

    <p-dialog *ngIf="visible" header="Annotazione" [(visible)]="visible" [contentStyle]="{'overflow':'visible'}"
      [modal]="true" [draggable]="true" [maximizable]="true">
      <upload-file *ngIf="datiModelloDocumentale?.formGroup" nomeModello="Annotazione" [cup]="cup" [user]="user" [permesso]="permesso"
        [datiModelloDocumentale]="datiModelloDocumentale" [entityIdAdd]="entityIdAdd" (add)="addAnnotazione($event)"
        (save)="saveAnnotazione($event)" (close)="closeModal()"></upload-file>
    </p-dialog>

    <p-card>
      <p-table [value]="filteredTab" selectionMode="single" [(selection)]="selectedAnnotazione" dataKey="codice"
        [paginator]="true" [rows]="5" [showCurrentPageReport]="true"
        currentPageReportTemplate="Da {first} a {last} di {totalRecords} annotazioni"
        [rowsPerPageOptions]="[5, 10, 25, 50]">

        <ng-template pTemplate="header">
          <tr>
            <th style="width: 10%;">Data</th>
            <th style="width: 50%;">Descrizione</th>
            <th>Note</th>
            <!-- <th>Allegato</th> -->
            <th style="width: 10%;"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-annotazione>
          <tr [pSelectableRow]="annotazione"> <!-- (dblclick)="selectedRow(annotazione.id)" -->
            <td>{{ annotazione.data | date:"dd/MM/yy"}}</td>
            <td>
              {{(annotazione.descrizione.length>6)? (annotazione.descrizione |
              slice:0:160)+'...':(annotazione.descrizione)}}
            </td>
            <td>
              {{(annotazione.nota.length>6) ? (annotazione.nota | slice:0:100)+'...':(annotazione.nota)}}
            </td>
            <!-- <td>{{ annotazione.allegato }}</td> -->
            <td>
              <div style="display: flex;">
                <p-button type="button" icon="pi pi-pencil" styleClass="p-button-text" (click)="showDialogForm(true, annotazione)"></p-button>
                <p-button *ngIf="!permesso || permesso === TipoPermesso.ReadWriteDelete || user.roles === RuoloPipe.transform(Ruolo.SuperAdministrator)"
                  type="button" icon="pi pi-trash" styleClass="p-button-text p-button-danger" (click)="confirm(annotazione.id)"></p-button>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>

  </div>

</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<p-toast></p-toast>