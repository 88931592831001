import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { NgxSpinnerService } from 'ngx-spinner';
import { Utente } from 'src/app/model/utente';
import { UserClientService } from 'src/app/service/user-client.service';


@Component({
	selector: 'app-utente',
	templateUrl: './utente.component.html',
	styleUrls: ['./utente.component.scss'],
	providers: [ConfirmationService, MessageService]
})


export class UtenteComponent implements OnChanges {

  //@Input() utente: Utente;
  @Input() clientId: string;
  @Input() isInEdit: boolean;
  @Input() visible: boolean;

  @Output() closeDialog = new EventEmitter<boolean>()

  utente: Utente;

  formUtente: FormGroup = new FormGroup({
    id: new FormControl(),
    qualification: new FormControl(),
    firstName: new FormControl(),
    lastName: new FormControl(),
    userName: new FormControl(),
    createdAt: new FormControl(),
    address: new FormControl(),
    city: new FormControl(),
    province: new FormControl(),
    fiscalCode: new FormControl(),
    phoneNumber: new FormControl(),
    mobilePhoneNumber: new FormControl(),
    isLocked: new FormControl(),
    roles: new FormControl(),
    clientId: new FormControl(),
    //emailConfirmed: new FormControl(),
    //passwordLocked: new FormControl(),

  });


  constructor(
    private userClientService: UserClientService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private routes: Router,
  ){}

  ngOnChanges(changes: SimpleChanges): void {

    const utenteChange = changes['utente'];
    if (utenteChange && utenteChange.currentValue ) {
      this.setUtente()
    }
  }

  setUtente() {
    if(this.utente){
      this.formUtente.setValue({

        id: this.utente.id,
        qualification: this.utente.qualification,
        firstName: this.utente?.firstName,
        lastName: this.utente.lastName,
        userName: this.utente.userName,
        createdAt: this.utente.createdAt,
        address: this.utente.address,
        city: this.utente.city,
        province: this.utente.province,
        fiscalCode: this.utente.fiscalCode,
        phoneNumber: this.utente.phoneNumber,
        mobilePhoneNumber: this.utente.mobilePhoneNumber,
        isLocked: this.utente.isLocked,
        roles: this.utente.roles,
        clientId: this.utente.clientId,
        //emailConfirmed: this.utente.emailConfirmed,
        //passwordLocked: this.utente.passwordLocked,

      })
    }else{
      this.formUtente = new FormGroup({
        id: new FormControl(''),
        qualification: new FormControl(''),
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        userName: new FormControl('', Validators.required),
        createdAt: new FormControl(''),
        address: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
        province: new FormControl('', Validators.required),
        fiscalCode: new FormControl('', [this.cf_piPatternValidator(/^([0-9]){11}$/, /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/)]),
        phoneNumber: new FormControl(''),
        mobilePhoneNumber: new FormControl('',[Validators.pattern('^[0-9]{10}$'), Validators.required]),
        isLocked: new FormControl(),
        roles: new FormControl(),
        clientId: new FormControl(),
        //emailConfirmed: new FormControl(),
        //passwordLocked: new FormControl(),

      });
    }
  }


  ngOnInit(){
    this.utente = this.userClientService.getUser()
    this.formUtente.setValue({
      address: this.utente.address,
      city: this.utente.city,
      clientId: this.utente.clientId,
      createdAt: this.utente.createdAt,
      firstName: this.utente.firstName || '',
      fiscalCode: this.utente.fiscalCode,
      isLocked: this.utente.isLocked,
      id: this.utente.id,
      lastName: this.utente.lastName || '',
      mobilePhoneNumber: this.utente.mobilePhoneNumber,
      phoneNumber: this.utente.phoneNumber,
      province: this.utente.province,
      qualification: this.utente.qualification,
      roles: this.utente.roles,
      userName: this.utente.userName
    })
  }

  cf_piPatternValidator(pattern1: RegExp, pattern2: RegExp): ValidatorFn {
    return (control: FormControl) => {
      const value = control.value;
      const isValid = pattern1.test(value) || pattern2.test(value);
      return isValid ? null : { patternMismatch: true };
    };
  }

  createUser(){
    this.utente = this.formUtente.value;
    this.utente.createdAt =  new Date
    this.spinner.show()
    this.userClientService.createUser(this.utente, true, this.clientId).subscribe({
      next:(resp: Utente) => {
        this.utente = resp
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Utente aggiunto con successo' });
        this.spinner.hide();
        this.routes.navigateByUrl('')
      },
      error: (err) =>{
        this.spinner.hide();
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore in fase di registrazione' });
      }
    })
  }

  close(){
    this.visible = false;
    this.utente = undefined;
    this.closeDialog.emit();
  }
}
