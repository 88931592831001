import { Component, Input } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { AreaDto, AuthDto, GestioneUtente, Ruolo, SearcherUserDto, TipoPermesso, UserAuthDto, Utente } from 'src/app/model/utente';
import { PermessoPipe, TipoPermessoPipe } from 'src/app/pipe/permessi-pipe';
import { UserClientService } from 'src/app/service/user-client.service';
import { DialogModel } from '../../shared/rbk-modal/rbk-modal';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';


@Component({
  selector: 'app-gestione-utenti-form',
  templateUrl: './gestione-utenti-form.component.html',
  styleUrls: ['./gestione-utenti-form.component.scss']
})
export class GestioneUtentiFormComponent {
  @Input() public idCup: number;
  
  utenti: GestioneUtente[];
  disabled: boolean = true

  selectedCategories: any[] = [];
  user: Utente;
  users: Utente[] = [];


  tabs: AreaDto[];
  userPermessiArea: AuthDto[] = [];

  ruoli = [
    // { ruolo: 'SuperAdmin' },
    { ruolo: 'Admin' },
    // { ruolo: 'Utente' },
  ];
  public tipoPermesso = new TipoPermessoPipe();
  public permessi = [
    {permesso: this.tipoPermesso.transform(TipoPermesso.None)},
    {permesso: this.tipoPermesso.transform(TipoPermesso.Read)},
    {permesso: this.tipoPermesso.transform(TipoPermesso.ReadWrite)},
    {permesso: this.tipoPermesso.transform(TipoPermesso.ReadWriteDelete)}
  ];

  formGestioneUtente: FormGroup;
  percorsoGroup: FormGroup;
  isDisabled: boolean = false;
  isLoadingPermessi: boolean = false;
  public dialogModel: DialogModel;
  public visibleDialog: boolean = false;
  public labelSavePermessi: string = 'SALVA PERMESSI';
  public infoTooltip: string = 'Le tab che non sono state selezionate/checkate avranno tutti i permessi abilitati per questo Cup';


  constructor(
    private fb: FormBuilder,
    private userClientService: UserClientService,
    private spinner: NgxSpinnerService,
  ) {
    this.formGestioneUtente = this.fb.group({
      id: new FormControl(''),
      utente: new FormControl('', Validators.required),
      percorsi: this.fb.array([])
    });

    this.percorsoGroup = this.fb.group({
      tab: new FormControl(),
      permesso: new FormControl(),
    });
  }

  get formGestioneUtenteArray() {
    return this.formGestioneUtente.get('percorsi') as FormArray;  
}

  async ngOnInit() {
    this.user = this.userClientService.getUser();
    this.spinner.show();
    const area = await this.userClientService.getArea().toPromise();
    if(area && area.success) {
      this.tabs = area.dtos;
    }

    const users = await this.userClientService.getUsers(this.user.clientId).toPromise();
    if(users) {
      this.users = users.filter(user => <any>user.roles instanceof Array && user.roles.length > 0);
    }
    this.spinner.hide();
  }

  async onChangeUtente(utente: any) {
    this.isLoadingPermessi = true;
    this.formGestioneUtenteArray.clear()
    const searcherAuth = new SearcherUserDto();
    searcherAuth.clientId = this.user.clientId;
    searcherAuth.idCup = this.idCup;
    searcherAuth.username = utente;
    if(utente) {
      const response = await this.userClientService.getUserAuth(searcherAuth).toPromise();
      if(response && response.success && response.dto && response.dto.auths && response.dto.auths.length > 0) {
        this.userPermessiArea = response.dto.auths;
        for (let tab of this.tabs) {
          for (let auth of this.userPermessiArea) {
            const authFound = this.userPermessiArea.find(auth => auth.idArea === tab.id);
            if(tab.id === auth.idArea) {
              this.addPercorso(false, auth);
              break;
            } else if(!authFound) {
              const tabFound = this.formGestioneUtenteArray.controls.find(c => c.get('id').value === tab.id);
              if(!tabFound) {
                this.addPercorso(false, tab);
              }
            }
          } 
        }
        this.formGestioneUtenteArray.disable();
        this.labelSavePermessi = 'MODIFICA PERMESSI';
      } else {
        this.userPermessiArea = [];
        for (let elem of this.tabs) {
          this.addPercorso(true, elem);
        }
        this.labelSavePermessi = 'SALVA PERMESSI';
      }
      this.formGestioneUtenteArray.controls.forEach( control => {
        if(control.get('tab').value && control.get('tab').value.length === 0) {
          control.get('permesso').disable();
          control.get('permesso').reset();
        }
      });
    }
    
    this.isLoadingPermessi = false;
  }

  addPercorso(isValidator: boolean, elem: any) {
    const percorsoGroup = this.fb.group({ 
      id: elem.id || elem.idArea,
      tab: new FormControl(elem.id ? [] : [elem.idArea]),
      permesso: new FormControl(elem.id ? '' : new TipoPermessoPipe().transform(elem.tipoPermesso))
    });
    this.formGestioneUtenteArray.push(percorsoGroup);
  }


  change(event, percorsoGroup?, i?) {
    if (event.checked.length !== 0) {
      percorsoGroup.get('permesso').enable();
      percorsoGroup.get('permesso').setValidators(Validators.required);
    } else {
      percorsoGroup.get('permesso').setValue(null);
      percorsoGroup.get('permesso').disable();
      percorsoGroup.get('permesso').clearValidators();
      this.onChangePermesso(percorsoGroup, i);
    }
    percorsoGroup.get('permesso').updateValueAndValidity();
  }

  onChangePermesso(percorsoGroup: AbstractControl, index?: number) {
    const permesso = percorsoGroup.get('permesso').value;
    const idArea = percorsoGroup.get('tab').value[0] || index + 1;

    let authPermesso: AuthDto = {
      idArea: idArea,
      idCup: this.idCup,
      tipoPermesso: new PermessoPipe().transform(permesso)
    };

    if (this.userPermessiArea && this.userPermessiArea.length > 0) {
      let userPermesso = authPermesso;
      const userPermessoFind = this.userPermessiArea.find(p => p.idArea === idArea);
      if (userPermessoFind) {
        _.remove(this.userPermessiArea, userPermessoFind);
        userPermessoFind.tipoPermesso = new PermessoPipe().transform(permesso);
        userPermesso = userPermessoFind;
      }
      if (!userPermessoFind || userPermessoFind && userPermessoFind.tipoPermesso !== null) {
        this.userPermessiArea.push(userPermesso);
      }
    } else {
      this.userPermessiArea.push(authPermesso);
    }
    this.userPermessiArea = _.sortBy(this.userPermessiArea, (permesso) => this.userPermessiArea.indexOf(permesso));
  }

  async savePermessi() {
    this.spinner.show();
    if(this.labelSavePermessi === 'SALVA PERMESSI') {
      const userAddOrUpdate = new UserAuthDto();
      userAddOrUpdate.auths = [];
      userAddOrUpdate.userName = this.formGestioneUtente.get('utente').value;
      if (this.userPermessiArea && this.userPermessiArea.length === 0) {
        this.dialogModel = {
          title: "Attenzione",
          message: "Non è stato selezionato nessun permesso",
          btnConferma: "Ok",
          hasBtnAnnulla: false
        };
        this.visibleDialog = true;
      } else {
        this.userPermessiArea.forEach(auth => {
          if(auth) {
            userAddOrUpdate.auths.push(auth);
          }
        });
        const response = await this.userClientService.addOrUpdateUserAuth(userAddOrUpdate).toPromise();
        if(response && response.success) {
          this.dialogModel = {
            title: "Successo",
            message: `I permessi per l'utente: <br/><strong>${response.dto.userName}</strong><br/> sono stati salvati correttamente`,
            btnConferma: "Ok",
            hasBtnAnnulla: false
          };
          this.visibleDialog = true;
          this.formGestioneUtenteArray.disable();
          this.labelSavePermessi = 'MODIFICA PERMESSI';
        }
      }
    } else {
      this.formGestioneUtente.enable();
      this.formGestioneUtenteArray.controls.forEach( control => {
        if(control.get('tab').value && control.get('tab').value.length === 0) {
          control.get('permesso').disable();
          control.get('permesso').reset();
        }
      });
      this.labelSavePermessi = 'SALVA PERMESSI';
    }
    this.spinner.hide();
  }

  onConfirmPopup() {
    this.visibleDialog = false;
  }

}
