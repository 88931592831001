<div *ngIf="qeCompare">

  <p-accordion class="w-full">

    <div style="padding-bottom: 2em">
      <rbk-export-excel *ngIf="qeCompare" [qeCompare]="qeCompare" [cup]="cup"
        [tipoEsportazione]="esportaExcelTipo.Raffronto"></rbk-export-excel>

      <div class="row my-2">
        <div class=" px-2 d-flex align-items-center flex-row pb-1">
          <div class="p-inputgroup">
            <span pInputText [ngStyle]="{'max-width': '7em'}" style="border: none; font-weight: 700;"
              value="INDICE">INDICE</span>
            <span pInputText style="border: none; font-weight: 700; text-align: left;"
              value="DESCRIZIONE">DESCRIZIONE</span>

            <span *ngIf="qe.publishedAt" pInputText class="tableElm"
              [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'center'}"
              value="">QE PRECEDENTE</span>
            <!-- QE: {{oldQE.detail.tipo}}{{oldQE.detail.atto}} {{oldQE.detail.numero}} del {{oldQE.publishedAt | date:'dd/MM/yyyy'}} -->
            <span *ngIf="!qe.publishedAt" pInputText class="tableElm"
              [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'center'}"
              value="">QE ATTUALE</span>
            <!-- QE: {{oldQE.detail.tipo}}{{oldQE.detail.atto}} {{oldQE.detail.numero}} del {{oldQE.publishedAt | date:'dd/MM/yyyy'}} -->

            <span *ngIf="qe.publishedAt" pInputText class="tableElm"
              [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'center'}"
              value="">QE IN
              ESAME</span><!-- QE: {{qe.detail.tipo}} {{qe.detail.atto}} {{qe.detail.numero}} del {{qe.publishedAt | date:'dd/MM/yyyy'}} -->
            <span *ngIf="!qe.publishedAt" pInputText class="tableElm"
              [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'center'}"
              value="Bozza">BOZZA</span>

            <span pInputText class="tableElm"
              [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'center'}"></span>
          </div>
        </div>
      </div>


      <div class="my-2" *ngFor="let section of qeCompare.items">

        <div *ngFor="let item of section.items">
          <div class="row my-1" *ngIf="item.firstSubIndex === 0 && item.secondSubIndex === 0">
            <div class=" px-2 d-flex align-items-center flex-row pb-1">
              <div class="p-inputgroup"
                id="header_{{section.firstSectionIndex || section.secondSectionIndex}}_{{item.firstIndex || item.secondIndex}}">
                <span style="color:#444; width: 5%" class="p-inputgroup-addon qe-section-header">
                  <!-- id="header_{{section.index}}_{{header.index}}" -->
                  <!-- {{indexToTitle(section.firstSectionIndex) || indexToTitle(section.secondSectionIndex)}}.{{item.firstIndex || item.secondIndex}} -->
                  {{(section.firstSectionTitle === "" ? section.secondSectionTitle : section.firstSectionTitle)}}.{{item.firstIndex || item.secondIndex}}
                </span>

                <input pInputText
                  [pTooltip]="(item.firstSectionTitle !== item.secondSectionTitle) ? item.secondSectionTitle : ''"
                  [ngClass]="[(item.firstSectionTitle !== item.secondSectionTitle) && !item.firstSectionTitle ? 'removeTitle' : '', (item.firstSectionTitle !== item.secondSectionTitle) && !item.secondSectionTitle ? 'addTitle' : '', (item.firstSectionTitle !== item.secondSectionTitle) ? 'diffTitle' : 'sameTitle']"
                  id="header_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstSectionTitle}}_titolo"
                  [readOnly]="!isInEdit" [ngModel]="item.firstSectionTitle">

                <!-- <input pInputText
                    id="header_{{section.firstSectionTitle || section.secondSectionTitle}}_{{header.secondSectionTitle}}_titolo"
                    [readOnly]="!isInEdit" [ngModel]="header.secondSectionTitle"> -->
                <p-inputNumber
                  id="header_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.secondSectionTitle}}_importo"
                  [ngModel]="item.secondTotal" placeholder="Importo" class="p-right header" mode="currency"
                  currency="EUR" [ngStyle]="{'max-width': '10em'}" locale="de-DE" [readonly]="!isInEdit">
                </p-inputNumber>

                <p-inputNumber
                  id="header_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstSectionTitle}}_importo"
                  [ngModel]="item.firstTotal" placeholder="Importo" class="p-right header" mode="currency"
                  currency="EUR" [ngStyle]="{'max-width': '10em'}" locale="de-DE" [readonly]="!isInEdit">
                </p-inputNumber>

                <p-inputNumber id="delta" [ngModel]="item.firstTotal - item.secondTotal" placeholder="Importo"
                  mode="currency"
                  [ngClass]="item.firstTotal - item.secondTotal >= 0 ? 'p-right header colorGreen' : 'p-right header colorRed'"
                  currency="EUR" [ngStyle]="{'max-width': '10em'}" locale="de-DE" [readonly]="!isInEdit">
                </p-inputNumber>

              </div>
            </div>
          </div>

          <div class="mb-2" *ngIf="(item.firstSubIndex !== 0 && item.secondSubIndex === 0) || (item.firstSubIndex === 0 && item.secondSubIndex !== 0)">
            <div class="align-items-center px-3">
              <div class="row align-items-center pb-1">
                <span class="col-sm-12 col-md itemList">
                  <div class="p-inputgroup"
                    id="item_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstIndex || item.secondIndex}}_{{item.firstSubIndex || item.secondSubIndex }}">
                    <span class="p-inputgroup-addon" style="width: 4%">{{(section.firstSectionTitle === "" ? section.secondSectionTitle : section.firstSectionTitle)}}.{{item.firstIndex || item.secondIndex}}.{{item.firstSubIndex || item.secondSubIndex }}</span>
                    <input class="w-full" pInputText
                      [pTooltip]="(item.firstSectionTitle !== item.secondSectionTitle) ? item.secondSectionTitle : ''"
                      [ngClass]="[(item.firstSectionTitle !== item.secondSectionTitle) && !item.firstSectionTitle ? 'removeTitle' : '', (item.firstSectionTitle !== item.secondSectionTitle) && !item.secondSectionTitle ? 'addTitle' : '', (item.firstSectionTitle !== item.secondSectionTitle) ? 'diffTitle' : 'sameTitle']"
                      id="item_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstIndex || item.secondIndex}}_{{item.firstSubIndex || item.secondSubIndex }}"
                      [readonly]="!isInEdit" [ngModel]="item.firstSectionTitle" />


                    <p-inputNumber [ngStyle]="{'min-width': '10em', 'text-align': 'right'}" class="p-right item"
                      id="item_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstIndex || item.secondIndex}}_{{item.firstSubIndex || item.secondSubIndex }}_importo"
                      [ngModel]="item.secondTotal" placeholder="Importo" mode="currency" [readonly]="!isInEdit"
                      currency="EUR" locale="it-IT">
                    </p-inputNumber>

                    <p-inputNumber [ngStyle]="{'min-width': '10em', 'text-align': 'right'}" class="p-right item"
                      id="item_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstIndex || item.secondIndex}}_{{item.firstSubIndex || item.secondSubIndex }}_importo"
                      [ngModel]="item.firstTotal" placeholder="Importo" mode="currency" [readonly]="!isInEdit"
                      currency="EUR" locale="it-IT">
                    </p-inputNumber>

                    <p-inputNumber id="deltaItem" [ngStyle]="{'min-width': '10em', 'text-align': 'right'}"
                      [ngModel]="item.firstTotal - item.secondTotal" placeholder="Importo" mode="currency"
                      [readonly]="!isInEdit"
                      [ngClass]="item.firstTotal - item.secondTotal >= 0 ? 'p-right item colorGreen' : 'p-right item colorRed'"
                      currency="EUR" locale="it-IT">
                    </p-inputNumber>

                  </div>
                </span>
              </div>
            </div>
          </div>


          <div class="mb-2" *ngIf="item.firstSubIndex !== 0 && item.secondSubIndex !== 0">
            <div class="align-items-center px-3">
              <div class="row align-items-center pb-1">
                <span class="col-sm-12 col-md itemList">
                  <div class="p-inputgroup"
                    id="item_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstIndex || item.secondIndex}}_{{item.firstSubIndex || item.secondSubIndex }}">
                    <span class="p-inputgroup-addon" style="width: 4%">{{indexToTitle(section.firstSectionIndex) || indexToTitle(section.secondSectionIndex)}}.{{item.firstIndex || item.secondIndex}}.{{item.firstSubIndex || item.secondSubIndex }}</span>
                    <input class="w-full" pInputText
                      [pTooltip]="(item.firstSectionTitle !== item.secondSectionTitle) ? item.secondSectionTitle : ''"
                      [ngClass]="[(item.firstSectionTitle !== item.secondSectionTitle) && !item.firstSectionTitle ? 'removeTitle' : '', (item.firstSectionTitle !== item.secondSectionTitle) && !item.secondSectionTitle ? 'addTitle' : '', (item.firstSectionTitle !== item.secondSectionTitle) ? 'diffTitle' : 'sameTitle']"
                      id="item_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstIndex || item.secondIndex}}_{{item.firstSubIndex || item.secondSubIndex }}"
                      [readonly]="!isInEdit" [ngModel]="item.firstSectionTitle" />


                    <p-inputNumber [ngStyle]="{'min-width': '10em', 'text-align': 'right'}" class="p-right item"
                      id="item_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstIndex || item.secondIndex}}_{{item.firstSubIndex || item.secondSubIndex }}_importo"
                      [ngModel]="item.secondTotal" placeholder="Importo" mode="currency" [readonly]="!isInEdit"
                      currency="EUR" locale="it-IT">
                    </p-inputNumber>

                    <p-inputNumber [ngStyle]="{'min-width': '10em', 'text-align': 'right'}" class="p-right item"
                      id="item_{{section.firstSectionTitle || section.secondSectionTitle}}_{{item.firstIndex || item.secondIndex}}_{{item.firstSubIndex || item.secondSubIndex }}_importo"
                      [ngModel]="item.firstTotal" placeholder="Importo" mode="currency" [readonly]="!isInEdit"
                      currency="EUR" locale="it-IT">
                    </p-inputNumber>

                    <p-inputNumber id="deltaItem" [ngStyle]="{'min-width': '10em', 'text-align': 'right'}"
                      [ngModel]="item.firstTotal - item.secondTotal" placeholder="Importo" mode="currency"
                      [readonly]="!isInEdit"
                      [ngClass]="item.firstTotal - item.secondTotal >= 0 ? 'p-right item colorGreen' : 'p-right item colorRed'"
                      currency="EUR" locale="it-IT">
                    </p-inputNumber>

                  </div>
                </span>
              </div>
            </div>
          </div>


        </div>

        <div class="row my-2">
          <div class=" px-2 d-flex align-items-center flex-row pb-1">
            <div class="p-inputgroup">
              <input pInputText [readOnly]="!isInEdit" style="border: none; font-weight: 700;"
                value="SEZIONE {{indexToTitle(section.firstSectionIndex) || indexToTitle(section.secondSectionIndex)}}">

              <input pInputText [readOnly]="!isInEdit"
                [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'right'}"
                value="{{section.secondTotal | currency: 'EUR':'symbol':undefined:'it-IT'}}">

              <input pInputText [readOnly]="!isInEdit"
                [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'right'}"
                value="{{section.firstTotal| currency: 'EUR':'symbol':undefined:'it-IT'}}">

              <input pInputText [readOnly]="!isInEdit" id="deltaTotal"
                [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'right'}"
                [ngClass]="section.firstTotal - section.secondTotal   >= 0 ? 'colorGreen' : 'colorRed'"
                value="{{section.firstTotal - section.secondTotal | currency: 'EUR':'symbol':undefined:'it-IT'}}" mode="currency"
                currency="EUR" locale="it-IT">
            </div>
          </div>
        </div>

        <!-- </p-panel> -->
      </div>

      <div class="row my-2">
        <div class=" px-2 d-flex align-items-center flex-row pb-1">
          <div class="p-inputgroup">
            <input pInputText [readOnly]="!isInEdit" style="border: none; font-weight: 700;" value="TOTALE">

            <input pInputText [readOnly]="!isInEdit"
              [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'right'}"
              value="{{totaleSecond | currency: 'EUR':'symbol':undefined:'it-IT'}}">

            <input pInputText [readOnly]="!isInEdit"
              [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'right'}"
              value="{{totaleFirst | currency: 'EUR':'symbol':undefined:'it-IT'}}">

            <input pInputText [readOnly]="!isInEdit" id="deltaTotal"
              [ngStyle]="{'max-width': '10em', 'border':'none', 'font-weight': '700', 'text-align':'right'}"
              [ngClass]="totaleFirst - totaleSecond  >= 0 ? 'colorGreen' : 'colorRed'"
              value="{{totaleFirst - totaleSecond | currency: 'EUR':'symbol':undefined:'it-IT'}}" mode="currency"
              currency="EUR" locale="it-IT">
          </div>
        </div>
      </div>
    </div>

  </p-accordion>
</div>
