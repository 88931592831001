<p-dialog header="Header" [(visible)]="visible" [modal]="true" [style]="{ width: '25vw' }" 
  [closeIcon]="null" [draggable]="false" [resizable]="false" position="center">
  <ng-template pTemplate="header">
      <div style="display: flex; align-items: center;">
        <i *ngIf="dialogModel.title === 'Successo'" class="pi pi-check icon-success"></i>
        <i *ngIf="dialogModel.title === 'Errore'" class="pi pi-times-circle icon-error"></i>
        <i *ngIf="dialogModel.title === 'Attenzione'" class="pi pi-exclamation-triangle icon-warning"></i>
        <i *ngIf="dialogModel.title === 'Info'" class="pi pi-info-circle icon-info"></i>
        <span class="style-header">{{dialogModel.title}}</span>
      </div>
  </ng-template>
  <p style="text-align: center;" [innerHTML]="dialogModel.message"></p>
  <ng-template pTemplate="footer">
    <button pButton (click)="onConfirmModal()" [label]="dialogModel.btnConferma"></button>
    <button pButton *ngIf="dialogModel.hasBtnAnnulla" class="p-button-secondary" (click)="onCancelModal()" [label]="dialogModel.btnAnnulla"></button>
  </ng-template>
</p-dialog>