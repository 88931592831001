<ng-template pTemplate="header">
  <span class="text-xl font-bold">Ciao</span>
</ng-template>

<!-- <div *ngIf="spesaFase1">{{spesaFase1.id}}</div> -->

<form [formGroup]="professionistaForm">

  <div class="row">
    <div class="form-group col-md-3">
      <label for="ruolo" class="col-form-label">Ruolo</label>
      <input formControlName="ruolo" type="text" class="form-control" placeholder="Ruolo">
      <small style="color: red" *ngIf="!professionistaForm.get('ruolo').valid && professionistaForm.get('ruolo').touched">
        Inserisci un ruolo</small>
    </div>
    <div class="form-group col-md-2">
      <label for="titolo" class="col-form-label">Titolo</label>
      <input formControlName="titolo" type="text" class="form-control" placeholder="Titolo">
      <small style="color: red" *ngIf="!professionistaForm.get('titolo').valid && professionistaForm.get('titolo').touched">
        Inserisci un titolo</small>
    </div>
    <div class="form-group col-md-4">
      <label for="nome" class="col-form-label">Nominativo</label>
      <input formControlName="nome" type="text" class="form-control" placeholder="Nominativo">
      <small style="color: red" *ngIf="!professionistaForm.get('nome').valid && professionistaForm.get('nome').touched">
        Inserisci un nominativo</small>
    </div>
    <div class="form-group col-md-3">
      <label for="pi_cf" class="col-form-label">P.I./C.F.</label>
      <input formControlName="pi_cf" type="text" class="form-control" placeholder="P.I./C.F."
        oninput="this.value = this.value.toUpperCase()">
      <small style="color: red"
        *ngIf="!professionistaForm.get('pi_cf').valid && professionistaForm.get('pi_cf').touched">
        Inserisci un P.I./C.F.</small>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-3">
      <label for="indirizzo" class="col-form-label">Indirizzo</label>
      <input formControlName="indirizzo" type="text" class="form-control" placeholder="Indirizzo">
      <small style="color: red" *ngIf="!professionistaForm.get('indirizzo').valid && professionistaForm.get('indirizzo').touched">
        Inserisci un indirizzo</small>
    </div>
    <div class="form-group col-md-3">
      <label for="città" class="col-form-label">Città</label>
      <input formControlName="città" type="text" class="form-control" placeholder="Città">
      <small style="color: red" *ngIf="!professionistaForm.get('città').valid && professionistaForm.get('città').touched">
        Inserisci una città</small>
    </div>
    <div class="form-group col-md-3">
      <label for="telefono" class="col-form-label">Telefono</label>
      <input formControlName="telefono" type="text" class="form-control" placeholder="Telefono">
      <small style="color: red" *ngIf="!professionistaForm.get('telefono').valid && professionistaForm.get('telefono').touched">
        Inserisci un numero di telefono</small>
    </div>
    <div class="form-group col-md-3">
      <label for="email" class="col-form-label">Email</label>
      <input formControlName="email" type="text" class="form-control" placeholder="Email">
      <small style="color: red" *ngIf="!professionistaForm.get('email').valid && professionistaForm.get('email').touched">
        Inserisci un'email</small>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-3">
      <label for="atto" class="col-form-label">Atto</label>
      <p-dropdown [options]="tipiAtti" formControlName="atto" optionValue="atto" optionLabel="atto"
        placeholder="Seleziona un atto">
      </p-dropdown>
    </div>

    <div class="form-group col-md-2">
      <label for="numero" class="col-form-label">Numero</label>
      <input formControlName="numero" type="text" class="form-control" placeholder="Num">
      <small style="color: red" *ngIf="!professionistaForm.get('numero').valid && professionistaForm.get('numero').touched">
        Inserisci il numero</small>
    </div>

    <div class="form-group col-md-3">
      <label for="data" class="col-form-label">Del</label>
      <p-calendar formControlName="data" appendTo="body" dateFormat="dd/mm/yy"></p-calendar>
      <small *ngIf="!professionistaForm.get('data').valid && professionistaForm.get('data').touched">
        Inserisci una data</small>
    </div>

    <div class="form-group col-md-2">
      <label for="allegato" class="col-form-label">Allegato</label>
      <p-fileUpload mode="basic" chooseLabel="Carica file" name="demo[]"
        url="https://www.primefaces.org/cdn/api/upload.php" (onUpload)="onUpload($event)"></p-fileUpload>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-md-3">
      <label for="importoNetto" class="col-form-label">Importo Netto</label>
      <p-inputNumber formControlName="importoNetto" class="numb" mode="currency" placeholder="Importo"
        inputId="currency-germany" currency="EUR" locale="de-DE"> </p-inputNumber>
      <small style="color: red" *ngIf="!professionistaForm.get('importoNetto').valid && professionistaForm.get('importoNetto').touched">
        Inserisci un importo</small>
    </div>
    <div class="form-group col-md-3">
      <label for="iva" class="col-form-label">IVA</label>
      <p-inputNumber formControlName="iva" class="numb" mode="currency" placeholder="Importo"
        inputId="currency-germany" currency="EUR" locale="de-DE"> </p-inputNumber>
      <small style="color: red" *ngIf="!professionistaForm.get('iva').valid && professionistaForm.get('iva').touched">
        Inserisci un importo</small>
    </div>
    <div class="form-group col-md-3">
      <label for="cassa" class="col-form-label">Cassa</label>
      <p-inputNumber formControlName="cassa" class="numb" mode="currency" placeholder="Importo"
        inputId="currency-germany" currency="EUR" locale="de-DE"> </p-inputNumber>
      <small style="color: red" *ngIf="!professionistaForm.get('cassa').valid && professionistaForm.get('cassa').touched">
        Inserisci un importo</small>
    </div>
    <div class="form-group col-md-3">
      <label for="importoTotale" class="col-form-label">Totale</label>
      <p-inputNumber formControlName="importoTotale" class="numb" mode="currency"
        inputId="currency-germany" currency="EUR" locale="de-DE" [readonly]="true"> </p-inputNumber>
    </div>
  </div>



  <div *ngIf="visualizza" class="mt-4">
    <div *ngIf="!isInEdit" class="footer_button">
      <p-toast></p-toast>
      <button pButton label="AGGIUNGI" icon="pi pi-plus" [disabled]="professionistaForm.invalid" (click)="add()"></button>
      <button pButton label="ANNULLA" (click)="close(false)"></button>

    </div>

    <div *ngIf="isInEdit" class="footer_button">
      <button *ngIf="!professionistaForm.enabled" pButton label="MODIFICA" [disabled]="professionistaForm.invalid" icon="pi pi-pencil"
        (click)="professionistaForm.enable()"></button>
      <p-toast></p-toast>
      <button *ngIf="professionistaForm.enabled" pButton pRipple label="SALVA" [disabled]="professionistaForm.invalid" icon="pi pi-check"
        (click)="save()"></button>
      <button pButton label="ANNULLA" (click)="close(false)"></button>

    </div>
  </div>
</form>