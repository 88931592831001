import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,} from '@angular/core';
import { QE, QESection, QEMainBase, QEItemBase, Model, QEResponse} from 'src/app/model/qe';
import { QEService } from 'src/app/service/qe.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { SharedService } from 'src/app/service/shared.service';
import { CUP } from 'src/app/model/cup';
import { CupService } from 'src/app/service/cup.service';
import { EsportaExcelTipo } from 'src/app/service/export-excel.service';
import { TipoEntita } from 'src/app/model/documento';
import { ModelloDocumentaleCustom, Scenario } from 'src/app/model/scenari';
import { ScenarioService } from 'src/app/service/scenario.service';
import { Ruolo, TipoPermesso, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';

@Component({
  selector: 'app-cup-qe',
  templateUrl: './cup-qe.component.html',
  styleUrls: ['./cup-qe.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService],
})
export class CupQEComponent implements OnInit, OnChanges {
  /**
   * CUP
   */
  @Input() public cup: CUP;
  /**
   * Flag per gestire le righe impegnate che non possono essere eliminate nel quadro economico
   */
  @Input() public refreshRowsQE: boolean = false;
  /**
   * Permesso in base all'utente loggato
   */
  @Input() public permesso: TipoPermesso;
  /**
   * Utente loggato
   */
  @Input() public user: Utente;
  /**
   * Refresh del Cup dopo aver publicato la bozza
   */
  @Output() refreshCup = new EventEmitter<void>();

/**
 * QE Attuale
 */
  qe: QE;
  /**
   * QE Bozza
   */
  qeBozza: QE;
  /**
   * Lista QE Storici
   */
  qeStorico: QEMainBase[];
  /**
   * Flag, se true lo Scenario è QE Bozza
   */
  isDraft = false;
  /**
   * Flag, se true lo Scenario è QE Attuale
   */
  isAttuale = false;
  /**
   * Flag, se true lo Scenario è QE Storico
   */
  isStorico = false;
  /**
   * Righe impegnate nella Spesa che on possono essere eliminate
   */
  rowsImpegnate: QEItemBase[];
  
  public esportaExcelTipo = EsportaExcelTipo;
  /**
   * QE Storico da mostrare nella modale
   */
  qeStoricoEdit: QE;
  /**
   * Flag per l'aperura della modale dalo storico 
   */
  qe_modal: boolean = false;
   /**
   * Flag per l'aperura della modale dalo storico 
   */
  qe_edit: boolean = false;
   /**
   * Flag per l'aperura della modale dalo storico 
   */
  raffronto_modal: boolean = false;
  /**
   * ID del primo QE della lista degli storici
   */
  idFirst: number;
  /**
   * ID dell'ultimo QE della lista degli storici
   */
  idLast: number;
  /**
   * Se true apre la form del carica file
   */
  public visible: boolean = false;

  /**
   * Tipo di entita da passare al documento
   */
  public tipoEntita = TipoEntita;
  
  /**
   * Datimodello per qe attuale
   */
  public datiModelloDocumentale: ModelloDocumentaleCustom;
  
  // Id dell'elemento appena creato da inviare all'onchange per procedere con il salvataggio del documento se collegato
  entityIdAdd: number;

  activeIndex: number = 1;

  public RuoloPipe = new RuoloPipe();
  public Ruolo = Ruolo;
  public TipoPermesso = TipoPermesso;

  constructor(
    private sharedServices: SharedService,
    private qeServices: QEService,
    private spinner: NgxSpinnerService,
    private cupServices: CupService,
    private cdRef: ChangeDetectorRef,
    public dialogService: DialogService,
    private scenarioServices: ScenarioService
  ) { }
  
  ngOnChanges (changes: SimpleChanges ): void
  {
    const refreshRowsQEChanges = changes['refreshRowsQE'];
    if(refreshRowsQEChanges && refreshRowsQEChanges.currentValue){
      this.rowsImpegnate = this.sharedServices.onReceiveRowsImpegnate();
    }
  }

  ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  async ngOnInit() {
    this.isAttuale = true;
    this.inizializzaQE();
  }
  

  public handleChange(handle){
    switch(handle?.index){
      case 0: 
        this.isDraft = true;
        this.isAttuale = false;
        this.isStorico = false;
      break;
      case 1: 
        this.isAttuale = true;
        this.isDraft = false;
        this.isStorico = false;
        this.inizializzaQE();
      break;
      case 2: 
        this.isStorico = true;
        this.isDraft = false;
        this.isAttuale = false;
      break;
    }
  }

  /**
   * Inizializza i dati del Quadro economico
   * Attuale, Bozza e Storico
   */
  public inizializzaQE(){
    const qesHub = this.cup?.qEs;
    
    if (qesHub && qesHub.length == 0) {
      delete this.qe;
      delete this.qeBozza;
      delete this.qeStorico;
      return;
    }
  
    if(this.cup?.qEs[0]){
      
      this.qeStorico = this.cup?.qEs[0].histories;
      if(this.qeStorico && this.qeStorico.length > 0) {
        const qeSort = this.qeStorico.sort((a, b) => b.version - a.version)
        this.idFirst = qeSort[qeSort?.length - 1].id;
        this.idLast = qeSort[0].id;
      }
  
      this.qe = qesHub[0].current;
      this.qe?.sections?.forEach(x => x.items = x.items ?? []);
  
      this.qeBozza = qesHub[0].draft;
      this.qeBozza?.sections?.forEach(x => x.items = x.items ?? []);  
    }    
  }

  async aftersaveBozza(event){
    const cupId = this.cup?.id;
    delete this.cup;
    if (cupId) {
      const cup = await this.cupServices.getCupById(cupId).toPromise();
      if(cup){
        this.refreshCup.emit();
        this.cup = cup;
        delete this.qe;
        delete this.qeBozza;
        delete this.qeStorico;
        this.inizializzaQE();
        this.activeIndex = 0;
      }
    }
    this.spinner.hide();
  }

  async afterUpdateQE(event){
    const cupId = this.cup?.id;
    this.spinner.hide();
  }

  async showDialogRaffronto(qe) {
    this.spinner.show();
    if (qe) {
      this.qeStoricoEdit = await this.recuperaQERaffronto(qe.id);
      this.raffronto_modal = true;
    }
    this.spinner.hide();
  }

    async editCurretQE(qe: QEMainBase) {
      this.spinner.show();
      if (qe) {
        this.qeStoricoEdit = await this.recuperaQERaffronto(qe.id);
        this.qe_edit = true;
      }
      this.spinner.hide();
  }

    async showDialogQE(qe: QEMainBase) {
      this.spinner.show();
      if (qe) {
        this.qeStoricoEdit = await this.recuperaQERaffronto(qe.id);
        this.qe_modal = true;
      }
      this.spinner.hide();
    }


  async recuperaQERaffronto(idVersione: number){
    let qe: QE;  
      const response: QEResponse = await this.qeServices.getQeHistoryById(idVersione).toPromise();
      if(response && response.success){
        qe = response.dto;
      } else {
        this.scenarioServices.showErrorMessage('Errore nel recupero del Quadro Economico');
      }
      return qe;
  }


  closeDialog() {
    this.qe_edit = false;
    this.qe_modal = false;
    this.raffronto_modal = false;
  }
 
}
 