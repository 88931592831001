import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { throwError } from 'rxjs';


const httpOptions = {
    headers: new HttpHeaders(
      { 'Content-Type': 'application/json' }
    )
  };


@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  constructor(private http: HttpClient) {}

  get(url: string): Observable<any> {
      return this.http
          .get<any>(url)
          .pipe(
            tap(_ => {
            }),
          catchError(this.handleErrorObservable));
  }

  post(model: any, url: string): Observable<any> {
      const headers = new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
      });
      const options = { headers: headers };
      const body = JSON.stringify(model);
      return this.http
          .post(url, body, options)
          .pipe(
            map(this.extractData),
            catchError(this.handleErrorObservable));
  }
  postForm(form: any, url: string): Observable<any> {
    let headers = new Headers();
    /** In Angular 5, including the header Content-Type can invalidate your request */
    return this.http
    .post(url, form)
    .pipe(
      map(res => res),
      catchError(this.handleErrorObservable));
  }

  deleteByBody(model: any, url: string): Observable<any> {
      const headers = new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
      });
      const options = { headers: headers };

      const body = JSON.parse(model);
      return this.http
          .post(url, body, options)
          .pipe(
              map(this.extractData),
              catchError(this.handleErrorObservable));
  }

  delete(url: string): Observable<any> {
      const headers = new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
      });
      const options = { headers: headers };

      return this.http
          .delete(url, options)
          .pipe(
          map(this.extractData),
          catchError(this.handleErrorObservable));
  }

  put(model: any, url: string): Observable<any> {
      const headers = new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
      });
      const options = { headers: headers };
      const body = JSON.stringify(model);
      return this.http
          .put(url, body, options)
          .pipe(
          map(this.extractData),
          catchError(this.handleErrorObservable));
  }

  putByParams(url: string): Observable<any> {
      const headers = new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
      });
      const options = { headers: headers };
      return this.http
          .put(url, '', options)
          .pipe(
          map(this.extractData),
          catchError(this.handleErrorObservable));
  }

  private extractData(res: any) {
      const body = res;
      return body || {};
  }

  private handleErrorObservable(error: Response | any) {
      console.error(error.message || error);
      return throwError(() => new Error(error.message || error))

  }
}
