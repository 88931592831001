<div class="col-md-6 offset-md-3 mt-5">
  <div class="card">
    <h4 class="card-header" style="background-color: transparent; border: none;">Cambio Password</h4>
    <div class="card-body">
      <form [formGroup]="conifrmResetPwdForm" (ngSubmit)="onSubmit()">
        <div class="form-group mt-2">
          <label for="password">Nuova Password</label>
          <input type="password" formControlName="newpassword" class="form-control"
                 [ngClass]="{ 'is-invalid': (f.newpassword.touched || f.newpassword.dirty) && f.newpassword.errors}" />
          <div *ngIf="f.newpassword.touched || f.newpassword.dirty" class="invalid-feedback">
            <div *ngIf="f.newpassword.errors?.['tooShort']">La password deve contenere almeno 8 caratteri.</div>
            <div *ngIf="f.newpassword.errors?.['missingUpperCase']">La password deve contenere almeno 1 lettera maiuscola.</div>
            <div *ngIf="f.newpassword.errors?.['missingNumber']">La password deve contenere almeno 1 numero.</div>
            <div *ngIf="f.newpassword.errors?.['missingSpecialChar']">La password deve contenere almeno 1 carattere speciale.</div>
            <div *ngIf="f.newpassword.errors?.required">Nuova Password Obbligatoria</div>
          </div>
        </div>
        <div class="form-group mt-2">
          <label for="password">Conferma Nuova Password</label>
          <input type="password" formControlName="confirmnewpassword" class="form-control"
                 [ngClass]="{ 'is-invalid': (f.confirmnewpassword.touched || f.confirmnewpassword.dirty) && f.confirmnewpassword.errors}" />
          <div *ngIf="f.confirmnewpassword.touched || f.confirmnewpassword.dirty" class="invalid-feedback">
            <div *ngIf="f.confirmnewpassword.errors?.passwordMismatch">Le password non coincidono.</div>
            <div *ngIf="f.confirmnewpassword.errors?.required">Conferma Password Obbligatoria</div>
          </div>
        </div>
        <div class="w-100 text-center">
          <button [disabled]="loading" class="btn btn-primary mt-3 w-50">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Cambia Password
          </button>
        </div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">Errore durante il cambio password, contattare l'amministratore</div>
      </form>
    </div>
  </div>
</div>
