import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Dizionario } from 'src/app/model/scenari';
import { ScenarioService } from 'src/app/service/scenario.service';
import * as _ from 'lodash';
import { SharedService } from 'src/app/service/shared.service';

export enum TipologiaDizionario {
  // 
  // Indica lo stato del CUP
  // 
  CUP_Stato = 0,
  // 
  // Indica la tipologia dell'atto spesa e del QEBozza
  //
  Atto = 1,
  //
  // Indica la tipologia del tipo bozza
  //
  QEBozza_Tipo = 2,
  //
  // Indica la tipologia del tipo spesa
  //
  Spesa_Tipo = 3,
  //
  // Indica la tipologia del tipo di giustoificativo
  //
  Giustificativo_Tipo = 4,
  //
  // Indica la tipologia di ente in fonte
  //
  Fonte_Ente = 5,
  //
  // Indica la tipologia di atto in fonte
  //
  Fonte_Atto = 6,
  //
  // Indica la tipologia di atto in incassato
  // 
  Incasso_Atto = 7
}

@Component({
  selector: 'app-configurazione',
  templateUrl: './configurazione.component.html',
  styleUrls: ['./configurazione.component.scss'],
})

export class ConfigurazioneComponent implements OnInit {
  /**
   * Apre finestra messaggio elimina voce
   */
  public dizionarioToDelete: Dizionario;
  /**
   * Apre finestra messaggio elimina voce
   */
  public indexToDelete: number;
  /**
   * Apre finestra messaggio elimina voce
   */
  public tipologiaDizionarioToDelete: TipologiaDizionario;
  /**
   * Apre finestra messaggio elimina voce
   */
  public openDeleteDialog: boolean;
  /**
   * Possibili stati CUP
   */
  public cupStatoTab: Dizionario[];
  /**
   * Tipologie Atti in Spesa e QE_Bozza
   */
  public tipologieAtto: Dizionario[] = [];
  /**
   * Tipologie Atti in Spesa e QE_Bozza
   */
  public tipologieQEBozza: Dizionario[] = [];
  /**
   * Tipologie Atti in Spesa e QE_Bozza
   */
  public tipologieSpesa: Dizionario[] = [];
  /**
   * Tipologie Atti in Spesa e QE_Bozza
   */
  public tipologieGiustificativo: Dizionario[] = [];
  /**
   * Tipologie Atti in Spesa e QE_Bozza
   */
  public tipologieEnteFonte: Dizionario[] = [];
  /**
   * Tipologie Atti in Spesa e QE_Bozza
   */
  public tipologieAttoFonte: Dizionario[] = [];
  /**
   * Tipologie Atti in Spesa e QE_Bozza
   */
  public tipologieAttoIncasso: Dizionario[] = [];
  /**
   * individua se sono state effettuate modifiche nella form CUP
   */
  public somethingHasChangedInCup: boolean = false;
  /**
   * individua se sono state effettuate modifiche nella form QE Tipo
   */
  public somethingHasChangedInTipiQE: boolean = false;
  /**
   * individua se sono state effettuate modifiche di atto nella form QE e SPesa
   */
  public somethingHasChangedInAtti: boolean = false;
  /**
   * individua se sono state effettuate modifiche di tipo in form Spesa
   */
  public somethingHasChangedInOrdinativi: boolean = false;
  /**
   * individua se sono state effettuate modifiche di tipo in form Spesa
   */
  public somethingHasChangedInGiustificativi: boolean = false;
  /**
   * individua se sono state effettuate modifiche di tipo ENTE in form FONTE
   */
  public somethingHasChangedInEntiFonte: boolean = false;
  /**
   * individua se sono state effettuate modifiche di tipo ATTO in form FONTE
   */
  public somethingHasChangedInAttiFonte: boolean = false;
  /**
   * individua se sono state effettuate modifiche di tipo ATTO in form FONTE
   */
  public somethingHasChangedInIncassi: boolean = false;


  public cupTabOriginal: Dizionario[];

  public bozzeTipiTabOriginal: Dizionario[];

  public tipiAttiTabOriginal: Dizionario[];

  public tipiSpeseOriginal: Dizionario[];

  public tipiGiustificativiOriginal: Dizionario[];

  public fonteEntiOriginal: Dizionario[];

  public fonteAttiOriginal: Dizionario[];

  public incassiOriginal: Dizionario[];


  formGroupCup: FormGroup; 

  formGroupOrdinativo: FormGroup;

  formGroupGiustificativo: FormGroup; 

  formGroupIncasso: FormGroup;

  formGroupTipiQE: FormGroup;

  formGroupAttiQE: FormGroup;

  formGroupEntiFonte: FormGroup; 

  formGroupAttiFonte: FormGroup;
  /* = new FormGroup({
    atti: this.fb.array([]),
  }) */


  constructor(
    private scenarioServices: ScenarioService,
    private messageService: MessageService,
    private _sharedService: SharedService,
    private fb: FormBuilder,
  ) { }

  async ngOnInit() {
    localStorage.removeItem('titoloCup');
    const takeAllDizionari = await this.scenarioServices.getAllDescrizioniDizionario().toPromise();
    if(takeAllDizionari && takeAllDizionari.success){
      const allDizionari = takeAllDizionari.dtos
      this.cupStatoTab = allDizionari.filter(x=> x.categoria === TipologiaDizionario.CUP_Stato);
      this.inizializzaCUP(this.cupStatoTab);
      this.tipologieAtto = allDizionari.filter(x=> x.categoria === TipologiaDizionario.Atto);
      this.inizializzaAtti(this.tipologieAtto);
      this.tipologieQEBozza = allDizionari.filter(x=> x.categoria === TipologiaDizionario.QEBozza_Tipo);
      this.inizializzaTipiQE(this.tipologieQEBozza);
      this.tipologieSpesa = allDizionari.filter(x=> x.categoria === TipologiaDizionario.Spesa_Tipo);
      this.inizializzaOrdinativo(this.tipologieSpesa);
      this.tipologieGiustificativo = allDizionari.filter(x=> x.categoria === TipologiaDizionario.Giustificativo_Tipo);
      this.inizializzaGiustificativo(this.tipologieGiustificativo);
      this.tipologieEnteFonte = allDizionari.filter(x=> x.categoria === TipologiaDizionario.Fonte_Ente);
      this.inizializzaEntiFonte(this.tipologieEnteFonte);
      this.tipologieAttoFonte = allDizionari.filter(x=> x.categoria === TipologiaDizionario.Fonte_Atto);
      this.inizializzaAttiFonte(this.tipologieAttoFonte);
      this.tipologieAttoIncasso = allDizionari.filter(x=> x.categoria === TipologiaDizionario.Incasso_Atto);
      this.inizializzaIncasso(this.tipologieAttoIncasso); 
    }
  }

  get cupFormArray() {
    return this.formGroupCup.get('stati') as FormArray;
  }
  get tipiOrdinativoFormArray() {
    return this.formGroupOrdinativo.get('tipi') as FormArray;
  }
  get tipiIncassoFormArray() {
    return this.formGroupIncasso.get('tipi') as FormArray;
  }
  get attiQEFormArray() {
    return this.formGroupAttiQE.get('atti') as FormArray;
  }

  get tipiQEFormArray() {
    return this.formGroupTipiQE.get('tipi') as FormArray;
  }
  get tipiGiustificativoFormArray() {
    return this.formGroupGiustificativo.get('tipi') as FormArray;
  }

  get entiFontiFormArray() {
    return this.formGroupEntiFonte.get('enti') as FormArray;
  }
  get attiFontiFormArray() {
    return this.formGroupAttiFonte.get('atti') as FormArray;
  }


  inizializzaCUP(dizionarioCup: Dizionario[]) {
    if (dizionarioCup) {
      this.formGroupCup = new FormGroup({
        stati: this.fb.array(dizionarioCup)
      })
      this.cupFormArray.clear();
      dizionarioCup.forEach(x => {
        const dizionario = this.fb.group({
          id: new FormControl(x.id),
          attivo: new FormControl(x.attivo),
          descrizione: new FormControl(x.descrizione, Validators.required),
          categoria: new FormControl(x.categoria),
          readonly: new FormControl(true),
        })
        this.cupFormArray.push(dizionario)
      })
      const dizionarioReadOnly: Dizionario[] = _.cloneDeep(dizionarioCup); 
      dizionarioReadOnly.forEach(x => x.readonly = true);
      this.cupTabOriginal = dizionarioReadOnly;
    }
  }

  inizializzaTipiQE(dizionarioTipiBozze) {
    if (dizionarioTipiBozze) {
      this.formGroupTipiQE = new FormGroup({
        tipi: this.fb.array(dizionarioTipiBozze)
      })
      this.tipiQEFormArray.clear();
      dizionarioTipiBozze.forEach(x => {
        const dizionarioTipoQE = this.fb.group({
          id: new FormControl(x.id),
          attivo: new FormControl(x.attivo),
          descrizione: new FormControl(x.descrizione),
          categoria: new FormControl(x.categoria),
          readonly: new FormControl(true),
        })
        this.tipiQEFormArray.push(dizionarioTipoQE)
      })
      const dizionarioReadOnly: Dizionario[] = _.cloneDeep(dizionarioTipiBozze); 
      dizionarioReadOnly.forEach(x => x.readonly = true);
      this.bozzeTipiTabOriginal = dizionarioReadOnly;
    }
  }

  inizializzaAtti(dizionarioAtti: Dizionario[]) {
    if (dizionarioAtti) {
      this.formGroupAttiQE = new FormGroup({
        atti: this.fb.array(dizionarioAtti)
      })
      this.attiQEFormArray.clear();
      dizionarioAtti.forEach(x => {
        const tipologieAtto = this.fb.group({
          id: new FormControl(x.id),
          attivo: new FormControl(x.attivo),
          descrizione: new FormControl(x.descrizione),
          categoria: new FormControl(x.categoria),
          readonly: new FormControl(true)
        })
        this.attiQEFormArray.push(tipologieAtto);
      })
      const dizionarioReadOnly: Dizionario[] = _.cloneDeep(dizionarioAtti); 
      dizionarioReadOnly.forEach(x => x.readonly = true);
      this.tipiAttiTabOriginal = dizionarioReadOnly;
    }
  }

  inizializzaOrdinativo(dizionarioSpese: Dizionario[]) {
    if (dizionarioSpese) {
      this.formGroupOrdinativo = new FormGroup({
        tipi: this.fb.array(dizionarioSpese)
      })
      this.tipiOrdinativoFormArray.clear();
      dizionarioSpese.forEach(x => {
        const dizionario = this.fb.group({
          id: new FormControl(x.id),
          attivo: new FormControl(x.attivo),
          descrizione: new FormControl(x.descrizione),
          categoria: new FormControl(x.categoria),
          readonly: new FormControl(true),
          hasFase: new FormControl(false)
        })
        this.tipiOrdinativoFormArray.push(dizionario);
      })
      const dizionarioReadOnly: Dizionario[] = _.cloneDeep(dizionarioSpese); 
      dizionarioReadOnly.forEach(x => x.readonly = true);
      this.tipiSpeseOriginal = dizionarioReadOnly;
    }
  }

  inizializzaGiustificativo(dizionarioGiustificativi) {
    if (dizionarioGiustificativi) {
      this.formGroupGiustificativo = new FormGroup({
        tipi: this.fb.array(dizionarioGiustificativi)
      })
      this.tipiGiustificativoFormArray.clear();
      dizionarioGiustificativi.forEach(x => {
        const dizionario = this.fb.group({
          id: new FormControl(x.id),
          attivo: new FormControl(x.attivo),
          descrizione: new FormControl(x.descrizione),
          categoria: new FormControl(x.categoria),
          readonly: new FormControl(true)
        })
        this.tipiGiustificativoFormArray.push(dizionario);
      })
      const dizionarioReadOnly: Dizionario[] = _.cloneDeep(dizionarioGiustificativi); 
      dizionarioReadOnly.forEach(x => x.readonly = true);
      this.tipiGiustificativiOriginal = dizionarioReadOnly;
    }
  }

  inizializzaEntiFonte(dizionarioEntiFonte: Dizionario[]) {
    if (dizionarioEntiFonte) {
      this.formGroupEntiFonte = new FormGroup({
        enti: this.fb.array(dizionarioEntiFonte)
      })
      this.entiFontiFormArray.clear();
      dizionarioEntiFonte.forEach(x => {
        const dizionario = this.fb.group({
          id: new FormControl(x.id),
          attivo: new FormControl(x.attivo),
          descrizione: new FormControl(x.descrizione),
          categoria: new FormControl(x.categoria),
          readonly: new FormControl(true)
        })
        this.entiFontiFormArray.push(dizionario);
      })
      const dizionarioReadOnly: Dizionario[] = _.cloneDeep(dizionarioEntiFonte); 
      dizionarioReadOnly.forEach(x => x.readonly = true);
      this.fonteEntiOriginal = dizionarioReadOnly;
    }
  }

  inizializzaAttiFonte(dizionarioAttiFonte: Dizionario[]) {
    if (dizionarioAttiFonte) {
      this.formGroupAttiFonte = new FormGroup({
        atti: this.fb.array(dizionarioAttiFonte)
      });
      this.attiFontiFormArray.clear();
      dizionarioAttiFonte.forEach(x => {
        const dizionario = this.fb.group({
          id: new FormControl(x.id),
          attivo: new FormControl(x.attivo),
          descrizione: new FormControl(x.descrizione),
          categoria: new FormControl(x.categoria),
          readonly: new FormControl(true)
        })
        this.attiFontiFormArray.push(dizionario);
      })
      const dizionarioReadOnly: Dizionario[] = _.cloneDeep(dizionarioAttiFonte); 
      dizionarioReadOnly.forEach(x => x.readonly = true);
      this.fonteAttiOriginal = dizionarioReadOnly;
    }
  }

  inizializzaIncasso(dizionarioIncassi: Dizionario[]) {
    if (dizionarioIncassi) {
      this.formGroupIncasso = new FormGroup({
        tipi: this.fb.array(dizionarioIncassi)
      });
      this.tipiIncassoFormArray.clear();
      dizionarioIncassi.forEach(x => {
        const dizionario = this.fb.group({
          id: new FormControl(x.id),
          attivo: new FormControl(x.attivo),
          descrizione: new FormControl(x.descrizione),
          categoria: new FormControl(x.categoria),
          readonly: new FormControl(true)
        })
        this.tipiIncassoFormArray.push(dizionario);
      })
      const dizionarioReadOnly: Dizionario[] = _.cloneDeep(dizionarioIncassi); 
      dizionarioReadOnly.forEach(x => x.readonly = true);
      this.incassiOriginal = dizionarioReadOnly;
    }
  }

  /**
   * @param tipologiaDizionario 
   * evento scatenato al click del + quando si aggiunge una voce in un qualsiasi dizionario
   */
  add(tipologiaDizionario: TipologiaDizionario) {
    const dizionario = this.fb.group({
      id: new FormControl(0),
      attivo: new FormControl(false),
      descrizione: new FormControl(null, Validators.required),
      categoria: new FormControl(tipologiaDizionario),
      readonly: new FormControl(false),
    });

    switch (tipologiaDizionario) {
      case TipologiaDizionario.CUP_Stato: {
        this.somethingHasChangedInCup = true;
        this.cupFormArray.push(dizionario);
        this.formGroupCup.invalid;
        break;
      }
      case TipologiaDizionario.Atto: {
        this.somethingHasChangedInAtti = true;
        this.attiQEFormArray.push(dizionario);
        this.formGroupAttiQE.invalid;
        break;
      }
      case TipologiaDizionario.QEBozza_Tipo: {
        this.somethingHasChangedInTipiQE = true;
        this.tipiQEFormArray.push(dizionario);
        this.formGroupTipiQE.invalid;
        break;
      }
      case TipologiaDizionario.Spesa_Tipo: {
        this.somethingHasChangedInOrdinativi = true;
        this.tipiOrdinativoFormArray.push(dizionario);
        this.formGroupOrdinativo.invalid;
        break;
      }
      case TipologiaDizionario.Giustificativo_Tipo: {
        this.somethingHasChangedInGiustificativi = true;
        this.tipiGiustificativoFormArray.push(dizionario);
        this.formGroupGiustificativo.invalid;
        break;
      }
      case TipologiaDizionario.Fonte_Ente: {
        this.somethingHasChangedInEntiFonte = true;
        this.entiFontiFormArray.push(dizionario);
        this.formGroupEntiFonte.invalid;
        break;
      }
      case TipologiaDizionario.Fonte_Atto: {
        this.somethingHasChangedInAttiFonte = true;
        this.attiFontiFormArray.push(dizionario);
        this.formGroupAttiFonte.invalid;
        break;
      }
      case TipologiaDizionario.Incasso_Atto: {
        this.somethingHasChangedInIncassi = true;
        this.tipiIncassoFormArray.push(dizionario);
        this.formGroupIncasso.invalid;
        break;
      }
    }

  }

  /**
   * mostra la dialog di conferma eliminazione della voce di dizionario 
   */
  async removeMessage(dizionario, index: number, tipologiaDizionario: TipologiaDizionario) {
    this.openDeleteDialog = true;
    console.log(dizionario.value.id)
    this.dizionarioToDelete = dizionario;
    this.indexToDelete = index;
    this.tipologiaDizionarioToDelete = tipologiaDizionario;
  }

  /**
   * Eliminazione voce di dizionario 
   */
  async remove(dizionario, index: number, tipologiaDizionario: TipologiaDizionario) {
    if (dizionario.value.id !== 0) {
      const removed = await this.scenarioServices.deleteDescrizioniDizionario(dizionario.value.id).toPromise();
      if(removed){
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "Voce eliminata con successo",
        });
        var newDizionario: Dizionario[] = [];
        this._sharedService.deleteDizionari();
        let resp = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
        newDizionario = resp && resp.success ? resp.dtos : [];
        switch (tipologiaDizionario){
          case TipologiaDizionario.CUP_Stato: {
            this.inizializzaCUP(newDizionario);
            this.somethingHasChangedInCup = false;
            break;
          }
          case TipologiaDizionario.Atto: {
            this.inizializzaAtti(newDizionario);
            this.somethingHasChangedInAtti = false;
            break;
          }
          case TipologiaDizionario.QEBozza_Tipo: {
            this.inizializzaTipiQE(newDizionario);
            this.somethingHasChangedInTipiQE = false;
            break;
          }
          case TipologiaDizionario.Spesa_Tipo: {
            this.inizializzaOrdinativo(newDizionario);
            this.somethingHasChangedInOrdinativi = false;
            break;
          }
          case TipologiaDizionario.Giustificativo_Tipo: {
            this.inizializzaGiustificativo(newDizionario);
            this.somethingHasChangedInGiustificativi = false;
            break;
          }
          case TipologiaDizionario.Fonte_Ente: {
            this.inizializzaEntiFonte(newDizionario);
            this.somethingHasChangedInEntiFonte = false;
            break;
          }
          case TipologiaDizionario.Fonte_Atto: {
            this.inizializzaAttiFonte(newDizionario);
            this.somethingHasChangedInAttiFonte = false;
            break;
          }
          case TipologiaDizionario.Incasso_Atto: {
            this.inizializzaIncasso(newDizionario);
            this.somethingHasChangedInIncassi = false;
            break;
          }
        }
      }
    } else {
      let formGroup: Dizionario[] = [];
      switch (tipologiaDizionario) {
        case TipologiaDizionario.CUP_Stato: {
          this.cupFormArray.removeAt(index);
          formGroup = this.formGroupCup.value.stati;
          if(_.isEqual(formGroup, this.cupTabOriginal)){
            this.somethingHasChangedInCup = false;
          }
          break;
        }
        case TipologiaDizionario.Atto: {
          this.attiQEFormArray.removeAt(index);
          formGroup = this.formGroupAttiQE.value.atti;
          if(_.isEqual(formGroup, this.tipiAttiTabOriginal)){
            this.somethingHasChangedInAtti = false;
          }
          break;
        }
        case TipologiaDizionario.QEBozza_Tipo: {
          this.tipiQEFormArray.removeAt(index);
          formGroup = this.formGroupTipiQE.value.tipi;
          if(_.isEqual(formGroup, this.bozzeTipiTabOriginal)){
            this.somethingHasChangedInTipiQE = false;
          }
          break;
        }
        case TipologiaDizionario.Spesa_Tipo: {
          this.tipiOrdinativoFormArray.removeAt(index);
          formGroup = this.formGroupOrdinativo.value.tipi;
          if(_.isEqual(formGroup, this.tipiSpeseOriginal)){
            this.somethingHasChangedInOrdinativi = false;
          }
          break;
        }
        case TipologiaDizionario.Giustificativo_Tipo: {
          this.tipiGiustificativoFormArray.removeAt(index);
          formGroup = this.formGroupGiustificativo.value.tipi;
          if(_.isEqual(formGroup, this.tipiGiustificativiOriginal)){
            this.somethingHasChangedInGiustificativi = false;
          }
          break;
        }
        case TipologiaDizionario.Fonte_Ente: {
          this.entiFontiFormArray.removeAt(index);
          formGroup = this.formGroupEntiFonte.value.enti;
          if(_.isEqual(formGroup, this.fonteEntiOriginal)){
            this.somethingHasChangedInEntiFonte = false;
          }
          break;
        }
        case TipologiaDizionario.Fonte_Atto: {
          this.attiFontiFormArray.removeAt(index);
          formGroup = this.formGroupAttiFonte.value.atti;
          if(_.isEqual(formGroup, this.fonteAttiOriginal)){
            this.somethingHasChangedInAttiFonte = false;
          }
          break;
        }
        case TipologiaDizionario.Incasso_Atto: {
          this.tipiIncassoFormArray.removeAt(index);
          formGroup = this.formGroupIncasso.value.tipi;
          if(_.isEqual(formGroup, this.incassiOriginal)){
            this.somethingHasChangedInIncassi = false;
          }
          break;
        }
      };
    }
    this.openDeleteDialog = false;
  }

  changeToggle(tipologiaDizionario?: TipologiaDizionario, dizionario?: Dizionario,) {
    switch (tipologiaDizionario) {
      case TipologiaDizionario.CUP_Stato: {
        this.somethingHasChangedInCup = true;
        break;
      }
      case TipologiaDizionario.Atto: {
        this.somethingHasChangedInAtti = true;
        break;
      }
      case TipologiaDizionario.QEBozza_Tipo: {
        this.somethingHasChangedInTipiQE = true;
        break;
      }
      case TipologiaDizionario.Spesa_Tipo: {
        this.somethingHasChangedInOrdinativi = true;
        break;
      }
      case TipologiaDizionario.Giustificativo_Tipo: {
        this.somethingHasChangedInGiustificativi = true;
        break;
      }
      case TipologiaDizionario.Fonte_Ente: {
        this.somethingHasChangedInEntiFonte = true;
        break;
      }
      case TipologiaDizionario.Fonte_Atto: {
        this.somethingHasChangedInAttiFonte = true;
        break;
      }
      case TipologiaDizionario.Incasso_Atto: {
        this.somethingHasChangedInIncassi = true;
        break;
      }
    }
  }

  /**
   * @param tipologiaDizionario 
   * Ripristina il dizionario alla situazione iniziale
   */
  restore(tipologiaDizionario?: TipologiaDizionario) {
    switch (tipologiaDizionario) {
      case TipologiaDizionario.CUP_Stato: {
        this.inizializzaCUP(this.cupTabOriginal)
        this.somethingHasChangedInCup = false;
        break;
      }
      case TipologiaDizionario.Atto: {
        this.inizializzaAtti(this.tipiAttiTabOriginal)
        this.somethingHasChangedInAtti = false;
        break;
      }
      case TipologiaDizionario.QEBozza_Tipo: {
        this.inizializzaTipiQE(this.bozzeTipiTabOriginal)
        this.somethingHasChangedInTipiQE = false;
        break;
      }
      case TipologiaDizionario.Spesa_Tipo: {
        this.inizializzaOrdinativo(this.tipiSpeseOriginal)
        this.somethingHasChangedInOrdinativi = false;
        break;
      }
      case TipologiaDizionario.Giustificativo_Tipo: {
        this.inizializzaGiustificativo(this.tipiGiustificativiOriginal)
        this.somethingHasChangedInGiustificativi = false;
        break;
      }
      case TipologiaDizionario.Fonte_Ente: {
        this.inizializzaEntiFonte(this.fonteEntiOriginal)
        this.somethingHasChangedInEntiFonte = false;
        break;
      }
      case TipologiaDizionario.Fonte_Atto: {
        this.inizializzaAttiFonte(this.fonteAttiOriginal)
        this.somethingHasChangedInAttiFonte = false;
        break;
      }
      case TipologiaDizionario.Incasso_Atto: {
        this.inizializzaIncasso(this.incassiOriginal)
        this.somethingHasChangedInIncassi = false;
        break;
      }
    }
  }

  /**
   * @param tipologiaDizionario 
   * Salva il nuovo dizionario
   */
  async save(tipologiaDizionario: TipologiaDizionario) {
    let newDizionario: Dizionario[] = [];
    switch (tipologiaDizionario) {
      case TipologiaDizionario.CUP_Stato: {
        const formValue = this.formGroupCup.value;
        const dizionario: Dizionario[] = [];
        formValue.stati.forEach((x: Dizionario) => {
          const diz: Dizionario = {
            id: x.id,
            descrizione: x.descrizione,
            attivo: x.attivo,
            categoria: x.categoria,
            readonly: false,
          }
          dizionario.push(diz)
        })
        let response = await this.scenarioServices.addOrUpdateDescrizioniDizionario(dizionario).toPromise();
        if(response && response.success){
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Dizionario CUP aggiornato con successo",
          });
          this._sharedService.deleteDizionari();
          const getNewDionario = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
          if(getNewDionario && getNewDionario.success) {
            newDizionario = getNewDionario.dtos;
            this.inizializzaCUP(newDizionario);
          }
          this.somethingHasChangedInCup = false;
        }
        break;
      }
      case TipologiaDizionario.Atto: {
        const formValue = this.formGroupAttiQE.value;
        const dizionario: Dizionario[] = [];
        formValue.atti.forEach((x: Dizionario) => {
          const diz: Dizionario = {
            id: x.id,
            descrizione: x.descrizione,
            attivo: x.attivo,
            categoria: x.categoria,
            readonly: false,
          }
          dizionario.push(diz)
        })
        let response = await this.scenarioServices.addOrUpdateDescrizioniDizionario(dizionario).toPromise();
        if(response && response.success){
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Dizionario QE aggiornato con successo",
          });
          
          this._sharedService.deleteDizionari();
          const getNewDionario = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
          if(getNewDionario && getNewDionario.success) {
            newDizionario = getNewDionario.dtos;
            this.inizializzaAtti(newDizionario);
          }
          this.somethingHasChangedInAtti = false;
        }
        break;
      }
      case TipologiaDizionario.QEBozza_Tipo: {
        const formValue = this.formGroupTipiQE.value;
        const dizionario: Dizionario[] = [];
        formValue.tipi.forEach((x: Dizionario) => {
          const diz: Dizionario = {
            id: x.id,
            descrizione: x.descrizione,
            attivo: x.attivo,
            categoria: x.categoria,
            readonly: false,
          }
          dizionario.push(diz)
        })
        let response = await this.scenarioServices.addOrUpdateDescrizioniDizionario(dizionario).toPromise();
        if(response && response.success){
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Dizionario QE aggiornato con successo",
          });
          
          this._sharedService.deleteDizionari();
          const getNewDionario = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
          if(getNewDionario && getNewDionario.success) {
            newDizionario = getNewDionario.dtos;
            this.inizializzaTipiQE(newDizionario);
          }
          this.somethingHasChangedInTipiQE = false;
        }
        break;
      }
      case TipologiaDizionario.Spesa_Tipo: {
        const formValue = this.formGroupOrdinativo.value;
        const dizionario: Dizionario[] = [];
        formValue.tipi.forEach((x: Dizionario) => {
          const diz: Dizionario = {
            id: x.id,
            descrizione: x.descrizione,
            attivo: x.attivo,
            categoria: x.categoria,
            readonly: false,
            hasFase: false
          }
          dizionario.push(diz)
        })
        let response = await this.scenarioServices.addOrUpdateDescrizioniDizionario(dizionario).toPromise();
        if(response && response.success){
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Dizionario Ordinativi aggiornato con successo",
          });
          
          this._sharedService.deleteDizionari();
          const getNewDionario = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
          if(getNewDionario && getNewDionario.success) {
            newDizionario = getNewDionario.dtos;
            this.inizializzaOrdinativo(newDizionario);
          }
          this.somethingHasChangedInOrdinativi = false;
        }
        break;
      }
      case TipologiaDizionario.Giustificativo_Tipo: {
        const formValue = this.formGroupGiustificativo.value;
        const dizionario: Dizionario[] = [];
        formValue.tipi.forEach((x: Dizionario) => {
          const diz: Dizionario = {
            id: x.id,
            descrizione: x.descrizione,
            attivo: x.attivo,
            categoria: x.categoria,
            readonly: false,
          }
          dizionario.push(diz)
        })
        let response = await this.scenarioServices.addOrUpdateDescrizioniDizionario(dizionario).toPromise();
        if(response && response.success){
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Dizionario Giustificativi aggiornato con successo",
          });
          
          this._sharedService.deleteDizionari();
          const getNewDionario = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
          if(getNewDionario && getNewDionario.success) {
            newDizionario = getNewDionario.dtos;
            this.inizializzaGiustificativo(newDizionario);
          }
          this.somethingHasChangedInGiustificativi = false;
        }
        break;
      }
      case TipologiaDizionario.Fonte_Ente: {
        const formValue = this.formGroupEntiFonte.value;
        const dizionario: Dizionario[] = [];
        formValue.enti.forEach((x: Dizionario) => {
          const diz: Dizionario = {
            id: x.id,
            descrizione: x.descrizione,
            attivo: x.attivo,
            categoria: x.categoria,
            readonly: false,
          }
          dizionario.push(diz)
        })
        let response = await this.scenarioServices.addOrUpdateDescrizioniDizionario(dizionario).toPromise();
        if(response && response.success){
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Dizionario Fonti aggiornato con successo",
          });
          
          this._sharedService.deleteDizionari();
          const getNewDionario = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
          if(getNewDionario && getNewDionario.success) {
            newDizionario = getNewDionario.dtos;
            this.inizializzaEntiFonte(newDizionario);
          }
          this.somethingHasChangedInEntiFonte = false;
        }
        break;
      }
      case TipologiaDizionario.Fonte_Atto: {
        const formValue = this.formGroupAttiFonte.value;
        const dizionario: Dizionario[] = [];
        formValue.atti.forEach((x: Dizionario) => {
          const diz: Dizionario = {
            id: x.id,
            descrizione: x.descrizione,
            attivo: x.attivo,
            categoria: x.categoria,
            readonly: false,
          }
          dizionario.push(diz)
        });
        
        let response = await this.scenarioServices.addOrUpdateDescrizioniDizionario(dizionario).toPromise();
        if(response && response.success){
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Dizionario Fonti aggiornato con successo",
          });
          this._sharedService.deleteDizionari();
          const getNewDionario = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
          if(getNewDionario && getNewDionario.success) {
            newDizionario = getNewDionario.dtos;
            this.inizializzaAttiFonte(newDizionario);
          }
          this.somethingHasChangedInAttiFonte = false;
        }
        break;
      }
      case TipologiaDizionario.Incasso_Atto: {
        const formValue = this.formGroupIncasso.value;
        const dizionario: Dizionario[] = [];
        formValue.tipi.forEach((x: Dizionario) => {
          const diz: Dizionario = {
            id: x.id,
            descrizione: x.descrizione,
            attivo: x.attivo,
            categoria: x.categoria,
            readonly: false,
          }
          dizionario.push(diz)
        });
        let response = await this.scenarioServices.addOrUpdateDescrizioniDizionario(dizionario).toPromise();
        if(response && response.success){
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Dizionario Incassi aggiornato con successo",
          });
        
          this._sharedService.deleteDizionari();
          const getNewDionario = await this.scenarioServices.getAllDescrizioniDizionario(tipologiaDizionario).toPromise();
          if(getNewDionario && getNewDionario.success) {
            newDizionario = getNewDionario.dtos;
            this.inizializzaIncasso(newDizionario);
          }
          this.somethingHasChangedInIncassi = false;
        }
        break;
      }
    }
  }
}
