<form [formGroup]="resetPwdForm" (ngSubmit)="onSubmit()" style="width: 100%;">
  <div style="width: 100%;">

    <div class="row">
      <div class="form-group col-md-9">
        <label for="username" class="col-form-label">Username</label>
        <input type="text" formControlName="username" class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />

        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Nome utente obbligatorio</div>
        </div>
      </div>

      <div class="form-group col-md-3">
        <label class="col-form-label"></label>
        <button [disabled]="!f.username.valid" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          Reset Password
        </button>
      </div>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">Errore durante il reset password, contattare
        l'amministratore</div>
    </div>
  </div>
</form>