
<div class="d-flex flex-row contenitore">
  <div class="flex-1 m-2"> 

    <p-table class="promemoriaTable"
      [value]="scadenze"
      [paginator]="true"
      [rows]="5"
      [showCurrentPageReport]="false"
      [rowsPerPageOptions]="[5, 10, 25]" dataKey="codice">

      <ng-template pTemplate="header">
      </ng-template>

      <ng-template pTemplate="body" let-scadenza>
        <tr (dblclick)="selectedCard(scadenza.cupId, scadenza)">
          <td>
            <div [ngClass]="scadenza.data < oggi ? 'color' : ''"><span style="font-weight: 600;">{{scadenza.data | date}}</span></div>
            <div [pTooltip]="scadenza.descrizione" [tooltipPosition]="left">
              <span style="font-weight: 500;">{{(scadenza.descrizione.length>6)? (scadenza.descrizione | slice:0:30)+'...':(scadenza.descrizione) }}</span>
            </div>
            <div>CUP: {{scadenza.titolo}}</div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<rbk-modal *ngIf="visibleDialog" [dialogModel]="dialogModel" (onConfirm)="visibleDialog = false"></rbk-modal>