import { Component, Input, OnInit } from '@angular/core';
import { CUP } from 'src/app/model/cup';
import { Fase1 } from 'src/app/model/fase1';
import { QE, QECompare, QEExcelTotaliEsecuzione, QETotaliSezioniExcel } from 'src/app/model/qe';
import {
  EsportaExcelTipo,
  ExportExcelService,
} from 'src/app/service/export-excel.service';
import { QEService } from 'src/app/service/qe.service';

export class ExcelReportData {
  title: string;
  tipoEsportazione: EsportaExcelTipo;
  cup?: CUP;
  qe?: QE;
  qeCompare?: QECompare;
  qeConTotaliSezione?: QETotaliSezioniExcel;
  qeTotaliEsecuzione?: QEExcelTotaliEsecuzione[];
  esecuzione?: Fase1[];
}

@Component({
  selector: 'rbk-export-excel',
  templateUrl: './rbk-export-excel.html',
})
export class ExportExcelComponent implements OnInit {
  @Input() public cup: CUP;
  @Input() public qe: QE;
  @Input() public qeCompare: QECompare;
  @Input() public tipoEsportazione: EsportaExcelTipo;
  @Input() public esecuzione: Fase1[];

  public labelBtn = '';

  title = 'angular-export-to-excel';
  dataForExcel = [];

  constructor(
    public exportExcelService: ExportExcelService,
    public qeService: QEService
  ) {}

  ngOnInit(): void {
    if (this.tipoEsportazione === EsportaExcelTipo.EsecuzioneEstesa) {
      this.labelBtn = 'Estesa';
    } else if (
      this.tipoEsportazione === EsportaExcelTipo.QuadroEconomicoConTotaliSezioni
    ) {
      this.labelBtn = 'Totali';
    } else if (
      this.tipoEsportazione === EsportaExcelTipo.QuadroEconomicoDettaglio
    ) {
      this.labelBtn = 'Totali di dettaglio';
    }
  }

  async exportToExcel() {
    const title = `CUP(${this.cup.id}) (${this.cup.titolo} ${this.cup.indirizzo})`;
    let reportData: ExcelReportData = new ExcelReportData();
    reportData.title = title;
    reportData.cup = this.cup;
    switch (this.tipoEsportazione) {
      case EsportaExcelTipo.QuadroEconomico:
        reportData.qe = this.qe;
        reportData.tipoEsportazione = EsportaExcelTipo.QuadroEconomico;
        this.exportExcelService.exportExcelQadroEconomico(reportData);
        break;
      case EsportaExcelTipo.Raffronto:
        reportData.qeCompare = this.qeCompare;
        reportData.tipoEsportazione = EsportaExcelTipo.Raffronto;
        this.exportExcelService.exportExcelRaffronto(reportData);
        break;
      case EsportaExcelTipo.EsecuzioneEstesa:
        reportData.esecuzione = this.esecuzione;
        reportData.tipoEsportazione = EsportaExcelTipo.Esecuzione;
        this.exportExcelService.exportExcelEstesaEsecuzione(reportData);
        break;
      case EsportaExcelTipo.Esecuzione:
        reportData.esecuzione = this.esecuzione;
        reportData.tipoEsportazione = EsportaExcelTipo.Esecuzione;
        this.exportExcelService.exportExcelEsecuzione(reportData);
        break;
      case EsportaExcelTipo.QuadroEconomicoConTotaliSezioni:
        const result = await this.qeService
          .getQEExcelConTotaliSezioni(this.qe.id)
          .toPromise();
        if (result && result.success) {
          reportData.qeConTotaliSezione = result.dto;
        } else {
          console.log('500');
        }
        this.exportExcelService.exportExcelConTotaliSezioni(reportData);
        break;
      case EsportaExcelTipo.QuadroEconomicoDettaglio:
        const result1 = await this.qeService.getQuadroEconomicoDettaglio(this.qe.id)
          .toPromise();
        if (result1 && result1.success) {
          reportData.qeTotaliEsecuzione = result1.dtos;
        } else {
          console.log('500');
        }
        const title = `${this.cup.titolo} CUP ${this.cup.codice}`;
        reportData.title = title;
        this.exportExcelService.exportExcelConQuadroEconomicoDettaglio(reportData);
        break;
    }
  }
}
