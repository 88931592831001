import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

export class DialogModel {
  public title: string;
  public message: string;
  public btnConferma: string;
  public btnAnnulla?: string;
  public hasBtnAnnulla?: boolean;
  constructor(
    title: string,
    message: string,
    btnConferma: string,
    btnAnnulla?: string,
    hasBtnAnnulla?: boolean
  ) {
    this.title = title || '';
    this.message = message || '';
    this.btnConferma = btnConferma || '';
    this.btnAnnulla = btnAnnulla || '';
    this.hasBtnAnnulla = hasBtnAnnulla;
  }
}

@Component({
  selector: 'rbk-modal',
  templateUrl: './rbk-modal.html',
  styleUrls: ['./rbk-modal.scss'],
})
export class RbkModalComponent<T> implements OnInit {
  /**
   * Parametri della modale
   */
  @Input() public dialogModel: DialogModel;
  /**
   * Al click della conferma della modale
   */
  @Output() public onConfirm = new EventEmitter<T>();
  /**
   * Al click dell'annulla della modale
   */
  @Output() public onCancel = new EventEmitter<T>();
  
  public visible: boolean = false;

  constructor(private confirmationService: ConfirmationService) {}

  ngOnInit() {
    if (this.dialogModel) {
      this.visible = true;
    }
  }

  onConfirmModal() {
    this.onConfirm.emit();
  }
  
  onCancelModal() {
    this.onCancel.emit();
  }
}
