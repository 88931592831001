import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CupService } from 'src/app/service/cup.service';
import { SharedService } from 'src/app/service/shared.service';
import { Utente } from 'src/app/model/utente';
import { UserClientService } from 'src/app/service/user-client.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-utenti-list',
  templateUrl: './utenti-list.component.html',
  styleUrls: ['./utenti-list.component.scss'],
  providers: [ConfirmationService, MessageService]
})

export class UtentiListComponent {
  filteredTab: Utente[];
  selectedUtente: Utente;
  visible: boolean = false;
  isInEdit: boolean;
  user: Utente;
  selectedUtenti: Utente[];

  constructor(
    private userClientService: UserClientService,
    private spinner: NgxSpinnerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
  ) { }


  ngOnInit() {
    this.init()
  }

  init() {
    this.user = this.userClientService.getUser()
    this.spinner.show()
    this.userClientService.getUsers(this.user.clientId).subscribe({
      next: resp => {
        this.spinner.hide();
        this.filteredTab = resp
        for (let elem of this.filteredTab) {
          elem.flagAttivo = true;
          elem.isLocked = false;
        }
      },
      error: err => {
        this.spinner.hide();
      }
    })

  }

  showDialog() {
    this.visible = true;
  }

  selectedRow(utente: Utente) {
    this.selectedUtente = utente;
    this.visible = true;
  }

  onClose(bool) {
    console.log(bool)
    if (bool) {
      this.init()
      //this.filteredTab = []
    }
    this.visible = false;
    this.selectedUtente = null
  }

  confirmLocked(utente: Utente) {
    if (!utente.isLocked) {
      this.confirmationService.confirm({
        message: "Vuoi sbloccare questo utente?",
        header: "Sblocca utente",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sì",
        rejectLabel: "No, torna indietro",
        acceptButtonStyleClass: "p-button-success p-button-text",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.messageService.add({
            severity: "success",
            summary: "Confirmed",
            detail: "Invito inviato",
          });
          utente.isLocked = false;
          /* this.invita()
          this.close() */
        },
        reject: () => {
          this.messageService.add({
            severity: "error",
            summary: "Rejected",
            detail: "Invito non inviato"
          });
          utente.isLocked = true;
        }
      })
    } else {
      this.confirmationService.confirm({
        message: "Vuoi bloccare questo utente?",
        header: "Blocca utente",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sì",
        rejectLabel: "No, torna indietro",
        acceptButtonStyleClass: "p-button-success p-button-text",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.messageService.add({
            severity: "success",
            summary: "Confirmed",
            detail: "Invito inviato",
          });
          utente.isLocked = true;
          /* this.invita()
          this.close() */
        },
        reject: () => {
          this.messageService.add({
            severity: "error",
            summary: "Rejected",
            detail: "Invito non inviato"
          });
          utente.isLocked = false;
        }
      })
    }
  }
  confirmAttivo(utente: Utente) {
    if (!utente.flagAttivo) {
      this.confirmationService.confirm({
        message: "L'utente selezionato sarà disassociato da questo ente. Sarà sempre possibile associarlo nuovamente con un nuovo invito. Proseguire?",
        header: "Disattiva utente",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sì",
        rejectLabel: "No, torna indietro",
        acceptButtonStyleClass: "p-button-success p-button-text",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.messageService.add({
            severity: "success",
            summary: "Confirmed",
            detail: "Utente disattivato",
          });
          utente.flagAttivo = false;
          this.dissociate(utente.userName);
          //this.init();
          this.filteredTab = this.filteredTab.filter(x => x.userName !== utente.userName);
          /* this.invita()
          this.close() */
        },
        reject: () => {
          this.messageService.add({
            severity: "error",
            summary: "Rejected",
            detail: "Utente non disattivato"
          });
          utente.flagAttivo = true;
        }
      });
    } else {
      this.confirmationService.confirm({
        message: "Vuoi rendere attivo questo utente?",
        header: "Attiva utente",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Sì",
        rejectLabel: "No, torna indietro",
        acceptButtonStyleClass: "p-button-success p-button-text",
        rejectButtonStyleClass: "p-button-text",
        accept: () => {
          this.messageService.add({
            severity: "success",
            summary: "Confirmed",
            detail: "Utente attivato",
          });
          utente.flagAttivo = true;
          /* this.invita()
          this.close() */
        },
        reject: () => {
          this.messageService.add({
            severity: "error",
            summary: "Rejected",
            detail: "Utente non attivato",
          });
          utente.flagAttivo = false;
        }
      });
    }
  }

  dissociate(username: string) {
    this.spinner.show()
    this.userClientService.dissociateUser(username, this.user.clientId).subscribe({
      next: (resp) => {
        this.spinner.hide();
        this.messageService.add({
          severity: "info",
          summary: "Utente dissociato",
          detail: "Utente dissociato",
          sticky: true
        });
      },
      error: (err) => {

        this.spinner.hide();
        console.log(err)
        this.messageService.add({
          severity: "error",
          summary: "Utente non dissociato",
          detail: "Errore: " + err,
          sticky: true
        });
      }
    })
  }

}
