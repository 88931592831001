import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';

@Injectable()
export class HttpTimingInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const startTime = Date.now();

    return next.handle(req).pipe(
      tap({
        next: (event) => {
          if (event instanceof HttpResponse) {
            const endTime = Date.now();
            const elapsed = endTime - startTime;
            //console.log(`HTTP_TIMING request to ${req.method} ${req.urlWithParams} took ${elapsed} ms`);
          }
        },
        error: (error) => {
          const endTime = Date.now();
          const elapsed = endTime - startTime;
          //console.error(`HTTP_TIMING request to ${req.method} ${req.urlWithParams} failed after ${elapsed} ms`, error);
        }
      })
    );
  }
}
