import { Inject, Injectable } from '@angular/core';
import { CupTotali, QE, QECompare, QEItemBase, QETotaliSezioniExcel, QEModel, Model,  ModelResponse, QEResponse, QEModelResponse, QEItemBaseResponse, QECompareResponse, QETotaliSezioniExcelResponse, CupTotaliResponse, QEExcelTotaliEsecuzione, QEExcelTotaliEsecuzioneResponse} from '../model/qe';
import { CUP, CUPResponse } from '../model/cup';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class QEService {

  BASE_URL = ''
  QE_URL = 'api/QE'
  QE_TEMPLATE_URL = 'api/QETemplateDraft'

  constructor(
    @Inject('BASE_URL') baseUrl: string,
    private http: HttpClient) {
    this.BASE_URL = baseUrl
    this.QE_URL = baseUrl + this.QE_URL
    this.QE_TEMPLATE_URL =  baseUrl + this.QE_TEMPLATE_URL
  }

  /* getAllCups(): Observable<CUP[]> {
    return this.http.get<CUP[]>(`${this.QE_URL}`)
  } */

  getTemplates(): Observable<ModelResponse>{
    return this.http.get<ModelResponse>(`${this.QE_TEMPLATE_URL}/GetAllTemplate`)
  }

  getTemplateById(id:number): Observable<QEResponse>{
    return this.http.get<QEResponse>(`${this.QE_TEMPLATE_URL}/GetTemplate/${id}`)
  }
  
  createTemplate(qeModel:QEModel): Observable<QEModelResponse>{
    return this.http.post<QEModelResponse>(`${this.QE_TEMPLATE_URL}/CreateTemplateBozza`, qeModel)
  }

  deleteTemplate(id: number){
    return this.http.delete(`${this.QE_TEMPLATE_URL}/Delete/${id}`)
  }
  

  getQeByIdCup(id: number): Observable<CUPResponse> {
    return this.http.get<CUPResponse>(`${this.QE_URL}/${id}/cup`)
  }

  //PASSO L'ID DEL QE E MI RESTITUISCE IL QE STESSO
  getQeById(id: number): Observable<QEResponse> {
    return this.http.get<QEResponse>(`${this.QE_URL}/${id}`)
  }
  getQeHistoryById(id: number): Observable<QEResponse> {
    return this.http.get<QEResponse>(`${this.QE_URL}/${id}/history`)
  }

  createAttualeQe(qe: QE): Observable<QEResponse> {
    return this.http.post<QEResponse>(`${this.QE_URL}`, qe)
  }

  updateQe(qe: QE): Observable<QEResponse> {
    return this.http.put<QEResponse>(`${this.QE_URL}`, qe)
  }
  updateQeDraft(qe: QE): Observable<QEResponse> {
    return this.http.put<QEResponse>(`${this.QE_URL}/draft`, qe)
  }

  getBozzeQE(cupId: number): Observable<QEResponse> {
    return this.http.get<QEResponse>(`${this.QE_URL}/draft/${cupId}`)
  }

  getFlatQEItems(cupId: number): Observable<QEItemBaseResponse> {
    return this.http.get<QEItemBaseResponse>(`${this.QE_URL}/flatList/${cupId}`)
  }

  getQECompare(firstQeId: number, qeMainId): Observable<QECompareResponse> {
    return this.http.get<QECompareResponse>(`${this.QE_URL}/compare/${qeMainId}/${firstQeId}`)
  }

  getQEExcelConTotaliSezioni(id: number): Observable<QETotaliSezioniExcelResponse> {
    return this.http.get<QETotaliSezioniExcelResponse>(`${this.QE_URL}/${id}/GetQEExcelTotaliSezione`)
  }


  getQuadroEconomicoDettaglio(id: number): Observable<QEExcelTotaliEsecuzioneResponse> {
    return this.http.get<QEExcelTotaliEsecuzioneResponse>(`${this.QE_URL}/${id}/GetQuadroEconomicoDettaglio`)
  }


  getTotaliCupAsync(cupIds: number[]): Observable<CupTotaliResponse> {
    let filterURL = '';
    if(cupIds.length > 0) {
      filterURL = `${this.QE_URL}/GetTotaliCupAsync?Ids=${cupIds[0]}`;
      if (cupIds.length > 1) {
        cupIds.forEach(cupId => {
          if (cupId && cupId !== cupIds[0]) {
            filterURL += `&Ids=${cupId}`;
          }
        });
      }
    }
    return this.http.get<CupTotaliResponse>(filterURL);
  }

}
