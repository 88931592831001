import { Injectable } from '@angular/core';

export interface Comune {

  denominazioneInItaliano: string;
  regione: string,
  provincia: string,
  codiceCatastale: string

}


@Injectable({
  providedIn: 'root'
})


export class EntiService {

  Comuni = [
      {
        denominazioneInItaliano: "Agliè",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A074"
      },
      {
        denominazioneInItaliano: "Airasca",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A109"
      },
      {
        denominazioneInItaliano: "Ala di Stura",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A117"
      },
      {
        denominazioneInItaliano: "Albiano d'Ivrea",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A157"
      },
      {
        denominazioneInItaliano: "Almese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A218"
      },
      {
        denominazioneInItaliano: "Alpette",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A221"
      },
      {
        denominazioneInItaliano: "Alpignano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A222"
      },
      {
        denominazioneInItaliano: "Andezeno",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A275"
      },
      {
        denominazioneInItaliano: "Andrate",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A282"
      },
      {
        denominazioneInItaliano: "Angrogna",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A295"
      },
      {
        denominazioneInItaliano: "Arignano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A405"
      },
      {
        denominazioneInItaliano: "Avigliana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A518"
      },
      {
        denominazioneInItaliano: "Azeglio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A525"
      },
      {
        denominazioneInItaliano: "Bairo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A584"
      },
      {
        denominazioneInItaliano: "Balangero",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A587"
      },
      {
        denominazioneInItaliano: "Baldissero Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A590"
      },
      {
        denominazioneInItaliano: "Baldissero Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A591"
      },
      {
        denominazioneInItaliano: "Balme",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A599"
      },
      {
        denominazioneInItaliano: "Banchette",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A607"
      },
      {
        denominazioneInItaliano: "Barbania",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A625"
      },
      {
        denominazioneInItaliano: "Bardonecchia",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A651"
      },
      {
        denominazioneInItaliano: "Barone Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A673"
      },
      {
        denominazioneInItaliano: "Beinasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A734"
      },
      {
        denominazioneInItaliano: "Bibiana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A853"
      },
      {
        denominazioneInItaliano: "Bobbio Pellice",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A910"
      },
      {
        denominazioneInItaliano: "Bollengo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A941"
      },
      {
        denominazioneInItaliano: "Borgaro Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "A990"
      },
      {
        denominazioneInItaliano: "Borgiallo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B003"
      },
      {
        denominazioneInItaliano: "Borgofranco d'Ivrea",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B015"
      },
      {
        denominazioneInItaliano: "Borgomasino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B021"
      },
      {
        denominazioneInItaliano: "Borgone Susa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B024"
      },
      {
        denominazioneInItaliano: "Bosconero",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B075"
      },
      {
        denominazioneInItaliano: "Brandizzo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B121"
      },
      {
        denominazioneInItaliano: "Bricherasio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B171"
      },
      {
        denominazioneInItaliano: "Brosso",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B205"
      },
      {
        denominazioneInItaliano: "Brozolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B209"
      },
      {
        denominazioneInItaliano: "Bruino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B216"
      },
      {
        denominazioneInItaliano: "Brusasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B225"
      },
      {
        denominazioneInItaliano: "Bruzolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B232"
      },
      {
        denominazioneInItaliano: "Buriasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B278"
      },
      {
        denominazioneInItaliano: "Burolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B279"
      },
      {
        denominazioneInItaliano: "Busano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B284"
      },
      {
        denominazioneInItaliano: "Bussoleno",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B297"
      },
      {
        denominazioneInItaliano: "Buttigliera Alta",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B305"
      },
      {
        denominazioneInItaliano: "Cafasse",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B350"
      },
      {
        denominazioneInItaliano: "Caluso",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B435"
      },
      {
        denominazioneInItaliano: "Cambiano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B462"
      },
      {
        denominazioneInItaliano: "Campiglione Fenile",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B512"
      },
      {
        denominazioneInItaliano: "Candia Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B588"
      },
      {
        denominazioneInItaliano: "Candiolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B592"
      },
      {
        denominazioneInItaliano: "Canischio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B605"
      },
      {
        denominazioneInItaliano: "Cantalupa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B628"
      },
      {
        denominazioneInItaliano: "Cantoira",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B637"
      },
      {
        denominazioneInItaliano: "Caprie",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B705"
      },
      {
        denominazioneInItaliano: "Caravino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B733"
      },
      {
        denominazioneInItaliano: "Carema",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B762"
      },
      {
        denominazioneInItaliano: "Carignano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B777"
      },
      {
        denominazioneInItaliano: "Carmagnola",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B791"
      },
      {
        denominazioneInItaliano: "Casalborgone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B867"
      },
      {
        denominazioneInItaliano: "Cascinette d'Ivrea",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B953"
      },
      {
        denominazioneInItaliano: "Caselette",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B955"
      },
      {
        denominazioneInItaliano: "Caselle Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B960"
      },
      {
        denominazioneInItaliano: "Castagneto Po",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C045"
      },
      {
        denominazioneInItaliano: "Castagnole Piemonte",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C048"
      },
      {
        denominazioneInItaliano: "Castellamonte",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C133"
      },
      {
        denominazioneInItaliano: "Castelnuovo Nigra",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C241"
      },
      {
        denominazioneInItaliano: "Castiglione Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C307"
      },
      {
        denominazioneInItaliano: "Cavagnolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C369"
      },
      {
        denominazioneInItaliano: "Cavour",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C404"
      },
      {
        denominazioneInItaliano: "Cercenasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C487"
      },
      {
        denominazioneInItaliano: "Ceres",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C497"
      },
      {
        denominazioneInItaliano: "Ceresole Reale",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C505"
      },
      {
        denominazioneInItaliano: "Cesana Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C564"
      },
      {
        denominazioneInItaliano: "Chialamberto",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C604"
      },
      {
        denominazioneInItaliano: "Chianocco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C610"
      },
      {
        denominazioneInItaliano: "Chiaverano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C624"
      },
      {
        denominazioneInItaliano: "Chieri",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C627"
      },
      {
        denominazioneInItaliano: "Chiesanuova",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C629"
      },
      {
        denominazioneInItaliano: "Chiomonte",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C639"
      },
      {
        denominazioneInItaliano: "Chiusa di San Michele",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C655"
      },
      {
        denominazioneInItaliano: "Chivasso",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C665"
      },
      {
        denominazioneInItaliano: "Ciconio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C679"
      },
      {
        denominazioneInItaliano: "Cintano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C711"
      },
      {
        denominazioneInItaliano: "Cinzano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C715"
      },
      {
        denominazioneInItaliano: "Ciriè",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C722"
      },
      {
        denominazioneInItaliano: "Claviere",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C793"
      },
      {
        denominazioneInItaliano: "Coassolo Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C801"
      },
      {
        denominazioneInItaliano: "Coazze",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C803"
      },
      {
        denominazioneInItaliano: "Collegno",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C860"
      },
      {
        denominazioneInItaliano: "Colleretto Castelnuovo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C867"
      },
      {
        denominazioneInItaliano: "Colleretto Giacosa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C868"
      },
      {
        denominazioneInItaliano: "Condove",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "C955"
      },
      {
        denominazioneInItaliano: "Corio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D008"
      },
      {
        denominazioneInItaliano: "Cossano Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D092"
      },
      {
        denominazioneInItaliano: "Cuceglio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D197"
      },
      {
        denominazioneInItaliano: "Cumiana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D202"
      },
      {
        denominazioneInItaliano: "Cuorgnè",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D208"
      },
      {
        denominazioneInItaliano: "Druento",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D373"
      },
      {
        denominazioneInItaliano: "Exilles",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D433"
      },
      {
        denominazioneInItaliano: "Favria",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D520"
      },
      {
        denominazioneInItaliano: "Feletto",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D524"
      },
      {
        denominazioneInItaliano: "Fenestrelle",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D532"
      },
      {
        denominazioneInItaliano: "Fiano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D562"
      },
      {
        denominazioneInItaliano: "Fiorano Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D608"
      },
      {
        denominazioneInItaliano: "Foglizzo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D646"
      },
      {
        denominazioneInItaliano: "Forno Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D725"
      },
      {
        denominazioneInItaliano: "Frassinetto",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D781"
      },
      {
        denominazioneInItaliano: "Front",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D805"
      },
      {
        denominazioneInItaliano: "Frossasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D812"
      },
      {
        denominazioneInItaliano: "Garzigliana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D931"
      },
      {
        denominazioneInItaliano: "Gassino Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D933"
      },
      {
        denominazioneInItaliano: "Germagnano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D983"
      },
      {
        denominazioneInItaliano: "Giaglione",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E009"
      },
      {
        denominazioneInItaliano: "Giaveno",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E020"
      },
      {
        denominazioneInItaliano: "Givoletto",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E067"
      },
      {
        denominazioneInItaliano: "Gravere",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E154"
      },
      {
        denominazioneInItaliano: "Groscavallo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E199"
      },
      {
        denominazioneInItaliano: "Grosso",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E203"
      },
      {
        denominazioneInItaliano: "Grugliasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E216"
      },
      {
        denominazioneInItaliano: "Ingria",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E301"
      },
      {
        denominazioneInItaliano: "Inverso Pinasca",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E311"
      },
      {
        denominazioneInItaliano: "Isolabella",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E345"
      },
      {
        denominazioneInItaliano: "Issiglio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E368"
      },
      {
        denominazioneInItaliano: "Ivrea",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E379"
      },
      {
        denominazioneInItaliano: "La Cassa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E394"
      },
      {
        denominazioneInItaliano: "La Loggia",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E423"
      },
      {
        denominazioneInItaliano: "Lanzo Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E445"
      },
      {
        denominazioneInItaliano: "Lauriano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E484"
      },
      {
        denominazioneInItaliano: "Leini",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E518"
      },
      {
        denominazioneInItaliano: "Lemie",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E520"
      },
      {
        denominazioneInItaliano: "Lessolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E551"
      },
      {
        denominazioneInItaliano: "Levone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E566"
      },
      {
        denominazioneInItaliano: "Locana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E635"
      },
      {
        denominazioneInItaliano: "Lombardore",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E660"
      },
      {
        denominazioneInItaliano: "Lombriasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E661"
      },
      {
        denominazioneInItaliano: "Loranzè",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E683"
      },
      {
        denominazioneInItaliano: "Luserna San Giovanni",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E758"
      },
      {
        denominazioneInItaliano: "Lusernetta",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E759"
      },
      {
        denominazioneInItaliano: "Lusigliè",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E763"
      },
      {
        denominazioneInItaliano: "Macello",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E782"
      },
      {
        denominazioneInItaliano: "Maglione",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E817"
      },
      {
        denominazioneInItaliano: "Marentino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "E941"
      },
      {
        denominazioneInItaliano: "Massello",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F041"
      },
      {
        denominazioneInItaliano: "Mathi",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F053"
      },
      {
        denominazioneInItaliano: "Mattie",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F058"
      },
      {
        denominazioneInItaliano: "Mazzè",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F067"
      },
      {
        denominazioneInItaliano: "Meana di Susa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F074"
      },
      {
        denominazioneInItaliano: "Mercenasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F140"
      },
      {
        denominazioneInItaliano: "Mezzenile",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F182"
      },
      {
        denominazioneInItaliano: "Mombello di Torino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F315"
      },
      {
        denominazioneInItaliano: "Mompantero",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F318"
      },
      {
        denominazioneInItaliano: "Monastero di Lanzo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F327"
      },
      {
        denominazioneInItaliano: "Moncalieri",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F335"
      },
      {
        denominazioneInItaliano: "Moncenisio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "D553"
      },
      {
        denominazioneInItaliano: "Montaldo Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F407"
      },
      {
        denominazioneInItaliano: "Montalenghe",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F411"
      },
      {
        denominazioneInItaliano: "Montalto Dora",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F420"
      },
      {
        denominazioneInItaliano: "Montanaro",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F422"
      },
      {
        denominazioneInItaliano: "Monteu da Po",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F651"
      },
      {
        denominazioneInItaliano: "Moriondo Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F733"
      },
      {
        denominazioneInItaliano: "Nichelino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F889"
      },
      {
        denominazioneInItaliano: "Noasca",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F906"
      },
      {
        denominazioneInItaliano: "Nole",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F925"
      },
      {
        denominazioneInItaliano: "Nomaglio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F927"
      },
      {
        denominazioneInItaliano: "None",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F931"
      },
      {
        denominazioneInItaliano: "Novalesa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "F948"
      },
      {
        denominazioneInItaliano: "Oglianico",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G010"
      },
      {
        denominazioneInItaliano: "Orbassano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G087"
      },
      {
        denominazioneInItaliano: "Orio Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G109"
      },
      {
        denominazioneInItaliano: "Osasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G151"
      },
      {
        denominazioneInItaliano: "Osasio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G152"
      },
      {
        denominazioneInItaliano: "Oulx",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G196"
      },
      {
        denominazioneInItaliano: "Ozegna",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G202"
      },
      {
        denominazioneInItaliano: "Palazzo Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G262"
      },
      {
        denominazioneInItaliano: "Pancalieri",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G303"
      },
      {
        denominazioneInItaliano: "Parella",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G330"
      },
      {
        denominazioneInItaliano: "Pavarolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G387"
      },
      {
        denominazioneInItaliano: "Pavone Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G392"
      },
      {
        denominazioneInItaliano: "Pecetto Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G398"
      },
      {
        denominazioneInItaliano: "Perosa Argentina",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G463"
      },
      {
        denominazioneInItaliano: "Perosa Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G462"
      },
      {
        denominazioneInItaliano: "Perrero",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G465"
      },
      {
        denominazioneInItaliano: "Pertusio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G477"
      },
      {
        denominazioneInItaliano: "Pessinetto",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G505"
      },
      {
        denominazioneInItaliano: "Pianezza",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G559"
      },
      {
        denominazioneInItaliano: "Pinasca",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G672"
      },
      {
        denominazioneInItaliano: "Pinerolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G674"
      },
      {
        denominazioneInItaliano: "Pino Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G678"
      },
      {
        denominazioneInItaliano: "Piobesi Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G684"
      },
      {
        denominazioneInItaliano: "Piossasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G691"
      },
      {
        denominazioneInItaliano: "Piscina",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G705"
      },
      {
        denominazioneInItaliano: "Piverone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G719"
      },
      {
        denominazioneInItaliano: "Poirino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G777"
      },
      {
        denominazioneInItaliano: "Pomaretto",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G805"
      },
      {
        denominazioneInItaliano: "Pont Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G826"
      },
      {
        denominazioneInItaliano: "Porte",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G900"
      },
      {
        denominazioneInItaliano: "Pragelato",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G973"
      },
      {
        denominazioneInItaliano: "Prali",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G978"
      },
      {
        denominazioneInItaliano: "Pralormo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G979"
      },
      {
        denominazioneInItaliano: "Pramollo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G982"
      },
      {
        denominazioneInItaliano: "Prarostino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G986"
      },
      {
        denominazioneInItaliano: "Prascorsano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G988"
      },
      {
        denominazioneInItaliano: "Pratiglione",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "G997"
      },
      {
        denominazioneInItaliano: "Quagliuzzo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H100"
      },
      {
        denominazioneInItaliano: "Quassolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H120"
      },
      {
        denominazioneInItaliano: "Quincinetto",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H127"
      },
      {
        denominazioneInItaliano: "Reano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H207"
      },
      {
        denominazioneInItaliano: "Ribordone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H270"
      },
      {
        denominazioneInItaliano: "Rivalba",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H333"
      },
      {
        denominazioneInItaliano: "Rivalta di Torino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H335"
      },
      {
        denominazioneInItaliano: "Riva presso Chieri",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H337"
      },
      {
        denominazioneInItaliano: "Rivara",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H338"
      },
      {
        denominazioneInItaliano: "Rivarolo Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H340"
      },
      {
        denominazioneInItaliano: "Rivarossa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H344"
      },
      {
        denominazioneInItaliano: "Rivoli",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H355"
      },
      {
        denominazioneInItaliano: "Robassomero",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H367"
      },
      {
        denominazioneInItaliano: "Rocca Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H386"
      },
      {
        denominazioneInItaliano: "Roletto",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H498"
      },
      {
        denominazioneInItaliano: "Romano Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H511"
      },
      {
        denominazioneInItaliano: "Ronco Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H539"
      },
      {
        denominazioneInItaliano: "Rondissone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H547"
      },
      {
        denominazioneInItaliano: "Rorà",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H554"
      },
      {
        denominazioneInItaliano: "Roure",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H555"
      },
      {
        denominazioneInItaliano: "Rosta",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H583"
      },
      {
        denominazioneInItaliano: "Rubiana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H627"
      },
      {
        denominazioneInItaliano: "Rueglio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H631"
      },
      {
        denominazioneInItaliano: "Salassa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H691"
      },
      {
        denominazioneInItaliano: "Salbertrand",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H684"
      },
      {
        denominazioneInItaliano: "Salerano Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H702"
      },
      {
        denominazioneInItaliano: "Salza di Pinerolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H734"
      },
      {
        denominazioneInItaliano: "Samone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H753"
      },
      {
        denominazioneInItaliano: "San Benigno Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H775"
      },
      {
        denominazioneInItaliano: "San Carlo Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H789"
      },
      {
        denominazioneInItaliano: "San Colombano Belmonte",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H804"
      },
      {
        denominazioneInItaliano: "San Didero",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H820"
      },
      {
        denominazioneInItaliano: "San Francesco al Campo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H847"
      },
      {
        denominazioneInItaliano: "Sangano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H855"
      },
      {
        denominazioneInItaliano: "San Germano Chisone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H862"
      },
      {
        denominazioneInItaliano: "San Gillio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H873"
      },
      {
        denominazioneInItaliano: "San Giorgio Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H890"
      },
      {
        denominazioneInItaliano: "San Giorio di Susa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H900"
      },
      {
        denominazioneInItaliano: "San Giusto Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H936"
      },
      {
        denominazioneInItaliano: "San Martino Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "H997"
      },
      {
        denominazioneInItaliano: "San Maurizio Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I024"
      },
      {
        denominazioneInItaliano: "San Mauro Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I030"
      },
      {
        denominazioneInItaliano: "San Pietro Val Lemina",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I090"
      },
      {
        denominazioneInItaliano: "San Ponso",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I126"
      },
      {
        denominazioneInItaliano: "San Raffaele Cimena",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I137"
      },
      {
        denominazioneInItaliano: "San Sebastiano da Po",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I152"
      },
      {
        denominazioneInItaliano: "San Secondo di Pinerolo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I154"
      },
      {
        denominazioneInItaliano: "Sant'Ambrogio di Torino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I258"
      },
      {
        denominazioneInItaliano: "Sant'Antonino di Susa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I296"
      },
      {
        denominazioneInItaliano: "Santena",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I327"
      },
      {
        denominazioneInItaliano: "Sauze di Cesana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I465"
      },
      {
        denominazioneInItaliano: "Sauze d'Oulx",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I466"
      },
      {
        denominazioneInItaliano: "Scalenghe",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I490"
      },
      {
        denominazioneInItaliano: "Scarmagno",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I511"
      },
      {
        denominazioneInItaliano: "Sciolze",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I539"
      },
      {
        denominazioneInItaliano: "Sestriere",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I692"
      },
      {
        denominazioneInItaliano: "Settimo Rottaro",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I701"
      },
      {
        denominazioneInItaliano: "Settimo Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I703"
      },
      {
        denominazioneInItaliano: "Settimo Vittone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I702"
      },
      {
        denominazioneInItaliano: "Sparone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I886"
      },
      {
        denominazioneInItaliano: "Strambinello",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I969"
      },
      {
        denominazioneInItaliano: "Strambino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "I970"
      },
      {
        denominazioneInItaliano: "Susa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L013"
      },
      {
        denominazioneInItaliano: "Tavagnasco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L066"
      },
      {
        denominazioneInItaliano: "Torino",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L219"
      },
      {
        denominazioneInItaliano: "Torrazza Piemonte",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L238"
      },
      {
        denominazioneInItaliano: "Torre Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L247"
      },
      {
        denominazioneInItaliano: "Torre Pellice",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L277"
      },
      {
        denominazioneInItaliano: "Trana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L327"
      },
      {
        denominazioneInItaliano: "Traversella",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L345"
      },
      {
        denominazioneInItaliano: "Traves",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L340"
      },
      {
        denominazioneInItaliano: "Trofarello",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L445"
      },
      {
        denominazioneInItaliano: "Usseaux",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L515"
      },
      {
        denominazioneInItaliano: "Usseglio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L516"
      },
      {
        denominazioneInItaliano: "Vaie",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L538"
      },
      {
        denominazioneInItaliano: "Val della Torre",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L555"
      },
      {
        denominazioneInItaliano: "Valgioie",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L578"
      },
      {
        denominazioneInItaliano: "Vallo Torinese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L629"
      },
      {
        denominazioneInItaliano: "Valperga",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L644"
      },
      {
        denominazioneInItaliano: "Valprato Soana",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "B510"
      },
      {
        denominazioneInItaliano: "Varisella",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L685"
      },
      {
        denominazioneInItaliano: "Vauda Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L698"
      },
      {
        denominazioneInItaliano: "Venaus",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L726"
      },
      {
        denominazioneInItaliano: "Venaria Reale",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L727"
      },
      {
        denominazioneInItaliano: "Verolengo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L779"
      },
      {
        denominazioneInItaliano: "Verrua Savoia",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L787"
      },
      {
        denominazioneInItaliano: "Vestignè",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L811"
      },
      {
        denominazioneInItaliano: "Vialfrè",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L830"
      },
      {
        denominazioneInItaliano: "Vidracco",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L857"
      },
      {
        denominazioneInItaliano: "Vigone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L898"
      },
      {
        denominazioneInItaliano: "Villafranca Piemonte",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L948"
      },
      {
        denominazioneInItaliano: "Villanova Canavese",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L982"
      },
      {
        denominazioneInItaliano: "Villarbasse",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M002"
      },
      {
        denominazioneInItaliano: "Villar Dora",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "L999"
      },
      {
        denominazioneInItaliano: "Villareggia",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M004"
      },
      {
        denominazioneInItaliano: "Villar Focchiardo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M007"
      },
      {
        denominazioneInItaliano: "Villar Pellice",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M013"
      },
      {
        denominazioneInItaliano: "Villar Perosa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M014"
      },
      {
        denominazioneInItaliano: "Villastellone",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M027"
      },
      {
        denominazioneInItaliano: "Vinovo",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M060"
      },
      {
        denominazioneInItaliano: "Virle Piemonte",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M069"
      },
      {
        denominazioneInItaliano: "Vische",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M071"
      },
      {
        denominazioneInItaliano: "Vistrorio",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M080"
      },
      {
        denominazioneInItaliano: "Viù",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M094"
      },
      {
        denominazioneInItaliano: "Volpiano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M122"
      },
      {
        denominazioneInItaliano: "Volvera",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M133"
      },
      {
        denominazioneInItaliano: "Mappano",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M316"
      },
      {
        denominazioneInItaliano: "Val di Chy",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M405"
      },
      {
        denominazioneInItaliano: "Valchiusa",
        regione: "Piemonte",
        provincia: "Torino",
        codiceCatastale: "M415"
      },
      {
        denominazioneInItaliano: "Alagna Valsesia",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A119"
      },
      {
        denominazioneInItaliano: "Albano Vercellese",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A130"
      },
      {
        denominazioneInItaliano: "Alice Castello",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A198"
      },
      {
        denominazioneInItaliano: "Arborio",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A358"
      },
      {
        denominazioneInItaliano: "Asigliano Vercellese",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A466"
      },
      {
        denominazioneInItaliano: "Balmuccia",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A600"
      },
      {
        denominazioneInItaliano: "Balocco",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A601"
      },
      {
        denominazioneInItaliano: "Bianzè",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A847"
      },
      {
        denominazioneInItaliano: "Boccioleto",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "A914"
      },
      {
        denominazioneInItaliano: "Borgo d'Ale",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B009"
      },
      {
        denominazioneInItaliano: "Borgosesia",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B041"
      },
      {
        denominazioneInItaliano: "Borgo Vercelli",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B046"
      },
      {
        denominazioneInItaliano: "Buronzo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B280"
      },
      {
        denominazioneInItaliano: "Campertogno",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B505"
      },
      {
        denominazioneInItaliano: "Carcoforo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B752"
      },
      {
        denominazioneInItaliano: "Caresana",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B767"
      },
      {
        denominazioneInItaliano: "Caresanablot",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B768"
      },
      {
        denominazioneInItaliano: "Carisio",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B782"
      },
      {
        denominazioneInItaliano: "Casanova Elvo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B928"
      },
      {
        denominazioneInItaliano: "San Giacomo Vercellese",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "B952"
      },
      {
        denominazioneInItaliano: "Cervatto",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "C548"
      },
      {
        denominazioneInItaliano: "Cigliano",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "C680"
      },
      {
        denominazioneInItaliano: "Civiasco",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "C757"
      },
      {
        denominazioneInItaliano: "Collobiano",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "C884"
      },
      {
        denominazioneInItaliano: "Costanzana",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D113"
      },
      {
        denominazioneInItaliano: "Cravagliana",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D132"
      },
      {
        denominazioneInItaliano: "Crescentino",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D154"
      },
      {
        denominazioneInItaliano: "Crova",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D187"
      },
      {
        denominazioneInItaliano: "Desana",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D281"
      },
      {
        denominazioneInItaliano: "Fobello",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D641"
      },
      {
        denominazioneInItaliano: "Fontanetto Po",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D676"
      },
      {
        denominazioneInItaliano: "Formigliana",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D712"
      },
      {
        denominazioneInItaliano: "Gattinara",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "D938"
      },
      {
        denominazioneInItaliano: "Ghislarengo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "E007"
      },
      {
        denominazioneInItaliano: "Greggio",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "E163"
      },
      {
        denominazioneInItaliano: "Guardabosone",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "E237"
      },
      {
        denominazioneInItaliano: "Lamporo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "E433"
      },
      {
        denominazioneInItaliano: "Lenta",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "E528"
      },
      {
        denominazioneInItaliano: "Lignana",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "E583"
      },
      {
        denominazioneInItaliano: "Livorno Ferraris",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "E626"
      },
      {
        denominazioneInItaliano: "Lozzolo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "E711"
      },
      {
        denominazioneInItaliano: "Mollia",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "F297"
      },
      {
        denominazioneInItaliano: "Moncrivello",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "F342"
      },
      {
        denominazioneInItaliano: "Motta de' Conti",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "F774"
      },
      {
        denominazioneInItaliano: "Olcenengo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G016"
      },
      {
        denominazioneInItaliano: "Oldenico",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G018"
      },
      {
        denominazioneInItaliano: "Palazzolo Vercellese",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G266"
      },
      {
        denominazioneInItaliano: "Pertengo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G471"
      },
      {
        denominazioneInItaliano: "Pezzana",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G528"
      },
      {
        denominazioneInItaliano: "Pila",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G666"
      },
      {
        denominazioneInItaliano: "Piode",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G685"
      },
      {
        denominazioneInItaliano: "Postua",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G940"
      },
      {
        denominazioneInItaliano: "Prarolo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "G985"
      },
      {
        denominazioneInItaliano: "Quarona",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H108"
      },
      {
        denominazioneInItaliano: "Quinto Vercellese",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H132"
      },
      {
        denominazioneInItaliano: "Rassa",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H188"
      },
      {
        denominazioneInItaliano: "Rimella",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H293"
      },
      {
        denominazioneInItaliano: "Rive",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H346"
      },
      {
        denominazioneInItaliano: "Roasio",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H365"
      },
      {
        denominazioneInItaliano: "Ronsecco",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H549"
      },
      {
        denominazioneInItaliano: "Rossa",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H577"
      },
      {
        denominazioneInItaliano: "Rovasenda",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H364"
      },
      {
        denominazioneInItaliano: "Salasco",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H690"
      },
      {
        denominazioneInItaliano: "Sali Vercellese",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H707"
      },
      {
        denominazioneInItaliano: "Saluggia",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H725"
      },
      {
        denominazioneInItaliano: "San Germano Vercellese",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "H861"
      },
      {
        denominazioneInItaliano: "Santhià",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "I337"
      },
      {
        denominazioneInItaliano: "Scopa",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "I544"
      },
      {
        denominazioneInItaliano: "Scopello",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "I545"
      },
      {
        denominazioneInItaliano: "Serravalle Sesia",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "I663"
      },
      {
        denominazioneInItaliano: "Stroppiana",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "I984"
      },
      {
        denominazioneInItaliano: "Tricerro",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "L420"
      },
      {
        denominazioneInItaliano: "Trino",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "L429"
      },
      {
        denominazioneInItaliano: "Tronzano Vercellese",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "L451"
      },
      {
        denominazioneInItaliano: "Valduggia",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "L566"
      },
      {
        denominazioneInItaliano: "Varallo",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "L669"
      },
      {
        denominazioneInItaliano: "Vercelli",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "L750"
      },
      {
        denominazioneInItaliano: "Villarboit",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "M003"
      },
      {
        denominazioneInItaliano: "Villata",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "M028"
      },
      {
        denominazioneInItaliano: "Vocca",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "M106"
      },
      {
        denominazioneInItaliano: "Alto Sermenza",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "M389"
      },
      {
        denominazioneInItaliano: "Cellio con Breia",
        regione: "Piemonte",
        provincia: "Vercelli",
        codiceCatastale: "M398"
      },
      {
        denominazioneInItaliano: "Agrate Conturbia",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A088"
      },
      {
        denominazioneInItaliano: "Ameno",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A264"
      },
      {
        denominazioneInItaliano: "Armeno",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A414"
      },
      {
        denominazioneInItaliano: "Arona",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A429"
      },
      {
        denominazioneInItaliano: "Barengo",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A653"
      },
      {
        denominazioneInItaliano: "Bellinzago Novarese",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A752"
      },
      {
        denominazioneInItaliano: "Biandrate",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A844"
      },
      {
        denominazioneInItaliano: "Boca",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A911"
      },
      {
        denominazioneInItaliano: "Bogogno",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A929"
      },
      {
        denominazioneInItaliano: "Bolzano Novarese",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "A953"
      },
      {
        denominazioneInItaliano: "Borgolavezzaro",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B016"
      },
      {
        denominazioneInItaliano: "Borgomanero",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B019"
      },
      {
        denominazioneInItaliano: "Borgo Ticino",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B043"
      },
      {
        denominazioneInItaliano: "Briga Novarese",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B176"
      },
      {
        denominazioneInItaliano: "Briona",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B183"
      },
      {
        denominazioneInItaliano: "Caltignaga",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B431"
      },
      {
        denominazioneInItaliano: "Cameri",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B473"
      },
      {
        denominazioneInItaliano: "Carpignano Sesia",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B823"
      },
      {
        denominazioneInItaliano: "Casalbeltrame",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B864"
      },
      {
        denominazioneInItaliano: "Casaleggio Novara",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B883"
      },
      {
        denominazioneInItaliano: "Casalino",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B897"
      },
      {
        denominazioneInItaliano: "Casalvolone",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "B920"
      },
      {
        denominazioneInItaliano: "Castellazzo Novarese",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "C149"
      },
      {
        denominazioneInItaliano: "Castelletto sopra Ticino",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "C166"
      },
      {
        denominazioneInItaliano: "Cavaglietto",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "C364"
      },
      {
        denominazioneInItaliano: "Cavaglio d'Agogna",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "C365"
      },
      {
        denominazioneInItaliano: "Cavallirio",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "C378"
      },
      {
        denominazioneInItaliano: "Cerano",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "C483"
      },
      {
        denominazioneInItaliano: "Colazza",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "C829"
      },
      {
        denominazioneInItaliano: "Comignago",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "C926"
      },
      {
        denominazioneInItaliano: "Cressa",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D162"
      },
      {
        denominazioneInItaliano: "Cureggio",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D216"
      },
      {
        denominazioneInItaliano: "Divignano",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D309"
      },
      {
        denominazioneInItaliano: "Dormelletto",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D347"
      },
      {
        denominazioneInItaliano: "Fara Novarese",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D492"
      },
      {
        denominazioneInItaliano: "Fontaneto d'Agogna",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D675"
      },
      {
        denominazioneInItaliano: "Galliate",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D872"
      },
      {
        denominazioneInItaliano: "Garbagna Novarese",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D911"
      },
      {
        denominazioneInItaliano: "Gargallo",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "D921"
      },
      {
        denominazioneInItaliano: "Ghemme",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E001"
      },
      {
        denominazioneInItaliano: "Gozzano",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E120"
      },
      {
        denominazioneInItaliano: "Granozzo con Monticello",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E143"
      },
      {
        denominazioneInItaliano: "Grignasco",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E177"
      },
      {
        denominazioneInItaliano: "Invorio",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E314"
      },
      {
        denominazioneInItaliano: "Landiona",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E436"
      },
      {
        denominazioneInItaliano: "Lesa",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E544"
      },
      {
        denominazioneInItaliano: "Maggiora",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E803"
      },
      {
        denominazioneInItaliano: "Mandello Vitta",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E880"
      },
      {
        denominazioneInItaliano: "Marano Ticino",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "E907"
      },
      {
        denominazioneInItaliano: "Massino Visconti",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "F047"
      },
      {
        denominazioneInItaliano: "Meina",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "F093"
      },
      {
        denominazioneInItaliano: "Mezzomerico",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "F188"
      },
      {
        denominazioneInItaliano: "Miasino",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "F191"
      },
      {
        denominazioneInItaliano: "Momo",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "F317"
      },
      {
        denominazioneInItaliano: "Nebbiuno",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "F859"
      },
      {
        denominazioneInItaliano: "Nibbiola",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "F886"
      },
      {
        denominazioneInItaliano: "Novara",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "F952"
      },
      {
        denominazioneInItaliano: "Oleggio",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G019"
      },
      {
        denominazioneInItaliano: "Oleggio Castello",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G020"
      },
      {
        denominazioneInItaliano: "Orta San Giulio",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G134"
      },
      {
        denominazioneInItaliano: "Paruzzaro",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G349"
      },
      {
        denominazioneInItaliano: "Pella",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G421"
      },
      {
        denominazioneInItaliano: "Pettenasco",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G520"
      },
      {
        denominazioneInItaliano: "Pisano",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G703"
      },
      {
        denominazioneInItaliano: "Pogno",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G775"
      },
      {
        denominazioneInItaliano: "Pombia",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "G809"
      },
      {
        denominazioneInItaliano: "Prato Sesia",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "H001"
      },
      {
        denominazioneInItaliano: "Recetto",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "H213"
      },
      {
        denominazioneInItaliano: "Romagnano Sesia",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "H502"
      },
      {
        denominazioneInItaliano: "Romentino",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "H518"
      },
      {
        denominazioneInItaliano: "San Maurizio d'Opaglio",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "I025"
      },
      {
        denominazioneInItaliano: "San Nazzaro Sesia",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "I052"
      },
      {
        denominazioneInItaliano: "San Pietro Mosezzo",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "I116"
      },
      {
        denominazioneInItaliano: "Sillavengo",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "I736"
      },
      {
        denominazioneInItaliano: "Sizzano",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "I767"
      },
      {
        denominazioneInItaliano: "Soriso",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "I857"
      },
      {
        denominazioneInItaliano: "Sozzago",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "I880"
      },
      {
        denominazioneInItaliano: "Suno",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "L007"
      },
      {
        denominazioneInItaliano: "Terdobbiate",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "L104"
      },
      {
        denominazioneInItaliano: "Tornaco",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "L223"
      },
      {
        denominazioneInItaliano: "Trecate",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "L356"
      },
      {
        denominazioneInItaliano: "Vaprio d'Agogna",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "L668"
      },
      {
        denominazioneInItaliano: "Varallo Pombia",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "L670"
      },
      {
        denominazioneInItaliano: "Vespolate",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "L808"
      },
      {
        denominazioneInItaliano: "Vicolungo",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "L847"
      },
      {
        denominazioneInItaliano: "Vinzaglio",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "M062"
      },
      {
        denominazioneInItaliano: "Gattico-Veruno",
        regione: "Piemonte",
        provincia: "Novara",
        codiceCatastale: "M416"
      },
      {
        denominazioneInItaliano: "Acceglio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A016"
      },
      {
        denominazioneInItaliano: "Aisone",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A113"
      },
      {
        denominazioneInItaliano: "Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A124"
      },
      {
        denominazioneInItaliano: "Albaretto della Torre",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A139"
      },
      {
        denominazioneInItaliano: "Alto",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A238"
      },
      {
        denominazioneInItaliano: "Argentera",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A394"
      },
      {
        denominazioneInItaliano: "Arguello",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A396"
      },
      {
        denominazioneInItaliano: "Bagnasco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A555"
      },
      {
        denominazioneInItaliano: "Bagnolo Piemonte",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A571"
      },
      {
        denominazioneInItaliano: "Baldissero d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A589"
      },
      {
        denominazioneInItaliano: "Barbaresco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A629"
      },
      {
        denominazioneInItaliano: "Barge",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A660"
      },
      {
        denominazioneInItaliano: "Barolo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A671"
      },
      {
        denominazioneInItaliano: "Bastia Mondovì",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A709"
      },
      {
        denominazioneInItaliano: "Battifollo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A716"
      },
      {
        denominazioneInItaliano: "Beinette",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A735"
      },
      {
        denominazioneInItaliano: "Bellino",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A750"
      },
      {
        denominazioneInItaliano: "Belvedere Langhe",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A774"
      },
      {
        denominazioneInItaliano: "Bene Vagienna",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A779"
      },
      {
        denominazioneInItaliano: "Benevello",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A782"
      },
      {
        denominazioneInItaliano: "Bergolo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A798"
      },
      {
        denominazioneInItaliano: "Bernezzo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A805"
      },
      {
        denominazioneInItaliano: "Bonvicino",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "A979"
      },
      {
        denominazioneInItaliano: "Borgomale",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B018"
      },
      {
        denominazioneInItaliano: "Borgo San Dalmazzo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B033"
      },
      {
        denominazioneInItaliano: "Bosia",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B079"
      },
      {
        denominazioneInItaliano: "Bossolasco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B084"
      },
      {
        denominazioneInItaliano: "Boves",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B101"
      },
      {
        denominazioneInItaliano: "Bra",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B111"
      },
      {
        denominazioneInItaliano: "Briaglia",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B167"
      },
      {
        denominazioneInItaliano: "Briga Alta",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B175"
      },
      {
        denominazioneInItaliano: "Brondello",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B200"
      },
      {
        denominazioneInItaliano: "Brossasco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B204"
      },
      {
        denominazioneInItaliano: "Busca",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B285"
      },
      {
        denominazioneInItaliano: "Camerana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B467"
      },
      {
        denominazioneInItaliano: "Canale",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B573"
      },
      {
        denominazioneInItaliano: "Canosio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B621"
      },
      {
        denominazioneInItaliano: "Caprauna",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B692"
      },
      {
        denominazioneInItaliano: "Caraglio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B719"
      },
      {
        denominazioneInItaliano: "Caramagna Piemonte",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B720"
      },
      {
        denominazioneInItaliano: "Cardè",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B755"
      },
      {
        denominazioneInItaliano: "Carrù",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B841"
      },
      {
        denominazioneInItaliano: "Cartignano",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B845"
      },
      {
        denominazioneInItaliano: "Casalgrasso",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "B894"
      },
      {
        denominazioneInItaliano: "Castagnito",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C046"
      },
      {
        denominazioneInItaliano: "Casteldelfino",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C081"
      },
      {
        denominazioneInItaliano: "Castelletto Stura",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C165"
      },
      {
        denominazioneInItaliano: "Castelletto Uzzone",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C167"
      },
      {
        denominazioneInItaliano: "Castellinaldo d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C173"
      },
      {
        denominazioneInItaliano: "Castellino Tanaro",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C176"
      },
      {
        denominazioneInItaliano: "Castelmagno",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C205"
      },
      {
        denominazioneInItaliano: "Castelnuovo di Ceva",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C214"
      },
      {
        denominazioneInItaliano: "Castiglione Falletto",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C314"
      },
      {
        denominazioneInItaliano: "Castiglione Tinella",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C317"
      },
      {
        denominazioneInItaliano: "Castino",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C323"
      },
      {
        denominazioneInItaliano: "Cavallerleone",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C375"
      },
      {
        denominazioneInItaliano: "Cavallermaggiore",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C376"
      },
      {
        denominazioneInItaliano: "Celle di Macra",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C441"
      },
      {
        denominazioneInItaliano: "Centallo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C466"
      },
      {
        denominazioneInItaliano: "Ceresole Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C504"
      },
      {
        denominazioneInItaliano: "Cerretto Langhe",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C530"
      },
      {
        denominazioneInItaliano: "Cervasca",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C547"
      },
      {
        denominazioneInItaliano: "Cervere",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C550"
      },
      {
        denominazioneInItaliano: "Ceva",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C589"
      },
      {
        denominazioneInItaliano: "Cherasco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C599"
      },
      {
        denominazioneInItaliano: "Chiusa di Pesio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C653"
      },
      {
        denominazioneInItaliano: "Cigliè",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C681"
      },
      {
        denominazioneInItaliano: "Cissone",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C738"
      },
      {
        denominazioneInItaliano: "Clavesana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "C792"
      },
      {
        denominazioneInItaliano: "Corneliano d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D022"
      },
      {
        denominazioneInItaliano: "Cortemilia",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D062"
      },
      {
        denominazioneInItaliano: "Cossano Belbo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D093"
      },
      {
        denominazioneInItaliano: "Costigliole Saluzzo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D120"
      },
      {
        denominazioneInItaliano: "Cravanzana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D133"
      },
      {
        denominazioneInItaliano: "Crissolo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D172"
      },
      {
        denominazioneInItaliano: "Cuneo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D205"
      },
      {
        denominazioneInItaliano: "Demonte",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D271"
      },
      {
        denominazioneInItaliano: "Diano d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D291"
      },
      {
        denominazioneInItaliano: "Dogliani",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D314"
      },
      {
        denominazioneInItaliano: "Dronero",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D372"
      },
      {
        denominazioneInItaliano: "Elva",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D401"
      },
      {
        denominazioneInItaliano: "Entracque",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D410"
      },
      {
        denominazioneInItaliano: "Envie",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D412"
      },
      {
        denominazioneInItaliano: "Farigliano",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D499"
      },
      {
        denominazioneInItaliano: "Faule",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D511"
      },
      {
        denominazioneInItaliano: "Feisoglio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D523"
      },
      {
        denominazioneInItaliano: "Fossano",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D742"
      },
      {
        denominazioneInItaliano: "Frabosa Soprana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D751"
      },
      {
        denominazioneInItaliano: "Frabosa Sottana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D752"
      },
      {
        denominazioneInItaliano: "Frassino",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D782"
      },
      {
        denominazioneInItaliano: "Gaiola",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D856"
      },
      {
        denominazioneInItaliano: "Gambasca",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D894"
      },
      {
        denominazioneInItaliano: "Garessio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D920"
      },
      {
        denominazioneInItaliano: "Genola",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "D967"
      },
      {
        denominazioneInItaliano: "Gorzegno",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E111"
      },
      {
        denominazioneInItaliano: "Gottasecca",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E115"
      },
      {
        denominazioneInItaliano: "Govone",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E118"
      },
      {
        denominazioneInItaliano: "Grinzane Cavour",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E182"
      },
      {
        denominazioneInItaliano: "Guarene",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E251"
      },
      {
        denominazioneInItaliano: "Igliano",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E282"
      },
      {
        denominazioneInItaliano: "Isasca",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E327"
      },
      {
        denominazioneInItaliano: "Lagnasco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E406"
      },
      {
        denominazioneInItaliano: "La Morra",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E430"
      },
      {
        denominazioneInItaliano: "Lequio Berria",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E540"
      },
      {
        denominazioneInItaliano: "Lequio Tanaro",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E539"
      },
      {
        denominazioneInItaliano: "Lesegno",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E546"
      },
      {
        denominazioneInItaliano: "Levice",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E564"
      },
      {
        denominazioneInItaliano: "Limone Piemonte",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E597"
      },
      {
        denominazioneInItaliano: "Lisio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E615"
      },
      {
        denominazioneInItaliano: "Macra",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E789"
      },
      {
        denominazioneInItaliano: "Magliano Alfieri",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E809"
      },
      {
        denominazioneInItaliano: "Magliano Alpi",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E808"
      },
      {
        denominazioneInItaliano: "Mango",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E887"
      },
      {
        denominazioneInItaliano: "Manta",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E894"
      },
      {
        denominazioneInItaliano: "Marene",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E939"
      },
      {
        denominazioneInItaliano: "Margarita",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E945"
      },
      {
        denominazioneInItaliano: "Marmora",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E963"
      },
      {
        denominazioneInItaliano: "Marsaglia",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E973"
      },
      {
        denominazioneInItaliano: "Martiniana Po",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "E988"
      },
      {
        denominazioneInItaliano: "Melle",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F114"
      },
      {
        denominazioneInItaliano: "Moiola",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F279"
      },
      {
        denominazioneInItaliano: "Mombarcaro",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F309"
      },
      {
        denominazioneInItaliano: "Mombasiglio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F312"
      },
      {
        denominazioneInItaliano: "Monastero di Vasco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F326"
      },
      {
        denominazioneInItaliano: "Monasterolo Casotto",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F329"
      },
      {
        denominazioneInItaliano: "Monasterolo di Savigliano",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F330"
      },
      {
        denominazioneInItaliano: "Monchiero",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F338"
      },
      {
        denominazioneInItaliano: "Mondovì",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F351"
      },
      {
        denominazioneInItaliano: "Monesiglio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F355"
      },
      {
        denominazioneInItaliano: "Monforte d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F358"
      },
      {
        denominazioneInItaliano: "Montà",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F385"
      },
      {
        denominazioneInItaliano: "Montaldo di Mondovì",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F405"
      },
      {
        denominazioneInItaliano: "Montaldo Roero",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F408"
      },
      {
        denominazioneInItaliano: "Montanera",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F424"
      },
      {
        denominazioneInItaliano: "Montelupo Albese",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F550"
      },
      {
        denominazioneInItaliano: "Montemale di Cuneo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F558"
      },
      {
        denominazioneInItaliano: "Monterosso Grana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F608"
      },
      {
        denominazioneInItaliano: "Monteu Roero",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F654"
      },
      {
        denominazioneInItaliano: "Montezemolo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F666"
      },
      {
        denominazioneInItaliano: "Monticello d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F669"
      },
      {
        denominazioneInItaliano: "Moretta",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F723"
      },
      {
        denominazioneInItaliano: "Morozzo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F743"
      },
      {
        denominazioneInItaliano: "Murazzano",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F809"
      },
      {
        denominazioneInItaliano: "Murello",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F811"
      },
      {
        denominazioneInItaliano: "Narzole",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F846"
      },
      {
        denominazioneInItaliano: "Neive",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F863"
      },
      {
        denominazioneInItaliano: "Neviglie",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F883"
      },
      {
        denominazioneInItaliano: "Niella Belbo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F894"
      },
      {
        denominazioneInItaliano: "Niella Tanaro",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F895"
      },
      {
        denominazioneInItaliano: "Novello",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F961"
      },
      {
        denominazioneInItaliano: "Nucetto",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "F972"
      },
      {
        denominazioneInItaliano: "Oncino",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G066"
      },
      {
        denominazioneInItaliano: "Ormea",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G114"
      },
      {
        denominazioneInItaliano: "Ostana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G183"
      },
      {
        denominazioneInItaliano: "Paesana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G228"
      },
      {
        denominazioneInItaliano: "Pagno",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G240"
      },
      {
        denominazioneInItaliano: "Pamparato",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G302"
      },
      {
        denominazioneInItaliano: "Paroldo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G339"
      },
      {
        denominazioneInItaliano: "Perletto",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G457"
      },
      {
        denominazioneInItaliano: "Perlo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G458"
      },
      {
        denominazioneInItaliano: "Peveragno",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G526"
      },
      {
        denominazioneInItaliano: "Pezzolo Valle Uzzone",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G532"
      },
      {
        denominazioneInItaliano: "Pianfei",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G561"
      },
      {
        denominazioneInItaliano: "Piasco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G575"
      },
      {
        denominazioneInItaliano: "Pietraporzio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G625"
      },
      {
        denominazioneInItaliano: "Piobesi d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G683"
      },
      {
        denominazioneInItaliano: "Piozzo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G697"
      },
      {
        denominazioneInItaliano: "Pocapaglia",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G742"
      },
      {
        denominazioneInItaliano: "Polonghera",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G800"
      },
      {
        denominazioneInItaliano: "Pontechianale",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G837"
      },
      {
        denominazioneInItaliano: "Pradleves",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "G970"
      },
      {
        denominazioneInItaliano: "Prazzo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H011"
      },
      {
        denominazioneInItaliano: "Priero",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H059"
      },
      {
        denominazioneInItaliano: "Priocca",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H068"
      },
      {
        denominazioneInItaliano: "Priola",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H069"
      },
      {
        denominazioneInItaliano: "Prunetto",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H085"
      },
      {
        denominazioneInItaliano: "Racconigi",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H150"
      },
      {
        denominazioneInItaliano: "Revello",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H247"
      },
      {
        denominazioneInItaliano: "Rifreddo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H285"
      },
      {
        denominazioneInItaliano: "Rittana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H326"
      },
      {
        denominazioneInItaliano: "Roaschia",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H362"
      },
      {
        denominazioneInItaliano: "Roascio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H363"
      },
      {
        denominazioneInItaliano: "Robilante",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H377"
      },
      {
        denominazioneInItaliano: "Roburent",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H378"
      },
      {
        denominazioneInItaliano: "Roccabruna",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H385"
      },
      {
        denominazioneInItaliano: "Rocca Cigliè",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H391"
      },
      {
        denominazioneInItaliano: "Rocca de' Baldi",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H395"
      },
      {
        denominazioneInItaliano: "Roccaforte Mondovì",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H407"
      },
      {
        denominazioneInItaliano: "Roccasparvera",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H447"
      },
      {
        denominazioneInItaliano: "Roccavione",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H453"
      },
      {
        denominazioneInItaliano: "Rocchetta Belbo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H462"
      },
      {
        denominazioneInItaliano: "Roddi",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H472"
      },
      {
        denominazioneInItaliano: "Roddino",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H473"
      },
      {
        denominazioneInItaliano: "Rodello",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H474"
      },
      {
        denominazioneInItaliano: "Rossana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H578"
      },
      {
        denominazioneInItaliano: "Ruffia",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H633"
      },
      {
        denominazioneInItaliano: "Sale delle Langhe",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H695"
      },
      {
        denominazioneInItaliano: "Sale San Giovanni",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H704"
      },
      {
        denominazioneInItaliano: "Saliceto",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H710"
      },
      {
        denominazioneInItaliano: "Salmour",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H716"
      },
      {
        denominazioneInItaliano: "Saluzzo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H727"
      },
      {
        denominazioneInItaliano: "Sambuco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H746"
      },
      {
        denominazioneInItaliano: "Sampeyre",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H755"
      },
      {
        denominazioneInItaliano: "San Benedetto Belbo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H770"
      },
      {
        denominazioneInItaliano: "San Damiano Macra",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H812"
      },
      {
        denominazioneInItaliano: "Sanfrè",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H851"
      },
      {
        denominazioneInItaliano: "Sanfront",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "H852"
      },
      {
        denominazioneInItaliano: "San Michele Mondovì",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I037"
      },
      {
        denominazioneInItaliano: "Sant'Albano Stura",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I210"
      },
      {
        denominazioneInItaliano: "Santa Vittoria d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I316"
      },
      {
        denominazioneInItaliano: "Santo Stefano Belbo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I367"
      },
      {
        denominazioneInItaliano: "Santo Stefano Roero",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I372"
      },
      {
        denominazioneInItaliano: "Savigliano",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I470"
      },
      {
        denominazioneInItaliano: "Scagnello",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I484"
      },
      {
        denominazioneInItaliano: "Scarnafigi",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I512"
      },
      {
        denominazioneInItaliano: "Serralunga d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I646"
      },
      {
        denominazioneInItaliano: "Serravalle Langhe",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I659"
      },
      {
        denominazioneInItaliano: "Sinio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I750"
      },
      {
        denominazioneInItaliano: "Somano",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I817"
      },
      {
        denominazioneInItaliano: "Sommariva del Bosco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I822"
      },
      {
        denominazioneInItaliano: "Sommariva Perno",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I823"
      },
      {
        denominazioneInItaliano: "Stroppo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "I985"
      },
      {
        denominazioneInItaliano: "Tarantasca",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L048"
      },
      {
        denominazioneInItaliano: "Torre Bormida",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L252"
      },
      {
        denominazioneInItaliano: "Torre Mondovì",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L241"
      },
      {
        denominazioneInItaliano: "Torre San Giorgio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L278"
      },
      {
        denominazioneInItaliano: "Torresina",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L281"
      },
      {
        denominazioneInItaliano: "Treiso",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L367"
      },
      {
        denominazioneInItaliano: "Trezzo Tinella",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L410"
      },
      {
        denominazioneInItaliano: "Trinità",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L427"
      },
      {
        denominazioneInItaliano: "Valdieri",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L558"
      },
      {
        denominazioneInItaliano: "Valgrana",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L580"
      },
      {
        denominazioneInItaliano: "Valloriate",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L631"
      },
      {
        denominazioneInItaliano: "Venasca",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L729"
      },
      {
        denominazioneInItaliano: "Verduno",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L758"
      },
      {
        denominazioneInItaliano: "Vernante",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L771"
      },
      {
        denominazioneInItaliano: "Verzuolo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L804"
      },
      {
        denominazioneInItaliano: "Vezza d'Alba",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L817"
      },
      {
        denominazioneInItaliano: "Vicoforte",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L841"
      },
      {
        denominazioneInItaliano: "Vignolo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L888"
      },
      {
        denominazioneInItaliano: "Villafalletto",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L942"
      },
      {
        denominazioneInItaliano: "Villanova Mondovì",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L974"
      },
      {
        denominazioneInItaliano: "Villanova Solaro",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "L990"
      },
      {
        denominazioneInItaliano: "Villar San Costanzo",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "M015"
      },
      {
        denominazioneInItaliano: "Vinadio",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "M055"
      },
      {
        denominazioneInItaliano: "Viola",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "M063"
      },
      {
        denominazioneInItaliano: "Vottignasco",
        regione: "Piemonte",
        provincia: "Cuneo",
        codiceCatastale: "M136"
      },
      {
        denominazioneInItaliano: "Agliano Terme",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A072"
      },
      {
        denominazioneInItaliano: "Albugnano",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A173"
      },
      {
        denominazioneInItaliano: "Antignano",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A312"
      },
      {
        denominazioneInItaliano: "Aramengo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A352"
      },
      {
        denominazioneInItaliano: "Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A479"
      },
      {
        denominazioneInItaliano: "Azzano d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A527"
      },
      {
        denominazioneInItaliano: "Baldichieri d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A588"
      },
      {
        denominazioneInItaliano: "Belveglio",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A770"
      },
      {
        denominazioneInItaliano: "Berzano di San Pietro",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A812"
      },
      {
        denominazioneInItaliano: "Bruno",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B221"
      },
      {
        denominazioneInItaliano: "Bubbio",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B236"
      },
      {
        denominazioneInItaliano: "Buttigliera d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B306"
      },
      {
        denominazioneInItaliano: "Calamandrana",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B376"
      },
      {
        denominazioneInItaliano: "Calliano Monferrato",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B418"
      },
      {
        denominazioneInItaliano: "Calosso",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B425"
      },
      {
        denominazioneInItaliano: "Camerano Casasco",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B469"
      },
      {
        denominazioneInItaliano: "Canelli",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B594"
      },
      {
        denominazioneInItaliano: "Cantarana",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B633"
      },
      {
        denominazioneInItaliano: "Capriglio",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B707"
      },
      {
        denominazioneInItaliano: "Casorzo Monferrato",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "B991"
      },
      {
        denominazioneInItaliano: "Cassinasco",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C022"
      },
      {
        denominazioneInItaliano: "Castagnole delle Lanze",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C049"
      },
      {
        denominazioneInItaliano: "Castagnole Monferrato",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C047"
      },
      {
        denominazioneInItaliano: "Castel Boglione",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C064"
      },
      {
        denominazioneInItaliano: "Castell'Alfero",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C127"
      },
      {
        denominazioneInItaliano: "Castellero",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C154"
      },
      {
        denominazioneInItaliano: "Castelletto Molina",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C161"
      },
      {
        denominazioneInItaliano: "Castello di Annone",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "A300"
      },
      {
        denominazioneInItaliano: "Castelnuovo Belbo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C226"
      },
      {
        denominazioneInItaliano: "Castelnuovo Calcea",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C230"
      },
      {
        denominazioneInItaliano: "Castelnuovo Don Bosco",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C232"
      },
      {
        denominazioneInItaliano: "Castel Rocchero",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C253"
      },
      {
        denominazioneInItaliano: "Cellarengo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C438"
      },
      {
        denominazioneInItaliano: "Celle Enomondo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C440"
      },
      {
        denominazioneInItaliano: "Cerreto d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C528"
      },
      {
        denominazioneInItaliano: "Cerro Tanaro",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C533"
      },
      {
        denominazioneInItaliano: "Cessole",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C583"
      },
      {
        denominazioneInItaliano: "Chiusano d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C658"
      },
      {
        denominazioneInItaliano: "Cinaglio",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C701"
      },
      {
        denominazioneInItaliano: "Cisterna d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C739"
      },
      {
        denominazioneInItaliano: "Coazzolo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C804"
      },
      {
        denominazioneInItaliano: "Cocconato",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "C807"
      },
      {
        denominazioneInItaliano: "Corsione",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D046"
      },
      {
        denominazioneInItaliano: "Cortandone",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D050"
      },
      {
        denominazioneInItaliano: "Cortanze",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D051"
      },
      {
        denominazioneInItaliano: "Cortazzone",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D052"
      },
      {
        denominazioneInItaliano: "Cortiglione",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D072"
      },
      {
        denominazioneInItaliano: "Cossombrato",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D101"
      },
      {
        denominazioneInItaliano: "Costigliole d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D119"
      },
      {
        denominazioneInItaliano: "Cunico",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D207"
      },
      {
        denominazioneInItaliano: "Dusino San Michele",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D388"
      },
      {
        denominazioneInItaliano: "Ferrere",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D554"
      },
      {
        denominazioneInItaliano: "Fontanile",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D678"
      },
      {
        denominazioneInItaliano: "Frinco",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "D802"
      },
      {
        denominazioneInItaliano: "Grana Monferrato",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "E134"
      },
      {
        denominazioneInItaliano: "Grazzano Badoglio",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "E159"
      },
      {
        denominazioneInItaliano: "Incisa Scapaccino",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "E295"
      },
      {
        denominazioneInItaliano: "Isola d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "E338"
      },
      {
        denominazioneInItaliano: "Loazzolo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "E633"
      },
      {
        denominazioneInItaliano: "Maranzana",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "E917"
      },
      {
        denominazioneInItaliano: "Maretto",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "E944"
      },
      {
        denominazioneInItaliano: "Moasca",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F254"
      },
      {
        denominazioneInItaliano: "Mombaldone",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F308"
      },
      {
        denominazioneInItaliano: "Mombaruzzo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F311"
      },
      {
        denominazioneInItaliano: "Mombercelli",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F316"
      },
      {
        denominazioneInItaliano: "Monale",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F323"
      },
      {
        denominazioneInItaliano: "Monastero Bormida",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F325"
      },
      {
        denominazioneInItaliano: "Moncalvo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F336"
      },
      {
        denominazioneInItaliano: "Moncucco Torinese",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F343"
      },
      {
        denominazioneInItaliano: "Mongardino",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F361"
      },
      {
        denominazioneInItaliano: "Montabone",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F386"
      },
      {
        denominazioneInItaliano: "Montafia",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F390"
      },
      {
        denominazioneInItaliano: "Montaldo Scarampi",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F409"
      },
      {
        denominazioneInItaliano: "Montechiaro d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F468"
      },
      {
        denominazioneInItaliano: "Montegrosso d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F527"
      },
      {
        denominazioneInItaliano: "Montemagno",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F556"
      },
      {
        denominazioneInItaliano: "Nizza Monferrato",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "F902"
      },
      {
        denominazioneInItaliano: "Olmo Gentile",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "G048"
      },
      {
        denominazioneInItaliano: "Passerano Marmorito",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "G358"
      },
      {
        denominazioneInItaliano: "Penango",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "G430"
      },
      {
        denominazioneInItaliano: "Piea",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "G593"
      },
      {
        denominazioneInItaliano: "Pino d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "G676"
      },
      {
        denominazioneInItaliano: "Piovà Massaia",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "G692"
      },
      {
        denominazioneInItaliano: "Portacomaro",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "G894"
      },
      {
        denominazioneInItaliano: "Quaranti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H102"
      },
      {
        denominazioneInItaliano: "Refrancore",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H219"
      },
      {
        denominazioneInItaliano: "Revigliasco d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H250"
      },
      {
        denominazioneInItaliano: "Roatto",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H366"
      },
      {
        denominazioneInItaliano: "Robella",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H376"
      },
      {
        denominazioneInItaliano: "Rocca d'Arazzo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H392"
      },
      {
        denominazioneInItaliano: "Roccaverano",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H451"
      },
      {
        denominazioneInItaliano: "Rocchetta Palafea",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H466"
      },
      {
        denominazioneInItaliano: "Rocchetta Tanaro",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H468"
      },
      {
        denominazioneInItaliano: "San Damiano d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H811"
      },
      {
        denominazioneInItaliano: "San Giorgio Scarampi",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H899"
      },
      {
        denominazioneInItaliano: "San Martino Alfieri",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "H987"
      },
      {
        denominazioneInItaliano: "San Marzano Oliveto",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "I017"
      },
      {
        denominazioneInItaliano: "San Paolo Solbrito",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "I076"
      },
      {
        denominazioneInItaliano: "Scurzolengo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "I555"
      },
      {
        denominazioneInItaliano: "Serole",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "I637"
      },
      {
        denominazioneInItaliano: "Sessame",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "I678"
      },
      {
        denominazioneInItaliano: "Settime",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "I698"
      },
      {
        denominazioneInItaliano: "Soglio",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "I781"
      },
      {
        denominazioneInItaliano: "Tigliole",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L168"
      },
      {
        denominazioneInItaliano: "Tonco",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L203"
      },
      {
        denominazioneInItaliano: "Vaglio Serra",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L531"
      },
      {
        denominazioneInItaliano: "Valfenera",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L574"
      },
      {
        denominazioneInItaliano: "Vesime",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L807"
      },
      {
        denominazioneInItaliano: "Viale",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L829"
      },
      {
        denominazioneInItaliano: "Viarigi",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L834"
      },
      {
        denominazioneInItaliano: "Vigliano d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L879"
      },
      {
        denominazioneInItaliano: "Villafranca d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L945"
      },
      {
        denominazioneInItaliano: "Villanova d'Asti",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "L984"
      },
      {
        denominazioneInItaliano: "Villa San Secondo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "M019"
      },
      {
        denominazioneInItaliano: "Vinchio",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "M058"
      },
      {
        denominazioneInItaliano: "Montiglio Monferrato",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "M302"
      },
      {
        denominazioneInItaliano: "Moransengo-Tonengo",
        regione: "Piemonte",
        provincia: "Asti",
        codiceCatastale: "M434"
      },
      {
        denominazioneInItaliano: "Acqui Terme",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A052"
      },
      {
        denominazioneInItaliano: "Albera Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A146"
      },
      {
        denominazioneInItaliano: "Alessandria",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A182"
      },
      {
        denominazioneInItaliano: "Alfiano Natta",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A189"
      },
      {
        denominazioneInItaliano: "Alice Bel Colle",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A197"
      },
      {
        denominazioneInItaliano: "Altavilla Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A227"
      },
      {
        denominazioneInItaliano: "Alzano Scrivia",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A245"
      },
      {
        denominazioneInItaliano: "Arquata Scrivia",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A436"
      },
      {
        denominazioneInItaliano: "Avolasca",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A523"
      },
      {
        denominazioneInItaliano: "Balzola",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A605"
      },
      {
        denominazioneInItaliano: "Basaluzzo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A689"
      },
      {
        denominazioneInItaliano: "Bassignana",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A708"
      },
      {
        denominazioneInItaliano: "Belforte Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A738"
      },
      {
        denominazioneInItaliano: "Bergamasco",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A793"
      },
      {
        denominazioneInItaliano: "Berzano di Tortona",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A813"
      },
      {
        denominazioneInItaliano: "Bistagno",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A889"
      },
      {
        denominazioneInItaliano: "Borghetto di Borbera",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "A998"
      },
      {
        denominazioneInItaliano: "Borgoratto Alessandrino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B029"
      },
      {
        denominazioneInItaliano: "Borgo San Martino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B037"
      },
      {
        denominazioneInItaliano: "Bosco Marengo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B071"
      },
      {
        denominazioneInItaliano: "Bosio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B080"
      },
      {
        denominazioneInItaliano: "Bozzole",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B109"
      },
      {
        denominazioneInItaliano: "Brignano-Frascata",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B179"
      },
      {
        denominazioneInItaliano: "Cabella Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B311"
      },
      {
        denominazioneInItaliano: "Camagna Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B453"
      },
      {
        denominazioneInItaliano: "Camino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B482"
      },
      {
        denominazioneInItaliano: "Cantalupo Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B629"
      },
      {
        denominazioneInItaliano: "Capriata d'Orba",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B701"
      },
      {
        denominazioneInItaliano: "Carbonara Scrivia",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B736"
      },
      {
        denominazioneInItaliano: "Carentino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B765"
      },
      {
        denominazioneInItaliano: "Carezzano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B769"
      },
      {
        denominazioneInItaliano: "Carpeneto",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B818"
      },
      {
        denominazioneInItaliano: "Carrega Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B836"
      },
      {
        denominazioneInItaliano: "Carrosio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B840"
      },
      {
        denominazioneInItaliano: "Cartosio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B847"
      },
      {
        denominazioneInItaliano: "Casal Cermelli",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B870"
      },
      {
        denominazioneInItaliano: "Casaleggio Boiro",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B882"
      },
      {
        denominazioneInItaliano: "Casale Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B885"
      },
      {
        denominazioneInItaliano: "Casalnoceto",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B902"
      },
      {
        denominazioneInItaliano: "Casasco",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "B941"
      },
      {
        denominazioneInItaliano: "Cassine",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C027"
      },
      {
        denominazioneInItaliano: "Cassinelle",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C030"
      },
      {
        denominazioneInItaliano: "Castellania Coppi",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C137"
      },
      {
        denominazioneInItaliano: "Castellar Guidobono",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C142"
      },
      {
        denominazioneInItaliano: "Castellazzo Bormida",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C148"
      },
      {
        denominazioneInItaliano: "Castelletto d'Erro",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C156"
      },
      {
        denominazioneInItaliano: "Castelletto d'Orba",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C158"
      },
      {
        denominazioneInItaliano: "Castelletto Merli",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C160"
      },
      {
        denominazioneInItaliano: "Castelletto Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C162"
      },
      {
        denominazioneInItaliano: "Castelnuovo Bormida",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C229"
      },
      {
        denominazioneInItaliano: "Castelnuovo Scrivia",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C243"
      },
      {
        denominazioneInItaliano: "Castelspina",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C274"
      },
      {
        denominazioneInItaliano: "Cavatore",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C387"
      },
      {
        denominazioneInItaliano: "Cella Monte",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C432"
      },
      {
        denominazioneInItaliano: "Cereseto",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C503"
      },
      {
        denominazioneInItaliano: "Cerreto Grue",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C507"
      },
      {
        denominazioneInItaliano: "Cerrina Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C531"
      },
      {
        denominazioneInItaliano: "Coniolo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C962"
      },
      {
        denominazioneInItaliano: "Conzano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "C977"
      },
      {
        denominazioneInItaliano: "Costa Vescovato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D102"
      },
      {
        denominazioneInItaliano: "Cremolino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D149"
      },
      {
        denominazioneInItaliano: "Denice",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D272"
      },
      {
        denominazioneInItaliano: "Dernice",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D277"
      },
      {
        denominazioneInItaliano: "Fabbrica Curone",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D447"
      },
      {
        denominazioneInItaliano: "Felizzano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D528"
      },
      {
        denominazioneInItaliano: "Fraconalto",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D559"
      },
      {
        denominazioneInItaliano: "Francavilla Bisio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D759"
      },
      {
        denominazioneInItaliano: "Frascaro",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D770"
      },
      {
        denominazioneInItaliano: "Frassinello Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D777"
      },
      {
        denominazioneInItaliano: "Frassineto Po",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D780"
      },
      {
        denominazioneInItaliano: "Fresonara",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D797"
      },
      {
        denominazioneInItaliano: "Frugarolo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D813"
      },
      {
        denominazioneInItaliano: "Fubine Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D814"
      },
      {
        denominazioneInItaliano: "Gabiano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D835"
      },
      {
        denominazioneInItaliano: "Gamalero",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D890"
      },
      {
        denominazioneInItaliano: "Garbagna",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D910"
      },
      {
        denominazioneInItaliano: "Gavi",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "D944"
      },
      {
        denominazioneInItaliano: "Giarole",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "E015"
      },
      {
        denominazioneInItaliano: "Gremiasco",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "E164"
      },
      {
        denominazioneInItaliano: "Grognardo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "E188"
      },
      {
        denominazioneInItaliano: "Grondona",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "E191"
      },
      {
        denominazioneInItaliano: "Guazzora",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "E255"
      },
      {
        denominazioneInItaliano: "Isola Sant'Antonio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "E360"
      },
      {
        denominazioneInItaliano: "Lerma",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "E543"
      },
      {
        denominazioneInItaliano: "Malvicino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "E870"
      },
      {
        denominazioneInItaliano: "Masio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F015"
      },
      {
        denominazioneInItaliano: "Melazzo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F096"
      },
      {
        denominazioneInItaliano: "Merana",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F131"
      },
      {
        denominazioneInItaliano: "Mirabello Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F232"
      },
      {
        denominazioneInItaliano: "Molare",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F281"
      },
      {
        denominazioneInItaliano: "Molino dei Torti",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F293"
      },
      {
        denominazioneInItaliano: "Mombello Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F313"
      },
      {
        denominazioneInItaliano: "Momperone",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F320"
      },
      {
        denominazioneInItaliano: "Moncestino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F337"
      },
      {
        denominazioneInItaliano: "Mongiardino Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F365"
      },
      {
        denominazioneInItaliano: "Monleale",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F374"
      },
      {
        denominazioneInItaliano: "Montacuto",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F387"
      },
      {
        denominazioneInItaliano: "Montaldeo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F403"
      },
      {
        denominazioneInItaliano: "Montaldo Bormida",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F404"
      },
      {
        denominazioneInItaliano: "Montecastello",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F455"
      },
      {
        denominazioneInItaliano: "Montechiaro d'Acqui",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F469"
      },
      {
        denominazioneInItaliano: "Montegioco",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F518"
      },
      {
        denominazioneInItaliano: "Montemarzino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F562"
      },
      {
        denominazioneInItaliano: "Morano sul Po",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F707"
      },
      {
        denominazioneInItaliano: "Morbello",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F713"
      },
      {
        denominazioneInItaliano: "Mornese",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F737"
      },
      {
        denominazioneInItaliano: "Morsasco",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F751"
      },
      {
        denominazioneInItaliano: "Murisengo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F814"
      },
      {
        denominazioneInItaliano: "Novi Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F965"
      },
      {
        denominazioneInItaliano: "Occimiano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F995"
      },
      {
        denominazioneInItaliano: "Odalengo Grande",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F997"
      },
      {
        denominazioneInItaliano: "Odalengo Piccolo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "F998"
      },
      {
        denominazioneInItaliano: "Olivola",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G042"
      },
      {
        denominazioneInItaliano: "Orsara Bormida",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G124"
      },
      {
        denominazioneInItaliano: "Ottiglio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G193"
      },
      {
        denominazioneInItaliano: "Ovada",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G197"
      },
      {
        denominazioneInItaliano: "Oviglio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G199"
      },
      {
        denominazioneInItaliano: "Ozzano Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G204"
      },
      {
        denominazioneInItaliano: "Paderna",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G215"
      },
      {
        denominazioneInItaliano: "Pareto",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G334"
      },
      {
        denominazioneInItaliano: "Parodi Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G338"
      },
      {
        denominazioneInItaliano: "Pasturana",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G367"
      },
      {
        denominazioneInItaliano: "Pecetto di Valenza",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G397"
      },
      {
        denominazioneInItaliano: "Pietra Marazzi",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G619"
      },
      {
        denominazioneInItaliano: "Pomaro Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G807"
      },
      {
        denominazioneInItaliano: "Pontecurone",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G839"
      },
      {
        denominazioneInItaliano: "Pontestura",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G858"
      },
      {
        denominazioneInItaliano: "Ponti",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G861"
      },
      {
        denominazioneInItaliano: "Ponzano Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G872"
      },
      {
        denominazioneInItaliano: "Ponzone",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G877"
      },
      {
        denominazioneInItaliano: "Pozzol Groppo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G960"
      },
      {
        denominazioneInItaliano: "Pozzolo Formigaro",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G961"
      },
      {
        denominazioneInItaliano: "Prasco",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "G987"
      },
      {
        denominazioneInItaliano: "Predosa",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H021"
      },
      {
        denominazioneInItaliano: "Quargnento",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H104"
      },
      {
        denominazioneInItaliano: "Quattordio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H121"
      },
      {
        denominazioneInItaliano: "Ricaldone",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H272"
      },
      {
        denominazioneInItaliano: "Rivalta Bormida",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H334"
      },
      {
        denominazioneInItaliano: "Rivarone",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H343"
      },
      {
        denominazioneInItaliano: "Roccaforte Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H406"
      },
      {
        denominazioneInItaliano: "Rocca Grimalda",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H414"
      },
      {
        denominazioneInItaliano: "Rocchetta Ligure",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H465"
      },
      {
        denominazioneInItaliano: "Rosignano Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H569"
      },
      {
        denominazioneInItaliano: "Sala Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H677"
      },
      {
        denominazioneInItaliano: "Sale",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H694"
      },
      {
        denominazioneInItaliano: "San Cristoforo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H810"
      },
      {
        denominazioneInItaliano: "San Giorgio Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "H878"
      },
      {
        denominazioneInItaliano: "San Salvatore Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I144"
      },
      {
        denominazioneInItaliano: "San Sebastiano Curone",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I150"
      },
      {
        denominazioneInItaliano: "Sant'Agata Fossili",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I190"
      },
      {
        denominazioneInItaliano: "Sardigliano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I429"
      },
      {
        denominazioneInItaliano: "Sarezzano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I432"
      },
      {
        denominazioneInItaliano: "Serralunga di Crea",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I645"
      },
      {
        denominazioneInItaliano: "Serravalle Scrivia",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I657"
      },
      {
        denominazioneInItaliano: "Sezzadio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I711"
      },
      {
        denominazioneInItaliano: "Silvano d'Orba",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I738"
      },
      {
        denominazioneInItaliano: "Solero",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I798"
      },
      {
        denominazioneInItaliano: "Solonghello",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I808"
      },
      {
        denominazioneInItaliano: "Spigno Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I901"
      },
      {
        denominazioneInItaliano: "Spineto Scrivia",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I911"
      },
      {
        denominazioneInItaliano: "Stazzano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I941"
      },
      {
        denominazioneInItaliano: "Strevi",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "I977"
      },
      {
        denominazioneInItaliano: "Tagliolo Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L027"
      },
      {
        denominazioneInItaliano: "Tassarolo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L059"
      },
      {
        denominazioneInItaliano: "Terruggia",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L139"
      },
      {
        denominazioneInItaliano: "Terzo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L143"
      },
      {
        denominazioneInItaliano: "Ticineto",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L165"
      },
      {
        denominazioneInItaliano: "Tortona",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L304"
      },
      {
        denominazioneInItaliano: "Treville",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L403"
      },
      {
        denominazioneInItaliano: "Trisobbio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L432"
      },
      {
        denominazioneInItaliano: "Valenza",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L570"
      },
      {
        denominazioneInItaliano: "Valmacca",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L633"
      },
      {
        denominazioneInItaliano: "Vignale Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L881"
      },
      {
        denominazioneInItaliano: "Vignole Borbera",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L887"
      },
      {
        denominazioneInItaliano: "Viguzzolo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L904"
      },
      {
        denominazioneInItaliano: "Villadeati",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L931"
      },
      {
        denominazioneInItaliano: "Villalvernia",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L963"
      },
      {
        denominazioneInItaliano: "Villamiroglio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L970"
      },
      {
        denominazioneInItaliano: "Villanova Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "L972"
      },
      {
        denominazioneInItaliano: "Villaromagnano",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "M009"
      },
      {
        denominazioneInItaliano: "Visone",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "M077"
      },
      {
        denominazioneInItaliano: "Volpedo",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "M120"
      },
      {
        denominazioneInItaliano: "Volpeglino",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "M121"
      },
      {
        denominazioneInItaliano: "Voltaggio",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "M123"
      },
      {
        denominazioneInItaliano: "Cassano Spinola",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "M388"
      },
      {
        denominazioneInItaliano: "Alluvioni Piovera",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "M397"
      },
      {
        denominazioneInItaliano: "Lu e Cuccaro Monferrato",
        regione: "Piemonte",
        provincia: "Alessandria",
        codiceCatastale: "M420"
      },
      {
        denominazioneInItaliano: "Ailoche",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "A107"
      },
      {
        denominazioneInItaliano: "Andorno Micca",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "A280"
      },
      {
        denominazioneInItaliano: "Benna",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "A784"
      },
      {
        denominazioneInItaliano: "Biella",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "A859"
      },
      {
        denominazioneInItaliano: "Bioglio",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "A876"
      },
      {
        denominazioneInItaliano: "Borriana",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "B058"
      },
      {
        denominazioneInItaliano: "Brusnengo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "B229"
      },
      {
        denominazioneInItaliano: "Callabiana",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "B417"
      },
      {
        denominazioneInItaliano: "Camandona",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "B457"
      },
      {
        denominazioneInItaliano: "Camburzano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "B465"
      },
      {
        denominazioneInItaliano: "Candelo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "B586"
      },
      {
        denominazioneInItaliano: "Caprile",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "B708"
      },
      {
        denominazioneInItaliano: "Casapinta",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "B933"
      },
      {
        denominazioneInItaliano: "Castelletto Cervo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "C155"
      },
      {
        denominazioneInItaliano: "Cavaglià",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "C363"
      },
      {
        denominazioneInItaliano: "Cerrione",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "C532"
      },
      {
        denominazioneInItaliano: "Coggiola",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "C819"
      },
      {
        denominazioneInItaliano: "Cossato",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "D094"
      },
      {
        denominazioneInItaliano: "Crevacuore",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "D165"
      },
      {
        denominazioneInItaliano: "Curino",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "D219"
      },
      {
        denominazioneInItaliano: "Donato",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "D339"
      },
      {
        denominazioneInItaliano: "Dorzano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "D350"
      },
      {
        denominazioneInItaliano: "Gaglianico",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "D848"
      },
      {
        denominazioneInItaliano: "Gifflenga",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "E024"
      },
      {
        denominazioneInItaliano: "Graglia",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "E130"
      },
      {
        denominazioneInItaliano: "Magnano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "E821"
      },
      {
        denominazioneInItaliano: "Massazza",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F037"
      },
      {
        denominazioneInItaliano: "Masserano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F042"
      },
      {
        denominazioneInItaliano: "Mezzana Mortigliengo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F167"
      },
      {
        denominazioneInItaliano: "Miagliano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F189"
      },
      {
        denominazioneInItaliano: "Mongrando",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F369"
      },
      {
        denominazioneInItaliano: "Mottalciata",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F776"
      },
      {
        denominazioneInItaliano: "Muzzano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F833"
      },
      {
        denominazioneInItaliano: "Netro",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F878"
      },
      {
        denominazioneInItaliano: "Occhieppo Inferiore",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F992"
      },
      {
        denominazioneInItaliano: "Occhieppo Superiore",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "F993"
      },
      {
        denominazioneInItaliano: "Pettinengo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "G521"
      },
      {
        denominazioneInItaliano: "Piatto",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "G577"
      },
      {
        denominazioneInItaliano: "Piedicavallo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "G594"
      },
      {
        denominazioneInItaliano: "Pollone",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "G798"
      },
      {
        denominazioneInItaliano: "Ponderano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "G820"
      },
      {
        denominazioneInItaliano: "Portula",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "G927"
      },
      {
        denominazioneInItaliano: "Pralungo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "G980"
      },
      {
        denominazioneInItaliano: "Pray",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "G974"
      },
      {
        denominazioneInItaliano: "Ronco Biellese",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "H538"
      },
      {
        denominazioneInItaliano: "Roppolo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "H553"
      },
      {
        denominazioneInItaliano: "Rosazza",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "H561"
      },
      {
        denominazioneInItaliano: "Sagliano Micca",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "H662"
      },
      {
        denominazioneInItaliano: "Sala Biellese",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "H681"
      },
      {
        denominazioneInItaliano: "Salussola",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "H726"
      },
      {
        denominazioneInItaliano: "Sandigliano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "H821"
      },
      {
        denominazioneInItaliano: "Sordevolo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "I847"
      },
      {
        denominazioneInItaliano: "Sostegno",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "I868"
      },
      {
        denominazioneInItaliano: "Strona",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "I980"
      },
      {
        denominazioneInItaliano: "Tavigliano",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L075"
      },
      {
        denominazioneInItaliano: "Ternengo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L116"
      },
      {
        denominazioneInItaliano: "Tollegno",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L193"
      },
      {
        denominazioneInItaliano: "Torrazzo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L239"
      },
      {
        denominazioneInItaliano: "Valdengo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L556"
      },
      {
        denominazioneInItaliano: "Vallanzengo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L586"
      },
      {
        denominazioneInItaliano: "Valle San Nicolao",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L620"
      },
      {
        denominazioneInItaliano: "Veglio",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L712"
      },
      {
        denominazioneInItaliano: "Verrone",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L785"
      },
      {
        denominazioneInItaliano: "Vigliano Biellese",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L880"
      },
      {
        denominazioneInItaliano: "Villa del Bosco",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L933"
      },
      {
        denominazioneInItaliano: "Villanova Biellese",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "L978"
      },
      {
        denominazioneInItaliano: "Viverone",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "M098"
      },
      {
        denominazioneInItaliano: "Zimone",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "M179"
      },
      {
        denominazioneInItaliano: "Zubiena",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "M196"
      },
      {
        denominazioneInItaliano: "Zumaglia",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "M201"
      },
      {
        denominazioneInItaliano: "Lessona",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "M371"
      },
      {
        denominazioneInItaliano: "Campiglia Cervo",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "M373"
      },
      {
        denominazioneInItaliano: "Quaregna Cerreto",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "M414"
      },
      {
        denominazioneInItaliano: "Valdilana",
        regione: "Piemonte",
        provincia: "Biella",
        codiceCatastale: "M417"
      },
      {
        denominazioneInItaliano: "Antrona Schieranco",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A317"
      },
      {
        denominazioneInItaliano: "Anzola d'Ossola",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A325"
      },
      {
        denominazioneInItaliano: "Arizzano",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A409"
      },
      {
        denominazioneInItaliano: "Arola",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A427"
      },
      {
        denominazioneInItaliano: "Aurano",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A497"
      },
      {
        denominazioneInItaliano: "Baceno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A534"
      },
      {
        denominazioneInItaliano: "Bannio Anzino",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A610"
      },
      {
        denominazioneInItaliano: "Baveno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A725"
      },
      {
        denominazioneInItaliano: "Bee",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A733"
      },
      {
        denominazioneInItaliano: "Belgirate",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A742"
      },
      {
        denominazioneInItaliano: "Beura-Cardezza",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A834"
      },
      {
        denominazioneInItaliano: "Bognanco",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "A925"
      },
      {
        denominazioneInItaliano: "Brovello-Carpugnino",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "B207"
      },
      {
        denominazioneInItaliano: "Calasca-Castiglione",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "B380"
      },
      {
        denominazioneInItaliano: "Cambiasca",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "B463"
      },
      {
        denominazioneInItaliano: "Cannero Riviera",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "B610"
      },
      {
        denominazioneInItaliano: "Cannobio",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "B615"
      },
      {
        denominazioneInItaliano: "Caprezzo",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "B694"
      },
      {
        denominazioneInItaliano: "Casale Corte Cerro",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "B876"
      },
      {
        denominazioneInItaliano: "Ceppo Morelli",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "C478"
      },
      {
        denominazioneInItaliano: "Cesara",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "C567"
      },
      {
        denominazioneInItaliano: "Cossogno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "D099"
      },
      {
        denominazioneInItaliano: "Craveggia",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "D134"
      },
      {
        denominazioneInItaliano: "Crevoladossola",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "D168"
      },
      {
        denominazioneInItaliano: "Crodo",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "D177"
      },
      {
        denominazioneInItaliano: "Domodossola",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "D332"
      },
      {
        denominazioneInItaliano: "Druogno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "D374"
      },
      {
        denominazioneInItaliano: "Formazza",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "D706"
      },
      {
        denominazioneInItaliano: "Germagno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "D984"
      },
      {
        denominazioneInItaliano: "Ghiffa",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E003"
      },
      {
        denominazioneInItaliano: "Gignese",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E028"
      },
      {
        denominazioneInItaliano: "Gravellona Toce",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E153"
      },
      {
        denominazioneInItaliano: "Gurro",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E269"
      },
      {
        denominazioneInItaliano: "Intragna",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E304"
      },
      {
        denominazioneInItaliano: "Loreglia",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E685"
      },
      {
        denominazioneInItaliano: "Macugnaga",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E790"
      },
      {
        denominazioneInItaliano: "Madonna del Sasso",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E795"
      },
      {
        denominazioneInItaliano: "Malesco",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "E853"
      },
      {
        denominazioneInItaliano: "Masera",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "F010"
      },
      {
        denominazioneInItaliano: "Massiola",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "F048"
      },
      {
        denominazioneInItaliano: "Mergozzo",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "F146"
      },
      {
        denominazioneInItaliano: "Miazzina",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "F192"
      },
      {
        denominazioneInItaliano: "Montecrestese",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "F483"
      },
      {
        denominazioneInItaliano: "Montescheno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "F639"
      },
      {
        denominazioneInItaliano: "Nonio",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "F932"
      },
      {
        denominazioneInItaliano: "Oggebbio",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "G007"
      },
      {
        denominazioneInItaliano: "Omegna",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "G062"
      },
      {
        denominazioneInItaliano: "Ornavasso",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "G117"
      },
      {
        denominazioneInItaliano: "Pallanzeno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "G280"
      },
      {
        denominazioneInItaliano: "Piedimulera",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "G600"
      },
      {
        denominazioneInItaliano: "Pieve Vergonte",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "G658"
      },
      {
        denominazioneInItaliano: "Premeno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "H030"
      },
      {
        denominazioneInItaliano: "Premia",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "H033"
      },
      {
        denominazioneInItaliano: "Premosello-Chiovenda",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "H037"
      },
      {
        denominazioneInItaliano: "Quarna Sopra",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "H106"
      },
      {
        denominazioneInItaliano: "Quarna Sotto",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "H107"
      },
      {
        denominazioneInItaliano: "Re",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "H203"
      },
      {
        denominazioneInItaliano: "San Bernardino Verbano",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "H777"
      },
      {
        denominazioneInItaliano: "Santa Maria Maggiore",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "I249"
      },
      {
        denominazioneInItaliano: "Stresa",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "I976"
      },
      {
        denominazioneInItaliano: "Toceno",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L187"
      },
      {
        denominazioneInItaliano: "Trarego Viggiona",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L333"
      },
      {
        denominazioneInItaliano: "Trasquera",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L336"
      },
      {
        denominazioneInItaliano: "Trontano",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L450"
      },
      {
        denominazioneInItaliano: "Valstrona",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L651"
      },
      {
        denominazioneInItaliano: "Vanzone con San Carlo",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L666"
      },
      {
        denominazioneInItaliano: "Varzo",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L691"
      },
      {
        denominazioneInItaliano: "Verbania",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L746"
      },
      {
        denominazioneInItaliano: "Vignone",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L889"
      },
      {
        denominazioneInItaliano: "Villadossola",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "L906"
      },
      {
        denominazioneInItaliano: "Villette",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "M042"
      },
      {
        denominazioneInItaliano: "Vogogna",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "M111"
      },
      {
        denominazioneInItaliano: "Borgomezzavalle",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "M370"
      },
      {
        denominazioneInItaliano: "Valle Cannobina",
        regione: "Piemonte",
        provincia: "Verbano-Cusio-Ossola",
        codiceCatastale: "M404"
      },
      {
        denominazioneInItaliano: "Allein",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A205"
      },
      {
        denominazioneInItaliano: "Antey-Saint-André",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A305"
      },
      {
        denominazioneInItaliano: "Aosta",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A326"
      },
      {
        denominazioneInItaliano: "Arnad",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A424"
      },
      {
        denominazioneInItaliano: "Arvier",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A452"
      },
      {
        denominazioneInItaliano: "Avise",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A521"
      },
      {
        denominazioneInItaliano: "Ayas",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A094"
      },
      {
        denominazioneInItaliano: "Aymavilles",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A108"
      },
      {
        denominazioneInItaliano: "Bard",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A643"
      },
      {
        denominazioneInItaliano: "Bionaz",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A877"
      },
      {
        denominazioneInItaliano: "Brissogne",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "B192"
      },
      {
        denominazioneInItaliano: "Brusson",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "B230"
      },
      {
        denominazioneInItaliano: "Challand-Saint-Anselme",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "C593"
      },
      {
        denominazioneInItaliano: "Challand-Saint-Victor",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "C594"
      },
      {
        denominazioneInItaliano: "Chambave",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "C595"
      },
      {
        denominazioneInItaliano: "Chamois",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "B491"
      },
      {
        denominazioneInItaliano: "Champdepraz",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "C596"
      },
      {
        denominazioneInItaliano: "Champorcher",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "B540"
      },
      {
        denominazioneInItaliano: "Charvensod",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "C598"
      },
      {
        denominazioneInItaliano: "Châtillon",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "C294"
      },
      {
        denominazioneInItaliano: "Cogne",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "C821"
      },
      {
        denominazioneInItaliano: "Courmayeur",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "D012"
      },
      {
        denominazioneInItaliano: "Donnas",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "D338"
      },
      {
        denominazioneInItaliano: "Doues",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "D356"
      },
      {
        denominazioneInItaliano: "Emarèse",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "D402"
      },
      {
        denominazioneInItaliano: "Etroubles",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "D444"
      },
      {
        denominazioneInItaliano: "Fénis",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "D537"
      },
      {
        denominazioneInItaliano: "Fontainemore",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "D666"
      },
      {
        denominazioneInItaliano: "Gaby",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "D839"
      },
      {
        denominazioneInItaliano: "Gignod",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E029"
      },
      {
        denominazioneInItaliano: "Gressan",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E165"
      },
      {
        denominazioneInItaliano: "Gressoney-La-Trinité",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E167"
      },
      {
        denominazioneInItaliano: "Gressoney-Saint-Jean",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E168"
      },
      {
        denominazioneInItaliano: "Hône",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E273"
      },
      {
        denominazioneInItaliano: "Introd",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E306"
      },
      {
        denominazioneInItaliano: "Issime",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E369"
      },
      {
        denominazioneInItaliano: "Issogne",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E371"
      },
      {
        denominazioneInItaliano: "Jovençan",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E391"
      },
      {
        denominazioneInItaliano: "La Magdeleine",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "A308"
      },
      {
        denominazioneInItaliano: "La Salle",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E458"
      },
      {
        denominazioneInItaliano: "La Thuile",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E470"
      },
      {
        denominazioneInItaliano: "Lillianes",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "E587"
      },
      {
        denominazioneInItaliano: "Montjovet",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "F367"
      },
      {
        denominazioneInItaliano: "Morgex",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "F726"
      },
      {
        denominazioneInItaliano: "Nus",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "F987"
      },
      {
        denominazioneInItaliano: "Ollomont",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "G045"
      },
      {
        denominazioneInItaliano: "Oyace",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "G012"
      },
      {
        denominazioneInItaliano: "Perloz",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "G459"
      },
      {
        denominazioneInItaliano: "Pollein",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "G794"
      },
      {
        denominazioneInItaliano: "Pontboset",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "G545"
      },
      {
        denominazioneInItaliano: "Pontey",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "G860"
      },
      {
        denominazioneInItaliano: "Pont-Saint-Martin",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "G854"
      },
      {
        denominazioneInItaliano: "Pré-Saint-Didier",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H042"
      },
      {
        denominazioneInItaliano: "Quart",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H110"
      },
      {
        denominazioneInItaliano: "Rhêmes-Notre-Dame",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H262"
      },
      {
        denominazioneInItaliano: "Rhêmes-Saint-Georges",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H263"
      },
      {
        denominazioneInItaliano: "Roisan",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H497"
      },
      {
        denominazioneInItaliano: "Saint-Christophe",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H669"
      },
      {
        denominazioneInItaliano: "Saint-Denis",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H670"
      },
      {
        denominazioneInItaliano: "Saint-Marcel",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H671"
      },
      {
        denominazioneInItaliano: "Saint-Nicolas",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H672"
      },
      {
        denominazioneInItaliano: "Saint-Oyen",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H673"
      },
      {
        denominazioneInItaliano: "Saint-Pierre",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H674"
      },
      {
        denominazioneInItaliano: "Saint-Rhémy-en-Bosses",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H675"
      },
      {
        denominazioneInItaliano: "Saint-Vincent",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "H676"
      },
      {
        denominazioneInItaliano: "Sarre",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "I442"
      },
      {
        denominazioneInItaliano: "Torgnon",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "L217"
      },
      {
        denominazioneInItaliano: "Valgrisenche",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "L582"
      },
      {
        denominazioneInItaliano: "Valpelline",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "L643"
      },
      {
        denominazioneInItaliano: "Valsavarenche",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "L647"
      },
      {
        denominazioneInItaliano: "Valtournenche",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "L654"
      },
      {
        denominazioneInItaliano: "Verrayes",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "L783"
      },
      {
        denominazioneInItaliano: "Verrès",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "C282"
      },
      {
        denominazioneInItaliano: "Villeneuve",
        regione: "Valle d'Aosta\/Vallée d'Aoste",
        provincia: "Valle d'Aosta\/Vallée d'Aoste",
        codiceCatastale: "L981"
      },
      {
        denominazioneInItaliano: "Agra",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A085"
      },
      {
        denominazioneInItaliano: "Albizzate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A167"
      },
      {
        denominazioneInItaliano: "Angera",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A290"
      },
      {
        denominazioneInItaliano: "Arcisate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A371"
      },
      {
        denominazioneInItaliano: "Arsago Seprio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A441"
      },
      {
        denominazioneInItaliano: "Azzate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A531"
      },
      {
        denominazioneInItaliano: "Azzio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A532"
      },
      {
        denominazioneInItaliano: "Barasso",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A619"
      },
      {
        denominazioneInItaliano: "Bedero Valcuvia",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A728"
      },
      {
        denominazioneInItaliano: "Besano",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A819"
      },
      {
        denominazioneInItaliano: "Besnate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A825"
      },
      {
        denominazioneInItaliano: "Besozzo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A826"
      },
      {
        denominazioneInItaliano: "Biandronno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A845"
      },
      {
        denominazioneInItaliano: "Bisuschio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A891"
      },
      {
        denominazioneInItaliano: "Bodio Lomnago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A918"
      },
      {
        denominazioneInItaliano: "Brebbia",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B126"
      },
      {
        denominazioneInItaliano: "Brenta",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B150"
      },
      {
        denominazioneInItaliano: "Brezzo di Bedero",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B166"
      },
      {
        denominazioneInItaliano: "Brinzio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B182"
      },
      {
        denominazioneInItaliano: "Brissago-Valtravaglia",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B191"
      },
      {
        denominazioneInItaliano: "Brunello",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B219"
      },
      {
        denominazioneInItaliano: "Brusimpiano",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B228"
      },
      {
        denominazioneInItaliano: "Buguggiate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B258"
      },
      {
        denominazioneInItaliano: "Busto Arsizio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B300"
      },
      {
        denominazioneInItaliano: "Cadegliano-Viconago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B326"
      },
      {
        denominazioneInItaliano: "Cairate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B368"
      },
      {
        denominazioneInItaliano: "Cantello",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B634"
      },
      {
        denominazioneInItaliano: "Caravate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B732"
      },
      {
        denominazioneInItaliano: "Cardano al Campo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B754"
      },
      {
        denominazioneInItaliano: "Carnago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B796"
      },
      {
        denominazioneInItaliano: "Caronno Pertusella",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B805"
      },
      {
        denominazioneInItaliano: "Caronno Varesino",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B807"
      },
      {
        denominazioneInItaliano: "Casale Litta",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B875"
      },
      {
        denominazioneInItaliano: "Casalzuigno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B921"
      },
      {
        denominazioneInItaliano: "Casciago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B949"
      },
      {
        denominazioneInItaliano: "Casorate Sempione",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B987"
      },
      {
        denominazioneInItaliano: "Cassano Magnago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C004"
      },
      {
        denominazioneInItaliano: "Cassano Valcuvia",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B999"
      },
      {
        denominazioneInItaliano: "Castellanza",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C139"
      },
      {
        denominazioneInItaliano: "Castello Cabiaglio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "B312"
      },
      {
        denominazioneInItaliano: "Castelseprio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C273"
      },
      {
        denominazioneInItaliano: "Castelveccana",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C181"
      },
      {
        denominazioneInItaliano: "Castiglione Olona",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C300"
      },
      {
        denominazioneInItaliano: "Castronno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C343"
      },
      {
        denominazioneInItaliano: "Cavaria con Premezzo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C382"
      },
      {
        denominazioneInItaliano: "Cazzago Brabbia",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C409"
      },
      {
        denominazioneInItaliano: "Cislago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C732"
      },
      {
        denominazioneInItaliano: "Cittiglio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C751"
      },
      {
        denominazioneInItaliano: "Clivio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C796"
      },
      {
        denominazioneInItaliano: "Cocquio-Trevisago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C810"
      },
      {
        denominazioneInItaliano: "Comabbio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C911"
      },
      {
        denominazioneInItaliano: "Comerio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "C922"
      },
      {
        denominazioneInItaliano: "Cremenaga",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D144"
      },
      {
        denominazioneInItaliano: "Crosio della Valle",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D185"
      },
      {
        denominazioneInItaliano: "Cuasso al Monte",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D192"
      },
      {
        denominazioneInItaliano: "Cugliate-Fabiasco",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D199"
      },
      {
        denominazioneInItaliano: "Cunardo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D204"
      },
      {
        denominazioneInItaliano: "Curiglia con Monteviasco",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D217"
      },
      {
        denominazioneInItaliano: "Cuveglio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D238"
      },
      {
        denominazioneInItaliano: "Cuvio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D239"
      },
      {
        denominazioneInItaliano: "Daverio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D256"
      },
      {
        denominazioneInItaliano: "Dumenza",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D384"
      },
      {
        denominazioneInItaliano: "Duno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D385"
      },
      {
        denominazioneInItaliano: "Fagnano Olona",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D467"
      },
      {
        denominazioneInItaliano: "Ferno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D543"
      },
      {
        denominazioneInItaliano: "Ferrera di Varese",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D551"
      },
      {
        denominazioneInItaliano: "Gallarate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D869"
      },
      {
        denominazioneInItaliano: "Galliate Lombardo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D871"
      },
      {
        denominazioneInItaliano: "Gavirate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D946"
      },
      {
        denominazioneInItaliano: "Gazzada Schianno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D951"
      },
      {
        denominazioneInItaliano: "Gemonio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D963"
      },
      {
        denominazioneInItaliano: "Gerenzano",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D981"
      },
      {
        denominazioneInItaliano: "Germignaga",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "D987"
      },
      {
        denominazioneInItaliano: "Golasecca",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E079"
      },
      {
        denominazioneInItaliano: "Gorla Maggiore",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E101"
      },
      {
        denominazioneInItaliano: "Gorla Minore",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E102"
      },
      {
        denominazioneInItaliano: "Gornate Olona",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E104"
      },
      {
        denominazioneInItaliano: "Grantola",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E144"
      },
      {
        denominazioneInItaliano: "Inarzo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E292"
      },
      {
        denominazioneInItaliano: "Induno Olona",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E299"
      },
      {
        denominazioneInItaliano: "Ispra",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E367"
      },
      {
        denominazioneInItaliano: "Jerago con Orago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E386"
      },
      {
        denominazioneInItaliano: "Lavena Ponte Tresa",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E494"
      },
      {
        denominazioneInItaliano: "Laveno-Mombello",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E496"
      },
      {
        denominazioneInItaliano: "Leggiuno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E510"
      },
      {
        denominazioneInItaliano: "Lonate Ceppino",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E665"
      },
      {
        denominazioneInItaliano: "Lonate Pozzolo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E666"
      },
      {
        denominazioneInItaliano: "Lozza",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E707"
      },
      {
        denominazioneInItaliano: "Luino",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E734"
      },
      {
        denominazioneInItaliano: "Luvinate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E769"
      },
      {
        denominazioneInItaliano: "Malnate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E863"
      },
      {
        denominazioneInItaliano: "Marchirolo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E929"
      },
      {
        denominazioneInItaliano: "Marnate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "E965"
      },
      {
        denominazioneInItaliano: "Marzio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "F002"
      },
      {
        denominazioneInItaliano: "Masciago Primo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "F007"
      },
      {
        denominazioneInItaliano: "Mercallo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "F134"
      },
      {
        denominazioneInItaliano: "Mesenzana",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "F154"
      },
      {
        denominazioneInItaliano: "Montegrino Valtravaglia",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "F526"
      },
      {
        denominazioneInItaliano: "Monvalle",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "F703"
      },
      {
        denominazioneInItaliano: "Morazzone",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "F711"
      },
      {
        denominazioneInItaliano: "Mornago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "F736"
      },
      {
        denominazioneInItaliano: "Oggiona con Santo Stefano",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "G008"
      },
      {
        denominazioneInItaliano: "Olgiate Olona",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "G028"
      },
      {
        denominazioneInItaliano: "Origgio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "G103"
      },
      {
        denominazioneInItaliano: "Orino",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "G105"
      },
      {
        denominazioneInItaliano: "Porto Ceresio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "G906"
      },
      {
        denominazioneInItaliano: "Porto Valtravaglia",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "G907"
      },
      {
        denominazioneInItaliano: "Rancio Valcuvia",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "H173"
      },
      {
        denominazioneInItaliano: "Ranco",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "H174"
      },
      {
        denominazioneInItaliano: "Saltrio",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "H723"
      },
      {
        denominazioneInItaliano: "Samarate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "H736"
      },
      {
        denominazioneInItaliano: "Saronno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "I441"
      },
      {
        denominazioneInItaliano: "Sesto Calende",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "I688"
      },
      {
        denominazioneInItaliano: "Solbiate Arno",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "I793"
      },
      {
        denominazioneInItaliano: "Solbiate Olona",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "I794"
      },
      {
        denominazioneInItaliano: "Somma Lombardo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "I819"
      },
      {
        denominazioneInItaliano: "Sumirago",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L003"
      },
      {
        denominazioneInItaliano: "Taino",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L032"
      },
      {
        denominazioneInItaliano: "Ternate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L115"
      },
      {
        denominazioneInItaliano: "Tradate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L319"
      },
      {
        denominazioneInItaliano: "Travedona-Monate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L342"
      },
      {
        denominazioneInItaliano: "Tronzano Lago Maggiore",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "A705"
      },
      {
        denominazioneInItaliano: "Uboldo",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L480"
      },
      {
        denominazioneInItaliano: "Valganna",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L577"
      },
      {
        denominazioneInItaliano: "Varano Borghi",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L671"
      },
      {
        denominazioneInItaliano: "Varese",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L682"
      },
      {
        denominazioneInItaliano: "Vedano Olona",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L703"
      },
      {
        denominazioneInItaliano: "Venegono Inferiore",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L733"
      },
      {
        denominazioneInItaliano: "Venegono Superiore",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L734"
      },
      {
        denominazioneInItaliano: "Vergiate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L765"
      },
      {
        denominazioneInItaliano: "Viggiù",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "L876"
      },
      {
        denominazioneInItaliano: "Vizzola Ticino",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "M101"
      },
      {
        denominazioneInItaliano: "Sangiano",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "H872"
      },
      {
        denominazioneInItaliano: "Maccagno con Pino e Veddasca",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "M339"
      },
      {
        denominazioneInItaliano: "Cadrezzate con Osmate",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "M425"
      },
      {
        denominazioneInItaliano: "Bardello con Malgesso e Bregano",
        regione: "Lombardia",
        provincia: "Varese",
        codiceCatastale: "M433"
      },
      {
        denominazioneInItaliano: "Albavilla",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A143"
      },
      {
        denominazioneInItaliano: "Albese con Cassano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A153"
      },
      {
        denominazioneInItaliano: "Albiolo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A164"
      },
      {
        denominazioneInItaliano: "Alserio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A224"
      },
      {
        denominazioneInItaliano: "Alzate Brianza",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A249"
      },
      {
        denominazioneInItaliano: "Anzano del Parco",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A319"
      },
      {
        denominazioneInItaliano: "Appiano Gentile",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A333"
      },
      {
        denominazioneInItaliano: "Argegno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A391"
      },
      {
        denominazioneInItaliano: "Arosio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A430"
      },
      {
        denominazioneInItaliano: "Asso",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A476"
      },
      {
        denominazioneInItaliano: "Barni",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A670"
      },
      {
        denominazioneInItaliano: "Bene Lario",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A778"
      },
      {
        denominazioneInItaliano: "Beregazzo con Figliaro",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A791"
      },
      {
        denominazioneInItaliano: "Binago",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A870"
      },
      {
        denominazioneInItaliano: "Bizzarone",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A898"
      },
      {
        denominazioneInItaliano: "Blessagno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A904"
      },
      {
        denominazioneInItaliano: "Blevio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "A905"
      },
      {
        denominazioneInItaliano: "Bregnano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B134"
      },
      {
        denominazioneInItaliano: "Brenna",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B144"
      },
      {
        denominazioneInItaliano: "Brienno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B172"
      },
      {
        denominazioneInItaliano: "Brunate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B218"
      },
      {
        denominazioneInItaliano: "Bulgarograsso",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B262"
      },
      {
        denominazioneInItaliano: "Cabiate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B313"
      },
      {
        denominazioneInItaliano: "Cadorago",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B346"
      },
      {
        denominazioneInItaliano: "Caglio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B355"
      },
      {
        denominazioneInItaliano: "Campione d'Italia",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B513"
      },
      {
        denominazioneInItaliano: "Cantù",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B639"
      },
      {
        denominazioneInItaliano: "Canzo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B641"
      },
      {
        denominazioneInItaliano: "Capiago Intimiano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B653"
      },
      {
        denominazioneInItaliano: "Carate Urio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B730"
      },
      {
        denominazioneInItaliano: "Carbonate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B742"
      },
      {
        denominazioneInItaliano: "Carimate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B778"
      },
      {
        denominazioneInItaliano: "Carlazzo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B785"
      },
      {
        denominazioneInItaliano: "Carugo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B851"
      },
      {
        denominazioneInItaliano: "Caslino d'Erba",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B974"
      },
      {
        denominazioneInItaliano: "Casnate con Bernate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "B977"
      },
      {
        denominazioneInItaliano: "Cassina Rizzardi",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C020"
      },
      {
        denominazioneInItaliano: "Castelmarte",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C206"
      },
      {
        denominazioneInItaliano: "Castelnuovo Bozzente",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C220"
      },
      {
        denominazioneInItaliano: "Cavargna",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C381"
      },
      {
        denominazioneInItaliano: "Cerano d'Intelvi",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C482"
      },
      {
        denominazioneInItaliano: "Cermenate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C516"
      },
      {
        denominazioneInItaliano: "Cernobbio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C520"
      },
      {
        denominazioneInItaliano: "Cirimido",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C724"
      },
      {
        denominazioneInItaliano: "Claino con Osteno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C787"
      },
      {
        denominazioneInItaliano: "Colonno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C902"
      },
      {
        denominazioneInItaliano: "Como",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C933"
      },
      {
        denominazioneInItaliano: "Corrido",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D041"
      },
      {
        denominazioneInItaliano: "Cremia",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D147"
      },
      {
        denominazioneInItaliano: "Cucciago",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D196"
      },
      {
        denominazioneInItaliano: "Cusino",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D232"
      },
      {
        denominazioneInItaliano: "Dizzasco",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D310"
      },
      {
        denominazioneInItaliano: "Domaso",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D329"
      },
      {
        denominazioneInItaliano: "Dongo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D341"
      },
      {
        denominazioneInItaliano: "Dosso del Liro",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D355"
      },
      {
        denominazioneInItaliano: "Erba",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D416"
      },
      {
        denominazioneInItaliano: "Eupilio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D445"
      },
      {
        denominazioneInItaliano: "Faggeto Lario",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D462"
      },
      {
        denominazioneInItaliano: "Faloppio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D482"
      },
      {
        denominazioneInItaliano: "Fenegrò",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D531"
      },
      {
        denominazioneInItaliano: "Figino Serenza",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D579"
      },
      {
        denominazioneInItaliano: "Fino Mornasco",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D605"
      },
      {
        denominazioneInItaliano: "Garzeno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D930"
      },
      {
        denominazioneInItaliano: "Gera Lario",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "D974"
      },
      {
        denominazioneInItaliano: "Grandate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E139"
      },
      {
        denominazioneInItaliano: "Grandola ed Uniti",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E141"
      },
      {
        denominazioneInItaliano: "Griante",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E172"
      },
      {
        denominazioneInItaliano: "Guanzate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E235"
      },
      {
        denominazioneInItaliano: "Inverigo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E309"
      },
      {
        denominazioneInItaliano: "Laglio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E405"
      },
      {
        denominazioneInItaliano: "Laino",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E416"
      },
      {
        denominazioneInItaliano: "Lambrugo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E428"
      },
      {
        denominazioneInItaliano: "Lasnigo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E462"
      },
      {
        denominazioneInItaliano: "Lezzeno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E569"
      },
      {
        denominazioneInItaliano: "Limido Comasco",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E593"
      },
      {
        denominazioneInItaliano: "Lipomo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E607"
      },
      {
        denominazioneInItaliano: "Livo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E623"
      },
      {
        denominazioneInItaliano: "Locate Varesino",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E638"
      },
      {
        denominazioneInItaliano: "Lomazzo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E659"
      },
      {
        denominazioneInItaliano: "Longone al Segrino",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E679"
      },
      {
        denominazioneInItaliano: "Luisago",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E735"
      },
      {
        denominazioneInItaliano: "Lurago d'Erba",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E749"
      },
      {
        denominazioneInItaliano: "Lurago Marinone",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E750"
      },
      {
        denominazioneInItaliano: "Lurate Caccivio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E753"
      },
      {
        denominazioneInItaliano: "Magreglio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E830"
      },
      {
        denominazioneInItaliano: "Mariano Comense",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "E951"
      },
      {
        denominazioneInItaliano: "Maslianico",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F017"
      },
      {
        denominazioneInItaliano: "Menaggio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F120"
      },
      {
        denominazioneInItaliano: "Merone",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F151"
      },
      {
        denominazioneInItaliano: "Moltrasio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F305"
      },
      {
        denominazioneInItaliano: "Monguzzo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F372"
      },
      {
        denominazioneInItaliano: "Montano Lucino",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F427"
      },
      {
        denominazioneInItaliano: "Montemezzo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F564"
      },
      {
        denominazioneInItaliano: "Montorfano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F688"
      },
      {
        denominazioneInItaliano: "Mozzate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F788"
      },
      {
        denominazioneInItaliano: "Musso",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F828"
      },
      {
        denominazioneInItaliano: "Nesso",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F877"
      },
      {
        denominazioneInItaliano: "Novedrate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "F958"
      },
      {
        denominazioneInItaliano: "Olgiate Comasco",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G025"
      },
      {
        denominazioneInItaliano: "Oltrona di San Mamette",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G056"
      },
      {
        denominazioneInItaliano: "Orsenigo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G126"
      },
      {
        denominazioneInItaliano: "Peglio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G415"
      },
      {
        denominazioneInItaliano: "Pianello del Lario",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G556"
      },
      {
        denominazioneInItaliano: "Pigra",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G665"
      },
      {
        denominazioneInItaliano: "Plesio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G737"
      },
      {
        denominazioneInItaliano: "Pognana Lario",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G773"
      },
      {
        denominazioneInItaliano: "Ponna",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G821"
      },
      {
        denominazioneInItaliano: "Ponte Lambro",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G847"
      },
      {
        denominazioneInItaliano: "Porlezza",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "G889"
      },
      {
        denominazioneInItaliano: "Proserpio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H074"
      },
      {
        denominazioneInItaliano: "Pusiano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H094"
      },
      {
        denominazioneInItaliano: "Rezzago",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H255"
      },
      {
        denominazioneInItaliano: "Rodero",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H478"
      },
      {
        denominazioneInItaliano: "Ronago",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H521"
      },
      {
        denominazioneInItaliano: "Rovellasca",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H601"
      },
      {
        denominazioneInItaliano: "Rovello Porro",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H602"
      },
      {
        denominazioneInItaliano: "Sala Comacina",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H679"
      },
      {
        denominazioneInItaliano: "San Bartolomeo Val Cavargna",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H760"
      },
      {
        denominazioneInItaliano: "San Fermo della Battaglia",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H840"
      },
      {
        denominazioneInItaliano: "San Nazzaro Val Cavargna",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "I051"
      },
      {
        denominazioneInItaliano: "Schignano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "I529"
      },
      {
        denominazioneInItaliano: "Senna Comasco",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "I611"
      },
      {
        denominazioneInItaliano: "Sorico",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "I856"
      },
      {
        denominazioneInItaliano: "Sormano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "I860"
      },
      {
        denominazioneInItaliano: "Stazzona",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "I943"
      },
      {
        denominazioneInItaliano: "Tavernerio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L071"
      },
      {
        denominazioneInItaliano: "Torno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L228"
      },
      {
        denominazioneInItaliano: "Trezzone",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L413"
      },
      {
        denominazioneInItaliano: "Turate",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L470"
      },
      {
        denominazioneInItaliano: "Uggiate-Trevano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L487"
      },
      {
        denominazioneInItaliano: "Valbrona",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L547"
      },
      {
        denominazioneInItaliano: "Valmorea",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L640"
      },
      {
        denominazioneInItaliano: "Val Rezzo",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "H259"
      },
      {
        denominazioneInItaliano: "Valsolda",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "C936"
      },
      {
        denominazioneInItaliano: "Veleso",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L715"
      },
      {
        denominazioneInItaliano: "Veniano",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L737"
      },
      {
        denominazioneInItaliano: "Vercana",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L748"
      },
      {
        denominazioneInItaliano: "Vertemate con Minoprio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L792"
      },
      {
        denominazioneInItaliano: "Villa Guardia",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "L956"
      },
      {
        denominazioneInItaliano: "Zelbio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "M156"
      },
      {
        denominazioneInItaliano: "San Siro",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "I162"
      },
      {
        denominazioneInItaliano: "Gravedona ed Uniti",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "M315"
      },
      {
        denominazioneInItaliano: "Bellagio",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "M335"
      },
      {
        denominazioneInItaliano: "Colverde",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "M336"
      },
      {
        denominazioneInItaliano: "Tremezzina",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "M341"
      },
      {
        denominazioneInItaliano: "Alta Valle Intelvi",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "M383"
      },
      {
        denominazioneInItaliano: "Centro Valle Intelvi",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "M394"
      },
      {
        denominazioneInItaliano: "Solbiate con Cagno",
        regione: "Lombardia",
        provincia: "Como",
        codiceCatastale: "M412"
      },
      {
        denominazioneInItaliano: "Albaredo per San Marco",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "A135"
      },
      {
        denominazioneInItaliano: "Albosaggia",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "A172"
      },
      {
        denominazioneInItaliano: "Andalo Valtellino",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "A273"
      },
      {
        denominazioneInItaliano: "Aprica",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "A337"
      },
      {
        denominazioneInItaliano: "Ardenno",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "A382"
      },
      {
        denominazioneInItaliano: "Bema",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "A777"
      },
      {
        denominazioneInItaliano: "Berbenno di Valtellina",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "A787"
      },
      {
        denominazioneInItaliano: "Bianzone",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "A848"
      },
      {
        denominazioneInItaliano: "Bormio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "B049"
      },
      {
        denominazioneInItaliano: "Buglio in Monte",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "B255"
      },
      {
        denominazioneInItaliano: "Caiolo",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "B366"
      },
      {
        denominazioneInItaliano: "Campodolcino",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "B530"
      },
      {
        denominazioneInItaliano: "Caspoggio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "B993"
      },
      {
        denominazioneInItaliano: "Castello dell'Acqua",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C186"
      },
      {
        denominazioneInItaliano: "Castione Andevenno",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C325"
      },
      {
        denominazioneInItaliano: "Cedrasco",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C418"
      },
      {
        denominazioneInItaliano: "Cercino",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C493"
      },
      {
        denominazioneInItaliano: "Chiavenna",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C623"
      },
      {
        denominazioneInItaliano: "Chiesa in Valmalenco",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C628"
      },
      {
        denominazioneInItaliano: "Chiuro",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C651"
      },
      {
        denominazioneInItaliano: "Cino",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C709"
      },
      {
        denominazioneInItaliano: "Civo",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C785"
      },
      {
        denominazioneInItaliano: "Colorina",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "C903"
      },
      {
        denominazioneInItaliano: "Cosio Valtellino",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "D088"
      },
      {
        denominazioneInItaliano: "Dazio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "D258"
      },
      {
        denominazioneInItaliano: "Delebio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "D266"
      },
      {
        denominazioneInItaliano: "Dubino",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "D377"
      },
      {
        denominazioneInItaliano: "Faedo Valtellino",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "D456"
      },
      {
        denominazioneInItaliano: "Forcola",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "D694"
      },
      {
        denominazioneInItaliano: "Fusine",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "D830"
      },
      {
        denominazioneInItaliano: "Gerola Alta",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "D990"
      },
      {
        denominazioneInItaliano: "Gordona",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "E090"
      },
      {
        denominazioneInItaliano: "Grosio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "E200"
      },
      {
        denominazioneInItaliano: "Grosotto",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "E201"
      },
      {
        denominazioneInItaliano: "Madesimo",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "E342"
      },
      {
        denominazioneInItaliano: "Lanzada",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "E443"
      },
      {
        denominazioneInItaliano: "Livigno",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "E621"
      },
      {
        denominazioneInItaliano: "Lovero",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "E705"
      },
      {
        denominazioneInItaliano: "Mantello",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "E896"
      },
      {
        denominazioneInItaliano: "Mazzo di Valtellina",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "F070"
      },
      {
        denominazioneInItaliano: "Mello",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "F115"
      },
      {
        denominazioneInItaliano: "Mese",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "F153"
      },
      {
        denominazioneInItaliano: "Montagna in Valtellina",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "F393"
      },
      {
        denominazioneInItaliano: "Morbegno",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "F712"
      },
      {
        denominazioneInItaliano: "Novate Mezzola",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "F956"
      },
      {
        denominazioneInItaliano: "Pedesina",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "G410"
      },
      {
        denominazioneInItaliano: "Piantedo",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "G572"
      },
      {
        denominazioneInItaliano: "Piateda",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "G576"
      },
      {
        denominazioneInItaliano: "Piuro",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "G718"
      },
      {
        denominazioneInItaliano: "Poggiridenti",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "G431"
      },
      {
        denominazioneInItaliano: "Ponte in Valtellina",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "G829"
      },
      {
        denominazioneInItaliano: "Postalesio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "G937"
      },
      {
        denominazioneInItaliano: "Prata Camportaccio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "G993"
      },
      {
        denominazioneInItaliano: "Rasura",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "H192"
      },
      {
        denominazioneInItaliano: "Rogolo",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "H493"
      },
      {
        denominazioneInItaliano: "Samolaco",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "H752"
      },
      {
        denominazioneInItaliano: "San Giacomo Filippo",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "H868"
      },
      {
        denominazioneInItaliano: "Sernio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "I636"
      },
      {
        denominazioneInItaliano: "Sondalo",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "I828"
      },
      {
        denominazioneInItaliano: "Sondrio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "I829"
      },
      {
        denominazioneInItaliano: "Spriana",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "I928"
      },
      {
        denominazioneInItaliano: "Talamona",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L035"
      },
      {
        denominazioneInItaliano: "Tartano",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L056"
      },
      {
        denominazioneInItaliano: "Teglio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L084"
      },
      {
        denominazioneInItaliano: "Tirano",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L175"
      },
      {
        denominazioneInItaliano: "Torre di Santa Maria",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L244"
      },
      {
        denominazioneInItaliano: "Tovo di Sant'Agata",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L316"
      },
      {
        denominazioneInItaliano: "Traona",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L330"
      },
      {
        denominazioneInItaliano: "Tresivio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L392"
      },
      {
        denominazioneInItaliano: "Valdidentro",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L557"
      },
      {
        denominazioneInItaliano: "Valdisotto",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L563"
      },
      {
        denominazioneInItaliano: "Valfurva",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L576"
      },
      {
        denominazioneInItaliano: "Val Masino",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L638"
      },
      {
        denominazioneInItaliano: "Verceia",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L749"
      },
      {
        denominazioneInItaliano: "Vervio",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L799"
      },
      {
        denominazioneInItaliano: "Villa di Chiavenna",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L907"
      },
      {
        denominazioneInItaliano: "Villa di Tirano",
        regione: "Lombardia",
        provincia: "Sondrio",
        codiceCatastale: "L908"
      },
      {
        denominazioneInItaliano: "Abbiategrasso",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A010"
      },
      {
        denominazioneInItaliano: "Albairate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A127"
      },
      {
        denominazioneInItaliano: "Arconate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A375"
      },
      {
        denominazioneInItaliano: "Arese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A389"
      },
      {
        denominazioneInItaliano: "Arluno",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A413"
      },
      {
        denominazioneInItaliano: "Assago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A473"
      },
      {
        denominazioneInItaliano: "Bareggio",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A652"
      },
      {
        denominazioneInItaliano: "Basiano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A697"
      },
      {
        denominazioneInItaliano: "Basiglio",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A699"
      },
      {
        denominazioneInItaliano: "Bellinzago Lombardo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A751"
      },
      {
        denominazioneInItaliano: "Bernate Ticino",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A804"
      },
      {
        denominazioneInItaliano: "Besate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A820"
      },
      {
        denominazioneInItaliano: "Binasco",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A872"
      },
      {
        denominazioneInItaliano: "Boffalora sopra Ticino",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A920"
      },
      {
        denominazioneInItaliano: "Bollate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A940"
      },
      {
        denominazioneInItaliano: "Bresso",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B162"
      },
      {
        denominazioneInItaliano: "Bubbiano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B235"
      },
      {
        denominazioneInItaliano: "Buccinasco",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B240"
      },
      {
        denominazioneInItaliano: "Buscate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B286"
      },
      {
        denominazioneInItaliano: "Bussero",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B292"
      },
      {
        denominazioneInItaliano: "Busto Garolfo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B301"
      },
      {
        denominazioneInItaliano: "Calvignasco",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B448"
      },
      {
        denominazioneInItaliano: "Cambiago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B461"
      },
      {
        denominazioneInItaliano: "Canegrate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B593"
      },
      {
        denominazioneInItaliano: "Carpiano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B820"
      },
      {
        denominazioneInItaliano: "Carugate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B850"
      },
      {
        denominazioneInItaliano: "Casarile",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B938"
      },
      {
        denominazioneInItaliano: "Casorezzo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "B989"
      },
      {
        denominazioneInItaliano: "Cassano d'Adda",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C003"
      },
      {
        denominazioneInItaliano: "Cassina de' Pecchi",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C014"
      },
      {
        denominazioneInItaliano: "Cassinetta di Lugagnano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C033"
      },
      {
        denominazioneInItaliano: "Castano Primo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C052"
      },
      {
        denominazioneInItaliano: "Cernusco sul Naviglio",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C523"
      },
      {
        denominazioneInItaliano: "Cerro al Lambro",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C536"
      },
      {
        denominazioneInItaliano: "Cerro Maggiore",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C537"
      },
      {
        denominazioneInItaliano: "Cesano Boscone",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C565"
      },
      {
        denominazioneInItaliano: "Cesate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C569"
      },
      {
        denominazioneInItaliano: "Cinisello Balsamo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C707"
      },
      {
        denominazioneInItaliano: "Cisliano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C733"
      },
      {
        denominazioneInItaliano: "Cologno Monzese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C895"
      },
      {
        denominazioneInItaliano: "Colturano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C908"
      },
      {
        denominazioneInItaliano: "Corbetta",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C986"
      },
      {
        denominazioneInItaliano: "Cormano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D013"
      },
      {
        denominazioneInItaliano: "Cornaredo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D018"
      },
      {
        denominazioneInItaliano: "Corsico",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D045"
      },
      {
        denominazioneInItaliano: "Cuggiono",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D198"
      },
      {
        denominazioneInItaliano: "Cusago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D229"
      },
      {
        denominazioneInItaliano: "Cusano Milanino",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D231"
      },
      {
        denominazioneInItaliano: "Dairago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D244"
      },
      {
        denominazioneInItaliano: "Dresano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D367"
      },
      {
        denominazioneInItaliano: "Gaggiano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D845"
      },
      {
        denominazioneInItaliano: "Garbagnate Milanese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D912"
      },
      {
        denominazioneInItaliano: "Gessate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D995"
      },
      {
        denominazioneInItaliano: "Gorgonzola",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E094"
      },
      {
        denominazioneInItaliano: "Grezzago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E170"
      },
      {
        denominazioneInItaliano: "Gudo Visconti",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E258"
      },
      {
        denominazioneInItaliano: "Inveruno",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E313"
      },
      {
        denominazioneInItaliano: "Inzago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E317"
      },
      {
        denominazioneInItaliano: "Lacchiarella",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E395"
      },
      {
        denominazioneInItaliano: "Lainate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E415"
      },
      {
        denominazioneInItaliano: "Legnano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E514"
      },
      {
        denominazioneInItaliano: "Liscate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E610"
      },
      {
        denominazioneInItaliano: "Locate di Triulzi",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E639"
      },
      {
        denominazioneInItaliano: "Magenta",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E801"
      },
      {
        denominazioneInItaliano: "Magnago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E819"
      },
      {
        denominazioneInItaliano: "Marcallo con Casone",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "E921"
      },
      {
        denominazioneInItaliano: "Masate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F003"
      },
      {
        denominazioneInItaliano: "Mediglia",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F084"
      },
      {
        denominazioneInItaliano: "Melegnano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F100"
      },
      {
        denominazioneInItaliano: "Melzo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F119"
      },
      {
        denominazioneInItaliano: "Mesero",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F155"
      },
      {
        denominazioneInItaliano: "Milano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F205"
      },
      {
        denominazioneInItaliano: "Morimondo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "D033"
      },
      {
        denominazioneInItaliano: "Motta Visconti",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F783"
      },
      {
        denominazioneInItaliano: "Nerviano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F874"
      },
      {
        denominazioneInItaliano: "Nosate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F939"
      },
      {
        denominazioneInItaliano: "Novate Milanese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F955"
      },
      {
        denominazioneInItaliano: "Noviglio",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "F968"
      },
      {
        denominazioneInItaliano: "Opera",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G078"
      },
      {
        denominazioneInItaliano: "Ossona",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G181"
      },
      {
        denominazioneInItaliano: "Ozzero",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G206"
      },
      {
        denominazioneInItaliano: "Paderno Dugnano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G220"
      },
      {
        denominazioneInItaliano: "Pantigliate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G316"
      },
      {
        denominazioneInItaliano: "Parabiago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G324"
      },
      {
        denominazioneInItaliano: "Paullo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G385"
      },
      {
        denominazioneInItaliano: "Pero",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "C013"
      },
      {
        denominazioneInItaliano: "Peschiera Borromeo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G488"
      },
      {
        denominazioneInItaliano: "Pessano con Bornago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G502"
      },
      {
        denominazioneInItaliano: "Pieve Emanuele",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G634"
      },
      {
        denominazioneInItaliano: "Pioltello",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G686"
      },
      {
        denominazioneInItaliano: "Pogliano Milanese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G772"
      },
      {
        denominazioneInItaliano: "Pozzo d'Adda",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G955"
      },
      {
        denominazioneInItaliano: "Pozzuolo Martesana",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "G965"
      },
      {
        denominazioneInItaliano: "Pregnana Milanese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H026"
      },
      {
        denominazioneInItaliano: "Rescaldina",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H240"
      },
      {
        denominazioneInItaliano: "Rho",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H264"
      },
      {
        denominazioneInItaliano: "Robecchetto con Induno",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H371"
      },
      {
        denominazioneInItaliano: "Robecco sul Naviglio",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H373"
      },
      {
        denominazioneInItaliano: "Rodano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H470"
      },
      {
        denominazioneInItaliano: "Rosate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H560"
      },
      {
        denominazioneInItaliano: "Rozzano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H623"
      },
      {
        denominazioneInItaliano: "San Colombano al Lambro",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H803"
      },
      {
        denominazioneInItaliano: "San Donato Milanese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H827"
      },
      {
        denominazioneInItaliano: "San Giorgio su Legnano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H884"
      },
      {
        denominazioneInItaliano: "San Giuliano Milanese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "H930"
      },
      {
        denominazioneInItaliano: "Santo Stefano Ticino",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I361"
      },
      {
        denominazioneInItaliano: "San Vittore Olona",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I409"
      },
      {
        denominazioneInItaliano: "San Zenone al Lambro",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I415"
      },
      {
        denominazioneInItaliano: "Sedriano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I566"
      },
      {
        denominazioneInItaliano: "Segrate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I577"
      },
      {
        denominazioneInItaliano: "Senago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I602"
      },
      {
        denominazioneInItaliano: "Sesto San Giovanni",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I690"
      },
      {
        denominazioneInItaliano: "Settala",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I696"
      },
      {
        denominazioneInItaliano: "Settimo Milanese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I700"
      },
      {
        denominazioneInItaliano: "Solaro",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "I786"
      },
      {
        denominazioneInItaliano: "Trezzano Rosa",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L408"
      },
      {
        denominazioneInItaliano: "Trezzano sul Naviglio",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L409"
      },
      {
        denominazioneInItaliano: "Trezzo sull'Adda",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L411"
      },
      {
        denominazioneInItaliano: "Tribiano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L415"
      },
      {
        denominazioneInItaliano: "Truccazzano",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L454"
      },
      {
        denominazioneInItaliano: "Turbigo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L471"
      },
      {
        denominazioneInItaliano: "Vanzago",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L665"
      },
      {
        denominazioneInItaliano: "Vaprio d'Adda",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L667"
      },
      {
        denominazioneInItaliano: "Vernate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L773"
      },
      {
        denominazioneInItaliano: "Vignate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L883"
      },
      {
        denominazioneInItaliano: "Vimodrone",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "M053"
      },
      {
        denominazioneInItaliano: "Vittuone",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "M091"
      },
      {
        denominazioneInItaliano: "Vizzolo Predabissi",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "M102"
      },
      {
        denominazioneInItaliano: "Zibido San Giacomo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "M176"
      },
      {
        denominazioneInItaliano: "Villa Cortese",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L928"
      },
      {
        denominazioneInItaliano: "Vanzaghello",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "L664"
      },
      {
        denominazioneInItaliano: "Baranzate",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "A618"
      },
      {
        denominazioneInItaliano: "Vermezzo con Zelo",
        regione: "Lombardia",
        provincia: "Milano",
        codiceCatastale: "M424"
      },
      {
        denominazioneInItaliano: "Adrara San Martino",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A057"
      },
      {
        denominazioneInItaliano: "Adrara San Rocco",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A058"
      },
      {
        denominazioneInItaliano: "Albano Sant'Alessandro",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A129"
      },
      {
        denominazioneInItaliano: "Albino",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A163"
      },
      {
        denominazioneInItaliano: "Almè",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A214"
      },
      {
        denominazioneInItaliano: "Almenno San Bartolomeo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A216"
      },
      {
        denominazioneInItaliano: "Almenno San Salvatore",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A217"
      },
      {
        denominazioneInItaliano: "Alzano Lombardo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A246"
      },
      {
        denominazioneInItaliano: "Ambivere",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A259"
      },
      {
        denominazioneInItaliano: "Antegnate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A304"
      },
      {
        denominazioneInItaliano: "Arcene",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A365"
      },
      {
        denominazioneInItaliano: "Ardesio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A383"
      },
      {
        denominazioneInItaliano: "Arzago d'Adda",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A440"
      },
      {
        denominazioneInItaliano: "Averara",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A511"
      },
      {
        denominazioneInItaliano: "Aviatico",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A517"
      },
      {
        denominazioneInItaliano: "Azzano San Paolo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A528"
      },
      {
        denominazioneInItaliano: "Azzone",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A533"
      },
      {
        denominazioneInItaliano: "Bagnatica",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A557"
      },
      {
        denominazioneInItaliano: "Barbata",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A631"
      },
      {
        denominazioneInItaliano: "Bariano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A664"
      },
      {
        denominazioneInItaliano: "Barzana",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A684"
      },
      {
        denominazioneInItaliano: "Bedulita",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A732"
      },
      {
        denominazioneInItaliano: "Berbenno",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A786"
      },
      {
        denominazioneInItaliano: "Bergamo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A794"
      },
      {
        denominazioneInItaliano: "Berzo San Fermo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A815"
      },
      {
        denominazioneInItaliano: "Bianzano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A846"
      },
      {
        denominazioneInItaliano: "Blello",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A903"
      },
      {
        denominazioneInItaliano: "Bolgare",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A937"
      },
      {
        denominazioneInItaliano: "Boltiere",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A950"
      },
      {
        denominazioneInItaliano: "Bonate Sopra",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A963"
      },
      {
        denominazioneInItaliano: "Bonate Sotto",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A962"
      },
      {
        denominazioneInItaliano: "Borgo di Terzo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B010"
      },
      {
        denominazioneInItaliano: "Bossico",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B083"
      },
      {
        denominazioneInItaliano: "Bottanuco",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B088"
      },
      {
        denominazioneInItaliano: "Bracca",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B112"
      },
      {
        denominazioneInItaliano: "Branzi",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B123"
      },
      {
        denominazioneInItaliano: "Brembate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B137"
      },
      {
        denominazioneInItaliano: "Brembate di Sopra",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B138"
      },
      {
        denominazioneInItaliano: "Brignano Gera d'Adda",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B178"
      },
      {
        denominazioneInItaliano: "Brumano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B217"
      },
      {
        denominazioneInItaliano: "Brusaporto",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B223"
      },
      {
        denominazioneInItaliano: "Calcinate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B393"
      },
      {
        denominazioneInItaliano: "Calcio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B395"
      },
      {
        denominazioneInItaliano: "Calusco d'Adda",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B434"
      },
      {
        denominazioneInItaliano: "Calvenzano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B442"
      },
      {
        denominazioneInItaliano: "Camerata Cornello",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B471"
      },
      {
        denominazioneInItaliano: "Canonica d'Adda",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B618"
      },
      {
        denominazioneInItaliano: "Capizzone",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B661"
      },
      {
        denominazioneInItaliano: "Capriate San Gervasio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B703"
      },
      {
        denominazioneInItaliano: "Caprino Bergamasco",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B710"
      },
      {
        denominazioneInItaliano: "Caravaggio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B731"
      },
      {
        denominazioneInItaliano: "Carobbio degli Angeli",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B801"
      },
      {
        denominazioneInItaliano: "Carona",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B803"
      },
      {
        denominazioneInItaliano: "Carvico",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B854"
      },
      {
        denominazioneInItaliano: "Casazza",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B947"
      },
      {
        denominazioneInItaliano: "Casirate d'Adda",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B971"
      },
      {
        denominazioneInItaliano: "Casnigo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B978"
      },
      {
        denominazioneInItaliano: "Cassiglio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C007"
      },
      {
        denominazioneInItaliano: "Castelli Calepio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C079"
      },
      {
        denominazioneInItaliano: "Castel Rozzone",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C255"
      },
      {
        denominazioneInItaliano: "Castione della Presolana",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C324"
      },
      {
        denominazioneInItaliano: "Castro",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C337"
      },
      {
        denominazioneInItaliano: "Cavernago",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C396"
      },
      {
        denominazioneInItaliano: "Cazzano Sant'Andrea",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C410"
      },
      {
        denominazioneInItaliano: "Cenate Sopra",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C456"
      },
      {
        denominazioneInItaliano: "Cenate Sotto",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C457"
      },
      {
        denominazioneInItaliano: "Cene",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C459"
      },
      {
        denominazioneInItaliano: "Cerete",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C506"
      },
      {
        denominazioneInItaliano: "Chignolo d'Isola",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C635"
      },
      {
        denominazioneInItaliano: "Chiuduno",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C649"
      },
      {
        denominazioneInItaliano: "Cisano Bergamasco",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C728"
      },
      {
        denominazioneInItaliano: "Ciserano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C730"
      },
      {
        denominazioneInItaliano: "Cividate al Piano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C759"
      },
      {
        denominazioneInItaliano: "Clusone",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C800"
      },
      {
        denominazioneInItaliano: "Colere",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C835"
      },
      {
        denominazioneInItaliano: "Cologno al Serio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C894"
      },
      {
        denominazioneInItaliano: "Colzate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C910"
      },
      {
        denominazioneInItaliano: "Comun Nuovo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C937"
      },
      {
        denominazioneInItaliano: "Corna Imagna",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D015"
      },
      {
        denominazioneInItaliano: "Cortenuova",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D066"
      },
      {
        denominazioneInItaliano: "Costa di Mezzate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D110"
      },
      {
        denominazioneInItaliano: "Costa Valle Imagna",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D103"
      },
      {
        denominazioneInItaliano: "Costa Volpino",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D117"
      },
      {
        denominazioneInItaliano: "Covo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D126"
      },
      {
        denominazioneInItaliano: "Credaro",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D139"
      },
      {
        denominazioneInItaliano: "Curno",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D221"
      },
      {
        denominazioneInItaliano: "Cusio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D233"
      },
      {
        denominazioneInItaliano: "Dalmine",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D245"
      },
      {
        denominazioneInItaliano: "Dossena",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D352"
      },
      {
        denominazioneInItaliano: "Endine Gaiano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D406"
      },
      {
        denominazioneInItaliano: "Entratico",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D411"
      },
      {
        denominazioneInItaliano: "Fara Gera d'Adda",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D490"
      },
      {
        denominazioneInItaliano: "Fara Olivana con Sola",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D491"
      },
      {
        denominazioneInItaliano: "Filago",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D588"
      },
      {
        denominazioneInItaliano: "Fino del Monte",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D604"
      },
      {
        denominazioneInItaliano: "Fiorano al Serio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D606"
      },
      {
        denominazioneInItaliano: "Fontanella",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D672"
      },
      {
        denominazioneInItaliano: "Fonteno",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D684"
      },
      {
        denominazioneInItaliano: "Foppolo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D688"
      },
      {
        denominazioneInItaliano: "Foresto Sparso",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D697"
      },
      {
        denominazioneInItaliano: "Fornovo San Giovanni",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D727"
      },
      {
        denominazioneInItaliano: "Fuipiano Valle Imagna",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D817"
      },
      {
        denominazioneInItaliano: "Gandellino",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D903"
      },
      {
        denominazioneInItaliano: "Gandino",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D905"
      },
      {
        denominazioneInItaliano: "Gandosso",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D906"
      },
      {
        denominazioneInItaliano: "Gaverina Terme",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D943"
      },
      {
        denominazioneInItaliano: "Gazzaniga",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D952"
      },
      {
        denominazioneInItaliano: "Ghisalba",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E006"
      },
      {
        denominazioneInItaliano: "Gorlago",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E100"
      },
      {
        denominazioneInItaliano: "Gorle",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E103"
      },
      {
        denominazioneInItaliano: "Gorno",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E106"
      },
      {
        denominazioneInItaliano: "Grassobbio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E148"
      },
      {
        denominazioneInItaliano: "Gromo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E189"
      },
      {
        denominazioneInItaliano: "Grone",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E192"
      },
      {
        denominazioneInItaliano: "Grumello del Monte",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E219"
      },
      {
        denominazioneInItaliano: "Isola di Fondra",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E353"
      },
      {
        denominazioneInItaliano: "Isso",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E370"
      },
      {
        denominazioneInItaliano: "Lallio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E422"
      },
      {
        denominazioneInItaliano: "Leffe",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E509"
      },
      {
        denominazioneInItaliano: "Lenna",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E524"
      },
      {
        denominazioneInItaliano: "Levate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E562"
      },
      {
        denominazioneInItaliano: "Locatello",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E640"
      },
      {
        denominazioneInItaliano: "Lovere",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E704"
      },
      {
        denominazioneInItaliano: "Lurano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E751"
      },
      {
        denominazioneInItaliano: "Luzzana",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E770"
      },
      {
        denominazioneInItaliano: "Madone",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E794"
      },
      {
        denominazioneInItaliano: "Mapello",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E901"
      },
      {
        denominazioneInItaliano: "Martinengo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "E987"
      },
      {
        denominazioneInItaliano: "Mezzoldo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F186"
      },
      {
        denominazioneInItaliano: "Misano di Gera d'Adda",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F243"
      },
      {
        denominazioneInItaliano: "Moio de' Calvi",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F276"
      },
      {
        denominazioneInItaliano: "Monasterolo del Castello",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F328"
      },
      {
        denominazioneInItaliano: "Montello",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F547"
      },
      {
        denominazioneInItaliano: "Morengo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F720"
      },
      {
        denominazioneInItaliano: "Mornico al Serio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F738"
      },
      {
        denominazioneInItaliano: "Mozzanica",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F786"
      },
      {
        denominazioneInItaliano: "Mozzo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F791"
      },
      {
        denominazioneInItaliano: "Nembro",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F864"
      },
      {
        denominazioneInItaliano: "Olmo al Brembo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G049"
      },
      {
        denominazioneInItaliano: "Oltre il Colle",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G050"
      },
      {
        denominazioneInItaliano: "Oltressenda Alta",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G054"
      },
      {
        denominazioneInItaliano: "Oneta",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G068"
      },
      {
        denominazioneInItaliano: "Onore",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G075"
      },
      {
        denominazioneInItaliano: "Orio al Serio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G108"
      },
      {
        denominazioneInItaliano: "Ornica",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G118"
      },
      {
        denominazioneInItaliano: "Osio Sopra",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G159"
      },
      {
        denominazioneInItaliano: "Osio Sotto",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G160"
      },
      {
        denominazioneInItaliano: "Pagazzano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G233"
      },
      {
        denominazioneInItaliano: "Paladina",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G249"
      },
      {
        denominazioneInItaliano: "Palazzago",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G259"
      },
      {
        denominazioneInItaliano: "Palosco",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G295"
      },
      {
        denominazioneInItaliano: "Parre",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G346"
      },
      {
        denominazioneInItaliano: "Parzanica",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G350"
      },
      {
        denominazioneInItaliano: "Pedrengo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G412"
      },
      {
        denominazioneInItaliano: "Peia",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G418"
      },
      {
        denominazioneInItaliano: "Pianico",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G564"
      },
      {
        denominazioneInItaliano: "Piario",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G574"
      },
      {
        denominazioneInItaliano: "Piazza Brembana",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G579"
      },
      {
        denominazioneInItaliano: "Piazzatorre",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G583"
      },
      {
        denominazioneInItaliano: "Piazzolo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G588"
      },
      {
        denominazioneInItaliano: "Pognano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G774"
      },
      {
        denominazioneInItaliano: "Ponte Nossa",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F941"
      },
      {
        denominazioneInItaliano: "Ponteranica",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G853"
      },
      {
        denominazioneInItaliano: "Ponte San Pietro",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G856"
      },
      {
        denominazioneInItaliano: "Pontida",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G864"
      },
      {
        denominazioneInItaliano: "Pontirolo Nuovo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G867"
      },
      {
        denominazioneInItaliano: "Pradalunga",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "G968"
      },
      {
        denominazioneInItaliano: "Predore",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H020"
      },
      {
        denominazioneInItaliano: "Premolo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H036"
      },
      {
        denominazioneInItaliano: "Presezzo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H046"
      },
      {
        denominazioneInItaliano: "Pumenengo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H091"
      },
      {
        denominazioneInItaliano: "Ranica",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H176"
      },
      {
        denominazioneInItaliano: "Ranzanico",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H177"
      },
      {
        denominazioneInItaliano: "Riva di Solto",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H331"
      },
      {
        denominazioneInItaliano: "Rogno",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H492"
      },
      {
        denominazioneInItaliano: "Romano di Lombardia",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H509"
      },
      {
        denominazioneInItaliano: "Roncobello",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H535"
      },
      {
        denominazioneInItaliano: "Roncola",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H544"
      },
      {
        denominazioneInItaliano: "Rota d'Imagna",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H584"
      },
      {
        denominazioneInItaliano: "Rovetta",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H615"
      },
      {
        denominazioneInItaliano: "San Giovanni Bianco",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "H910"
      },
      {
        denominazioneInItaliano: "San Paolo d'Argon",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "B310"
      },
      {
        denominazioneInItaliano: "San Pellegrino Terme",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I079"
      },
      {
        denominazioneInItaliano: "Santa Brigida",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I168"
      },
      {
        denominazioneInItaliano: "Sarnico",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I437"
      },
      {
        denominazioneInItaliano: "Scanzorosciate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I506"
      },
      {
        denominazioneInItaliano: "Schilpario",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I530"
      },
      {
        denominazioneInItaliano: "Sedrina",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I567"
      },
      {
        denominazioneInItaliano: "Selvino",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I597"
      },
      {
        denominazioneInItaliano: "Seriate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I628"
      },
      {
        denominazioneInItaliano: "Serina",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I629"
      },
      {
        denominazioneInItaliano: "Solto Collina",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I812"
      },
      {
        denominazioneInItaliano: "Songavazzo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I830"
      },
      {
        denominazioneInItaliano: "Sorisole",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I858"
      },
      {
        denominazioneInItaliano: "Sotto il Monte Giovanni XXIII",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I869"
      },
      {
        denominazioneInItaliano: "Sovere",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I873"
      },
      {
        denominazioneInItaliano: "Spinone al Lago",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I916"
      },
      {
        denominazioneInItaliano: "Spirano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I919"
      },
      {
        denominazioneInItaliano: "Stezzano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I951"
      },
      {
        denominazioneInItaliano: "Strozza",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I986"
      },
      {
        denominazioneInItaliano: "Suisio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I997"
      },
      {
        denominazioneInItaliano: "Taleggio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L037"
      },
      {
        denominazioneInItaliano: "Tavernola Bergamasca",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L073"
      },
      {
        denominazioneInItaliano: "Telgate",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L087"
      },
      {
        denominazioneInItaliano: "Terno d'Isola",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L118"
      },
      {
        denominazioneInItaliano: "Torre Boldone",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L251"
      },
      {
        denominazioneInItaliano: "Torre de' Busi",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L257"
      },
      {
        denominazioneInItaliano: "Torre de' Roveri",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L265"
      },
      {
        denominazioneInItaliano: "Torre Pallavicina",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L276"
      },
      {
        denominazioneInItaliano: "Trescore Balneario",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L388"
      },
      {
        denominazioneInItaliano: "Treviglio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L400"
      },
      {
        denominazioneInItaliano: "Treviolo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L404"
      },
      {
        denominazioneInItaliano: "Ubiale Clanezzo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "C789"
      },
      {
        denominazioneInItaliano: "Urgnano",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L502"
      },
      {
        denominazioneInItaliano: "Valbondione",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L544"
      },
      {
        denominazioneInItaliano: "Valbrembo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L545"
      },
      {
        denominazioneInItaliano: "Valgoglio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L579"
      },
      {
        denominazioneInItaliano: "Valleve",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L623"
      },
      {
        denominazioneInItaliano: "Valnegra",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L642"
      },
      {
        denominazioneInItaliano: "Valtorta",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L655"
      },
      {
        denominazioneInItaliano: "Vedeseta",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L707"
      },
      {
        denominazioneInItaliano: "Verdellino",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L752"
      },
      {
        denominazioneInItaliano: "Verdello",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L753"
      },
      {
        denominazioneInItaliano: "Vertova",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L795"
      },
      {
        denominazioneInItaliano: "Viadanica",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L827"
      },
      {
        denominazioneInItaliano: "Vigano San Martino",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L865"
      },
      {
        denominazioneInItaliano: "Vigolo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L894"
      },
      {
        denominazioneInItaliano: "Villa d'Adda",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L929"
      },
      {
        denominazioneInItaliano: "Villa d'Almè",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A215"
      },
      {
        denominazioneInItaliano: "Villa di Serio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L936"
      },
      {
        denominazioneInItaliano: "Villa d'Ogna",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "L938"
      },
      {
        denominazioneInItaliano: "Villongo",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "M045"
      },
      {
        denominazioneInItaliano: "Vilminore di Scalve",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "M050"
      },
      {
        denominazioneInItaliano: "Zandobbio",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "M144"
      },
      {
        denominazioneInItaliano: "Zanica",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "M147"
      },
      {
        denominazioneInItaliano: "Zogno",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "M184"
      },
      {
        denominazioneInItaliano: "Costa Serina",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D111"
      },
      {
        denominazioneInItaliano: "Algua",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "A193"
      },
      {
        denominazioneInItaliano: "Cornalba",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "D016"
      },
      {
        denominazioneInItaliano: "Medolago",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "F085"
      },
      {
        denominazioneInItaliano: "Solza",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "I813"
      },
      {
        denominazioneInItaliano: "Sant'Omobono Terme",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "M333"
      },
      {
        denominazioneInItaliano: "Val Brembilla",
        regione: "Lombardia",
        provincia: "Bergamo",
        codiceCatastale: "M334"
      },
      {
        denominazioneInItaliano: "Acquafredda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A034"
      },
      {
        denominazioneInItaliano: "Adro",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A060"
      },
      {
        denominazioneInItaliano: "Agnosine",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A082"
      },
      {
        denominazioneInItaliano: "Alfianello",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A188"
      },
      {
        denominazioneInItaliano: "Anfo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A288"
      },
      {
        denominazioneInItaliano: "Angolo Terme",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A293"
      },
      {
        denominazioneInItaliano: "Artogne",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A451"
      },
      {
        denominazioneInItaliano: "Azzano Mella",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A529"
      },
      {
        denominazioneInItaliano: "Bagnolo Mella",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A569"
      },
      {
        denominazioneInItaliano: "Bagolino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A578"
      },
      {
        denominazioneInItaliano: "Barbariga",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A630"
      },
      {
        denominazioneInItaliano: "Barghe",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A661"
      },
      {
        denominazioneInItaliano: "Bassano Bresciano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A702"
      },
      {
        denominazioneInItaliano: "Bedizzole",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A729"
      },
      {
        denominazioneInItaliano: "Berlingo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A799"
      },
      {
        denominazioneInItaliano: "Berzo Demo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A816"
      },
      {
        denominazioneInItaliano: "Berzo Inferiore",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A817"
      },
      {
        denominazioneInItaliano: "Bienno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A861"
      },
      {
        denominazioneInItaliano: "Bione",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "A878"
      },
      {
        denominazioneInItaliano: "Borgo San Giacomo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B035"
      },
      {
        denominazioneInItaliano: "Borgosatollo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B040"
      },
      {
        denominazioneInItaliano: "Borno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B054"
      },
      {
        denominazioneInItaliano: "Botticino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B091"
      },
      {
        denominazioneInItaliano: "Bovegno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B100"
      },
      {
        denominazioneInItaliano: "Bovezzo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B102"
      },
      {
        denominazioneInItaliano: "Brandico",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B120"
      },
      {
        denominazioneInItaliano: "Braone",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B124"
      },
      {
        denominazioneInItaliano: "Breno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B149"
      },
      {
        denominazioneInItaliano: "Brescia",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B157"
      },
      {
        denominazioneInItaliano: "Brione",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B184"
      },
      {
        denominazioneInItaliano: "Caino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B365"
      },
      {
        denominazioneInItaliano: "Calcinato",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B394"
      },
      {
        denominazioneInItaliano: "Calvagese della Riviera",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B436"
      },
      {
        denominazioneInItaliano: "Calvisano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B450"
      },
      {
        denominazioneInItaliano: "Capo di Ponte",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B664"
      },
      {
        denominazioneInItaliano: "Capovalle",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B676"
      },
      {
        denominazioneInItaliano: "Capriano del Colle",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B698"
      },
      {
        denominazioneInItaliano: "Capriolo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B711"
      },
      {
        denominazioneInItaliano: "Carpenedolo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "B817"
      },
      {
        denominazioneInItaliano: "Castegnato",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C055"
      },
      {
        denominazioneInItaliano: "Castelcovati",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C072"
      },
      {
        denominazioneInItaliano: "Castel Mella",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C208"
      },
      {
        denominazioneInItaliano: "Castenedolo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C293"
      },
      {
        denominazioneInItaliano: "Casto",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C330"
      },
      {
        denominazioneInItaliano: "Castrezzato",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C332"
      },
      {
        denominazioneInItaliano: "Cazzago San Martino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C408"
      },
      {
        denominazioneInItaliano: "Cedegolo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C417"
      },
      {
        denominazioneInItaliano: "Cellatica",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C439"
      },
      {
        denominazioneInItaliano: "Cerveno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C549"
      },
      {
        denominazioneInItaliano: "Ceto",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C585"
      },
      {
        denominazioneInItaliano: "Cevo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C591"
      },
      {
        denominazioneInItaliano: "Chiari",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C618"
      },
      {
        denominazioneInItaliano: "Cigole",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C685"
      },
      {
        denominazioneInItaliano: "Cimbergo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C691"
      },
      {
        denominazioneInItaliano: "Cividate Camuno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C760"
      },
      {
        denominazioneInItaliano: "Coccaglio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C806"
      },
      {
        denominazioneInItaliano: "Collebeato",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C850"
      },
      {
        denominazioneInItaliano: "Collio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C883"
      },
      {
        denominazioneInItaliano: "Cologne",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C893"
      },
      {
        denominazioneInItaliano: "Comezzano-Cizzago",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C925"
      },
      {
        denominazioneInItaliano: "Concesio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C948"
      },
      {
        denominazioneInItaliano: "Corte Franca",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D058"
      },
      {
        denominazioneInItaliano: "Corteno Golgi",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D064"
      },
      {
        denominazioneInItaliano: "Corzano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D082"
      },
      {
        denominazioneInItaliano: "Darfo Boario Terme",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D251"
      },
      {
        denominazioneInItaliano: "Dello",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D270"
      },
      {
        denominazioneInItaliano: "Desenzano del Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D284"
      },
      {
        denominazioneInItaliano: "Edolo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D391"
      },
      {
        denominazioneInItaliano: "Erbusco",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D421"
      },
      {
        denominazioneInItaliano: "Esine",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D434"
      },
      {
        denominazioneInItaliano: "Fiesse",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D576"
      },
      {
        denominazioneInItaliano: "Flero",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D634"
      },
      {
        denominazioneInItaliano: "Gambara",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D891"
      },
      {
        denominazioneInItaliano: "Gardone Riviera",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D917"
      },
      {
        denominazioneInItaliano: "Gardone Val Trompia",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D918"
      },
      {
        denominazioneInItaliano: "Gargnano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D924"
      },
      {
        denominazioneInItaliano: "Gavardo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D940"
      },
      {
        denominazioneInItaliano: "Ghedi",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "D999"
      },
      {
        denominazioneInItaliano: "Gianico",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E010"
      },
      {
        denominazioneInItaliano: "Gottolengo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E116"
      },
      {
        denominazioneInItaliano: "Gussago",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E271"
      },
      {
        denominazioneInItaliano: "Idro",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E280"
      },
      {
        denominazioneInItaliano: "Incudine",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E297"
      },
      {
        denominazioneInItaliano: "Irma",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E325"
      },
      {
        denominazioneInItaliano: "Iseo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E333"
      },
      {
        denominazioneInItaliano: "Isorella",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E364"
      },
      {
        denominazioneInItaliano: "Lavenone",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E497"
      },
      {
        denominazioneInItaliano: "Leno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E526"
      },
      {
        denominazioneInItaliano: "Limone sul Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E596"
      },
      {
        denominazioneInItaliano: "Lodrino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E652"
      },
      {
        denominazioneInItaliano: "Lograto",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E654"
      },
      {
        denominazioneInItaliano: "Lonato del Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "M312"
      },
      {
        denominazioneInItaliano: "Longhena",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E673"
      },
      {
        denominazioneInItaliano: "Losine",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E698"
      },
      {
        denominazioneInItaliano: "Lozio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E706"
      },
      {
        denominazioneInItaliano: "Lumezzane",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E738"
      },
      {
        denominazioneInItaliano: "Maclodio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E787"
      },
      {
        denominazioneInItaliano: "Magasa",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E800"
      },
      {
        denominazioneInItaliano: "Mairano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E841"
      },
      {
        denominazioneInItaliano: "Malegno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E851"
      },
      {
        denominazioneInItaliano: "Malonno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E865"
      },
      {
        denominazioneInItaliano: "Manerba del Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E883"
      },
      {
        denominazioneInItaliano: "Manerbio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E884"
      },
      {
        denominazioneInItaliano: "Marcheno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E928"
      },
      {
        denominazioneInItaliano: "Marmentino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E961"
      },
      {
        denominazioneInItaliano: "Marone",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "E967"
      },
      {
        denominazioneInItaliano: "Mazzano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F063"
      },
      {
        denominazioneInItaliano: "Milzano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F216"
      },
      {
        denominazioneInItaliano: "Moniga del Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F373"
      },
      {
        denominazioneInItaliano: "Monno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F375"
      },
      {
        denominazioneInItaliano: "Monte Isola",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F532"
      },
      {
        denominazioneInItaliano: "Monticelli Brusati",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F672"
      },
      {
        denominazioneInItaliano: "Montichiari",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F471"
      },
      {
        denominazioneInItaliano: "Montirone",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F680"
      },
      {
        denominazioneInItaliano: "Mura",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F806"
      },
      {
        denominazioneInItaliano: "Muscoline",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F820"
      },
      {
        denominazioneInItaliano: "Nave",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F851"
      },
      {
        denominazioneInItaliano: "Niardo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F884"
      },
      {
        denominazioneInItaliano: "Nuvolento",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F989"
      },
      {
        denominazioneInItaliano: "Nuvolera",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "F990"
      },
      {
        denominazioneInItaliano: "Odolo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G001"
      },
      {
        denominazioneInItaliano: "Offlaga",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G006"
      },
      {
        denominazioneInItaliano: "Ome",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G061"
      },
      {
        denominazioneInItaliano: "Ono San Pietro",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G074"
      },
      {
        denominazioneInItaliano: "Orzinuovi",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G149"
      },
      {
        denominazioneInItaliano: "Orzivecchi",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G150"
      },
      {
        denominazioneInItaliano: "Ospitaletto",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G170"
      },
      {
        denominazioneInItaliano: "Ossimo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G179"
      },
      {
        denominazioneInItaliano: "Padenghe sul Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G213"
      },
      {
        denominazioneInItaliano: "Paderno Franciacorta",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G217"
      },
      {
        denominazioneInItaliano: "Paisco Loveno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G247"
      },
      {
        denominazioneInItaliano: "Paitone",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G248"
      },
      {
        denominazioneInItaliano: "Palazzolo sull'Oglio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G264"
      },
      {
        denominazioneInItaliano: "Paratico",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G327"
      },
      {
        denominazioneInItaliano: "Paspardo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G354"
      },
      {
        denominazioneInItaliano: "Passirano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G361"
      },
      {
        denominazioneInItaliano: "Pavone del Mella",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G391"
      },
      {
        denominazioneInItaliano: "San Paolo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G407"
      },
      {
        denominazioneInItaliano: "Pertica Alta",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G474"
      },
      {
        denominazioneInItaliano: "Pertica Bassa",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G475"
      },
      {
        denominazioneInItaliano: "Pezzaze",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G529"
      },
      {
        denominazioneInItaliano: "Pian Camuno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G546"
      },
      {
        denominazioneInItaliano: "Pisogne",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G710"
      },
      {
        denominazioneInItaliano: "Polaveno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G779"
      },
      {
        denominazioneInItaliano: "Polpenazze del Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G801"
      },
      {
        denominazioneInItaliano: "Pompiano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G815"
      },
      {
        denominazioneInItaliano: "Poncarale",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G818"
      },
      {
        denominazioneInItaliano: "Ponte di Legno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G844"
      },
      {
        denominazioneInItaliano: "Pontevico",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G859"
      },
      {
        denominazioneInItaliano: "Pontoglio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G869"
      },
      {
        denominazioneInItaliano: "Pozzolengo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G959"
      },
      {
        denominazioneInItaliano: "Pralboino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G977"
      },
      {
        denominazioneInItaliano: "Preseglie",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H043"
      },
      {
        denominazioneInItaliano: "Prevalle",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H055"
      },
      {
        denominazioneInItaliano: "Provaglio d'Iseo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H078"
      },
      {
        denominazioneInItaliano: "Provaglio Val Sabbia",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H077"
      },
      {
        denominazioneInItaliano: "Puegnago del Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H086"
      },
      {
        denominazioneInItaliano: "Quinzano d'Oglio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H140"
      },
      {
        denominazioneInItaliano: "Remedello",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H230"
      },
      {
        denominazioneInItaliano: "Rezzato",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H256"
      },
      {
        denominazioneInItaliano: "Roccafranca",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H410"
      },
      {
        denominazioneInItaliano: "Rodengo Saiano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H477"
      },
      {
        denominazioneInItaliano: "Roè Volciano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H484"
      },
      {
        denominazioneInItaliano: "Roncadelle",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H525"
      },
      {
        denominazioneInItaliano: "Rovato",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H598"
      },
      {
        denominazioneInItaliano: "Rudiano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H630"
      },
      {
        denominazioneInItaliano: "Sabbio Chiese",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H650"
      },
      {
        denominazioneInItaliano: "Sale Marasino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H699"
      },
      {
        denominazioneInItaliano: "Salò",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H717"
      },
      {
        denominazioneInItaliano: "San Felice del Benaco",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H838"
      },
      {
        denominazioneInItaliano: "San Gervasio Bresciano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "H865"
      },
      {
        denominazioneInItaliano: "San Zeno Naviglio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I412"
      },
      {
        denominazioneInItaliano: "Sarezzo",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I433"
      },
      {
        denominazioneInItaliano: "Saviore dell'Adamello",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I476"
      },
      {
        denominazioneInItaliano: "Sellero",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I588"
      },
      {
        denominazioneInItaliano: "Seniga",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I607"
      },
      {
        denominazioneInItaliano: "Serle",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I631"
      },
      {
        denominazioneInItaliano: "Sirmione",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I633"
      },
      {
        denominazioneInItaliano: "Soiano del Lago",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I782"
      },
      {
        denominazioneInItaliano: "Sonico",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "I831"
      },
      {
        denominazioneInItaliano: "Sulzano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L002"
      },
      {
        denominazioneInItaliano: "Tavernole sul Mella",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "C698"
      },
      {
        denominazioneInItaliano: "Temù",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L094"
      },
      {
        denominazioneInItaliano: "Tignale",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L169"
      },
      {
        denominazioneInItaliano: "Torbole Casaglia",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L210"
      },
      {
        denominazioneInItaliano: "Toscolano-Maderno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L312"
      },
      {
        denominazioneInItaliano: "Travagliato",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L339"
      },
      {
        denominazioneInItaliano: "Tremosine sul Garda",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L372"
      },
      {
        denominazioneInItaliano: "Trenzano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L380"
      },
      {
        denominazioneInItaliano: "Treviso Bresciano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L406"
      },
      {
        denominazioneInItaliano: "Urago d'Oglio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L494"
      },
      {
        denominazioneInItaliano: "Vallio Terme",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L626"
      },
      {
        denominazioneInItaliano: "Valvestino",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L468"
      },
      {
        denominazioneInItaliano: "Verolanuova",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L777"
      },
      {
        denominazioneInItaliano: "Verolavecchia",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L778"
      },
      {
        denominazioneInItaliano: "Vestone",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L812"
      },
      {
        denominazioneInItaliano: "Vezza d'Oglio",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L816"
      },
      {
        denominazioneInItaliano: "Villa Carcina",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L919"
      },
      {
        denominazioneInItaliano: "Villachiara",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L923"
      },
      {
        denominazioneInItaliano: "Villanuova sul Clisi",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "L995"
      },
      {
        denominazioneInItaliano: "Vione",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "M065"
      },
      {
        denominazioneInItaliano: "Visano",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "M070"
      },
      {
        denominazioneInItaliano: "Vobarno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "M104"
      },
      {
        denominazioneInItaliano: "Zone",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "M188"
      },
      {
        denominazioneInItaliano: "Piancogno",
        regione: "Lombardia",
        provincia: "Brescia",
        codiceCatastale: "G549"
      },
      {
        denominazioneInItaliano: "Alagna",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A118"
      },
      {
        denominazioneInItaliano: "Albaredo Arnaboldi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A134"
      },
      {
        denominazioneInItaliano: "Albonese",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A171"
      },
      {
        denominazioneInItaliano: "Albuzzano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A175"
      },
      {
        denominazioneInItaliano: "Arena Po",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A387"
      },
      {
        denominazioneInItaliano: "Badia Pavese",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A538"
      },
      {
        denominazioneInItaliano: "Bagnaria",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A550"
      },
      {
        denominazioneInItaliano: "Barbianello",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A634"
      },
      {
        denominazioneInItaliano: "Bascapè",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A690"
      },
      {
        denominazioneInItaliano: "Bastida Pancarana",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A712"
      },
      {
        denominazioneInItaliano: "Battuda",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A718"
      },
      {
        denominazioneInItaliano: "Belgioioso",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A741"
      },
      {
        denominazioneInItaliano: "Bereguardo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A792"
      },
      {
        denominazioneInItaliano: "Borgarello",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "A989"
      },
      {
        denominazioneInItaliano: "Borgo Priolo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B028"
      },
      {
        denominazioneInItaliano: "Borgoratto Mormorolo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B030"
      },
      {
        denominazioneInItaliano: "Borgo San Siro",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B038"
      },
      {
        denominazioneInItaliano: "Bornasco",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B051"
      },
      {
        denominazioneInItaliano: "Bosnasco",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B082"
      },
      {
        denominazioneInItaliano: "Brallo di Pregola",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B117"
      },
      {
        denominazioneInItaliano: "Breme",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B142"
      },
      {
        denominazioneInItaliano: "Bressana Bottarone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B159"
      },
      {
        denominazioneInItaliano: "Broni",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B201"
      },
      {
        denominazioneInItaliano: "Calvignano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B447"
      },
      {
        denominazioneInItaliano: "Campospinoso",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B567"
      },
      {
        denominazioneInItaliano: "Candia Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B587"
      },
      {
        denominazioneInItaliano: "Canneto Pavese",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B613"
      },
      {
        denominazioneInItaliano: "Carbonara al Ticino",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B741"
      },
      {
        denominazioneInItaliano: "Casanova Lonati",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B929"
      },
      {
        denominazioneInItaliano: "Casatisma",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B945"
      },
      {
        denominazioneInItaliano: "Casei Gerola",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B954"
      },
      {
        denominazioneInItaliano: "Casorate Primo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B988"
      },
      {
        denominazioneInItaliano: "Cassolnovo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C038"
      },
      {
        denominazioneInItaliano: "Castana",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C050"
      },
      {
        denominazioneInItaliano: "Casteggio",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C053"
      },
      {
        denominazioneInItaliano: "Castelletto di Branduzzo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C157"
      },
      {
        denominazioneInItaliano: "Castello d'Agogna",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C184"
      },
      {
        denominazioneInItaliano: "Castelnovetto",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C213"
      },
      {
        denominazioneInItaliano: "Cava Manara",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C360"
      },
      {
        denominazioneInItaliano: "Cecima",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C414"
      },
      {
        denominazioneInItaliano: "Ceranova",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C484"
      },
      {
        denominazioneInItaliano: "Ceretto Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C508"
      },
      {
        denominazioneInItaliano: "Cergnago",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C509"
      },
      {
        denominazioneInItaliano: "Certosa di Pavia",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C541"
      },
      {
        denominazioneInItaliano: "Cervesina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C551"
      },
      {
        denominazioneInItaliano: "Chignolo Po",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C637"
      },
      {
        denominazioneInItaliano: "Cigognola",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C684"
      },
      {
        denominazioneInItaliano: "Cilavegna",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C686"
      },
      {
        denominazioneInItaliano: "Codevilla",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C813"
      },
      {
        denominazioneInItaliano: "Confienza",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C958"
      },
      {
        denominazioneInItaliano: "Copiano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C979"
      },
      {
        denominazioneInItaliano: "Corana",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "C982"
      },
      {
        denominazioneInItaliano: "Corvino San Quirico",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D081"
      },
      {
        denominazioneInItaliano: "Costa de' Nobili",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D109"
      },
      {
        denominazioneInItaliano: "Cozzo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D127"
      },
      {
        denominazioneInItaliano: "Cura Carpignano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B824"
      },
      {
        denominazioneInItaliano: "Dorno",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D348"
      },
      {
        denominazioneInItaliano: "Ferrera Erbognone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D552"
      },
      {
        denominazioneInItaliano: "Filighera",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D594"
      },
      {
        denominazioneInItaliano: "Fortunago",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D732"
      },
      {
        denominazioneInItaliano: "Frascarolo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D771"
      },
      {
        denominazioneInItaliano: "Galliavola",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D873"
      },
      {
        denominazioneInItaliano: "Gambarana",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D892"
      },
      {
        denominazioneInItaliano: "Gambolò",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D901"
      },
      {
        denominazioneInItaliano: "Garlasco",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D925"
      },
      {
        denominazioneInItaliano: "Gerenzago",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "D980"
      },
      {
        denominazioneInItaliano: "Giussago",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E062"
      },
      {
        denominazioneInItaliano: "Godiasco Salice Terme",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E072"
      },
      {
        denominazioneInItaliano: "Golferenzo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E081"
      },
      {
        denominazioneInItaliano: "Gravellona Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E152"
      },
      {
        denominazioneInItaliano: "Gropello Cairoli",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E195"
      },
      {
        denominazioneInItaliano: "Inverno e Monteleone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E310"
      },
      {
        denominazioneInItaliano: "Landriano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E437"
      },
      {
        denominazioneInItaliano: "Langosco",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E439"
      },
      {
        denominazioneInItaliano: "Lardirago",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E454"
      },
      {
        denominazioneInItaliano: "Linarolo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E600"
      },
      {
        denominazioneInItaliano: "Lirio",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E608"
      },
      {
        denominazioneInItaliano: "Lomello",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E662"
      },
      {
        denominazioneInItaliano: "Lungavilla",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B387"
      },
      {
        denominazioneInItaliano: "Magherno",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E804"
      },
      {
        denominazioneInItaliano: "Marcignago",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E934"
      },
      {
        denominazioneInItaliano: "Marzano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E999"
      },
      {
        denominazioneInItaliano: "Mede",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F080"
      },
      {
        denominazioneInItaliano: "Menconico",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F122"
      },
      {
        denominazioneInItaliano: "Mezzana Bigli",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F170"
      },
      {
        denominazioneInItaliano: "Mezzana Rabattone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F171"
      },
      {
        denominazioneInItaliano: "Mezzanino",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F175"
      },
      {
        denominazioneInItaliano: "Miradolo Terme",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F238"
      },
      {
        denominazioneInItaliano: "Montalto Pavese",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F417"
      },
      {
        denominazioneInItaliano: "Montebello della Battaglia",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F440"
      },
      {
        denominazioneInItaliano: "Montecalvo Versiggia",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F449"
      },
      {
        denominazioneInItaliano: "Montescano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F638"
      },
      {
        denominazioneInItaliano: "Montesegale",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F644"
      },
      {
        denominazioneInItaliano: "Monticelli Pavese",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F670"
      },
      {
        denominazioneInItaliano: "Montù Beccaria",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F701"
      },
      {
        denominazioneInItaliano: "Mornico Losana",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F739"
      },
      {
        denominazioneInItaliano: "Mortara",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F754"
      },
      {
        denominazioneInItaliano: "Nicorvo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "F891"
      },
      {
        denominazioneInItaliano: "Olevano di Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G021"
      },
      {
        denominazioneInItaliano: "Oliva Gessi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G032"
      },
      {
        denominazioneInItaliano: "Ottobiano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G194"
      },
      {
        denominazioneInItaliano: "Palestro",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G275"
      },
      {
        denominazioneInItaliano: "Pancarana",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G304"
      },
      {
        denominazioneInItaliano: "Parona",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G342"
      },
      {
        denominazioneInItaliano: "Pavia",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G388"
      },
      {
        denominazioneInItaliano: "Pietra de' Giorgi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G612"
      },
      {
        denominazioneInItaliano: "Pieve Albignola",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G635"
      },
      {
        denominazioneInItaliano: "Pieve del Cairo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G639"
      },
      {
        denominazioneInItaliano: "Pieve Porto Morone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G650"
      },
      {
        denominazioneInItaliano: "Pinarolo Po",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G671"
      },
      {
        denominazioneInItaliano: "Pizzale",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G720"
      },
      {
        denominazioneInItaliano: "Ponte Nizza",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G851"
      },
      {
        denominazioneInItaliano: "Portalbera",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "G895"
      },
      {
        denominazioneInItaliano: "Rea",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H204"
      },
      {
        denominazioneInItaliano: "Redavalle",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H216"
      },
      {
        denominazioneInItaliano: "Retorbido",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H246"
      },
      {
        denominazioneInItaliano: "Rivanazzano Terme",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H336"
      },
      {
        denominazioneInItaliano: "Robbio",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H369"
      },
      {
        denominazioneInItaliano: "Robecco Pavese",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H375"
      },
      {
        denominazioneInItaliano: "Rocca de' Giorgi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H396"
      },
      {
        denominazioneInItaliano: "Rocca Susella",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H450"
      },
      {
        denominazioneInItaliano: "Rognano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H491"
      },
      {
        denominazioneInItaliano: "Romagnese",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H505"
      },
      {
        denominazioneInItaliano: "Roncaro",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H527"
      },
      {
        denominazioneInItaliano: "Rosasco",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H559"
      },
      {
        denominazioneInItaliano: "Rovescala",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H614"
      },
      {
        denominazioneInItaliano: "San Cipriano Po",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H799"
      },
      {
        denominazioneInItaliano: "San Damiano al Colle",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H814"
      },
      {
        denominazioneInItaliano: "San Genesio ed Uniti",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H859"
      },
      {
        denominazioneInItaliano: "San Giorgio di Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "H885"
      },
      {
        denominazioneInItaliano: "San Martino Siccomario",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I014"
      },
      {
        denominazioneInItaliano: "Sannazzaro de' Burgondi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I048"
      },
      {
        denominazioneInItaliano: "Santa Cristina e Bissone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I175"
      },
      {
        denominazioneInItaliano: "Santa Giuletta",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I203"
      },
      {
        denominazioneInItaliano: "Sant'Alessio con Vialone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I213"
      },
      {
        denominazioneInItaliano: "Santa Margherita di Staffora",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I230"
      },
      {
        denominazioneInItaliano: "Santa Maria della Versa",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I237"
      },
      {
        denominazioneInItaliano: "Sant'Angelo Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I276"
      },
      {
        denominazioneInItaliano: "San Zenone al Po",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I416"
      },
      {
        denominazioneInItaliano: "Sartirana Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I447"
      },
      {
        denominazioneInItaliano: "Scaldasole",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I487"
      },
      {
        denominazioneInItaliano: "Semiana",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I599"
      },
      {
        denominazioneInItaliano: "Silvano Pietra",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I739"
      },
      {
        denominazioneInItaliano: "Siziano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "E265"
      },
      {
        denominazioneInItaliano: "Sommo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I825"
      },
      {
        denominazioneInItaliano: "Spessa",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I894"
      },
      {
        denominazioneInItaliano: "Stradella",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I968"
      },
      {
        denominazioneInItaliano: "Suardi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "B014"
      },
      {
        denominazioneInItaliano: "Torrazza Coste",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L237"
      },
      {
        denominazioneInItaliano: "Torre Beretti e Castellaro",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L250"
      },
      {
        denominazioneInItaliano: "Torre d'Arese",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L256"
      },
      {
        denominazioneInItaliano: "Torre de' Negri",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L262"
      },
      {
        denominazioneInItaliano: "Torre d'Isola",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L269"
      },
      {
        denominazioneInItaliano: "Torrevecchia Pia",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L285"
      },
      {
        denominazioneInItaliano: "Torricella Verzate",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L292"
      },
      {
        denominazioneInItaliano: "Travacò Siccomario",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "I236"
      },
      {
        denominazioneInItaliano: "Trivolzio",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L440"
      },
      {
        denominazioneInItaliano: "Tromello",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L449"
      },
      {
        denominazioneInItaliano: "Trovo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L453"
      },
      {
        denominazioneInItaliano: "Val di Nizza",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L562"
      },
      {
        denominazioneInItaliano: "Valeggio",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L568"
      },
      {
        denominazioneInItaliano: "Valle Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L593"
      },
      {
        denominazioneInItaliano: "Valle Salimbene",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L617"
      },
      {
        denominazioneInItaliano: "Varzi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L690"
      },
      {
        denominazioneInItaliano: "Velezzo Lomellina",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L716"
      },
      {
        denominazioneInItaliano: "Vellezzo Bellini",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L720"
      },
      {
        denominazioneInItaliano: "Verretto",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L784"
      },
      {
        denominazioneInItaliano: "Verrua Po",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L788"
      },
      {
        denominazioneInItaliano: "Vidigulfo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L854"
      },
      {
        denominazioneInItaliano: "Vigevano",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L872"
      },
      {
        denominazioneInItaliano: "Villa Biscossi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L917"
      },
      {
        denominazioneInItaliano: "Villanova d'Ardenghi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L983"
      },
      {
        denominazioneInItaliano: "Villanterio",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "L994"
      },
      {
        denominazioneInItaliano: "Vistarino",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M079"
      },
      {
        denominazioneInItaliano: "Voghera",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M109"
      },
      {
        denominazioneInItaliano: "Volpara",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M119"
      },
      {
        denominazioneInItaliano: "Zavattarello",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M150"
      },
      {
        denominazioneInItaliano: "Zeccone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M152"
      },
      {
        denominazioneInItaliano: "Zeme",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M161"
      },
      {
        denominazioneInItaliano: "Zenevredo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M162"
      },
      {
        denominazioneInItaliano: "Zerbo",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M166"
      },
      {
        denominazioneInItaliano: "Zerbolò",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M167"
      },
      {
        denominazioneInItaliano: "Zinasco",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M180"
      },
      {
        denominazioneInItaliano: "Cornale e Bastida",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M338"
      },
      {
        denominazioneInItaliano: "Corteolona e Genzone",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M372"
      },
      {
        denominazioneInItaliano: "Colli Verdi",
        regione: "Lombardia",
        provincia: "Pavia",
        codiceCatastale: "M419"
      },
      {
        denominazioneInItaliano: "Acquanegra Cremonese",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "A039"
      },
      {
        denominazioneInItaliano: "Agnadello",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "A076"
      },
      {
        denominazioneInItaliano: "Annicco",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "A299"
      },
      {
        denominazioneInItaliano: "Azzanello",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "A526"
      },
      {
        denominazioneInItaliano: "Bagnolo Cremasco",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "A570"
      },
      {
        denominazioneInItaliano: "Bonemerse",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "A972"
      },
      {
        denominazioneInItaliano: "Bordolano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "A986"
      },
      {
        denominazioneInItaliano: "Calvatone",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B439"
      },
      {
        denominazioneInItaliano: "Camisano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B484"
      },
      {
        denominazioneInItaliano: "Campagnola Cremasca",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B498"
      },
      {
        denominazioneInItaliano: "Capergnanica",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B650"
      },
      {
        denominazioneInItaliano: "Cappella Cantone",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B679"
      },
      {
        denominazioneInItaliano: "Cappella de' Picenardi",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B680"
      },
      {
        denominazioneInItaliano: "Capralba",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B686"
      },
      {
        denominazioneInItaliano: "Casalbuttano ed Uniti",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B869"
      },
      {
        denominazioneInItaliano: "Casale Cremasco-Vidolasco",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B881"
      },
      {
        denominazioneInItaliano: "Casaletto Ceredano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B889"
      },
      {
        denominazioneInItaliano: "Casaletto di Sopra",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B890"
      },
      {
        denominazioneInItaliano: "Casaletto Vaprio",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B891"
      },
      {
        denominazioneInItaliano: "Casalmaggiore",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B898"
      },
      {
        denominazioneInItaliano: "Casalmorano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B900"
      },
      {
        denominazioneInItaliano: "Casteldidone",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "C089"
      },
      {
        denominazioneInItaliano: "Castel Gabbiano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "C115"
      },
      {
        denominazioneInItaliano: "Castelleone",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "C153"
      },
      {
        denominazioneInItaliano: "Castelverde",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B129"
      },
      {
        denominazioneInItaliano: "Castelvisconti",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "C290"
      },
      {
        denominazioneInItaliano: "Cella Dati",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "C435"
      },
      {
        denominazioneInItaliano: "Chieve",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "C634"
      },
      {
        denominazioneInItaliano: "Cicognolo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "C678"
      },
      {
        denominazioneInItaliano: "Cingia de' Botti",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "C703"
      },
      {
        denominazioneInItaliano: "Corte de' Cortesi con Cignone",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D056"
      },
      {
        denominazioneInItaliano: "Corte de' Frati",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D057"
      },
      {
        denominazioneInItaliano: "Credera Rubbiano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D141"
      },
      {
        denominazioneInItaliano: "Crema",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D142"
      },
      {
        denominazioneInItaliano: "Cremona",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D150"
      },
      {
        denominazioneInItaliano: "Cremosano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D151"
      },
      {
        denominazioneInItaliano: "Crotta d'Adda",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D186"
      },
      {
        denominazioneInItaliano: "Cumignano sul Naviglio",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D203"
      },
      {
        denominazioneInItaliano: "Derovere",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D278"
      },
      {
        denominazioneInItaliano: "Dovera",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D358"
      },
      {
        denominazioneInItaliano: "Fiesco",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D574"
      },
      {
        denominazioneInItaliano: "Formigara",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D710"
      },
      {
        denominazioneInItaliano: "Gabbioneta-Binanuova",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D834"
      },
      {
        denominazioneInItaliano: "Gadesco-Pieve Delmona",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D841"
      },
      {
        denominazioneInItaliano: "Genivolta",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D966"
      },
      {
        denominazioneInItaliano: "Gerre de' Caprioli",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "D993"
      },
      {
        denominazioneInItaliano: "Gombito",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E082"
      },
      {
        denominazioneInItaliano: "Grontardo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E193"
      },
      {
        denominazioneInItaliano: "Grumello Cremonese ed Uniti",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E217"
      },
      {
        denominazioneInItaliano: "Gussola",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E272"
      },
      {
        denominazioneInItaliano: "Isola Dovarese",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E356"
      },
      {
        denominazioneInItaliano: "Izano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E380"
      },
      {
        denominazioneInItaliano: "Madignano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E793"
      },
      {
        denominazioneInItaliano: "Malagnino",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E843"
      },
      {
        denominazioneInItaliano: "Martignana di Po",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "E983"
      },
      {
        denominazioneInItaliano: "Monte Cremasco",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "F434"
      },
      {
        denominazioneInItaliano: "Montodine",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "F681"
      },
      {
        denominazioneInItaliano: "Moscazzano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "F761"
      },
      {
        denominazioneInItaliano: "Motta Baluffi",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "F771"
      },
      {
        denominazioneInItaliano: "Offanengo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G004"
      },
      {
        denominazioneInItaliano: "Olmeneta",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G047"
      },
      {
        denominazioneInItaliano: "Ostiano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G185"
      },
      {
        denominazioneInItaliano: "Paderno Ponchielli",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G222"
      },
      {
        denominazioneInItaliano: "Palazzo Pignano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G260"
      },
      {
        denominazioneInItaliano: "Pandino",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G306"
      },
      {
        denominazioneInItaliano: "Persico Dosimo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G469"
      },
      {
        denominazioneInItaliano: "Pescarolo ed Uniti",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G483"
      },
      {
        denominazioneInItaliano: "Pessina Cremonese",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G504"
      },
      {
        denominazioneInItaliano: "Pianengo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G558"
      },
      {
        denominazioneInItaliano: "Pieranica",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G603"
      },
      {
        denominazioneInItaliano: "Pieve d'Olmi",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G647"
      },
      {
        denominazioneInItaliano: "Pieve San Giacomo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G651"
      },
      {
        denominazioneInItaliano: "Pizzighettone",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "G721"
      },
      {
        denominazioneInItaliano: "Pozzaglio ed Uniti",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "B914"
      },
      {
        denominazioneInItaliano: "Quintano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H130"
      },
      {
        denominazioneInItaliano: "Ricengo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H276"
      },
      {
        denominazioneInItaliano: "Ripalta Arpina",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H314"
      },
      {
        denominazioneInItaliano: "Ripalta Cremasca",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H315"
      },
      {
        denominazioneInItaliano: "Ripalta Guerina",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H316"
      },
      {
        denominazioneInItaliano: "Rivarolo del Re ed Uniti",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H341"
      },
      {
        denominazioneInItaliano: "Rivolta d'Adda",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H357"
      },
      {
        denominazioneInItaliano: "Robecco d'Oglio",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H372"
      },
      {
        denominazioneInItaliano: "Romanengo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H508"
      },
      {
        denominazioneInItaliano: "Salvirola",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H731"
      },
      {
        denominazioneInItaliano: "San Bassano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H767"
      },
      {
        denominazioneInItaliano: "San Daniele Po",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H815"
      },
      {
        denominazioneInItaliano: "San Giovanni in Croce",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "H918"
      },
      {
        denominazioneInItaliano: "San Martino del Lago",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I007"
      },
      {
        denominazioneInItaliano: "Scandolara Ravara",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I497"
      },
      {
        denominazioneInItaliano: "Scandolara Ripa d'Oglio",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I498"
      },
      {
        denominazioneInItaliano: "Sergnano",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I627"
      },
      {
        denominazioneInItaliano: "Sesto ed Uniti",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I683"
      },
      {
        denominazioneInItaliano: "Solarolo Rainerio",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I790"
      },
      {
        denominazioneInItaliano: "Soncino",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I827"
      },
      {
        denominazioneInItaliano: "Soresina",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I849"
      },
      {
        denominazioneInItaliano: "Sospiro",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I865"
      },
      {
        denominazioneInItaliano: "Spinadesco",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I906"
      },
      {
        denominazioneInItaliano: "Spineda",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I909"
      },
      {
        denominazioneInItaliano: "Spino d'Adda",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I914"
      },
      {
        denominazioneInItaliano: "Stagno Lombardo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "I935"
      },
      {
        denominazioneInItaliano: "Ticengo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L164"
      },
      {
        denominazioneInItaliano: "Torlino Vimercati",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L221"
      },
      {
        denominazioneInItaliano: "Tornata",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L225"
      },
      {
        denominazioneInItaliano: "Torre de' Picenardi",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L258"
      },
      {
        denominazioneInItaliano: "Torricella del Pizzo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L296"
      },
      {
        denominazioneInItaliano: "Trescore Cremasco",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L389"
      },
      {
        denominazioneInItaliano: "Trigolo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L426"
      },
      {
        denominazioneInItaliano: "Vaiano Cremasco",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L535"
      },
      {
        denominazioneInItaliano: "Vailate",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L539"
      },
      {
        denominazioneInItaliano: "Vescovato",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "L806"
      },
      {
        denominazioneInItaliano: "Volongo",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "M116"
      },
      {
        denominazioneInItaliano: "Voltido",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "M127"
      },
      {
        denominazioneInItaliano: "Piadena Drizzona",
        regione: "Lombardia",
        provincia: "Cremona",
        codiceCatastale: "M418"
      },
      {
        denominazioneInItaliano: "Acquanegra sul Chiese",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "A038"
      },
      {
        denominazioneInItaliano: "Asola",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "A470"
      },
      {
        denominazioneInItaliano: "Bagnolo San Vito",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "A575"
      },
      {
        denominazioneInItaliano: "Bozzolo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "B110"
      },
      {
        denominazioneInItaliano: "Canneto sull'Oglio",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "B612"
      },
      {
        denominazioneInItaliano: "Casalmoro",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "B901"
      },
      {
        denominazioneInItaliano: "Casaloldo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "B907"
      },
      {
        denominazioneInItaliano: "Casalromano",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "B911"
      },
      {
        denominazioneInItaliano: "Castelbelforte",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "C059"
      },
      {
        denominazioneInItaliano: "Castel d'Ario",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "C076"
      },
      {
        denominazioneInItaliano: "Castel Goffredo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "C118"
      },
      {
        denominazioneInItaliano: "Castellucchio",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "C195"
      },
      {
        denominazioneInItaliano: "Castiglione delle Stiviere",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "C312"
      },
      {
        denominazioneInItaliano: "Cavriana",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "C406"
      },
      {
        denominazioneInItaliano: "Ceresara",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "C502"
      },
      {
        denominazioneInItaliano: "Commessaggio",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "C930"
      },
      {
        denominazioneInItaliano: "Curtatone",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "D227"
      },
      {
        denominazioneInItaliano: "Dosolo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "D351"
      },
      {
        denominazioneInItaliano: "Gazoldo degli Ippoliti",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "D949"
      },
      {
        denominazioneInItaliano: "Gazzuolo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "D959"
      },
      {
        denominazioneInItaliano: "Goito",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "E078"
      },
      {
        denominazioneInItaliano: "Gonzaga",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "E089"
      },
      {
        denominazioneInItaliano: "Guidizzolo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "E261"
      },
      {
        denominazioneInItaliano: "Magnacavallo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "E818"
      },
      {
        denominazioneInItaliano: "Mantova",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "E897"
      },
      {
        denominazioneInItaliano: "Marcaria",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "E922"
      },
      {
        denominazioneInItaliano: "Mariana Mantovana",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "E949"
      },
      {
        denominazioneInItaliano: "Marmirolo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "E962"
      },
      {
        denominazioneInItaliano: "Medole",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "F086"
      },
      {
        denominazioneInItaliano: "Moglia",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "F267"
      },
      {
        denominazioneInItaliano: "Monzambano",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "F705"
      },
      {
        denominazioneInItaliano: "Motteggiana",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "B012"
      },
      {
        denominazioneInItaliano: "Ostiglia",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "G186"
      },
      {
        denominazioneInItaliano: "Pegognaga",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "G417"
      },
      {
        denominazioneInItaliano: "Piubega",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "G717"
      },
      {
        denominazioneInItaliano: "Poggio Rusco",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "G753"
      },
      {
        denominazioneInItaliano: "Pomponesco",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "G816"
      },
      {
        denominazioneInItaliano: "Ponti sul Mincio",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "G862"
      },
      {
        denominazioneInItaliano: "Porto Mantovano",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "G917"
      },
      {
        denominazioneInItaliano: "Quingentole",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H129"
      },
      {
        denominazioneInItaliano: "Quistello",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H143"
      },
      {
        denominazioneInItaliano: "Redondesco",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H218"
      },
      {
        denominazioneInItaliano: "Rivarolo Mantovano",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H342"
      },
      {
        denominazioneInItaliano: "Rodigo",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H481"
      },
      {
        denominazioneInItaliano: "Roncoferraro",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H541"
      },
      {
        denominazioneInItaliano: "Roverbella",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H604"
      },
      {
        denominazioneInItaliano: "Sabbioneta",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H652"
      },
      {
        denominazioneInItaliano: "San Benedetto Po",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H771"
      },
      {
        denominazioneInItaliano: "San Giacomo delle Segnate",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H870"
      },
      {
        denominazioneInItaliano: "San Giorgio Bigarello",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H883"
      },
      {
        denominazioneInItaliano: "San Giovanni del Dosso",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "H912"
      },
      {
        denominazioneInItaliano: "San Martino dall'Argine",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "I005"
      },
      {
        denominazioneInItaliano: "Schivenoglia",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "I532"
      },
      {
        denominazioneInItaliano: "Sermide e Felonica",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "I632"
      },
      {
        denominazioneInItaliano: "Serravalle a Po",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "I662"
      },
      {
        denominazioneInItaliano: "Solferino",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "I801"
      },
      {
        denominazioneInItaliano: "Sustinente",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "L015"
      },
      {
        denominazioneInItaliano: "Suzzara",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "L020"
      },
      {
        denominazioneInItaliano: "Viadana",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "L826"
      },
      {
        denominazioneInItaliano: "Villimpenta",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "M044"
      },
      {
        denominazioneInItaliano: "Volta Mantovana",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "M125"
      },
      {
        denominazioneInItaliano: "Borgo Virgilio",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "M340"
      },
      {
        denominazioneInItaliano: "Borgo Mantovano",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "M396"
      },
      {
        denominazioneInItaliano: "Borgocarbonara",
        regione: "Lombardia",
        provincia: "Mantova",
        codiceCatastale: "M406"
      },
      {
        denominazioneInItaliano: "Abbadia Lariana",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "A005"
      },
      {
        denominazioneInItaliano: "Airuno",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "A112"
      },
      {
        denominazioneInItaliano: "Annone di Brianza",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "A301"
      },
      {
        denominazioneInItaliano: "Ballabio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "A594"
      },
      {
        denominazioneInItaliano: "Barzago",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "A683"
      },
      {
        denominazioneInItaliano: "Barzanò",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "A686"
      },
      {
        denominazioneInItaliano: "Barzio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "A687"
      },
      {
        denominazioneInItaliano: "Bellano",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "A745"
      },
      {
        denominazioneInItaliano: "Bosisio Parini",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B081"
      },
      {
        denominazioneInItaliano: "Brivio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B194"
      },
      {
        denominazioneInItaliano: "Bulciago",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B261"
      },
      {
        denominazioneInItaliano: "Calco",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B396"
      },
      {
        denominazioneInItaliano: "Calolziocorte",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B423"
      },
      {
        denominazioneInItaliano: "Carenno",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B763"
      },
      {
        denominazioneInItaliano: "Casargo",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B937"
      },
      {
        denominazioneInItaliano: "Casatenovo",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B943"
      },
      {
        denominazioneInItaliano: "Cassago Brianza",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "B996"
      },
      {
        denominazioneInItaliano: "Cassina Valsassina",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "C024"
      },
      {
        denominazioneInItaliano: "Castello di Brianza",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "C187"
      },
      {
        denominazioneInItaliano: "Cernusco Lombardone",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "C521"
      },
      {
        denominazioneInItaliano: "Cesana Brianza",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "C563"
      },
      {
        denominazioneInItaliano: "Civate",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "C752"
      },
      {
        denominazioneInItaliano: "Colico",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "C839"
      },
      {
        denominazioneInItaliano: "Colle Brianza",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "C851"
      },
      {
        denominazioneInItaliano: "Cortenova",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D065"
      },
      {
        denominazioneInItaliano: "Costa Masnaga",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D112"
      },
      {
        denominazioneInItaliano: "Crandola Valsassina",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D131"
      },
      {
        denominazioneInItaliano: "Cremella",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D143"
      },
      {
        denominazioneInItaliano: "Cremeno",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D145"
      },
      {
        denominazioneInItaliano: "Dervio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D280"
      },
      {
        denominazioneInItaliano: "Dolzago",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D327"
      },
      {
        denominazioneInItaliano: "Dorio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D346"
      },
      {
        denominazioneInItaliano: "Ello",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D398"
      },
      {
        denominazioneInItaliano: "Erve",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D428"
      },
      {
        denominazioneInItaliano: "Esino Lario",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D436"
      },
      {
        denominazioneInItaliano: "Galbiate",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D865"
      },
      {
        denominazioneInItaliano: "Garbagnate Monastero",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D913"
      },
      {
        denominazioneInItaliano: "Garlate",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "D926"
      },
      {
        denominazioneInItaliano: "Imbersago",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "E287"
      },
      {
        denominazioneInItaliano: "Introbio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "E305"
      },
      {
        denominazioneInItaliano: "Lecco",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "E507"
      },
      {
        denominazioneInItaliano: "Lierna",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "E581"
      },
      {
        denominazioneInItaliano: "Lomagna",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "E656"
      },
      {
        denominazioneInItaliano: "Malgrate",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "E858"
      },
      {
        denominazioneInItaliano: "Mandello del Lario",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "E879"
      },
      {
        denominazioneInItaliano: "Margno",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "E947"
      },
      {
        denominazioneInItaliano: "Merate",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F133"
      },
      {
        denominazioneInItaliano: "Missaglia",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F248"
      },
      {
        denominazioneInItaliano: "Moggio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F265"
      },
      {
        denominazioneInItaliano: "Molteno",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F304"
      },
      {
        denominazioneInItaliano: "Monte Marenzo",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F561"
      },
      {
        denominazioneInItaliano: "Montevecchia",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F657"
      },
      {
        denominazioneInItaliano: "Monticello Brianza",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F674"
      },
      {
        denominazioneInItaliano: "Morterone",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F758"
      },
      {
        denominazioneInItaliano: "Nibionno",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "F887"
      },
      {
        denominazioneInItaliano: "Oggiono",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G009"
      },
      {
        denominazioneInItaliano: "Olgiate Molgora",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G026"
      },
      {
        denominazioneInItaliano: "Olginate",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G030"
      },
      {
        denominazioneInItaliano: "Oliveto Lario",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G040"
      },
      {
        denominazioneInItaliano: "Osnago",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G161"
      },
      {
        denominazioneInItaliano: "Paderno d'Adda",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G218"
      },
      {
        denominazioneInItaliano: "Pagnona",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G241"
      },
      {
        denominazioneInItaliano: "Parlasco",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G336"
      },
      {
        denominazioneInItaliano: "Pasturo",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G368"
      },
      {
        denominazioneInItaliano: "Perledo",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G456"
      },
      {
        denominazioneInItaliano: "Pescate",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G485"
      },
      {
        denominazioneInItaliano: "Premana",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "H028"
      },
      {
        denominazioneInItaliano: "Primaluna",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "H063"
      },
      {
        denominazioneInItaliano: "Robbiate",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "G223"
      },
      {
        denominazioneInItaliano: "Rogeno",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "H486"
      },
      {
        denominazioneInItaliano: "Santa Maria Hoè",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "I243"
      },
      {
        denominazioneInItaliano: "Sirone",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "I759"
      },
      {
        denominazioneInItaliano: "Sirtori",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "I761"
      },
      {
        denominazioneInItaliano: "Sueglio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "I994"
      },
      {
        denominazioneInItaliano: "Suello",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "I996"
      },
      {
        denominazioneInItaliano: "Taceno",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "L022"
      },
      {
        denominazioneInItaliano: "Valgreghentino",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "L581"
      },
      {
        denominazioneInItaliano: "Valmadrera",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "L634"
      },
      {
        denominazioneInItaliano: "Varenna",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "L680"
      },
      {
        denominazioneInItaliano: "Vercurago",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "L751"
      },
      {
        denominazioneInItaliano: "Viganò",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "L866"
      },
      {
        denominazioneInItaliano: "Verderio",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "M337"
      },
      {
        denominazioneInItaliano: "La Valletta Brianza",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "M348"
      },
      {
        denominazioneInItaliano: "Valvarrone",
        regione: "Lombardia",
        provincia: "Lecco",
        codiceCatastale: "M395"
      },
      {
        denominazioneInItaliano: "Abbadia Cerreto",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "A004"
      },
      {
        denominazioneInItaliano: "Bertonico",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "A811"
      },
      {
        denominazioneInItaliano: "Boffalora d'Adda",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "A919"
      },
      {
        denominazioneInItaliano: "Borghetto Lodigiano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "A995"
      },
      {
        denominazioneInItaliano: "Borgo San Giovanni",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "B017"
      },
      {
        denominazioneInItaliano: "Brembio",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "B141"
      },
      {
        denominazioneInItaliano: "Casaletto Lodigiano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "B887"
      },
      {
        denominazioneInItaliano: "Casalmaiocco",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "B899"
      },
      {
        denominazioneInItaliano: "Casalpusterlengo",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "B910"
      },
      {
        denominazioneInItaliano: "Caselle Landi",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "B961"
      },
      {
        denominazioneInItaliano: "Caselle Lurani",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "B958"
      },
      {
        denominazioneInItaliano: "Castelnuovo Bocca d'Adda",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "C228"
      },
      {
        denominazioneInItaliano: "Castiglione d'Adda",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "C304"
      },
      {
        denominazioneInItaliano: "Castiraga Vidardo",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "C329"
      },
      {
        denominazioneInItaliano: "Cavenago d'Adda",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "C394"
      },
      {
        denominazioneInItaliano: "Cervignano d'Adda",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "C555"
      },
      {
        denominazioneInItaliano: "Codogno",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "C816"
      },
      {
        denominazioneInItaliano: "Comazzo",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "C917"
      },
      {
        denominazioneInItaliano: "Cornegliano Laudense",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "D021"
      },
      {
        denominazioneInItaliano: "Corno Giovine",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "D028"
      },
      {
        denominazioneInItaliano: "Cornovecchio",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "D029"
      },
      {
        denominazioneInItaliano: "Corte Palasio",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "D068"
      },
      {
        denominazioneInItaliano: "Crespiatica",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "D159"
      },
      {
        denominazioneInItaliano: "Fombio",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "D660"
      },
      {
        denominazioneInItaliano: "Galgagnano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "D868"
      },
      {
        denominazioneInItaliano: "Graffignana",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E127"
      },
      {
        denominazioneInItaliano: "Guardamiglio",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E238"
      },
      {
        denominazioneInItaliano: "Livraga",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E627"
      },
      {
        denominazioneInItaliano: "Lodi",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E648"
      },
      {
        denominazioneInItaliano: "Lodi Vecchio",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E651"
      },
      {
        denominazioneInItaliano: "Maccastorna",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E777"
      },
      {
        denominazioneInItaliano: "Mairago",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E840"
      },
      {
        denominazioneInItaliano: "Maleo",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E852"
      },
      {
        denominazioneInItaliano: "Marudo",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "E994"
      },
      {
        denominazioneInItaliano: "Massalengo",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "F028"
      },
      {
        denominazioneInItaliano: "Meleti",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "F102"
      },
      {
        denominazioneInItaliano: "Merlino",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "F149"
      },
      {
        denominazioneInItaliano: "Montanaso Lombardo",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "F423"
      },
      {
        denominazioneInItaliano: "Mulazzano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "F801"
      },
      {
        denominazioneInItaliano: "Orio Litta",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "G107"
      },
      {
        denominazioneInItaliano: "Ospedaletto Lodigiano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "G166"
      },
      {
        denominazioneInItaliano: "Ossago Lodigiano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "G171"
      },
      {
        denominazioneInItaliano: "Pieve Fissiraga",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "G096"
      },
      {
        denominazioneInItaliano: "Salerano sul Lambro",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "H701"
      },
      {
        denominazioneInItaliano: "San Fiorano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "H844"
      },
      {
        denominazioneInItaliano: "San Martino in Strada",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "I012"
      },
      {
        denominazioneInItaliano: "San Rocco al Porto",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "I140"
      },
      {
        denominazioneInItaliano: "Sant'Angelo Lodigiano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "I274"
      },
      {
        denominazioneInItaliano: "Santo Stefano Lodigiano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "I362"
      },
      {
        denominazioneInItaliano: "Secugnago",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "I561"
      },
      {
        denominazioneInItaliano: "Senna Lodigiana",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "I612"
      },
      {
        denominazioneInItaliano: "Somaglia",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "I815"
      },
      {
        denominazioneInItaliano: "Sordio",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "I848"
      },
      {
        denominazioneInItaliano: "Tavazzano con Villavesco",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "F260"
      },
      {
        denominazioneInItaliano: "Terranova dei Passerini",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "L125"
      },
      {
        denominazioneInItaliano: "Turano Lodigiano",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "L469"
      },
      {
        denominazioneInItaliano: "Valera Fratta",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "L572"
      },
      {
        denominazioneInItaliano: "Villanova del Sillaro",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "L977"
      },
      {
        denominazioneInItaliano: "Zelo Buon Persico",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "M158"
      },
      {
        denominazioneInItaliano: "Castelgerundo",
        regione: "Lombardia",
        provincia: "Lodi",
        codiceCatastale: "M393"
      },
      {
        denominazioneInItaliano: "Agrate Brianza",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A087"
      },
      {
        denominazioneInItaliano: "Aicurzio",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A096"
      },
      {
        denominazioneInItaliano: "Albiate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A159"
      },
      {
        denominazioneInItaliano: "Arcore",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A376"
      },
      {
        denominazioneInItaliano: "Barlassina",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A668"
      },
      {
        denominazioneInItaliano: "Bellusco",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A759"
      },
      {
        denominazioneInItaliano: "Bernareggio",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A802"
      },
      {
        denominazioneInItaliano: "Besana in Brianza",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A818"
      },
      {
        denominazioneInItaliano: "Biassono",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "A849"
      },
      {
        denominazioneInItaliano: "Bovisio-Masciago",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B105"
      },
      {
        denominazioneInItaliano: "Briosco",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B187"
      },
      {
        denominazioneInItaliano: "Brugherio",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B212"
      },
      {
        denominazioneInItaliano: "Burago di Molgora",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B272"
      },
      {
        denominazioneInItaliano: "Camparada",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B501"
      },
      {
        denominazioneInItaliano: "Carate Brianza",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B729"
      },
      {
        denominazioneInItaliano: "Carnate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B798"
      },
      {
        denominazioneInItaliano: "Cavenago di Brianza",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "C395"
      },
      {
        denominazioneInItaliano: "Ceriano Laghetto",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "C512"
      },
      {
        denominazioneInItaliano: "Cesano Maderno",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "C566"
      },
      {
        denominazioneInItaliano: "Cogliate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "C820"
      },
      {
        denominazioneInItaliano: "Concorezzo",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "C952"
      },
      {
        denominazioneInItaliano: "Correzzana",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "D038"
      },
      {
        denominazioneInItaliano: "Desio",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "D286"
      },
      {
        denominazioneInItaliano: "Giussano",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "E063"
      },
      {
        denominazioneInItaliano: "Lazzate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "E504"
      },
      {
        denominazioneInItaliano: "Lesmo",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "E550"
      },
      {
        denominazioneInItaliano: "Limbiate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "E591"
      },
      {
        denominazioneInItaliano: "Lissone",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "E617"
      },
      {
        denominazioneInItaliano: "Macherio",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "E786"
      },
      {
        denominazioneInItaliano: "Meda",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "F078"
      },
      {
        denominazioneInItaliano: "Mezzago",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "F165"
      },
      {
        denominazioneInItaliano: "Misinto",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "F247"
      },
      {
        denominazioneInItaliano: "Monza",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "F704"
      },
      {
        denominazioneInItaliano: "Muggiò",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "F797"
      },
      {
        denominazioneInItaliano: "Nova Milanese",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "F944"
      },
      {
        denominazioneInItaliano: "Ornago",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "G116"
      },
      {
        denominazioneInItaliano: "Renate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "H233"
      },
      {
        denominazioneInItaliano: "Ronco Briantino",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "H537"
      },
      {
        denominazioneInItaliano: "Seregno",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "I625"
      },
      {
        denominazioneInItaliano: "Seveso",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "I709"
      },
      {
        denominazioneInItaliano: "Sovico",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "I878"
      },
      {
        denominazioneInItaliano: "Sulbiate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "I998"
      },
      {
        denominazioneInItaliano: "Triuggio",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "L434"
      },
      {
        denominazioneInItaliano: "Usmate Velate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "L511"
      },
      {
        denominazioneInItaliano: "Varedo",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "L677"
      },
      {
        denominazioneInItaliano: "Vedano al Lambro",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "L704"
      },
      {
        denominazioneInItaliano: "Veduggio con Colzano",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "L709"
      },
      {
        denominazioneInItaliano: "Verano Brianza",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "L744"
      },
      {
        denominazioneInItaliano: "Villasanta",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "M017"
      },
      {
        denominazioneInItaliano: "Vimercate",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "M052"
      },
      {
        denominazioneInItaliano: "Busnago",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B289"
      },
      {
        denominazioneInItaliano: "Caponago",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "B671"
      },
      {
        denominazioneInItaliano: "Cornate d'Adda",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "D019"
      },
      {
        denominazioneInItaliano: "Lentate sul Seveso",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "E530"
      },
      {
        denominazioneInItaliano: "Roncello",
        regione: "Lombardia",
        provincia: "Monza e della Brianza",
        codiceCatastale: "H529"
      },
      {
        denominazioneInItaliano: "Aldino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A179"
      },
      {
        denominazioneInItaliano: "Andriano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A286"
      },
      {
        denominazioneInItaliano: "Anterivo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A306"
      },
      {
        denominazioneInItaliano: "Appiano sulla strada del vino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A332"
      },
      {
        denominazioneInItaliano: "Avelengo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A507"
      },
      {
        denominazioneInItaliano: "Badia",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A537"
      },
      {
        denominazioneInItaliano: "Barbiano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A635"
      },
      {
        denominazioneInItaliano: "Bolzano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A952"
      },
      {
        denominazioneInItaliano: "Braies",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B116"
      },
      {
        denominazioneInItaliano: "Brennero",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B145"
      },
      {
        denominazioneInItaliano: "Bressanone",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B160"
      },
      {
        denominazioneInItaliano: "Bronzolo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B203"
      },
      {
        denominazioneInItaliano: "Brunico",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B220"
      },
      {
        denominazioneInItaliano: "Caines",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B364"
      },
      {
        denominazioneInItaliano: "Caldaro sulla strada del vino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B397"
      },
      {
        denominazioneInItaliano: "Campo di Trens",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B529"
      },
      {
        denominazioneInItaliano: "Campo Tures",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B570"
      },
      {
        denominazioneInItaliano: "Castelbello-Ciardes",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "C062"
      },
      {
        denominazioneInItaliano: "Castelrotto",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "C254"
      },
      {
        denominazioneInItaliano: "Cermes",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "A022"
      },
      {
        denominazioneInItaliano: "Chienes",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "C625"
      },
      {
        denominazioneInItaliano: "Chiusa",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "C652"
      },
      {
        denominazioneInItaliano: "Cornedo all'Isarco",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "B799"
      },
      {
        denominazioneInItaliano: "Cortaccia sulla strada del vino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D048"
      },
      {
        denominazioneInItaliano: "Cortina sulla strada del vino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D075"
      },
      {
        denominazioneInItaliano: "Corvara in Badia",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D079"
      },
      {
        denominazioneInItaliano: "Curon Venosta",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D222"
      },
      {
        denominazioneInItaliano: "Dobbiaco",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D311"
      },
      {
        denominazioneInItaliano: "Egna",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D392"
      },
      {
        denominazioneInItaliano: "Falzes",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D484"
      },
      {
        denominazioneInItaliano: "Fiè allo Sciliar",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D571"
      },
      {
        denominazioneInItaliano: "Fortezza",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D731"
      },
      {
        denominazioneInItaliano: "Funes",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D821"
      },
      {
        denominazioneInItaliano: "Gais",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D860"
      },
      {
        denominazioneInItaliano: "Gargazzone",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "D923"
      },
      {
        denominazioneInItaliano: "Glorenza",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E069"
      },
      {
        denominazioneInItaliano: "Laces",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E398"
      },
      {
        denominazioneInItaliano: "Lagundo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E412"
      },
      {
        denominazioneInItaliano: "Laion",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E420"
      },
      {
        denominazioneInItaliano: "Laives",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E421"
      },
      {
        denominazioneInItaliano: "Lana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E434"
      },
      {
        denominazioneInItaliano: "Lasa",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E457"
      },
      {
        denominazioneInItaliano: "Lauregno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E481"
      },
      {
        denominazioneInItaliano: "Luson",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E764"
      },
      {
        denominazioneInItaliano: "Magrè sulla strada del vino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E829"
      },
      {
        denominazioneInItaliano: "Malles Venosta",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E862"
      },
      {
        denominazioneInItaliano: "Marebbe",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E938"
      },
      {
        denominazioneInItaliano: "Marlengo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E959"
      },
      {
        denominazioneInItaliano: "Martello",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E981"
      },
      {
        denominazioneInItaliano: "Meltina",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F118"
      },
      {
        denominazioneInItaliano: "Merano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F132"
      },
      {
        denominazioneInItaliano: "Monguelfo-Tesido",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F371"
      },
      {
        denominazioneInItaliano: "Montagna sulla Strada del Vino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F392"
      },
      {
        denominazioneInItaliano: "Moso in Passiria",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F766"
      },
      {
        denominazioneInItaliano: "Nalles",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F836"
      },
      {
        denominazioneInItaliano: "Naturno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F849"
      },
      {
        denominazioneInItaliano: "Naz-Sciaves",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F856"
      },
      {
        denominazioneInItaliano: "Nova Levante",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F949"
      },
      {
        denominazioneInItaliano: "Nova Ponente",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "F950"
      },
      {
        denominazioneInItaliano: "Ora",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "G083"
      },
      {
        denominazioneInItaliano: "Ortisei",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "G140"
      },
      {
        denominazioneInItaliano: "Parcines",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "G328"
      },
      {
        denominazioneInItaliano: "Perca",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "G443"
      },
      {
        denominazioneInItaliano: "Plaus",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "G299"
      },
      {
        denominazioneInItaliano: "Ponte Gardena",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "G830"
      },
      {
        denominazioneInItaliano: "Postal",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "G936"
      },
      {
        denominazioneInItaliano: "Prato allo Stelvio",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H004"
      },
      {
        denominazioneInItaliano: "Predoi",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H019"
      },
      {
        denominazioneInItaliano: "Proves",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H081"
      },
      {
        denominazioneInItaliano: "Racines",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H152"
      },
      {
        denominazioneInItaliano: "Rasun-Anterselva",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H189"
      },
      {
        denominazioneInItaliano: "Renon",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H236"
      },
      {
        denominazioneInItaliano: "Rifiano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H284"
      },
      {
        denominazioneInItaliano: "Rio di Pusteria",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H299"
      },
      {
        denominazioneInItaliano: "Rodengo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H475"
      },
      {
        denominazioneInItaliano: "Salorno sulla strada del vino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H719"
      },
      {
        denominazioneInItaliano: "San Candido",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H786"
      },
      {
        denominazioneInItaliano: "San Genesio Atesino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H858"
      },
      {
        denominazioneInItaliano: "San Leonardo in Passiria",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H952"
      },
      {
        denominazioneInItaliano: "San Lorenzo di Sebato",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H956"
      },
      {
        denominazioneInItaliano: "San Martino in Badia",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H988"
      },
      {
        denominazioneInItaliano: "San Martino in Passiria",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "H989"
      },
      {
        denominazioneInItaliano: "San Pancrazio",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I065"
      },
      {
        denominazioneInItaliano: "Santa Cristina Valgardena",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I173"
      },
      {
        denominazioneInItaliano: "Sarentino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I431"
      },
      {
        denominazioneInItaliano: "Scena",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I519"
      },
      {
        denominazioneInItaliano: "Selva dei Molini",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I593"
      },
      {
        denominazioneInItaliano: "Selva di Val Gardena",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I591"
      },
      {
        denominazioneInItaliano: "Senales",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I604"
      },
      {
        denominazioneInItaliano: "Sesto",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I687"
      },
      {
        denominazioneInItaliano: "Silandro",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I729"
      },
      {
        denominazioneInItaliano: "Sluderno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I771"
      },
      {
        denominazioneInItaliano: "Stelvio",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I948"
      },
      {
        denominazioneInItaliano: "Terento",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L106"
      },
      {
        denominazioneInItaliano: "Terlano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L108"
      },
      {
        denominazioneInItaliano: "Termeno sulla strada del vino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L111"
      },
      {
        denominazioneInItaliano: "Tesimo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L149"
      },
      {
        denominazioneInItaliano: "Tires",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L176"
      },
      {
        denominazioneInItaliano: "Tirolo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L178"
      },
      {
        denominazioneInItaliano: "Trodena nel parco naturale",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L444"
      },
      {
        denominazioneInItaliano: "Tubre",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L455"
      },
      {
        denominazioneInItaliano: "Ultimo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L490"
      },
      {
        denominazioneInItaliano: "Vadena",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L527"
      },
      {
        denominazioneInItaliano: "Valdaora",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L552"
      },
      {
        denominazioneInItaliano: "Val di Vizze",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L564"
      },
      {
        denominazioneInItaliano: "Valle Aurina",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L595"
      },
      {
        denominazioneInItaliano: "Valle di Casies",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L601"
      },
      {
        denominazioneInItaliano: "Vandoies",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L660"
      },
      {
        denominazioneInItaliano: "Varna",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L687"
      },
      {
        denominazioneInItaliano: "Verano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L745"
      },
      {
        denominazioneInItaliano: "Villabassa",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L915"
      },
      {
        denominazioneInItaliano: "Villandro",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L971"
      },
      {
        denominazioneInItaliano: "Vipiteno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "M067"
      },
      {
        denominazioneInItaliano: "Velturno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "L724"
      },
      {
        denominazioneInItaliano: "La Valle",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "E491"
      },
      {
        denominazioneInItaliano: "Senale-San Felice",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Bolzano\/Bozen",
        codiceCatastale: "I603"
      },
      {
        denominazioneInItaliano: "Ala",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A116"
      },
      {
        denominazioneInItaliano: "Albiano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A158"
      },
      {
        denominazioneInItaliano: "Aldeno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A178"
      },
      {
        denominazioneInItaliano: "Andalo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A274"
      },
      {
        denominazioneInItaliano: "Arco",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A372"
      },
      {
        denominazioneInItaliano: "Avio",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A520"
      },
      {
        denominazioneInItaliano: "Baselga di Pinè",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A694"
      },
      {
        denominazioneInItaliano: "Bedollo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A730"
      },
      {
        denominazioneInItaliano: "Besenello",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A821"
      },
      {
        denominazioneInItaliano: "Bieno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A863"
      },
      {
        denominazioneInItaliano: "Bleggio Superiore",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A902"
      },
      {
        denominazioneInItaliano: "Bocenago",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A916"
      },
      {
        denominazioneInItaliano: "Bondone",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "A968"
      },
      {
        denominazioneInItaliano: "Borgo Valsugana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B006"
      },
      {
        denominazioneInItaliano: "Brentonico",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B153"
      },
      {
        denominazioneInItaliano: "Bresimo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B158"
      },
      {
        denominazioneInItaliano: "Caderzone Terme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B335"
      },
      {
        denominazioneInItaliano: "Calceranica al Lago",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B389"
      },
      {
        denominazioneInItaliano: "Caldes",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B400"
      },
      {
        denominazioneInItaliano: "Caldonazzo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B404"
      },
      {
        denominazioneInItaliano: "Calliano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B419"
      },
      {
        denominazioneInItaliano: "Campitello di Fassa",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B514"
      },
      {
        denominazioneInItaliano: "Campodenno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B525"
      },
      {
        denominazioneInItaliano: "Canal San Bovo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B577"
      },
      {
        denominazioneInItaliano: "Canazei",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B579"
      },
      {
        denominazioneInItaliano: "Capriana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B697"
      },
      {
        denominazioneInItaliano: "Carisolo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B783"
      },
      {
        denominazioneInItaliano: "Carzano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "B856"
      },
      {
        denominazioneInItaliano: "Castel Condino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C183"
      },
      {
        denominazioneInItaliano: "Castello-Molina di Fiemme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C189"
      },
      {
        denominazioneInItaliano: "Castello Tesino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C194"
      },
      {
        denominazioneInItaliano: "Castelnuovo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C216"
      },
      {
        denominazioneInItaliano: "Cavalese",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C372"
      },
      {
        denominazioneInItaliano: "Cavareno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C380"
      },
      {
        denominazioneInItaliano: "Cavedago",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C392"
      },
      {
        denominazioneInItaliano: "Cavedine",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C393"
      },
      {
        denominazioneInItaliano: "Cavizzana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C400"
      },
      {
        denominazioneInItaliano: "Cimone",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C700"
      },
      {
        denominazioneInItaliano: "Cinte Tesino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C712"
      },
      {
        denominazioneInItaliano: "Cis",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C727"
      },
      {
        denominazioneInItaliano: "Civezzano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C756"
      },
      {
        denominazioneInItaliano: "Cles",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C794"
      },
      {
        denominazioneInItaliano: "Commezzadura",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "C931"
      },
      {
        denominazioneInItaliano: "Croviana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D188"
      },
      {
        denominazioneInItaliano: "Dambel",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D246"
      },
      {
        denominazioneInItaliano: "Denno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D273"
      },
      {
        denominazioneInItaliano: "Drena",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D365"
      },
      {
        denominazioneInItaliano: "Dro",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D371"
      },
      {
        denominazioneInItaliano: "Fai della Paganella",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D468"
      },
      {
        denominazioneInItaliano: "Fiavè",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D565"
      },
      {
        denominazioneInItaliano: "Fierozzo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D573"
      },
      {
        denominazioneInItaliano: "Folgaria",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D651"
      },
      {
        denominazioneInItaliano: "Fornace",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D714"
      },
      {
        denominazioneInItaliano: "Frassilongo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D775"
      },
      {
        denominazioneInItaliano: "Garniga Terme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "D928"
      },
      {
        denominazioneInItaliano: "Giovo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E048"
      },
      {
        denominazioneInItaliano: "Giustino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E065"
      },
      {
        denominazioneInItaliano: "Grigno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E178"
      },
      {
        denominazioneInItaliano: "Imer",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E288"
      },
      {
        denominazioneInItaliano: "Isera",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E334"
      },
      {
        denominazioneInItaliano: "Lavarone",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E492"
      },
      {
        denominazioneInItaliano: "Lavis",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E500"
      },
      {
        denominazioneInItaliano: "Levico Terme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E565"
      },
      {
        denominazioneInItaliano: "Livo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E624"
      },
      {
        denominazioneInItaliano: "Lona-Lases",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E664"
      },
      {
        denominazioneInItaliano: "Luserna",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E757"
      },
      {
        denominazioneInItaliano: "Malé",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "E850"
      },
      {
        denominazioneInItaliano: "Massimeno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F045"
      },
      {
        denominazioneInItaliano: "Mazzin",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F068"
      },
      {
        denominazioneInItaliano: "Mezzana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F168"
      },
      {
        denominazioneInItaliano: "Mezzano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F176"
      },
      {
        denominazioneInItaliano: "Mezzocorona",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F183"
      },
      {
        denominazioneInItaliano: "Mezzolombardo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F187"
      },
      {
        denominazioneInItaliano: "Moena",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F263"
      },
      {
        denominazioneInItaliano: "Molveno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F307"
      },
      {
        denominazioneInItaliano: "Mori",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F728"
      },
      {
        denominazioneInItaliano: "Nago-Torbole",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F835"
      },
      {
        denominazioneInItaliano: "Nogaredo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F920"
      },
      {
        denominazioneInItaliano: "Nomi",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F929"
      },
      {
        denominazioneInItaliano: "Novaledo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "F947"
      },
      {
        denominazioneInItaliano: "Ospedaletto",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G168"
      },
      {
        denominazioneInItaliano: "Ossana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G173"
      },
      {
        denominazioneInItaliano: "Palù del Fersina",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G296"
      },
      {
        denominazioneInItaliano: "Panchià",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G305"
      },
      {
        denominazioneInItaliano: "Ronzo-Chienis",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M303"
      },
      {
        denominazioneInItaliano: "Peio",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G419"
      },
      {
        denominazioneInItaliano: "Pellizzano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G428"
      },
      {
        denominazioneInItaliano: "Pelugo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G429"
      },
      {
        denominazioneInItaliano: "Pergine Valsugana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G452"
      },
      {
        denominazioneInItaliano: "Pieve Tesino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G656"
      },
      {
        denominazioneInItaliano: "Pinzolo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G681"
      },
      {
        denominazioneInItaliano: "Pomarolo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "G808"
      },
      {
        denominazioneInItaliano: "Predazzo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H018"
      },
      {
        denominazioneInItaliano: "Rabbi",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H146"
      },
      {
        denominazioneInItaliano: "Riva del Garda",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H330"
      },
      {
        denominazioneInItaliano: "Romeno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H517"
      },
      {
        denominazioneInItaliano: "Roncegno Terme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H528"
      },
      {
        denominazioneInItaliano: "Ronchi Valsugana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H532"
      },
      {
        denominazioneInItaliano: "Ronzone",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H552"
      },
      {
        denominazioneInItaliano: "Roverè della Luna",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H607"
      },
      {
        denominazioneInItaliano: "Rovereto",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H612"
      },
      {
        denominazioneInItaliano: "Ruffrè-Mendola",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H634"
      },
      {
        denominazioneInItaliano: "Rumo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H639"
      },
      {
        denominazioneInItaliano: "Sagron Mis",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H666"
      },
      {
        denominazioneInItaliano: "Samone",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "H754"
      },
      {
        denominazioneInItaliano: "San Michele all'Adige",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I042"
      },
      {
        denominazioneInItaliano: "Sant'Orsola Terme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I354"
      },
      {
        denominazioneInItaliano: "Sanzeno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I411"
      },
      {
        denominazioneInItaliano: "Sarnonico",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I439"
      },
      {
        denominazioneInItaliano: "Scurelle",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I554"
      },
      {
        denominazioneInItaliano: "Segonzano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I576"
      },
      {
        denominazioneInItaliano: "Sfruz",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I714"
      },
      {
        denominazioneInItaliano: "Soraga di Fassa",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I839"
      },
      {
        denominazioneInItaliano: "Sover",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I871"
      },
      {
        denominazioneInItaliano: "Spiazzo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I899"
      },
      {
        denominazioneInItaliano: "Spormaggiore",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I924"
      },
      {
        denominazioneInItaliano: "Sporminore",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I925"
      },
      {
        denominazioneInItaliano: "Stenico",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I949"
      },
      {
        denominazioneInItaliano: "Storo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I964"
      },
      {
        denominazioneInItaliano: "Strembo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "I975"
      },
      {
        denominazioneInItaliano: "Telve",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L089"
      },
      {
        denominazioneInItaliano: "Telve di Sopra",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L090"
      },
      {
        denominazioneInItaliano: "Tenna",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L096"
      },
      {
        denominazioneInItaliano: "Tenno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L097"
      },
      {
        denominazioneInItaliano: "Terragnolo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L121"
      },
      {
        denominazioneInItaliano: "Terzolas",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L145"
      },
      {
        denominazioneInItaliano: "Tesero",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L147"
      },
      {
        denominazioneInItaliano: "Tione di Trento",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L174"
      },
      {
        denominazioneInItaliano: "Ton",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L200"
      },
      {
        denominazioneInItaliano: "Torcegno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L211"
      },
      {
        denominazioneInItaliano: "Trambileno",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L322"
      },
      {
        denominazioneInItaliano: "Trento",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L378"
      },
      {
        denominazioneInItaliano: "Valfloriana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L575"
      },
      {
        denominazioneInItaliano: "Vallarsa",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L588"
      },
      {
        denominazioneInItaliano: "Vermiglio",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L769"
      },
      {
        denominazioneInItaliano: "Vignola-Falesina",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L886"
      },
      {
        denominazioneInItaliano: "Villa Lagarina",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "L957"
      },
      {
        denominazioneInItaliano: "Volano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M113"
      },
      {
        denominazioneInItaliano: "Ziano di Fiemme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M173"
      },
      {
        denominazioneInItaliano: "Comano Terme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M314"
      },
      {
        denominazioneInItaliano: "Ledro",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M313"
      },
      {
        denominazioneInItaliano: "Predaia",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M344"
      },
      {
        denominazioneInItaliano: "San Lorenzo Dorsino",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M345"
      },
      {
        denominazioneInItaliano: "Valdaone",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M343"
      },
      {
        denominazioneInItaliano: "Dimaro Folgarida",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M366"
      },
      {
        denominazioneInItaliano: "Pieve di Bono-Prezzo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M365"
      },
      {
        denominazioneInItaliano: "Altavalle",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M349"
      },
      {
        denominazioneInItaliano: "Altopiano della Vigolana",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M350"
      },
      {
        denominazioneInItaliano: "Amblar-Don",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M351"
      },
      {
        denominazioneInItaliano: "Borgo Chiese",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M352"
      },
      {
        denominazioneInItaliano: "Borgo Lares",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M353"
      },
      {
        denominazioneInItaliano: "Castel Ivano",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M354"
      },
      {
        denominazioneInItaliano: "Cembra Lisignago",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M355"
      },
      {
        denominazioneInItaliano: "Contà",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M356"
      },
      {
        denominazioneInItaliano: "Madruzzo",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M357"
      },
      {
        denominazioneInItaliano: "Porte di Rendena",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M358"
      },
      {
        denominazioneInItaliano: "Primiero San Martino di Castrozza",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M359"
      },
      {
        denominazioneInItaliano: "Sella Giudicarie",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M360"
      },
      {
        denominazioneInItaliano: "Tre Ville",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M361"
      },
      {
        denominazioneInItaliano: "Vallelaghi",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M362"
      },
      {
        denominazioneInItaliano: "Ville d'Anaunia",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M363"
      },
      {
        denominazioneInItaliano: "San Giovanni di Fassa",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M390"
      },
      {
        denominazioneInItaliano: "Terre d'Adige",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M407"
      },
      {
        denominazioneInItaliano: "Borgo d'Anaunia",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M429"
      },
      {
        denominazioneInItaliano: "Novella",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M430"
      },
      {
        denominazioneInItaliano: "Ville di Fiemme",
        regione: "Trentino-Alto Adige\/Südtirol",
        provincia: "Trento",
        codiceCatastale: "M431"
      },
      {
        denominazioneInItaliano: "Affi",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A061"
      },
      {
        denominazioneInItaliano: "Albaredo d'Adige",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A137"
      },
      {
        denominazioneInItaliano: "Angiari",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A292"
      },
      {
        denominazioneInItaliano: "Arcole",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A374"
      },
      {
        denominazioneInItaliano: "Badia Calavena",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A540"
      },
      {
        denominazioneInItaliano: "Bardolino",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A650"
      },
      {
        denominazioneInItaliano: "Belfiore",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A737"
      },
      {
        denominazioneInItaliano: "Bevilacqua",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A837"
      },
      {
        denominazioneInItaliano: "Bonavigo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "A964"
      },
      {
        denominazioneInItaliano: "Boschi Sant'Anna",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B070"
      },
      {
        denominazioneInItaliano: "Bosco Chiesanuova",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B073"
      },
      {
        denominazioneInItaliano: "Bovolone",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B107"
      },
      {
        denominazioneInItaliano: "Brentino Belluno",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B152"
      },
      {
        denominazioneInItaliano: "Brenzone sul Garda",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B154"
      },
      {
        denominazioneInItaliano: "Bussolengo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B296"
      },
      {
        denominazioneInItaliano: "Buttapietra",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B304"
      },
      {
        denominazioneInItaliano: "Caldiero",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B402"
      },
      {
        denominazioneInItaliano: "Caprino Veronese",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B709"
      },
      {
        denominazioneInItaliano: "Casaleone",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "B886"
      },
      {
        denominazioneInItaliano: "Castagnaro",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C041"
      },
      {
        denominazioneInItaliano: "Castel d'Azzano",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C078"
      },
      {
        denominazioneInItaliano: "Castelnuovo del Garda",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C225"
      },
      {
        denominazioneInItaliano: "Cavaion Veronese",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C370"
      },
      {
        denominazioneInItaliano: "Cazzano di Tramigna",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C412"
      },
      {
        denominazioneInItaliano: "Cerea",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C498"
      },
      {
        denominazioneInItaliano: "Cerro Veronese",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C538"
      },
      {
        denominazioneInItaliano: "Cologna Veneta",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C890"
      },
      {
        denominazioneInItaliano: "Colognola ai Colli",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C897"
      },
      {
        denominazioneInItaliano: "Concamarise",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "C943"
      },
      {
        denominazioneInItaliano: "Costermano sul Garda",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D118"
      },
      {
        denominazioneInItaliano: "Dolcè",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D317"
      },
      {
        denominazioneInItaliano: "Erbè",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D419"
      },
      {
        denominazioneInItaliano: "Erbezzo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D420"
      },
      {
        denominazioneInItaliano: "Ferrara di Monte Baldo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D549"
      },
      {
        denominazioneInItaliano: "Fumane",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D818"
      },
      {
        denominazioneInItaliano: "Garda",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D915"
      },
      {
        denominazioneInItaliano: "Gazzo Veronese",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D957"
      },
      {
        denominazioneInItaliano: "Grezzana",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E171"
      },
      {
        denominazioneInItaliano: "Illasi",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E284"
      },
      {
        denominazioneInItaliano: "Isola della Scala",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E349"
      },
      {
        denominazioneInItaliano: "Isola Rizza",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E358"
      },
      {
        denominazioneInItaliano: "Lavagno",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E489"
      },
      {
        denominazioneInItaliano: "Lazise",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E502"
      },
      {
        denominazioneInItaliano: "Legnago",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E512"
      },
      {
        denominazioneInItaliano: "Malcesine",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E848"
      },
      {
        denominazioneInItaliano: "Marano di Valpolicella",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "E911"
      },
      {
        denominazioneInItaliano: "Mezzane di Sotto",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "F172"
      },
      {
        denominazioneInItaliano: "Minerbe",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "F218"
      },
      {
        denominazioneInItaliano: "Montecchia di Crosara",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "F461"
      },
      {
        denominazioneInItaliano: "Monteforte d'Alpone",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "F508"
      },
      {
        denominazioneInItaliano: "Mozzecane",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "F789"
      },
      {
        denominazioneInItaliano: "Negrar di Valpolicella",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "F861"
      },
      {
        denominazioneInItaliano: "Nogara",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "F918"
      },
      {
        denominazioneInItaliano: "Nogarole Rocca",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "F921"
      },
      {
        denominazioneInItaliano: "Oppeano",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "G080"
      },
      {
        denominazioneInItaliano: "Palù",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "G297"
      },
      {
        denominazioneInItaliano: "Pastrengo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "G365"
      },
      {
        denominazioneInItaliano: "Pescantina",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "G481"
      },
      {
        denominazioneInItaliano: "Peschiera del Garda",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "G489"
      },
      {
        denominazioneInItaliano: "Povegliano Veronese",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "G945"
      },
      {
        denominazioneInItaliano: "Pressana",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H048"
      },
      {
        denominazioneInItaliano: "Rivoli Veronese",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H356"
      },
      {
        denominazioneInItaliano: "Roncà",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H522"
      },
      {
        denominazioneInItaliano: "Ronco all'Adige",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H540"
      },
      {
        denominazioneInItaliano: "Roverchiara",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H606"
      },
      {
        denominazioneInItaliano: "Roveredo di Guà",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H610"
      },
      {
        denominazioneInItaliano: "Roverè Veronese",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H608"
      },
      {
        denominazioneInItaliano: "Salizzole",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H714"
      },
      {
        denominazioneInItaliano: "San Bonifacio",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H783"
      },
      {
        denominazioneInItaliano: "San Giovanni Ilarione",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H916"
      },
      {
        denominazioneInItaliano: "San Giovanni Lupatoto",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H924"
      },
      {
        denominazioneInItaliano: "Sanguinetto",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H944"
      },
      {
        denominazioneInItaliano: "San Martino Buon Albergo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I003"
      },
      {
        denominazioneInItaliano: "San Mauro di Saline",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "H712"
      },
      {
        denominazioneInItaliano: "San Pietro di Morubio",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I105"
      },
      {
        denominazioneInItaliano: "San Pietro in Cariano",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I109"
      },
      {
        denominazioneInItaliano: "Sant'Ambrogio di Valpolicella",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I259"
      },
      {
        denominazioneInItaliano: "Sant'Anna d'Alfaedo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I292"
      },
      {
        denominazioneInItaliano: "San Zeno di Montagna",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I414"
      },
      {
        denominazioneInItaliano: "Selva di Progno",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I594"
      },
      {
        denominazioneInItaliano: "Soave",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I775"
      },
      {
        denominazioneInItaliano: "Sommacampagna",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I821"
      },
      {
        denominazioneInItaliano: "Sona",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I826"
      },
      {
        denominazioneInItaliano: "Sorgà",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "I850"
      },
      {
        denominazioneInItaliano: "Terrazzo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L136"
      },
      {
        denominazioneInItaliano: "Torri del Benaco",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L287"
      },
      {
        denominazioneInItaliano: "Tregnago",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L364"
      },
      {
        denominazioneInItaliano: "Trevenzuolo",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L396"
      },
      {
        denominazioneInItaliano: "Valeggio sul Mincio",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L567"
      },
      {
        denominazioneInItaliano: "Velo Veronese",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L722"
      },
      {
        denominazioneInItaliano: "Verona",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L781"
      },
      {
        denominazioneInItaliano: "Veronella",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "D193"
      },
      {
        denominazioneInItaliano: "Vestenanova",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L810"
      },
      {
        denominazioneInItaliano: "Vigasio",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L869"
      },
      {
        denominazioneInItaliano: "Villa Bartolomea",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L912"
      },
      {
        denominazioneInItaliano: "Villafranca di Verona",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "L949"
      },
      {
        denominazioneInItaliano: "Zevio",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "M172"
      },
      {
        denominazioneInItaliano: "Zimella",
        regione: "Veneto",
        provincia: "Verona",
        codiceCatastale: "M178"
      },
      {
        denominazioneInItaliano: "Agugliaro",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A093"
      },
      {
        denominazioneInItaliano: "Albettone",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A154"
      },
      {
        denominazioneInItaliano: "Alonte",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A220"
      },
      {
        denominazioneInItaliano: "Altavilla Vicentina",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A231"
      },
      {
        denominazioneInItaliano: "Altissimo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A236"
      },
      {
        denominazioneInItaliano: "Arcugnano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A377"
      },
      {
        denominazioneInItaliano: "Arsiero",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A444"
      },
      {
        denominazioneInItaliano: "Arzignano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A459"
      },
      {
        denominazioneInItaliano: "Asiago",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A465"
      },
      {
        denominazioneInItaliano: "Asigliano Veneto",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A467"
      },
      {
        denominazioneInItaliano: "Bassano del Grappa",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A703"
      },
      {
        denominazioneInItaliano: "Bolzano Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "A954"
      },
      {
        denominazioneInItaliano: "Breganze",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B132"
      },
      {
        denominazioneInItaliano: "Brendola",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B143"
      },
      {
        denominazioneInItaliano: "Bressanvido",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B161"
      },
      {
        denominazioneInItaliano: "Brogliano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B196"
      },
      {
        denominazioneInItaliano: "Caldogno",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B403"
      },
      {
        denominazioneInItaliano: "Caltrano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B433"
      },
      {
        denominazioneInItaliano: "Calvene",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B441"
      },
      {
        denominazioneInItaliano: "Camisano Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B485"
      },
      {
        denominazioneInItaliano: "Campiglia dei Berici",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B511"
      },
      {
        denominazioneInItaliano: "Carrè",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B835"
      },
      {
        denominazioneInItaliano: "Cartigliano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "B844"
      },
      {
        denominazioneInItaliano: "Cassola",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "C037"
      },
      {
        denominazioneInItaliano: "Castegnero",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "C056"
      },
      {
        denominazioneInItaliano: "Castelgomberto",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "C119"
      },
      {
        denominazioneInItaliano: "Chiampo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "C605"
      },
      {
        denominazioneInItaliano: "Chiuppano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "C650"
      },
      {
        denominazioneInItaliano: "Cogollo del Cengio",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "C824"
      },
      {
        denominazioneInItaliano: "Cornedo Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D020"
      },
      {
        denominazioneInItaliano: "Costabissara",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D107"
      },
      {
        denominazioneInItaliano: "Creazzo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D136"
      },
      {
        denominazioneInItaliano: "Crespadoro",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D156"
      },
      {
        denominazioneInItaliano: "Dueville",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D379"
      },
      {
        denominazioneInItaliano: "Enego",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D407"
      },
      {
        denominazioneInItaliano: "Fara Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D496"
      },
      {
        denominazioneInItaliano: "Foza",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D750"
      },
      {
        denominazioneInItaliano: "Gallio",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D882"
      },
      {
        denominazioneInItaliano: "Gambellara",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D897"
      },
      {
        denominazioneInItaliano: "Gambugliano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D902"
      },
      {
        denominazioneInItaliano: "Grisignano di Zocco",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E184"
      },
      {
        denominazioneInItaliano: "Grumolo delle Abbadesse",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E226"
      },
      {
        denominazioneInItaliano: "Isola Vicentina",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E354"
      },
      {
        denominazioneInItaliano: "Laghi",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E403"
      },
      {
        denominazioneInItaliano: "Lastebasse",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E465"
      },
      {
        denominazioneInItaliano: "Longare",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E671"
      },
      {
        denominazioneInItaliano: "Lonigo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E682"
      },
      {
        denominazioneInItaliano: "Lugo di Vicenza",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E731"
      },
      {
        denominazioneInItaliano: "Malo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E864"
      },
      {
        denominazioneInItaliano: "Marano Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E912"
      },
      {
        denominazioneInItaliano: "Marostica",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "E970"
      },
      {
        denominazioneInItaliano: "Montebello Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F442"
      },
      {
        denominazioneInItaliano: "Montecchio Maggiore",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F464"
      },
      {
        denominazioneInItaliano: "Montecchio Precalcino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F465"
      },
      {
        denominazioneInItaliano: "Monte di Malo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F486"
      },
      {
        denominazioneInItaliano: "Montegalda",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F514"
      },
      {
        denominazioneInItaliano: "Montegaldella",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F515"
      },
      {
        denominazioneInItaliano: "Monteviale",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F662"
      },
      {
        denominazioneInItaliano: "Monticello Conte Otto",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F675"
      },
      {
        denominazioneInItaliano: "Montorso Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F696"
      },
      {
        denominazioneInItaliano: "Mussolente",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F829"
      },
      {
        denominazioneInItaliano: "Nanto",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F838"
      },
      {
        denominazioneInItaliano: "Nogarole Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F922"
      },
      {
        denominazioneInItaliano: "Nove",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F957"
      },
      {
        denominazioneInItaliano: "Noventa Vicentina",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F964"
      },
      {
        denominazioneInItaliano: "Orgiano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "G095"
      },
      {
        denominazioneInItaliano: "Pedemonte",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "G406"
      },
      {
        denominazioneInItaliano: "Pianezze",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "G560"
      },
      {
        denominazioneInItaliano: "Piovene Rocchette",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "G694"
      },
      {
        denominazioneInItaliano: "Pojana Maggiore",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "G776"
      },
      {
        denominazioneInItaliano: "Posina",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "G931"
      },
      {
        denominazioneInItaliano: "Pove del Grappa",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "G943"
      },
      {
        denominazioneInItaliano: "Pozzoleone",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "G957"
      },
      {
        denominazioneInItaliano: "Quinto Vicentino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "H134"
      },
      {
        denominazioneInItaliano: "Recoaro Terme",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "H214"
      },
      {
        denominazioneInItaliano: "Roana",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "H361"
      },
      {
        denominazioneInItaliano: "Romano d'Ezzelino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "H512"
      },
      {
        denominazioneInItaliano: "Rosà",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "H556"
      },
      {
        denominazioneInItaliano: "Rossano Veneto",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "H580"
      },
      {
        denominazioneInItaliano: "Rotzo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "H594"
      },
      {
        denominazioneInItaliano: "Salcedo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "F810"
      },
      {
        denominazioneInItaliano: "Sandrigo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "H829"
      },
      {
        denominazioneInItaliano: "San Pietro Mussolino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I117"
      },
      {
        denominazioneInItaliano: "Santorso",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I353"
      },
      {
        denominazioneInItaliano: "San Vito di Leguzzano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I401"
      },
      {
        denominazioneInItaliano: "Sarcedo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I425"
      },
      {
        denominazioneInItaliano: "Sarego",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I430"
      },
      {
        denominazioneInItaliano: "Schiavon",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I527"
      },
      {
        denominazioneInItaliano: "Schio",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I531"
      },
      {
        denominazioneInItaliano: "Solagna",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I783"
      },
      {
        denominazioneInItaliano: "Sossano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I867"
      },
      {
        denominazioneInItaliano: "Sovizzo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "I879"
      },
      {
        denominazioneInItaliano: "Tezze sul Brenta",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L156"
      },
      {
        denominazioneInItaliano: "Thiene",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L157"
      },
      {
        denominazioneInItaliano: "Tonezza del Cimone",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "D717"
      },
      {
        denominazioneInItaliano: "Torrebelvicino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L248"
      },
      {
        denominazioneInItaliano: "Torri di Quartesolo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L297"
      },
      {
        denominazioneInItaliano: "Trissino",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L433"
      },
      {
        denominazioneInItaliano: "Valdagno",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L551"
      },
      {
        denominazioneInItaliano: "Valdastico",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L554"
      },
      {
        denominazioneInItaliano: "Valli del Pasubio",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L624"
      },
      {
        denominazioneInItaliano: "Velo d'Astico",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L723"
      },
      {
        denominazioneInItaliano: "Vicenza",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L840"
      },
      {
        denominazioneInItaliano: "Villaga",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "L952"
      },
      {
        denominazioneInItaliano: "Villaverla",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M032"
      },
      {
        denominazioneInItaliano: "Zanè",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M145"
      },
      {
        denominazioneInItaliano: "Zermeghedo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M170"
      },
      {
        denominazioneInItaliano: "Zovencedo",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M194"
      },
      {
        denominazioneInItaliano: "Zugliano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M199"
      },
      {
        denominazioneInItaliano: "Val Liona",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M384"
      },
      {
        denominazioneInItaliano: "Barbarano Mossano",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M401"
      },
      {
        denominazioneInItaliano: "Valbrenta",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M423"
      },
      {
        denominazioneInItaliano: "Colceresa",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M426"
      },
      {
        denominazioneInItaliano: "Lusiana Conco",
        regione: "Veneto",
        provincia: "Vicenza",
        codiceCatastale: "M427"
      },
      {
        denominazioneInItaliano: "Agordo",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "A083"
      },
      {
        denominazioneInItaliano: "Alano di Piave",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "A121"
      },
      {
        denominazioneInItaliano: "Alleghe",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "A206"
      },
      {
        denominazioneInItaliano: "Arsiè",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "A443"
      },
      {
        denominazioneInItaliano: "Auronzo di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "A501"
      },
      {
        denominazioneInItaliano: "Belluno",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "A757"
      },
      {
        denominazioneInItaliano: "Borca di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "A982"
      },
      {
        denominazioneInItaliano: "Calalzo di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "B375"
      },
      {
        denominazioneInItaliano: "Cencenighe Agordino",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "C458"
      },
      {
        denominazioneInItaliano: "Cesiomaggiore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "C577"
      },
      {
        denominazioneInItaliano: "Chies d'Alpago",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "C630"
      },
      {
        denominazioneInItaliano: "Cibiana di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "C672"
      },
      {
        denominazioneInItaliano: "Colle Santa Lucia",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "C872"
      },
      {
        denominazioneInItaliano: "Comelico Superiore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "C920"
      },
      {
        denominazioneInItaliano: "Cortina d'Ampezzo",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "A266"
      },
      {
        denominazioneInItaliano: "Danta di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "D247"
      },
      {
        denominazioneInItaliano: "Domegge di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "D330"
      },
      {
        denominazioneInItaliano: "Falcade",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "D470"
      },
      {
        denominazioneInItaliano: "Feltre",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "D530"
      },
      {
        denominazioneInItaliano: "Fonzaso",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "D686"
      },
      {
        denominazioneInItaliano: "Canale d'Agordo",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "B574"
      },
      {
        denominazioneInItaliano: "Gosaldo",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "E113"
      },
      {
        denominazioneInItaliano: "Lamon",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "E429"
      },
      {
        denominazioneInItaliano: "La Valle Agordina",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "E490"
      },
      {
        denominazioneInItaliano: "Limana",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "E588"
      },
      {
        denominazioneInItaliano: "Livinallongo del Col di Lana",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "E622"
      },
      {
        denominazioneInItaliano: "Lorenzago di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "E687"
      },
      {
        denominazioneInItaliano: "Lozzo di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "E708"
      },
      {
        denominazioneInItaliano: "Ospitale di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "G169"
      },
      {
        denominazioneInItaliano: "Pedavena",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "G404"
      },
      {
        denominazioneInItaliano: "Perarolo di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "G442"
      },
      {
        denominazioneInItaliano: "Pieve di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "G642"
      },
      {
        denominazioneInItaliano: "Ponte nelle Alpi",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "B662"
      },
      {
        denominazioneInItaliano: "Rivamonte Agordino",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "H327"
      },
      {
        denominazioneInItaliano: "Rocca Pietore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "H379"
      },
      {
        denominazioneInItaliano: "San Gregorio nelle Alpi",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "H938"
      },
      {
        denominazioneInItaliano: "San Nicolò di Comelico",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I063"
      },
      {
        denominazioneInItaliano: "San Pietro di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I088"
      },
      {
        denominazioneInItaliano: "Santa Giustina",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I206"
      },
      {
        denominazioneInItaliano: "San Tomaso Agordino",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I347"
      },
      {
        denominazioneInItaliano: "Santo Stefano di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "C919"
      },
      {
        denominazioneInItaliano: "San Vito di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I392"
      },
      {
        denominazioneInItaliano: "Sedico",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I563"
      },
      {
        denominazioneInItaliano: "Selva di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I592"
      },
      {
        denominazioneInItaliano: "Seren del Grappa",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I626"
      },
      {
        denominazioneInItaliano: "Sospirolo",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I866"
      },
      {
        denominazioneInItaliano: "Soverzene",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I876"
      },
      {
        denominazioneInItaliano: "Sovramonte",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "I673"
      },
      {
        denominazioneInItaliano: "Taibon Agordino",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "L030"
      },
      {
        denominazioneInItaliano: "Tambre",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "L040"
      },
      {
        denominazioneInItaliano: "Vallada Agordina",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "L584"
      },
      {
        denominazioneInItaliano: "Valle di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "L590"
      },
      {
        denominazioneInItaliano: "Vigo di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "L890"
      },
      {
        denominazioneInItaliano: "Vodo Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "M108"
      },
      {
        denominazioneInItaliano: "Voltago Agordino",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "M124"
      },
      {
        denominazioneInItaliano: "Zoppè di Cadore",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "M189"
      },
      {
        denominazioneInItaliano: "Quero Vas",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "M332"
      },
      {
        denominazioneInItaliano: "Longarone",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "M342"
      },
      {
        denominazioneInItaliano: "Alpago",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "M375"
      },
      {
        denominazioneInItaliano: "Val di Zoldo",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "M374"
      },
      {
        denominazioneInItaliano: "Borgo Valbelluna",
        regione: "Veneto",
        provincia: "Belluno",
        codiceCatastale: "M421"
      },
      {
        denominazioneInItaliano: "Altivole",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "A237"
      },
      {
        denominazioneInItaliano: "Arcade",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "A360"
      },
      {
        denominazioneInItaliano: "Asolo",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "A471"
      },
      {
        denominazioneInItaliano: "Borso del Grappa",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "B061"
      },
      {
        denominazioneInItaliano: "Breda di Piave",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "B128"
      },
      {
        denominazioneInItaliano: "Caerano di San Marco",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "B349"
      },
      {
        denominazioneInItaliano: "Cappella Maggiore",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "B678"
      },
      {
        denominazioneInItaliano: "Carbonera",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "B744"
      },
      {
        denominazioneInItaliano: "Casale sul Sile",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "B879"
      },
      {
        denominazioneInItaliano: "Casier",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "B965"
      },
      {
        denominazioneInItaliano: "Castelcucco",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C073"
      },
      {
        denominazioneInItaliano: "Castelfranco Veneto",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C111"
      },
      {
        denominazioneInItaliano: "Castello di Godego",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C190"
      },
      {
        denominazioneInItaliano: "Cavaso del Tomba",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C384"
      },
      {
        denominazioneInItaliano: "Cessalto",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C580"
      },
      {
        denominazioneInItaliano: "Chiarano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C614"
      },
      {
        denominazioneInItaliano: "Cimadolmo",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C689"
      },
      {
        denominazioneInItaliano: "Cison di Valmarino",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C735"
      },
      {
        denominazioneInItaliano: "Codognè",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C815"
      },
      {
        denominazioneInItaliano: "Colle Umberto",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C848"
      },
      {
        denominazioneInItaliano: "Conegliano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C957"
      },
      {
        denominazioneInItaliano: "Cordignano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C992"
      },
      {
        denominazioneInItaliano: "Cornuda",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "D030"
      },
      {
        denominazioneInItaliano: "Crocetta del Montello",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "C670"
      },
      {
        denominazioneInItaliano: "Farra di Soligo",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "D505"
      },
      {
        denominazioneInItaliano: "Follina",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "D654"
      },
      {
        denominazioneInItaliano: "Fontanelle",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "D674"
      },
      {
        denominazioneInItaliano: "Fonte",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "D680"
      },
      {
        denominazioneInItaliano: "Fregona",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "D794"
      },
      {
        denominazioneInItaliano: "Gaiarine",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "D854"
      },
      {
        denominazioneInItaliano: "Giavera del Montello",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "E021"
      },
      {
        denominazioneInItaliano: "Godega di Sant'Urbano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "E071"
      },
      {
        denominazioneInItaliano: "Gorgo al Monticano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "E092"
      },
      {
        denominazioneInItaliano: "Istrana",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "E373"
      },
      {
        denominazioneInItaliano: "Loria",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "E692"
      },
      {
        denominazioneInItaliano: "Mansuè",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "E893"
      },
      {
        denominazioneInItaliano: "Mareno di Piave",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "E940"
      },
      {
        denominazioneInItaliano: "Maser",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F009"
      },
      {
        denominazioneInItaliano: "Maserada sul Piave",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F012"
      },
      {
        denominazioneInItaliano: "Meduna di Livenza",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F088"
      },
      {
        denominazioneInItaliano: "Miane",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F190"
      },
      {
        denominazioneInItaliano: "Mogliano Veneto",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F269"
      },
      {
        denominazioneInItaliano: "Monastier di Treviso",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F332"
      },
      {
        denominazioneInItaliano: "Monfumo",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F360"
      },
      {
        denominazioneInItaliano: "Montebelluna",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F443"
      },
      {
        denominazioneInItaliano: "Morgano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F725"
      },
      {
        denominazioneInItaliano: "Moriago della Battaglia",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F729"
      },
      {
        denominazioneInItaliano: "Motta di Livenza",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F770"
      },
      {
        denominazioneInItaliano: "Nervesa della Battaglia",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F872"
      },
      {
        denominazioneInItaliano: "Oderzo",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F999"
      },
      {
        denominazioneInItaliano: "Ormelle",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G115"
      },
      {
        denominazioneInItaliano: "Orsago",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G123"
      },
      {
        denominazioneInItaliano: "Paese",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G229"
      },
      {
        denominazioneInItaliano: "Pederobba",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G408"
      },
      {
        denominazioneInItaliano: "Pieve di Soligo",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G645"
      },
      {
        denominazioneInItaliano: "Ponte di Piave",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G846"
      },
      {
        denominazioneInItaliano: "Ponzano Veneto",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G875"
      },
      {
        denominazioneInItaliano: "Portobuffolè",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G909"
      },
      {
        denominazioneInItaliano: "Possagno",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G933"
      },
      {
        denominazioneInItaliano: "Povegliano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "G944"
      },
      {
        denominazioneInItaliano: "Preganziol",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H022"
      },
      {
        denominazioneInItaliano: "Quinto di Treviso",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H131"
      },
      {
        denominazioneInItaliano: "Refrontolo",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H220"
      },
      {
        denominazioneInItaliano: "Resana",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H238"
      },
      {
        denominazioneInItaliano: "Revine Lago",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H253"
      },
      {
        denominazioneInItaliano: "Riese Pio X",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H280"
      },
      {
        denominazioneInItaliano: "Roncade",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H523"
      },
      {
        denominazioneInItaliano: "Salgareda",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H706"
      },
      {
        denominazioneInItaliano: "San Biagio di Callalta",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H781"
      },
      {
        denominazioneInItaliano: "San Fior",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "H843"
      },
      {
        denominazioneInItaliano: "San Pietro di Feletto",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I103"
      },
      {
        denominazioneInItaliano: "San Polo di Piave",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I124"
      },
      {
        denominazioneInItaliano: "Santa Lucia di Piave",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I221"
      },
      {
        denominazioneInItaliano: "San Vendemiano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I382"
      },
      {
        denominazioneInItaliano: "San Zenone degli Ezzelini",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I417"
      },
      {
        denominazioneInItaliano: "Sarmede",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I435"
      },
      {
        denominazioneInItaliano: "Segusino",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I578"
      },
      {
        denominazioneInItaliano: "Sernaglia della Battaglia",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I635"
      },
      {
        denominazioneInItaliano: "Silea",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "F116"
      },
      {
        denominazioneInItaliano: "Spresiano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "I927"
      },
      {
        denominazioneInItaliano: "Susegana",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "L014"
      },
      {
        denominazioneInItaliano: "Tarzo",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "L058"
      },
      {
        denominazioneInItaliano: "Trevignano",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "L402"
      },
      {
        denominazioneInItaliano: "Treviso",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "L407"
      },
      {
        denominazioneInItaliano: "Valdobbiadene",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "L565"
      },
      {
        denominazioneInItaliano: "Vazzola",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "L700"
      },
      {
        denominazioneInItaliano: "Vedelago",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "L706"
      },
      {
        denominazioneInItaliano: "Vidor",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "L856"
      },
      {
        denominazioneInItaliano: "Villorba",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "M048"
      },
      {
        denominazioneInItaliano: "Vittorio Veneto",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "M089"
      },
      {
        denominazioneInItaliano: "Volpago del Montello",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "M118"
      },
      {
        denominazioneInItaliano: "Zenson di Piave",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "M163"
      },
      {
        denominazioneInItaliano: "Zero Branco",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "M171"
      },
      {
        denominazioneInItaliano: "Pieve del Grappa",
        regione: "Veneto",
        provincia: "Treviso",
        codiceCatastale: "M422"
      },
      {
        denominazioneInItaliano: "Annone Veneto",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "A302"
      },
      {
        denominazioneInItaliano: "Campagna Lupia",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "B493"
      },
      {
        denominazioneInItaliano: "Campolongo Maggiore",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "B546"
      },
      {
        denominazioneInItaliano: "Camponogara",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "B554"
      },
      {
        denominazioneInItaliano: "Caorle",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "B642"
      },
      {
        denominazioneInItaliano: "Cavarzere",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "C383"
      },
      {
        denominazioneInItaliano: "Ceggia",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "C422"
      },
      {
        denominazioneInItaliano: "Chioggia",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "C638"
      },
      {
        denominazioneInItaliano: "Cinto Caomaggiore",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "C714"
      },
      {
        denominazioneInItaliano: "Cona",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "C938"
      },
      {
        denominazioneInItaliano: "Concordia Sagittaria",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "C950"
      },
      {
        denominazioneInItaliano: "Dolo",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "D325"
      },
      {
        denominazioneInItaliano: "Eraclea",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "D415"
      },
      {
        denominazioneInItaliano: "Fiesso d'Artico",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "D578"
      },
      {
        denominazioneInItaliano: "Fossalta di Piave",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "D740"
      },
      {
        denominazioneInItaliano: "Fossalta di Portogruaro",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "D741"
      },
      {
        denominazioneInItaliano: "Fossò",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "D748"
      },
      {
        denominazioneInItaliano: "Gruaro",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "E215"
      },
      {
        denominazioneInItaliano: "Jesolo",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "C388"
      },
      {
        denominazioneInItaliano: "Marcon",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "E936"
      },
      {
        denominazioneInItaliano: "Martellago",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "E980"
      },
      {
        denominazioneInItaliano: "Meolo",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "F130"
      },
      {
        denominazioneInItaliano: "Mira",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "F229"
      },
      {
        denominazioneInItaliano: "Mirano",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "F241"
      },
      {
        denominazioneInItaliano: "Musile di Piave",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "F826"
      },
      {
        denominazioneInItaliano: "Noale",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "F904"
      },
      {
        denominazioneInItaliano: "Noventa di Piave",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "F963"
      },
      {
        denominazioneInItaliano: "Pianiga",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "G565"
      },
      {
        denominazioneInItaliano: "Portogruaro",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "G914"
      },
      {
        denominazioneInItaliano: "Pramaggiore",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "G981"
      },
      {
        denominazioneInItaliano: "Quarto d'Altino",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "H117"
      },
      {
        denominazioneInItaliano: "Salzano",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "H735"
      },
      {
        denominazioneInItaliano: "San Donà di Piave",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "H823"
      },
      {
        denominazioneInItaliano: "San Michele al Tagliamento",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "I040"
      },
      {
        denominazioneInItaliano: "Santa Maria di Sala",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "I242"
      },
      {
        denominazioneInItaliano: "San Stino di Livenza",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "I373"
      },
      {
        denominazioneInItaliano: "Scorzè",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "I551"
      },
      {
        denominazioneInItaliano: "Spinea",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "I908"
      },
      {
        denominazioneInItaliano: "Stra",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "I965"
      },
      {
        denominazioneInItaliano: "Teglio Veneto",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "L085"
      },
      {
        denominazioneInItaliano: "Torre di Mosto",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "L267"
      },
      {
        denominazioneInItaliano: "Venezia",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "L736"
      },
      {
        denominazioneInItaliano: "Vigonovo",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "L899"
      },
      {
        denominazioneInItaliano: "Cavallino-Treporti",
        regione: "Veneto",
        provincia: "Venezia",
        codiceCatastale: "M308"
      },
      {
        denominazioneInItaliano: "Abano Terme",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A001"
      },
      {
        denominazioneInItaliano: "Agna",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A075"
      },
      {
        denominazioneInItaliano: "Albignasego",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A161"
      },
      {
        denominazioneInItaliano: "Anguillara Veneta",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A296"
      },
      {
        denominazioneInItaliano: "Arquà Petrarca",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A434"
      },
      {
        denominazioneInItaliano: "Arre",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A438"
      },
      {
        denominazioneInItaliano: "Arzergrande",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A458"
      },
      {
        denominazioneInItaliano: "Bagnoli di Sopra",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A568"
      },
      {
        denominazioneInItaliano: "Baone",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A613"
      },
      {
        denominazioneInItaliano: "Barbona",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A637"
      },
      {
        denominazioneInItaliano: "Battaglia Terme",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A714"
      },
      {
        denominazioneInItaliano: "Boara Pisani",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "A906"
      },
      {
        denominazioneInItaliano: "Borgoricco",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B031"
      },
      {
        denominazioneInItaliano: "Bovolenta",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B106"
      },
      {
        denominazioneInItaliano: "Brugine",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B213"
      },
      {
        denominazioneInItaliano: "Cadoneghe",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B345"
      },
      {
        denominazioneInItaliano: "Campodarsego",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B524"
      },
      {
        denominazioneInItaliano: "Campodoro",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B531"
      },
      {
        denominazioneInItaliano: "Camposampiero",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B563"
      },
      {
        denominazioneInItaliano: "Campo San Martino",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B564"
      },
      {
        denominazioneInItaliano: "Candiana",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B589"
      },
      {
        denominazioneInItaliano: "Carceri",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B749"
      },
      {
        denominazioneInItaliano: "Carmignano di Brenta",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B795"
      },
      {
        denominazioneInItaliano: "Cartura",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B848"
      },
      {
        denominazioneInItaliano: "Casale di Scodosia",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B877"
      },
      {
        denominazioneInItaliano: "Casalserugo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "B912"
      },
      {
        denominazioneInItaliano: "Castelbaldo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "C057"
      },
      {
        denominazioneInItaliano: "Cervarese Santa Croce",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "C544"
      },
      {
        denominazioneInItaliano: "Cinto Euganeo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "C713"
      },
      {
        denominazioneInItaliano: "Cittadella",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "C743"
      },
      {
        denominazioneInItaliano: "Codevigo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "C812"
      },
      {
        denominazioneInItaliano: "Conselve",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "C964"
      },
      {
        denominazioneInItaliano: "Correzzola",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "D040"
      },
      {
        denominazioneInItaliano: "Curtarolo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "D226"
      },
      {
        denominazioneInItaliano: "Este",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "D442"
      },
      {
        denominazioneInItaliano: "Fontaniva",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "D679"
      },
      {
        denominazioneInItaliano: "Galliera Veneta",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "D879"
      },
      {
        denominazioneInItaliano: "Galzignano Terme",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "D889"
      },
      {
        denominazioneInItaliano: "Gazzo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "D956"
      },
      {
        denominazioneInItaliano: "Grantorto",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "E145"
      },
      {
        denominazioneInItaliano: "Granze",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "E146"
      },
      {
        denominazioneInItaliano: "Legnaro",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "E515"
      },
      {
        denominazioneInItaliano: "Limena",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "E592"
      },
      {
        denominazioneInItaliano: "Loreggia",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "E684"
      },
      {
        denominazioneInItaliano: "Lozzo Atestino",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "E709"
      },
      {
        denominazioneInItaliano: "Maserà di Padova",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F011"
      },
      {
        denominazioneInItaliano: "Masi",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F013"
      },
      {
        denominazioneInItaliano: "Massanzago",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F033"
      },
      {
        denominazioneInItaliano: "Megliadino San Vitale",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F092"
      },
      {
        denominazioneInItaliano: "Merlara",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F148"
      },
      {
        denominazioneInItaliano: "Mestrino",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F161"
      },
      {
        denominazioneInItaliano: "Monselice",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F382"
      },
      {
        denominazioneInItaliano: "Montagnana",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F394"
      },
      {
        denominazioneInItaliano: "Montegrotto Terme",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F529"
      },
      {
        denominazioneInItaliano: "Noventa Padovana",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "F962"
      },
      {
        denominazioneInItaliano: "Ospedaletto Euganeo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G167"
      },
      {
        denominazioneInItaliano: "Padova",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G224"
      },
      {
        denominazioneInItaliano: "Pernumia",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G461"
      },
      {
        denominazioneInItaliano: "Piacenza d'Adige",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G534"
      },
      {
        denominazioneInItaliano: "Piazzola sul Brenta",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G587"
      },
      {
        denominazioneInItaliano: "Piombino Dese",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G688"
      },
      {
        denominazioneInItaliano: "Piove di Sacco",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G693"
      },
      {
        denominazioneInItaliano: "Polverara",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G802"
      },
      {
        denominazioneInItaliano: "Ponso",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G823"
      },
      {
        denominazioneInItaliano: "Pontelongo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G850"
      },
      {
        denominazioneInItaliano: "Ponte San Nicolò",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G855"
      },
      {
        denominazioneInItaliano: "Pozzonovo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "G963"
      },
      {
        denominazioneInItaliano: "Rovolon",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "H622"
      },
      {
        denominazioneInItaliano: "Rubano",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "H625"
      },
      {
        denominazioneInItaliano: "Saccolongo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "H655"
      },
      {
        denominazioneInItaliano: "San Giorgio delle Pertiche",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "H893"
      },
      {
        denominazioneInItaliano: "San Giorgio in Bosco",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "H897"
      },
      {
        denominazioneInItaliano: "San Martino di Lupari",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I008"
      },
      {
        denominazioneInItaliano: "San Pietro in Gu",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I107"
      },
      {
        denominazioneInItaliano: "San Pietro Viminario",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I120"
      },
      {
        denominazioneInItaliano: "Santa Giustina in Colle",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I207"
      },
      {
        denominazioneInItaliano: "Sant'Angelo di Piove di Sacco",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I275"
      },
      {
        denominazioneInItaliano: "Sant'Elena",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I319"
      },
      {
        denominazioneInItaliano: "Sant'Urbano",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I375"
      },
      {
        denominazioneInItaliano: "Saonara",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I418"
      },
      {
        denominazioneInItaliano: "Selvazzano Dentro",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I595"
      },
      {
        denominazioneInItaliano: "Solesino",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I799"
      },
      {
        denominazioneInItaliano: "Stanghella",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "I938"
      },
      {
        denominazioneInItaliano: "Teolo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L100"
      },
      {
        denominazioneInItaliano: "Terrassa Padovana",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L132"
      },
      {
        denominazioneInItaliano: "Tombolo",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L199"
      },
      {
        denominazioneInItaliano: "Torreglia",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L270"
      },
      {
        denominazioneInItaliano: "Trebaseleghe",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L349"
      },
      {
        denominazioneInItaliano: "Tribano",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L414"
      },
      {
        denominazioneInItaliano: "Urbana",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L497"
      },
      {
        denominazioneInItaliano: "Veggiano",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L710"
      },
      {
        denominazioneInItaliano: "Vescovana",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L805"
      },
      {
        denominazioneInItaliano: "Vighizzolo d'Este",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L878"
      },
      {
        denominazioneInItaliano: "Vigodarzere",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L892"
      },
      {
        denominazioneInItaliano: "Vigonza",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L900"
      },
      {
        denominazioneInItaliano: "Villa del Conte",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L934"
      },
      {
        denominazioneInItaliano: "Villa Estense",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L937"
      },
      {
        denominazioneInItaliano: "Villafranca Padovana",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L947"
      },
      {
        denominazioneInItaliano: "Villanova di Camposampiero",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "L979"
      },
      {
        denominazioneInItaliano: "Vo'",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "M103"
      },
      {
        denominazioneInItaliano: "Due Carrare",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "M300"
      },
      {
        denominazioneInItaliano: "Borgo Veneto",
        regione: "Veneto",
        provincia: "Padova",
        codiceCatastale: "M402"
      },
      {
        denominazioneInItaliano: "Adria",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "A059"
      },
      {
        denominazioneInItaliano: "Ariano nel Polesine",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "A400"
      },
      {
        denominazioneInItaliano: "Arquà Polesine",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "A435"
      },
      {
        denominazioneInItaliano: "Badia Polesine",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "A539"
      },
      {
        denominazioneInItaliano: "Bagnolo di Po",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "A574"
      },
      {
        denominazioneInItaliano: "Bergantino",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "A795"
      },
      {
        denominazioneInItaliano: "Bosaro",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "B069"
      },
      {
        denominazioneInItaliano: "Calto",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "B432"
      },
      {
        denominazioneInItaliano: "Canaro",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "B578"
      },
      {
        denominazioneInItaliano: "Canda",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "B582"
      },
      {
        denominazioneInItaliano: "Castelguglielmo",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "C122"
      },
      {
        denominazioneInItaliano: "Castelmassa",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "C207"
      },
      {
        denominazioneInItaliano: "Castelnovo Bariano",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "C215"
      },
      {
        denominazioneInItaliano: "Ceneselli",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "C461"
      },
      {
        denominazioneInItaliano: "Ceregnano",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "C500"
      },
      {
        denominazioneInItaliano: "Corbola",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "C987"
      },
      {
        denominazioneInItaliano: "Costa di Rovigo",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "D105"
      },
      {
        denominazioneInItaliano: "Crespino",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "D161"
      },
      {
        denominazioneInItaliano: "Ficarolo",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "D568"
      },
      {
        denominazioneInItaliano: "Fiesso Umbertiano",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "D577"
      },
      {
        denominazioneInItaliano: "Frassinelle Polesine",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "D776"
      },
      {
        denominazioneInItaliano: "Fratta Polesine",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "D788"
      },
      {
        denominazioneInItaliano: "Gaiba",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "D855"
      },
      {
        denominazioneInItaliano: "Gavello",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "D942"
      },
      {
        denominazioneInItaliano: "Giacciano con Baruchella",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "E008"
      },
      {
        denominazioneInItaliano: "Guarda Veneta",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "E240"
      },
      {
        denominazioneInItaliano: "Lendinara",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "E522"
      },
      {
        denominazioneInItaliano: "Loreo",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "E689"
      },
      {
        denominazioneInItaliano: "Lusia",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "E761"
      },
      {
        denominazioneInItaliano: "Melara",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "F095"
      },
      {
        denominazioneInItaliano: "Occhiobello",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "F994"
      },
      {
        denominazioneInItaliano: "Papozze",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "G323"
      },
      {
        denominazioneInItaliano: "Pettorazza Grimani",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "G525"
      },
      {
        denominazioneInItaliano: "Pincara",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "G673"
      },
      {
        denominazioneInItaliano: "Polesella",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "G782"
      },
      {
        denominazioneInItaliano: "Pontecchio Polesine",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "G836"
      },
      {
        denominazioneInItaliano: "Porto Tolle",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "G923"
      },
      {
        denominazioneInItaliano: "Rosolina",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "H573"
      },
      {
        denominazioneInItaliano: "Rovigo",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "H620"
      },
      {
        denominazioneInItaliano: "Salara",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "H689"
      },
      {
        denominazioneInItaliano: "San Bellino",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "H768"
      },
      {
        denominazioneInItaliano: "San Martino di Venezze",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "H996"
      },
      {
        denominazioneInItaliano: "Stienta",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "I953"
      },
      {
        denominazioneInItaliano: "Taglio di Po",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "L026"
      },
      {
        denominazioneInItaliano: "Trecenta",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "L359"
      },
      {
        denominazioneInItaliano: "Villadose",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "L939"
      },
      {
        denominazioneInItaliano: "Villamarzana",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "L967"
      },
      {
        denominazioneInItaliano: "Villanova del Ghebbo",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "L985"
      },
      {
        denominazioneInItaliano: "Villanova Marchesana",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "L988"
      },
      {
        denominazioneInItaliano: "Porto Viro",
        regione: "Veneto",
        provincia: "Rovigo",
        codiceCatastale: "G926"
      },
      {
        denominazioneInItaliano: "Aiello del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A103"
      },
      {
        denominazioneInItaliano: "Amaro",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A254"
      },
      {
        denominazioneInItaliano: "Ampezzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A267"
      },
      {
        denominazioneInItaliano: "Aquileia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A346"
      },
      {
        denominazioneInItaliano: "Arta Terme",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A447"
      },
      {
        denominazioneInItaliano: "Artegna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A448"
      },
      {
        denominazioneInItaliano: "Attimis",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A491"
      },
      {
        denominazioneInItaliano: "Bagnaria Arsa",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A553"
      },
      {
        denominazioneInItaliano: "Basiliano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A700"
      },
      {
        denominazioneInItaliano: "Bertiolo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A810"
      },
      {
        denominazioneInItaliano: "Bicinicco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A855"
      },
      {
        denominazioneInItaliano: "Bordano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "A983"
      },
      {
        denominazioneInItaliano: "Buja",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "B259"
      },
      {
        denominazioneInItaliano: "Buttrio",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "B309"
      },
      {
        denominazioneInItaliano: "Camino al Tagliamento",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "B483"
      },
      {
        denominazioneInItaliano: "Campoformido",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "B536"
      },
      {
        denominazioneInItaliano: "Carlino",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "B788"
      },
      {
        denominazioneInItaliano: "Cassacco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "B994"
      },
      {
        denominazioneInItaliano: "Castions di Strada",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C327"
      },
      {
        denominazioneInItaliano: "Cavazzo Carnico",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C389"
      },
      {
        denominazioneInItaliano: "Cercivento",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C494"
      },
      {
        denominazioneInItaliano: "Cervignano del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C556"
      },
      {
        denominazioneInItaliano: "Chiopris-Viscone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C641"
      },
      {
        denominazioneInItaliano: "Chiusaforte",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C656"
      },
      {
        denominazioneInItaliano: "Cividale del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C758"
      },
      {
        denominazioneInItaliano: "Codroipo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C817"
      },
      {
        denominazioneInItaliano: "Colloredo di Monte Albano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C885"
      },
      {
        denominazioneInItaliano: "Comeglians",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "C918"
      },
      {
        denominazioneInItaliano: "Corno di Rosazzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D027"
      },
      {
        denominazioneInItaliano: "Coseano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D085"
      },
      {
        denominazioneInItaliano: "Dignano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D300"
      },
      {
        denominazioneInItaliano: "Dogna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D316"
      },
      {
        denominazioneInItaliano: "Drenchia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D366"
      },
      {
        denominazioneInItaliano: "Enemonzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D408"
      },
      {
        denominazioneInItaliano: "Faedis",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D455"
      },
      {
        denominazioneInItaliano: "Fagagna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D461"
      },
      {
        denominazioneInItaliano: "Flaibano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D630"
      },
      {
        denominazioneInItaliano: "Forni Avoltri",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D718"
      },
      {
        denominazioneInItaliano: "Forni di Sopra",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D719"
      },
      {
        denominazioneInItaliano: "Forni di Sotto",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D720"
      },
      {
        denominazioneInItaliano: "Gemona del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D962"
      },
      {
        denominazioneInItaliano: "Gonars",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E083"
      },
      {
        denominazioneInItaliano: "Grimacco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E179"
      },
      {
        denominazioneInItaliano: "Latisana",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E473"
      },
      {
        denominazioneInItaliano: "Lauco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E476"
      },
      {
        denominazioneInItaliano: "Lestizza",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E553"
      },
      {
        denominazioneInItaliano: "Lignano Sabbiadoro",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E584"
      },
      {
        denominazioneInItaliano: "Lusevera",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E760"
      },
      {
        denominazioneInItaliano: "Magnano in Riviera",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E820"
      },
      {
        denominazioneInItaliano: "Majano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E833"
      },
      {
        denominazioneInItaliano: "Malborghetto Valbruna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E847"
      },
      {
        denominazioneInItaliano: "Manzano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E899"
      },
      {
        denominazioneInItaliano: "Marano Lagunare",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E910"
      },
      {
        denominazioneInItaliano: "Martignacco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "E982"
      },
      {
        denominazioneInItaliano: "Mereto di Tomba",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "F144"
      },
      {
        denominazioneInItaliano: "Moggio Udinese",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "F266"
      },
      {
        denominazioneInItaliano: "Moimacco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "F275"
      },
      {
        denominazioneInItaliano: "Montenars",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "F574"
      },
      {
        denominazioneInItaliano: "Mortegliano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "F756"
      },
      {
        denominazioneInItaliano: "Moruzzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "F760"
      },
      {
        denominazioneInItaliano: "Muzzana del Turgnano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "F832"
      },
      {
        denominazioneInItaliano: "Nimis",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "F898"
      },
      {
        denominazioneInItaliano: "Osoppo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G163"
      },
      {
        denominazioneInItaliano: "Ovaro",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G198"
      },
      {
        denominazioneInItaliano: "Pagnacco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G238"
      },
      {
        denominazioneInItaliano: "Palazzolo dello Stella",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G268"
      },
      {
        denominazioneInItaliano: "Palmanova",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G284"
      },
      {
        denominazioneInItaliano: "Paluzza",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G300"
      },
      {
        denominazioneInItaliano: "Pasian di Prato",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G352"
      },
      {
        denominazioneInItaliano: "Paularo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G381"
      },
      {
        denominazioneInItaliano: "Pavia di Udine",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G389"
      },
      {
        denominazioneInItaliano: "Pocenia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G743"
      },
      {
        denominazioneInItaliano: "Pontebba",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G831"
      },
      {
        denominazioneInItaliano: "Porpetto",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G891"
      },
      {
        denominazioneInItaliano: "Povoletto",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G949"
      },
      {
        denominazioneInItaliano: "Pozzuolo del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G966"
      },
      {
        denominazioneInItaliano: "Pradamano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G969"
      },
      {
        denominazioneInItaliano: "Prato Carnico",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H002"
      },
      {
        denominazioneInItaliano: "Precenicco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H014"
      },
      {
        denominazioneInItaliano: "Premariacco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H029"
      },
      {
        denominazioneInItaliano: "Preone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H038"
      },
      {
        denominazioneInItaliano: "Prepotto",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H040"
      },
      {
        denominazioneInItaliano: "Pulfero",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H089"
      },
      {
        denominazioneInItaliano: "Ragogna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H161"
      },
      {
        denominazioneInItaliano: "Ravascletto",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H196"
      },
      {
        denominazioneInItaliano: "Raveo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H200"
      },
      {
        denominazioneInItaliano: "Reana del Rojale",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H206"
      },
      {
        denominazioneInItaliano: "Remanzacco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H229"
      },
      {
        denominazioneInItaliano: "Resia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H242"
      },
      {
        denominazioneInItaliano: "Resiutta",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H244"
      },
      {
        denominazioneInItaliano: "Rigolato",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H289"
      },
      {
        denominazioneInItaliano: "Rive d'Arcano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H347"
      },
      {
        denominazioneInItaliano: "Ronchis",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H533"
      },
      {
        denominazioneInItaliano: "Ruda",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H629"
      },
      {
        denominazioneInItaliano: "San Daniele del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H816"
      },
      {
        denominazioneInItaliano: "San Giorgio di Nogaro",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H895"
      },
      {
        denominazioneInItaliano: "San Giovanni al Natisone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H906"
      },
      {
        denominazioneInItaliano: "San Leonardo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "H951"
      },
      {
        denominazioneInItaliano: "San Pietro al Natisone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I092"
      },
      {
        denominazioneInItaliano: "Santa Maria la Longa",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I248"
      },
      {
        denominazioneInItaliano: "San Vito al Torre",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I404"
      },
      {
        denominazioneInItaliano: "San Vito di Fagagna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I405"
      },
      {
        denominazioneInItaliano: "Sauris",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I464"
      },
      {
        denominazioneInItaliano: "Savogna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I478"
      },
      {
        denominazioneInItaliano: "Sedegliano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I562"
      },
      {
        denominazioneInItaliano: "Socchieve",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I777"
      },
      {
        denominazioneInItaliano: "Stregna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I974"
      },
      {
        denominazioneInItaliano: "Sutrio",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L018"
      },
      {
        denominazioneInItaliano: "Taipana",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "G736"
      },
      {
        denominazioneInItaliano: "Talmassons",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L039"
      },
      {
        denominazioneInItaliano: "Tarcento",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L050"
      },
      {
        denominazioneInItaliano: "Tarvisio",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L057"
      },
      {
        denominazioneInItaliano: "Tavagnacco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L065"
      },
      {
        denominazioneInItaliano: "Terzo d'Aquileia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L144"
      },
      {
        denominazioneInItaliano: "Tolmezzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L195"
      },
      {
        denominazioneInItaliano: "Torreano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L246"
      },
      {
        denominazioneInItaliano: "Torviscosa",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L309"
      },
      {
        denominazioneInItaliano: "Trasaghis",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L335"
      },
      {
        denominazioneInItaliano: "Treppo Grande",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L382"
      },
      {
        denominazioneInItaliano: "Tricesimo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L421"
      },
      {
        denominazioneInItaliano: "Trivignano Udinese",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L438"
      },
      {
        denominazioneInItaliano: "Udine",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L483"
      },
      {
        denominazioneInItaliano: "Varmo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L686"
      },
      {
        denominazioneInItaliano: "Venzone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L743"
      },
      {
        denominazioneInItaliano: "Verzegnis",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L801"
      },
      {
        denominazioneInItaliano: "Villa Santina",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "L909"
      },
      {
        denominazioneInItaliano: "Visco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "M073"
      },
      {
        denominazioneInItaliano: "Zuglio",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "M200"
      },
      {
        denominazioneInItaliano: "Forgaria nel Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "D700"
      },
      {
        denominazioneInItaliano: "Campolongo Tapogliano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "M311"
      },
      {
        denominazioneInItaliano: "Rivignano Teor",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "M317"
      },
      {
        denominazioneInItaliano: "Sappada",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "I421"
      },
      {
        denominazioneInItaliano: "Fiumicello Villa Vicentina",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "M400"
      },
      {
        denominazioneInItaliano: "Treppo Ligosullo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Udine",
        codiceCatastale: "M399"
      },
      {
        denominazioneInItaliano: "Capriva del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "B712"
      },
      {
        denominazioneInItaliano: "Cormons",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "D014"
      },
      {
        denominazioneInItaliano: "Doberdò del Lago",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "D312"
      },
      {
        denominazioneInItaliano: "Dolegna del Collio",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "D321"
      },
      {
        denominazioneInItaliano: "Farra d'Isonzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "D504"
      },
      {
        denominazioneInItaliano: "Fogliano Redipuglia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "D645"
      },
      {
        denominazioneInItaliano: "Gorizia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "E098"
      },
      {
        denominazioneInItaliano: "Gradisca d'Isonzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "E124"
      },
      {
        denominazioneInItaliano: "Grado",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "E125"
      },
      {
        denominazioneInItaliano: "Mariano del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "E952"
      },
      {
        denominazioneInItaliano: "Medea",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "F081"
      },
      {
        denominazioneInItaliano: "Monfalcone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "F356"
      },
      {
        denominazioneInItaliano: "Moraro",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "F710"
      },
      {
        denominazioneInItaliano: "Mossa",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "F767"
      },
      {
        denominazioneInItaliano: "Romans d'Isonzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "H514"
      },
      {
        denominazioneInItaliano: "Ronchi dei Legionari",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "H531"
      },
      {
        denominazioneInItaliano: "Sagrado",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "H665"
      },
      {
        denominazioneInItaliano: "San Canzian d'Isonzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "H787"
      },
      {
        denominazioneInItaliano: "San Floriano del Collio",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "H845"
      },
      {
        denominazioneInItaliano: "San Lorenzo Isontino",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "H964"
      },
      {
        denominazioneInItaliano: "San Pier d'Isonzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "I082"
      },
      {
        denominazioneInItaliano: "Savogna d'Isonzo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "I479"
      },
      {
        denominazioneInItaliano: "Staranzano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "I939"
      },
      {
        denominazioneInItaliano: "Turriaco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "L474"
      },
      {
        denominazioneInItaliano: "Villesse",
        regione: "Friuli-Venezia Giulia",
        provincia: "Gorizia",
        codiceCatastale: "M043"
      },
      {
        denominazioneInItaliano: "Duino Aurisina",
        regione: "Friuli-Venezia Giulia",
        provincia: "Trieste",
        codiceCatastale: "D383"
      },
      {
        denominazioneInItaliano: "Monrupino",
        regione: "Friuli-Venezia Giulia",
        provincia: "Trieste",
        codiceCatastale: "F378"
      },
      {
        denominazioneInItaliano: "Muggia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Trieste",
        codiceCatastale: "F795"
      },
      {
        denominazioneInItaliano: "San Dorligo della Valle",
        regione: "Friuli-Venezia Giulia",
        provincia: "Trieste",
        codiceCatastale: "D324"
      },
      {
        denominazioneInItaliano: "Sgonico",
        regione: "Friuli-Venezia Giulia",
        provincia: "Trieste",
        codiceCatastale: "I715"
      },
      {
        denominazioneInItaliano: "Trieste",
        regione: "Friuli-Venezia Giulia",
        provincia: "Trieste",
        codiceCatastale: "L424"
      },
      {
        denominazioneInItaliano: "Andreis",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "A283"
      },
      {
        denominazioneInItaliano: "Arba",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "A354"
      },
      {
        denominazioneInItaliano: "Aviano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "A516"
      },
      {
        denominazioneInItaliano: "Azzano Decimo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "A530"
      },
      {
        denominazioneInItaliano: "Barcis",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "A640"
      },
      {
        denominazioneInItaliano: "Brugnera",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "B215"
      },
      {
        denominazioneInItaliano: "Budoia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "B247"
      },
      {
        denominazioneInItaliano: "Caneva",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "B598"
      },
      {
        denominazioneInItaliano: "Casarsa della Delizia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "B940"
      },
      {
        denominazioneInItaliano: "Castelnovo del Friuli",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "C217"
      },
      {
        denominazioneInItaliano: "Cavasso Nuovo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "C385"
      },
      {
        denominazioneInItaliano: "Chions",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "C640"
      },
      {
        denominazioneInItaliano: "Cimolais",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "C699"
      },
      {
        denominazioneInItaliano: "Claut",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "C790"
      },
      {
        denominazioneInItaliano: "Clauzetto",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "C791"
      },
      {
        denominazioneInItaliano: "Cordenons",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "C991"
      },
      {
        denominazioneInItaliano: "Cordovado",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "C993"
      },
      {
        denominazioneInItaliano: "Erto e Casso",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "D426"
      },
      {
        denominazioneInItaliano: "Fanna",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "D487"
      },
      {
        denominazioneInItaliano: "Fiume Veneto",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "D621"
      },
      {
        denominazioneInItaliano: "Fontanafredda",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "D670"
      },
      {
        denominazioneInItaliano: "Frisanco",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "D804"
      },
      {
        denominazioneInItaliano: "Maniago",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "E889"
      },
      {
        denominazioneInItaliano: "Meduno",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "F089"
      },
      {
        denominazioneInItaliano: "Montereale Valcellina",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "F596"
      },
      {
        denominazioneInItaliano: "Morsano al Tagliamento",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "F750"
      },
      {
        denominazioneInItaliano: "Pasiano di Pordenone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "G353"
      },
      {
        denominazioneInItaliano: "Pinzano al Tagliamento",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "G680"
      },
      {
        denominazioneInItaliano: "Polcenigo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "G780"
      },
      {
        denominazioneInItaliano: "Porcia",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "G886"
      },
      {
        denominazioneInItaliano: "Pordenone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "G888"
      },
      {
        denominazioneInItaliano: "Prata di Pordenone",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "G994"
      },
      {
        denominazioneInItaliano: "Pravisdomini",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "H010"
      },
      {
        denominazioneInItaliano: "Roveredo in Piano",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "H609"
      },
      {
        denominazioneInItaliano: "Sacile",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "H657"
      },
      {
        denominazioneInItaliano: "San Giorgio della Richinvelda",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "H891"
      },
      {
        denominazioneInItaliano: "San Martino al Tagliamento",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "H999"
      },
      {
        denominazioneInItaliano: "San Quirino",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "I136"
      },
      {
        denominazioneInItaliano: "San Vito al Tagliamento",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "I403"
      },
      {
        denominazioneInItaliano: "Sequals",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "I621"
      },
      {
        denominazioneInItaliano: "Sesto al Reghena",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "I686"
      },
      {
        denominazioneInItaliano: "Spilimbergo",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "I904"
      },
      {
        denominazioneInItaliano: "Tramonti di Sopra",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "L324"
      },
      {
        denominazioneInItaliano: "Tramonti di Sotto",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "L325"
      },
      {
        denominazioneInItaliano: "Travesio",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "L347"
      },
      {
        denominazioneInItaliano: "Vito d'Asio",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "M085"
      },
      {
        denominazioneInItaliano: "Vivaro",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "M096"
      },
      {
        denominazioneInItaliano: "Zoppola",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "M190"
      },
      {
        denominazioneInItaliano: "Vajont",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "M265"
      },
      {
        denominazioneInItaliano: "Valvasone Arzene",
        regione: "Friuli-Venezia Giulia",
        provincia: "Pordenone",
        codiceCatastale: "M346"
      },
      {
        denominazioneInItaliano: "Airole",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A111"
      },
      {
        denominazioneInItaliano: "Apricale",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A338"
      },
      {
        denominazioneInItaliano: "Aquila d'Arroscia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A344"
      },
      {
        denominazioneInItaliano: "Armo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A418"
      },
      {
        denominazioneInItaliano: "Aurigo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A499"
      },
      {
        denominazioneInItaliano: "Badalucco",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A536"
      },
      {
        denominazioneInItaliano: "Bajardo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A581"
      },
      {
        denominazioneInItaliano: "Bordighera",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A984"
      },
      {
        denominazioneInItaliano: "Borghetto d'Arroscia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "A993"
      },
      {
        denominazioneInItaliano: "Borgomaro",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "B020"
      },
      {
        denominazioneInItaliano: "Camporosso",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "B559"
      },
      {
        denominazioneInItaliano: "Caravonica",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "B734"
      },
      {
        denominazioneInItaliano: "Castellaro",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C143"
      },
      {
        denominazioneInItaliano: "Castel Vittorio",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C110"
      },
      {
        denominazioneInItaliano: "Ceriana",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C511"
      },
      {
        denominazioneInItaliano: "Cervo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C559"
      },
      {
        denominazioneInItaliano: "Cesio",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C578"
      },
      {
        denominazioneInItaliano: "Chiusanico",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C657"
      },
      {
        denominazioneInItaliano: "Chiusavecchia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C660"
      },
      {
        denominazioneInItaliano: "Cipressa",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C718"
      },
      {
        denominazioneInItaliano: "Civezza",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "C755"
      },
      {
        denominazioneInItaliano: "Cosio d'Arroscia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "D087"
      },
      {
        denominazioneInItaliano: "Costarainera",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "D114"
      },
      {
        denominazioneInItaliano: "Diano Arentino",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "D293"
      },
      {
        denominazioneInItaliano: "Diano Castello",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "D296"
      },
      {
        denominazioneInItaliano: "Diano Marina",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "D297"
      },
      {
        denominazioneInItaliano: "Diano San Pietro",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "D298"
      },
      {
        denominazioneInItaliano: "Dolceacqua",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "D318"
      },
      {
        denominazioneInItaliano: "Dolcedo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "D319"
      },
      {
        denominazioneInItaliano: "Imperia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "E290"
      },
      {
        denominazioneInItaliano: "Isolabona",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "E346"
      },
      {
        denominazioneInItaliano: "Lucinasco",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "E719"
      },
      {
        denominazioneInItaliano: "Mendatica",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "F123"
      },
      {
        denominazioneInItaliano: "Molini di Triora",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "F290"
      },
      {
        denominazioneInItaliano: "Montegrosso Pian Latte",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "F528"
      },
      {
        denominazioneInItaliano: "Olivetta San Michele",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G041"
      },
      {
        denominazioneInItaliano: "Ospedaletti",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G164"
      },
      {
        denominazioneInItaliano: "Perinaldo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G454"
      },
      {
        denominazioneInItaliano: "Pietrabruna",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G607"
      },
      {
        denominazioneInItaliano: "Pieve di Teco",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G632"
      },
      {
        denominazioneInItaliano: "Pigna",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G660"
      },
      {
        denominazioneInItaliano: "Pompeiana",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G814"
      },
      {
        denominazioneInItaliano: "Pontedassio",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G840"
      },
      {
        denominazioneInItaliano: "Pornassio",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "G890"
      },
      {
        denominazioneInItaliano: "Prelà",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "H027"
      },
      {
        denominazioneInItaliano: "Ranzo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "H180"
      },
      {
        denominazioneInItaliano: "Rezzo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "H257"
      },
      {
        denominazioneInItaliano: "Riva Ligure",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "H328"
      },
      {
        denominazioneInItaliano: "Rocchetta Nervina",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "H460"
      },
      {
        denominazioneInItaliano: "San Bartolomeo al Mare",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "H763"
      },
      {
        denominazioneInItaliano: "San Biagio della Cima",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "H780"
      },
      {
        denominazioneInItaliano: "San Lorenzo al Mare",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "H957"
      },
      {
        denominazioneInItaliano: "Sanremo",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "I138"
      },
      {
        denominazioneInItaliano: "Santo Stefano al Mare",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "I365"
      },
      {
        denominazioneInItaliano: "Seborga",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "I556"
      },
      {
        denominazioneInItaliano: "Soldano",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "I796"
      },
      {
        denominazioneInItaliano: "Taggia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L024"
      },
      {
        denominazioneInItaliano: "Terzorio",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L146"
      },
      {
        denominazioneInItaliano: "Triora",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L430"
      },
      {
        denominazioneInItaliano: "Vallebona",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L596"
      },
      {
        denominazioneInItaliano: "Vallecrosia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L599"
      },
      {
        denominazioneInItaliano: "Vasia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L693"
      },
      {
        denominazioneInItaliano: "Ventimiglia",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L741"
      },
      {
        denominazioneInItaliano: "Vessalico",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L809"
      },
      {
        denominazioneInItaliano: "Villa Faraldi",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "L943"
      },
      {
        denominazioneInItaliano: "Montalto Carpasio",
        regione: "Liguria",
        provincia: "Imperia",
        codiceCatastale: "M387"
      },
      {
        denominazioneInItaliano: "Alassio",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A122"
      },
      {
        denominazioneInItaliano: "Albenga",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A145"
      },
      {
        denominazioneInItaliano: "Albissola Marina",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A165"
      },
      {
        denominazioneInItaliano: "Albisola Superiore",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A166"
      },
      {
        denominazioneInItaliano: "Altare",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A226"
      },
      {
        denominazioneInItaliano: "Andora",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A278"
      },
      {
        denominazioneInItaliano: "Arnasco",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A422"
      },
      {
        denominazioneInItaliano: "Balestrino",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A593"
      },
      {
        denominazioneInItaliano: "Bardineto",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A647"
      },
      {
        denominazioneInItaliano: "Bergeggi",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A796"
      },
      {
        denominazioneInItaliano: "Boissano",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A931"
      },
      {
        denominazioneInItaliano: "Borghetto Santo Spirito",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "A999"
      },
      {
        denominazioneInItaliano: "Borgio Verezzi",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "B005"
      },
      {
        denominazioneInItaliano: "Bormida",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "B048"
      },
      {
        denominazioneInItaliano: "Cairo Montenotte",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "B369"
      },
      {
        denominazioneInItaliano: "Calice Ligure",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "B409"
      },
      {
        denominazioneInItaliano: "Calizzano",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "B416"
      },
      {
        denominazioneInItaliano: "Carcare",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "B748"
      },
      {
        denominazioneInItaliano: "Casanova Lerrone",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "B927"
      },
      {
        denominazioneInItaliano: "Castelbianco",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "C063"
      },
      {
        denominazioneInItaliano: "Castelvecchio di Rocca Barbena",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "C276"
      },
      {
        denominazioneInItaliano: "Celle Ligure",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "C443"
      },
      {
        denominazioneInItaliano: "Cengio",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "C463"
      },
      {
        denominazioneInItaliano: "Ceriale",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "C510"
      },
      {
        denominazioneInItaliano: "Cisano sul Neva",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "C729"
      },
      {
        denominazioneInItaliano: "Cosseria",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "D095"
      },
      {
        denominazioneInItaliano: "Dego",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "D264"
      },
      {
        denominazioneInItaliano: "Erli",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "D424"
      },
      {
        denominazioneInItaliano: "Finale Ligure",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "D600"
      },
      {
        denominazioneInItaliano: "Garlenda",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "D927"
      },
      {
        denominazioneInItaliano: "Giustenice",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "E064"
      },
      {
        denominazioneInItaliano: "Giusvalla",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "E066"
      },
      {
        denominazioneInItaliano: "Laigueglia",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "E414"
      },
      {
        denominazioneInItaliano: "Loano",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "E632"
      },
      {
        denominazioneInItaliano: "Magliolo",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "E816"
      },
      {
        denominazioneInItaliano: "Mallare",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "E860"
      },
      {
        denominazioneInItaliano: "Massimino",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "F046"
      },
      {
        denominazioneInItaliano: "Millesimo",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "F213"
      },
      {
        denominazioneInItaliano: "Mioglia",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "F226"
      },
      {
        denominazioneInItaliano: "Murialdo",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "F813"
      },
      {
        denominazioneInItaliano: "Nasino",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "F847"
      },
      {
        denominazioneInItaliano: "Noli",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "F926"
      },
      {
        denominazioneInItaliano: "Onzo",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "G076"
      },
      {
        denominazioneInItaliano: "Orco Feglino",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "D522"
      },
      {
        denominazioneInItaliano: "Ortovero",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "G144"
      },
      {
        denominazioneInItaliano: "Osiglia",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "G155"
      },
      {
        denominazioneInItaliano: "Pallare",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "G281"
      },
      {
        denominazioneInItaliano: "Piana Crixia",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "G542"
      },
      {
        denominazioneInItaliano: "Pietra Ligure",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "G605"
      },
      {
        denominazioneInItaliano: "Plodio",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "G741"
      },
      {
        denominazioneInItaliano: "Pontinvrea",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "G866"
      },
      {
        denominazioneInItaliano: "Quiliano",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "H126"
      },
      {
        denominazioneInItaliano: "Rialto",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "H266"
      },
      {
        denominazioneInItaliano: "Roccavignale",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "H452"
      },
      {
        denominazioneInItaliano: "Sassello",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "I453"
      },
      {
        denominazioneInItaliano: "Savona",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "I480"
      },
      {
        denominazioneInItaliano: "Spotorno",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "I926"
      },
      {
        denominazioneInItaliano: "Stella",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "I946"
      },
      {
        denominazioneInItaliano: "Stellanello",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "I947"
      },
      {
        denominazioneInItaliano: "Testico",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L152"
      },
      {
        denominazioneInItaliano: "Toirano",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L190"
      },
      {
        denominazioneInItaliano: "Tovo San Giacomo",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L315"
      },
      {
        denominazioneInItaliano: "Urbe",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L499"
      },
      {
        denominazioneInItaliano: "Vado Ligure",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L528"
      },
      {
        denominazioneInItaliano: "Varazze",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L675"
      },
      {
        denominazioneInItaliano: "Vendone",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L730"
      },
      {
        denominazioneInItaliano: "Vezzi Portio",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L823"
      },
      {
        denominazioneInItaliano: "Villanova d'Albenga",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "L975"
      },
      {
        denominazioneInItaliano: "Zuccarello",
        regione: "Liguria",
        provincia: "Savona",
        codiceCatastale: "M197"
      },
      {
        denominazioneInItaliano: "Arenzano",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "A388"
      },
      {
        denominazioneInItaliano: "Avegno",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "A506"
      },
      {
        denominazioneInItaliano: "Bargagli",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "A658"
      },
      {
        denominazioneInItaliano: "Bogliasco",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "A922"
      },
      {
        denominazioneInItaliano: "Borzonasca",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "B067"
      },
      {
        denominazioneInItaliano: "Busalla",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "B282"
      },
      {
        denominazioneInItaliano: "Camogli",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "B490"
      },
      {
        denominazioneInItaliano: "Campo Ligure",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "B538"
      },
      {
        denominazioneInItaliano: "Campomorone",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "B551"
      },
      {
        denominazioneInItaliano: "Carasco",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "B726"
      },
      {
        denominazioneInItaliano: "Casarza Ligure",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "B939"
      },
      {
        denominazioneInItaliano: "Casella",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "B956"
      },
      {
        denominazioneInItaliano: "Castiglione Chiavarese",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "C302"
      },
      {
        denominazioneInItaliano: "Ceranesi",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "C481"
      },
      {
        denominazioneInItaliano: "Chiavari",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "C621"
      },
      {
        denominazioneInItaliano: "Cicagna",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "C673"
      },
      {
        denominazioneInItaliano: "Cogoleto",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "C823"
      },
      {
        denominazioneInItaliano: "Cogorno",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "C826"
      },
      {
        denominazioneInItaliano: "Coreglia Ligure",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "C995"
      },
      {
        denominazioneInItaliano: "Crocefieschi",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "D175"
      },
      {
        denominazioneInItaliano: "Davagna",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "D255"
      },
      {
        denominazioneInItaliano: "Fascia",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "D509"
      },
      {
        denominazioneInItaliano: "Favale di Malvaro",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "D512"
      },
      {
        denominazioneInItaliano: "Fontanigorda",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "D677"
      },
      {
        denominazioneInItaliano: "Genova",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "D969"
      },
      {
        denominazioneInItaliano: "Gorreto",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "E109"
      },
      {
        denominazioneInItaliano: "Isola del Cantone",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "E341"
      },
      {
        denominazioneInItaliano: "Lavagna",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "E488"
      },
      {
        denominazioneInItaliano: "Leivi",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "E519"
      },
      {
        denominazioneInItaliano: "Lorsica",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "E695"
      },
      {
        denominazioneInItaliano: "Lumarzo",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "E737"
      },
      {
        denominazioneInItaliano: "Masone",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F020"
      },
      {
        denominazioneInItaliano: "Mele",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F098"
      },
      {
        denominazioneInItaliano: "Mezzanego",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F173"
      },
      {
        denominazioneInItaliano: "Mignanego",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F202"
      },
      {
        denominazioneInItaliano: "Moconesi",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F256"
      },
      {
        denominazioneInItaliano: "Moneglia",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F354"
      },
      {
        denominazioneInItaliano: "Montebruno",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F445"
      },
      {
        denominazioneInItaliano: "Montoggio",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F682"
      },
      {
        denominazioneInItaliano: "Ne",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F858"
      },
      {
        denominazioneInItaliano: "Neirone",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "F862"
      },
      {
        denominazioneInItaliano: "Orero",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "G093"
      },
      {
        denominazioneInItaliano: "Pieve Ligure",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "G646"
      },
      {
        denominazioneInItaliano: "Portofino",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "G913"
      },
      {
        denominazioneInItaliano: "Propata",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H073"
      },
      {
        denominazioneInItaliano: "Rapallo",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H183"
      },
      {
        denominazioneInItaliano: "Recco",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H212"
      },
      {
        denominazioneInItaliano: "Rezzoaglio",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H258"
      },
      {
        denominazioneInItaliano: "Ronco Scrivia",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H536"
      },
      {
        denominazioneInItaliano: "Rondanina",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H546"
      },
      {
        denominazioneInItaliano: "Rossiglione",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H581"
      },
      {
        denominazioneInItaliano: "Rovegno",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H599"
      },
      {
        denominazioneInItaliano: "San Colombano Certenoli",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "H802"
      },
      {
        denominazioneInItaliano: "Santa Margherita Ligure",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "I225"
      },
      {
        denominazioneInItaliano: "Sant'Olcese",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "I346"
      },
      {
        denominazioneInItaliano: "Santo Stefano d'Aveto",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "I368"
      },
      {
        denominazioneInItaliano: "Savignone",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "I475"
      },
      {
        denominazioneInItaliano: "Serra Riccò",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "I640"
      },
      {
        denominazioneInItaliano: "Sestri Levante",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "I693"
      },
      {
        denominazioneInItaliano: "Sori",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "I852"
      },
      {
        denominazioneInItaliano: "Tiglieto",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "L167"
      },
      {
        denominazioneInItaliano: "Torriglia",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "L298"
      },
      {
        denominazioneInItaliano: "Tribogna",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "L416"
      },
      {
        denominazioneInItaliano: "Uscio",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "L507"
      },
      {
        denominazioneInItaliano: "Valbrevenna",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "L546"
      },
      {
        denominazioneInItaliano: "Vobbia",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "M105"
      },
      {
        denominazioneInItaliano: "Zoagli",
        regione: "Liguria",
        provincia: "Genova",
        codiceCatastale: "M182"
      },
      {
        denominazioneInItaliano: "Ameglia",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "A261"
      },
      {
        denominazioneInItaliano: "Arcola",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "A373"
      },
      {
        denominazioneInItaliano: "Beverino",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "A836"
      },
      {
        denominazioneInItaliano: "Bolano",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "A932"
      },
      {
        denominazioneInItaliano: "Bonassola",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "A961"
      },
      {
        denominazioneInItaliano: "Borghetto di Vara",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "A992"
      },
      {
        denominazioneInItaliano: "Brugnato",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "B214"
      },
      {
        denominazioneInItaliano: "Calice al Cornoviglio",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "B410"
      },
      {
        denominazioneInItaliano: "Carro",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "B838"
      },
      {
        denominazioneInItaliano: "Carrodano",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "B839"
      },
      {
        denominazioneInItaliano: "Castelnuovo Magra",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "C240"
      },
      {
        denominazioneInItaliano: "Deiva Marina",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "D265"
      },
      {
        denominazioneInItaliano: "Follo",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "D655"
      },
      {
        denominazioneInItaliano: "Framura",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "D758"
      },
      {
        denominazioneInItaliano: "La Spezia",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "E463"
      },
      {
        denominazioneInItaliano: "Lerici",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "E542"
      },
      {
        denominazioneInItaliano: "Levanto",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "E560"
      },
      {
        denominazioneInItaliano: "Maissana",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "E842"
      },
      {
        denominazioneInItaliano: "Monterosso al Mare",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "F609"
      },
      {
        denominazioneInItaliano: "Luni",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "G143"
      },
      {
        denominazioneInItaliano: "Pignone",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "G664"
      },
      {
        denominazioneInItaliano: "Portovenere",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "G925"
      },
      {
        denominazioneInItaliano: "Riccò del Golfo di Spezia",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "H275"
      },
      {
        denominazioneInItaliano: "Riomaggiore",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "H304"
      },
      {
        denominazioneInItaliano: "Rocchetta di Vara",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "H461"
      },
      {
        denominazioneInItaliano: "Santo Stefano di Magra",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "I363"
      },
      {
        denominazioneInItaliano: "Sarzana",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "I449"
      },
      {
        denominazioneInItaliano: "Sesta Godano",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "E070"
      },
      {
        denominazioneInItaliano: "Varese Ligure",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "L681"
      },
      {
        denominazioneInItaliano: "Vernazza",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "L774"
      },
      {
        denominazioneInItaliano: "Vezzano Ligure",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "L819"
      },
      {
        denominazioneInItaliano: "Zignago",
        regione: "Liguria",
        provincia: "La Spezia",
        codiceCatastale: "M177"
      },
      {
        denominazioneInItaliano: "Agazzano",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "A067"
      },
      {
        denominazioneInItaliano: "Alseno",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "A223"
      },
      {
        denominazioneInItaliano: "Besenzone",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "A823"
      },
      {
        denominazioneInItaliano: "Bettola",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "A831"
      },
      {
        denominazioneInItaliano: "Bobbio",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "A909"
      },
      {
        denominazioneInItaliano: "Borgonovo Val Tidone",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "B025"
      },
      {
        denominazioneInItaliano: "Cadeo",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "B332"
      },
      {
        denominazioneInItaliano: "Calendasco",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "B405"
      },
      {
        denominazioneInItaliano: "Caorso",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "B643"
      },
      {
        denominazioneInItaliano: "Carpaneto Piacentino",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "B812"
      },
      {
        denominazioneInItaliano: "Castell'Arquato",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "C145"
      },
      {
        denominazioneInItaliano: "Castel San Giovanni",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "C261"
      },
      {
        denominazioneInItaliano: "Castelvetro Piacentino",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "C288"
      },
      {
        denominazioneInItaliano: "Cerignale",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "C513"
      },
      {
        denominazioneInItaliano: "Coli",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "C838"
      },
      {
        denominazioneInItaliano: "Corte Brugnatella",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "D054"
      },
      {
        denominazioneInItaliano: "Cortemaggiore",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "D061"
      },
      {
        denominazioneInItaliano: "Farini",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "D502"
      },
      {
        denominazioneInItaliano: "Ferriere",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "D555"
      },
      {
        denominazioneInItaliano: "Fiorenzuola d'Arda",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "D611"
      },
      {
        denominazioneInItaliano: "Gazzola",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "D958"
      },
      {
        denominazioneInItaliano: "Gossolengo",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "E114"
      },
      {
        denominazioneInItaliano: "Gragnano Trebbiense",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "E132"
      },
      {
        denominazioneInItaliano: "Gropparello",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "E196"
      },
      {
        denominazioneInItaliano: "Lugagnano Val d'Arda",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "E726"
      },
      {
        denominazioneInItaliano: "Monticelli d'Ongina",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "F671"
      },
      {
        denominazioneInItaliano: "Morfasso",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "F724"
      },
      {
        denominazioneInItaliano: "Ottone",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "G195"
      },
      {
        denominazioneInItaliano: "Piacenza",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "G535"
      },
      {
        denominazioneInItaliano: "Pianello Val Tidone",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "G557"
      },
      {
        denominazioneInItaliano: "Piozzano",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "G696"
      },
      {
        denominazioneInItaliano: "Podenzano",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "G747"
      },
      {
        denominazioneInItaliano: "Ponte dell'Olio",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "G842"
      },
      {
        denominazioneInItaliano: "Pontenure",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "G852"
      },
      {
        denominazioneInItaliano: "Rivergaro",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "H350"
      },
      {
        denominazioneInItaliano: "Rottofreno",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "H593"
      },
      {
        denominazioneInItaliano: "San Giorgio Piacentino",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "H887"
      },
      {
        denominazioneInItaliano: "San Pietro in Cerro",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "G788"
      },
      {
        denominazioneInItaliano: "Sarmato",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "I434"
      },
      {
        denominazioneInItaliano: "Travo",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "L348"
      },
      {
        denominazioneInItaliano: "Vernasca",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "L772"
      },
      {
        denominazioneInItaliano: "Vigolzone",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "L897"
      },
      {
        denominazioneInItaliano: "Villanova sull'Arda",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "L980"
      },
      {
        denominazioneInItaliano: "Zerba",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "M165"
      },
      {
        denominazioneInItaliano: "Ziano Piacentino",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "L848"
      },
      {
        denominazioneInItaliano: "Alta Val Tidone",
        regione: "Emilia-Romagna",
        provincia: "Piacenza",
        codiceCatastale: "M386"
      },
      {
        denominazioneInItaliano: "Albareto",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "A138"
      },
      {
        denominazioneInItaliano: "Bardi",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "A646"
      },
      {
        denominazioneInItaliano: "Bedonia",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "A731"
      },
      {
        denominazioneInItaliano: "Berceto",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "A788"
      },
      {
        denominazioneInItaliano: "Bore",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "A987"
      },
      {
        denominazioneInItaliano: "Borgo Val di Taro",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "B042"
      },
      {
        denominazioneInItaliano: "Busseto",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "B293"
      },
      {
        denominazioneInItaliano: "Calestano",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "B408"
      },
      {
        denominazioneInItaliano: "Collecchio",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "C852"
      },
      {
        denominazioneInItaliano: "Colorno",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "C904"
      },
      {
        denominazioneInItaliano: "Compiano",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "C934"
      },
      {
        denominazioneInItaliano: "Corniglio",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "D026"
      },
      {
        denominazioneInItaliano: "Felino",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "D526"
      },
      {
        denominazioneInItaliano: "Fidenza",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "B034"
      },
      {
        denominazioneInItaliano: "Fontanellato",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "D673"
      },
      {
        denominazioneInItaliano: "Fontevivo",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "D685"
      },
      {
        denominazioneInItaliano: "Fornovo di Taro",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "D728"
      },
      {
        denominazioneInItaliano: "Langhirano",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "E438"
      },
      {
        denominazioneInItaliano: "Lesignano de' Bagni",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "E547"
      },
      {
        denominazioneInItaliano: "Medesano",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "F082"
      },
      {
        denominazioneInItaliano: "Monchio delle Corti",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "F340"
      },
      {
        denominazioneInItaliano: "Montechiarugolo",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "F473"
      },
      {
        denominazioneInItaliano: "Neviano degli Arduini",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "F882"
      },
      {
        denominazioneInItaliano: "Noceto",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "F914"
      },
      {
        denominazioneInItaliano: "Palanzano",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "G255"
      },
      {
        denominazioneInItaliano: "Parma",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "G337"
      },
      {
        denominazioneInItaliano: "Pellegrino Parmense",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "G424"
      },
      {
        denominazioneInItaliano: "Roccabianca",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "H384"
      },
      {
        denominazioneInItaliano: "Sala Baganza",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "H682"
      },
      {
        denominazioneInItaliano: "Salsomaggiore Terme",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "H720"
      },
      {
        denominazioneInItaliano: "San Secondo Parmense",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "I153"
      },
      {
        denominazioneInItaliano: "Solignano",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "I803"
      },
      {
        denominazioneInItaliano: "Soragna",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "I840"
      },
      {
        denominazioneInItaliano: "Terenzo",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "E548"
      },
      {
        denominazioneInItaliano: "Tizzano Val Parma",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "L183"
      },
      {
        denominazioneInItaliano: "Tornolo",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "L229"
      },
      {
        denominazioneInItaliano: "Torrile",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "L299"
      },
      {
        denominazioneInItaliano: "Traversetolo",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "L346"
      },
      {
        denominazioneInItaliano: "Valmozzola",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "L641"
      },
      {
        denominazioneInItaliano: "Varano de' Melegari",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "L672"
      },
      {
        denominazioneInItaliano: "Varsi",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "L689"
      },
      {
        denominazioneInItaliano: "Sissa Trecasali",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "M325"
      },
      {
        denominazioneInItaliano: "Polesine Zibello",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "M367"
      },
      {
        denominazioneInItaliano: "Sorbolo Mezzani",
        regione: "Emilia-Romagna",
        provincia: "Parma",
        codiceCatastale: "M411"
      },
      {
        denominazioneInItaliano: "Albinea",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "A162"
      },
      {
        denominazioneInItaliano: "Bagnolo in Piano",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "A573"
      },
      {
        denominazioneInItaliano: "Baiso",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "A586"
      },
      {
        denominazioneInItaliano: "Bibbiano",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "A850"
      },
      {
        denominazioneInItaliano: "Boretto",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "A988"
      },
      {
        denominazioneInItaliano: "Brescello",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "B156"
      },
      {
        denominazioneInItaliano: "Cadelbosco di Sopra",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "B328"
      },
      {
        denominazioneInItaliano: "Campagnola Emilia",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "B499"
      },
      {
        denominazioneInItaliano: "Campegine",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "B502"
      },
      {
        denominazioneInItaliano: "Carpineti",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "B825"
      },
      {
        denominazioneInItaliano: "Casalgrande",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "B893"
      },
      {
        denominazioneInItaliano: "Casina",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "B967"
      },
      {
        denominazioneInItaliano: "Castellarano",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "C141"
      },
      {
        denominazioneInItaliano: "Castelnovo di Sotto",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "C218"
      },
      {
        denominazioneInItaliano: "Castelnovo ne' Monti",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "C219"
      },
      {
        denominazioneInItaliano: "Cavriago",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "C405"
      },
      {
        denominazioneInItaliano: "Canossa",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "C669"
      },
      {
        denominazioneInItaliano: "Correggio",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "D037"
      },
      {
        denominazioneInItaliano: "Fabbrico",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "D450"
      },
      {
        denominazioneInItaliano: "Gattatico",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "D934"
      },
      {
        denominazioneInItaliano: "Gualtieri",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "E232"
      },
      {
        denominazioneInItaliano: "Guastalla",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "E253"
      },
      {
        denominazioneInItaliano: "Luzzara",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "E772"
      },
      {
        denominazioneInItaliano: "Montecchio Emilia",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "F463"
      },
      {
        denominazioneInItaliano: "Novellara",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "F960"
      },
      {
        denominazioneInItaliano: "Poviglio",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "G947"
      },
      {
        denominazioneInItaliano: "Quattro Castella",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "H122"
      },
      {
        denominazioneInItaliano: "Reggiolo",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "H225"
      },
      {
        denominazioneInItaliano: "Reggio nell'Emilia",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "H223"
      },
      {
        denominazioneInItaliano: "Rio Saliceto",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "H298"
      },
      {
        denominazioneInItaliano: "Rolo",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "H500"
      },
      {
        denominazioneInItaliano: "Rubiera",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "H628"
      },
      {
        denominazioneInItaliano: "San Martino in Rio",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "I011"
      },
      {
        denominazioneInItaliano: "San Polo d'Enza",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "I123"
      },
      {
        denominazioneInItaliano: "Sant'Ilario d'Enza",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "I342"
      },
      {
        denominazioneInItaliano: "Scandiano",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "I496"
      },
      {
        denominazioneInItaliano: "Toano",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "L184"
      },
      {
        denominazioneInItaliano: "Vetto",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "L815"
      },
      {
        denominazioneInItaliano: "Vezzano sul Crostolo",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "L820"
      },
      {
        denominazioneInItaliano: "Viano",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "L831"
      },
      {
        denominazioneInItaliano: "Villa Minozzo",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "L969"
      },
      {
        denominazioneInItaliano: "Ventasso",
        regione: "Emilia-Romagna",
        provincia: "Reggio nell'Emilia",
        codiceCatastale: "M364"
      },
      {
        denominazioneInItaliano: "Bastiglia",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "A713"
      },
      {
        denominazioneInItaliano: "Bomporto",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "A959"
      },
      {
        denominazioneInItaliano: "Campogalliano",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "B539"
      },
      {
        denominazioneInItaliano: "Camposanto",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "B566"
      },
      {
        denominazioneInItaliano: "Carpi",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "B819"
      },
      {
        denominazioneInItaliano: "Castelfranco Emilia",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "C107"
      },
      {
        denominazioneInItaliano: "Castelnuovo Rangone",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "C242"
      },
      {
        denominazioneInItaliano: "Castelvetro di Modena",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "C287"
      },
      {
        denominazioneInItaliano: "Cavezzo",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "C398"
      },
      {
        denominazioneInItaliano: "Concordia sulla Secchia",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "C951"
      },
      {
        denominazioneInItaliano: "Fanano",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "D486"
      },
      {
        denominazioneInItaliano: "Finale Emilia",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "D599"
      },
      {
        denominazioneInItaliano: "Fiorano Modenese",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "D607"
      },
      {
        denominazioneInItaliano: "Fiumalbo",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "D617"
      },
      {
        denominazioneInItaliano: "Formigine",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "D711"
      },
      {
        denominazioneInItaliano: "Frassinoro",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "D783"
      },
      {
        denominazioneInItaliano: "Guiglia",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "E264"
      },
      {
        denominazioneInItaliano: "Lama Mocogno",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "E426"
      },
      {
        denominazioneInItaliano: "Maranello",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "E904"
      },
      {
        denominazioneInItaliano: "Marano sul Panaro",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "E905"
      },
      {
        denominazioneInItaliano: "Medolla",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F087"
      },
      {
        denominazioneInItaliano: "Mirandola",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F240"
      },
      {
        denominazioneInItaliano: "Modena",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F257"
      },
      {
        denominazioneInItaliano: "Montecreto",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F484"
      },
      {
        denominazioneInItaliano: "Montefiorino",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F503"
      },
      {
        denominazioneInItaliano: "Montese",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F642"
      },
      {
        denominazioneInItaliano: "Nonantola",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F930"
      },
      {
        denominazioneInItaliano: "Novi di Modena",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F966"
      },
      {
        denominazioneInItaliano: "Palagano",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "G250"
      },
      {
        denominazioneInItaliano: "Pavullo nel Frignano",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "G393"
      },
      {
        denominazioneInItaliano: "Pievepelago",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "G649"
      },
      {
        denominazioneInItaliano: "Polinago",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "G789"
      },
      {
        denominazioneInItaliano: "Prignano sulla Secchia",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "H061"
      },
      {
        denominazioneInItaliano: "Ravarino",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "H195"
      },
      {
        denominazioneInItaliano: "Riolunato",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "H303"
      },
      {
        denominazioneInItaliano: "San Cesario sul Panaro",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "H794"
      },
      {
        denominazioneInItaliano: "San Felice sul Panaro",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "H835"
      },
      {
        denominazioneInItaliano: "San Possidonio",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "I128"
      },
      {
        denominazioneInItaliano: "San Prospero",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "I133"
      },
      {
        denominazioneInItaliano: "Sassuolo",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "I462"
      },
      {
        denominazioneInItaliano: "Savignano sul Panaro",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "I473"
      },
      {
        denominazioneInItaliano: "Serramazzoni",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "F357"
      },
      {
        denominazioneInItaliano: "Sestola",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "I689"
      },
      {
        denominazioneInItaliano: "Soliera",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "I802"
      },
      {
        denominazioneInItaliano: "Spilamberto",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "I903"
      },
      {
        denominazioneInItaliano: "Vignola",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "L885"
      },
      {
        denominazioneInItaliano: "Zocca",
        regione: "Emilia-Romagna",
        provincia: "Modena",
        codiceCatastale: "M183"
      },
      {
        denominazioneInItaliano: "Anzola dell'Emilia",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "A324"
      },
      {
        denominazioneInItaliano: "Argelato",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "A392"
      },
      {
        denominazioneInItaliano: "Baricella",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "A665"
      },
      {
        denominazioneInItaliano: "Bentivoglio",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "A785"
      },
      {
        denominazioneInItaliano: "Bologna",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "A944"
      },
      {
        denominazioneInItaliano: "Borgo Tossignano",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "B044"
      },
      {
        denominazioneInItaliano: "Budrio",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "B249"
      },
      {
        denominazioneInItaliano: "Calderara di Reno",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "B399"
      },
      {
        denominazioneInItaliano: "Camugnano",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "B572"
      },
      {
        denominazioneInItaliano: "Casalecchio di Reno",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "B880"
      },
      {
        denominazioneInItaliano: "Casalfiumanese",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "B892"
      },
      {
        denominazioneInItaliano: "Castel d'Aiano",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "C075"
      },
      {
        denominazioneInItaliano: "Castel del Rio",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "C086"
      },
      {
        denominazioneInItaliano: "Castel di Casio",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "B969"
      },
      {
        denominazioneInItaliano: "Castel Guelfo di Bologna",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "C121"
      },
      {
        denominazioneInItaliano: "Castello d'Argile",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "C185"
      },
      {
        denominazioneInItaliano: "Castel Maggiore",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "C204"
      },
      {
        denominazioneInItaliano: "Castel San Pietro Terme",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "C265"
      },
      {
        denominazioneInItaliano: "Castenaso",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "C292"
      },
      {
        denominazioneInItaliano: "Castiglione dei Pepoli",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "C296"
      },
      {
        denominazioneInItaliano: "Crevalcore",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "D166"
      },
      {
        denominazioneInItaliano: "Dozza",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "D360"
      },
      {
        denominazioneInItaliano: "Fontanelice",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "D668"
      },
      {
        denominazioneInItaliano: "Gaggio Montano",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "D847"
      },
      {
        denominazioneInItaliano: "Galliera",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "D878"
      },
      {
        denominazioneInItaliano: "Granarolo dell'Emilia",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "E136"
      },
      {
        denominazioneInItaliano: "Grizzana Morandi",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "E187"
      },
      {
        denominazioneInItaliano: "Imola",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "E289"
      },
      {
        denominazioneInItaliano: "Lizzano in Belvedere",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "A771"
      },
      {
        denominazioneInItaliano: "Loiano",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "E655"
      },
      {
        denominazioneInItaliano: "Malalbergo",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "E844"
      },
      {
        denominazioneInItaliano: "Marzabotto",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "B689"
      },
      {
        denominazioneInItaliano: "Medicina",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "F083"
      },
      {
        denominazioneInItaliano: "Minerbio",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "F219"
      },
      {
        denominazioneInItaliano: "Molinella",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "F288"
      },
      {
        denominazioneInItaliano: "Monghidoro",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "F363"
      },
      {
        denominazioneInItaliano: "Monterenzio",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "F597"
      },
      {
        denominazioneInItaliano: "Monte San Pietro",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "F627"
      },
      {
        denominazioneInItaliano: "Monzuno",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "F706"
      },
      {
        denominazioneInItaliano: "Mordano",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "F718"
      },
      {
        denominazioneInItaliano: "Ozzano dell'Emilia",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "G205"
      },
      {
        denominazioneInItaliano: "Pianoro",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "G570"
      },
      {
        denominazioneInItaliano: "Pieve di Cento",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "G643"
      },
      {
        denominazioneInItaliano: "Sala Bolognese",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "H678"
      },
      {
        denominazioneInItaliano: "San Benedetto Val di Sambro",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "G566"
      },
      {
        denominazioneInItaliano: "San Giorgio di Piano",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "H896"
      },
      {
        denominazioneInItaliano: "San Giovanni in Persiceto",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "G467"
      },
      {
        denominazioneInItaliano: "San Lazzaro di Savena",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "H945"
      },
      {
        denominazioneInItaliano: "San Pietro in Casale",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "I110"
      },
      {
        denominazioneInItaliano: "Sant'Agata Bolognese",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "I191"
      },
      {
        denominazioneInItaliano: "Sasso Marconi",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "G972"
      },
      {
        denominazioneInItaliano: "Vergato",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "L762"
      },
      {
        denominazioneInItaliano: "Zola Predosa",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "M185"
      },
      {
        denominazioneInItaliano: "Valsamoggia",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "M320"
      },
      {
        denominazioneInItaliano: "Alto Reno Terme",
        regione: "Emilia-Romagna",
        provincia: "Bologna",
        codiceCatastale: "M369"
      },
      {
        denominazioneInItaliano: "Argenta",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "A393"
      },
      {
        denominazioneInItaliano: "Bondeno",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "A965"
      },
      {
        denominazioneInItaliano: "Cento",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "C469"
      },
      {
        denominazioneInItaliano: "Codigoro",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "C814"
      },
      {
        denominazioneInItaliano: "Comacchio",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "C912"
      },
      {
        denominazioneInItaliano: "Copparo",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "C980"
      },
      {
        denominazioneInItaliano: "Ferrara",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "D548"
      },
      {
        denominazioneInItaliano: "Jolanda di Savoia",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "E320"
      },
      {
        denominazioneInItaliano: "Lagosanto",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "E410"
      },
      {
        denominazioneInItaliano: "Masi Torello",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "F016"
      },
      {
        denominazioneInItaliano: "Mesola",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "F156"
      },
      {
        denominazioneInItaliano: "Ostellato",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "G184"
      },
      {
        denominazioneInItaliano: "Poggio Renatico",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "G768"
      },
      {
        denominazioneInItaliano: "Portomaggiore",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "G916"
      },
      {
        denominazioneInItaliano: "Vigarano Mainarda",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "L868"
      },
      {
        denominazioneInItaliano: "Voghiera",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "M110"
      },
      {
        denominazioneInItaliano: "Goro",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "E107"
      },
      {
        denominazioneInItaliano: "Fiscaglia",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "M323"
      },
      {
        denominazioneInItaliano: "Terre del Reno",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "M381"
      },
      {
        denominazioneInItaliano: "Riva del Po",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "M410"
      },
      {
        denominazioneInItaliano: "Tresignana",
        regione: "Emilia-Romagna",
        provincia: "Ferrara",
        codiceCatastale: "M409"
      },
      {
        denominazioneInItaliano: "Alfonsine",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "A191"
      },
      {
        denominazioneInItaliano: "Bagnacavallo",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "A547"
      },
      {
        denominazioneInItaliano: "Bagnara di Romagna",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "A551"
      },
      {
        denominazioneInItaliano: "Brisighella",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "B188"
      },
      {
        denominazioneInItaliano: "Casola Valsenio",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "B982"
      },
      {
        denominazioneInItaliano: "Castel Bolognese",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "C065"
      },
      {
        denominazioneInItaliano: "Cervia",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "C553"
      },
      {
        denominazioneInItaliano: "Conselice",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "C963"
      },
      {
        denominazioneInItaliano: "Cotignola",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "D121"
      },
      {
        denominazioneInItaliano: "Faenza",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "D458"
      },
      {
        denominazioneInItaliano: "Fusignano",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "D829"
      },
      {
        denominazioneInItaliano: "Lugo",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "E730"
      },
      {
        denominazioneInItaliano: "Massa Lombarda",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "F029"
      },
      {
        denominazioneInItaliano: "Ravenna",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "H199"
      },
      {
        denominazioneInItaliano: "Riolo Terme",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "H302"
      },
      {
        denominazioneInItaliano: "Russi",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "H642"
      },
      {
        denominazioneInItaliano: "Sant'Agata sul Santerno",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "I196"
      },
      {
        denominazioneInItaliano: "Solarolo",
        regione: "Emilia-Romagna",
        provincia: "Ravenna",
        codiceCatastale: "I787"
      },
      {
        denominazioneInItaliano: "Bagno di Romagna",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "A565"
      },
      {
        denominazioneInItaliano: "Bertinoro",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "A809"
      },
      {
        denominazioneInItaliano: "Borghi",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "B001"
      },
      {
        denominazioneInItaliano: "Castrocaro Terme e Terra del Sole",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "C339"
      },
      {
        denominazioneInItaliano: "Cesena",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "C573"
      },
      {
        denominazioneInItaliano: "Cesenatico",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "C574"
      },
      {
        denominazioneInItaliano: "Civitella di Romagna",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "C777"
      },
      {
        denominazioneInItaliano: "Dovadola",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "D357"
      },
      {
        denominazioneInItaliano: "Forlì",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "D704"
      },
      {
        denominazioneInItaliano: "Forlimpopoli",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "D705"
      },
      {
        denominazioneInItaliano: "Galeata",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "D867"
      },
      {
        denominazioneInItaliano: "Gambettola",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "D899"
      },
      {
        denominazioneInItaliano: "Gatteo",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "D935"
      },
      {
        denominazioneInItaliano: "Longiano",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "E675"
      },
      {
        denominazioneInItaliano: "Meldola",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "F097"
      },
      {
        denominazioneInItaliano: "Mercato Saraceno",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "F139"
      },
      {
        denominazioneInItaliano: "Modigliana",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "F259"
      },
      {
        denominazioneInItaliano: "Montiano",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "F668"
      },
      {
        denominazioneInItaliano: "Portico e San Benedetto",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "G904"
      },
      {
        denominazioneInItaliano: "Predappio",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "H017"
      },
      {
        denominazioneInItaliano: "Premilcuore",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "H034"
      },
      {
        denominazioneInItaliano: "Rocca San Casciano",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "H437"
      },
      {
        denominazioneInItaliano: "Roncofreddo",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "H542"
      },
      {
        denominazioneInItaliano: "San Mauro Pascoli",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "I027"
      },
      {
        denominazioneInItaliano: "Santa Sofia",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "I310"
      },
      {
        denominazioneInItaliano: "Sarsina",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "I444"
      },
      {
        denominazioneInItaliano: "Savignano sul Rubicone",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "I472"
      },
      {
        denominazioneInItaliano: "Sogliano al Rubicone",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "I779"
      },
      {
        denominazioneInItaliano: "Tredozio",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "L361"
      },
      {
        denominazioneInItaliano: "Verghereto",
        regione: "Emilia-Romagna",
        provincia: "Forlì-Cesena",
        codiceCatastale: "L764"
      },
      {
        denominazioneInItaliano: "Bellaria-Igea Marina",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "A747"
      },
      {
        denominazioneInItaliano: "Cattolica",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "C357"
      },
      {
        denominazioneInItaliano: "Coriano",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "D004"
      },
      {
        denominazioneInItaliano: "Gemmano",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "D961"
      },
      {
        denominazioneInItaliano: "Misano Adriatico",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "F244"
      },
      {
        denominazioneInItaliano: "Mondaino",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "F346"
      },
      {
        denominazioneInItaliano: "Montefiore Conca",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "F502"
      },
      {
        denominazioneInItaliano: "Montegridolfo",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "F523"
      },
      {
        denominazioneInItaliano: "Morciano di Romagna",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "F715"
      },
      {
        denominazioneInItaliano: "Riccione",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "H274"
      },
      {
        denominazioneInItaliano: "Rimini",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "H294"
      },
      {
        denominazioneInItaliano: "Saludecio",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "H724"
      },
      {
        denominazioneInItaliano: "San Clemente",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "H801"
      },
      {
        denominazioneInItaliano: "San Giovanni in Marignano",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "H921"
      },
      {
        denominazioneInItaliano: "Santarcangelo di Romagna",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "I304"
      },
      {
        denominazioneInItaliano: "Verucchio",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "L797"
      },
      {
        denominazioneInItaliano: "Casteldelci",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "C080"
      },
      {
        denominazioneInItaliano: "Maiolo",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "E838"
      },
      {
        denominazioneInItaliano: "Novafeltria",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "F137"
      },
      {
        denominazioneInItaliano: "Pennabilli",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "G433"
      },
      {
        denominazioneInItaliano: "San Leo",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "H949"
      },
      {
        denominazioneInItaliano: "Sant'Agata Feltria",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "I201"
      },
      {
        denominazioneInItaliano: "Talamello",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "L034"
      },
      {
        denominazioneInItaliano: "Poggio Torriana",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "M324"
      },
      {
        denominazioneInItaliano: "Montescudo-Monte Colombo",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "M368"
      },
      {
        denominazioneInItaliano: "Montecopiolo",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "F478"
      },
      {
        denominazioneInItaliano: "Sassofeltrio",
        regione: "Emilia-Romagna",
        provincia: "Rimini",
        codiceCatastale: "I460"
      },
      {
        denominazioneInItaliano: "Aulla",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "A496"
      },
      {
        denominazioneInItaliano: "Bagnone",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "A576"
      },
      {
        denominazioneInItaliano: "Carrara",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "B832"
      },
      {
        denominazioneInItaliano: "Casola in Lunigiana",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "B979"
      },
      {
        denominazioneInItaliano: "Comano",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "C914"
      },
      {
        denominazioneInItaliano: "Filattiera",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "D590"
      },
      {
        denominazioneInItaliano: "Fivizzano",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "D629"
      },
      {
        denominazioneInItaliano: "Fosdinovo",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "D735"
      },
      {
        denominazioneInItaliano: "Licciana Nardi",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "E574"
      },
      {
        denominazioneInItaliano: "Massa",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "F023"
      },
      {
        denominazioneInItaliano: "Montignoso",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "F679"
      },
      {
        denominazioneInItaliano: "Mulazzo",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "F802"
      },
      {
        denominazioneInItaliano: "Podenzana",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "G746"
      },
      {
        denominazioneInItaliano: "Pontremoli",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "G870"
      },
      {
        denominazioneInItaliano: "Tresana",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "L386"
      },
      {
        denominazioneInItaliano: "Villafranca in Lunigiana",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "L946"
      },
      {
        denominazioneInItaliano: "Zeri",
        regione: "Toscana",
        provincia: "Massa-Carrara",
        codiceCatastale: "M169"
      },
      {
        denominazioneInItaliano: "Altopascio",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "A241"
      },
      {
        denominazioneInItaliano: "Bagni di Lucca",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "A560"
      },
      {
        denominazioneInItaliano: "Barga",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "A657"
      },
      {
        denominazioneInItaliano: "Borgo a Mozzano",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "B007"
      },
      {
        denominazioneInItaliano: "Camaiore",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "B455"
      },
      {
        denominazioneInItaliano: "Camporgiano",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "B557"
      },
      {
        denominazioneInItaliano: "Capannori",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "B648"
      },
      {
        denominazioneInItaliano: "Careggine",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "B760"
      },
      {
        denominazioneInItaliano: "Castelnuovo di Garfagnana",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "C236"
      },
      {
        denominazioneInItaliano: "Castiglione di Garfagnana",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "C303"
      },
      {
        denominazioneInItaliano: "Coreglia Antelminelli",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "C996"
      },
      {
        denominazioneInItaliano: "Forte dei Marmi",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "D730"
      },
      {
        denominazioneInItaliano: "Fosciandora",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "D734"
      },
      {
        denominazioneInItaliano: "Gallicano",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "D874"
      },
      {
        denominazioneInItaliano: "Lucca",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "E715"
      },
      {
        denominazioneInItaliano: "Massarosa",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "F035"
      },
      {
        denominazioneInItaliano: "Minucciano",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "F225"
      },
      {
        denominazioneInItaliano: "Molazzana",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "F283"
      },
      {
        denominazioneInItaliano: "Montecarlo",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "F452"
      },
      {
        denominazioneInItaliano: "Pescaglia",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "G480"
      },
      {
        denominazioneInItaliano: "Piazza al Serchio",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "G582"
      },
      {
        denominazioneInItaliano: "Pietrasanta",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "G628"
      },
      {
        denominazioneInItaliano: "Pieve Fosciana",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "G648"
      },
      {
        denominazioneInItaliano: "Porcari",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "G882"
      },
      {
        denominazioneInItaliano: "San Romano in Garfagnana",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "I142"
      },
      {
        denominazioneInItaliano: "Seravezza",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "I622"
      },
      {
        denominazioneInItaliano: "Stazzema",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "I942"
      },
      {
        denominazioneInItaliano: "Vagli Sotto",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "L533"
      },
      {
        denominazioneInItaliano: "Viareggio",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "L833"
      },
      {
        denominazioneInItaliano: "Villa Basilica",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "L913"
      },
      {
        denominazioneInItaliano: "Villa Collemandina",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "L926"
      },
      {
        denominazioneInItaliano: "Fabbriche di Vergemoli",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "M319"
      },
      {
        denominazioneInItaliano: "Sillano Giuncugnano",
        regione: "Toscana",
        provincia: "Lucca",
        codiceCatastale: "M347"
      },
      {
        denominazioneInItaliano: "Agliana",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "A071"
      },
      {
        denominazioneInItaliano: "Buggiano",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "B251"
      },
      {
        denominazioneInItaliano: "Lamporecchio",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "E432"
      },
      {
        denominazioneInItaliano: "Larciano",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "E451"
      },
      {
        denominazioneInItaliano: "Marliana",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "E960"
      },
      {
        denominazioneInItaliano: "Massa e Cozzile",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "F025"
      },
      {
        denominazioneInItaliano: "Monsummano Terme",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "F384"
      },
      {
        denominazioneInItaliano: "Montale",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "F410"
      },
      {
        denominazioneInItaliano: "Montecatini-Terme",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "A561"
      },
      {
        denominazioneInItaliano: "Pescia",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "G491"
      },
      {
        denominazioneInItaliano: "Pieve a Nievole",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "G636"
      },
      {
        denominazioneInItaliano: "Pistoia",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "G713"
      },
      {
        denominazioneInItaliano: "Ponte Buggianese",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "G833"
      },
      {
        denominazioneInItaliano: "Quarrata",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "H109"
      },
      {
        denominazioneInItaliano: "Sambuca Pistoiese",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "H744"
      },
      {
        denominazioneInItaliano: "Serravalle Pistoiese",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "I660"
      },
      {
        denominazioneInItaliano: "Uzzano",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "L522"
      },
      {
        denominazioneInItaliano: "Chiesina Uzzanese",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "C631"
      },
      {
        denominazioneInItaliano: "Abetone Cutigliano",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "M376"
      },
      {
        denominazioneInItaliano: "San Marcello Piteglio",
        regione: "Toscana",
        provincia: "Pistoia",
        codiceCatastale: "M377"
      },
      {
        denominazioneInItaliano: "Bagno a Ripoli",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "A564"
      },
      {
        denominazioneInItaliano: "Barberino di Mugello",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "A632"
      },
      {
        denominazioneInItaliano: "Borgo San Lorenzo",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "B036"
      },
      {
        denominazioneInItaliano: "Calenzano",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "B406"
      },
      {
        denominazioneInItaliano: "Campi Bisenzio",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "B507"
      },
      {
        denominazioneInItaliano: "Capraia e Limite",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "B684"
      },
      {
        denominazioneInItaliano: "Castelfiorentino",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "C101"
      },
      {
        denominazioneInItaliano: "Cerreto Guidi",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "C529"
      },
      {
        denominazioneInItaliano: "Certaldo",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "C540"
      },
      {
        denominazioneInItaliano: "Dicomano",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "D299"
      },
      {
        denominazioneInItaliano: "Empoli",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "D403"
      },
      {
        denominazioneInItaliano: "Fiesole",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "D575"
      },
      {
        denominazioneInItaliano: "Firenze",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "D612"
      },
      {
        denominazioneInItaliano: "Firenzuola",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "D613"
      },
      {
        denominazioneInItaliano: "Fucecchio",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "D815"
      },
      {
        denominazioneInItaliano: "Gambassi Terme",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "D895"
      },
      {
        denominazioneInItaliano: "Greve in Chianti",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "E169"
      },
      {
        denominazioneInItaliano: "Impruneta",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "E291"
      },
      {
        denominazioneInItaliano: "Lastra a Signa",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "E466"
      },
      {
        denominazioneInItaliano: "Londa",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "E668"
      },
      {
        denominazioneInItaliano: "Marradi",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "E971"
      },
      {
        denominazioneInItaliano: "Montaione",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "F398"
      },
      {
        denominazioneInItaliano: "Montelupo Fiorentino",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "F551"
      },
      {
        denominazioneInItaliano: "Montespertoli",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "F648"
      },
      {
        denominazioneInItaliano: "Palazzuolo sul Senio",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "G270"
      },
      {
        denominazioneInItaliano: "Pelago",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "G420"
      },
      {
        denominazioneInItaliano: "Pontassieve",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "G825"
      },
      {
        denominazioneInItaliano: "Reggello",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "H222"
      },
      {
        denominazioneInItaliano: "Rignano sull'Arno",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "H286"
      },
      {
        denominazioneInItaliano: "Rufina",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "H635"
      },
      {
        denominazioneInItaliano: "San Casciano in Val di Pesa",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "H791"
      },
      {
        denominazioneInItaliano: "San Godenzo",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "H937"
      },
      {
        denominazioneInItaliano: "Scandicci",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "B962"
      },
      {
        denominazioneInItaliano: "Sesto Fiorentino",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "I684"
      },
      {
        denominazioneInItaliano: "Signa",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "I728"
      },
      {
        denominazioneInItaliano: "Vaglia",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "L529"
      },
      {
        denominazioneInItaliano: "Vicchio",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "L838"
      },
      {
        denominazioneInItaliano: "Vinci",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "M059"
      },
      {
        denominazioneInItaliano: "Figline e Incisa Valdarno",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "M321"
      },
      {
        denominazioneInItaliano: "Scarperia e San Piero",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "M326"
      },
      {
        denominazioneInItaliano: "Barberino Tavarnelle",
        regione: "Toscana",
        provincia: "Firenze",
        codiceCatastale: "M408"
      },
      {
        denominazioneInItaliano: "Bibbona",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "A852"
      },
      {
        denominazioneInItaliano: "Campiglia Marittima",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "B509"
      },
      {
        denominazioneInItaliano: "Campo nell'Elba",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "B553"
      },
      {
        denominazioneInItaliano: "Capoliveri",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "B669"
      },
      {
        denominazioneInItaliano: "Capraia Isola",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "B685"
      },
      {
        denominazioneInItaliano: "Castagneto Carducci",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "C044"
      },
      {
        denominazioneInItaliano: "Cecina",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "C415"
      },
      {
        denominazioneInItaliano: "Collesalvetti",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "C869"
      },
      {
        denominazioneInItaliano: "Livorno",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "E625"
      },
      {
        denominazioneInItaliano: "Marciana",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "E930"
      },
      {
        denominazioneInItaliano: "Marciana Marina",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "E931"
      },
      {
        denominazioneInItaliano: "Piombino",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "G687"
      },
      {
        denominazioneInItaliano: "Porto Azzurro",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "E680"
      },
      {
        denominazioneInItaliano: "Portoferraio",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "G912"
      },
      {
        denominazioneInItaliano: "Rosignano Marittimo",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "H570"
      },
      {
        denominazioneInItaliano: "San Vincenzo",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "I390"
      },
      {
        denominazioneInItaliano: "Sassetta",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "I454"
      },
      {
        denominazioneInItaliano: "Suvereto",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "L019"
      },
      {
        denominazioneInItaliano: "Rio",
        regione: "Toscana",
        provincia: "Livorno",
        codiceCatastale: "M391"
      },
      {
        denominazioneInItaliano: "Bientina",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "A864"
      },
      {
        denominazioneInItaliano: "Buti",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "B303"
      },
      {
        denominazioneInItaliano: "Calci",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "B390"
      },
      {
        denominazioneInItaliano: "Calcinaia",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "B392"
      },
      {
        denominazioneInItaliano: "Capannoli",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "B647"
      },
      {
        denominazioneInItaliano: "Casale Marittimo",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "B878"
      },
      {
        denominazioneInItaliano: "Cascina",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "B950"
      },
      {
        denominazioneInItaliano: "Castelfranco di Sotto",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "C113"
      },
      {
        denominazioneInItaliano: "Castellina Marittima",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "C174"
      },
      {
        denominazioneInItaliano: "Castelnuovo di Val di Cecina",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "C244"
      },
      {
        denominazioneInItaliano: "Chianni",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "C609"
      },
      {
        denominazioneInItaliano: "Fauglia",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "D510"
      },
      {
        denominazioneInItaliano: "Guardistallo",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "E250"
      },
      {
        denominazioneInItaliano: "Lajatico",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "E413"
      },
      {
        denominazioneInItaliano: "Montecatini Val di Cecina",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "F458"
      },
      {
        denominazioneInItaliano: "Montescudaio",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "F640"
      },
      {
        denominazioneInItaliano: "Monteverdi Marittimo",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "F661"
      },
      {
        denominazioneInItaliano: "Montopoli in Val d'Arno",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "F686"
      },
      {
        denominazioneInItaliano: "Orciano Pisano",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "G090"
      },
      {
        denominazioneInItaliano: "Palaia",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "G254"
      },
      {
        denominazioneInItaliano: "Peccioli",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "G395"
      },
      {
        denominazioneInItaliano: "Pisa",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "G702"
      },
      {
        denominazioneInItaliano: "Pomarance",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "G804"
      },
      {
        denominazioneInItaliano: "Ponsacco",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "G822"
      },
      {
        denominazioneInItaliano: "Pontedera",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "G843"
      },
      {
        denominazioneInItaliano: "Riparbella",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "H319"
      },
      {
        denominazioneInItaliano: "San Giuliano Terme",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "A562"
      },
      {
        denominazioneInItaliano: "San Miniato",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "I046"
      },
      {
        denominazioneInItaliano: "Santa Croce sull'Arno",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "I177"
      },
      {
        denominazioneInItaliano: "Santa Luce",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "I217"
      },
      {
        denominazioneInItaliano: "Santa Maria a Monte",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "I232"
      },
      {
        denominazioneInItaliano: "Terricciola",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "L138"
      },
      {
        denominazioneInItaliano: "Vecchiano",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "L702"
      },
      {
        denominazioneInItaliano: "Vicopisano",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "L850"
      },
      {
        denominazioneInItaliano: "Volterra",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "M126"
      },
      {
        denominazioneInItaliano: "Casciana Terme Lari",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "M327"
      },
      {
        denominazioneInItaliano: "Crespina Lorenzana",
        regione: "Toscana",
        provincia: "Pisa",
        codiceCatastale: "M328"
      },
      {
        denominazioneInItaliano: "Anghiari",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "A291"
      },
      {
        denominazioneInItaliano: "Arezzo",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "A390"
      },
      {
        denominazioneInItaliano: "Badia Tedalda",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "A541"
      },
      {
        denominazioneInItaliano: "Bibbiena",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "A851"
      },
      {
        denominazioneInItaliano: "Bucine",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "B243"
      },
      {
        denominazioneInItaliano: "Capolona",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "B670"
      },
      {
        denominazioneInItaliano: "Caprese Michelangelo",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "B693"
      },
      {
        denominazioneInItaliano: "Castel Focognano",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "C102"
      },
      {
        denominazioneInItaliano: "Castel San Niccolò",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "C263"
      },
      {
        denominazioneInItaliano: "Castiglion Fibocchi",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "C318"
      },
      {
        denominazioneInItaliano: "Castiglion Fiorentino",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "C319"
      },
      {
        denominazioneInItaliano: "Cavriglia",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "C407"
      },
      {
        denominazioneInItaliano: "Chitignano",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "C648"
      },
      {
        denominazioneInItaliano: "Chiusi della Verna",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "C663"
      },
      {
        denominazioneInItaliano: "Civitella in Val di Chiana",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "C774"
      },
      {
        denominazioneInItaliano: "Cortona",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "D077"
      },
      {
        denominazioneInItaliano: "Foiano della Chiana",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "D649"
      },
      {
        denominazioneInItaliano: "Loro Ciuffenna",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "E693"
      },
      {
        denominazioneInItaliano: "Lucignano",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "E718"
      },
      {
        denominazioneInItaliano: "Marciano della Chiana",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "E933"
      },
      {
        denominazioneInItaliano: "Montemignaio",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "F565"
      },
      {
        denominazioneInItaliano: "Monterchi",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "F594"
      },
      {
        denominazioneInItaliano: "Monte San Savino",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "F628"
      },
      {
        denominazioneInItaliano: "Montevarchi",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "F656"
      },
      {
        denominazioneInItaliano: "Ortignano Raggiolo",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "G139"
      },
      {
        denominazioneInItaliano: "Pieve Santo Stefano",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "G653"
      },
      {
        denominazioneInItaliano: "Poppi",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "G879"
      },
      {
        denominazioneInItaliano: "San Giovanni Valdarno",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "H901"
      },
      {
        denominazioneInItaliano: "Sansepolcro",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "I155"
      },
      {
        denominazioneInItaliano: "Sestino",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "I681"
      },
      {
        denominazioneInItaliano: "Subbiano",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "I991"
      },
      {
        denominazioneInItaliano: "Talla",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "L038"
      },
      {
        denominazioneInItaliano: "Terranuova Bracciolini",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "L123"
      },
      {
        denominazioneInItaliano: "Castelfranco Piandiscò",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "M322"
      },
      {
        denominazioneInItaliano: "Pratovecchio Stia",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "M329"
      },
      {
        denominazioneInItaliano: "Laterina Pergine Valdarno",
        regione: "Toscana",
        provincia: "Arezzo",
        codiceCatastale: "M392"
      },
      {
        denominazioneInItaliano: "Abbadia San Salvatore",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "A006"
      },
      {
        denominazioneInItaliano: "Asciano",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "A461"
      },
      {
        denominazioneInItaliano: "Buonconvento",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "B269"
      },
      {
        denominazioneInItaliano: "Casole d'Elsa",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "B984"
      },
      {
        denominazioneInItaliano: "Castellina in Chianti",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "C172"
      },
      {
        denominazioneInItaliano: "Castelnuovo Berardenga",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "C227"
      },
      {
        denominazioneInItaliano: "Castiglione d'Orcia",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "C313"
      },
      {
        denominazioneInItaliano: "Cetona",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "C587"
      },
      {
        denominazioneInItaliano: "Chianciano Terme",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "C608"
      },
      {
        denominazioneInItaliano: "Chiusdino",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "C661"
      },
      {
        denominazioneInItaliano: "Chiusi",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "C662"
      },
      {
        denominazioneInItaliano: "Colle di Val d'Elsa",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "C847"
      },
      {
        denominazioneInItaliano: "Gaiole in Chianti",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "D858"
      },
      {
        denominazioneInItaliano: "Montepulciano",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "F592"
      },
      {
        denominazioneInItaliano: "Monteriggioni",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "F598"
      },
      {
        denominazioneInItaliano: "Monteroni d'Arbia",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "F605"
      },
      {
        denominazioneInItaliano: "Monticiano",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "F676"
      },
      {
        denominazioneInItaliano: "Murlo",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "F815"
      },
      {
        denominazioneInItaliano: "Piancastagnaio",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "G547"
      },
      {
        denominazioneInItaliano: "Pienza",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "G602"
      },
      {
        denominazioneInItaliano: "Poggibonsi",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "G752"
      },
      {
        denominazioneInItaliano: "Radda in Chianti",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "H153"
      },
      {
        denominazioneInItaliano: "Radicofani",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "H156"
      },
      {
        denominazioneInItaliano: "Radicondoli",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "H157"
      },
      {
        denominazioneInItaliano: "Rapolano Terme",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "H185"
      },
      {
        denominazioneInItaliano: "San Casciano dei Bagni",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "H790"
      },
      {
        denominazioneInItaliano: "San Gimignano",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "H875"
      },
      {
        denominazioneInItaliano: "San Quirico d'Orcia",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "I135"
      },
      {
        denominazioneInItaliano: "Sarteano",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "I445"
      },
      {
        denominazioneInItaliano: "Siena",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "I726"
      },
      {
        denominazioneInItaliano: "Sinalunga",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "A468"
      },
      {
        denominazioneInItaliano: "Sovicille",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "I877"
      },
      {
        denominazioneInItaliano: "Torrita di Siena",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "L303"
      },
      {
        denominazioneInItaliano: "Trequanda",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "L384"
      },
      {
        denominazioneInItaliano: "Montalcino",
        regione: "Toscana",
        provincia: "Siena",
        codiceCatastale: "M378"
      },
      {
        denominazioneInItaliano: "Arcidosso",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "A369"
      },
      {
        denominazioneInItaliano: "Campagnatico",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "B497"
      },
      {
        denominazioneInItaliano: "Capalbio",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "B646"
      },
      {
        denominazioneInItaliano: "Castel del Piano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "C085"
      },
      {
        denominazioneInItaliano: "Castell'Azzara",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "C147"
      },
      {
        denominazioneInItaliano: "Castiglione della Pescaia",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "C310"
      },
      {
        denominazioneInItaliano: "Cinigiano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "C705"
      },
      {
        denominazioneInItaliano: "Civitella Paganico",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "C782"
      },
      {
        denominazioneInItaliano: "Follonica",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "D656"
      },
      {
        denominazioneInItaliano: "Gavorrano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "D948"
      },
      {
        denominazioneInItaliano: "Grosseto",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "E202"
      },
      {
        denominazioneInItaliano: "Isola del Giglio",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "E348"
      },
      {
        denominazioneInItaliano: "Magliano in Toscana",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "E810"
      },
      {
        denominazioneInItaliano: "Manciano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "E875"
      },
      {
        denominazioneInItaliano: "Massa Marittima",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "F032"
      },
      {
        denominazioneInItaliano: "Monte Argentario",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "F437"
      },
      {
        denominazioneInItaliano: "Montieri",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "F677"
      },
      {
        denominazioneInItaliano: "Orbetello",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "G088"
      },
      {
        denominazioneInItaliano: "Pitigliano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "G716"
      },
      {
        denominazioneInItaliano: "Roccalbegna",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "H417"
      },
      {
        denominazioneInItaliano: "Roccastrada",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "H449"
      },
      {
        denominazioneInItaliano: "Santa Fiora",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "I187"
      },
      {
        denominazioneInItaliano: "Scansano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "I504"
      },
      {
        denominazioneInItaliano: "Scarlino",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "I510"
      },
      {
        denominazioneInItaliano: "Seggiano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "I571"
      },
      {
        denominazioneInItaliano: "Sorano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "I841"
      },
      {
        denominazioneInItaliano: "Monterotondo Marittimo",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "F612"
      },
      {
        denominazioneInItaliano: "Semproniano",
        regione: "Toscana",
        provincia: "Grosseto",
        codiceCatastale: "I601"
      },
      {
        denominazioneInItaliano: "Cantagallo",
        regione: "Toscana",
        provincia: "Prato",
        codiceCatastale: "B626"
      },
      {
        denominazioneInItaliano: "Carmignano",
        regione: "Toscana",
        provincia: "Prato",
        codiceCatastale: "B794"
      },
      {
        denominazioneInItaliano: "Montemurlo",
        regione: "Toscana",
        provincia: "Prato",
        codiceCatastale: "F572"
      },
      {
        denominazioneInItaliano: "Poggio a Caiano",
        regione: "Toscana",
        provincia: "Prato",
        codiceCatastale: "G754"
      },
      {
        denominazioneInItaliano: "Prato",
        regione: "Toscana",
        provincia: "Prato",
        codiceCatastale: "G999"
      },
      {
        denominazioneInItaliano: "Vaiano",
        regione: "Toscana",
        provincia: "Prato",
        codiceCatastale: "L537"
      },
      {
        denominazioneInItaliano: "Vernio",
        regione: "Toscana",
        provincia: "Prato",
        codiceCatastale: "L775"
      },
      {
        denominazioneInItaliano: "Assisi",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "A475"
      },
      {
        denominazioneInItaliano: "Bastia Umbra",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "A710"
      },
      {
        denominazioneInItaliano: "Bettona",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "A832"
      },
      {
        denominazioneInItaliano: "Bevagna",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "A835"
      },
      {
        denominazioneInItaliano: "Campello sul Clitunno",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "B504"
      },
      {
        denominazioneInItaliano: "Cannara",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "B609"
      },
      {
        denominazioneInItaliano: "Cascia",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "B948"
      },
      {
        denominazioneInItaliano: "Castel Ritaldi",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "C252"
      },
      {
        denominazioneInItaliano: "Castiglione del Lago",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "C309"
      },
      {
        denominazioneInItaliano: "Cerreto di Spoleto",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "C527"
      },
      {
        denominazioneInItaliano: "Citerna",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "C742"
      },
      {
        denominazioneInItaliano: "Città della Pieve",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "C744"
      },
      {
        denominazioneInItaliano: "Città di Castello",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "C745"
      },
      {
        denominazioneInItaliano: "Collazzone",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "C845"
      },
      {
        denominazioneInItaliano: "Corciano",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "C990"
      },
      {
        denominazioneInItaliano: "Costacciaro",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "D108"
      },
      {
        denominazioneInItaliano: "Deruta",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "D279"
      },
      {
        denominazioneInItaliano: "Foligno",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "D653"
      },
      {
        denominazioneInItaliano: "Fossato di Vico",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "D745"
      },
      {
        denominazioneInItaliano: "Fratta Todina",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "D787"
      },
      {
        denominazioneInItaliano: "Giano dell'Umbria",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "E012"
      },
      {
        denominazioneInItaliano: "Gualdo Cattaneo",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "E229"
      },
      {
        denominazioneInItaliano: "Gualdo Tadino",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "E230"
      },
      {
        denominazioneInItaliano: "Gubbio",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "E256"
      },
      {
        denominazioneInItaliano: "Lisciano Niccone",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "E613"
      },
      {
        denominazioneInItaliano: "Magione",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "E805"
      },
      {
        denominazioneInItaliano: "Marsciano",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "E975"
      },
      {
        denominazioneInItaliano: "Massa Martana",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "F024"
      },
      {
        denominazioneInItaliano: "Monte Castello di Vibio",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "F456"
      },
      {
        denominazioneInItaliano: "Montefalco",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "F492"
      },
      {
        denominazioneInItaliano: "Monteleone di Spoleto",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "F540"
      },
      {
        denominazioneInItaliano: "Monte Santa Maria Tiberina",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "F629"
      },
      {
        denominazioneInItaliano: "Montone",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "F685"
      },
      {
        denominazioneInItaliano: "Nocera Umbra",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "F911"
      },
      {
        denominazioneInItaliano: "Norcia",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "F935"
      },
      {
        denominazioneInItaliano: "Paciano",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "G212"
      },
      {
        denominazioneInItaliano: "Panicale",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "G308"
      },
      {
        denominazioneInItaliano: "Passignano sul Trasimeno",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "G359"
      },
      {
        denominazioneInItaliano: "Perugia",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "G478"
      },
      {
        denominazioneInItaliano: "Piegaro",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "G601"
      },
      {
        denominazioneInItaliano: "Pietralunga",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "G618"
      },
      {
        denominazioneInItaliano: "Poggiodomo",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "G758"
      },
      {
        denominazioneInItaliano: "Preci",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "H015"
      },
      {
        denominazioneInItaliano: "San Giustino",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "H935"
      },
      {
        denominazioneInItaliano: "Sant'Anatolia di Narco",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "I263"
      },
      {
        denominazioneInItaliano: "Scheggia e Pascelupo",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "I522"
      },
      {
        denominazioneInItaliano: "Scheggino",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "I523"
      },
      {
        denominazioneInItaliano: "Sellano",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "I585"
      },
      {
        denominazioneInItaliano: "Sigillo",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "I727"
      },
      {
        denominazioneInItaliano: "Spello",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "I888"
      },
      {
        denominazioneInItaliano: "Spoleto",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "I921"
      },
      {
        denominazioneInItaliano: "Todi",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "L188"
      },
      {
        denominazioneInItaliano: "Torgiano",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "L216"
      },
      {
        denominazioneInItaliano: "Trevi",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "L397"
      },
      {
        denominazioneInItaliano: "Tuoro sul Trasimeno",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "L466"
      },
      {
        denominazioneInItaliano: "Umbertide",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "D786"
      },
      {
        denominazioneInItaliano: "Valfabbrica",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "L573"
      },
      {
        denominazioneInItaliano: "Vallo di Nera",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "L627"
      },
      {
        denominazioneInItaliano: "Valtopina",
        regione: "Umbria",
        provincia: "Perugia",
        codiceCatastale: "L653"
      },
      {
        denominazioneInItaliano: "Acquasparta",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "A045"
      },
      {
        denominazioneInItaliano: "Allerona",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "A207"
      },
      {
        denominazioneInItaliano: "Alviano",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "A242"
      },
      {
        denominazioneInItaliano: "Amelia",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "A262"
      },
      {
        denominazioneInItaliano: "Arrone",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "A439"
      },
      {
        denominazioneInItaliano: "Attigliano",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "A490"
      },
      {
        denominazioneInItaliano: "Baschi",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "A691"
      },
      {
        denominazioneInItaliano: "Calvi dell'Umbria",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "B446"
      },
      {
        denominazioneInItaliano: "Castel Giorgio",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "C117"
      },
      {
        denominazioneInItaliano: "Castel Viscardo",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "C289"
      },
      {
        denominazioneInItaliano: "Fabro",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "D454"
      },
      {
        denominazioneInItaliano: "Ferentillo",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "D538"
      },
      {
        denominazioneInItaliano: "Ficulle",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "D570"
      },
      {
        denominazioneInItaliano: "Giove",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "E045"
      },
      {
        denominazioneInItaliano: "Guardea",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "E241"
      },
      {
        denominazioneInItaliano: "Lugnano in Teverina",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "E729"
      },
      {
        denominazioneInItaliano: "Montecastrilli",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "F457"
      },
      {
        denominazioneInItaliano: "Montecchio",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "F462"
      },
      {
        denominazioneInItaliano: "Montefranco",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "F510"
      },
      {
        denominazioneInItaliano: "Montegabbione",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "F513"
      },
      {
        denominazioneInItaliano: "Monteleone d'Orvieto",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "F543"
      },
      {
        denominazioneInItaliano: "Narni",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "F844"
      },
      {
        denominazioneInItaliano: "Orvieto",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "G148"
      },
      {
        denominazioneInItaliano: "Otricoli",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "G189"
      },
      {
        denominazioneInItaliano: "Parrano",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "G344"
      },
      {
        denominazioneInItaliano: "Penna in Teverina",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "G432"
      },
      {
        denominazioneInItaliano: "Polino",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "G790"
      },
      {
        denominazioneInItaliano: "Porano",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "G881"
      },
      {
        denominazioneInItaliano: "San Gemini",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "H857"
      },
      {
        denominazioneInItaliano: "San Venanzo",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "I381"
      },
      {
        denominazioneInItaliano: "Stroncone",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "I981"
      },
      {
        denominazioneInItaliano: "Terni",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "L117"
      },
      {
        denominazioneInItaliano: "Avigliano Umbro",
        regione: "Umbria",
        provincia: "Terni",
        codiceCatastale: "M258"
      },
      {
        denominazioneInItaliano: "Acqualagna",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "A035"
      },
      {
        denominazioneInItaliano: "Apecchio",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "A327"
      },
      {
        denominazioneInItaliano: "Belforte all'Isauro",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "A740"
      },
      {
        denominazioneInItaliano: "Borgo Pace",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "B026"
      },
      {
        denominazioneInItaliano: "Cagli",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "B352"
      },
      {
        denominazioneInItaliano: "Cantiano",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "B636"
      },
      {
        denominazioneInItaliano: "Carpegna",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "B816"
      },
      {
        denominazioneInItaliano: "Cartoceto",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "B846"
      },
      {
        denominazioneInItaliano: "Fano",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "D488"
      },
      {
        denominazioneInItaliano: "Fermignano",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "D541"
      },
      {
        denominazioneInItaliano: "Fossombrone",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "D749"
      },
      {
        denominazioneInItaliano: "Fratte Rosa",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "D791"
      },
      {
        denominazioneInItaliano: "Frontino",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "D807"
      },
      {
        denominazioneInItaliano: "Frontone",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "D808"
      },
      {
        denominazioneInItaliano: "Gabicce Mare",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "D836"
      },
      {
        denominazioneInItaliano: "Gradara",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "E122"
      },
      {
        denominazioneInItaliano: "Isola del Piano",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "E351"
      },
      {
        denominazioneInItaliano: "Lunano",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "E743"
      },
      {
        denominazioneInItaliano: "Macerata Feltria",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "E785"
      },
      {
        denominazioneInItaliano: "Mercatello sul Metauro",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F135"
      },
      {
        denominazioneInItaliano: "Mercatino Conca",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F136"
      },
      {
        denominazioneInItaliano: "Mombaroccio",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F310"
      },
      {
        denominazioneInItaliano: "Mondavio",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F347"
      },
      {
        denominazioneInItaliano: "Mondolfo",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F348"
      },
      {
        denominazioneInItaliano: "Montecalvo in Foglia",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F450"
      },
      {
        denominazioneInItaliano: "Monte Cerignone",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F467"
      },
      {
        denominazioneInItaliano: "Montefelcino",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F497"
      },
      {
        denominazioneInItaliano: "Monte Grimano Terme",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F524"
      },
      {
        denominazioneInItaliano: "Montelabbate",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F533"
      },
      {
        denominazioneInItaliano: "Monte Porzio",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "F589"
      },
      {
        denominazioneInItaliano: "Peglio",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "G416"
      },
      {
        denominazioneInItaliano: "Pergola",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "G453"
      },
      {
        denominazioneInItaliano: "Pesaro",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "G479"
      },
      {
        denominazioneInItaliano: "Petriano",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "G514"
      },
      {
        denominazioneInItaliano: "Piandimeleto",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "G551"
      },
      {
        denominazioneInItaliano: "Pietrarubbia",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "G627"
      },
      {
        denominazioneInItaliano: "Piobbico",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "G682"
      },
      {
        denominazioneInItaliano: "San Costanzo",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "H809"
      },
      {
        denominazioneInItaliano: "San Lorenzo in Campo",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "H958"
      },
      {
        denominazioneInItaliano: "Sant'Angelo in Vado",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "I287"
      },
      {
        denominazioneInItaliano: "Sant'Ippolito",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "I344"
      },
      {
        denominazioneInItaliano: "Serra Sant'Abbondio",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "I654"
      },
      {
        denominazioneInItaliano: "Tavoleto",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "L078"
      },
      {
        denominazioneInItaliano: "Tavullia",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "L081"
      },
      {
        denominazioneInItaliano: "Urbania",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "L498"
      },
      {
        denominazioneInItaliano: "Urbino",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "L500"
      }, {
        denominazioneInItaliano: "Vallefoglia",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "M331"
      },
      {
        denominazioneInItaliano: "Colli al Metauro",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "M380"
      },
      {
        denominazioneInItaliano: "Terre Roveresche",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "M379"
      },
      {
        denominazioneInItaliano: "Sassocorvaro Auditore",
        regione: "Marche",
        provincia: "Pesaro e Urbino",
        codiceCatastale: "M413"
      },
      {
        denominazioneInItaliano: "Agugliano",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "A092"
      },
      {
        denominazioneInItaliano: "Ancona",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "A271"
      },
      {
        denominazioneInItaliano: "Arcevia",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "A366"
      },
      {
        denominazioneInItaliano: "Barbara",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "A626"
      },
      {
        denominazioneInItaliano: "Belvedere Ostrense",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "A769"
      },
      {
        denominazioneInItaliano: "Camerano",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "B468"
      },
      {
        denominazioneInItaliano: "Camerata Picena",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "B470"
      },
      {
        denominazioneInItaliano: "Castelbellino",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "C060"
      },
      {
        denominazioneInItaliano: "Castelfidardo",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "C100"
      },
      {
        denominazioneInItaliano: "Castelleone di Suasa",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "C152"
      },
      {
        denominazioneInItaliano: "Castelplanio",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "C248"
      },
      {
        denominazioneInItaliano: "Cerreto d'Esi",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "C524"
      },
      {
        denominazioneInItaliano: "Chiaravalle",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "C615"
      },
      {
        denominazioneInItaliano: "Corinaldo",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "D007"
      },
      {
        denominazioneInItaliano: "Cupramontana",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "D211"
      },
      {
        denominazioneInItaliano: "Fabriano",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "D451"
      },
      {
        denominazioneInItaliano: "Falconara Marittima",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "D472"
      },
      {
        denominazioneInItaliano: "Filottrano",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "D597"
      },
      {
        denominazioneInItaliano: "Genga",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "D965"
      },
      {
        denominazioneInItaliano: "Jesi",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "E388"
      },
      {
        denominazioneInItaliano: "Loreto",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "E690"
      },
      {
        denominazioneInItaliano: "Maiolati Spontini",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "E837"
      },
      {
        denominazioneInItaliano: "Mergo",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F145"
      },
      {
        denominazioneInItaliano: "Monsano",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F381"
      },
      {
        denominazioneInItaliano: "Montecarotto",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F453"
      },
      {
        denominazioneInItaliano: "Montemarciano",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F560"
      },
      {
        denominazioneInItaliano: "Monte Roberto",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F600"
      },
      {
        denominazioneInItaliano: "Monte San Vito",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F634"
      },
      {
        denominazioneInItaliano: "Morro d'Alba",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F745"
      },
      {
        denominazioneInItaliano: "Numana",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F978"
      },
      {
        denominazioneInItaliano: "Offagna",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "G003"
      },
      {
        denominazioneInItaliano: "Osimo",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "G157"
      },
      {
        denominazioneInItaliano: "Ostra",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F401"
      },
      {
        denominazioneInItaliano: "Ostra Vetere",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "F581"
      },
      {
        denominazioneInItaliano: "Poggio San Marcello",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "G771"
      },
      {
        denominazioneInItaliano: "Polverigi",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "G803"
      },
      {
        denominazioneInItaliano: "Rosora",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "H575"
      },
      {
        denominazioneInItaliano: "San Marcello",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "H979"
      },
      {
        denominazioneInItaliano: "San Paolo di Jesi",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "I071"
      },
      {
        denominazioneInItaliano: "Santa Maria Nuova",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "I251"
      },
      {
        denominazioneInItaliano: "Sassoferrato",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "I461"
      },
      {
        denominazioneInItaliano: "Senigallia",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "I608"
      },
      {
        denominazioneInItaliano: "Serra de' Conti",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "I643"
      },
      {
        denominazioneInItaliano: "Serra San Quirico",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "I653"
      },
      {
        denominazioneInItaliano: "Sirolo",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "I758"
      },
      {
        denominazioneInItaliano: "Staffolo",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "I932"
      },
      {
        denominazioneInItaliano: "Trecastelli",
        regione: "Marche",
        provincia: "Ancona",
        codiceCatastale: "M318"
      },
      {
        denominazioneInItaliano: "Apiro",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "A329"
      },
      {
        denominazioneInItaliano: "Appignano",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "A334"
      },
      {
        denominazioneInItaliano: "Belforte del Chienti",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "A739"
      },
      {
        denominazioneInItaliano: "Bolognola",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "A947"
      },
      {
        denominazioneInItaliano: "Caldarola",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "B398"
      },
      {
        denominazioneInItaliano: "Camerino",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "B474"
      },
      {
        denominazioneInItaliano: "Camporotondo di Fiastrone",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "B562"
      },
      {
        denominazioneInItaliano: "Castelraimondo",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "C251"
      },
      {
        denominazioneInItaliano: "Castelsantangelo sul Nera",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "C267"
      },
      {
        denominazioneInItaliano: "Cessapalombo",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "C582"
      },
      {
        denominazioneInItaliano: "Cingoli",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "C704"
      },
      {
        denominazioneInItaliano: "Civitanova Marche",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "C770"
      },
      {
        denominazioneInItaliano: "Colmurano",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "C886"
      },
      {
        denominazioneInItaliano: "Corridonia",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "D042"
      },
      {
        denominazioneInItaliano: "Esanatoglia",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "D429"
      },
      {
        denominazioneInItaliano: "Fiastra",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "D564"
      },
      {
        denominazioneInItaliano: "Fiuminata",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "D628"
      },
      {
        denominazioneInItaliano: "Gagliole",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "D853"
      },
      {
        denominazioneInItaliano: "Gualdo",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "E228"
      },
      {
        denominazioneInItaliano: "Loro Piceno",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "E694"
      },
      {
        denominazioneInItaliano: "Macerata",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "E783"
      },
      {
        denominazioneInItaliano: "Matelica",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F051"
      },
      {
        denominazioneInItaliano: "Mogliano",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F268"
      },
      {
        denominazioneInItaliano: "Montecassiano",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F454"
      },
      {
        denominazioneInItaliano: "Monte Cavallo",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F460"
      },
      {
        denominazioneInItaliano: "Montecosaro",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F482"
      },
      {
        denominazioneInItaliano: "Montefano",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F496"
      },
      {
        denominazioneInItaliano: "Montelupone",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F552"
      },
      {
        denominazioneInItaliano: "Monte San Giusto",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F621"
      },
      {
        denominazioneInItaliano: "Monte San Martino",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F622"
      },
      {
        denominazioneInItaliano: "Morrovalle",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F749"
      },
      {
        denominazioneInItaliano: "Muccia",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F793"
      },
      {
        denominazioneInItaliano: "Penna San Giovanni",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "G436"
      },
      {
        denominazioneInItaliano: "Petriolo",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "G515"
      },
      {
        denominazioneInItaliano: "Pieve Torina",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "G657"
      },
      {
        denominazioneInItaliano: "Pioraco",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "G690"
      },
      {
        denominazioneInItaliano: "Poggio San Vicino",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "D566"
      },
      {
        denominazioneInItaliano: "Pollenza",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F567"
      },
      {
        denominazioneInItaliano: "Porto Recanati",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "G919"
      },
      {
        denominazioneInItaliano: "Potenza Picena",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "F632"
      },
      {
        denominazioneInItaliano: "Recanati",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "H211"
      },
      {
        denominazioneInItaliano: "Ripe San Ginesio",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "H323"
      },
      {
        denominazioneInItaliano: "San Ginesio",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "H876"
      },
      {
        denominazioneInItaliano: "San Severino Marche",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "I156"
      },
      {
        denominazioneInItaliano: "Sant'Angelo in Pontano",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "I286"
      },
      {
        denominazioneInItaliano: "Sarnano",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "I436"
      },
      {
        denominazioneInItaliano: "Sefro",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "I569"
      },
      {
        denominazioneInItaliano: "Serrapetrona",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "I651"
      },
      {
        denominazioneInItaliano: "Serravalle di Chienti",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "I661"
      },
      {
        denominazioneInItaliano: "Tolentino",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "L191"
      },
      {
        denominazioneInItaliano: "Treia",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "L366"
      },
      {
        denominazioneInItaliano: "Urbisaglia",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "L501"
      },
      {
        denominazioneInItaliano: "Ussita",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "L517"
      },
      {
        denominazioneInItaliano: "Visso",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "M078"
      },
      {
        denominazioneInItaliano: "Valfornace",
        regione: "Marche",
        provincia: "Macerata",
        codiceCatastale: "M382"
      },
      {
        denominazioneInItaliano: "Acquasanta Terme",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "A044"
      },
      {
        denominazioneInItaliano: "Acquaviva Picena",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "A047"
      },
      {
        denominazioneInItaliano: "Appignano del Tronto",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "A335"
      },
      {
        denominazioneInItaliano: "Arquata del Tronto",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "A437"
      },
      {
        denominazioneInItaliano: "Ascoli Piceno",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "A462"
      },
      {
        denominazioneInItaliano: "Carassai",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "B727"
      },
      {
        denominazioneInItaliano: "Castel di Lama",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "C093"
      },
      {
        denominazioneInItaliano: "Castignano",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "C321"
      },
      {
        denominazioneInItaliano: "Castorano",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "C331"
      },
      {
        denominazioneInItaliano: "Colli del Tronto",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "C877"
      },
      {
        denominazioneInItaliano: "Comunanza",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "C935"
      },
      {
        denominazioneInItaliano: "Cossignano",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "D096"
      },
      {
        denominazioneInItaliano: "Cupra Marittima",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "D210"
      },
      {
        denominazioneInItaliano: "Folignano",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "D652"
      },
      {
        denominazioneInItaliano: "Force",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "D691"
      },
      {
        denominazioneInItaliano: "Grottammare",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "E207"
      },
      {
        denominazioneInItaliano: "Maltignano",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "E868"
      },
      {
        denominazioneInItaliano: "Massignano",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "F044"
      },
      {
        denominazioneInItaliano: "Monsampolo del Tronto",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "F380"
      },
      {
        denominazioneInItaliano: "Montalto delle Marche",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "F415"
      },
      {
        denominazioneInItaliano: "Montedinove",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "F487"
      },
      {
        denominazioneInItaliano: "Montefiore dell'Aso",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "F501"
      },
      {
        denominazioneInItaliano: "Montegallo",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "F516"
      },
      {
        denominazioneInItaliano: "Montemonaco",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "F570"
      },
      {
        denominazioneInItaliano: "Monteprandone",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "F591"
      },
      {
        denominazioneInItaliano: "Offida",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "G005"
      },
      {
        denominazioneInItaliano: "Palmiano",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "G289"
      },
      {
        denominazioneInItaliano: "Ripatransone",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "H321"
      },
      {
        denominazioneInItaliano: "Roccafluvione",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "H390"
      },
      {
        denominazioneInItaliano: "Rotella",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "H588"
      },
      {
        denominazioneInItaliano: "San Benedetto del Tronto",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "H769"
      },
      {
        denominazioneInItaliano: "Spinetoli",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "I912"
      },
      {
        denominazioneInItaliano: "Venarotta",
        regione: "Marche",
        provincia: "Ascoli Piceno",
        codiceCatastale: "L728"
      },
      {
        denominazioneInItaliano: "Altidona",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "A233"
      },
      {
        denominazioneInItaliano: "Amandola",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "A252"
      },
      {
        denominazioneInItaliano: "Belmonte Piceno",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "A760"
      },
      {
        denominazioneInItaliano: "Campofilone",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "B534"
      },
      {
        denominazioneInItaliano: "Falerone",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "D477"
      },
      {
        denominazioneInItaliano: "Fermo",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "D542"
      },
      {
        denominazioneInItaliano: "Francavilla d'Ete",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "D760"
      },
      {
        denominazioneInItaliano: "Grottazzolina",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "E208"
      },
      {
        denominazioneInItaliano: "Lapedona",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "E447"
      },
      {
        denominazioneInItaliano: "Magliano di Tenna",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "E807"
      },
      {
        denominazioneInItaliano: "Massa Fermana",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F021"
      },
      {
        denominazioneInItaliano: "Monsampietro Morico",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F379"
      },
      {
        denominazioneInItaliano: "Montappone",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F428"
      },
      {
        denominazioneInItaliano: "Montefalcone Appennino",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F493"
      },
      {
        denominazioneInItaliano: "Montefortino",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F509"
      },
      {
        denominazioneInItaliano: "Monte Giberto",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F517"
      },
      {
        denominazioneInItaliano: "Montegiorgio",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F520"
      },
      {
        denominazioneInItaliano: "Montegranaro",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F522"
      },
      {
        denominazioneInItaliano: "Monteleone di Fermo",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F536"
      },
      {
        denominazioneInItaliano: "Montelparo",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F549"
      },
      {
        denominazioneInItaliano: "Monte Rinaldo",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F599"
      },
      {
        denominazioneInItaliano: "Monterubbiano",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F614"
      },
      {
        denominazioneInItaliano: "Monte San Pietrangeli",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F626"
      },
      {
        denominazioneInItaliano: "Monte Urano",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F653"
      },
      {
        denominazioneInItaliano: "Monte Vidon Combatte",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F664"
      },
      {
        denominazioneInItaliano: "Monte Vidon Corrado",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F665"
      },
      {
        denominazioneInItaliano: "Montottone",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F697"
      },
      {
        denominazioneInItaliano: "Moresco",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "F722"
      },
      {
        denominazioneInItaliano: "Ortezzano",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "G137"
      },
      {
        denominazioneInItaliano: "Pedaso",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "G403"
      },
      {
        denominazioneInItaliano: "Petritoli",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "G516"
      },
      {
        denominazioneInItaliano: "Ponzano di Fermo",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "G873"
      },
      {
        denominazioneInItaliano: "Porto San Giorgio",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "G920"
      },
      {
        denominazioneInItaliano: "Porto Sant'Elpidio",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "G921"
      },
      {
        denominazioneInItaliano: "Rapagnano",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "H182"
      },
      {
        denominazioneInItaliano: "Santa Vittoria in Matenano",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "I315"
      },
      {
        denominazioneInItaliano: "Sant'Elpidio a Mare",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "I324"
      },
      {
        denominazioneInItaliano: "Servigliano",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "C070"
      },
      {
        denominazioneInItaliano: "Smerillo",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "I774"
      },
      {
        denominazioneInItaliano: "Torre San Patrizio",
        regione: "Marche",
        provincia: "Fermo",
        codiceCatastale: "L279"
      },
      {
        denominazioneInItaliano: "Acquapendente",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A040"
      },
      {
        denominazioneInItaliano: "Arlena di Castro",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A412"
      },
      {
        denominazioneInItaliano: "Bagnoregio",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A577"
      },
      {
        denominazioneInItaliano: "Barbarano Romano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A628"
      },
      {
        denominazioneInItaliano: "Bassano Romano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A704"
      },
      {
        denominazioneInItaliano: "Bassano in Teverina",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A706"
      },
      {
        denominazioneInItaliano: "Blera",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A857"
      },
      {
        denominazioneInItaliano: "Bolsena",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A949"
      },
      {
        denominazioneInItaliano: "Bomarzo",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A955"
      },
      {
        denominazioneInItaliano: "Calcata",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "B388"
      },
      {
        denominazioneInItaliano: "Canepina",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "B597"
      },
      {
        denominazioneInItaliano: "Canino",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "B604"
      },
      {
        denominazioneInItaliano: "Capodimonte",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "B663"
      },
      {
        denominazioneInItaliano: "Capranica",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "B688"
      },
      {
        denominazioneInItaliano: "Caprarola",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "B691"
      },
      {
        denominazioneInItaliano: "Carbognano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "B735"
      },
      {
        denominazioneInItaliano: "Castel Sant'Elia",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "C269"
      },
      {
        denominazioneInItaliano: "Castiglione in Teverina",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "C315"
      },
      {
        denominazioneInItaliano: "Celleno",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "C446"
      },
      {
        denominazioneInItaliano: "Cellere",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "C447"
      },
      {
        denominazioneInItaliano: "Civita Castellana",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "C765"
      },
      {
        denominazioneInItaliano: "Civitella d'Agliano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "C780"
      },
      {
        denominazioneInItaliano: "Corchiano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "C988"
      },
      {
        denominazioneInItaliano: "Fabrica di Roma",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "D452"
      },
      {
        denominazioneInItaliano: "Faleria",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "D475"
      },
      {
        denominazioneInItaliano: "Farnese",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "D503"
      },
      {
        denominazioneInItaliano: "Gallese",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "D870"
      },
      {
        denominazioneInItaliano: "Gradoli",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "E126"
      },
      {
        denominazioneInItaliano: "Graffignano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "E128"
      },
      {
        denominazioneInItaliano: "Grotte di Castro",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "E210"
      },
      {
        denominazioneInItaliano: "Ischia di Castro",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "E330"
      },
      {
        denominazioneInItaliano: "Latera",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "E467"
      },
      {
        denominazioneInItaliano: "Lubriano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "E713"
      },
      {
        denominazioneInItaliano: "Marta",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "E978"
      },
      {
        denominazioneInItaliano: "Montalto di Castro",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "F419"
      },
      {
        denominazioneInItaliano: "Montefiascone",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "F499"
      },
      {
        denominazioneInItaliano: "Monte Romano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "F603"
      },
      {
        denominazioneInItaliano: "Monterosi",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "F606"
      },
      {
        denominazioneInItaliano: "Nepi",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "F868"
      },
      {
        denominazioneInItaliano: "Onano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "G065"
      },
      {
        denominazioneInItaliano: "Oriolo Romano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "G111"
      },
      {
        denominazioneInItaliano: "Orte",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "G135"
      },
      {
        denominazioneInItaliano: "Piansano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "G571"
      },
      {
        denominazioneInItaliano: "Proceno",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "H071"
      },
      {
        denominazioneInItaliano: "Ronciglione",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "H534"
      },
      {
        denominazioneInItaliano: "Villa San Giovanni in Tuscia",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "H913"
      },
      {
        denominazioneInItaliano: "San Lorenzo Nuovo",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "H969"
      },
      {
        denominazioneInItaliano: "Soriano nel Cimino",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "I855"
      },
      {
        denominazioneInItaliano: "Sutri",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "L017"
      },
      {
        denominazioneInItaliano: "Tarquinia",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "D024"
      },
      {
        denominazioneInItaliano: "Tessennano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "L150"
      },
      {
        denominazioneInItaliano: "Tuscania",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "L310"
      },
      {
        denominazioneInItaliano: "Valentano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "L569"
      },
      {
        denominazioneInItaliano: "Vallerano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "L612"
      },
      {
        denominazioneInItaliano: "Vasanello",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "A701"
      },
      {
        denominazioneInItaliano: "Vejano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "L713"
      },
      {
        denominazioneInItaliano: "Vetralla",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "L814"
      },
      {
        denominazioneInItaliano: "Vignanello",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "L882"
      },
      {
        denominazioneInItaliano: "Viterbo",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "M082"
      },
      {
        denominazioneInItaliano: "Vitorchiano",
        regione: "Lazio",
        provincia: "Viterbo",
        codiceCatastale: "M086"
      },
      {
        denominazioneInItaliano: "Accumoli",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "A019"
      },
      {
        denominazioneInItaliano: "Amatrice",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "A258"
      },
      {
        denominazioneInItaliano: "Antrodoco",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "A315"
      },
      {
        denominazioneInItaliano: "Ascrea",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "A464"
      },
      {
        denominazioneInItaliano: "Belmonte in Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "A765"
      },
      {
        denominazioneInItaliano: "Borbona",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "A981"
      },
      {
        denominazioneInItaliano: "Borgorose",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "B008"
      },
      {
        denominazioneInItaliano: "Borgo Velino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "A996"
      },
      {
        denominazioneInItaliano: "Cantalice",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "B627"
      },
      {
        denominazioneInItaliano: "Cantalupo in Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "B631"
      },
      {
        denominazioneInItaliano: "Casaprota",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "B934"
      },
      {
        denominazioneInItaliano: "Casperia",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "A472"
      },
      {
        denominazioneInItaliano: "Castel di Tora",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C098"
      },
      {
        denominazioneInItaliano: "Castelnuovo di Farfa",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C224"
      },
      {
        denominazioneInItaliano: "Castel Sant'Angelo",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C268"
      },
      {
        denominazioneInItaliano: "Cittaducale",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C746"
      },
      {
        denominazioneInItaliano: "Cittareale",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C749"
      },
      {
        denominazioneInItaliano: "Collalto Sabino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C841"
      },
      {
        denominazioneInItaliano: "Colle di Tora",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C857"
      },
      {
        denominazioneInItaliano: "Collegiove",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C859"
      },
      {
        denominazioneInItaliano: "Collevecchio",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C876"
      },
      {
        denominazioneInItaliano: "Colli sul Velino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C880"
      },
      {
        denominazioneInItaliano: "Concerviano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C946"
      },
      {
        denominazioneInItaliano: "Configni",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C959"
      },
      {
        denominazioneInItaliano: "Contigliano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "C969"
      },
      {
        denominazioneInItaliano: "Cottanello",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "D124"
      },
      {
        denominazioneInItaliano: "Fara in Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "D493"
      },
      {
        denominazioneInItaliano: "Fiamignano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "D560"
      },
      {
        denominazioneInItaliano: "Forano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "D689"
      },
      {
        denominazioneInItaliano: "Frasso Sabino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "D785"
      },
      {
        denominazioneInItaliano: "Greccio",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "E160"
      },
      {
        denominazioneInItaliano: "Labro",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "E393"
      },
      {
        denominazioneInItaliano: "Leonessa",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "E535"
      },
      {
        denominazioneInItaliano: "Longone Sabino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "E681"
      },
      {
        denominazioneInItaliano: "Magliano Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "E812"
      },
      {
        denominazioneInItaliano: "Marcetelli",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "E927"
      },
      {
        denominazioneInItaliano: "Micigliano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F193"
      },
      {
        denominazioneInItaliano: "Mompeo",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F319"
      },
      {
        denominazioneInItaliano: "Montasola",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F430"
      },
      {
        denominazioneInItaliano: "Montebuono",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F446"
      },
      {
        denominazioneInItaliano: "Monteleone Sabino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F541"
      },
      {
        denominazioneInItaliano: "Montenero Sabino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F579"
      },
      {
        denominazioneInItaliano: "Monte San Giovanni in Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F619"
      },
      {
        denominazioneInItaliano: "Montopoli di Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F687"
      },
      {
        denominazioneInItaliano: "Morro Reatino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F746"
      },
      {
        denominazioneInItaliano: "Nespolo",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "F876"
      },
      {
        denominazioneInItaliano: "Orvinio",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "B595"
      },
      {
        denominazioneInItaliano: "Paganico Sabino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G232"
      },
      {
        denominazioneInItaliano: "Pescorocchiano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G498"
      },
      {
        denominazioneInItaliano: "Petrella Salto",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G513"
      },
      {
        denominazioneInItaliano: "Poggio Bustone",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G756"
      },
      {
        denominazioneInItaliano: "Poggio Catino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G757"
      },
      {
        denominazioneInItaliano: "Poggio Mirteto",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G763"
      },
      {
        denominazioneInItaliano: "Poggio Moiano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G764"
      },
      {
        denominazioneInItaliano: "Poggio Nativo",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G765"
      },
      {
        denominazioneInItaliano: "Poggio San Lorenzo",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G770"
      },
      {
        denominazioneInItaliano: "Posta",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G934"
      },
      {
        denominazioneInItaliano: "Pozzaglia Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G951"
      },
      {
        denominazioneInItaliano: "Rieti",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "H282"
      },
      {
        denominazioneInItaliano: "Rivodutri",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "H354"
      },
      {
        denominazioneInItaliano: "Roccantica",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "H427"
      },
      {
        denominazioneInItaliano: "Rocca Sinibalda",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "H446"
      },
      {
        denominazioneInItaliano: "Salisano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "H713"
      },
      {
        denominazioneInItaliano: "Scandriglia",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "I499"
      },
      {
        denominazioneInItaliano: "Selci",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "I581"
      },
      {
        denominazioneInItaliano: "Stimigliano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "I959"
      },
      {
        denominazioneInItaliano: "Tarano",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "L046"
      },
      {
        denominazioneInItaliano: "Toffia",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "L189"
      },
      {
        denominazioneInItaliano: "Torricella in Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "L293"
      },
      {
        denominazioneInItaliano: "Torri in Sabina",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "L286"
      },
      {
        denominazioneInItaliano: "Turania",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "G507"
      },
      {
        denominazioneInItaliano: "Vacone",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "L525"
      },
      {
        denominazioneInItaliano: "Varco Sabino",
        regione: "Lazio",
        provincia: "Rieti",
        codiceCatastale: "L676"
      },
      {
        denominazioneInItaliano: "Affile",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A062"
      },
      {
        denominazioneInItaliano: "Agosta",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A084"
      },
      {
        denominazioneInItaliano: "Albano Laziale",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A132"
      },
      {
        denominazioneInItaliano: "Allumiere",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A210"
      },
      {
        denominazioneInItaliano: "Anguillara Sabazia",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A297"
      },
      {
        denominazioneInItaliano: "Anticoli Corrado",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A309"
      },
      {
        denominazioneInItaliano: "Anzio",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A323"
      },
      {
        denominazioneInItaliano: "Arcinazzo Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A370"
      },
      {
        denominazioneInItaliano: "Ariccia",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A401"
      },
      {
        denominazioneInItaliano: "Arsoli",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A446"
      },
      {
        denominazioneInItaliano: "Artena",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A449"
      },
      {
        denominazioneInItaliano: "Bellegra",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "A749"
      },
      {
        denominazioneInItaliano: "Bracciano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B114"
      },
      {
        denominazioneInItaliano: "Camerata Nuova",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B472"
      },
      {
        denominazioneInItaliano: "Campagnano di Roma",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B496"
      },
      {
        denominazioneInItaliano: "Canale Monterano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B576"
      },
      {
        denominazioneInItaliano: "Canterano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B635"
      },
      {
        denominazioneInItaliano: "Capena",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B649"
      },
      {
        denominazioneInItaliano: "Capranica Prenestina",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B687"
      },
      {
        denominazioneInItaliano: "Carpineto Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B828"
      },
      {
        denominazioneInItaliano: "Casape",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B932"
      },
      {
        denominazioneInItaliano: "Castel Gandolfo",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C116"
      },
      {
        denominazioneInItaliano: "Castel Madama",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C203"
      },
      {
        denominazioneInItaliano: "Castelnuovo di Porto",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C237"
      },
      {
        denominazioneInItaliano: "Castel San Pietro Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C266"
      },
      {
        denominazioneInItaliano: "Cave",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C390"
      },
      {
        denominazioneInItaliano: "Cerreto Laziale",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C518"
      },
      {
        denominazioneInItaliano: "Cervara di Roma",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C543"
      },
      {
        denominazioneInItaliano: "Cerveteri",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C552"
      },
      {
        denominazioneInItaliano: "Ciciliano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C677"
      },
      {
        denominazioneInItaliano: "Cineto Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C702"
      },
      {
        denominazioneInItaliano: "Civitavecchia",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C773"
      },
      {
        denominazioneInItaliano: "Civitella San Paolo",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C784"
      },
      {
        denominazioneInItaliano: "Colleferro",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C858"
      },
      {
        denominazioneInItaliano: "Colonna",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C900"
      },
      {
        denominazioneInItaliano: "Fiano Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D561"
      },
      {
        denominazioneInItaliano: "Filacciano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D586"
      },
      {
        denominazioneInItaliano: "Formello",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D707"
      },
      {
        denominazioneInItaliano: "Frascati",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D773"
      },
      {
        denominazioneInItaliano: "Gallicano nel Lazio",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D875"
      },
      {
        denominazioneInItaliano: "Gavignano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D945"
      },
      {
        denominazioneInItaliano: "Genazzano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D964"
      },
      {
        denominazioneInItaliano: "Genzano di Roma",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D972"
      },
      {
        denominazioneInItaliano: "Gerano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "D978"
      },
      {
        denominazioneInItaliano: "Gorga",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E091"
      },
      {
        denominazioneInItaliano: "Grottaferrata",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E204"
      },
      {
        denominazioneInItaliano: "Guidonia Montecelio",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E263"
      },
      {
        denominazioneInItaliano: "Jenne",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E382"
      },
      {
        denominazioneInItaliano: "Labico",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E392"
      },
      {
        denominazioneInItaliano: "Lanuvio",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "C767"
      },
      {
        denominazioneInItaliano: "Licenza",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E576"
      },
      {
        denominazioneInItaliano: "Magliano Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E813"
      },
      {
        denominazioneInItaliano: "Mandela",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "B632"
      },
      {
        denominazioneInItaliano: "Manziana",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E900"
      },
      {
        denominazioneInItaliano: "Marano Equo",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E908"
      },
      {
        denominazioneInItaliano: "Marcellina",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E924"
      },
      {
        denominazioneInItaliano: "Marino",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "E958"
      },
      {
        denominazioneInItaliano: "Mazzano Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F064"
      },
      {
        denominazioneInItaliano: "Mentana",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F127"
      },
      {
        denominazioneInItaliano: "Monte Compatri",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F477"
      },
      {
        denominazioneInItaliano: "Monteflavio",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F504"
      },
      {
        denominazioneInItaliano: "Montelanico",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F534"
      },
      {
        denominazioneInItaliano: "Montelibretti",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F545"
      },
      {
        denominazioneInItaliano: "Monte Porzio Catone",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F590"
      },
      {
        denominazioneInItaliano: "Monterotondo",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F611"
      },
      {
        denominazioneInItaliano: "Montorio Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F692"
      },
      {
        denominazioneInItaliano: "Moricone",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F730"
      },
      {
        denominazioneInItaliano: "Morlupo",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F734"
      },
      {
        denominazioneInItaliano: "Nazzano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F857"
      },
      {
        denominazioneInItaliano: "Nemi",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F865"
      },
      {
        denominazioneInItaliano: "Nerola",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F871"
      },
      {
        denominazioneInItaliano: "Nettuno",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "F880"
      },
      {
        denominazioneInItaliano: "Olevano Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "G022"
      },
      {
        denominazioneInItaliano: "Palestrina",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "G274"
      },
      {
        denominazioneInItaliano: "Palombara Sabina",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "G293"
      },
      {
        denominazioneInItaliano: "Percile",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "G444"
      },
      {
        denominazioneInItaliano: "Pisoniano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "G704"
      },
      {
        denominazioneInItaliano: "Poli",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "G784"
      },
      {
        denominazioneInItaliano: "Pomezia",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "G811"
      },
      {
        denominazioneInItaliano: "Ponzano Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "G874"
      },
      {
        denominazioneInItaliano: "Riano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H267"
      },
      {
        denominazioneInItaliano: "Rignano Flaminio",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H288"
      },
      {
        denominazioneInItaliano: "Riofreddo",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H300"
      },
      {
        denominazioneInItaliano: "Rocca Canterano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H387"
      },
      {
        denominazioneInItaliano: "Rocca di Cave",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H401"
      },
      {
        denominazioneInItaliano: "Rocca di Papa",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H404"
      },
      {
        denominazioneInItaliano: "Roccagiovine",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H411"
      },
      {
        denominazioneInItaliano: "Rocca Priora",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H432"
      },
      {
        denominazioneInItaliano: "Rocca Santo Stefano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H441"
      },
      {
        denominazioneInItaliano: "Roiate",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H494"
      },
      {
        denominazioneInItaliano: "Roma",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H501"
      },
      {
        denominazioneInItaliano: "Roviano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H618"
      },
      {
        denominazioneInItaliano: "Sacrofano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H658"
      },
      {
        denominazioneInItaliano: "Sambuci",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H745"
      },
      {
        denominazioneInItaliano: "San Gregorio da Sassola",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "H942"
      },
      {
        denominazioneInItaliano: "San Polo dei Cavalieri",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "I125"
      },
      {
        denominazioneInItaliano: "Santa Marinella",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "I255"
      },
      {
        denominazioneInItaliano: "Sant'Angelo Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "I284"
      },
      {
        denominazioneInItaliano: "Sant'Oreste",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "I352"
      },
      {
        denominazioneInItaliano: "San Vito Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "I400"
      },
      {
        denominazioneInItaliano: "Saracinesco",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "I424"
      },
      {
        denominazioneInItaliano: "Segni",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "I573"
      },
      {
        denominazioneInItaliano: "Subiaco",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "I992"
      },
      {
        denominazioneInItaliano: "Tivoli",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L182"
      },
      {
        denominazioneInItaliano: "Tolfa",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L192"
      },
      {
        denominazioneInItaliano: "Torrita Tiberina",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L302"
      },
      {
        denominazioneInItaliano: "Trevignano Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L401"
      },
      {
        denominazioneInItaliano: "Vallepietra",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L611"
      },
      {
        denominazioneInItaliano: "Vallinfreda",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L625"
      },
      {
        denominazioneInItaliano: "Valmontone",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L639"
      },
      {
        denominazioneInItaliano: "Velletri",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L719"
      },
      {
        denominazioneInItaliano: "Vicovaro",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "L851"
      },
      {
        denominazioneInItaliano: "Vivaro Romano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M095"
      },
      {
        denominazioneInItaliano: "Zagarolo",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M141"
      },
      {
        denominazioneInItaliano: "Lariano",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M207"
      },
      {
        denominazioneInItaliano: "Ladispoli",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M212"
      },
      {
        denominazioneInItaliano: "Ardea",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M213"
      },
      {
        denominazioneInItaliano: "Ciampino",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M272"
      },
      {
        denominazioneInItaliano: "San Cesareo",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M295"
      },
      {
        denominazioneInItaliano: "Fiumicino",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M297"
      },
      {
        denominazioneInItaliano: "Fonte Nuova",
        regione: "Lazio",
        provincia: "Roma",
        codiceCatastale: "M309"
      },
      {
        denominazioneInItaliano: "Aprilia",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "A341"
      },
      {
        denominazioneInItaliano: "Bassiano",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "A707"
      },
      {
        denominazioneInItaliano: "Campodimele",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "B527"
      },
      {
        denominazioneInItaliano: "Castelforte",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "C104"
      },
      {
        denominazioneInItaliano: "Cisterna di Latina",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "C740"
      },
      {
        denominazioneInItaliano: "Cori",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "D003"
      },
      {
        denominazioneInItaliano: "Fondi",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "D662"
      },
      {
        denominazioneInItaliano: "Formia",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "D708"
      },
      {
        denominazioneInItaliano: "Gaeta",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "D843"
      },
      {
        denominazioneInItaliano: "Itri",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "E375"
      },
      {
        denominazioneInItaliano: "Latina",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "E472"
      },
      {
        denominazioneInItaliano: "Lenola",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "E527"
      },
      {
        denominazioneInItaliano: "Maenza",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "E798"
      },
      {
        denominazioneInItaliano: "Minturno",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "F224"
      },
      {
        denominazioneInItaliano: "Monte San Biagio",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "F616"
      },
      {
        denominazioneInItaliano: "Norma",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "F937"
      },
      {
        denominazioneInItaliano: "Pontinia",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "G865"
      },
      {
        denominazioneInItaliano: "Ponza",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "G871"
      },
      {
        denominazioneInItaliano: "Priverno",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "G698"
      },
      {
        denominazioneInItaliano: "Prossedi",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "H076"
      },
      {
        denominazioneInItaliano: "Roccagorga",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "H413"
      },
      {
        denominazioneInItaliano: "Rocca Massima",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "H421"
      },
      {
        denominazioneInItaliano: "Roccasecca dei Volsci",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "H444"
      },
      {
        denominazioneInItaliano: "Sabaudia",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "H647"
      },
      {
        denominazioneInItaliano: "San Felice Circeo",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "H836"
      },
      {
        denominazioneInItaliano: "Santi Cosma e Damiano",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "I339"
      },
      {
        denominazioneInItaliano: "Sermoneta",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "I634"
      },
      {
        denominazioneInItaliano: "Sezze",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "I712"
      },
      {
        denominazioneInItaliano: "Sonnino",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "I832"
      },
      {
        denominazioneInItaliano: "Sperlonga",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "I892"
      },
      {
        denominazioneInItaliano: "Spigno Saturnia",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "I902"
      },
      {
        denominazioneInItaliano: "Terracina",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "L120"
      },
      {
        denominazioneInItaliano: "Ventotene",
        regione: "Lazio",
        provincia: "Latina",
        codiceCatastale: "L742"
      },
      {
        denominazioneInItaliano: "Acquafondata",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A032"
      },
      {
        denominazioneInItaliano: "Acuto",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A054"
      },
      {
        denominazioneInItaliano: "Alatri",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A123"
      },
      {
        denominazioneInItaliano: "Alvito",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A244"
      },
      {
        denominazioneInItaliano: "Amaseno",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A256"
      },
      {
        denominazioneInItaliano: "Anagni",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A269"
      },
      {
        denominazioneInItaliano: "Aquino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A348"
      },
      {
        denominazioneInItaliano: "Arce",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A363"
      },
      {
        denominazioneInItaliano: "Arnara",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A421"
      },
      {
        denominazioneInItaliano: "Arpino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A433"
      },
      {
        denominazioneInItaliano: "Atina",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A486"
      },
      {
        denominazioneInItaliano: "Ausonia",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A502"
      },
      {
        denominazioneInItaliano: "Belmonte Castello",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A763"
      },
      {
        denominazioneInItaliano: "Boville Ernica",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A720"
      },
      {
        denominazioneInItaliano: "Broccostella",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "B195"
      },
      {
        denominazioneInItaliano: "Campoli Appennino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "B543"
      },
      {
        denominazioneInItaliano: "Casalattico",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "B862"
      },
      {
        denominazioneInItaliano: "Casalvieri",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "B919"
      },
      {
        denominazioneInItaliano: "Cassino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C034"
      },
      {
        denominazioneInItaliano: "Castelliri",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C177"
      },
      {
        denominazioneInItaliano: "Castelnuovo Parano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C223"
      },
      {
        denominazioneInItaliano: "Castrocielo",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C340"
      },
      {
        denominazioneInItaliano: "Castro dei Volsci",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C338"
      },
      {
        denominazioneInItaliano: "Ceccano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C413"
      },
      {
        denominazioneInItaliano: "Ceprano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C479"
      },
      {
        denominazioneInItaliano: "Cervaro",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C545"
      },
      {
        denominazioneInItaliano: "Colfelice",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C836"
      },
      {
        denominazioneInItaliano: "Collepardo",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C864"
      },
      {
        denominazioneInItaliano: "Colle San Magno",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C870"
      },
      {
        denominazioneInItaliano: "Coreno Ausonio",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "C998"
      },
      {
        denominazioneInItaliano: "Esperia",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D440"
      },
      {
        denominazioneInItaliano: "Falvaterra",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D483"
      },
      {
        denominazioneInItaliano: "Ferentino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D539"
      },
      {
        denominazioneInItaliano: "Filettino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D591"
      },
      {
        denominazioneInItaliano: "Fiuggi",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A310"
      },
      {
        denominazioneInItaliano: "Fontana Liri",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D667"
      },
      {
        denominazioneInItaliano: "Fontechiari",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D682"
      },
      {
        denominazioneInItaliano: "Frosinone",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D810"
      },
      {
        denominazioneInItaliano: "Fumone",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D819"
      },
      {
        denominazioneInItaliano: "Gallinaro",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "D881"
      },
      {
        denominazioneInItaliano: "Giuliano di Roma",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "E057"
      },
      {
        denominazioneInItaliano: "Guarcino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "E236"
      },
      {
        denominazioneInItaliano: "Isola del Liri",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "E340"
      },
      {
        denominazioneInItaliano: "Monte San Giovanni Campano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "F620"
      },
      {
        denominazioneInItaliano: "Morolo",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "F740"
      },
      {
        denominazioneInItaliano: "Paliano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G276"
      },
      {
        denominazioneInItaliano: "Pastena",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G362"
      },
      {
        denominazioneInItaliano: "Patrica",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G374"
      },
      {
        denominazioneInItaliano: "Pescosolido",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G500"
      },
      {
        denominazioneInItaliano: "Picinisco",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G591"
      },
      {
        denominazioneInItaliano: "Pico",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G592"
      },
      {
        denominazioneInItaliano: "Piedimonte San Germano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G598"
      },
      {
        denominazioneInItaliano: "Piglio",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G659"
      },
      {
        denominazioneInItaliano: "Pignataro Interamna",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G662"
      },
      {
        denominazioneInItaliano: "Pofi",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G749"
      },
      {
        denominazioneInItaliano: "Pontecorvo",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G838"
      },
      {
        denominazioneInItaliano: "Posta Fibreno",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "G935"
      },
      {
        denominazioneInItaliano: "Ripi",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "H324"
      },
      {
        denominazioneInItaliano: "Rocca d'Arce",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "H393"
      },
      {
        denominazioneInItaliano: "Roccasecca",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "H443"
      },
      {
        denominazioneInItaliano: "San Biagio Saracinisco",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "H779"
      },
      {
        denominazioneInItaliano: "San Donato Val di Comino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "H824"
      },
      {
        denominazioneInItaliano: "San Giorgio a Liri",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "H880"
      },
      {
        denominazioneInItaliano: "San Giovanni Incarico",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "H917"
      },
      {
        denominazioneInItaliano: "Sant'Ambrogio sul Garigliano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I256"
      },
      {
        denominazioneInItaliano: "Sant'Andrea del Garigliano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I265"
      },
      {
        denominazioneInItaliano: "Sant'Apollinare",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I302"
      },
      {
        denominazioneInItaliano: "Sant'Elia Fiumerapido",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I321"
      },
      {
        denominazioneInItaliano: "Santopadre",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I351"
      },
      {
        denominazioneInItaliano: "San Vittore del Lazio",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I408"
      },
      {
        denominazioneInItaliano: "Serrone",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I669"
      },
      {
        denominazioneInItaliano: "Settefrati",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I697"
      },
      {
        denominazioneInItaliano: "Sgurgola",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I716"
      },
      {
        denominazioneInItaliano: "Sora",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I838"
      },
      {
        denominazioneInItaliano: "Strangolagalli",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I973"
      },
      {
        denominazioneInItaliano: "Supino",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L009"
      },
      {
        denominazioneInItaliano: "Terelle",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L105"
      },
      {
        denominazioneInItaliano: "Torre Cajetani",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L243"
      },
      {
        denominazioneInItaliano: "Torrice",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L290"
      },
      {
        denominazioneInItaliano: "Trevi nel Lazio",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L398"
      },
      {
        denominazioneInItaliano: "Trivigliano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L437"
      },
      {
        denominazioneInItaliano: "Vallecorsa",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L598"
      },
      {
        denominazioneInItaliano: "Vallemaio",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L605"
      },
      {
        denominazioneInItaliano: "Vallerotonda",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L614"
      },
      {
        denominazioneInItaliano: "Veroli",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L780"
      },
      {
        denominazioneInItaliano: "Vicalvi",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L836"
      },
      {
        denominazioneInItaliano: "Vico nel Lazio",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L843"
      },
      {
        denominazioneInItaliano: "Villa Latina",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "A081"
      },
      {
        denominazioneInItaliano: "Villa Santa Lucia",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "L905"
      },
      {
        denominazioneInItaliano: "Villa Santo Stefano",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "I364"
      },
      {
        denominazioneInItaliano: "Viticuso",
        regione: "Lazio",
        provincia: "Frosinone",
        codiceCatastale: "M083"
      },
      {
        denominazioneInItaliano: "Acciano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A018"
      },
      {
        denominazioneInItaliano: "Aielli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A100"
      },
      {
        denominazioneInItaliano: "Alfedena",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A187"
      },
      {
        denominazioneInItaliano: "Anversa degli Abruzzi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A318"
      },
      {
        denominazioneInItaliano: "Ateleta",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A481"
      },
      {
        denominazioneInItaliano: "Avezzano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A515"
      },
      {
        denominazioneInItaliano: "Balsorano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A603"
      },
      {
        denominazioneInItaliano: "Barete",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A656"
      },
      {
        denominazioneInItaliano: "Barisciano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A667"
      },
      {
        denominazioneInItaliano: "Barrea",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A678"
      },
      {
        denominazioneInItaliano: "Bisegna",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A884"
      },
      {
        denominazioneInItaliano: "Bugnara",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B256"
      },
      {
        denominazioneInItaliano: "Cagnano Amiterno",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B358"
      },
      {
        denominazioneInItaliano: "Calascio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B382"
      },
      {
        denominazioneInItaliano: "Campo di Giove",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B526"
      },
      {
        denominazioneInItaliano: "Campotosto",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B569"
      },
      {
        denominazioneInItaliano: "Canistro",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B606"
      },
      {
        denominazioneInItaliano: "Cansano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B624"
      },
      {
        denominazioneInItaliano: "Capestrano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B651"
      },
      {
        denominazioneInItaliano: "Capistrello",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B656"
      },
      {
        denominazioneInItaliano: "Capitignano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B658"
      },
      {
        denominazioneInItaliano: "Caporciano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B672"
      },
      {
        denominazioneInItaliano: "Cappadocia",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B677"
      },
      {
        denominazioneInItaliano: "Carapelle Calvisio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B725"
      },
      {
        denominazioneInItaliano: "Carsoli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "B842"
      },
      {
        denominazioneInItaliano: "Castel del Monte",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C083"
      },
      {
        denominazioneInItaliano: "Castel di Ieri",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C090"
      },
      {
        denominazioneInItaliano: "Castel di Sangro",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C096"
      },
      {
        denominazioneInItaliano: "Castellafiume",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C126"
      },
      {
        denominazioneInItaliano: "Castelvecchio Calvisio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C278"
      },
      {
        denominazioneInItaliano: "Castelvecchio Subequo",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C279"
      },
      {
        denominazioneInItaliano: "Celano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C426"
      },
      {
        denominazioneInItaliano: "Cerchio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C492"
      },
      {
        denominazioneInItaliano: "Civita d'Antino",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C766"
      },
      {
        denominazioneInItaliano: "Civitella Alfedena",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C778"
      },
      {
        denominazioneInItaliano: "Civitella Roveto",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C783"
      },
      {
        denominazioneInItaliano: "Cocullo",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C811"
      },
      {
        denominazioneInItaliano: "Collarmele",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C844"
      },
      {
        denominazioneInItaliano: "Collelongo",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C862"
      },
      {
        denominazioneInItaliano: "Collepietro",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C866"
      },
      {
        denominazioneInItaliano: "Corfinio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "C999"
      },
      {
        denominazioneInItaliano: "Fagnano Alto",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "D465"
      },
      {
        denominazioneInItaliano: "Fontecchio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "D681"
      },
      {
        denominazioneInItaliano: "Fossa",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "D736"
      },
      {
        denominazioneInItaliano: "Gagliano Aterno",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "D850"
      },
      {
        denominazioneInItaliano: "Gioia dei Marsi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "E040"
      },
      {
        denominazioneInItaliano: "Goriano Sicoli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "E096"
      },
      {
        denominazioneInItaliano: "Introdacqua",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "E307"
      },
      {
        denominazioneInItaliano: "L'Aquila",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "A345"
      },
      {
        denominazioneInItaliano: "Lecce nei Marsi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "E505"
      },
      {
        denominazioneInItaliano: "Luco dei Marsi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "E723"
      },
      {
        denominazioneInItaliano: "Lucoli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "E724"
      },
      {
        denominazioneInItaliano: "Magliano de' Marsi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "E811"
      },
      {
        denominazioneInItaliano: "Massa d'Albe",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "F022"
      },
      {
        denominazioneInItaliano: "Molina Aterno",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "M255"
      },
      {
        denominazioneInItaliano: "Montereale",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "F595"
      },
      {
        denominazioneInItaliano: "Morino",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "F732"
      },
      {
        denominazioneInItaliano: "Navelli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "F852"
      },
      {
        denominazioneInItaliano: "Ocre",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "F996"
      },
      {
        denominazioneInItaliano: "Ofena",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G002"
      },
      {
        denominazioneInItaliano: "Opi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G079"
      },
      {
        denominazioneInItaliano: "Oricola",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G102"
      },
      {
        denominazioneInItaliano: "Ortona dei Marsi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G142"
      },
      {
        denominazioneInItaliano: "Ortucchio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G145"
      },
      {
        denominazioneInItaliano: "Ovindoli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G200"
      },
      {
        denominazioneInItaliano: "Pacentro",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G210"
      },
      {
        denominazioneInItaliano: "Pereto",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G449"
      },
      {
        denominazioneInItaliano: "Pescasseroli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G484"
      },
      {
        denominazioneInItaliano: "Pescina",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G492"
      },
      {
        denominazioneInItaliano: "Pescocostanzo",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G493"
      },
      {
        denominazioneInItaliano: "Pettorano sul Gizio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G524"
      },
      {
        denominazioneInItaliano: "Pizzoli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G726"
      },
      {
        denominazioneInItaliano: "Poggio Picenze",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G766"
      },
      {
        denominazioneInItaliano: "Prata d'Ansidonia",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "G992"
      },
      {
        denominazioneInItaliano: "Pratola Peligna",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H007"
      },
      {
        denominazioneInItaliano: "Prezza",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H056"
      },
      {
        denominazioneInItaliano: "Raiano",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H166"
      },
      {
        denominazioneInItaliano: "Rivisondoli",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H353"
      },
      {
        denominazioneInItaliano: "Roccacasale",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H389"
      },
      {
        denominazioneInItaliano: "Rocca di Botte",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H399"
      },
      {
        denominazioneInItaliano: "Rocca di Cambio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H400"
      },
      {
        denominazioneInItaliano: "Rocca di Mezzo",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H402"
      },
      {
        denominazioneInItaliano: "Rocca Pia",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H429"
      },
      {
        denominazioneInItaliano: "Roccaraso",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H434"
      },
      {
        denominazioneInItaliano: "San Benedetto dei Marsi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H772"
      },
      {
        denominazioneInItaliano: "San Benedetto in Perillis",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H773"
      },
      {
        denominazioneInItaliano: "San Demetrio ne' Vestini",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "H819"
      },
      {
        denominazioneInItaliano: "San Pio delle Camere",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I121"
      },
      {
        denominazioneInItaliano: "Sante Marie",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I326"
      },
      {
        denominazioneInItaliano: "Sant'Eusanio Forconese",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I336"
      },
      {
        denominazioneInItaliano: "Santo Stefano di Sessanio",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I360"
      },
      {
        denominazioneInItaliano: "San Vincenzo Valle Roveto",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I389"
      },
      {
        denominazioneInItaliano: "Scanno",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I501"
      },
      {
        denominazioneInItaliano: "Scontrone",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I543"
      },
      {
        denominazioneInItaliano: "Scoppito",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I546"
      },
      {
        denominazioneInItaliano: "Scurcola Marsicana",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I553"
      },
      {
        denominazioneInItaliano: "Secinaro",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I558"
      },
      {
        denominazioneInItaliano: "Sulmona",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "I804"
      },
      {
        denominazioneInItaliano: "Tagliacozzo",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "L025"
      },
      {
        denominazioneInItaliano: "Tione degli Abruzzi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "L173"
      },
      {
        denominazioneInItaliano: "Tornimparte",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "L227"
      },
      {
        denominazioneInItaliano: "Trasacco",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "L334"
      },
      {
        denominazioneInItaliano: "Villalago",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "L958"
      },
      {
        denominazioneInItaliano: "Villa Santa Lucia degli Abruzzi",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "M021"
      },
      {
        denominazioneInItaliano: "Villa Sant'Angelo",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "M023"
      },
      {
        denominazioneInItaliano: "Villavallelonga",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "M031"
      },
      {
        denominazioneInItaliano: "Villetta Barrea",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "M041"
      },
      {
        denominazioneInItaliano: "Vittorito",
        regione: "Abruzzo",
        provincia: "L'Aquila",
        codiceCatastale: "M090"
      },
      {
        denominazioneInItaliano: "Alba Adriatica",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "A125"
      },
      {
        denominazioneInItaliano: "Ancarano",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "A270"
      },
      {
        denominazioneInItaliano: "Arsita",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "A445"
      },
      {
        denominazioneInItaliano: "Atri",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "A488"
      },
      {
        denominazioneInItaliano: "Basciano",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "A692"
      },
      {
        denominazioneInItaliano: "Bellante",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "A746"
      },
      {
        denominazioneInItaliano: "Bisenti",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "A885"
      },
      {
        denominazioneInItaliano: "Campli",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "B515"
      },
      {
        denominazioneInItaliano: "Canzano",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "B640"
      },
      {
        denominazioneInItaliano: "Castel Castagna",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C040"
      },
      {
        denominazioneInItaliano: "Castellalto",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C128"
      },
      {
        denominazioneInItaliano: "Castelli",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C169"
      },
      {
        denominazioneInItaliano: "Castiglione Messer Raimondo",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C316"
      },
      {
        denominazioneInItaliano: "Castilenti",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C322"
      },
      {
        denominazioneInItaliano: "Cellino Attanasio",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C449"
      },
      {
        denominazioneInItaliano: "Cermignano",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C517"
      },
      {
        denominazioneInItaliano: "Civitella del Tronto",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C781"
      },
      {
        denominazioneInItaliano: "Colledara",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C311"
      },
      {
        denominazioneInItaliano: "Colonnella",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C901"
      },
      {
        denominazioneInItaliano: "Controguerra",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "C972"
      },
      {
        denominazioneInItaliano: "Corropoli",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "D043"
      },
      {
        denominazioneInItaliano: "Cortino",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "D076"
      },
      {
        denominazioneInItaliano: "Crognaleto",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "D179"
      },
      {
        denominazioneInItaliano: "Fano Adriano",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "D489"
      },
      {
        denominazioneInItaliano: "Giulianova",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "E058"
      },
      {
        denominazioneInItaliano: "Isola del Gran Sasso d'Italia",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "E343"
      },
      {
        denominazioneInItaliano: "Montefino",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "F500"
      },
      {
        denominazioneInItaliano: "Montorio al Vomano",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "F690"
      },
      {
        denominazioneInItaliano: "Morro d'Oro",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "F747"
      },
      {
        denominazioneInItaliano: "Mosciano Sant'Angelo",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "F764"
      },
      {
        denominazioneInItaliano: "Nereto",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "F870"
      },
      {
        denominazioneInItaliano: "Notaresco",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "F942"
      },
      {
        denominazioneInItaliano: "Penna Sant'Andrea",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "G437"
      },
      {
        denominazioneInItaliano: "Pietracamela",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "G608"
      },
      {
        denominazioneInItaliano: "Pineto",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "F831"
      },
      {
        denominazioneInItaliano: "Rocca Santa Maria",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "H440"
      },
      {
        denominazioneInItaliano: "Roseto degli Abruzzi",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "F585"
      },
      {
        denominazioneInItaliano: "Sant'Egidio alla Vibrata",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "I318"
      },
      {
        denominazioneInItaliano: "Sant'Omero",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "I348"
      },
      {
        denominazioneInItaliano: "Silvi",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "I741"
      },
      {
        denominazioneInItaliano: "Teramo",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "L103"
      },
      {
        denominazioneInItaliano: "Torano Nuovo",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "L207"
      },
      {
        denominazioneInItaliano: "Torricella Sicura",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "L295"
      },
      {
        denominazioneInItaliano: "Tortoreto",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "L307"
      },
      {
        denominazioneInItaliano: "Tossicia",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "L314"
      },
      {
        denominazioneInItaliano: "Valle Castellana",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "L597"
      },
      {
        denominazioneInItaliano: "Martinsicuro",
        regione: "Abruzzo",
        provincia: "Teramo",
        codiceCatastale: "E989"
      },
      {
        denominazioneInItaliano: "Abbateggio",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "A008"
      },
      {
        denominazioneInItaliano: "Alanno",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "A120"
      },
      {
        denominazioneInItaliano: "Bolognano",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "A945"
      },
      {
        denominazioneInItaliano: "Brittoli",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "B193"
      },
      {
        denominazioneInItaliano: "Bussi sul Tirino",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "B294"
      },
      {
        denominazioneInItaliano: "Cappelle sul Tavo",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "B681"
      },
      {
        denominazioneInItaliano: "Caramanico Terme",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "B722"
      },
      {
        denominazioneInItaliano: "Carpineto della Nora",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "B827"
      },
      {
        denominazioneInItaliano: "Castiglione a Casauria",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "C308"
      },
      {
        denominazioneInItaliano: "Catignano",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "C354"
      },
      {
        denominazioneInItaliano: "Cepagatti",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "C474"
      },
      {
        denominazioneInItaliano: "Città Sant'Angelo",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "C750"
      },
      {
        denominazioneInItaliano: "Civitaquana",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "C771"
      },
      {
        denominazioneInItaliano: "Civitella Casanova",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "C779"
      },
      {
        denominazioneInItaliano: "Collecorvino",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "C853"
      },
      {
        denominazioneInItaliano: "Corvara",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "D078"
      },
      {
        denominazioneInItaliano: "Cugnoli",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "D201"
      },
      {
        denominazioneInItaliano: "Elice",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "D394"
      },
      {
        denominazioneInItaliano: "Farindola",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "D501"
      },
      {
        denominazioneInItaliano: "Lettomanoppello",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "E558"
      },
      {
        denominazioneInItaliano: "Loreto Aprutino",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "E691"
      },
      {
        denominazioneInItaliano: "Manoppello",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "E892"
      },
      {
        denominazioneInItaliano: "Montebello di Bertona",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "F441"
      },
      {
        denominazioneInItaliano: "Montesilvano",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "F646"
      },
      {
        denominazioneInItaliano: "Moscufo",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "F765"
      },
      {
        denominazioneInItaliano: "Nocciano",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "F908"
      },
      {
        denominazioneInItaliano: "Penne",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "G438"
      },
      {
        denominazioneInItaliano: "Pescara",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "G482"
      },
      {
        denominazioneInItaliano: "Pescosansonesco",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "G499"
      },
      {
        denominazioneInItaliano: "Pianella",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "G555"
      },
      {
        denominazioneInItaliano: "Picciano",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "G589"
      },
      {
        denominazioneInItaliano: "Pietranico",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "G621"
      },
      {
        denominazioneInItaliano: "Popoli",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "G878"
      },
      {
        denominazioneInItaliano: "Roccamorice",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "H425"
      },
      {
        denominazioneInItaliano: "Rosciano",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "H562"
      },
      {
        denominazioneInItaliano: "Salle",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "H715"
      },
      {
        denominazioneInItaliano: "Sant'Eufemia a Maiella",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "I332"
      },
      {
        denominazioneInItaliano: "San Valentino in Abruzzo Citeriore",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "I376"
      },
      {
        denominazioneInItaliano: "Scafa",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "I482"
      },
      {
        denominazioneInItaliano: "Serramonacesca",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "I649"
      },
      {
        denominazioneInItaliano: "Spoltore",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "I922"
      },
      {
        denominazioneInItaliano: "Tocco da Casauria",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "L186"
      },
      {
        denominazioneInItaliano: "Torre de' Passeri",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "L263"
      },
      {
        denominazioneInItaliano: "Turrivalignani",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "L475"
      },
      {
        denominazioneInItaliano: "Vicoli",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "L846"
      },
      {
        denominazioneInItaliano: "Villa Celiera",
        regione: "Abruzzo",
        provincia: "Pescara",
        codiceCatastale: "L922"
      },
      {
        denominazioneInItaliano: "Altino",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "A235"
      },
      {
        denominazioneInItaliano: "Archi",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "A367"
      },
      {
        denominazioneInItaliano: "Ari",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "A398"
      },
      {
        denominazioneInItaliano: "Arielli",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "A402"
      },
      {
        denominazioneInItaliano: "Atessa",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "A485"
      },
      {
        denominazioneInItaliano: "Bomba",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "A956"
      },
      {
        denominazioneInItaliano: "Borrello",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B057"
      },
      {
        denominazioneInItaliano: "Bucchianico",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B238"
      },
      {
        denominazioneInItaliano: "Montebello sul Sangro",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B268"
      },
      {
        denominazioneInItaliano: "Canosa Sannita",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B620"
      },
      {
        denominazioneInItaliano: "Carpineto Sinello",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B826"
      },
      {
        denominazioneInItaliano: "Carunchio",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B853"
      },
      {
        denominazioneInItaliano: "Casacanditella",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B859"
      },
      {
        denominazioneInItaliano: "Casalanguida",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B861"
      },
      {
        denominazioneInItaliano: "Casalbordino",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B865"
      },
      {
        denominazioneInItaliano: "Casalincontrada",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B896"
      },
      {
        denominazioneInItaliano: "Casoli",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "B985"
      },
      {
        denominazioneInItaliano: "Castel Frentano",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C114"
      },
      {
        denominazioneInItaliano: "Castelguidone",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C123"
      },
      {
        denominazioneInItaliano: "Castiglione Messer Marino",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C298"
      },
      {
        denominazioneInItaliano: "Celenza sul Trigno",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C428"
      },
      {
        denominazioneInItaliano: "Chieti",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C632"
      },
      {
        denominazioneInItaliano: "Civitaluparella",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C768"
      },
      {
        denominazioneInItaliano: "Civitella Messer Raimondo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C776"
      },
      {
        denominazioneInItaliano: "Colledimacine",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C855"
      },
      {
        denominazioneInItaliano: "Colledimezzo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "C856"
      },
      {
        denominazioneInItaliano: "Crecchio",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D137"
      },
      {
        denominazioneInItaliano: "Cupello",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D209"
      },
      {
        denominazioneInItaliano: "Dogliola",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D315"
      },
      {
        denominazioneInItaliano: "Fara Filiorum Petri",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D494"
      },
      {
        denominazioneInItaliano: "Fara San Martino",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D495"
      },
      {
        denominazioneInItaliano: "Filetto",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D592"
      },
      {
        denominazioneInItaliano: "Fossacesia",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D738"
      },
      {
        denominazioneInItaliano: "Fraine",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D757"
      },
      {
        denominazioneInItaliano: "Francavilla al Mare",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D763"
      },
      {
        denominazioneInItaliano: "Fresagrandinaria",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D796"
      },
      {
        denominazioneInItaliano: "Frisa",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D803"
      },
      {
        denominazioneInItaliano: "Furci",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D823"
      },
      {
        denominazioneInItaliano: "Gamberale",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D898"
      },
      {
        denominazioneInItaliano: "Gessopalena",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D996"
      },
      {
        denominazioneInItaliano: "Gissi",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E052"
      },
      {
        denominazioneInItaliano: "Giuliano Teatino",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E056"
      },
      {
        denominazioneInItaliano: "Guardiagrele",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E243"
      },
      {
        denominazioneInItaliano: "Guilmi",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E266"
      },
      {
        denominazioneInItaliano: "Lama dei Peligni",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E424"
      },
      {
        denominazioneInItaliano: "Lanciano",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E435"
      },
      {
        denominazioneInItaliano: "Lentella",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E531"
      },
      {
        denominazioneInItaliano: "Lettopalena",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E559"
      },
      {
        denominazioneInItaliano: "Liscia",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E611"
      },
      {
        denominazioneInItaliano: "Miglianico",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "F196"
      },
      {
        denominazioneInItaliano: "Montazzoli",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "F433"
      },
      {
        denominazioneInItaliano: "Monteferrante",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "F498"
      },
      {
        denominazioneInItaliano: "Montelapiano",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "F535"
      },
      {
        denominazioneInItaliano: "Montenerodomo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "F578"
      },
      {
        denominazioneInItaliano: "Monteodorisio",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "F582"
      },
      {
        denominazioneInItaliano: "Mozzagrogna",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "F785"
      },
      {
        denominazioneInItaliano: "Orsogna",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G128"
      },
      {
        denominazioneInItaliano: "Ortona",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G141"
      },
      {
        denominazioneInItaliano: "Paglieta",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G237"
      },
      {
        denominazioneInItaliano: "Palena",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G271"
      },
      {
        denominazioneInItaliano: "Palmoli",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G290"
      },
      {
        denominazioneInItaliano: "Palombaro",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G294"
      },
      {
        denominazioneInItaliano: "Pennadomo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G434"
      },
      {
        denominazioneInItaliano: "Pennapiedimonte",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G435"
      },
      {
        denominazioneInItaliano: "Perano",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G441"
      },
      {
        denominazioneInItaliano: "Pizzoferrato",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G724"
      },
      {
        denominazioneInItaliano: "Poggiofiorito",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G760"
      },
      {
        denominazioneInItaliano: "Pollutri",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G799"
      },
      {
        denominazioneInItaliano: "Pretoro",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H052"
      },
      {
        denominazioneInItaliano: "Quadri",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H098"
      },
      {
        denominazioneInItaliano: "Rapino",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H184"
      },
      {
        denominazioneInItaliano: "Ripa Teatina",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H320"
      },
      {
        denominazioneInItaliano: "Roccamontepiano",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H424"
      },
      {
        denominazioneInItaliano: "Rocca San Giovanni",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H439"
      },
      {
        denominazioneInItaliano: "Roccascalegna",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H442"
      },
      {
        denominazioneInItaliano: "Roccaspinalveti",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H448"
      },
      {
        denominazioneInItaliano: "Roio del Sangro",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H495"
      },
      {
        denominazioneInItaliano: "Rosello",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H566"
      },
      {
        denominazioneInItaliano: "San Buono",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H784"
      },
      {
        denominazioneInItaliano: "San Giovanni Lipioni",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H923"
      },
      {
        denominazioneInItaliano: "San Giovanni Teatino",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D690"
      },
      {
        denominazioneInItaliano: "San Martino sulla Marrucina",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "H991"
      },
      {
        denominazioneInItaliano: "San Salvo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "I148"
      },
      {
        denominazioneInItaliano: "Santa Maria Imbaro",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "I244"
      },
      {
        denominazioneInItaliano: "Sant'Eusanio del Sangro",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "I335"
      },
      {
        denominazioneInItaliano: "San Vito Chietino",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "I394"
      },
      {
        denominazioneInItaliano: "Scerni",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "I520"
      },
      {
        denominazioneInItaliano: "Schiavi di Abruzzo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "I526"
      },
      {
        denominazioneInItaliano: "Taranta Peligna",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L047"
      },
      {
        denominazioneInItaliano: "Tollo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L194"
      },
      {
        denominazioneInItaliano: "Torino di Sangro",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L218"
      },
      {
        denominazioneInItaliano: "Tornareccio",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L224"
      },
      {
        denominazioneInItaliano: "Torrebruna",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L253"
      },
      {
        denominazioneInItaliano: "Torrevecchia Teatina",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L284"
      },
      {
        denominazioneInItaliano: "Torricella Peligna",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L291"
      },
      {
        denominazioneInItaliano: "Treglio",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L363"
      },
      {
        denominazioneInItaliano: "Tufillo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L459"
      },
      {
        denominazioneInItaliano: "Vacri",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L526"
      },
      {
        denominazioneInItaliano: "Vasto",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "E372"
      },
      {
        denominazioneInItaliano: "Villalfonsina",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L961"
      },
      {
        denominazioneInItaliano: "Villamagna",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "L964"
      },
      {
        denominazioneInItaliano: "Villa Santa Maria",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "M022"
      },
      {
        denominazioneInItaliano: "Pietraferrazzana",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "G613"
      },
      {
        denominazioneInItaliano: "Fallo",
        regione: "Abruzzo",
        provincia: "Chieti",
        codiceCatastale: "D480"
      },
      {
        denominazioneInItaliano: "Acquaviva Collecroce",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "A050"
      },
      {
        denominazioneInItaliano: "Baranello",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "A616"
      },
      {
        denominazioneInItaliano: "Bojano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "A930"
      },
      {
        denominazioneInItaliano: "Bonefro",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "A971"
      },
      {
        denominazioneInItaliano: "Busso",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "B295"
      },
      {
        denominazioneInItaliano: "Campobasso",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "B519"
      },
      {
        denominazioneInItaliano: "Campochiaro",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "B522"
      },
      {
        denominazioneInItaliano: "Campodipietra",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "B528"
      },
      {
        denominazioneInItaliano: "Campolieto",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "B544"
      },
      {
        denominazioneInItaliano: "Campomarino",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "B550"
      },
      {
        denominazioneInItaliano: "Casacalenda",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "B858"
      },
      {
        denominazioneInItaliano: "Casalciprano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "B871"
      },
      {
        denominazioneInItaliano: "Castelbottaccio",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C066"
      },
      {
        denominazioneInItaliano: "Castellino del Biferno",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C175"
      },
      {
        denominazioneInItaliano: "Castelmauro",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C197"
      },
      {
        denominazioneInItaliano: "Castropignano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C346"
      },
      {
        denominazioneInItaliano: "Cercemaggiore",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C486"
      },
      {
        denominazioneInItaliano: "Cercepiccola",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C488"
      },
      {
        denominazioneInItaliano: "Civitacampomarano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C764"
      },
      {
        denominazioneInItaliano: "Colle d'Anchise",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C854"
      },
      {
        denominazioneInItaliano: "Colletorto",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C875"
      },
      {
        denominazioneInItaliano: "Duronia",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "C772"
      },
      {
        denominazioneInItaliano: "Ferrazzano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "D550"
      },
      {
        denominazioneInItaliano: "Fossalto",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "D737"
      },
      {
        denominazioneInItaliano: "Gambatesa",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "D896"
      },
      {
        denominazioneInItaliano: "Gildone",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E030"
      },
      {
        denominazioneInItaliano: "Guardialfiera",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E244"
      },
      {
        denominazioneInItaliano: "Guardiaregia",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E248"
      },
      {
        denominazioneInItaliano: "Guglionesi",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E259"
      },
      {
        denominazioneInItaliano: "Jelsi",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E381"
      },
      {
        denominazioneInItaliano: "Larino",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E456"
      },
      {
        denominazioneInItaliano: "Limosano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E599"
      },
      {
        denominazioneInItaliano: "Lucito",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E722"
      },
      {
        denominazioneInItaliano: "Lupara",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E748"
      },
      {
        denominazioneInItaliano: "Macchia Valfortore",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E780"
      },
      {
        denominazioneInItaliano: "Mafalda",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "E799"
      },
      {
        denominazioneInItaliano: "Matrice",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F055"
      },
      {
        denominazioneInItaliano: "Mirabello Sannitico",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F233"
      },
      {
        denominazioneInItaliano: "Molise",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F294"
      },
      {
        denominazioneInItaliano: "Monacilioni",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F322"
      },
      {
        denominazioneInItaliano: "Montagano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F391"
      },
      {
        denominazioneInItaliano: "Montecilfone",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F475"
      },
      {
        denominazioneInItaliano: "Montefalcone nel Sannio",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F495"
      },
      {
        denominazioneInItaliano: "Montelongo",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F548"
      },
      {
        denominazioneInItaliano: "Montemitro",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F569"
      },
      {
        denominazioneInItaliano: "Montenero di Bisaccia",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F576"
      },
      {
        denominazioneInItaliano: "Montorio nei Frentani",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F689"
      },
      {
        denominazioneInItaliano: "Morrone del Sannio",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "F748"
      },
      {
        denominazioneInItaliano: "Oratino",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "G086"
      },
      {
        denominazioneInItaliano: "Palata",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "G257"
      },
      {
        denominazioneInItaliano: "Petacciato",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "G506"
      },
      {
        denominazioneInItaliano: "Petrella Tifernina",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "G512"
      },
      {
        denominazioneInItaliano: "Pietracatella",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "G609"
      },
      {
        denominazioneInItaliano: "Pietracupa",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "G610"
      },
      {
        denominazioneInItaliano: "Portocannone",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "G910"
      },
      {
        denominazioneInItaliano: "Provvidenti",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H083"
      },
      {
        denominazioneInItaliano: "Riccia",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H273"
      },
      {
        denominazioneInItaliano: "Ripabottoni",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H311"
      },
      {
        denominazioneInItaliano: "Ripalimosani",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H313"
      },
      {
        denominazioneInItaliano: "Roccavivara",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H454"
      },
      {
        denominazioneInItaliano: "Rotello",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H589"
      },
      {
        denominazioneInItaliano: "Salcito",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H693"
      },
      {
        denominazioneInItaliano: "San Biase",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H782"
      },
      {
        denominazioneInItaliano: "San Felice del Molise",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H833"
      },
      {
        denominazioneInItaliano: "San Giacomo degli Schiavoni",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H867"
      },
      {
        denominazioneInItaliano: "San Giovanni in Galdo",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H920"
      },
      {
        denominazioneInItaliano: "San Giuliano del Sannio",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H928"
      },
      {
        denominazioneInItaliano: "San Giuliano di Puglia",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H929"
      },
      {
        denominazioneInItaliano: "San Martino in Pensilis",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "H990"
      },
      {
        denominazioneInItaliano: "San Massimo",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "I023"
      },
      {
        denominazioneInItaliano: "San Polo Matese",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "I122"
      },
      {
        denominazioneInItaliano: "Santa Croce di Magliano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "I181"
      },
      {
        denominazioneInItaliano: "Sant'Angelo Limosano",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "I289"
      },
      {
        denominazioneInItaliano: "Sant'Elia a Pianisi",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "I320"
      },
      {
        denominazioneInItaliano: "Sepino",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "I618"
      },
      {
        denominazioneInItaliano: "Spinete",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "I910"
      },
      {
        denominazioneInItaliano: "Tavenna",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "L069"
      },
      {
        denominazioneInItaliano: "Termoli",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "L113"
      },
      {
        denominazioneInItaliano: "Torella del Sannio",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "L215"
      },
      {
        denominazioneInItaliano: "Toro",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "L230"
      },
      {
        denominazioneInItaliano: "Trivento",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "L435"
      },
      {
        denominazioneInItaliano: "Tufara",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "L458"
      },
      {
        denominazioneInItaliano: "Ururi",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "L505"
      },
      {
        denominazioneInItaliano: "Vinchiaturo",
        regione: "Molise",
        provincia: "Campobasso",
        codiceCatastale: "M057"
      },
      {
        denominazioneInItaliano: "Acquaviva d'Isernia",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "A051"
      },
      {
        denominazioneInItaliano: "Agnone",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "A080"
      },
      {
        denominazioneInItaliano: "Bagnoli del Trigno",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "A567"
      },
      {
        denominazioneInItaliano: "Belmonte del Sannio",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "A761"
      },
      {
        denominazioneInItaliano: "Cantalupo nel Sannio",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "B630"
      },
      {
        denominazioneInItaliano: "Capracotta",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "B682"
      },
      {
        denominazioneInItaliano: "Carovilli",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "B810"
      },
      {
        denominazioneInItaliano: "Carpinone",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "B830"
      },
      {
        denominazioneInItaliano: "Castel del Giudice",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C082"
      },
      {
        denominazioneInItaliano: "Castelpetroso",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C246"
      },
      {
        denominazioneInItaliano: "Castelpizzuto",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C247"
      },
      {
        denominazioneInItaliano: "Castel San Vincenzo",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C270"
      },
      {
        denominazioneInItaliano: "Castelverrino",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C200"
      },
      {
        denominazioneInItaliano: "Cerro al Volturno",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C534"
      },
      {
        denominazioneInItaliano: "Chiauci",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C620"
      },
      {
        denominazioneInItaliano: "Civitanova del Sannio",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C769"
      },
      {
        denominazioneInItaliano: "Colli a Volturno",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C878"
      },
      {
        denominazioneInItaliano: "Conca Casale",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "C941"
      },
      {
        denominazioneInItaliano: "Filignano",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "D595"
      },
      {
        denominazioneInItaliano: "Forlì del Sannio",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "D703"
      },
      {
        denominazioneInItaliano: "Fornelli",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "D715"
      },
      {
        denominazioneInItaliano: "Frosolone",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "D811"
      },
      {
        denominazioneInItaliano: "Isernia",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "E335"
      },
      {
        denominazioneInItaliano: "Longano",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "E669"
      },
      {
        denominazioneInItaliano: "Macchia d'Isernia",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "E778"
      },
      {
        denominazioneInItaliano: "Macchiagodena",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "E779"
      },
      {
        denominazioneInItaliano: "Miranda",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "F239"
      },
      {
        denominazioneInItaliano: "Montaquila",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "F429"
      },
      {
        denominazioneInItaliano: "Montenero Val Cocchiara",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "F580"
      },
      {
        denominazioneInItaliano: "Monteroduni",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "F601"
      },
      {
        denominazioneInItaliano: "Pesche",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "G486"
      },
      {
        denominazioneInItaliano: "Pescolanciano",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "G495"
      },
      {
        denominazioneInItaliano: "Pescopennataro",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "G497"
      },
      {
        denominazioneInItaliano: "Pettoranello del Molise",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "G523"
      },
      {
        denominazioneInItaliano: "Pietrabbondante",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "G606"
      },
      {
        denominazioneInItaliano: "Pizzone",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "G727"
      },
      {
        denominazioneInItaliano: "Poggio Sannita",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "B317"
      },
      {
        denominazioneInItaliano: "Pozzilli",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "G954"
      },
      {
        denominazioneInItaliano: "Rionero Sannitico",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "H308"
      },
      {
        denominazioneInItaliano: "Roccamandolfi",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "H420"
      },
      {
        denominazioneInItaliano: "Roccasicura",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "H445"
      },
      {
        denominazioneInItaliano: "Rocchetta a Volturno",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "H458"
      },
      {
        denominazioneInItaliano: "San Pietro Avellana",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "I096"
      },
      {
        denominazioneInItaliano: "Sant'Agapito",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "I189"
      },
      {
        denominazioneInItaliano: "Santa Maria del Molise",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "I238"
      },
      {
        denominazioneInItaliano: "Sant'Angelo del Pesco",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "I282"
      },
      {
        denominazioneInItaliano: "Sant'Elena Sannita",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "B466"
      },
      {
        denominazioneInItaliano: "Scapoli",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "I507"
      },
      {
        denominazioneInItaliano: "Sessano del Molise",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "I679"
      },
      {
        denominazioneInItaliano: "Sesto Campano",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "I682"
      },
      {
        denominazioneInItaliano: "Vastogirardi",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "L696"
      },
      {
        denominazioneInItaliano: "Venafro",
        regione: "Molise",
        provincia: "Isernia",
        codiceCatastale: "L725"
      },
      {
        denominazioneInItaliano: "Ailano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "A106"
      },
      {
        denominazioneInItaliano: "Alife",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "A200"
      },
      {
        denominazioneInItaliano: "Alvignano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "A243"
      },
      {
        denominazioneInItaliano: "Arienzo",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "A403"
      },
      {
        denominazioneInItaliano: "Aversa",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "A512"
      },
      {
        denominazioneInItaliano: "Baia e Latina",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "A579"
      },
      {
        denominazioneInItaliano: "Bellona",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "A755"
      },
      {
        denominazioneInItaliano: "Caianello",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B361"
      },
      {
        denominazioneInItaliano: "Caiazzo",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B362"
      },
      {
        denominazioneInItaliano: "Calvi Risorta",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B445"
      },
      {
        denominazioneInItaliano: "Camigliano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B477"
      },
      {
        denominazioneInItaliano: "Cancello ed Arnone",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B581"
      },
      {
        denominazioneInItaliano: "Capodrise",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B667"
      },
      {
        denominazioneInItaliano: "Capriati a Volturno",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B704"
      },
      {
        denominazioneInItaliano: "Capua",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B715"
      },
      {
        denominazioneInItaliano: "Carinaro",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B779"
      },
      {
        denominazioneInItaliano: "Carinola",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B781"
      },
      {
        denominazioneInItaliano: "Casagiove",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B860"
      },
      {
        denominazioneInItaliano: "Casal di Principe",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B872"
      },
      {
        denominazioneInItaliano: "Casaluce",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B916"
      },
      {
        denominazioneInItaliano: "Casapulla",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B935"
      },
      {
        denominazioneInItaliano: "Caserta",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B963"
      },
      {
        denominazioneInItaliano: "Castel Campagnano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "B494"
      },
      {
        denominazioneInItaliano: "Castel di Sasso",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "C097"
      },
      {
        denominazioneInItaliano: "Castello del Matese",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "C178"
      },
      {
        denominazioneInItaliano: "Castel Morrone",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "C211"
      },
      {
        denominazioneInItaliano: "Castel Volturno",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "C291"
      },
      {
        denominazioneInItaliano: "Cervino",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "C558"
      },
      {
        denominazioneInItaliano: "Cesa",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "C561"
      },
      {
        denominazioneInItaliano: "Ciorlano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "C716"
      },
      {
        denominazioneInItaliano: "Conca della Campania",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "C939"
      },
      {
        denominazioneInItaliano: "Curti",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D228"
      },
      {
        denominazioneInItaliano: "Dragoni",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D361"
      },
      {
        denominazioneInItaliano: "Fontegreca",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D683"
      },
      {
        denominazioneInItaliano: "Formicola",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D709"
      },
      {
        denominazioneInItaliano: "Francolise",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D769"
      },
      {
        denominazioneInItaliano: "Frignano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D799"
      },
      {
        denominazioneInItaliano: "Gallo Matese",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D884"
      },
      {
        denominazioneInItaliano: "Galluccio",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D886"
      },
      {
        denominazioneInItaliano: "Giano Vetusto",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E011"
      },
      {
        denominazioneInItaliano: "Gioia Sannitica",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E039"
      },
      {
        denominazioneInItaliano: "Grazzanise",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E158"
      },
      {
        denominazioneInItaliano: "Gricignano di Aversa",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E173"
      },
      {
        denominazioneInItaliano: "Letino",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E554"
      },
      {
        denominazioneInItaliano: "Liberi",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E570"
      },
      {
        denominazioneInItaliano: "Lusciano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E754"
      },
      {
        denominazioneInItaliano: "Macerata Campania",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E784"
      },
      {
        denominazioneInItaliano: "Maddaloni",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E791"
      },
      {
        denominazioneInItaliano: "Marcianise",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E932"
      },
      {
        denominazioneInItaliano: "Marzano Appio",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "E998"
      },
      {
        denominazioneInItaliano: "Mignano Monte Lungo",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "F203"
      },
      {
        denominazioneInItaliano: "Mondragone",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "F352"
      },
      {
        denominazioneInItaliano: "Orta di Atella",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G130"
      },
      {
        denominazioneInItaliano: "Parete",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G333"
      },
      {
        denominazioneInItaliano: "Pastorano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G364"
      },
      {
        denominazioneInItaliano: "Piana di Monte Verna",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G541"
      },
      {
        denominazioneInItaliano: "Piedimonte Matese",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G596"
      },
      {
        denominazioneInItaliano: "Pietramelara",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G620"
      },
      {
        denominazioneInItaliano: "Pietravairano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G630"
      },
      {
        denominazioneInItaliano: "Pignataro Maggiore",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G661"
      },
      {
        denominazioneInItaliano: "Pontelatone",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G849"
      },
      {
        denominazioneInItaliano: "Portico di Caserta",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G903"
      },
      {
        denominazioneInItaliano: "Prata Sannita",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G991"
      },
      {
        denominazioneInItaliano: "Pratella",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "G995"
      },
      {
        denominazioneInItaliano: "Presenzano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H045"
      },
      {
        denominazioneInItaliano: "Raviscanina",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H202"
      },
      {
        denominazioneInItaliano: "Recale",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H210"
      },
      {
        denominazioneInItaliano: "Riardo",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H268"
      },
      {
        denominazioneInItaliano: "Rocca d'Evandro",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H398"
      },
      {
        denominazioneInItaliano: "Roccamonfina",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H423"
      },
      {
        denominazioneInItaliano: "Roccaromana",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H436"
      },
      {
        denominazioneInItaliano: "Rocchetta e Croce",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H459"
      },
      {
        denominazioneInItaliano: "Ruviano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H165"
      },
      {
        denominazioneInItaliano: "San Cipriano d'Aversa",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H798"
      },
      {
        denominazioneInItaliano: "San Felice a Cancello",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H834"
      },
      {
        denominazioneInItaliano: "San Gregorio Matese",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H939"
      },
      {
        denominazioneInItaliano: "San Marcellino",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "H978"
      },
      {
        denominazioneInItaliano: "San Nicola la Strada",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I056"
      },
      {
        denominazioneInItaliano: "San Pietro Infine",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I113"
      },
      {
        denominazioneInItaliano: "San Potito Sannitico",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I130"
      },
      {
        denominazioneInItaliano: "San Prisco",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I131"
      },
      {
        denominazioneInItaliano: "Santa Maria a Vico",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I233"
      },
      {
        denominazioneInItaliano: "Santa Maria Capua Vetere",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I234"
      },
      {
        denominazioneInItaliano: "Santa Maria la Fossa",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I247"
      },
      {
        denominazioneInItaliano: "San Tammaro",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I261"
      },
      {
        denominazioneInItaliano: "Sant'Angelo d'Alife",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I273"
      },
      {
        denominazioneInItaliano: "Sant'Arpino",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I306"
      },
      {
        denominazioneInItaliano: "Sessa Aurunca",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I676"
      },
      {
        denominazioneInItaliano: "Sparanise",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I885"
      },
      {
        denominazioneInItaliano: "Succivo",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "I993"
      },
      {
        denominazioneInItaliano: "Teano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "L083"
      },
      {
        denominazioneInItaliano: "Teverola",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "L155"
      },
      {
        denominazioneInItaliano: "Tora e Piccilli",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "L205"
      },
      {
        denominazioneInItaliano: "Trentola Ducenta",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "L379"
      },
      {
        denominazioneInItaliano: "Vairano Patenora",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "L540"
      },
      {
        denominazioneInItaliano: "Valle Agricola",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "L594"
      },
      {
        denominazioneInItaliano: "Valle di Maddaloni",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "L591"
      },
      {
        denominazioneInItaliano: "Villa di Briano",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D801"
      },
      {
        denominazioneInItaliano: "Villa Literno",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "L844"
      },
      {
        denominazioneInItaliano: "Vitulazio",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "M092"
      },
      {
        denominazioneInItaliano: "Falciano del Massico",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "D471"
      },
      {
        denominazioneInItaliano: "Cellole",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "M262"
      },
      {
        denominazioneInItaliano: "Casapesenna",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "M260"
      },
      {
        denominazioneInItaliano: "San Marco Evangelista",
        regione: "Campania",
        provincia: "Caserta",
        codiceCatastale: "F043"
      },
      {
        denominazioneInItaliano: "Airola",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A110"
      },
      {
        denominazioneInItaliano: "Amorosi",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A265"
      },
      {
        denominazioneInItaliano: "Apice",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A328"
      },
      {
        denominazioneInItaliano: "Apollosa",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A330"
      },
      {
        denominazioneInItaliano: "Arpaia",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A431"
      },
      {
        denominazioneInItaliano: "Arpaise",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A432"
      },
      {
        denominazioneInItaliano: "Baselice",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A696"
      },
      {
        denominazioneInItaliano: "Benevento",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A783"
      },
      {
        denominazioneInItaliano: "Bonea",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "A970"
      },
      {
        denominazioneInItaliano: "Bucciano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "B239"
      },
      {
        denominazioneInItaliano: "Buonalbergo",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "B267"
      },
      {
        denominazioneInItaliano: "Calvi",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "B444"
      },
      {
        denominazioneInItaliano: "Campolattaro",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "B541"
      },
      {
        denominazioneInItaliano: "Campoli del Monte Taburno",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "B542"
      },
      {
        denominazioneInItaliano: "Casalduni",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "B873"
      },
      {
        denominazioneInItaliano: "Castelfranco in Miscano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C106"
      },
      {
        denominazioneInItaliano: "Castelpagano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C245"
      },
      {
        denominazioneInItaliano: "Castelpoto",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C250"
      },
      {
        denominazioneInItaliano: "Castelvenere",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C280"
      },
      {
        denominazioneInItaliano: "Castelvetere in Val Fortore",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C284"
      },
      {
        denominazioneInItaliano: "Cautano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C359"
      },
      {
        denominazioneInItaliano: "Ceppaloni",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C476"
      },
      {
        denominazioneInItaliano: "Cerreto Sannita",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C525"
      },
      {
        denominazioneInItaliano: "Circello",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C719"
      },
      {
        denominazioneInItaliano: "Colle Sannita",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "C846"
      },
      {
        denominazioneInItaliano: "Cusano Mutri",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D230"
      },
      {
        denominazioneInItaliano: "Dugenta",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D380"
      },
      {
        denominazioneInItaliano: "Durazzano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D386"
      },
      {
        denominazioneInItaliano: "Faicchio",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D469"
      },
      {
        denominazioneInItaliano: "Foglianise",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D644"
      },
      {
        denominazioneInItaliano: "Foiano di Val Fortore",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D650"
      },
      {
        denominazioneInItaliano: "Forchia",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D693"
      },
      {
        denominazioneInItaliano: "Fragneto l'Abate",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D755"
      },
      {
        denominazioneInItaliano: "Fragneto Monforte",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D756"
      },
      {
        denominazioneInItaliano: "Frasso Telesino",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "D784"
      },
      {
        denominazioneInItaliano: "Ginestra degli Schiavoni",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "E034"
      },
      {
        denominazioneInItaliano: "Guardia Sanframondi",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "E249"
      },
      {
        denominazioneInItaliano: "Limatola",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "E589"
      },
      {
        denominazioneInItaliano: "Melizzano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "F113"
      },
      {
        denominazioneInItaliano: "Moiano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "F274"
      },
      {
        denominazioneInItaliano: "Molinara",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "F287"
      },
      {
        denominazioneInItaliano: "Montefalcone di Val Fortore",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "F494"
      },
      {
        denominazioneInItaliano: "Montesarchio",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "F636"
      },
      {
        denominazioneInItaliano: "Morcone",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "F717"
      },
      {
        denominazioneInItaliano: "Paduli",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G227"
      },
      {
        denominazioneInItaliano: "Pago Veiano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G243"
      },
      {
        denominazioneInItaliano: "Pannarano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G311"
      },
      {
        denominazioneInItaliano: "Paolisi",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G318"
      },
      {
        denominazioneInItaliano: "Paupisi",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G386"
      },
      {
        denominazioneInItaliano: "Pesco Sannita",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G494"
      },
      {
        denominazioneInItaliano: "Pietraroja",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G626"
      },
      {
        denominazioneInItaliano: "Pietrelcina",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G631"
      },
      {
        denominazioneInItaliano: "Ponte",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G827"
      },
      {
        denominazioneInItaliano: "Pontelandolfo",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "G848"
      },
      {
        denominazioneInItaliano: "Puglianello",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H087"
      },
      {
        denominazioneInItaliano: "Reino",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H227"
      },
      {
        denominazioneInItaliano: "San Bartolomeo in Galdo",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H764"
      },
      {
        denominazioneInItaliano: "San Giorgio del Sannio",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H894"
      },
      {
        denominazioneInItaliano: "San Giorgio La Molara",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H898"
      },
      {
        denominazioneInItaliano: "San Leucio del Sannio",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H953"
      },
      {
        denominazioneInItaliano: "San Lorenzello",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H955"
      },
      {
        denominazioneInItaliano: "San Lorenzo Maggiore",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H967"
      },
      {
        denominazioneInItaliano: "San Lupo",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H973"
      },
      {
        denominazioneInItaliano: "San Marco dei Cavoti",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "H984"
      },
      {
        denominazioneInItaliano: "San Martino Sannita",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I002"
      },
      {
        denominazioneInItaliano: "San Nazzaro",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I049"
      },
      {
        denominazioneInItaliano: "San Nicola Manfredi",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I062"
      },
      {
        denominazioneInItaliano: "San Salvatore Telesino",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I145"
      },
      {
        denominazioneInItaliano: "Santa Croce del Sannio",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I179"
      },
      {
        denominazioneInItaliano: "Sant'Agata de' Goti",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I197"
      },
      {
        denominazioneInItaliano: "Sant'Angelo a Cupolo",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I277"
      },
      {
        denominazioneInItaliano: "Sassinoro",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I455"
      },
      {
        denominazioneInItaliano: "Solopaca",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "I809"
      },
      {
        denominazioneInItaliano: "Telese Terme",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "L086"
      },
      {
        denominazioneInItaliano: "Tocco Caudio",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "L185"
      },
      {
        denominazioneInItaliano: "Torrecuso",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "L254"
      },
      {
        denominazioneInItaliano: "Vitulano",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "M093"
      },
      {
        denominazioneInItaliano: "Sant'Arcangelo Trimonte",
        regione: "Campania",
        provincia: "Benevento",
        codiceCatastale: "F557"
      },
      {
        denominazioneInItaliano: "Acerra",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "A024"
      },
      {
        denominazioneInItaliano: "Afragola",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "A064"
      },
      {
        denominazioneInItaliano: "Agerola",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "A068"
      },
      {
        denominazioneInItaliano: "Anacapri",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "A268"
      },
      {
        denominazioneInItaliano: "Arzano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "A455"
      },
      {
        denominazioneInItaliano: "Bacoli",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "A535"
      },
      {
        denominazioneInItaliano: "Barano d'Ischia",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "A617"
      },
      {
        denominazioneInItaliano: "Boscoreale",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B076"
      },
      {
        denominazioneInItaliano: "Boscotrecase",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B077"
      },
      {
        denominazioneInItaliano: "Brusciano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B227"
      },
      {
        denominazioneInItaliano: "Caivano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B371"
      },
      {
        denominazioneInItaliano: "Calvizzano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B452"
      },
      {
        denominazioneInItaliano: "Camposano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B565"
      },
      {
        denominazioneInItaliano: "Capri",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B696"
      },
      {
        denominazioneInItaliano: "Carbonara di Nola",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B740"
      },
      {
        denominazioneInItaliano: "Cardito",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B759"
      },
      {
        denominazioneInItaliano: "Casalnuovo di Napoli",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B905"
      },
      {
        denominazioneInItaliano: "Casamarciano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B922"
      },
      {
        denominazioneInItaliano: "Casamicciola Terme",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B924"
      },
      {
        denominazioneInItaliano: "Casandrino",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B925"
      },
      {
        denominazioneInItaliano: "Casavatore",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B946"
      },
      {
        denominazioneInItaliano: "Casola di Napoli",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B980"
      },
      {
        denominazioneInItaliano: "Casoria",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "B990"
      },
      {
        denominazioneInItaliano: "Castellammare di Stabia",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "C129"
      },
      {
        denominazioneInItaliano: "Castello di Cisterna",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "C188"
      },
      {
        denominazioneInItaliano: "Cercola",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "C495"
      },
      {
        denominazioneInItaliano: "Cicciano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "C675"
      },
      {
        denominazioneInItaliano: "Cimitile",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "C697"
      },
      {
        denominazioneInItaliano: "Comiziano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "C929"
      },
      {
        denominazioneInItaliano: "Crispano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "D170"
      },
      {
        denominazioneInItaliano: "Forio",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "D702"
      },
      {
        denominazioneInItaliano: "Frattamaggiore",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "D789"
      },
      {
        denominazioneInItaliano: "Frattaminore",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "D790"
      },
      {
        denominazioneInItaliano: "Giugliano in Campania",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E054"
      },
      {
        denominazioneInItaliano: "Gragnano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E131"
      },
      {
        denominazioneInItaliano: "Grumo Nevano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E224"
      },
      {
        denominazioneInItaliano: "Ischia",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E329"
      },
      {
        denominazioneInItaliano: "Lacco Ameno",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E396"
      },
      {
        denominazioneInItaliano: "Lettere",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E557"
      },
      {
        denominazioneInItaliano: "Liveri",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E620"
      },
      {
        denominazioneInItaliano: "Marano di Napoli",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E906"
      },
      {
        denominazioneInItaliano: "Mariglianella",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E954"
      },
      {
        denominazioneInItaliano: "Marigliano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "E955"
      },
      {
        denominazioneInItaliano: "Massa Lubrense",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "F030"
      },
      {
        denominazioneInItaliano: "Melito di Napoli",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "F111"
      },
      {
        denominazioneInItaliano: "Meta",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "F162"
      },
      {
        denominazioneInItaliano: "Monte di Procida",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "F488"
      },
      {
        denominazioneInItaliano: "Mugnano di Napoli",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "F799"
      },
      {
        denominazioneInItaliano: "Napoli",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "F839"
      },
      {
        denominazioneInItaliano: "Nola",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "F924"
      },
      {
        denominazioneInItaliano: "Ottaviano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G190"
      },
      {
        denominazioneInItaliano: "Palma Campania",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G283"
      },
      {
        denominazioneInItaliano: "Piano di Sorrento",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G568"
      },
      {
        denominazioneInItaliano: "Pimonte",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G670"
      },
      {
        denominazioneInItaliano: "Poggiomarino",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G762"
      },
      {
        denominazioneInItaliano: "Pollena Trocchia",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G795"
      },
      {
        denominazioneInItaliano: "Pomigliano d'Arco",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G812"
      },
      {
        denominazioneInItaliano: "Pompei",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G813"
      },
      {
        denominazioneInItaliano: "Portici",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G902"
      },
      {
        denominazioneInItaliano: "Pozzuoli",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G964"
      },
      {
        denominazioneInItaliano: "Procida",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "H072"
      },
      {
        denominazioneInItaliano: "Qualiano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "H101"
      },
      {
        denominazioneInItaliano: "Quarto",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "H114"
      },
      {
        denominazioneInItaliano: "Ercolano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "H243"
      },
      {
        denominazioneInItaliano: "Roccarainola",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "H433"
      },
      {
        denominazioneInItaliano: "San Gennaro Vesuviano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "H860"
      },
      {
        denominazioneInItaliano: "San Giorgio a Cremano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "H892"
      },
      {
        denominazioneInItaliano: "San Giuseppe Vesuviano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "H931"
      },
      {
        denominazioneInItaliano: "San Paolo Bel Sito",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I073"
      },
      {
        denominazioneInItaliano: "San Sebastiano al Vesuvio",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I151"
      },
      {
        denominazioneInItaliano: "Sant'Agnello",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I208"
      },
      {
        denominazioneInItaliano: "Sant'Anastasia",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I262"
      },
      {
        denominazioneInItaliano: "Sant'Antimo",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I293"
      },
      {
        denominazioneInItaliano: "Sant'Antonio Abate",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I300"
      },
      {
        denominazioneInItaliano: "San Vitaliano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I391"
      },
      {
        denominazioneInItaliano: "Saviano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I469"
      },
      {
        denominazioneInItaliano: "Scisciano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I540"
      },
      {
        denominazioneInItaliano: "Serrara Fontana",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I652"
      },
      {
        denominazioneInItaliano: "Somma Vesuviana",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I820"
      },
      {
        denominazioneInItaliano: "Sorrento",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I862"
      },
      {
        denominazioneInItaliano: "Striano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "I978"
      },
      {
        denominazioneInItaliano: "Terzigno",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "L142"
      },
      {
        denominazioneInItaliano: "Torre Annunziata",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "L245"
      },
      {
        denominazioneInItaliano: "Torre del Greco",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "L259"
      },
      {
        denominazioneInItaliano: "Tufino",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "L460"
      },
      {
        denominazioneInItaliano: "Vico Equense",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "L845"
      },
      {
        denominazioneInItaliano: "Villaricca",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "G309"
      },
      {
        denominazioneInItaliano: "Visciano",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "M072"
      },
      {
        denominazioneInItaliano: "Volla",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "M115"
      },
      {
        denominazioneInItaliano: "Santa Maria la Carità",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "M273"
      },
      {
        denominazioneInItaliano: "Trecase",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "M280"
      },
      {
        denominazioneInItaliano: "Massa di Somma",
        regione: "Campania",
        provincia: "Napoli",
        codiceCatastale: "M289"
      },
      {
        denominazioneInItaliano: "Aiello del Sabato",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A101"
      },
      {
        denominazioneInItaliano: "Altavilla Irpina",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A228"
      },
      {
        denominazioneInItaliano: "Andretta",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A284"
      },
      {
        denominazioneInItaliano: "Aquilonia",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A347"
      },
      {
        denominazioneInItaliano: "Ariano Irpino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A399"
      },
      {
        denominazioneInItaliano: "Atripalda",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A489"
      },
      {
        denominazioneInItaliano: "Avella",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A508"
      },
      {
        denominazioneInItaliano: "Avellino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A509"
      },
      {
        denominazioneInItaliano: "Bagnoli Irpino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A566"
      },
      {
        denominazioneInItaliano: "Baiano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A580"
      },
      {
        denominazioneInItaliano: "Bisaccia",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A881"
      },
      {
        denominazioneInItaliano: "Bonito",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "A975"
      },
      {
        denominazioneInItaliano: "Cairano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B367"
      },
      {
        denominazioneInItaliano: "Calabritto",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B374"
      },
      {
        denominazioneInItaliano: "Calitri",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B415"
      },
      {
        denominazioneInItaliano: "Candida",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B590"
      },
      {
        denominazioneInItaliano: "Caposele",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B674"
      },
      {
        denominazioneInItaliano: "Capriglia Irpina",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B706"
      },
      {
        denominazioneInItaliano: "Carife",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B776"
      },
      {
        denominazioneInItaliano: "Casalbore",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B866"
      },
      {
        denominazioneInItaliano: "Cassano Irpino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "B997"
      },
      {
        denominazioneInItaliano: "Castel Baronia",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C058"
      },
      {
        denominazioneInItaliano: "Castelfranci",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C105"
      },
      {
        denominazioneInItaliano: "Castelvetere sul Calore",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C283"
      },
      {
        denominazioneInItaliano: "Cervinara",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C557"
      },
      {
        denominazioneInItaliano: "Cesinali",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C576"
      },
      {
        denominazioneInItaliano: "Chianche",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C606"
      },
      {
        denominazioneInItaliano: "Chiusano di San Domenico",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C659"
      },
      {
        denominazioneInItaliano: "Contrada",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C971"
      },
      {
        denominazioneInItaliano: "Conza della Campania",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "C976"
      },
      {
        denominazioneInItaliano: "Domicella",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "D331"
      },
      {
        denominazioneInItaliano: "Flumeri",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "D638"
      },
      {
        denominazioneInItaliano: "Fontanarosa",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "D671"
      },
      {
        denominazioneInItaliano: "Forino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "D701"
      },
      {
        denominazioneInItaliano: "Frigento",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "D798"
      },
      {
        denominazioneInItaliano: "Gesualdo",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "D998"
      },
      {
        denominazioneInItaliano: "Greci",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E161"
      },
      {
        denominazioneInItaliano: "Grottaminarda",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E206"
      },
      {
        denominazioneInItaliano: "Grottolella",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E214"
      },
      {
        denominazioneInItaliano: "Guardia Lombardi",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E245"
      },
      {
        denominazioneInItaliano: "Lacedonia",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E397"
      },
      {
        denominazioneInItaliano: "Lapio",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E448"
      },
      {
        denominazioneInItaliano: "Lauro",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E487"
      },
      {
        denominazioneInItaliano: "Lioni",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E605"
      },
      {
        denominazioneInItaliano: "Luogosano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E746"
      },
      {
        denominazioneInItaliano: "Manocalzati",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E891"
      },
      {
        denominazioneInItaliano: "Marzano di Nola",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "E997"
      },
      {
        denominazioneInItaliano: "Melito Irpino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F110"
      },
      {
        denominazioneInItaliano: "Mercogliano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F141"
      },
      {
        denominazioneInItaliano: "Mirabella Eclano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F230"
      },
      {
        denominazioneInItaliano: "Montaguto",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F397"
      },
      {
        denominazioneInItaliano: "Montecalvo Irpino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F448"
      },
      {
        denominazioneInItaliano: "Montefalcione",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F491"
      },
      {
        denominazioneInItaliano: "Monteforte Irpino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F506"
      },
      {
        denominazioneInItaliano: "Montefredane",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F511"
      },
      {
        denominazioneInItaliano: "Montefusco",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F512"
      },
      {
        denominazioneInItaliano: "Montella",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F546"
      },
      {
        denominazioneInItaliano: "Montemarano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F559"
      },
      {
        denominazioneInItaliano: "Montemiletto",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F566"
      },
      {
        denominazioneInItaliano: "Monteverde",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F660"
      },
      {
        denominazioneInItaliano: "Morra De Sanctis",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F744"
      },
      {
        denominazioneInItaliano: "Moschiano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F762"
      },
      {
        denominazioneInItaliano: "Mugnano del Cardinale",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F798"
      },
      {
        denominazioneInItaliano: "Nusco",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "F988"
      },
      {
        denominazioneInItaliano: "Ospedaletto d'Alpinolo",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "G165"
      },
      {
        denominazioneInItaliano: "Pago del Vallo di Lauro",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "G242"
      },
      {
        denominazioneInItaliano: "Parolise",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "G340"
      },
      {
        denominazioneInItaliano: "Paternopoli",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "G370"
      },
      {
        denominazioneInItaliano: "Petruro Irpino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "G519"
      },
      {
        denominazioneInItaliano: "Pietradefusi",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "G611"
      },
      {
        denominazioneInItaliano: "Pietrastornina",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "G629"
      },
      {
        denominazioneInItaliano: "Prata di Principato Ultra",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "G990"
      },
      {
        denominazioneInItaliano: "Pratola Serra",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "H006"
      },
      {
        denominazioneInItaliano: "Quadrelle",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "H097"
      },
      {
        denominazioneInItaliano: "Quindici",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "H128"
      },
      {
        denominazioneInItaliano: "Roccabascerana",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "H382"
      },
      {
        denominazioneInItaliano: "Rocca San Felice",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "H438"
      },
      {
        denominazioneInItaliano: "Rotondi",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "H592"
      },
      {
        denominazioneInItaliano: "Salza Irpina",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "H733"
      },
      {
        denominazioneInItaliano: "San Mango sul Calore",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "H975"
      },
      {
        denominazioneInItaliano: "San Martino Valle Caudina",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I016"
      },
      {
        denominazioneInItaliano: "San Michele di Serino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I034"
      },
      {
        denominazioneInItaliano: "San Nicola Baronia",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I061"
      },
      {
        denominazioneInItaliano: "San Potito Ultra",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I129"
      },
      {
        denominazioneInItaliano: "San Sossio Baronia",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I163"
      },
      {
        denominazioneInItaliano: "Santa Lucia di Serino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I219"
      },
      {
        denominazioneInItaliano: "Sant'Andrea di Conza",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I264"
      },
      {
        denominazioneInItaliano: "Sant'Angelo all'Esca",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I279"
      },
      {
        denominazioneInItaliano: "Sant'Angelo a Scala",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I280"
      },
      {
        denominazioneInItaliano: "Sant'Angelo dei Lombardi",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I281"
      },
      {
        denominazioneInItaliano: "Santa Paolina",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I301"
      },
      {
        denominazioneInItaliano: "Santo Stefano del Sole",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I357"
      },
      {
        denominazioneInItaliano: "Savignano Irpino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I471"
      },
      {
        denominazioneInItaliano: "Scampitella",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I493"
      },
      {
        denominazioneInItaliano: "Senerchia",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I606"
      },
      {
        denominazioneInItaliano: "Serino",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I630"
      },
      {
        denominazioneInItaliano: "Sirignano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I756"
      },
      {
        denominazioneInItaliano: "Solofra",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I805"
      },
      {
        denominazioneInItaliano: "Sorbo Serpico",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I843"
      },
      {
        denominazioneInItaliano: "Sperone",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I893"
      },
      {
        denominazioneInItaliano: "Sturno",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "I990"
      },
      {
        denominazioneInItaliano: "Summonte",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L004"
      },
      {
        denominazioneInItaliano: "Taurano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L061"
      },
      {
        denominazioneInItaliano: "Taurasi",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L062"
      },
      {
        denominazioneInItaliano: "Teora",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L102"
      },
      {
        denominazioneInItaliano: "Torella dei Lombardi",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L214"
      },
      {
        denominazioneInItaliano: "Torre Le Nocelle",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L272"
      },
      {
        denominazioneInItaliano: "Torrioni",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L301"
      },
      {
        denominazioneInItaliano: "Trevico",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L399"
      },
      {
        denominazioneInItaliano: "Tufo",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L461"
      },
      {
        denominazioneInItaliano: "Vallata",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L589"
      },
      {
        denominazioneInItaliano: "Vallesaccarda",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L616"
      },
      {
        denominazioneInItaliano: "Venticano",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L739"
      },
      {
        denominazioneInItaliano: "Villamaina",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L965"
      },
      {
        denominazioneInItaliano: "Villanova del Battista",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "L973"
      },
      {
        denominazioneInItaliano: "Volturara Irpina",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "M130"
      },
      {
        denominazioneInItaliano: "Zungoli",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "M203"
      },
      {
        denominazioneInItaliano: "Montoro",
        regione: "Campania",
        provincia: "Avellino",
        codiceCatastale: "M330"
      },
      {
        denominazioneInItaliano: "Acerno",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A023"
      },
      {
        denominazioneInItaliano: "Agropoli",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A091"
      },
      {
        denominazioneInItaliano: "Albanella",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A128"
      },
      {
        denominazioneInItaliano: "Alfano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A186"
      },
      {
        denominazioneInItaliano: "Altavilla Silentina",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A230"
      },
      {
        denominazioneInItaliano: "Amalfi",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A251"
      },
      {
        denominazioneInItaliano: "Angri",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A294"
      },
      {
        denominazioneInItaliano: "Aquara",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A343"
      },
      {
        denominazioneInItaliano: "Ascea",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A460"
      },
      {
        denominazioneInItaliano: "Atena Lucana",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A484"
      },
      {
        denominazioneInItaliano: "Atrani",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A487"
      },
      {
        denominazioneInItaliano: "Auletta",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A495"
      },
      {
        denominazioneInItaliano: "Baronissi",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A674"
      },
      {
        denominazioneInItaliano: "Battipaglia",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A717"
      },
      {
        denominazioneInItaliano: "Bellosguardo",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "A756"
      },
      {
        denominazioneInItaliano: "Bracigliano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B115"
      },
      {
        denominazioneInItaliano: "Buccino",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B242"
      },
      {
        denominazioneInItaliano: "Buonabitacolo",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B266"
      },
      {
        denominazioneInItaliano: "Caggiano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B351"
      },
      {
        denominazioneInItaliano: "Calvanico",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B437"
      },
      {
        denominazioneInItaliano: "Camerota",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B476"
      },
      {
        denominazioneInItaliano: "Campagna",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B492"
      },
      {
        denominazioneInItaliano: "Campora",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B555"
      },
      {
        denominazioneInItaliano: "Cannalonga",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B608"
      },
      {
        denominazioneInItaliano: "Capaccio Paestum",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B644"
      },
      {
        denominazioneInItaliano: "Casalbuono",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B868"
      },
      {
        denominazioneInItaliano: "Casaletto Spartano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B888"
      },
      {
        denominazioneInItaliano: "Casal Velino",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B895"
      },
      {
        denominazioneInItaliano: "Caselle in Pittari",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "B959"
      },
      {
        denominazioneInItaliano: "Castelcivita",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C069"
      },
      {
        denominazioneInItaliano: "Castellabate",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C125"
      },
      {
        denominazioneInItaliano: "Castelnuovo Cilento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C231"
      },
      {
        denominazioneInItaliano: "Castelnuovo di Conza",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C235"
      },
      {
        denominazioneInItaliano: "Castel San Giorgio",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C259"
      },
      {
        denominazioneInItaliano: "Castel San Lorenzo",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C262"
      },
      {
        denominazioneInItaliano: "Castiglione del Genovesi",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C306"
      },
      {
        denominazioneInItaliano: "Cava de' Tirreni",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C361"
      },
      {
        denominazioneInItaliano: "Celle di Bulgheria",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C444"
      },
      {
        denominazioneInItaliano: "Centola",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C470"
      },
      {
        denominazioneInItaliano: "Ceraso",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C485"
      },
      {
        denominazioneInItaliano: "Cetara",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C584"
      },
      {
        denominazioneInItaliano: "Cicerale",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C676"
      },
      {
        denominazioneInItaliano: "Colliano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C879"
      },
      {
        denominazioneInItaliano: "Conca dei Marini",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C940"
      },
      {
        denominazioneInItaliano: "Controne",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C973"
      },
      {
        denominazioneInItaliano: "Contursi Terme",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C974"
      },
      {
        denominazioneInItaliano: "Corbara",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "C984"
      },
      {
        denominazioneInItaliano: "Corleto Monforte",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "D011"
      },
      {
        denominazioneInItaliano: "Cuccaro Vetere",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "D195"
      },
      {
        denominazioneInItaliano: "Eboli",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "D390"
      },
      {
        denominazioneInItaliano: "Felitto",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "D527"
      },
      {
        denominazioneInItaliano: "Fisciano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "D615"
      },
      {
        denominazioneInItaliano: "Furore",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "D826"
      },
      {
        denominazioneInItaliano: "Futani",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "D832"
      },
      {
        denominazioneInItaliano: "Giffoni Sei Casali",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E026"
      },
      {
        denominazioneInItaliano: "Giffoni Valle Piana",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E027"
      },
      {
        denominazioneInItaliano: "Gioi",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E037"
      },
      {
        denominazioneInItaliano: "Giungano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E060"
      },
      {
        denominazioneInItaliano: "Ispani",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E365"
      },
      {
        denominazioneInItaliano: "Laureana Cilento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E480"
      },
      {
        denominazioneInItaliano: "Laurino",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E485"
      },
      {
        denominazioneInItaliano: "Laurito",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E486"
      },
      {
        denominazioneInItaliano: "Laviano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E498"
      },
      {
        denominazioneInItaliano: "Lustra",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E767"
      },
      {
        denominazioneInItaliano: "Magliano Vetere",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E814"
      },
      {
        denominazioneInItaliano: "Maiori",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "E839"
      },
      {
        denominazioneInItaliano: "Mercato San Severino",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F138"
      },
      {
        denominazioneInItaliano: "Minori",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F223"
      },
      {
        denominazioneInItaliano: "Moio della Civitella",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F278"
      },
      {
        denominazioneInItaliano: "Montano Antilia",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F426"
      },
      {
        denominazioneInItaliano: "Montecorice",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F479"
      },
      {
        denominazioneInItaliano: "Montecorvino Pugliano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F480"
      },
      {
        denominazioneInItaliano: "Montecorvino Rovella",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F481"
      },
      {
        denominazioneInItaliano: "Monteforte Cilento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F507"
      },
      {
        denominazioneInItaliano: "Monte San Giacomo",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F618"
      },
      {
        denominazioneInItaliano: "Montesano sulla Marcellana",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F625"
      },
      {
        denominazioneInItaliano: "Morigerati",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F731"
      },
      {
        denominazioneInItaliano: "Nocera Inferiore",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F912"
      },
      {
        denominazioneInItaliano: "Nocera Superiore",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F913"
      },
      {
        denominazioneInItaliano: "Novi Velia",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "F967"
      },
      {
        denominazioneInItaliano: "Ogliastro Cilento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G011"
      },
      {
        denominazioneInItaliano: "Olevano sul Tusciano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G023"
      },
      {
        denominazioneInItaliano: "Oliveto Citra",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G039"
      },
      {
        denominazioneInItaliano: "Omignano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G063"
      },
      {
        denominazioneInItaliano: "Orria",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G121"
      },
      {
        denominazioneInItaliano: "Ottati",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G192"
      },
      {
        denominazioneInItaliano: "Padula",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G226"
      },
      {
        denominazioneInItaliano: "Pagani",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G230"
      },
      {
        denominazioneInItaliano: "Palomonte",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G292"
      },
      {
        denominazioneInItaliano: "Pellezzano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G426"
      },
      {
        denominazioneInItaliano: "Perdifumo",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G447"
      },
      {
        denominazioneInItaliano: "Perito",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G455"
      },
      {
        denominazioneInItaliano: "Pertosa",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G476"
      },
      {
        denominazioneInItaliano: "Petina",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G509"
      },
      {
        denominazioneInItaliano: "Piaggine",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G538"
      },
      {
        denominazioneInItaliano: "Pisciotta",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G707"
      },
      {
        denominazioneInItaliano: "Polla",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G793"
      },
      {
        denominazioneInItaliano: "Pollica",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G796"
      },
      {
        denominazioneInItaliano: "Pontecagnano Faiano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G834"
      },
      {
        denominazioneInItaliano: "Positano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G932"
      },
      {
        denominazioneInItaliano: "Postiglione",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G939"
      },
      {
        denominazioneInItaliano: "Praiano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G976"
      },
      {
        denominazioneInItaliano: "Prignano Cilento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H062"
      },
      {
        denominazioneInItaliano: "Ravello",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H198"
      },
      {
        denominazioneInItaliano: "Ricigliano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H277"
      },
      {
        denominazioneInItaliano: "Roccadaspide",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H394"
      },
      {
        denominazioneInItaliano: "Roccagloriosa",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H412"
      },
      {
        denominazioneInItaliano: "Roccapiemonte",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H431"
      },
      {
        denominazioneInItaliano: "Rofrano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H485"
      },
      {
        denominazioneInItaliano: "Romagnano al Monte",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H503"
      },
      {
        denominazioneInItaliano: "Roscigno",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H564"
      },
      {
        denominazioneInItaliano: "Rutino",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H644"
      },
      {
        denominazioneInItaliano: "Sacco",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H654"
      },
      {
        denominazioneInItaliano: "Sala Consilina",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H683"
      },
      {
        denominazioneInItaliano: "Salento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H686"
      },
      {
        denominazioneInItaliano: "Salerno",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H703"
      },
      {
        denominazioneInItaliano: "Salvitelle",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H732"
      },
      {
        denominazioneInItaliano: "San Cipriano Picentino",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H800"
      },
      {
        denominazioneInItaliano: "San Giovanni a Piro",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H907"
      },
      {
        denominazioneInItaliano: "San Gregorio Magno",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H943"
      },
      {
        denominazioneInItaliano: "San Mango Piemonte",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "H977"
      },
      {
        denominazioneInItaliano: "San Marzano sul Sarno",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I019"
      },
      {
        denominazioneInItaliano: "San Mauro Cilento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I031"
      },
      {
        denominazioneInItaliano: "San Mauro la Bruca",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I032"
      },
      {
        denominazioneInItaliano: "San Pietro al Tanagro",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I089"
      },
      {
        denominazioneInItaliano: "San Rufo",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I143"
      },
      {
        denominazioneInItaliano: "Santa Marina",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I253"
      },
      {
        denominazioneInItaliano: "Sant'Angelo a Fasanella",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I278"
      },
      {
        denominazioneInItaliano: "Sant'Arsenio",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I307"
      },
      {
        denominazioneInItaliano: "Sant'Egidio del Monte Albino",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I317"
      },
      {
        denominazioneInItaliano: "Santomenna",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I260"
      },
      {
        denominazioneInItaliano: "San Valentino Torio",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I377"
      },
      {
        denominazioneInItaliano: "Sanza",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I410"
      },
      {
        denominazioneInItaliano: "Sapri",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I422"
      },
      {
        denominazioneInItaliano: "Sarno",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I438"
      },
      {
        denominazioneInItaliano: "Sassano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I451"
      },
      {
        denominazioneInItaliano: "Scafati",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I483"
      },
      {
        denominazioneInItaliano: "Scala",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I486"
      },
      {
        denominazioneInItaliano: "Serramezzana",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I648"
      },
      {
        denominazioneInItaliano: "Serre",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I666"
      },
      {
        denominazioneInItaliano: "Sessa Cilento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I677"
      },
      {
        denominazioneInItaliano: "Siano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I720"
      },
      {
        denominazioneInItaliano: "Sicignano degli Alburni",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "M253"
      },
      {
        denominazioneInItaliano: "Stella Cilento",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G887"
      },
      {
        denominazioneInItaliano: "Stio",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "I960"
      },
      {
        denominazioneInItaliano: "Teggiano",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "D292"
      },
      {
        denominazioneInItaliano: "Torchiara",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L212"
      },
      {
        denominazioneInItaliano: "Torraca",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L233"
      },
      {
        denominazioneInItaliano: "Torre Orsaia",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L274"
      },
      {
        denominazioneInItaliano: "Tortorella",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L306"
      },
      {
        denominazioneInItaliano: "Tramonti",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L323"
      },
      {
        denominazioneInItaliano: "Trentinara",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L377"
      },
      {
        denominazioneInItaliano: "Valle dell'Angelo",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "G540"
      },
      {
        denominazioneInItaliano: "Vallo della Lucania",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L628"
      },
      {
        denominazioneInItaliano: "Valva",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L656"
      },
      {
        denominazioneInItaliano: "Vibonati",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L835"
      },
      {
        denominazioneInItaliano: "Vietri sul Mare",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "L860"
      },
      {
        denominazioneInItaliano: "Bellizzi",
        regione: "Campania",
        provincia: "Salerno",
        codiceCatastale: "M294"
      },
      {
        denominazioneInItaliano: "Accadia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "A015"
      },
      {
        denominazioneInItaliano: "Alberona",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "A150"
      },
      {
        denominazioneInItaliano: "Anzano di Puglia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "A320"
      },
      {
        denominazioneInItaliano: "Apricena",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "A339"
      },
      {
        denominazioneInItaliano: "Ascoli Satriano",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "A463"
      },
      {
        denominazioneInItaliano: "Biccari",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "A854"
      },
      {
        denominazioneInItaliano: "Bovino",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "B104"
      },
      {
        denominazioneInItaliano: "Cagnano Varano",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "B357"
      },
      {
        denominazioneInItaliano: "Candela",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "B584"
      },
      {
        denominazioneInItaliano: "Carapelle",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "B724"
      },
      {
        denominazioneInItaliano: "Carlantino",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "B784"
      },
      {
        denominazioneInItaliano: "Carpino",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "B829"
      },
      {
        denominazioneInItaliano: "Casalnuovo Monterotaro",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "B904"
      },
      {
        denominazioneInItaliano: "Casalvecchio di Puglia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "B917"
      },
      {
        denominazioneInItaliano: "Castelluccio dei Sauri",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "C198"
      },
      {
        denominazioneInItaliano: "Castelluccio Valmaggiore",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "C202"
      },
      {
        denominazioneInItaliano: "Castelnuovo della Daunia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "C222"
      },
      {
        denominazioneInItaliano: "Celenza Valfortore",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "C429"
      },
      {
        denominazioneInItaliano: "Celle di San Vito",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "C442"
      },
      {
        denominazioneInItaliano: "Cerignola",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "C514"
      },
      {
        denominazioneInItaliano: "Chieuti",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "C633"
      },
      {
        denominazioneInItaliano: "Deliceto",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "D269"
      },
      {
        denominazioneInItaliano: "Faeto",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "D459"
      },
      {
        denominazioneInItaliano: "Foggia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "D643"
      },
      {
        denominazioneInItaliano: "Ischitella",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "E332"
      },
      {
        denominazioneInItaliano: "Isole Tremiti",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "E363"
      },
      {
        denominazioneInItaliano: "Lesina",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "E549"
      },
      {
        denominazioneInItaliano: "Lucera",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "E716"
      },
      {
        denominazioneInItaliano: "Manfredonia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "E885"
      },
      {
        denominazioneInItaliano: "Mattinata",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "F059"
      },
      {
        denominazioneInItaliano: "Monteleone di Puglia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "F538"
      },
      {
        denominazioneInItaliano: "Monte Sant'Angelo",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "F631"
      },
      {
        denominazioneInItaliano: "Motta Montecorvino",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "F777"
      },
      {
        denominazioneInItaliano: "Orsara di Puglia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "G125"
      },
      {
        denominazioneInItaliano: "Orta Nova",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "G131"
      },
      {
        denominazioneInItaliano: "Panni",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "G312"
      },
      {
        denominazioneInItaliano: "Peschici",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "G487"
      },
      {
        denominazioneInItaliano: "Pietramontecorvino",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "G604"
      },
      {
        denominazioneInItaliano: "Poggio Imperiale",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "G761"
      },
      {
        denominazioneInItaliano: "Rignano Garganico",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "H287"
      },
      {
        denominazioneInItaliano: "Rocchetta Sant'Antonio",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "H467"
      },
      {
        denominazioneInItaliano: "Rodi Garganico",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "H480"
      },
      {
        denominazioneInItaliano: "Roseto Valfortore",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "H568"
      },
      {
        denominazioneInItaliano: "San Giovanni Rotondo",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "H926"
      },
      {
        denominazioneInItaliano: "San Marco in Lamis",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "H985"
      },
      {
        denominazioneInItaliano: "San Marco la Catola",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "H986"
      },
      {
        denominazioneInItaliano: "San Nicandro Garganico",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "I054"
      },
      {
        denominazioneInItaliano: "San Paolo di Civitate",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "I072"
      },
      {
        denominazioneInItaliano: "San Severo",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "I158"
      },
      {
        denominazioneInItaliano: "Sant'Agata di Puglia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "I193"
      },
      {
        denominazioneInItaliano: "Serracapriola",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "I641"
      },
      {
        denominazioneInItaliano: "Stornara",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "I962"
      },
      {
        denominazioneInItaliano: "Stornarella",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "I963"
      },
      {
        denominazioneInItaliano: "Torremaggiore",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "L273"
      },
      {
        denominazioneInItaliano: "Troia",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "L447"
      },
      {
        denominazioneInItaliano: "Vico del Gargano",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "L842"
      },
      {
        denominazioneInItaliano: "Vieste",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "L858"
      },
      {
        denominazioneInItaliano: "Volturara Appula",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "M131"
      },
      {
        denominazioneInItaliano: "Volturino",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "M132"
      },
      {
        denominazioneInItaliano: "Ordona",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "M266"
      },
      {
        denominazioneInItaliano: "Zapponeta",
        regione: "Puglia",
        provincia: "Foggia",
        codiceCatastale: "M267"
      },
      {
        denominazioneInItaliano: "Acquaviva delle Fonti",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A048"
      },
      {
        denominazioneInItaliano: "Adelfia",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A055"
      },
      {
        denominazioneInItaliano: "Alberobello",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A149"
      },
      {
        denominazioneInItaliano: "Altamura",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A225"
      },
      {
        denominazioneInItaliano: "Bari",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A662"
      },
      {
        denominazioneInItaliano: "Binetto",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A874"
      },
      {
        denominazioneInItaliano: "Bitetto",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A892"
      },
      {
        denominazioneInItaliano: "Bitonto",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A893"
      },
      {
        denominazioneInItaliano: "Bitritto",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "A894"
      },
      {
        denominazioneInItaliano: "Capurso",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "B716"
      },
      {
        denominazioneInItaliano: "Casamassima",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "B923"
      },
      {
        denominazioneInItaliano: "Cassano delle Murge",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "B998"
      },
      {
        denominazioneInItaliano: "Castellana Grotte",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "C134"
      },
      {
        denominazioneInItaliano: "Cellamare",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "C436"
      },
      {
        denominazioneInItaliano: "Conversano",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "C975"
      },
      {
        denominazioneInItaliano: "Corato",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "C983"
      },
      {
        denominazioneInItaliano: "Gioia del Colle",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "E038"
      },
      {
        denominazioneInItaliano: "Giovinazzo",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "E047"
      },
      {
        denominazioneInItaliano: "Gravina in Puglia",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "E155"
      },
      {
        denominazioneInItaliano: "Grumo Appula",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "E223"
      },
      {
        denominazioneInItaliano: "Locorotondo",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "E645"
      },
      {
        denominazioneInItaliano: "Modugno",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "F262"
      },
      {
        denominazioneInItaliano: "Mola di Bari",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "F280"
      },
      {
        denominazioneInItaliano: "Molfetta",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "F284"
      },
      {
        denominazioneInItaliano: "Monopoli",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "F376"
      },
      {
        denominazioneInItaliano: "Noci",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "F915"
      },
      {
        denominazioneInItaliano: "Noicattaro",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "F923"
      },
      {
        denominazioneInItaliano: "Palo del Colle",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "G291"
      },
      {
        denominazioneInItaliano: "Poggiorsini",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "G769"
      },
      {
        denominazioneInItaliano: "Polignano a Mare",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "G787"
      },
      {
        denominazioneInItaliano: "Putignano",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "H096"
      },
      {
        denominazioneInItaliano: "Rutigliano",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "H643"
      },
      {
        denominazioneInItaliano: "Ruvo di Puglia",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "H645"
      },
      {
        denominazioneInItaliano: "Sammichele di Bari",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "H749"
      },
      {
        denominazioneInItaliano: "Sannicandro di Bari",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "I053"
      },
      {
        denominazioneInItaliano: "Santeramo in Colle",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "I330"
      },
      {
        denominazioneInItaliano: "Terlizzi",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "L109"
      },
      {
        denominazioneInItaliano: "Toritto",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "L220"
      },
      {
        denominazioneInItaliano: "Triggiano",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "L425"
      },
      {
        denominazioneInItaliano: "Turi",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "L472"
      },
      {
        denominazioneInItaliano: "Valenzano",
        regione: "Puglia",
        provincia: "Bari",
        codiceCatastale: "L571"
      },
      {
        denominazioneInItaliano: "Avetrana",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "A514"
      },
      {
        denominazioneInItaliano: "Carosino",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "B808"
      },
      {
        denominazioneInItaliano: "Castellaneta",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "C136"
      },
      {
        denominazioneInItaliano: "Crispiano",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "D171"
      },
      {
        denominazioneInItaliano: "Faggiano",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "D463"
      },
      {
        denominazioneInItaliano: "Fragagnano",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "D754"
      },
      {
        denominazioneInItaliano: "Ginosa",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "E036"
      },
      {
        denominazioneInItaliano: "Grottaglie",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "E205"
      },
      {
        denominazioneInItaliano: "Laterza",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "E469"
      },
      {
        denominazioneInItaliano: "Leporano",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "E537"
      },
      {
        denominazioneInItaliano: "Lizzano",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "E630"
      },
      {
        denominazioneInItaliano: "Manduria",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "E882"
      },
      {
        denominazioneInItaliano: "Martina Franca",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "E986"
      },
      {
        denominazioneInItaliano: "Maruggio",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "E995"
      },
      {
        denominazioneInItaliano: "Massafra",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "F027"
      },
      {
        denominazioneInItaliano: "Monteiasi",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "F531"
      },
      {
        denominazioneInItaliano: "Montemesola",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "F563"
      },
      {
        denominazioneInItaliano: "Monteparano",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "F587"
      },
      {
        denominazioneInItaliano: "Mottola",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "F784"
      },
      {
        denominazioneInItaliano: "Palagianello",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "G251"
      },
      {
        denominazioneInItaliano: "Palagiano",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "G252"
      },
      {
        denominazioneInItaliano: "Pulsano",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "H090"
      },
      {
        denominazioneInItaliano: "Roccaforzata",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "H409"
      },
      {
        denominazioneInItaliano: "San Giorgio Ionico",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "H882"
      },
      {
        denominazioneInItaliano: "San Marzano di San Giuseppe",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "I018"
      },
      {
        denominazioneInItaliano: "Sava",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "I467"
      },
      {
        denominazioneInItaliano: "Taranto",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "L049"
      },
      {
        denominazioneInItaliano: "Torricella",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "L294"
      },
      {
        denominazioneInItaliano: "Statte",
        regione: "Puglia",
        provincia: "Taranto",
        codiceCatastale: "M298"
      },
      {
        denominazioneInItaliano: "Brindisi",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "B180"
      },
      {
        denominazioneInItaliano: "Carovigno",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "B809"
      },
      {
        denominazioneInItaliano: "Ceglie Messapica",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "C424"
      },
      {
        denominazioneInItaliano: "Cellino San Marco",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "C448"
      },
      {
        denominazioneInItaliano: "Cisternino",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "C741"
      },
      {
        denominazioneInItaliano: "Erchie",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "D422"
      },
      {
        denominazioneInItaliano: "Fasano",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "D508"
      },
      {
        denominazioneInItaliano: "Francavilla Fontana",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "D761"
      },
      {
        denominazioneInItaliano: "Latiano",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "E471"
      },
      {
        denominazioneInItaliano: "Mesagne",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "F152"
      },
      {
        denominazioneInItaliano: "Oria",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "G098"
      },
      {
        denominazioneInItaliano: "Ostuni",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "G187"
      },
      {
        denominazioneInItaliano: "San Donaci",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "H822"
      },
      {
        denominazioneInItaliano: "San Michele Salentino",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "I045"
      },
      {
        denominazioneInItaliano: "San Pancrazio Salentino",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "I066"
      },
      {
        denominazioneInItaliano: "San Pietro Vernotico",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "I119"
      },
      {
        denominazioneInItaliano: "San Vito dei Normanni",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "I396"
      },
      {
        denominazioneInItaliano: "Torchiarolo",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "L213"
      },
      {
        denominazioneInItaliano: "Torre Santa Susanna",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "L280"
      },
      {
        denominazioneInItaliano: "Villa Castelli",
        regione: "Puglia",
        provincia: "Brindisi",
        codiceCatastale: "L920"
      },
      {
        denominazioneInItaliano: "Alessano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "A184"
      },
      {
        denominazioneInItaliano: "Alezio",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "A185"
      },
      {
        denominazioneInItaliano: "Alliste",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "A208"
      },
      {
        denominazioneInItaliano: "Andrano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "A281"
      },
      {
        denominazioneInItaliano: "Aradeo",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "A350"
      },
      {
        denominazioneInItaliano: "Arnesano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "A425"
      },
      {
        denominazioneInItaliano: "Bagnolo del Salento",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "A572"
      },
      {
        denominazioneInItaliano: "Botrugno",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "B086"
      },
      {
        denominazioneInItaliano: "Calimera",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "B413"
      },
      {
        denominazioneInItaliano: "Campi Salentina",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "B506"
      },
      {
        denominazioneInItaliano: "Cannole",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "B616"
      },
      {
        denominazioneInItaliano: "Caprarica di Lecce",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "B690"
      },
      {
        denominazioneInItaliano: "Carmiano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "B792"
      },
      {
        denominazioneInItaliano: "Carpignano Salentino",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "B822"
      },
      {
        denominazioneInItaliano: "Casarano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "B936"
      },
      {
        denominazioneInItaliano: "Castri di Lecce",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "C334"
      },
      {
        denominazioneInItaliano: "Castrignano de' Greci",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "C335"
      },
      {
        denominazioneInItaliano: "Castrignano del Capo",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "C336"
      },
      {
        denominazioneInItaliano: "Cavallino",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "C377"
      },
      {
        denominazioneInItaliano: "Collepasso",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "C865"
      },
      {
        denominazioneInItaliano: "Copertino",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "C978"
      },
      {
        denominazioneInItaliano: "Corigliano d'Otranto",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D006"
      },
      {
        denominazioneInItaliano: "Corsano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D044"
      },
      {
        denominazioneInItaliano: "Cursi",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D223"
      },
      {
        denominazioneInItaliano: "Cutrofiano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D237"
      },
      {
        denominazioneInItaliano: "Diso",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D305"
      },
      {
        denominazioneInItaliano: "Gagliano del Capo",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D851"
      },
      {
        denominazioneInItaliano: "Galatina",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D862"
      },
      {
        denominazioneInItaliano: "Galatone",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D863"
      },
      {
        denominazioneInItaliano: "Gallipoli",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "D883"
      },
      {
        denominazioneInItaliano: "Giuggianello",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E053"
      },
      {
        denominazioneInItaliano: "Giurdignano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E061"
      },
      {
        denominazioneInItaliano: "Guagnano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E227"
      },
      {
        denominazioneInItaliano: "Lecce",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E506"
      },
      {
        denominazioneInItaliano: "Lequile",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E538"
      },
      {
        denominazioneInItaliano: "Leverano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E563"
      },
      {
        denominazioneInItaliano: "Lizzanello",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E629"
      },
      {
        denominazioneInItaliano: "Maglie",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E815"
      },
      {
        denominazioneInItaliano: "Martano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E979"
      },
      {
        denominazioneInItaliano: "Martignano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "E984"
      },
      {
        denominazioneInItaliano: "Matino",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F054"
      },
      {
        denominazioneInItaliano: "Melendugno",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F101"
      },
      {
        denominazioneInItaliano: "Melissano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F109"
      },
      {
        denominazioneInItaliano: "Melpignano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F117"
      },
      {
        denominazioneInItaliano: "Miggiano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F194"
      },
      {
        denominazioneInItaliano: "Minervino di Lecce",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F221"
      },
      {
        denominazioneInItaliano: "Monteroni di Lecce",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F604"
      },
      {
        denominazioneInItaliano: "Montesano Salentino",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F623"
      },
      {
        denominazioneInItaliano: "Morciano di Leuca",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F716"
      },
      {
        denominazioneInItaliano: "Muro Leccese",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F816"
      },
      {
        denominazioneInItaliano: "Nardò",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F842"
      },
      {
        denominazioneInItaliano: "Neviano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F881"
      },
      {
        denominazioneInItaliano: "Nociglia",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F916"
      },
      {
        denominazioneInItaliano: "Novoli",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "F970"
      },
      {
        denominazioneInItaliano: "Ortelle",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "G136"
      },
      {
        denominazioneInItaliano: "Otranto",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "G188"
      },
      {
        denominazioneInItaliano: "Palmariggi",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "G285"
      },
      {
        denominazioneInItaliano: "Parabita",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "G325"
      },
      {
        denominazioneInItaliano: "Patù",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "G378"
      },
      {
        denominazioneInItaliano: "Poggiardo",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "G751"
      },
      {
        denominazioneInItaliano: "Racale",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "H147"
      },
      {
        denominazioneInItaliano: "Ruffano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "H632"
      },
      {
        denominazioneInItaliano: "Salice Salentino",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "H708"
      },
      {
        denominazioneInItaliano: "Salve",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "H729"
      },
      {
        denominazioneInItaliano: "Sanarica",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "H757"
      },
      {
        denominazioneInItaliano: "San Cesario di Lecce",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "H793"
      },
      {
        denominazioneInItaliano: "San Donato di Lecce",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "H826"
      },
      {
        denominazioneInItaliano: "Sannicola",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I059"
      },
      {
        denominazioneInItaliano: "San Pietro in Lama",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I115"
      },
      {
        denominazioneInItaliano: "Santa Cesarea Terme",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I172"
      },
      {
        denominazioneInItaliano: "Scorrano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I549"
      },
      {
        denominazioneInItaliano: "Seclì",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I559"
      },
      {
        denominazioneInItaliano: "Sogliano Cavour",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I780"
      },
      {
        denominazioneInItaliano: "Soleto",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I800"
      },
      {
        denominazioneInItaliano: "Specchia",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I887"
      },
      {
        denominazioneInItaliano: "Spongano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I923"
      },
      {
        denominazioneInItaliano: "Squinzano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I930"
      },
      {
        denominazioneInItaliano: "Sternatia",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "I950"
      },
      {
        denominazioneInItaliano: "Supersano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L008"
      },
      {
        denominazioneInItaliano: "Surano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L010"
      },
      {
        denominazioneInItaliano: "Surbo",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L011"
      },
      {
        denominazioneInItaliano: "Taurisano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L064"
      },
      {
        denominazioneInItaliano: "Taviano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L074"
      },
      {
        denominazioneInItaliano: "Tiggiano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L166"
      },
      {
        denominazioneInItaliano: "Trepuzzi",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L383"
      },
      {
        denominazioneInItaliano: "Tricase",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L419"
      },
      {
        denominazioneInItaliano: "Tuglie",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L462"
      },
      {
        denominazioneInItaliano: "Ugento",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L484"
      },
      {
        denominazioneInItaliano: "Uggiano la Chiesa",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L485"
      },
      {
        denominazioneInItaliano: "Veglie",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L711"
      },
      {
        denominazioneInItaliano: "Vernole",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "L776"
      },
      {
        denominazioneInItaliano: "Zollino",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "M187"
      },
      {
        denominazioneInItaliano: "San Cassiano",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "M264"
      },
      {
        denominazioneInItaliano: "Castro",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "M261"
      },
      {
        denominazioneInItaliano: "Porto Cesareo",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "M263"
      },
      {
        denominazioneInItaliano: "Presicce-Acquarica",
        regione: "Puglia",
        provincia: "Lecce",
        codiceCatastale: "M428"
      },
      {
        denominazioneInItaliano: "Andria",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "A285"
      },
      {
        denominazioneInItaliano: "Barletta",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "A669"
      },
      {
        denominazioneInItaliano: "Bisceglie",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "A883"
      },
      {
        denominazioneInItaliano: "Canosa di Puglia",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "B619"
      },
      {
        denominazioneInItaliano: "Margherita di Savoia",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "E946"
      },
      {
        denominazioneInItaliano: "Minervino Murge",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "F220"
      },
      {
        denominazioneInItaliano: "San Ferdinando di Puglia",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "H839"
      },
      {
        denominazioneInItaliano: "Spinazzola",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "I907"
      },
      {
        denominazioneInItaliano: "Trani",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "L328"
      },
      {
        denominazioneInItaliano: "Trinitapoli",
        regione: "Puglia",
        provincia: "Barletta-Andria-Trani",
        codiceCatastale: "B915"
      },
      {
        denominazioneInItaliano: "Abriola",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A013"
      },
      {
        denominazioneInItaliano: "Acerenza",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A020"
      },
      {
        denominazioneInItaliano: "Albano di Lucania",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A131"
      },
      {
        denominazioneInItaliano: "Anzi",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A321"
      },
      {
        denominazioneInItaliano: "Armento",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A415"
      },
      {
        denominazioneInItaliano: "Atella",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A482"
      },
      {
        denominazioneInItaliano: "Avigliano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A519"
      },
      {
        denominazioneInItaliano: "Balvano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A604"
      },
      {
        denominazioneInItaliano: "Banzi",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A612"
      },
      {
        denominazioneInItaliano: "Baragiano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A615"
      },
      {
        denominazioneInItaliano: "Barile",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A666"
      },
      {
        denominazioneInItaliano: "Bella",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "A743"
      },
      {
        denominazioneInItaliano: "Brienza",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "B173"
      },
      {
        denominazioneInItaliano: "Brindisi Montagna",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "B181"
      },
      {
        denominazioneInItaliano: "Calvello",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "B440"
      },
      {
        denominazioneInItaliano: "Calvera",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "B443"
      },
      {
        denominazioneInItaliano: "Campomaggiore",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "B549"
      },
      {
        denominazioneInItaliano: "Cancellara",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "B580"
      },
      {
        denominazioneInItaliano: "Carbone",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "B743"
      },
      {
        denominazioneInItaliano: "San Paolo Albanese",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "B906"
      },
      {
        denominazioneInItaliano: "Castelgrande",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "C120"
      },
      {
        denominazioneInItaliano: "Castelluccio Inferiore",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "C199"
      },
      {
        denominazioneInItaliano: "Castelluccio Superiore",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "C201"
      },
      {
        denominazioneInItaliano: "Castelmezzano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "C209"
      },
      {
        denominazioneInItaliano: "Castelsaraceno",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "C271"
      },
      {
        denominazioneInItaliano: "Castronuovo di Sant'Andrea",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "C345"
      },
      {
        denominazioneInItaliano: "Cersosimo",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "C539"
      },
      {
        denominazioneInItaliano: "Chiaromonte",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "C619"
      },
      {
        denominazioneInItaliano: "Corleto Perticara",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "D010"
      },
      {
        denominazioneInItaliano: "Episcopia",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "D414"
      },
      {
        denominazioneInItaliano: "Fardella",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "D497"
      },
      {
        denominazioneInItaliano: "Filiano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "D593"
      },
      {
        denominazioneInItaliano: "Forenza",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "D696"
      },
      {
        denominazioneInItaliano: "Francavilla in Sinni",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "D766"
      },
      {
        denominazioneInItaliano: "Gallicchio",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "D876"
      },
      {
        denominazioneInItaliano: "Genzano di Lucania",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "D971"
      },
      {
        denominazioneInItaliano: "Grumento Nova",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E221"
      },
      {
        denominazioneInItaliano: "Guardia Perticara",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E246"
      },
      {
        denominazioneInItaliano: "Lagonegro",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E409"
      },
      {
        denominazioneInItaliano: "Latronico",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E474"
      },
      {
        denominazioneInItaliano: "Laurenzana",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E482"
      },
      {
        denominazioneInItaliano: "Lauria",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E483"
      },
      {
        denominazioneInItaliano: "Lavello",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E493"
      },
      {
        denominazioneInItaliano: "Maratea",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E919"
      },
      {
        denominazioneInItaliano: "Marsico Nuovo",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E976"
      },
      {
        denominazioneInItaliano: "Marsicovetere",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E977"
      },
      {
        denominazioneInItaliano: "Maschito",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F006"
      },
      {
        denominazioneInItaliano: "Melfi",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F104"
      },
      {
        denominazioneInItaliano: "Missanello",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F249"
      },
      {
        denominazioneInItaliano: "Moliterno",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F295"
      },
      {
        denominazioneInItaliano: "Montemilone",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F568"
      },
      {
        denominazioneInItaliano: "Montemurro",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F573"
      },
      {
        denominazioneInItaliano: "Muro Lucano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F817"
      },
      {
        denominazioneInItaliano: "Nemoli",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F866"
      },
      {
        denominazioneInItaliano: "Noepoli",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "F917"
      },
      {
        denominazioneInItaliano: "Oppido Lucano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G081"
      },
      {
        denominazioneInItaliano: "Palazzo San Gervasio",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G261"
      },
      {
        denominazioneInItaliano: "Pescopagano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G496"
      },
      {
        denominazioneInItaliano: "Picerno",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G590"
      },
      {
        denominazioneInItaliano: "Pietragalla",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G616"
      },
      {
        denominazioneInItaliano: "Pietrapertosa",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G623"
      },
      {
        denominazioneInItaliano: "Pignola",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G663"
      },
      {
        denominazioneInItaliano: "Potenza",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G942"
      },
      {
        denominazioneInItaliano: "Rapolla",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H186"
      },
      {
        denominazioneInItaliano: "Rapone",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H187"
      },
      {
        denominazioneInItaliano: "Rionero in Vulture",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H307"
      },
      {
        denominazioneInItaliano: "Ripacandida",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H312"
      },
      {
        denominazioneInItaliano: "Rivello",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H348"
      },
      {
        denominazioneInItaliano: "Roccanova",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H426"
      },
      {
        denominazioneInItaliano: "Rotonda",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H590"
      },
      {
        denominazioneInItaliano: "Ruoti",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H641"
      },
      {
        denominazioneInItaliano: "Ruvo del Monte",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H646"
      },
      {
        denominazioneInItaliano: "San Chirico Nuovo",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H795"
      },
      {
        denominazioneInItaliano: "San Chirico Raparo",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H796"
      },
      {
        denominazioneInItaliano: "San Costantino Albanese",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H808"
      },
      {
        denominazioneInItaliano: "San Fele",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H831"
      },
      {
        denominazioneInItaliano: "San Martino d'Agri",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H994"
      },
      {
        denominazioneInItaliano: "San Severino Lucano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "I157"
      },
      {
        denominazioneInItaliano: "Sant'Angelo Le Fratte",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "I288"
      },
      {
        denominazioneInItaliano: "Sant'Arcangelo",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "I305"
      },
      {
        denominazioneInItaliano: "Sarconi",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "I426"
      },
      {
        denominazioneInItaliano: "Sasso di Castalda",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "I457"
      },
      {
        denominazioneInItaliano: "Satriano di Lucania",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "G614"
      },
      {
        denominazioneInItaliano: "Savoia di Lucania",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "H730"
      },
      {
        denominazioneInItaliano: "Senise",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "I610"
      },
      {
        denominazioneInItaliano: "Spinoso",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "I917"
      },
      {
        denominazioneInItaliano: "Teana",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L082"
      },
      {
        denominazioneInItaliano: "Terranova di Pollino",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L126"
      },
      {
        denominazioneInItaliano: "Tito",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L181"
      },
      {
        denominazioneInItaliano: "Tolve",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L197"
      },
      {
        denominazioneInItaliano: "Tramutola",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L326"
      },
      {
        denominazioneInItaliano: "Trecchina",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L357"
      },
      {
        denominazioneInItaliano: "Trivigno",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L439"
      },
      {
        denominazioneInItaliano: "Vaglio Basilicata",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L532"
      },
      {
        denominazioneInItaliano: "Venosa",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L738"
      },
      {
        denominazioneInItaliano: "Vietri di Potenza",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L859"
      },
      {
        denominazioneInItaliano: "Viggianello",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L873"
      },
      {
        denominazioneInItaliano: "Viggiano",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "L874"
      },
      {
        denominazioneInItaliano: "Ginestra",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "E033"
      },
      {
        denominazioneInItaliano: "Paterno",
        regione: "Basilicata",
        provincia: "Potenza",
        codiceCatastale: "M269"
      },
      {
        denominazioneInItaliano: "Accettura",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "A017"
      },
      {
        denominazioneInItaliano: "Aliano",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "A196"
      },
      {
        denominazioneInItaliano: "Bernalda",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "A801"
      },
      {
        denominazioneInItaliano: "Calciano",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "B391"
      },
      {
        denominazioneInItaliano: "Cirigliano",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "C723"
      },
      {
        denominazioneInItaliano: "Colobraro",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "C888"
      },
      {
        denominazioneInItaliano: "Craco",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "D128"
      },
      {
        denominazioneInItaliano: "Ferrandina",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "D547"
      },
      {
        denominazioneInItaliano: "Garaguso",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "D909"
      },
      {
        denominazioneInItaliano: "Gorgoglione",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "E093"
      },
      {
        denominazioneInItaliano: "Grassano",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "E147"
      },
      {
        denominazioneInItaliano: "Grottole",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "E213"
      },
      {
        denominazioneInItaliano: "Irsina",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "E326"
      },
      {
        denominazioneInItaliano: "Matera",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "F052"
      },
      {
        denominazioneInItaliano: "Miglionico",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "F201"
      },
      {
        denominazioneInItaliano: "Montalbano Jonico",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "F399"
      },
      {
        denominazioneInItaliano: "Montescaglioso",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "F637"
      },
      {
        denominazioneInItaliano: "Nova Siri",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "A942"
      },
      {
        denominazioneInItaliano: "Oliveto Lucano",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "G037"
      },
      {
        denominazioneInItaliano: "Pisticci",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "G712"
      },
      {
        denominazioneInItaliano: "Policoro",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "G786"
      },
      {
        denominazioneInItaliano: "Pomarico",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "G806"
      },
      {
        denominazioneInItaliano: "Rotondella",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "H591"
      },
      {
        denominazioneInItaliano: "Salandra",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "H687"
      },
      {
        denominazioneInItaliano: "San Giorgio Lucano",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "H888"
      },
      {
        denominazioneInItaliano: "San Mauro Forte",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "I029"
      },
      {
        denominazioneInItaliano: "Stigliano",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "I954"
      },
      {
        denominazioneInItaliano: "Tricarico",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "L418"
      },
      {
        denominazioneInItaliano: "Tursi",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "L477"
      },
      {
        denominazioneInItaliano: "Valsinni",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "D513"
      },
      {
        denominazioneInItaliano: "Scanzano Jonico",
        regione: "Basilicata",
        provincia: "Matera",
        codiceCatastale: "M256"
      },
      {
        denominazioneInItaliano: "Acquaformosa",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A033"
      },
      {
        denominazioneInItaliano: "Acquappesa",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A041"
      },
      {
        denominazioneInItaliano: "Acri",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A053"
      },
      {
        denominazioneInItaliano: "Aiello Calabro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A102"
      },
      {
        denominazioneInItaliano: "Aieta",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A105"
      },
      {
        denominazioneInItaliano: "Albidona",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A160"
      },
      {
        denominazioneInItaliano: "Alessandria del Carretto",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A183"
      },
      {
        denominazioneInItaliano: "Altilia",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A234"
      },
      {
        denominazioneInItaliano: "Altomonte",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A240"
      },
      {
        denominazioneInItaliano: "Amantea",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A253"
      },
      {
        denominazioneInItaliano: "Amendolara",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A263"
      },
      {
        denominazioneInItaliano: "Aprigliano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A340"
      },
      {
        denominazioneInItaliano: "Belmonte Calabro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A762"
      },
      {
        denominazioneInItaliano: "Belsito",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A768"
      },
      {
        denominazioneInItaliano: "Belvedere Marittimo",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A773"
      },
      {
        denominazioneInItaliano: "Bianchi",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A842"
      },
      {
        denominazioneInItaliano: "Bisignano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A887"
      },
      {
        denominazioneInItaliano: "Bocchigliero",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A912"
      },
      {
        denominazioneInItaliano: "Bonifati",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "A973"
      },
      {
        denominazioneInItaliano: "Buonvicino",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B270"
      },
      {
        denominazioneInItaliano: "Calopezzati",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B424"
      },
      {
        denominazioneInItaliano: "Caloveto",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B426"
      },
      {
        denominazioneInItaliano: "Campana",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B500"
      },
      {
        denominazioneInItaliano: "Canna",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B607"
      },
      {
        denominazioneInItaliano: "Cariati",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B774"
      },
      {
        denominazioneInItaliano: "Carolei",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B802"
      },
      {
        denominazioneInItaliano: "Carpanzano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B813"
      },
      {
        denominazioneInItaliano: "Cassano all'Ionio",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C002"
      },
      {
        denominazioneInItaliano: "Castiglione Cosentino",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C301"
      },
      {
        denominazioneInItaliano: "Castrolibero",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C108"
      },
      {
        denominazioneInItaliano: "Castroregio",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C348"
      },
      {
        denominazioneInItaliano: "Castrovillari",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C349"
      },
      {
        denominazioneInItaliano: "Celico",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C430"
      },
      {
        denominazioneInItaliano: "Cellara",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C437"
      },
      {
        denominazioneInItaliano: "Cerchiara di Calabria",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C489"
      },
      {
        denominazioneInItaliano: "Cerisano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C515"
      },
      {
        denominazioneInItaliano: "Cervicati",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C554"
      },
      {
        denominazioneInItaliano: "Cerzeto",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C560"
      },
      {
        denominazioneInItaliano: "Cetraro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C588"
      },
      {
        denominazioneInItaliano: "Civita",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C763"
      },
      {
        denominazioneInItaliano: "Cleto",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C795"
      },
      {
        denominazioneInItaliano: "Colosimi",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C905"
      },
      {
        denominazioneInItaliano: "Cosenza",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D086"
      },
      {
        denominazioneInItaliano: "Cropalati",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D180"
      },
      {
        denominazioneInItaliano: "Crosia",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D184"
      },
      {
        denominazioneInItaliano: "Diamante",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D289"
      },
      {
        denominazioneInItaliano: "Dipignano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D304"
      },
      {
        denominazioneInItaliano: "Domanico",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D328"
      },
      {
        denominazioneInItaliano: "Fagnano Castello",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D464"
      },
      {
        denominazioneInItaliano: "Falconara Albanese",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D473"
      },
      {
        denominazioneInItaliano: "Figline Vegliaturo",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D582"
      },
      {
        denominazioneInItaliano: "Firmo",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D614"
      },
      {
        denominazioneInItaliano: "Fiumefreddo Bruzio",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D624"
      },
      {
        denominazioneInItaliano: "Francavilla Marittima",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D764"
      },
      {
        denominazioneInItaliano: "Frascineto",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D774"
      },
      {
        denominazioneInItaliano: "Fuscaldo",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D828"
      },
      {
        denominazioneInItaliano: "Grimaldi",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E180"
      },
      {
        denominazioneInItaliano: "Grisolia",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E185"
      },
      {
        denominazioneInItaliano: "Guardia Piemontese",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E242"
      },
      {
        denominazioneInItaliano: "Lago",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E407"
      },
      {
        denominazioneInItaliano: "Laino Borgo",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E417"
      },
      {
        denominazioneInItaliano: "Laino Castello",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E419"
      },
      {
        denominazioneInItaliano: "Lappano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E450"
      },
      {
        denominazioneInItaliano: "Lattarico",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E475"
      },
      {
        denominazioneInItaliano: "Longobardi",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E677"
      },
      {
        denominazioneInItaliano: "Longobucco",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E678"
      },
      {
        denominazioneInItaliano: "Lungro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E745"
      },
      {
        denominazioneInItaliano: "Luzzi",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E773"
      },
      {
        denominazioneInItaliano: "Maierà",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E835"
      },
      {
        denominazioneInItaliano: "Malito",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E859"
      },
      {
        denominazioneInItaliano: "Malvito",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E872"
      },
      {
        denominazioneInItaliano: "Mandatoriccio",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E878"
      },
      {
        denominazioneInItaliano: "Mangone",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E888"
      },
      {
        denominazioneInItaliano: "Marano Marchesato",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E914"
      },
      {
        denominazioneInItaliano: "Marano Principato",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "E915"
      },
      {
        denominazioneInItaliano: "Marzi",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F001"
      },
      {
        denominazioneInItaliano: "Mendicino",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F125"
      },
      {
        denominazioneInItaliano: "Mongrassano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F370"
      },
      {
        denominazioneInItaliano: "Montalto Uffugo",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F416"
      },
      {
        denominazioneInItaliano: "Montegiordano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F519"
      },
      {
        denominazioneInItaliano: "Morano Calabro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F708"
      },
      {
        denominazioneInItaliano: "Mormanno",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F735"
      },
      {
        denominazioneInItaliano: "Mottafollone",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F775"
      },
      {
        denominazioneInItaliano: "Nocara",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "F907"
      },
      {
        denominazioneInItaliano: "Oriolo",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G110"
      },
      {
        denominazioneInItaliano: "Orsomarso",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G129"
      },
      {
        denominazioneInItaliano: "Paludi",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G298"
      },
      {
        denominazioneInItaliano: "Panettieri",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G307"
      },
      {
        denominazioneInItaliano: "Paola",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G317"
      },
      {
        denominazioneInItaliano: "Papasidero",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G320"
      },
      {
        denominazioneInItaliano: "Parenti",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G331"
      },
      {
        denominazioneInItaliano: "Paterno Calabro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G372"
      },
      {
        denominazioneInItaliano: "Pedivigliano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G411"
      },
      {
        denominazioneInItaliano: "Piane Crati",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G553"
      },
      {
        denominazioneInItaliano: "Pietrafitta",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G615"
      },
      {
        denominazioneInItaliano: "Pietrapaola",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G622"
      },
      {
        denominazioneInItaliano: "Plataci",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G733"
      },
      {
        denominazioneInItaliano: "Praia a Mare",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "G975"
      },
      {
        denominazioneInItaliano: "Rende",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H235"
      },
      {
        denominazioneInItaliano: "Rocca Imperiale",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H416"
      },
      {
        denominazioneInItaliano: "Roggiano Gravina",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H488"
      },
      {
        denominazioneInItaliano: "Rogliano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H490"
      },
      {
        denominazioneInItaliano: "Rose",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H565"
      },
      {
        denominazioneInItaliano: "Roseto Capo Spulico",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H572"
      },
      {
        denominazioneInItaliano: "Rota Greca",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H585"
      },
      {
        denominazioneInItaliano: "Rovito",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H621"
      },
      {
        denominazioneInItaliano: "San Basile",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H765"
      },
      {
        denominazioneInItaliano: "San Benedetto Ullano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H774"
      },
      {
        denominazioneInItaliano: "San Cosmo Albanese",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H806"
      },
      {
        denominazioneInItaliano: "San Demetrio Corone",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H818"
      },
      {
        denominazioneInItaliano: "San Donato di Ninea",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H825"
      },
      {
        denominazioneInItaliano: "San Fili",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H841"
      },
      {
        denominazioneInItaliano: "Sangineto",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H877"
      },
      {
        denominazioneInItaliano: "San Giorgio Albanese",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H881"
      },
      {
        denominazioneInItaliano: "San Giovanni in Fiore",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H919"
      },
      {
        denominazioneInItaliano: "San Lorenzo Bellizzi",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H961"
      },
      {
        denominazioneInItaliano: "San Lorenzo del Vallo",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H962"
      },
      {
        denominazioneInItaliano: "San Lucido",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H971"
      },
      {
        denominazioneInItaliano: "San Marco Argentano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H981"
      },
      {
        denominazioneInItaliano: "San Martino di Finita",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "H992"
      },
      {
        denominazioneInItaliano: "San Nicola Arcella",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I060"
      },
      {
        denominazioneInItaliano: "San Pietro in Amantea",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I108"
      },
      {
        denominazioneInItaliano: "San Pietro in Guarano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I114"
      },
      {
        denominazioneInItaliano: "San Sosti",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I165"
      },
      {
        denominazioneInItaliano: "Santa Caterina Albanese",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I171"
      },
      {
        denominazioneInItaliano: "Santa Domenica Talao",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I183"
      },
      {
        denominazioneInItaliano: "Sant'Agata di Esaro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I192"
      },
      {
        denominazioneInItaliano: "Santa Maria del Cedro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "C717"
      },
      {
        denominazioneInItaliano: "Santa Sofia d'Epiro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I309"
      },
      {
        denominazioneInItaliano: "Santo Stefano di Rogliano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I359"
      },
      {
        denominazioneInItaliano: "San Vincenzo La Costa",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I388"
      },
      {
        denominazioneInItaliano: "Saracena",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I423"
      },
      {
        denominazioneInItaliano: "Scala Coeli",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I485"
      },
      {
        denominazioneInItaliano: "Scalea",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I489"
      },
      {
        denominazioneInItaliano: "Scigliano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "D290"
      },
      {
        denominazioneInItaliano: "Serra d'Aiello",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I642"
      },
      {
        denominazioneInItaliano: "Spezzano Albanese",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I895"
      },
      {
        denominazioneInItaliano: "Spezzano della Sila",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "I896"
      },
      {
        denominazioneInItaliano: "Tarsia",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "L055"
      },
      {
        denominazioneInItaliano: "Terranova da Sibari",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "L124"
      },
      {
        denominazioneInItaliano: "Terravecchia",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "L134"
      },
      {
        denominazioneInItaliano: "Torano Castello",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "L206"
      },
      {
        denominazioneInItaliano: "Tortora",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "L305"
      },
      {
        denominazioneInItaliano: "Trebisacce",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "L353"
      },
      {
        denominazioneInItaliano: "Vaccarizzo Albanese",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "L524"
      },
      {
        denominazioneInItaliano: "Verbicaro",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "L747"
      },
      {
        denominazioneInItaliano: "Villapiana",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "B903"
      },
      {
        denominazioneInItaliano: "Zumpano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "M202"
      },
      {
        denominazioneInItaliano: "Casali del Manco",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "M385"
      },
      {
        denominazioneInItaliano: "Corigliano-Rossano",
        regione: "Calabria",
        provincia: "Cosenza",
        codiceCatastale: "M403"
      },
      {
        denominazioneInItaliano: "Albi",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "A155"
      },
      {
        denominazioneInItaliano: "Amaroni",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "A255"
      },
      {
        denominazioneInItaliano: "Amato",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "A257"
      },
      {
        denominazioneInItaliano: "Andali",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "A272"
      },
      {
        denominazioneInItaliano: "Argusto",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "A397"
      },
      {
        denominazioneInItaliano: "Badolato",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "A542"
      },
      {
        denominazioneInItaliano: "Belcastro",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "A736"
      },
      {
        denominazioneInItaliano: "Borgia",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "B002"
      },
      {
        denominazioneInItaliano: "Botricello",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "B085"
      },
      {
        denominazioneInItaliano: "Caraffa di Catanzaro",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "B717"
      },
      {
        denominazioneInItaliano: "Cardinale",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "B758"
      },
      {
        denominazioneInItaliano: "Carlopoli",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "B790"
      },
      {
        denominazioneInItaliano: "Catanzaro",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "C352"
      },
      {
        denominazioneInItaliano: "Cenadi",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "C453"
      },
      {
        denominazioneInItaliano: "Centrache",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "C472"
      },
      {
        denominazioneInItaliano: "Cerva",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "C542"
      },
      {
        denominazioneInItaliano: "Chiaravalle Centrale",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "C616"
      },
      {
        denominazioneInItaliano: "Cicala",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "C674"
      },
      {
        denominazioneInItaliano: "Conflenti",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "C960"
      },
      {
        denominazioneInItaliano: "Cortale",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D049"
      },
      {
        denominazioneInItaliano: "Cropani",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D181"
      },
      {
        denominazioneInItaliano: "Curinga",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D218"
      },
      {
        denominazioneInItaliano: "Davoli",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D257"
      },
      {
        denominazioneInItaliano: "Decollatura",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D261"
      },
      {
        denominazioneInItaliano: "Falerna",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D476"
      },
      {
        denominazioneInItaliano: "Feroleto Antico",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D544"
      },
      {
        denominazioneInItaliano: "Fossato Serralta",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D744"
      },
      {
        denominazioneInItaliano: "Gagliato",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D852"
      },
      {
        denominazioneInItaliano: "Gasperina",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D932"
      },
      {
        denominazioneInItaliano: "Gimigliano",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E031"
      },
      {
        denominazioneInItaliano: "Girifalco",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E050"
      },
      {
        denominazioneInItaliano: "Gizzeria",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E068"
      },
      {
        denominazioneInItaliano: "Guardavalle",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E239"
      },
      {
        denominazioneInItaliano: "Isca sullo Ionio",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E328"
      },
      {
        denominazioneInItaliano: "Jacurso",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E274"
      },
      {
        denominazioneInItaliano: "Magisano",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E806"
      },
      {
        denominazioneInItaliano: "Maida",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E834"
      },
      {
        denominazioneInItaliano: "Marcedusa",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E923"
      },
      {
        denominazioneInItaliano: "Marcellinara",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E925"
      },
      {
        denominazioneInItaliano: "Martirano",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E990"
      },
      {
        denominazioneInItaliano: "Martirano Lombardo",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "E991"
      },
      {
        denominazioneInItaliano: "Miglierina",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "F200"
      },
      {
        denominazioneInItaliano: "Montauro",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "F432"
      },
      {
        denominazioneInItaliano: "Montepaone",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "F586"
      },
      {
        denominazioneInItaliano: "Motta Santa Lucia",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "F780"
      },
      {
        denominazioneInItaliano: "Nocera Terinese",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "F910"
      },
      {
        denominazioneInItaliano: "Olivadi",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "G034"
      },
      {
        denominazioneInItaliano: "Palermiti",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "G272"
      },
      {
        denominazioneInItaliano: "Pentone",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "G439"
      },
      {
        denominazioneInItaliano: "Petrizzi",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "G517"
      },
      {
        denominazioneInItaliano: "Petronà",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "G518"
      },
      {
        denominazioneInItaliano: "Pianopoli",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "D546"
      },
      {
        denominazioneInItaliano: "Platania",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "G734"
      },
      {
        denominazioneInItaliano: "San Floro",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "H846"
      },
      {
        denominazioneInItaliano: "San Mango d'Aquino",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "H976"
      },
      {
        denominazioneInItaliano: "San Pietro a Maida",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I093"
      },
      {
        denominazioneInItaliano: "San Pietro Apostolo",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I095"
      },
      {
        denominazioneInItaliano: "San Sostene",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I164"
      },
      {
        denominazioneInItaliano: "Santa Caterina dello Ionio",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I170"
      },
      {
        denominazioneInItaliano: "Sant'Andrea Apostolo dello Ionio",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I266"
      },
      {
        denominazioneInItaliano: "San Vito sullo Ionio",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I393"
      },
      {
        denominazioneInItaliano: "Satriano",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I463"
      },
      {
        denominazioneInItaliano: "Sellia",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I589"
      },
      {
        denominazioneInItaliano: "Sellia Marina",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I590"
      },
      {
        denominazioneInItaliano: "Serrastretta",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I655"
      },
      {
        denominazioneInItaliano: "Sersale",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I671"
      },
      {
        denominazioneInItaliano: "Settingiano",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I704"
      },
      {
        denominazioneInItaliano: "Simeri Crichi",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I745"
      },
      {
        denominazioneInItaliano: "Sorbo San Basile",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I844"
      },
      {
        denominazioneInItaliano: "Soverato",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I872"
      },
      {
        denominazioneInItaliano: "Soveria Mannelli",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I874"
      },
      {
        denominazioneInItaliano: "Soveria Simeri",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I875"
      },
      {
        denominazioneInItaliano: "Squillace",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I929"
      },
      {
        denominazioneInItaliano: "Stalettì",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I937"
      },
      {
        denominazioneInItaliano: "Taverna",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "L070"
      },
      {
        denominazioneInItaliano: "Tiriolo",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "L177"
      },
      {
        denominazioneInItaliano: "Torre di Ruggiero",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "L240"
      },
      {
        denominazioneInItaliano: "Vallefiorita",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "I322"
      },
      {
        denominazioneInItaliano: "Zagarise",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "M140"
      },
      {
        denominazioneInItaliano: "Lamezia Terme",
        regione: "Calabria",
        provincia: "Catanzaro",
        codiceCatastale: "M208"
      },
      {
        denominazioneInItaliano: "Africo",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A065"
      },
      {
        denominazioneInItaliano: "Agnana Calabra",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A077"
      },
      {
        denominazioneInItaliano: "Anoia",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A303"
      },
      {
        denominazioneInItaliano: "Antonimina",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A314"
      },
      {
        denominazioneInItaliano: "Ardore",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A385"
      },
      {
        denominazioneInItaliano: "Bagaladi",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A544"
      },
      {
        denominazioneInItaliano: "Bagnara Calabra",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A552"
      },
      {
        denominazioneInItaliano: "Benestare",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A780"
      },
      {
        denominazioneInItaliano: "Bianco",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A843"
      },
      {
        denominazioneInItaliano: "Bivongi",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "A897"
      },
      {
        denominazioneInItaliano: "Bova",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B097"
      },
      {
        denominazioneInItaliano: "Bovalino",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B098"
      },
      {
        denominazioneInItaliano: "Bova Marina",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B099"
      },
      {
        denominazioneInItaliano: "Brancaleone",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B118"
      },
      {
        denominazioneInItaliano: "Bruzzano Zeffirio",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B234"
      },
      {
        denominazioneInItaliano: "Calanna",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B379"
      },
      {
        denominazioneInItaliano: "Camini",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B481"
      },
      {
        denominazioneInItaliano: "Campo Calabro",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B516"
      },
      {
        denominazioneInItaliano: "Candidoni",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B591"
      },
      {
        denominazioneInItaliano: "Canolo",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B617"
      },
      {
        denominazioneInItaliano: "Caraffa del Bianco",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B718"
      },
      {
        denominazioneInItaliano: "Cardeto",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B756"
      },
      {
        denominazioneInItaliano: "Careri",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B766"
      },
      {
        denominazioneInItaliano: "Casignana",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "B966"
      },
      {
        denominazioneInItaliano: "Caulonia",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "C285"
      },
      {
        denominazioneInItaliano: "Ciminà",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "C695"
      },
      {
        denominazioneInItaliano: "Cinquefrondi",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "C710"
      },
      {
        denominazioneInItaliano: "Cittanova",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "C747"
      },
      {
        denominazioneInItaliano: "Condofuri",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "C954"
      },
      {
        denominazioneInItaliano: "Cosoleto",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D089"
      },
      {
        denominazioneInItaliano: "Delianuova",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D268"
      },
      {
        denominazioneInItaliano: "Feroleto della Chiesa",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D545"
      },
      {
        denominazioneInItaliano: "Ferruzzano",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D557"
      },
      {
        denominazioneInItaliano: "Fiumara",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D619"
      },
      {
        denominazioneInItaliano: "Galatro",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D864"
      },
      {
        denominazioneInItaliano: "Gerace",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D975"
      },
      {
        denominazioneInItaliano: "Giffone",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E025"
      },
      {
        denominazioneInItaliano: "Gioia Tauro",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E041"
      },
      {
        denominazioneInItaliano: "Gioiosa Ionica",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E044"
      },
      {
        denominazioneInItaliano: "Grotteria",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E212"
      },
      {
        denominazioneInItaliano: "Laganadi",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E402"
      },
      {
        denominazioneInItaliano: "Laureana di Borrello",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E479"
      },
      {
        denominazioneInItaliano: "Locri",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D976"
      },
      {
        denominazioneInItaliano: "Mammola",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E873"
      },
      {
        denominazioneInItaliano: "Marina di Gioiosa Ionica",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E956"
      },
      {
        denominazioneInItaliano: "Maropati",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E968"
      },
      {
        denominazioneInItaliano: "Martone",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "E993"
      },
      {
        denominazioneInItaliano: "Melicuccà",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "F105"
      },
      {
        denominazioneInItaliano: "Melicucco",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "F106"
      },
      {
        denominazioneInItaliano: "Melito di Porto Salvo",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "F112"
      },
      {
        denominazioneInItaliano: "Molochio",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "F301"
      },
      {
        denominazioneInItaliano: "Monasterace",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "F324"
      },
      {
        denominazioneInItaliano: "Montebello Jonico",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "D746"
      },
      {
        denominazioneInItaliano: "Motta San Giovanni",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "F779"
      },
      {
        denominazioneInItaliano: "Oppido Mamertina",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "G082"
      },
      {
        denominazioneInItaliano: "Palizzi",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "G277"
      },
      {
        denominazioneInItaliano: "Palmi",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "G288"
      },
      {
        denominazioneInItaliano: "Pazzano",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "G394"
      },
      {
        denominazioneInItaliano: "Placanica",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "G729"
      },
      {
        denominazioneInItaliano: "Platì",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "G735"
      },
      {
        denominazioneInItaliano: "Polistena",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "G791"
      },
      {
        denominazioneInItaliano: "Portigliola",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "G905"
      },
      {
        denominazioneInItaliano: "Reggio di Calabria",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H224"
      },
      {
        denominazioneInItaliano: "Riace",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H265"
      },
      {
        denominazioneInItaliano: "Rizziconi",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H359"
      },
      {
        denominazioneInItaliano: "Roccaforte del Greco",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H408"
      },
      {
        denominazioneInItaliano: "Roccella Ionica",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H456"
      },
      {
        denominazioneInItaliano: "Roghudi",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H489"
      },
      {
        denominazioneInItaliano: "Rosarno",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H558"
      },
      {
        denominazioneInItaliano: "Samo",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H013"
      },
      {
        denominazioneInItaliano: "San Giorgio Morgeto",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H889"
      },
      {
        denominazioneInItaliano: "San Giovanni di Gerace",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H903"
      },
      {
        denominazioneInItaliano: "San Lorenzo",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H959"
      },
      {
        denominazioneInItaliano: "San Luca",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "H970"
      },
      {
        denominazioneInItaliano: "San Pietro di Caridà",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I102"
      },
      {
        denominazioneInItaliano: "San Procopio",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I132"
      },
      {
        denominazioneInItaliano: "San Roberto",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I139"
      },
      {
        denominazioneInItaliano: "Santa Cristina d'Aspromonte",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I176"
      },
      {
        denominazioneInItaliano: "Sant'Agata del Bianco",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I198"
      },
      {
        denominazioneInItaliano: "Sant'Alessio in Aspromonte",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I214"
      },
      {
        denominazioneInItaliano: "Sant'Eufemia d'Aspromonte",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I333"
      },
      {
        denominazioneInItaliano: "Sant'Ilario dello Ionio",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I341"
      },
      {
        denominazioneInItaliano: "Santo Stefano in Aspromonte",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I371"
      },
      {
        denominazioneInItaliano: "Scido",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I536"
      },
      {
        denominazioneInItaliano: "Scilla",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I537"
      },
      {
        denominazioneInItaliano: "Seminara",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I600"
      },
      {
        denominazioneInItaliano: "Serrata",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I656"
      },
      {
        denominazioneInItaliano: "Siderno",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I725"
      },
      {
        denominazioneInItaliano: "Sinopoli",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I753"
      },
      {
        denominazioneInItaliano: "Staiti",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I936"
      },
      {
        denominazioneInItaliano: "Stignano",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I955"
      },
      {
        denominazioneInItaliano: "Stilo",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "I956"
      },
      {
        denominazioneInItaliano: "Taurianova",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "L063"
      },
      {
        denominazioneInItaliano: "Terranova Sappo Minulio",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "L127"
      },
      {
        denominazioneInItaliano: "Varapodio",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "L673"
      },
      {
        denominazioneInItaliano: "Villa San Giovanni",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "M018"
      },
      {
        denominazioneInItaliano: "San Ferdinando",
        regione: "Calabria",
        provincia: "Reggio Calabria",
        codiceCatastale: "M277"
      },
      {
        denominazioneInItaliano: "Belvedere di Spinello",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "A772"
      },
      {
        denominazioneInItaliano: "Caccuri",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "B319"
      },
      {
        denominazioneInItaliano: "Carfizzi",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "B771"
      },
      {
        denominazioneInItaliano: "Casabona",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "B857"
      },
      {
        denominazioneInItaliano: "Castelsilano",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "B968"
      },
      {
        denominazioneInItaliano: "Cerenzia",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "C501"
      },
      {
        denominazioneInItaliano: "Cirò",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "C725"
      },
      {
        denominazioneInItaliano: "Cirò Marina",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "C726"
      },
      {
        denominazioneInItaliano: "Cotronei",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "D123"
      },
      {
        denominazioneInItaliano: "Crotone",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "D122"
      },
      {
        denominazioneInItaliano: "Crucoli",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "D189"
      },
      {
        denominazioneInItaliano: "Cutro",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "D236"
      },
      {
        denominazioneInItaliano: "Isola di Capo Rizzuto",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "E339"
      },
      {
        denominazioneInItaliano: "Melissa",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "F108"
      },
      {
        denominazioneInItaliano: "Mesoraca",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "F157"
      },
      {
        denominazioneInItaliano: "Pallagorio",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "G278"
      },
      {
        denominazioneInItaliano: "Petilia Policastro",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "G508"
      },
      {
        denominazioneInItaliano: "Roccabernarda",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "H383"
      },
      {
        denominazioneInItaliano: "Rocca di Neto",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "H403"
      },
      {
        denominazioneInItaliano: "San Mauro Marchesato",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "I026"
      },
      {
        denominazioneInItaliano: "San Nicola dell'Alto",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "I057"
      },
      {
        denominazioneInItaliano: "Santa Severina",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "I308"
      },
      {
        denominazioneInItaliano: "Savelli",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "I468"
      },
      {
        denominazioneInItaliano: "Scandale",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "I494"
      },
      {
        denominazioneInItaliano: "Strongoli",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "I982"
      },
      {
        denominazioneInItaliano: "Umbriatico",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "L492"
      },
      {
        denominazioneInItaliano: "Verzino",
        regione: "Calabria",
        provincia: "Crotone",
        codiceCatastale: "L802"
      },
      {
        denominazioneInItaliano: "Acquaro",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "A043"
      },
      {
        denominazioneInItaliano: "Arena",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "A386"
      },
      {
        denominazioneInItaliano: "Briatico",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "B169"
      },
      {
        denominazioneInItaliano: "Brognaturo",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "B197"
      },
      {
        denominazioneInItaliano: "Capistrano",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "B655"
      },
      {
        denominazioneInItaliano: "Cessaniti",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "C581"
      },
      {
        denominazioneInItaliano: "Dasà",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D253"
      },
      {
        denominazioneInItaliano: "Dinami",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D303"
      },
      {
        denominazioneInItaliano: "Drapia",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D364"
      },
      {
        denominazioneInItaliano: "Fabrizia",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D453"
      },
      {
        denominazioneInItaliano: "Filadelfia",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D587"
      },
      {
        denominazioneInItaliano: "Filandari",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D589"
      },
      {
        denominazioneInItaliano: "Filogaso",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D596"
      },
      {
        denominazioneInItaliano: "Francavilla Angitola",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D762"
      },
      {
        denominazioneInItaliano: "Francica",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D767"
      },
      {
        denominazioneInItaliano: "Gerocarne",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "D988"
      },
      {
        denominazioneInItaliano: "Ionadi",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "E321"
      },
      {
        denominazioneInItaliano: "Joppolo",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "E389"
      },
      {
        denominazioneInItaliano: "Limbadi",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "E590"
      },
      {
        denominazioneInItaliano: "Maierato",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "E836"
      },
      {
        denominazioneInItaliano: "Mileto",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "F207"
      },
      {
        denominazioneInItaliano: "Mongiana",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "F364"
      },
      {
        denominazioneInItaliano: "Monterosso Calabro",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "F607"
      },
      {
        denominazioneInItaliano: "Nardodipace",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "F843"
      },
      {
        denominazioneInItaliano: "Nicotera",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "F893"
      },
      {
        denominazioneInItaliano: "Parghelia",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "G335"
      },
      {
        denominazioneInItaliano: "Pizzo",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "G722"
      },
      {
        denominazioneInItaliano: "Pizzoni",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "G728"
      },
      {
        denominazioneInItaliano: "Polia",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "G785"
      },
      {
        denominazioneInItaliano: "Ricadi",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "H271"
      },
      {
        denominazioneInItaliano: "Rombiolo",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "H516"
      },
      {
        denominazioneInItaliano: "San Calogero",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "H785"
      },
      {
        denominazioneInItaliano: "San Costantino Calabro",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "H807"
      },
      {
        denominazioneInItaliano: "San Gregorio d'Ippona",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "H941"
      },
      {
        denominazioneInItaliano: "San Nicola da Crissa",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I058"
      },
      {
        denominazioneInItaliano: "Sant'Onofrio",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I350"
      },
      {
        denominazioneInItaliano: "Serra San Bruno",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I639"
      },
      {
        denominazioneInItaliano: "Simbario",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I744"
      },
      {
        denominazioneInItaliano: "Sorianello",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I853"
      },
      {
        denominazioneInItaliano: "Soriano Calabro",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I854"
      },
      {
        denominazioneInItaliano: "Spadola",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I884"
      },
      {
        denominazioneInItaliano: "Spilinga",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I905"
      },
      {
        denominazioneInItaliano: "Stefanaconi",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "I945"
      },
      {
        denominazioneInItaliano: "Tropea",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "L452"
      },
      {
        denominazioneInItaliano: "Vallelonga",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "L607"
      },
      {
        denominazioneInItaliano: "Vazzano",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "L699"
      },
      {
        denominazioneInItaliano: "Vibo Valentia",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "F537"
      },
      {
        denominazioneInItaliano: "Zaccanopoli",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "M138"
      },
      {
        denominazioneInItaliano: "Zambrone",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "M143"
      },
      {
        denominazioneInItaliano: "Zungri",
        regione: "Calabria",
        provincia: "Vibo Valentia",
        codiceCatastale: "M204"
      },
      {
        denominazioneInItaliano: "Alcamo",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "A176"
      },
      {
        denominazioneInItaliano: "Buseto Palizzolo",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "B288"
      },
      {
        denominazioneInItaliano: "Calatafimi-Segesta",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "B385"
      },
      {
        denominazioneInItaliano: "Campobello di Mazara",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "B521"
      },
      {
        denominazioneInItaliano: "Castellammare del Golfo",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "C130"
      },
      {
        denominazioneInItaliano: "Castelvetrano",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "C286"
      },
      {
        denominazioneInItaliano: "Custonaci",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "D234"
      },
      {
        denominazioneInItaliano: "Erice",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "D423"
      },
      {
        denominazioneInItaliano: "Favignana",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "D518"
      },
      {
        denominazioneInItaliano: "Gibellina",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "E023"
      },
      {
        denominazioneInItaliano: "Marsala",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "E974"
      },
      {
        denominazioneInItaliano: "Mazara del Vallo",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "F061"
      },
      {
        denominazioneInItaliano: "Paceco",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "G208"
      },
      {
        denominazioneInItaliano: "Pantelleria",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "G315"
      },
      {
        denominazioneInItaliano: "Partanna",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "G347"
      },
      {
        denominazioneInItaliano: "Poggioreale",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "G767"
      },
      {
        denominazioneInItaliano: "Salaparuta",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "H688"
      },
      {
        denominazioneInItaliano: "Salemi",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "H700"
      },
      {
        denominazioneInItaliano: "Santa Ninfa",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "I291"
      },
      {
        denominazioneInItaliano: "San Vito Lo Capo",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "I407"
      },
      {
        denominazioneInItaliano: "Trapani",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "L331"
      },
      {
        denominazioneInItaliano: "Valderice",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "G319"
      },
      {
        denominazioneInItaliano: "Vita",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "M081"
      },
      {
        denominazioneInItaliano: "Petrosino",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "M281"
      },
      {
        denominazioneInItaliano: "Misiliscemi",
        regione: "Sicilia",
        provincia: "Trapani",
        codiceCatastale: "M432"
      },
      {
        denominazioneInItaliano: "Alia",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A195"
      },
      {
        denominazioneInItaliano: "Alimena",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A202"
      },
      {
        denominazioneInItaliano: "Aliminusa",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A203"
      },
      {
        denominazioneInItaliano: "Altavilla Milicia",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A229"
      },
      {
        denominazioneInItaliano: "Altofonte",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A239"
      },
      {
        denominazioneInItaliano: "Bagheria",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A546"
      },
      {
        denominazioneInItaliano: "Balestrate",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A592"
      },
      {
        denominazioneInItaliano: "Baucina",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A719"
      },
      {
        denominazioneInItaliano: "Belmonte Mezzagno",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A764"
      },
      {
        denominazioneInItaliano: "Bisacquino",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A882"
      },
      {
        denominazioneInItaliano: "Bolognetta",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A946"
      },
      {
        denominazioneInItaliano: "Bompietro",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A958"
      },
      {
        denominazioneInItaliano: "Borgetto",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "A991"
      },
      {
        denominazioneInItaliano: "Caccamo",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "B315"
      },
      {
        denominazioneInItaliano: "Caltavuturo",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "B430"
      },
      {
        denominazioneInItaliano: "Campofelice di Fitalia",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "B533"
      },
      {
        denominazioneInItaliano: "Campofelice di Roccella",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "B532"
      },
      {
        denominazioneInItaliano: "Campofiorito",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "B535"
      },
      {
        denominazioneInItaliano: "Camporeale",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "B556"
      },
      {
        denominazioneInItaliano: "Capaci",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "B645"
      },
      {
        denominazioneInItaliano: "Carini",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "B780"
      },
      {
        denominazioneInItaliano: "Castelbuono",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C067"
      },
      {
        denominazioneInItaliano: "Casteldaccia",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C074"
      },
      {
        denominazioneInItaliano: "Castellana Sicula",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C135"
      },
      {
        denominazioneInItaliano: "Castronovo di Sicilia",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C344"
      },
      {
        denominazioneInItaliano: "Cefalà Diana",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C420"
      },
      {
        denominazioneInItaliano: "Cefalù",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C421"
      },
      {
        denominazioneInItaliano: "Cerda",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C496"
      },
      {
        denominazioneInItaliano: "Chiusa Sclafani",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C654"
      },
      {
        denominazioneInItaliano: "Ciminna",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C696"
      },
      {
        denominazioneInItaliano: "Cinisi",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C708"
      },
      {
        denominazioneInItaliano: "Collesano",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C871"
      },
      {
        denominazioneInItaliano: "Contessa Entellina",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "C968"
      },
      {
        denominazioneInItaliano: "Corleone",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "D009"
      },
      {
        denominazioneInItaliano: "Ficarazzi",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "D567"
      },
      {
        denominazioneInItaliano: "Gangi",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "D907"
      },
      {
        denominazioneInItaliano: "Geraci Siculo",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "D977"
      },
      {
        denominazioneInItaliano: "Giardinello",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E013"
      },
      {
        denominazioneInItaliano: "Giuliana",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E055"
      },
      {
        denominazioneInItaliano: "Godrano",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E074"
      },
      {
        denominazioneInItaliano: "Gratteri",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E149"
      },
      {
        denominazioneInItaliano: "Isnello",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E337"
      },
      {
        denominazioneInItaliano: "Isola delle Femmine",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E350"
      },
      {
        denominazioneInItaliano: "Lascari",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E459"
      },
      {
        denominazioneInItaliano: "Lercara Friddi",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E541"
      },
      {
        denominazioneInItaliano: "Marineo",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "E957"
      },
      {
        denominazioneInItaliano: "Mezzojuso",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "F184"
      },
      {
        denominazioneInItaliano: "Misilmeri",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "F246"
      },
      {
        denominazioneInItaliano: "Monreale",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "F377"
      },
      {
        denominazioneInItaliano: "Montelepre",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "F544"
      },
      {
        denominazioneInItaliano: "Montemaggiore Belsito",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "F553"
      },
      {
        denominazioneInItaliano: "Palazzo Adriano",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "G263"
      },
      {
        denominazioneInItaliano: "Palermo",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "G273"
      },
      {
        denominazioneInItaliano: "Partinico",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "G348"
      },
      {
        denominazioneInItaliano: "Petralia Soprana",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "G510"
      },
      {
        denominazioneInItaliano: "Petralia Sottana",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "G511"
      },
      {
        denominazioneInItaliano: "Piana degli Albanesi",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "G543"
      },
      {
        denominazioneInItaliano: "Polizzi Generosa",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "G792"
      },
      {
        denominazioneInItaliano: "Pollina",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "G797"
      },
      {
        denominazioneInItaliano: "Prizzi",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "H070"
      },
      {
        denominazioneInItaliano: "Roccamena",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "H422"
      },
      {
        denominazioneInItaliano: "Roccapalumba",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "H428"
      },
      {
        denominazioneInItaliano: "San Cipirello",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "H797"
      },
      {
        denominazioneInItaliano: "San Giuseppe Jato",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "H933"
      },
      {
        denominazioneInItaliano: "San Mauro Castelverde",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "I028"
      },
      {
        denominazioneInItaliano: "Santa Cristina Gela",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "I174"
      },
      {
        denominazioneInItaliano: "Santa Flavia",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "I188"
      },
      {
        denominazioneInItaliano: "Sciara",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "I534"
      },
      {
        denominazioneInItaliano: "Sclafani Bagni",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "I541"
      },
      {
        denominazioneInItaliano: "Termini Imerese",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L112"
      },
      {
        denominazioneInItaliano: "Terrasini",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L131"
      },
      {
        denominazioneInItaliano: "Torretta",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L282"
      },
      {
        denominazioneInItaliano: "Trabia",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L317"
      },
      {
        denominazioneInItaliano: "Trappeto",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L332"
      },
      {
        denominazioneInItaliano: "Ustica",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L519"
      },
      {
        denominazioneInItaliano: "Valledolmo",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L603"
      },
      {
        denominazioneInItaliano: "Ventimiglia di Sicilia",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L740"
      },
      {
        denominazioneInItaliano: "Vicari",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L837"
      },
      {
        denominazioneInItaliano: "Villabate",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L916"
      },
      {
        denominazioneInItaliano: "Villafrati",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "L951"
      },
      {
        denominazioneInItaliano: "Scillato",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "I538"
      },
      {
        denominazioneInItaliano: "Blufi",
        regione: "Sicilia",
        provincia: "Palermo",
        codiceCatastale: "M268"
      },
      {
        denominazioneInItaliano: "Alcara li Fusi",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "A177"
      },
      {
        denominazioneInItaliano: "Alì",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "A194"
      },
      {
        denominazioneInItaliano: "Alì Terme",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "A201"
      },
      {
        denominazioneInItaliano: "Antillo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "A313"
      },
      {
        denominazioneInItaliano: "Barcellona Pozzo di Gotto",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "A638"
      },
      {
        denominazioneInItaliano: "Basicò",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "A698"
      },
      {
        denominazioneInItaliano: "Brolo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "B198"
      },
      {
        denominazioneInItaliano: "Capizzi",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "B660"
      },
      {
        denominazioneInItaliano: "Capo d'Orlando",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "B666"
      },
      {
        denominazioneInItaliano: "Capri Leone",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "B695"
      },
      {
        denominazioneInItaliano: "Caronia",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "B804"
      },
      {
        denominazioneInItaliano: "Casalvecchio Siculo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "B918"
      },
      {
        denominazioneInItaliano: "Castel di Lucio",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "C094"
      },
      {
        denominazioneInItaliano: "Castell'Umberto",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "C051"
      },
      {
        denominazioneInItaliano: "Castelmola",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "C210"
      },
      {
        denominazioneInItaliano: "Castroreale",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "C347"
      },
      {
        denominazioneInItaliano: "Cesarò",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "C568"
      },
      {
        denominazioneInItaliano: "Condrò",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "C956"
      },
      {
        denominazioneInItaliano: "Falcone",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D474"
      },
      {
        denominazioneInItaliano: "Ficarra",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D569"
      },
      {
        denominazioneInItaliano: "Fiumedinisi",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D622"
      },
      {
        denominazioneInItaliano: "Floresta",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D635"
      },
      {
        denominazioneInItaliano: "Fondachelli-Fantina",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D661"
      },
      {
        denominazioneInItaliano: "Forza d'Agrò",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D733"
      },
      {
        denominazioneInItaliano: "Francavilla di Sicilia",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D765"
      },
      {
        denominazioneInItaliano: "Frazzanò",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D793"
      },
      {
        denominazioneInItaliano: "Furci Siculo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D824"
      },
      {
        denominazioneInItaliano: "Furnari",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D825"
      },
      {
        denominazioneInItaliano: "Gaggi",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D844"
      },
      {
        denominazioneInItaliano: "Galati Mamertino",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D861"
      },
      {
        denominazioneInItaliano: "Gallodoro",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "D885"
      },
      {
        denominazioneInItaliano: "Giardini-Naxos",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E014"
      },
      {
        denominazioneInItaliano: "Gioiosa Marea",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E043"
      },
      {
        denominazioneInItaliano: "Graniti",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E142"
      },
      {
        denominazioneInItaliano: "Gualtieri Sicaminò",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E233"
      },
      {
        denominazioneInItaliano: "Itala",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E374"
      },
      {
        denominazioneInItaliano: "Leni",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E523"
      },
      {
        denominazioneInItaliano: "Letojanni",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E555"
      },
      {
        denominazioneInItaliano: "Librizzi",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E571"
      },
      {
        denominazioneInItaliano: "Limina",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E594"
      },
      {
        denominazioneInItaliano: "Lipari",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E606"
      },
      {
        denominazioneInItaliano: "Longi",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E674"
      },
      {
        denominazioneInItaliano: "Malfa",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E855"
      },
      {
        denominazioneInItaliano: "Malvagna",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E869"
      },
      {
        denominazioneInItaliano: "Mandanici",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "E876"
      },
      {
        denominazioneInItaliano: "Mazzarrà Sant'Andrea",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F066"
      },
      {
        denominazioneInItaliano: "Merì",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F147"
      },
      {
        denominazioneInItaliano: "Messina",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F158"
      },
      {
        denominazioneInItaliano: "Milazzo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F206"
      },
      {
        denominazioneInItaliano: "Militello Rosmarino",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F210"
      },
      {
        denominazioneInItaliano: "Mirto",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F242"
      },
      {
        denominazioneInItaliano: "Mistretta",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F251"
      },
      {
        denominazioneInItaliano: "Moio Alcantara",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F277"
      },
      {
        denominazioneInItaliano: "Monforte San Giorgio",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F359"
      },
      {
        denominazioneInItaliano: "Mongiuffi Melia",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F368"
      },
      {
        denominazioneInItaliano: "Montagnareale",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F395"
      },
      {
        denominazioneInItaliano: "Montalbano Elicona",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F400"
      },
      {
        denominazioneInItaliano: "Motta Camastra",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F772"
      },
      {
        denominazioneInItaliano: "Motta d'Affermo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F773"
      },
      {
        denominazioneInItaliano: "Naso",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F848"
      },
      {
        denominazioneInItaliano: "Nizza di Sicilia",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F901"
      },
      {
        denominazioneInItaliano: "Novara di Sicilia",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "F951"
      },
      {
        denominazioneInItaliano: "Oliveri",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "G036"
      },
      {
        denominazioneInItaliano: "Pace del Mela",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "G209"
      },
      {
        denominazioneInItaliano: "Pagliara",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "G234"
      },
      {
        denominazioneInItaliano: "Patti",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "G377"
      },
      {
        denominazioneInItaliano: "Pettineo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "G522"
      },
      {
        denominazioneInItaliano: "Piraino",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "G699"
      },
      {
        denominazioneInItaliano: "Raccuja",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H151"
      },
      {
        denominazioneInItaliano: "Reitano",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H228"
      },
      {
        denominazioneInItaliano: "Roccafiorita",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H405"
      },
      {
        denominazioneInItaliano: "Roccalumera",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H418"
      },
      {
        denominazioneInItaliano: "Roccavaldina",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H380"
      },
      {
        denominazioneInItaliano: "Roccella Valdemone",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H455"
      },
      {
        denominazioneInItaliano: "Rodì Milici",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H479"
      },
      {
        denominazioneInItaliano: "Rometta",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H519"
      },
      {
        denominazioneInItaliano: "San Filippo del Mela",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H842"
      },
      {
        denominazioneInItaliano: "San Fratello",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H850"
      },
      {
        denominazioneInItaliano: "San Marco d'Alunzio",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "H982"
      },
      {
        denominazioneInItaliano: "San Pier Niceto",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I084"
      },
      {
        denominazioneInItaliano: "San Piero Patti",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I086"
      },
      {
        denominazioneInItaliano: "San Salvatore di Fitalia",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I147"
      },
      {
        denominazioneInItaliano: "Santa Domenica Vittoria",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I184"
      },
      {
        denominazioneInItaliano: "Sant'Agata di Militello",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I199"
      },
      {
        denominazioneInItaliano: "Sant'Alessio Siculo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I215"
      },
      {
        denominazioneInItaliano: "Santa Lucia del Mela",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I220"
      },
      {
        denominazioneInItaliano: "Santa Marina Salina",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I254"
      },
      {
        denominazioneInItaliano: "Sant'Angelo di Brolo",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I283"
      },
      {
        denominazioneInItaliano: "Santa Teresa di Riva",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I311"
      },
      {
        denominazioneInItaliano: "San Teodoro",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I328"
      },
      {
        denominazioneInItaliano: "Santo Stefano di Camastra",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I370"
      },
      {
        denominazioneInItaliano: "Saponara",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I420"
      },
      {
        denominazioneInItaliano: "Savoca",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I477"
      },
      {
        denominazioneInItaliano: "Scaletta Zanclea",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I492"
      },
      {
        denominazioneInItaliano: "Sinagra",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I747"
      },
      {
        denominazioneInItaliano: "Spadafora",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "I881"
      },
      {
        denominazioneInItaliano: "Taormina",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L042"
      },
      {
        denominazioneInItaliano: "Torregrotta",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L271"
      },
      {
        denominazioneInItaliano: "Tortorici",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L308"
      },
      {
        denominazioneInItaliano: "Tripi",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L431"
      },
      {
        denominazioneInItaliano: "Tusa",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L478"
      },
      {
        denominazioneInItaliano: "Ucria",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L482"
      },
      {
        denominazioneInItaliano: "Valdina",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L561"
      },
      {
        denominazioneInItaliano: "Venetico",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L735"
      },
      {
        denominazioneInItaliano: "Villafranca Tirrena",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "L950"
      },
      {
        denominazioneInItaliano: "Terme Vigliatore",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "M210"
      },
      {
        denominazioneInItaliano: "Acquedolci",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "M211"
      },
      {
        denominazioneInItaliano: "Torrenova",
        regione: "Sicilia",
        provincia: "Messina",
        codiceCatastale: "M286"
      },
      {
        denominazioneInItaliano: "Agrigento",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "A089"
      },
      {
        denominazioneInItaliano: "Alessandria della Rocca",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "A181"
      },
      {
        denominazioneInItaliano: "Aragona",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "A351"
      },
      {
        denominazioneInItaliano: "Bivona",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "A896"
      },
      {
        denominazioneInItaliano: "Burgio",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "B275"
      },
      {
        denominazioneInItaliano: "Calamonaci",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "B377"
      },
      {
        denominazioneInItaliano: "Caltabellotta",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "B427"
      },
      {
        denominazioneInItaliano: "Camastra",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "B460"
      },
      {
        denominazioneInItaliano: "Cammarata",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "B486"
      },
      {
        denominazioneInItaliano: "Campobello di Licata",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "B520"
      },
      {
        denominazioneInItaliano: "Canicattì",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "B602"
      },
      {
        denominazioneInItaliano: "Casteltermini",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "C275"
      },
      {
        denominazioneInItaliano: "Castrofilippo",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "C341"
      },
      {
        denominazioneInItaliano: "Cattolica Eraclea",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "C356"
      },
      {
        denominazioneInItaliano: "Cianciana",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "C668"
      },
      {
        denominazioneInItaliano: "Comitini",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "C928"
      },
      {
        denominazioneInItaliano: "Favara",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "D514"
      },
      {
        denominazioneInItaliano: "Grotte",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "E209"
      },
      {
        denominazioneInItaliano: "Joppolo Giancaxio",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "E390"
      },
      {
        denominazioneInItaliano: "Lampedusa e Linosa",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "E431"
      },
      {
        denominazioneInItaliano: "Licata",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "E573"
      },
      {
        denominazioneInItaliano: "Lucca Sicula",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "E714"
      },
      {
        denominazioneInItaliano: "Menfi",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "F126"
      },
      {
        denominazioneInItaliano: "Montallegro",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "F414"
      },
      {
        denominazioneInItaliano: "Montevago",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "F655"
      },
      {
        denominazioneInItaliano: "Naro",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "F845"
      },
      {
        denominazioneInItaliano: "Palma di Montechiaro",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "G282"
      },
      {
        denominazioneInItaliano: "Porto Empedocle",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "F299"
      },
      {
        denominazioneInItaliano: "Racalmuto",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "H148"
      },
      {
        denominazioneInItaliano: "Raffadali",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "H159"
      },
      {
        denominazioneInItaliano: "Ravanusa",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "H194"
      },
      {
        denominazioneInItaliano: "Realmonte",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "H205"
      },
      {
        denominazioneInItaliano: "Ribera",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "H269"
      },
      {
        denominazioneInItaliano: "Sambuca di Sicilia",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "H743"
      },
      {
        denominazioneInItaliano: "San Biagio Platani",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "H778"
      },
      {
        denominazioneInItaliano: "San Giovanni Gemini",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "H914"
      },
      {
        denominazioneInItaliano: "Santa Elisabetta",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "I185"
      },
      {
        denominazioneInItaliano: "Santa Margherita di Belice",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "I224"
      },
      {
        denominazioneInItaliano: "Sant'Angelo Muxaro",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "I290"
      },
      {
        denominazioneInItaliano: "Santo Stefano Quisquina",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "I356"
      },
      {
        denominazioneInItaliano: "Sciacca",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "I533"
      },
      {
        denominazioneInItaliano: "Siculiana",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "I723"
      },
      {
        denominazioneInItaliano: "Villafranca Sicula",
        regione: "Sicilia",
        provincia: "Agrigento",
        codiceCatastale: "L944"
      },
      {
        denominazioneInItaliano: "Acquaviva Platani",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "A049"
      },
      {
        denominazioneInItaliano: "Bompensiere",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "A957"
      },
      {
        denominazioneInItaliano: "Butera",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "B302"
      },
      {
        denominazioneInItaliano: "Caltanissetta",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "B429"
      },
      {
        denominazioneInItaliano: "Campofranco",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "B537"
      },
      {
        denominazioneInItaliano: "Delia",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "D267"
      },
      {
        denominazioneInItaliano: "Gela",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "D960"
      },
      {
        denominazioneInItaliano: "Marianopoli",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "E953"
      },
      {
        denominazioneInItaliano: "Mazzarino",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "F065"
      },
      {
        denominazioneInItaliano: "Milena",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "E618"
      },
      {
        denominazioneInItaliano: "Montedoro",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "F489"
      },
      {
        denominazioneInItaliano: "Mussomeli",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "F830"
      },
      {
        denominazioneInItaliano: "Niscemi",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "F899"
      },
      {
        denominazioneInItaliano: "Resuttano",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "H245"
      },
      {
        denominazioneInItaliano: "Riesi",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "H281"
      },
      {
        denominazioneInItaliano: "San Cataldo",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "H792"
      },
      {
        denominazioneInItaliano: "Santa Caterina Villarmosa",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "I169"
      },
      {
        denominazioneInItaliano: "Serradifalco",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "I644"
      },
      {
        denominazioneInItaliano: "Sommatino",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "I824"
      },
      {
        denominazioneInItaliano: "Sutera",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "L016"
      },
      {
        denominazioneInItaliano: "Vallelunga Pratameno",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "L609"
      },
      {
        denominazioneInItaliano: "Villalba",
        regione: "Sicilia",
        provincia: "Caltanissetta",
        codiceCatastale: "L959"
      },
      {
        denominazioneInItaliano: "Agira",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "A070"
      },
      {
        denominazioneInItaliano: "Aidone",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "A098"
      },
      {
        denominazioneInItaliano: "Assoro",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "A478"
      },
      {
        denominazioneInItaliano: "Barrafranca",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "A676"
      },
      {
        denominazioneInItaliano: "Calascibetta",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "B381"
      },
      {
        denominazioneInItaliano: "Catenanuova",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "C353"
      },
      {
        denominazioneInItaliano: "Centuripe",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "C471"
      },
      {
        denominazioneInItaliano: "Cerami",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "C480"
      },
      {
        denominazioneInItaliano: "Enna",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "C342"
      },
      {
        denominazioneInItaliano: "Gagliano Castelferrato",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "D849"
      },
      {
        denominazioneInItaliano: "Leonforte",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "E536"
      },
      {
        denominazioneInItaliano: "Nicosia",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "F892"
      },
      {
        denominazioneInItaliano: "Nissoria",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "F900"
      },
      {
        denominazioneInItaliano: "Piazza Armerina",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "G580"
      },
      {
        denominazioneInItaliano: "Pietraperzia",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "G624"
      },
      {
        denominazioneInItaliano: "Regalbuto",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "H221"
      },
      {
        denominazioneInItaliano: "Sperlinga",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "I891"
      },
      {
        denominazioneInItaliano: "Troina",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "L448"
      },
      {
        denominazioneInItaliano: "Valguarnera Caropepe",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "L583"
      },
      {
        denominazioneInItaliano: "Villarosa",
        regione: "Sicilia",
        provincia: "Enna",
        codiceCatastale: "M011"
      },
      {
        denominazioneInItaliano: "Aci Bonaccorsi",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "A025"
      },
      {
        denominazioneInItaliano: "Aci Castello",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "A026"
      },
      {
        denominazioneInItaliano: "Aci Catena",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "A027"
      },
      {
        denominazioneInItaliano: "Acireale",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "A028"
      },
      {
        denominazioneInItaliano: "Aci Sant'Antonio",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "A029"
      },
      {
        denominazioneInItaliano: "Adrano",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "A056"
      },
      {
        denominazioneInItaliano: "Belpasso",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "A766"
      },
      {
        denominazioneInItaliano: "Biancavilla",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "A841"
      },
      {
        denominazioneInItaliano: "Bronte",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "B202"
      },
      {
        denominazioneInItaliano: "Calatabiano",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "B384"
      },
      {
        denominazioneInItaliano: "Caltagirone",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "B428"
      },
      {
        denominazioneInItaliano: "Camporotondo Etneo",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "B561"
      },
      {
        denominazioneInItaliano: "Castel di Iudica",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "C091"
      },
      {
        denominazioneInItaliano: "Castiglione di Sicilia",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "C297"
      },
      {
        denominazioneInItaliano: "Catania",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "C351"
      },
      {
        denominazioneInItaliano: "Fiumefreddo di Sicilia",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "D623"
      },
      {
        denominazioneInItaliano: "Giarre",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "E017"
      },
      {
        denominazioneInItaliano: "Grammichele",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "E133"
      },
      {
        denominazioneInItaliano: "Gravina di Catania",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "E156"
      },
      {
        denominazioneInItaliano: "Licodia Eubea",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "E578"
      },
      {
        denominazioneInItaliano: "Linguaglossa",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "E602"
      },
      {
        denominazioneInItaliano: "Maletto",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "E854"
      },
      {
        denominazioneInItaliano: "Mascali",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F004"
      },
      {
        denominazioneInItaliano: "Mascalucia",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F005"
      },
      {
        denominazioneInItaliano: "Militello in Val di Catania",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F209"
      },
      {
        denominazioneInItaliano: "Milo",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F214"
      },
      {
        denominazioneInItaliano: "Mineo",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F217"
      },
      {
        denominazioneInItaliano: "Mirabella Imbaccari",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F231"
      },
      {
        denominazioneInItaliano: "Misterbianco",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F250"
      },
      {
        denominazioneInItaliano: "Motta Sant'Anastasia",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F781"
      },
      {
        denominazioneInItaliano: "Nicolosi",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "F890"
      },
      {
        denominazioneInItaliano: "Palagonia",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "G253"
      },
      {
        denominazioneInItaliano: "Paternò",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "G371"
      },
      {
        denominazioneInItaliano: "Pedara",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "G402"
      },
      {
        denominazioneInItaliano: "Piedimonte Etneo",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "G597"
      },
      {
        denominazioneInItaliano: "Raddusa",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "H154"
      },
      {
        denominazioneInItaliano: "Ramacca",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "H168"
      },
      {
        denominazioneInItaliano: "Randazzo",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "H175"
      },
      {
        denominazioneInItaliano: "Riposto",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "H325"
      },
      {
        denominazioneInItaliano: "San Cono",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "H805"
      },
      {
        denominazioneInItaliano: "San Giovanni la Punta",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "H922"
      },
      {
        denominazioneInItaliano: "San Gregorio di Catania",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "H940"
      },
      {
        denominazioneInItaliano: "San Michele di Ganzaria",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "I035"
      },
      {
        denominazioneInItaliano: "San Pietro Clarenza",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "I098"
      },
      {
        denominazioneInItaliano: "Sant'Agata li Battiati",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "I202"
      },
      {
        denominazioneInItaliano: "Sant'Alfio",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "I216"
      },
      {
        denominazioneInItaliano: "Santa Maria di Licodia",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "I240"
      },
      {
        denominazioneInItaliano: "Santa Venerina",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "I314"
      },
      {
        denominazioneInItaliano: "Scordia",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "I548"
      },
      {
        denominazioneInItaliano: "Trecastagni",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "L355"
      },
      {
        denominazioneInItaliano: "Tremestieri Etneo",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "L369"
      },
      {
        denominazioneInItaliano: "Valverde",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "L658"
      },
      {
        denominazioneInItaliano: "Viagrande",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "L828"
      },
      {
        denominazioneInItaliano: "Vizzini",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "M100"
      },
      {
        denominazioneInItaliano: "Zafferana Etnea",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "M139"
      },
      {
        denominazioneInItaliano: "Mazzarrone",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "M271"
      },
      {
        denominazioneInItaliano: "Maniace",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "M283"
      },
      {
        denominazioneInItaliano: "Ragalna",
        regione: "Sicilia",
        provincia: "Catania",
        codiceCatastale: "M287"
      },
      {
        denominazioneInItaliano: "Acate",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "A014"
      },
      {
        denominazioneInItaliano: "Chiaramonte Gulfi",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "C612"
      },
      {
        denominazioneInItaliano: "Comiso",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "C927"
      },
      {
        denominazioneInItaliano: "Giarratana",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "E016"
      },
      {
        denominazioneInItaliano: "Ispica",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "E366"
      },
      {
        denominazioneInItaliano: "Modica",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "F258"
      },
      {
        denominazioneInItaliano: "Monterosso Almo",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "F610"
      },
      {
        denominazioneInItaliano: "Pozzallo",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "G953"
      },
      {
        denominazioneInItaliano: "Ragusa",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "H163"
      },
      {
        denominazioneInItaliano: "Santa Croce Camerina",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "I178"
      },
      {
        denominazioneInItaliano: "Scicli",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "I535"
      },
      {
        denominazioneInItaliano: "Vittoria",
        regione: "Sicilia",
        provincia: "Ragusa",
        codiceCatastale: "M088"
      },
      {
        denominazioneInItaliano: "Augusta",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "A494"
      },
      {
        denominazioneInItaliano: "Avola",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "A522"
      },
      {
        denominazioneInItaliano: "Buccheri",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "B237"
      },
      {
        denominazioneInItaliano: "Buscemi",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "B287"
      },
      {
        denominazioneInItaliano: "Canicattini Bagni",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "B603"
      },
      {
        denominazioneInItaliano: "Carlentini",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "B787"
      },
      {
        denominazioneInItaliano: "Cassaro",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "C006"
      },
      {
        denominazioneInItaliano: "Ferla",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "D540"
      },
      {
        denominazioneInItaliano: "Floridia",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "D636"
      },
      {
        denominazioneInItaliano: "Francofonte",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "D768"
      },
      {
        denominazioneInItaliano: "Lentini",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "E532"
      },
      {
        denominazioneInItaliano: "Melilli",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "F107"
      },
      {
        denominazioneInItaliano: "Noto",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "F943"
      },
      {
        denominazioneInItaliano: "Pachino",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "G211"
      },
      {
        denominazioneInItaliano: "Palazzolo Acreide",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "G267"
      },
      {
        denominazioneInItaliano: "Rosolini",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "H574"
      },
      {
        denominazioneInItaliano: "Siracusa",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "I754"
      },
      {
        denominazioneInItaliano: "Solarino",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "I785"
      },
      {
        denominazioneInItaliano: "Sortino",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "I864"
      },
      {
        denominazioneInItaliano: "Portopalo di Capo Passero",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "M257"
      },
      {
        denominazioneInItaliano: "Priolo Gargallo",
        regione: "Sicilia",
        provincia: "Siracusa",
        codiceCatastale: "M279"
      },
      {
        denominazioneInItaliano: "Aggius",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A069"
      },
      {
        denominazioneInItaliano: "Alà dei Sardi",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A115"
      },
      {
        denominazioneInItaliano: "Alghero",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A192"
      },
      {
        denominazioneInItaliano: "Anela",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A287"
      },
      {
        denominazioneInItaliano: "Ardara",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A379"
      },
      {
        denominazioneInItaliano: "Arzachena",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A453"
      },
      {
        denominazioneInItaliano: "Banari",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A606"
      },
      {
        denominazioneInItaliano: "Benetutti",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A781"
      },
      {
        denominazioneInItaliano: "Berchidda",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A789"
      },
      {
        denominazioneInItaliano: "Bessude",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A827"
      },
      {
        denominazioneInItaliano: "Bonnanaro",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A976"
      },
      {
        denominazioneInItaliano: "Bono",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A977"
      },
      {
        denominazioneInItaliano: "Bonorva",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "A978"
      },
      {
        denominazioneInItaliano: "Bortigiadas",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B063"
      },
      {
        denominazioneInItaliano: "Borutta",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B064"
      },
      {
        denominazioneInItaliano: "Bottidda",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B094"
      },
      {
        denominazioneInItaliano: "Buddusò",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B246"
      },
      {
        denominazioneInItaliano: "Bultei",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B264"
      },
      {
        denominazioneInItaliano: "Bulzi",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B265"
      },
      {
        denominazioneInItaliano: "Burgos",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B276"
      },
      {
        denominazioneInItaliano: "Calangianus",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B378"
      },
      {
        denominazioneInItaliano: "Cargeghe",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B772"
      },
      {
        denominazioneInItaliano: "Castelsardo",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "C272"
      },
      {
        denominazioneInItaliano: "Cheremule",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "C600"
      },
      {
        denominazioneInItaliano: "Chiaramonti",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "C613"
      },
      {
        denominazioneInItaliano: "Codrongianos",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "C818"
      },
      {
        denominazioneInItaliano: "Cossoine",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "D100"
      },
      {
        denominazioneInItaliano: "Esporlatu",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "D441"
      },
      {
        denominazioneInItaliano: "Florinas",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "D637"
      },
      {
        denominazioneInItaliano: "Giave",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E019"
      },
      {
        denominazioneInItaliano: "Illorai",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E285"
      },
      {
        denominazioneInItaliano: "Ittireddu",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E376"
      },
      {
        denominazioneInItaliano: "Ittiri",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E377"
      },
      {
        denominazioneInItaliano: "Laerru",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E401"
      },
      {
        denominazioneInItaliano: "La Maddalena",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E425"
      },
      {
        denominazioneInItaliano: "Luogosanto",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E747"
      },
      {
        denominazioneInItaliano: "Luras",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E752"
      },
      {
        denominazioneInItaliano: "Mara",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E902"
      },
      {
        denominazioneInItaliano: "Martis",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "E992"
      },
      {
        denominazioneInItaliano: "Monteleone Rocca Doria",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "F542"
      },
      {
        denominazioneInItaliano: "Monti",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "F667"
      },
      {
        denominazioneInItaliano: "Mores",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "F721"
      },
      {
        denominazioneInItaliano: "Muros",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "F818"
      },
      {
        denominazioneInItaliano: "Nughedu San Nicolò",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "F975"
      },
      {
        denominazioneInItaliano: "Nule",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "F976"
      },
      {
        denominazioneInItaliano: "Nulvi",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "F977"
      },
      {
        denominazioneInItaliano: "Olbia",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G015"
      },
      {
        denominazioneInItaliano: "Olmedo",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G046"
      },
      {
        denominazioneInItaliano: "Oschiri",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G153"
      },
      {
        denominazioneInItaliano: "Osilo",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G156"
      },
      {
        denominazioneInItaliano: "Ossi",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G178"
      },
      {
        denominazioneInItaliano: "Ozieri",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G203"
      },
      {
        denominazioneInItaliano: "Padria",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G225"
      },
      {
        denominazioneInItaliano: "Palau",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G258"
      },
      {
        denominazioneInItaliano: "Pattada",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G376"
      },
      {
        denominazioneInItaliano: "Perfugas",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G450"
      },
      {
        denominazioneInItaliano: "Ploaghe",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G740"
      },
      {
        denominazioneInItaliano: "Porto Torres",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G924"
      },
      {
        denominazioneInItaliano: "Pozzomaggiore",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "G962"
      },
      {
        denominazioneInItaliano: "Putifigari",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "H095"
      },
      {
        denominazioneInItaliano: "Romana",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "H507"
      },
      {
        denominazioneInItaliano: "Aglientu",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "H848"
      },
      {
        denominazioneInItaliano: "Santa Teresa Gallura",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "I312"
      },
      {
        denominazioneInItaliano: "Sassari",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "I452"
      },
      {
        denominazioneInItaliano: "Sedini",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "I565"
      },
      {
        denominazioneInItaliano: "Semestene",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "I598"
      },
      {
        denominazioneInItaliano: "Sennori",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "I614"
      },
      {
        denominazioneInItaliano: "Siligo",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "I732"
      },
      {
        denominazioneInItaliano: "Sorso",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "I863"
      },
      {
        denominazioneInItaliano: "Tempio Pausania",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L093"
      },
      {
        denominazioneInItaliano: "Thiesi",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L158"
      },
      {
        denominazioneInItaliano: "Tissi",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L180"
      },
      {
        denominazioneInItaliano: "Torralba",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L235"
      },
      {
        denominazioneInItaliano: "Trinità d'Agultu e Vignola",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L428"
      },
      {
        denominazioneInItaliano: "Tula",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L464"
      },
      {
        denominazioneInItaliano: "Uri",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L503"
      },
      {
        denominazioneInItaliano: "Usini",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L509"
      },
      {
        denominazioneInItaliano: "Villanova Monteleone",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L989"
      },
      {
        denominazioneInItaliano: "Valledoria",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L604"
      },
      {
        denominazioneInItaliano: "Telti",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "L088"
      },
      {
        denominazioneInItaliano: "Badesi",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M214"
      },
      {
        denominazioneInItaliano: "Viddalba",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M259"
      },
      {
        denominazioneInItaliano: "Golfo Aranci",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M274"
      },
      {
        denominazioneInItaliano: "Loiri Porto San Paolo",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M275"
      },
      {
        denominazioneInItaliano: "Sant'Antonio di Gallura",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M276"
      },
      {
        denominazioneInItaliano: "Tergu",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M282"
      },
      {
        denominazioneInItaliano: "Santa Maria Coghinas",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M284"
      },
      {
        denominazioneInItaliano: "Erula",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M292"
      },
      {
        denominazioneInItaliano: "Stintino",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M290"
      },
      {
        denominazioneInItaliano: "Padru",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "M301"
      },
      {
        denominazioneInItaliano: "Budoni",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "B248"
      },
      {
        denominazioneInItaliano: "San Teodoro",
        regione: "Sardegna",
        provincia: "Sassari",
        codiceCatastale: "I329"
      },
      {
        denominazioneInItaliano: "Aritzo",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A407"
      },
      {
        denominazioneInItaliano: "Arzana",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A454"
      },
      {
        denominazioneInItaliano: "Atzara",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A492"
      },
      {
        denominazioneInItaliano: "Austis",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A503"
      },
      {
        denominazioneInItaliano: "Bari Sardo",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A663"
      },
      {
        denominazioneInItaliano: "Baunei",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A722"
      },
      {
        denominazioneInItaliano: "Belvì",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A776"
      },
      {
        denominazioneInItaliano: "Birori",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A880"
      },
      {
        denominazioneInItaliano: "Bitti",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A895"
      },
      {
        denominazioneInItaliano: "Bolotana",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A948"
      },
      {
        denominazioneInItaliano: "Borore",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "B056"
      },
      {
        denominazioneInItaliano: "Bortigali",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "B062"
      },
      {
        denominazioneInItaliano: "Desulo",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D287"
      },
      {
        denominazioneInItaliano: "Dorgali",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D345"
      },
      {
        denominazioneInItaliano: "Dualchi",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D376"
      },
      {
        denominazioneInItaliano: "Elini",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D395"
      },
      {
        denominazioneInItaliano: "Fonni",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D665"
      },
      {
        denominazioneInItaliano: "Gadoni",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D842"
      },
      {
        denominazioneInItaliano: "Gairo",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D859"
      },
      {
        denominazioneInItaliano: "Galtellì",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D888"
      },
      {
        denominazioneInItaliano: "Gavoi",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "D947"
      },
      {
        denominazioneInItaliano: "Girasole",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E049"
      },
      {
        denominazioneInItaliano: "Ilbono",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E283"
      },
      {
        denominazioneInItaliano: "Irgoli",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E323"
      },
      {
        denominazioneInItaliano: "Jerzu",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E387"
      },
      {
        denominazioneInItaliano: "Lanusei",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E441"
      },
      {
        denominazioneInItaliano: "Lei",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E517"
      },
      {
        denominazioneInItaliano: "Loceri",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E644"
      },
      {
        denominazioneInItaliano: "Loculi",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E646"
      },
      {
        denominazioneInItaliano: "Lodè",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E647"
      },
      {
        denominazioneInItaliano: "Lotzorai",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E700"
      },
      {
        denominazioneInItaliano: "Lula",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E736"
      },
      {
        denominazioneInItaliano: "Macomer",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E788"
      },
      {
        denominazioneInItaliano: "Mamoiada",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E874"
      },
      {
        denominazioneInItaliano: "Meana Sardo",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "F073"
      },
      {
        denominazioneInItaliano: "Noragugume",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "F933"
      },
      {
        denominazioneInItaliano: "Nuoro",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "F979"
      },
      {
        denominazioneInItaliano: "Oliena",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G031"
      },
      {
        denominazioneInItaliano: "Ollolai",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G044"
      },
      {
        denominazioneInItaliano: "Olzai",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G058"
      },
      {
        denominazioneInItaliano: "Onanì",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G064"
      },
      {
        denominazioneInItaliano: "Onifai",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G070"
      },
      {
        denominazioneInItaliano: "Oniferi",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G071"
      },
      {
        denominazioneInItaliano: "Orani",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G084"
      },
      {
        denominazioneInItaliano: "Orgosolo",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G097"
      },
      {
        denominazioneInItaliano: "Orosei",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G119"
      },
      {
        denominazioneInItaliano: "Orotelli",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G120"
      },
      {
        denominazioneInItaliano: "Ortueri",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G146"
      },
      {
        denominazioneInItaliano: "Orune",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G147"
      },
      {
        denominazioneInItaliano: "Osidda",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G154"
      },
      {
        denominazioneInItaliano: "Osini",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G158"
      },
      {
        denominazioneInItaliano: "Ottana",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G191"
      },
      {
        denominazioneInItaliano: "Ovodda",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G201"
      },
      {
        denominazioneInItaliano: "Perdasdefogu",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G445"
      },
      {
        denominazioneInItaliano: "Posada",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "G929"
      },
      {
        denominazioneInItaliano: "Sarule",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "I448"
      },
      {
        denominazioneInItaliano: "Silanus",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "I730"
      },
      {
        denominazioneInItaliano: "Sindia",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "I748"
      },
      {
        denominazioneInItaliano: "Siniscola",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "I751"
      },
      {
        denominazioneInItaliano: "Sorgono",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "I851"
      },
      {
        denominazioneInItaliano: "Talana",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L036"
      },
      {
        denominazioneInItaliano: "Tertenia",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L140"
      },
      {
        denominazioneInItaliano: "Teti",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L153"
      },
      {
        denominazioneInItaliano: "Tiana",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L160"
      },
      {
        denominazioneInItaliano: "Tonara",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L202"
      },
      {
        denominazioneInItaliano: "Torpè",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L231"
      },
      {
        denominazioneInItaliano: "Tortolì",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "A355"
      },
      {
        denominazioneInItaliano: "Triei",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L423"
      },
      {
        denominazioneInItaliano: "Ulassai",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L489"
      },
      {
        denominazioneInItaliano: "Urzulei",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L506"
      },
      {
        denominazioneInItaliano: "Ussassai",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L514"
      },
      {
        denominazioneInItaliano: "Villagrande Strisaili",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "L953"
      },
      {
        denominazioneInItaliano: "Cardedu",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "M285"
      },
      {
        denominazioneInItaliano: "Lodine",
        regione: "Sardegna",
        provincia: "Nuoro",
        codiceCatastale: "E649"
      },
      {
        denominazioneInItaliano: "Assemini",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "A474"
      },
      {
        denominazioneInItaliano: "Cagliari",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "B354"
      },
      {
        denominazioneInItaliano: "Capoterra",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "B675"
      },
      {
        denominazioneInItaliano: "Decimomannu",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "D259"
      },
      {
        denominazioneInItaliano: "Maracalagonis",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "E903"
      },
      {
        denominazioneInItaliano: "Pula",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "H088"
      },
      {
        denominazioneInItaliano: "Quartu Sant'Elena",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "H118"
      },
      {
        denominazioneInItaliano: "Sarroch",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "I443"
      },
      {
        denominazioneInItaliano: "Selargius",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "I580"
      },
      {
        denominazioneInItaliano: "Sestu",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "I695"
      },
      {
        denominazioneInItaliano: "Settimo San Pietro",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "I699"
      },
      {
        denominazioneInItaliano: "Sinnai",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "I752"
      },
      {
        denominazioneInItaliano: "Uta",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "L521"
      },
      {
        denominazioneInItaliano: "Villa San Pietro",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "I118"
      },
      {
        denominazioneInItaliano: "Quartucciu",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "H119"
      },
      {
        denominazioneInItaliano: "Elmas",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "D399"
      },
      {
        denominazioneInItaliano: "Monserrato",
        regione: "Sardegna",
        provincia: "Cagliari",
        codiceCatastale: "F383"
      },
      {
        denominazioneInItaliano: "Abbasanta",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A007"
      },
      {
        denominazioneInItaliano: "Aidomaggiore",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A097"
      },
      {
        denominazioneInItaliano: "Albagiara",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A126"
      },
      {
        denominazioneInItaliano: "Ales",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A180"
      },
      {
        denominazioneInItaliano: "Allai",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A204"
      },
      {
        denominazioneInItaliano: "Arborea",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A357"
      },
      {
        denominazioneInItaliano: "Ardauli",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A380"
      },
      {
        denominazioneInItaliano: "Assolo",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A477"
      },
      {
        denominazioneInItaliano: "Asuni",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A480"
      },
      {
        denominazioneInItaliano: "Baradili",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A614"
      },
      {
        denominazioneInItaliano: "Baratili San Pietro",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A621"
      },
      {
        denominazioneInItaliano: "Baressa",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A655"
      },
      {
        denominazioneInItaliano: "Bauladu",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A721"
      },
      {
        denominazioneInItaliano: "Bidonì",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A856"
      },
      {
        denominazioneInItaliano: "Bonarcado",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A960"
      },
      {
        denominazioneInItaliano: "Boroneddu",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "B055"
      },
      {
        denominazioneInItaliano: "Busachi",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "B281"
      },
      {
        denominazioneInItaliano: "Cabras",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "B314"
      },
      {
        denominazioneInItaliano: "Cuglieri",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "D200"
      },
      {
        denominazioneInItaliano: "Fordongianus",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "D695"
      },
      {
        denominazioneInItaliano: "Ghilarza",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "E004"
      },
      {
        denominazioneInItaliano: "Gonnoscodina",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "E087"
      },
      {
        denominazioneInItaliano: "Gonnosnò",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "D585"
      },
      {
        denominazioneInItaliano: "Gonnostramatza",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "E088"
      },
      {
        denominazioneInItaliano: "Marrubiu",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "E972"
      },
      {
        denominazioneInItaliano: "Masullas",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F050"
      },
      {
        denominazioneInItaliano: "Milis",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F208"
      },
      {
        denominazioneInItaliano: "Mogorella",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F270"
      },
      {
        denominazioneInItaliano: "Mogoro",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F272"
      },
      {
        denominazioneInItaliano: "Morgongiori",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F727"
      },
      {
        denominazioneInItaliano: "Narbolia",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F840"
      },
      {
        denominazioneInItaliano: "Neoneli",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F867"
      },
      {
        denominazioneInItaliano: "Norbello",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F934"
      },
      {
        denominazioneInItaliano: "Nughedu Santa Vittoria",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F974"
      },
      {
        denominazioneInItaliano: "Nurachi",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F980"
      },
      {
        denominazioneInItaliano: "Nureci",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F985"
      },
      {
        denominazioneInItaliano: "Ollastra",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "G043"
      },
      {
        denominazioneInItaliano: "Oristano",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "G113"
      },
      {
        denominazioneInItaliano: "Palmas Arborea",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "G286"
      },
      {
        denominazioneInItaliano: "Pau",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "G379"
      },
      {
        denominazioneInItaliano: "Paulilatino",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "G384"
      },
      {
        denominazioneInItaliano: "Pompu",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "G817"
      },
      {
        denominazioneInItaliano: "Riola Sardo",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "H301"
      },
      {
        denominazioneInItaliano: "Ruinas",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F271"
      },
      {
        denominazioneInItaliano: "Samugheo",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "H756"
      },
      {
        denominazioneInItaliano: "San Nicolò d'Arcidano",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A368"
      },
      {
        denominazioneInItaliano: "Santa Giusta",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I205"
      },
      {
        denominazioneInItaliano: "Villa Sant'Antonio",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I298"
      },
      {
        denominazioneInItaliano: "Santu Lussurgiu",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I374"
      },
      {
        denominazioneInItaliano: "San Vero Milis",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I384"
      },
      {
        denominazioneInItaliano: "Scano di Montiferro",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I503"
      },
      {
        denominazioneInItaliano: "Sedilo",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I564"
      },
      {
        denominazioneInItaliano: "Seneghe",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I605"
      },
      {
        denominazioneInItaliano: "Senis",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I609"
      },
      {
        denominazioneInItaliano: "Sennariolo",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I613"
      },
      {
        denominazioneInItaliano: "Siamaggiore",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I717"
      },
      {
        denominazioneInItaliano: "Siamanna",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I718"
      },
      {
        denominazioneInItaliano: "Simala",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I742"
      },
      {
        denominazioneInItaliano: "Simaxis",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I743"
      },
      {
        denominazioneInItaliano: "Sini",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I749"
      },
      {
        denominazioneInItaliano: "Siris",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I757"
      },
      {
        denominazioneInItaliano: "Solarussa",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I791"
      },
      {
        denominazioneInItaliano: "Sorradile",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I861"
      },
      {
        denominazioneInItaliano: "Tadasuni",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L023"
      },
      {
        denominazioneInItaliano: "Terralba",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L122"
      },
      {
        denominazioneInItaliano: "Tramatza",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L321"
      },
      {
        denominazioneInItaliano: "Tresnuraghes",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L393"
      },
      {
        denominazioneInItaliano: "Ulà Tirso",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L488"
      },
      {
        denominazioneInItaliano: "Uras",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L496"
      },
      {
        denominazioneInItaliano: "Usellus",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L508"
      },
      {
        denominazioneInItaliano: "Villanova Truschedu",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L991"
      },
      {
        denominazioneInItaliano: "Villaurbana",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "M030"
      },
      {
        denominazioneInItaliano: "Villa Verde",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "A609"
      },
      {
        denominazioneInItaliano: "Zeddiani",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "M153"
      },
      {
        denominazioneInItaliano: "Zerfaliu",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "M168"
      },
      {
        denominazioneInItaliano: "Siapiccia",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I721"
      },
      {
        denominazioneInItaliano: "Curcuris",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "D214"
      },
      {
        denominazioneInItaliano: "Soddì",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "I778"
      },
      {
        denominazioneInItaliano: "Bosa",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "B068"
      },
      {
        denominazioneInItaliano: "Flussio",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "D640"
      },
      {
        denominazioneInItaliano: "Laconi",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "E400"
      },
      {
        denominazioneInItaliano: "Magomadas",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "E825"
      },
      {
        denominazioneInItaliano: "Modolo",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F261"
      },
      {
        denominazioneInItaliano: "Montresta",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "F698"
      },
      {
        denominazioneInItaliano: "Sagama",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "H661"
      },
      {
        denominazioneInItaliano: "Suni",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L006"
      },
      {
        denominazioneInItaliano: "Tinnura",
        regione: "Sardegna",
        provincia: "Oristano",
        codiceCatastale: "L172"
      },
      {
        denominazioneInItaliano: "Arbus",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "A359"
      },
      {
        denominazioneInItaliano: "Armungia",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "A419"
      },
      {
        denominazioneInItaliano: "Ballao",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "A597"
      },
      {
        denominazioneInItaliano: "Barrali",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "A677"
      },
      {
        denominazioneInItaliano: "Barumini",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "A681"
      },
      {
        denominazioneInItaliano: "Buggerru",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "B250"
      },
      {
        denominazioneInItaliano: "Burcei",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "B274"
      },
      {
        denominazioneInItaliano: "Calasetta",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "B383"
      },
      {
        denominazioneInItaliano: "Carbonia",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "B745"
      },
      {
        denominazioneInItaliano: "Carloforte",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "B789"
      },
      {
        denominazioneInItaliano: "Castiadas",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "M288"
      },
      {
        denominazioneInItaliano: "Collinas",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "C882"
      },
      {
        denominazioneInItaliano: "Decimoputzu",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D260"
      },
      {
        denominazioneInItaliano: "Dolianova",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D323"
      },
      {
        denominazioneInItaliano: "Domus de Maria",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D333"
      },
      {
        denominazioneInItaliano: "Domusnovas",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D334"
      },
      {
        denominazioneInItaliano: "Donori",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D344"
      },
      {
        denominazioneInItaliano: "Escalaplano",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D430"
      },
      {
        denominazioneInItaliano: "Escolca",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D431"
      },
      {
        denominazioneInItaliano: "Esterzili",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D443"
      },
      {
        denominazioneInItaliano: "Fluminimaggiore",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D639"
      },
      {
        denominazioneInItaliano: "Furtei",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D827"
      },
      {
        denominazioneInItaliano: "Genoni",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D968"
      },
      {
        denominazioneInItaliano: "Genuri",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D970"
      },
      {
        denominazioneInItaliano: "Gergei",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D982"
      },
      {
        denominazioneInItaliano: "Gesico",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D994"
      },
      {
        denominazioneInItaliano: "Gesturi",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "D997"
      },
      {
        denominazioneInItaliano: "Giba",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E022"
      },
      {
        denominazioneInItaliano: "Goni",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E084"
      },
      {
        denominazioneInItaliano: "Gonnesa",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E086"
      },
      {
        denominazioneInItaliano: "Gonnosfanadiga",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E085"
      },
      {
        denominazioneInItaliano: "Guamaggiore",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E234"
      },
      {
        denominazioneInItaliano: "Guasila",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E252"
      },
      {
        denominazioneInItaliano: "Guspini",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E270"
      },
      {
        denominazioneInItaliano: "Iglesias",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E281"
      },
      {
        denominazioneInItaliano: "Isili",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E336"
      },
      {
        denominazioneInItaliano: "Las Plassas",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E464"
      },
      {
        denominazioneInItaliano: "Lunamatrona",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E742"
      },
      {
        denominazioneInItaliano: "Mandas",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "E877"
      },
      {
        denominazioneInItaliano: "Masainas",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "M270"
      },
      {
        denominazioneInItaliano: "Monastir",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F333"
      },
      {
        denominazioneInItaliano: "Muravera",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F808"
      },
      {
        denominazioneInItaliano: "Musei",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F822"
      },
      {
        denominazioneInItaliano: "Narcao",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F841"
      },
      {
        denominazioneInItaliano: "Nuragus",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F981"
      },
      {
        denominazioneInItaliano: "Nurallao",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F982"
      },
      {
        denominazioneInItaliano: "Nuraminis",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F983"
      },
      {
        denominazioneInItaliano: "Nurri",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F986"
      },
      {
        denominazioneInItaliano: "Nuxis",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "F991"
      },
      {
        denominazioneInItaliano: "Orroli",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G122"
      },
      {
        denominazioneInItaliano: "Ortacesus",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G133"
      },
      {
        denominazioneInItaliano: "Pabillonis",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G207"
      },
      {
        denominazioneInItaliano: "Pauli Arbarei",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G382"
      },
      {
        denominazioneInItaliano: "Perdaxius",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G446"
      },
      {
        denominazioneInItaliano: "Pimentel",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G669"
      },
      {
        denominazioneInItaliano: "Piscinas",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "M291"
      },
      {
        denominazioneInItaliano: "Portoscuso",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G922"
      },
      {
        denominazioneInItaliano: "Sadali",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "H659"
      },
      {
        denominazioneInItaliano: "Samassi",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "H738"
      },
      {
        denominazioneInItaliano: "Samatzai",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "H739"
      },
      {
        denominazioneInItaliano: "San Basilio",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "H766"
      },
      {
        denominazioneInItaliano: "San Gavino Monreale",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "H856"
      },
      {
        denominazioneInItaliano: "San Giovanni Suergiu",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G287"
      },
      {
        denominazioneInItaliano: "San Nicolò Gerrei",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "G383"
      },
      {
        denominazioneInItaliano: "San Sperate",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I166"
      },
      {
        denominazioneInItaliano: "San Vito",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I402"
      },
      {
        denominazioneInItaliano: "Sanluri",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "H974"
      },
      {
        denominazioneInItaliano: "Santadi",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I182"
      },
      {
        denominazioneInItaliano: "Sant'Andrea Frius",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I271"
      },
      {
        denominazioneInItaliano: "Sant'Anna Arresi",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "M209"
      },
      {
        denominazioneInItaliano: "Sant'Antioco",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I294"
      },
      {
        denominazioneInItaliano: "Sardara",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I428"
      },
      {
        denominazioneInItaliano: "Segariu",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I570"
      },
      {
        denominazioneInItaliano: "Selegas",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I582"
      },
      {
        denominazioneInItaliano: "Senorbì",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I615"
      },
      {
        denominazioneInItaliano: "Serdiana",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I624"
      },
      {
        denominazioneInItaliano: "Serramanna",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I647"
      },
      {
        denominazioneInItaliano: "Serrenti",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I667"
      },
      {
        denominazioneInItaliano: "Serri",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I668"
      },
      {
        denominazioneInItaliano: "Setzu",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I705"
      },
      {
        denominazioneInItaliano: "Seui",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I706"
      },
      {
        denominazioneInItaliano: "Seulo",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I707"
      },
      {
        denominazioneInItaliano: "Siddi",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I724"
      },
      {
        denominazioneInItaliano: "Siliqua",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I734"
      },
      {
        denominazioneInItaliano: "Silius",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I735"
      },
      {
        denominazioneInItaliano: "Siurgus Donigala",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I765"
      },
      {
        denominazioneInItaliano: "Soleminis",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I797"
      },
      {
        denominazioneInItaliano: "Suelli",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "I995"
      },
      {
        denominazioneInItaliano: "Teulada",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L154"
      },
      {
        denominazioneInItaliano: "Tratalias",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L337"
      },
      {
        denominazioneInItaliano: "Tuili",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L463"
      },
      {
        denominazioneInItaliano: "Turri",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L473"
      },
      {
        denominazioneInItaliano: "Ussana",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L512"
      },
      {
        denominazioneInItaliano: "Ussaramanna",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L513"
      },
      {
        denominazioneInItaliano: "Vallermosa",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L613"
      },
      {
        denominazioneInItaliano: "Villacidro",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L924"
      },
      {
        denominazioneInItaliano: "Villamar",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L966"
      },
      {
        denominazioneInItaliano: "Villamassargia",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L968"
      },
      {
        denominazioneInItaliano: "Villanova Tulo",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L992"
      },
      {
        denominazioneInItaliano: "Villanovaforru",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L986"
      },
      {
        denominazioneInItaliano: "Villanovafranca",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L987"
      },
      {
        denominazioneInItaliano: "Villaperuccio",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "M278"
      },
      {
        denominazioneInItaliano: "Villaputzu",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "L998"
      },
      {
        denominazioneInItaliano: "Villasalto",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "M016"
      },
      {
        denominazioneInItaliano: "Villasimius",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "B738"
      },
      {
        denominazioneInItaliano: "Villasor",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "M025"
      },
      {
        denominazioneInItaliano: "Villaspeciosa",
        regione: "Sardegna",
        provincia: "Sud Sardegna",
        codiceCatastale: "M026"
      }]


  //!!!! QUA SONO LE COMUINITA MONTANE
  ComunitaMontane = [{
    denominazioneInItaliano: "COMUNITA' MONTANA AVENTINO-MEDIO SANGRO",
    codiceCatastale: 717651
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA SIRENTINA",
    codiceCatastale: 723976
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA CAMPO IMPERATORE-PIANA DI NAVELLI",
    codiceCatastale: 11136077
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL GRAN SASSO",
    codiceCatastale: 11136557
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL VOMANO FINO E PIOMBA",
    codiceCatastale: 11137115
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA PELIGNA",
    codiceCatastale: 11142181
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLA LAGA",
    codiceCatastale: 11303591
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLA MAIELLETTA",
    codiceCatastale: 12503925
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA AMITERNINA ZONA \"A\"",
    codiceCatastale: 12533581
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'ALTO SANGRO E DELL'ALTOPIANO DELLE CINQUEMIGLIA",
    codiceCatastale: 12537047
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA \" MONTAGNA SANGRO VASTESE\"",
    codiceCatastale: 26968465
  },
  {
    denominazioneInItaliano: "COMUNITA'  MONTANA  \"MONTAGNA  MARSICANA\"",
    codiceCatastale: 27489075
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTAGNA DI L'AQUILA",
    codiceCatastale: 27501192
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA \" MONTAGNA PESCARESE\"",
    codiceCatastale: 27706694
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MEDIO BASENTO",
    codiceCatastale: 701823
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTO BASENTO",
    codiceCatastale: 701866
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MELANDRO",
    codiceCatastale: 702276
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTO AGRI",
    codiceCatastale: 703154
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA CAMASTRA ALTO SAURO",
    codiceCatastale: 704462
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VAL SARMENTO",
    codiceCatastale: 719885
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MEDIO AGRI",
    codiceCatastale: 719985
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA BASSO SINNI",
    codiceCatastale: 721118
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL VULTURE",
    codiceCatastale: 727198
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA COLLINA MATERANA",
    codiceCatastale: 742224
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MARMO-PLATANO",
    codiceCatastale: 10700974
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTO SINNI",
    codiceCatastale: 11102335
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTI TIRIOLO REVENTINO E MANCUSO",
    codiceCatastale: 74380
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'AREA GRECANICA",
    codiceCatastale: 706115
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'ASPROMONTE ORIENTALE",
    codiceCatastale: 718530
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL VERSANTE TIRRENICO SETTENTRIONALE",
    codiceCatastale: 720087
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL VERSANTE TIRRENICO MERIDIONALE",
    codiceCatastale: 720849
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DESTRA CRATI",
    codiceCatastale: 727013
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA FOSSA DEL LUPO\/VERSANTE JONICO",
    codiceCatastale: 9175603
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL VERSANTE DELLO STRETTO",
    codiceCatastale: 10697226
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLA PRESILA CATANZARESE",
    codiceCatastale: 11095913
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA SERRE COSENTINE",
    codiceCatastale: 11137544
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'ALTO CROTONESE",
    codiceCatastale: 11140781
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'ALTO MESIMA",
    codiceCatastale: 11142414
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLE SERRE CALABRE",
    codiceCatastale: 11321802
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA STILARO - ALLARO - LIMINA",
    codiceCatastale: 25499783
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL TABURNO",
    codiceCatastale: 700365
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTA IRPINIA",
    codiceCatastale: 720234
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA TERMINIO CERVIALTO",
    codiceCatastale: 721230
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLO DI DIANO",
    codiceCatastale: 724107
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL GELBISON E DEI CERVATI",
    codiceCatastale: 725925
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL CALORE SALERNITANA",
    codiceCatastale: 7548178
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTE MAGGIORE",
    codiceCatastale: 11137849
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL FORTORE",
    codiceCatastale: 11141374
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEGLI ALBURNI",
    codiceCatastale: 11141531
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTE S.CROCE",
    codiceCatastale: 11142338
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALENTO E MONTESTELLA",
    codiceCatastale: 11144406
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL MATESE",
    codiceCatastale: 11146546
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'UFITA",
    codiceCatastale: 11321261
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEI MONTI PICENTINI",
    codiceCatastale: 12535366
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ZONA DELL'IRNO",
    codiceCatastale: 12535367
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA BUSSENTO - LAMBRO E MINGARDO",
    codiceCatastale: 25499749
  },
  {
    denominazioneInItaliano: " COMUNITA' MONTANA TITERNO ED ALTO TAMMARO ",
    codiceCatastale: 25499756
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA PARTENIO- VALLO DI LAURO",
    codiceCatastale: 25499759
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA TANAGRO ALTO E MEDIO SELE",
    codiceCatastale: 25499769
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTI LATTARI",
    codiceCatastale: 25499774
  },
  {
    denominazioneInItaliano: "C.M. DEL FRIULI OCCIDENTALE",
    codiceCatastale: 20961021
  },
  {
    denominazioneInItaliano: "COMUNITA' DEL FRIULI ORIENTALE",
    codiceCatastale: 30825240
  },
  {
    denominazioneInItaliano: "COMUNITA' DI MONTAGNA DEL GEMONESE",
    codiceCatastale: 30825248
  },
  {
    denominazioneInItaliano: "COMUNITA' DI MONTAGNA DELLA CARNIA",
    codiceCatastale: 30825247
  },
  {
    denominazioneInItaliano: "COMUNITA' SILE",
    codiceCatastale: 30864335
  },
  {
    denominazioneInItaliano: "COMUNITA' DEL MEDIOFRIULI",
    codiceCatastale: 30835764
  },
  {
    denominazioneInItaliano: "COMUNITA' DI MONTAGNA DEL NATISONE E TORRE",
    codiceCatastale: 30825233
  },
  {
    denominazioneInItaliano: "COMUNITA' RIVIERA FRIULANA",
    codiceCatastale: 31046755
  },
  {
    denominazioneInItaliano: "COMUNITA' DI MONTAGNA DEL CANAL DEL FERRO E VAL CANALE",
    codiceCatastale: 30825232
  },
  {
    denominazioneInItaliano: "MAGNIFICA COMUNITA' DI MONTAGNA DOLOMITI FRIULANE, CAVALLO E CANSIGLIO",
    codiceCatastale: 33401298
  },
  {
    denominazioneInItaliano: "COMUNITA' DI MONTAGNA DELLE PREALPI FRIULANE ORIENTALI",
    codiceCatastale: 33401300
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTI ERNICI",
    codiceCatastale: 704514
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL TURANO",
    codiceCatastale: 704851
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTEPIANO REATINO",
    codiceCatastale: 705009
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEI CIMINI",
    codiceCatastale: 707216
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTA TUSCIA LAZIALE",
    codiceCatastale: 709189
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE DEL LIRI",
    codiceCatastale: 721994
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE DI COMINO",
    codiceCatastale: 722082
  },
  {
    denominazioneInItaliano: "IX COMUNITA' MONTANA DEI MONTI SABINI TIBURTINI CORNICOLANI PRENESTINI",
    codiceCatastale: 727861
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTI DELLA TOLFA",
    codiceCatastale: 739511
  },
  {
    denominazioneInItaliano: "XI COMUNITA' MONTANA DEI CASTELLI ROMANI E PRENESTINI",
    codiceCatastale: 7553185
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL SALTO GICOLANO",
    codiceCatastale: 11136705
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL VELINO",
    codiceCatastale: 11138482
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'ANIENE",
    codiceCatastale: 11152823
  },
  {
    denominazioneInItaliano: "XIII COMUNITA' MONTANA MONTI LEPINI E AUSONI",
    codiceCatastale: 13558943
  },
  {
    denominazioneInItaliano: "COMUNITA''MONTANA XX DEI MONTI SABINI",
    codiceCatastale: 19585223
  },
  {
    denominazioneInItaliano: "C.M. LAZIO XIX \" L'ARCO DEGLI AURUNCI\"",
    codiceCatastale: 19709010
  },
  {
    denominazioneInItaliano: "XVI C. M. MONTI AUSONI",
    codiceCatastale: 19709076
  },
  {
    denominazioneInItaliano: "XXII COMUNITA' MONTANA",
    codiceCatastale: 19712022
  },
  {
    denominazioneInItaliano: "XVIII C.M. DEI MONTI LEPINI - ZONA ROMANA -",
    codiceCatastale: 19722134
  },
  {
    denominazioneInItaliano: "XXI C.M. MONTI LEPINI AUSONI E VALLIVA",
    codiceCatastale: 19722136
  },
  {
    denominazioneInItaliano: "XVII COMUNITA' MONTANA MONTI AURUNCI ZONA XVII DEL LAZIO",
    codiceCatastale: 20183250
  },
  {
    denominazioneInItaliano: "COMUNITA' DELL'ARCIPELAGO DELLE ISOLE PONZIANE",
    codiceCatastale: 20930396
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLE ALTE VALLI TREBBIA E BISAGNO",
    codiceCatastale: 25499750
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLA RIVIERA SPEZZINA",
    codiceCatastale: 11137154
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLI GENOVESI SCRIVIA E POLCEVERA",
    codiceCatastale: 25499737
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLI STURA ORBA E LEIRA",
    codiceCatastale: 25499739
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VAL DI VARA",
    codiceCatastale: 25499742
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA PONENTE SAVONESE",
    codiceCatastale: 25499745
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ARGENTINA ARMEA",
    codiceCatastale: 25499757
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA FONTANABUONA",
    codiceCatastale: 25499796
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA INTEMELIA IN LIQUIDAZIONE",
    codiceCatastale: 25499798
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'OLIVO E ALTA VALLE ARROSCIA",
    codiceCatastale: 25499799
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL GIOVO",
    codiceCatastale: 25501369
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTA VAL BORMIDA",
    codiceCatastale: 25507414
  },
  {
    denominazioneInItaliano: "COMUNITA'  MONTANA VALLI AVETO,GRAVEGLIA E STURLA",
    codiceCatastale: 25616959
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DI SCALVE",
    codiceCatastale: 103486
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE CAMONICA",
    codiceCatastale: 373899
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA SEBINO BRESCIANO",
    codiceCatastale: 710172
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE IMAGNA",
    codiceCatastale: 711335
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA TRIANGOLO LARIANO",
    codiceCatastale: 721450
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE TROMPIA",
    codiceCatastale: 723980
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE BREMBANA",
    codiceCatastale: 727375
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA PARCO ALTO GARDA BRESCIANO",
    codiceCatastale: 728529
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE SABBIA",
    codiceCatastale: 728551
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALTELLINA DI TIRANO",
    codiceCatastale: 743295
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTA VALTELLINA",
    codiceCatastale: 10701365
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLA VALCHIAVENNA",
    codiceCatastale: 11140116
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALTELLINA DI MORBEGNO",
    codiceCatastale: 11146330
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALTELLINA DI SONDRIO",
    codiceCatastale: 11151037
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA OLTREPO PAVESE",
    codiceCatastale: 11153846
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA LARIO INTELVESE",
    codiceCatastale: 11154141
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALSASSINA-VALVARRONE-VAL D'ESINO E RIVIERA",
    codiceCatastale: 13159420
  },
  {
    denominazioneInItaliano: "COMUNITA'  MONTANA  DEI  LAGHI  BERGAMASCHI",
    codiceCatastale: 25617028
  },
  {
    denominazioneInItaliano: "COMUNITA'  MONTANA  VALLI  DEL  LARIO  E  DEL  CERESIO",
    codiceCatastale: 25617032
  },
  {
    denominazioneInItaliano: "COMUNITA'  MONTANA  VALLI  DEL  VERBANO",
    codiceCatastale: 25617031
  },
  {
    denominazioneInItaliano: "COMUNITA'  MONTANA  \" LARIO   ORIENTALE -  VALLE  SAN  MARTINO\"",
    codiceCatastale: 25617030
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL PIAMBELLO",
    codiceCatastale: 25617052
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE SERIANA",
    codiceCatastale: 25617060
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'ALTO E MEDIO METAURO",
    codiceCatastale: 722060
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL MONTEFELTRO",
    codiceCatastale: 722232
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL CATRIA E DEL NERONE",
    codiceCatastale: 722293
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTO MOLISE",
    codiceCatastale: 4547
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL MOLISE CENTRALE",
    codiceCatastale: 9608
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL TRIGNO MEDIO BIFERNO",
    codiceCatastale: 24360
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL FORTORE MOLISANO",
    codiceCatastale: 26180
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA CIGNO VALLE BIFERNO",
    codiceCatastale: 28101
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL MATESE 5^ ZONA OMOGENEA - BOIANO",
    codiceCatastale: 700366
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA CENTRO PENTRIA",
    codiceCatastale: 11116013
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL SANNIO",
    codiceCatastale: 11116031
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTE MAURO",
    codiceCatastale: 11141658
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL VOLTURNO",
    codiceCatastale: 12533557
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE SUSA E VAL SANGONE",
    codiceCatastale: 26279349
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA TERRE DEL GIAROLO - VALLI BORBERA, CURONE, GRUE OSSONA, SPINTI",
    codiceCatastale: 26279346
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLI CHISONE, GERMANASCA, PELLICE E PINEROLESE PEDEMONTANO",
    codiceCatastale: 26279352
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL GARGANO",
    codiceCatastale: 723946
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLA MURGIA NORD-OCCIDENTALE",
    codiceCatastale: 724232
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA SUB-APPENNINO DAUNO MERIDIONALE",
    codiceCatastale: 12533582
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA SUB  APPENNINO DAUNO SETTENTRIONALE ",
    codiceCatastale: 712265
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA GOCEANO",
    codiceCatastale: 717992
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA SARCIDANO-BARBAGIA DI SEULO",
    codiceCatastale: 728734
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DI SU SASSU-ANGLONA",
    codiceCatastale: 735431
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA OSILO",
    codiceCatastale: 7513886
  },
  {
    denominazioneInItaliano: "COMUNITA'  MONTANA  DEL MONTE  ACUTO ",
    codiceCatastale: 25499802
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA N.5 DEL NUORESE-GENNARGENTU-SUPRAMONTE-BARBAGIA",
    codiceCatastale: 26279254
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA GENNARGENTU MANDROLISAI",
    codiceCatastale: 26279397
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA COLLINE METALLIFERE",
    codiceCatastale: 43100
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VAL DI CECINA",
    codiceCatastale: 66411
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELL'APPENINO PISTOIESE",
    codiceCatastale: 10714091
  },
  {
    denominazioneInItaliano: "COMUNITA' COMPRENSORIALE VALLE PUSTERIA",
    codiceCatastale: 264731
  },
  {
    denominazioneInItaliano: "COMUNITA' COMPRENSORIALE DI SALTO-SCILIAR",
    codiceCatastale: 699620
  },
  {
    denominazioneInItaliano: "COMUNITA' COMPRENSORIALE OLTRADIGE BASSA ATESINA",
    codiceCatastale: 707056
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALLE ISARCO",
    codiceCatastale: 719179
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VAL VENOSTA",
    codiceCatastale: 722332
  },
  {
    denominazioneInItaliano: "COMUNITA' COMPRENSORIALE BURGRAVIATO",
    codiceCatastale: 722536
  },
  {
    denominazioneInItaliano: "COMUNITA' COMPRENSORIALE WIPPTAL",
    codiceCatastale: 7556857
  },
  {
    denominazioneInItaliano: "COMUNITA' DI PRIMIERO",
    codiceCatastale: 26279354
  },
  {
    denominazioneInItaliano: "COMUNITA' DELLE GIUDICARIE",
    codiceCatastale: 26279355
  },
  {
    denominazioneInItaliano: "COMUNITA' DELLA VALLE DI CEMBRA",
    codiceCatastale: 26279381
  },
  {
    denominazioneInItaliano: "COMUNITA' DELLA VALLE DEI LAGHI",
    codiceCatastale: 26279382
  },
  {
    denominazioneInItaliano: "COMUNITA' DELLA PAGANELLA",
    codiceCatastale: 26279383
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTA VALSUGANA E BERSNTOL",
    codiceCatastale: 26279411
  },
  {
    denominazioneInItaliano: "MAGNIFICA COMUNITA' DEGLI ALTIPIANI CIMBRI",
    codiceCatastale: 26279413
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA TERRITORIALE DELLA VAL DI FIEMME",
    codiceCatastale: 26279419
  },
  {
    denominazioneInItaliano: "COMUNITA' DELLA VAL DI NON ",
    codiceCatastale: 26279422
  },
  {
    denominazioneInItaliano: "COMUNITA' VALSUGANA E TESINO ",
    codiceCatastale: 26817030
  },
  {
    denominazioneInItaliano: "COMUNITA'  DELLA ROTALIANA - KONIGSBERG",
    codiceCatastale: 26832513
  },
  {
    denominazioneInItaliano: "COMUN GENERAL DE FASCIA",
    codiceCatastale: 26852098
  },
  {
    denominazioneInItaliano: "COMUNITA' ALTO GARDA E LEDRO",
    codiceCatastale: 26852103
  },
  {
    denominazioneInItaliano: "COMUNITA' DELLA VALLAGARINA",
    codiceCatastale: 26968374
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MONTI DEL TRASIMENO",
    codiceCatastale: 705320
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ALTA UMBRIA",
    codiceCatastale: 25499747
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA VALNERINA",
    codiceCatastale: 25499748
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DEL SUBASIO E DEI MONTI MARTANI",
    codiceCatastale: 25499734
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA ORVIETANO NARNESE AMERINO TUDERTE",
    codiceCatastale: 25499731
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA MI - VALLEE",
    codiceCatastale: 2448991
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA DELLA LESSINIA",
    codiceCatastale: 108183
  },
  {
    denominazioneInItaliano: "COMUNITA' MONTANA AGNO-CHIAMPO",
    codiceCatastale: 727153
  },
  {
    denominazioneInItaliano: "COMUNITA' DELLA VALLE DI SOLE",
    codiceCatastale: 26852102
  }
  ]

  getComuni() {
    return Promise.resolve(this.Comuni)
  }
  
  getComunitaMontane () {
    return Promise.resolve(this.ComunitaMontane)
  }
}