import { Component, Input } from '@angular/core';
import {
  Annotazione,
  AnnotazioneResponse,
  AnnotazioniResponse,
} from 'src/app/model/annotazione';
import { AnnotazioniService } from 'src/app/service/annotazioni.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ScenarioService } from 'src/app/service/scenario.service';
import { ModelloDocumentaleCustom, Scenario } from 'src/app/model/scenari';
import { NgxSpinnerService } from 'ngx-spinner';
import { CUP } from 'src/app/model/cup';
import { TipoEntita } from 'src/app/model/documento';
import { Ruolo, TipoPermesso, Utente } from 'src/app/model/utente';
import { RuoloPipe } from 'src/app/pipe/ruolo-pipe';

@Component({
  selector: 'app-cup-annotazioni',
  templateUrl: './cup-annotazioni.component.html',
  styleUrls: ['./cup-annotazioni.component.scss'],
  providers: [ConfirmationService, MessageService],
})
export class CupAnnotazioniComponent {
  @Input() cupId: number;

  @Input() cup: CUP;

  /**
   * Permesso in base all'utente loggato
   */
  @Input() public permesso: TipoPermesso;
  /**
   * Utente loggato
   */
  @Input() public user: Utente;

  annotazioni: Annotazione[];
  filteredTab: Annotazione[];
  selectedAnnotazione: Annotazione;
  datiModelloDocumentale: ModelloDocumentaleCustom;
  isRowSelected: boolean = false;
  visible: boolean = false;
  isInEdit: boolean;
  visualizza: boolean;
  id: number;
  showMessages: boolean;
  isId: boolean;
  annotazioneID: number;
  // Id dell'elemento appena creato da inviare all'onchange per procedere con il salvataggio del documento se collegato 
	entityIdAdd: number;

  public TipoPermesso = TipoPermesso;
	public Ruolo = Ruolo;
	public RuoloPipe = new RuoloPipe();

  constructor(
    private annotazioniServices: AnnotazioniService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private scenarioServices: ScenarioService,
    private spinner: NgxSpinnerService
  ) {
    this.showMessages = false;
  }

  ngOnInit() {
    this.isId = this.route.snapshot.paramMap.get('id') ? true : false;
    if (this.isId) {
      this.annotazioniServices.getAnnotazioniByCup(this.cupId).subscribe({
        next: (response: AnnotazioniResponse) => {
          if (response && response.success) {
            this.annotazioni = response.dtos;
            this.filteredTab = this.annotazioni;
          }
        },
      });
    }
  }

  showDialogForm(isInEdit: boolean, annotazione?: Annotazione) {
    this.datiModelloDocumentale = this.scenarioServices.getModelloDocumentaleCustom(Scenario.Annotazione);
    this.datiModelloDocumentale.isEdit = isInEdit;
    this.datiModelloDocumentale.cup = this.cup;
    this.datiModelloDocumentale.idCup = this.cupId;
    const formGroup = this.scenarioServices.getFormGroup(Scenario.Annotazione);
    if (isInEdit && annotazione) {
      this.datiModelloDocumentale.entityID = annotazione.id;
      formGroup.setValue({
        id: annotazione.id,
        cupId: annotazione.cupId,
        descrizione: annotazione.descrizione,
        data: new Date(annotazione.data),
        nota: annotazione.nota,
        allegato: null,
      });
      formGroup.disable();

    } else {
      formGroup.get('id').setValue(0);
    }
    this.datiModelloDocumentale.formGroup = formGroup;
    this.datiModelloDocumentale.entityTipo = TipoEntita.Annotazione;

    this.visible = true;
  }

  addAnnotazione(form) {
    let annotazione = form.value;
    annotazione.cupId = this.cupId;
    this.spinner.show();
    this.annotazioniServices.createAnnotazione(annotazione).subscribe({
      next: (resp: AnnotazioneResponse) => {
        if (resp && resp.success) {
            console.log(resp);
            this.entityIdAdd = resp.dto.id;
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Annotazione aggiunta con successo',
          });
        } else {
          console.log('500');
        }
        this.spinner.hide();
        this.updateAnnotazioni();
       
      },
      error: (err) => {
        this.spinner.hide();
        this.visible = false;
      },
    });
  }

  saveAnnotazione(form) {
    let annotazione = form.value;
    this.spinner.show();
    this.annotazioniServices.updateAnnotazione(annotazione).subscribe({
      next: (resp: AnnotazioneResponse) => {
        if (resp && resp.success) {
          console.log(resp.dto);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Annotazione modificata con successo',
          });
        } else {
          console.log('500');
        }
        this.spinner.hide();
        this.updateAnnotazioni();
      },
      error: (err) => {
        this.spinner.hide();
        this.visible = false;
      },
    });
  }

  closeModal(){
    this.visible = false;
  }

  confirm(id) {
    this.confirmationService.confirm({
      message: 'Sei sicuro di voler eliminare questa annotazione?',
      header: 'Eliminazione',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Si, elimina',
      rejectLabel: 'No, torna indietro',
      acceptButtonStyleClass: 'p-button-danger p-button-text',
      rejectButtonStyleClass: 'p-button-text',
      accept: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Eliminazione',
          detail: 'Annotazione eliminata con successo',
        });
        this.annotazioniServices.deleteAnnotazione(id).subscribe({
          next: (response: AnnotazioneResponse) => {
            if (response && response.success) {
              this.selectedAnnotazione = response.dto;
              this.annotazioni = this.annotazioni.filter((x) => x.id != id);
              this.filteredTab = this.annotazioni;
            }
          },
          error: (err) => {
            console.log(err);
          },
        });
      },
      reject: () => {
        this.messageService.add({
          severity: 'error',
          summary: 'Rejected',
          detail: 'You have rejected',
        });
      },
    });
  }

  updateAnnotazioni() {
    this.annotazioniServices.getAnnotazioniByCup(this.cupId).subscribe({
      next: (response: AnnotazioniResponse) => {
        if (response && response.success) {
          this.annotazioni = response.dtos;
          this.filteredTab = this.annotazioni;
        } else {
          console.log('500');
        }
      },
    });
  }
}
