<form [formGroup]="formInvito">
    <div class="row">
      <div class="form-group col-md-10">
        <label for="email" class="col-form-label">Email</label>
        <input formControlName="email" type="text" class="form-control" placeholder="Email di registrazione">
        <!-- <small style="color: red" *ngIf="!loginForm.get('username').valid && loginForm.get('username').touched">
          Inserisci il numero</small> -->
      </div>
      <div class="form-group col-md-2" style="display: flex; flex-direction: column-reverse;">
        <p-toast></p-toast>
        <button pButton label="INVITA" class="p-button-success" [disabled]="formInvito.invalid" (click)="confirm()"></button>
      </div>

      
    </div>
    
  
</form>
<p-confirmDialog [style]="{width: '50vw'}"></p-confirmDialog>
